import React from "react";
import { Modal } from "react-bootstrap";
export const DownloadFilesModal = ({
  showDownloadModal,
  setShowDownloadModal,
  selectedConversation,
  files,
}) => {
  return (
    <Modal
      show={showDownloadModal}
      onHide={() => setShowDownloadModal(false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Download Files</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {files.length === 0 ? (
          <h2>There are no files</h2>
        ) : (
          files.map((file, index) => (
            <a
              key={index}
              href={`/api/conversations/${selectedConversation.id}/files`}
              download
            >
              Download file {index + 1}
            </a>
          ))
        )}
      </Modal.Body>
    </Modal>
  );
};
export default DownloadFilesModal;
