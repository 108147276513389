import { useState, useEffect } from "react";
import { axios } from "common/utils/axios";

export const useCustomerData = () => {
  const [customerData, setCustomerData] = useState([]);

  useEffect(() => {
    axios
      .get(`/api/customers`)
      .then((response) => setCustomerData(response.data))
      .catch((error) => console.error(error));
  }, []);

  return customerData;
};
