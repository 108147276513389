import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export const SelectInput = (props) => {
  return (
    <div>
      <Box sx={{ minWidth: "80%", backgroundColor: "white", width: "80px" }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
          <Select
            required={props.required}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label={props.label}
          >
            {props.options ? (
              props.options.map((option, index) => (
                <MenuItem
                  key={index} // Use the index as a last resort if the values are not unique
                  value={typeof option === "object" ? option.value : option}
                >
                  {typeof option === "object" ? option.label : option}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">Loading...</MenuItem>
            )}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
};
export default SelectInput;
