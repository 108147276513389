import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useCustomerData } from "common/hooks/useCustomerData";
import { useQuery, useMutation } from "react-query";

import { useToursData } from "common/hooks/useToursData";
import {
  Table,
  Button,
  Form,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import { axios } from "common/utils/axios";
import { faTrash, faEye, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

const fetchMeetings = async (page) => {
  const { data } = await axios.get(
    `/api/customer-meetings/getPaginated?page=${page}`
  );
  return data;
};

export const CustomersMeetings = () => {
  const [showEditModal, setShowEditModal] = useState(false); // New state for modal visibility
  const [editMeetingData, setEditMeetingData] = useState(null);
  const [customersMeetingsData, setCustomersMeetingsData] = useState([]);
  const toursData = useToursData();
  const [filterText, setFilterText] = useState("");
  const customersData = useCustomerData();
  const [formData, setFormData] = useState({
    customer_id: "",
    Direction: "",
    MeetingDate: "",
    Note: "",
  });
  const [page, setPage] = useState(1); // Track the current page
  const { data, isLoading, isError, error } = useQuery(
    ["meetings", page],
    () => fetchMeetings(page),
    {
      keepPreviousData: true, // Keep previous data for seamless pagination
    }
  );
  const filteredItems = customersMeetingsData.filter(
    (item) =>
      item.customer_id &&
      item.customer_id
        .toString()
        .toLowerCase()
        .includes(filterText.toLowerCase())
  );
  const updateMeeting = async (meetingData) => {
    const { id, ...updateData } = meetingData;
    const response = await axios.put(
      `api/customers-meetings/${id}`,
      updateData
    );
    return response.data;
  };
  const mutation = useMutation(updateMeeting, {
    onSuccess: () => {
      toast.success("Toplantı başarıyla güncellendi");
      setShowEditModal(false);
      // Optionally invalidate or refetch queries to update the UI with the new data
      // queryClient.invalidateQueries('meetings');
    },
    onError: (error) => {
      toast.error(`Error updating meeting: ${error.message}`);
    },
  });

  const handleUpdateMeeting = () => {
    mutation.mutate({
      ...formData,
      id: editMeetingData.id,
    });
  };

  const handleDeleteMeeting = async (meetingId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this meeting?"
    );
    if (confirmDelete) {
      try {
        await axios.delete(`/api/customers/meetings/${meetingId}`);
        // Add logic to refresh the list or handle UI changes after successful deletion
        console.log("Meeting deleted successfully");
        toast.success("Toplantı başarıyla silindi");
      } catch (error) {
        toast.error("Toplantı silinirken hata oluştu");
        console.error("Error occurred while deleting the meeting:", error);
        // Handle error
      }
    }
  };

  const handleSearch = (e) => {
    setFilterText(e.target.value);
  };
  const handleEditMeeting = (meeting) => {
    setEditMeetingData(meeting);
    setFormData({
      customer_id: meeting.customer_id.toString(),
      Direction: meeting.Direction,
      MeetingDate: meeting.MeetingDate.split("T")[0], // Adjust according to your date format
      Note: meeting.Note,
    });
    setShowEditModal(true);
  };

  // Function to close the edit modal
  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };
  useEffect(() => {
    if (editMeetingData) {
      setFormData({
        customer_id: editMeetingData.customer_id.toString(),
        Direction: editMeetingData.Direction,
        customer_name: editMeetingData.customer_name,
        MeetingDate: editMeetingData.MeetingDate.split("T")[0], // Adjust according to your format
        Note: editMeetingData.Note,
      });
    }
  }, [editMeetingData]);
  console.log(data, "data");
  return (
    <Container fluid>
      <Row
        className="mb-4"
        style={{
          backgroundColor: "rgb(99, 102, 101)",
          padding: "10px",
          borderRadius: "8px",
          color: "white",
        }}
      >
        <Col md={6}>
          <h2>Görüşme Yönetimi</h2>
        </Col>
        <Col md={6} className="d-flex justify-content-end">
          <Link to={`/admin/customerManagement/CustomersMeetings/create`}>
            <Button
              style={{
                backgroundColor: "DodgerBlue",
                color: "#fff",
                fontWeight: "bold",
                marginBottom: "7px",
              }}
            >
              Yeni Görüşme
            </Button>
          </Link>
        </Col>
      </Row>
      {/* Meetings table and pagination controls */}
      <Row
        style={{
          padding: "20px",
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <h4>Sistemde kayıtlı görüşmeler listeleniyor</h4>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Müşteri</th>
              <th>Yön</th>
              <th>Görüşme Tarihi</th>
              <th>Not</th>
              <th>Eylem</th>
            </tr>
          </thead>
          <tbody>
            {data?.data.map((item, index) => (
              <tr key={index}>
                <td>{item.customer_name}</td>
                <td>{item.Direction}</td>
                <td>{item.MeetingDate}</td>
                <td>{item.Note}</td>
                <td>
                  <Link
                    to={`/admin/customerManagement/customers/${
                      item.customer_id
                    }/${item.customer_name.replace(/\s+/g, "-")}`}
                  >
                    <Button variant="success" className="mr-2">
                      <FontAwesomeIcon icon={faEye} />
                    </Button>
                  </Link>
                  <Button
                    variant="danger"
                    className="mr-2"
                    onClick={() => handleDeleteMeeting(item.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => handleEditMeeting(item)}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div>
          <Button
            variant="warning"
            className="mr-2"
            onClick={() => setPage((old) => Math.max(old - 1, 1))}
            disabled={page === 1}
          >
            Öncesi
          </Button>
          <Button
            onClick={() =>
              setPage((old) =>
                !data || !data.last_page || old === data.last_page
                  ? old
                  : old + 1
              )
            }
            disabled={!data || !data.last_page || page === data.last_page}
          >
            onraki
          </Button>
          <span>
            {" "}
            Sayfa {page} / {data?.last_page}
          </span>
        </div>
      </Row>
      <Modal show={showEditModal} onHide={handleCloseEditModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Toplantıyı Düzenle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Müşteri</Form.Label>
              <Form.Control
                as="select"
                value={formData.customer_id}
                onChange={(e) =>
                  setFormData({ ...formData, customer_id: e.target.value })
                }
              >
                <option>{formData.customer_name}</option>
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Yön</Form.Label>
              <Form.Control
                as="select"
                value={formData.Direction}
                onChange={(e) =>
                  setFormData({ ...formData, Direction: e.target.value })
                }
              >
                <option value="Online">Online</option>
                <option value="Offsite">Offsite</option>
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Tarih ve Saat</Form.Label>
              <Form.Control
                type="date"
                value={formData.MeetingDate}
                onChange={(e) =>
                  setFormData({ ...formData, MeetingDate: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Not</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={formData.Note}
                onChange={(e) =>
                  setFormData({ ...formData, Note: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditModal}>
            Kapat
          </Button>
          <Button variant="primary" onClick={handleUpdateMeeting}>
            Değişiklikleri Kaydet
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default CustomersMeetings;
