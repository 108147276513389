const ToggleButton = ({ name, isSelected, onClick }) => {
  const buttonStyle = {
    color: isSelected ? "white" : "#039",
    fontSize: "10px",
    backgroundColor: isSelected ? "#039" : "white",
    width: "35px",
    padding: "5px",
    border: "1px solid #039",
    cursor: "pointer",
  };

  return (
    <button style={buttonStyle} onClick={() => onClick(name)}>
      <span>{name}</span>
    </button>
  );
};
export default ToggleButton;
