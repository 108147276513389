import PrivateRoute from "./PrivateRoute";
import { GlobalRoutes } from "./GlobalRoutes";
import { AdminRoutes } from ".//AdminRoutes";
import { UserRoutes } from "./UserRoutes";
const authMap = (routes) =>
  routes.map((route) => {
    if (route.children) {
      route.children = authMap(route.children);
    }
    if (["user", "admin", "stuff", "manager"].includes(route.auth)) {
      return {
        ...route,
        element: <PrivateRoute who={route.auth}>{route.element}</PrivateRoute>,
      };
    }

    return route;
  });
const mappedAdminRoutes = authMap(AdminRoutes);
const mappedGlobalRoutes = authMap(GlobalRoutes);
const mappedUserRoutes = authMap(UserRoutes);
// const mappedStaffRoutes = authMap(adminRoutes);
const combinedRoutes = [
  ...mappedUserRoutes,
  ...mappedAdminRoutes,
  ...mappedGlobalRoutes,
];
export default combinedRoutes;
