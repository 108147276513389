import React, { useState } from "react";
import { axios } from "common/utils/axios";
import MapComponent from "./OtelsCreateModalComponents/MapComponent";
import ImageComponent from "./OtelsCreateModalComponents/ImageComponent";
import OtelDescComponents from "./OtelsCreateModalComponents/OtelDescComponents";
import OtelFeaaturesComponent from "./OtelsCreateModalComponents/OtelFeaaturesComponent";
import OtelFoodsComponent from "./OtelsCreateModalComponents/OtelFoodsComponent";
import OtelVideosComponent from "./OtelsCreateModalComponents/OtelVideosComponent";
import OtelHoneymoonComponent from "./OtelsCreateModalComponents/OtelHoneymoonComponent";
import OtelSliderComponent from "./OtelsCreateModalComponents/OtelSliderComponent";
import OtelOtherInformation from "./OtelsCreateModalComponents/OtelOtherInformation";
import OtelsRoomsComponent from "./OtelsCreateModalComponents/OtelsRoomsComponent";

export default function OtelsCreateModal({ setOpenModal, getOtels }) {
  const [editOtelImage, setOtelImage] = useState();
  const [editOtelDesc, setOtelDesc] = useState();
  const [editOtelName, setOtelName] = useState();
  const [editOtelDetailedDesc, setOtelDetailedDesc] = useState();
  const [editPositionX, setOtelPositionX] = useState();
  const [editPositionY, setOtelPositionY] = useState();
  const [otelFetaures, setOtelFetaures] = useState([]);
  const [otelFoodsTitle, setOtelFoodsTitle] = useState("");
  const [otelFoods, setOtelFoods] = useState("");
  const [video, setVideo] = useState();
  const [specialForHoneymoon, setSpecialForHoneymoon] = useState([]);
  const [slider, setSlider] = useState([]);
  const [otherInformations, setOtherInformations] = useState();
  const [rooms, setRooms] = useState([]);

  const handleSubmit = async () => {
    console.log(editOtelImage);
    const res = await axios.post("api/otel/store", {
      otel_name: editOtelName,
      otel_desc: editOtelDesc,
      otel_detailed_desc: editOtelDetailedDesc,
      cover_picture: editOtelImage,
      position: [editPositionX, editPositionY],
      otelFetaures: otelFetaures,
      otelFoodsTitle: otelFoodsTitle,
      otelFoods: otelFoods,
      video: video,
      specialForHoneymoon: specialForHoneymoon,
      slider: slider,
      otherInformations: otherInformations,
      rooms: rooms,
    });
    if (res) {
      console.log(res);
      getOtels();
      setOpenModal([]);
    }
  };
  return (
    <div>
      <div className="fixed z-10 w-screen h-full overlay-0 top-0 bottom-0 right-0 left-0 bg-black/[0.9]">
        <div className="sticky top-0 h-screen w-2/5 mx-auto grid">
          <div className=" sticky bg-white m-auto overflow-x-auto h-[80vh] p-5 bg-gray-50">
            <div>
              <div>
                <MapComponent
                  setOtelPositionX={setOtelPositionX}
                  setOtelPositionY={setOtelPositionY}
                />
                <ImageComponent
                  editOtelImage={editOtelImage}
                  setOtelImage={setOtelImage}
                />

                <OtelDescComponents
                  editOtelName={editOtelName}
                  editOtelDesc={editOtelDesc}
                  editOtelDetailedDesc={editOtelDetailedDesc}
                  setOtelDetailedDesc={setOtelDetailedDesc}
                  setOtelDesc={setOtelDesc}
                  setOtelName={setOtelName}
                />
                <OtelFeaaturesComponent
                  otelFetaures={otelFetaures}
                  setOtelFetaures={setOtelFetaures}
                />

                <OtelFoodsComponent
                  setOtelFoodsTitle={setOtelFoodsTitle}
                  setOtelFoods={setOtelFoods}
                  otelFoodsTitle={otelFoodsTitle}
                  otelFoods={otelFoods}
                />

                <OtelVideosComponent setVideo={setVideo} video={video} />

                <OtelHoneymoonComponent
                  setSpecialForHoneymoon={setSpecialForHoneymoon}
                  specialForHoneymoon={specialForHoneymoon}
                />

                <OtelSliderComponent setSlider={setSlider} slider={slider} />

                <OtelOtherInformation
                  setOtherInformations={setOtherInformations}
                  otherInformations={otherInformations}
                />

                <OtelsRoomsComponent setRooms={setRooms} rooms={rooms} />

                <div className="flex justify-end gap-2 mt-2">
                  <button
                    className="bg-red-300 text-white p-2"
                    onClick={() => setOpenModal([])}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-blue-300 text-white p-2"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Tamamla
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
