import React from "react";
import { Button, Modal } from "react-bootstrap";
import UserHelpp from "../UserHelpp";
function ReservationCancelModal({
  show,
  handleClose,
  handleStatusChange,
  userId,
}) {
  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Kullanıcı Yardımı</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ fontStyle: "12px" }}>
          Bu rezervasyonu iptal etmek için müdürle iletişime geçmeniz gerekecek.
        </p>
        <br />
        <p style={{ fontStyle: "12px" }}>
          İptal nedeni olarak açılır menüden "Reservasyon" seçeneğini seçin,
          ardından nedenini belirtin ve gönderin.
        </p>
        <br />
        <UserHelpp userId={userId} />
      </Modal.Body>
      <Modal.Footer>
        <Button active={true} variant="secondary" onClick={handleClose}>
          Kapat
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ReservationCancelModal;
