import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "../misc/Headings";
import { NavLink } from "react-router-dom";
import { PrimaryButton as PrimaryButtonBase } from "../misc/Buttons";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { axios } from "../../utils/axios";
import { getLastTour } from "common/Api/AdvertismentApi.js";
import { useQuery } from "react-query";
const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
  background-color: #006d77;
  // Position the buttons on the left and right of the slider on large screens
  @media (min-width: 1024px) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }
`;

const PrevButton = styled(ControlButton)`
  // Apply specific styles for smaller screens if needed

  @media (min-width: 1024px) {
    left: calc(4% - 40px); // Adjust as needed
  }
  @media (min-width: 1800px) {
    left: calc(15% - 40px); // Adjust as needed
  }
`;

const NextButton = styled(ControlButton)`
  // Apply specific styles for smaller screens if needed

  @media (min-width: 1024px) {
    right: calc(4% - 40px); // Adjust as needed
  }
  @media (min-width: 1800px) {
    right: calc(15% - 40px); // Adjust as needed
  }
`;

const CardSlider = styled(Slider)`
  ${tw`mt-4 lg:mt-12`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const Card = tw.div`h-full flex! flex-col sm:border max-w-sm sm:rounded sm:rounded relative focus:outline-none`;
const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-40 sm:h-56 md:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded`,
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
const Rating = tw.span`ml-2 font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const PrimaryButton = styled(PrimaryButtonBase)`
  ${tw`px-8 py-3 font-bold rounded text-gray-100 focus:shadow-outline focus:outline-none transition duration-300 mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded py-3 sm:py-6`}
  background-color: #006D77; // Custom background color
  &:hover {
    color: black;
    background-color: #83c5be; // Custom hover background color
  }
`;
const TumTurlarLink = styled(NavLink)`
  text-decoration: none;
  padding-left: "5px";

  }
`;
const TumTurlarLinkStyle = styled.span`
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #006d77;
  color: white !important;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: #83c5be;
    color: black !important;
  }
`;
export default () => {
  const [otels, setOtels] = useState();
  const [cards, setCards] = useState([]);
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        },
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  /* Change this according to your needs */
  // const getOtels = async () => {
  //   const response = await axios
  //     .get(`/api/otel`)
  //     .catch((err) => console.log("Error: ", err));

  //   if (response && response.data) {
  //     setOtels(response.data);
  //   } else {
  //     console.log(response);
  //   }
  // };

  // useEffect(() => {
  //   getOtels();
  // }, []);

  useEffect(() => {
    let newCards = [];
    otels?.otels.map((item, key) => {
      let card = {
        id: item.otel_id,
        imageSrc: item.cover_picture,
        title: item.otel_name,
        description: item.otel_desc,
        locationText:
          Number(item.x_axsis).toFixed(2) +
          "/" +
          Number(item.y_axsis).toFixed(2),
        pricingText: "USD 39/Day",
        rating: "4.8",
      };
      newCards = [...newCards, card];
    });
    setCards(newCards);
  }, [otels]);
  const Cardss = [
    {
      id: "1",
      title: "Umra",
      description: "Umra description",
      rating: "4.2",
      pricingText: "USD 39/Day",
      locationText: "Meke",
      imageSrc: "https://www.bon-cadeau.ru/mdata/gallery/size3/8469/16293.jpg",
    },
    {
      id: "2",
      title: "Hac",
      description: "Umra description",
      rating: "4.2",
      pricingText: "USD 39/Day",
      locationText: "Meke",
      imageSrc: "https://www.bon-cadeau.ru/mdata/gallery/size3/8469/16293.jpg",
    },
    {
      id: "3",
      title: "Tour",
      description: "Umra description",
      rating: "4.2",
      pricingText: "USD 39/Day",
      locationText: "Meke",
      imageSrc: "https://www.bon-cadeau.ru/mdata/gallery/size3/8469/16293.jpg",
    },
    {
      id: "4",
      title: "Some where",
      description: "Umra description",
      rating: "4.2",
      pricingText: "USD 39/Day",
      imageSrc: "https://www.bon-cadeau.ru/mdata/gallery/size3/8469/16293.jpg",
    },
    {
      id: "5",
      title: "Valahala",
      description: "Umra description",
      rating: "4.2",
      pricingText: "USD 39/Day",
      imageSrc: "https://www.bon-cadeau.ru/mdata/gallery/size3/8469/16293.jpg",
    },
  ];
  const { data, isLoading, isError } = useQuery(["LastesToursHomePage"], () =>
    getLastTour()
  );
  const getSmallestPrice = (prices) => {
    if (!prices || prices.length === 0) {
      return { price: "N/A", type: "" };
    }

    let smallestPrice = prices[0];
    for (let i = 1; i < prices.length; i++) {
      if (prices[i].mainPrice < smallestPrice.mainPrice) {
        smallestPrice = prices[i];
      }
    }
    return { price: smallestPrice.mainPrice, type: smallestPrice.pricingType };
  };

  const tourData = data?.data;
  console.log(tourData, "datadatadatadatadatadata");
  return (
    <Container>
      <Content>
        <HeadingWithControl>
          <Heading>Popüler Turlar</Heading>
          <Controls>
            <PrevButton onClick={sliderRef?.slickPrev}>
              <ChevronLeftIcon />
            </PrevButton>
            <NextButton onClick={sliderRef?.slickNext}>
              <ChevronRightIcon />
            </NextButton>
          </Controls>
          <div style={{ flex: 1 }}></div>{" "}
          {/* Add an empty flex div to push "Tum Turlar" to the right */}
          <TumTurlarLink to="/user/tours">
            <TumTurlarLinkStyle>Tüm Turlar</TumTurlarLinkStyle>
          </TumTurlarLink>
        </HeadingWithControl>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
          {tourData &&
            tourData?.map((card, index) => {
              const mainPhoto = card.photos.find(
                (photo) => photo.photo_type === "main"
              );
              const { price, type } = getSmallestPrice(card.prices);
              const firstImageSrc = mainPhoto
                ? mainPhoto.file_path
                : card.photos && card.photos.length > 0
                ? card.photos[0].file_path
                : "https://sun9-45.userapi.com/impg/eWcUucj0ZNxLaMk1fHP_IvZVQQngq82aUpQAPg/cJf0DG6LTjI.jpg?size=1280x1280&quality=95&sign=e345cac7746f599cf51c572fbaac6204&c_uniq_tag=NcUsRuf41JTuLEN40k3-AUDaem653-ccxUdatKFaiVw&type=album";

              return (
                <Card key={index}>
                  <CardImage imageSrc={firstImageSrc} />
                  <TextInfo>
                    <TitleReviewContainer>
                      <tourName>{card.tourName}</tourName>
                      <RatingsInfo>
                        <StarIcon />
                        <Rating>{card.rating || 4.2}</Rating>
                      </RatingsInfo>
                    </TitleReviewContainer>
                    <SecondaryInfoContainer>
                      <IconWithText>
                        <IconContainer>
                          <LocationIcon />
                        </IconContainer>
                        <Text>{card.returnCity}</Text>
                      </IconWithText>
                      <IconWithText>
                        <IconContainer>
                          <PriceIcon />
                        </IconContainer>
                        <Text>{`${price} (${type})`}</Text>
                      </IconWithText>
                    </SecondaryInfoContainer>
                    <Description>{card.category}</Description>
                  </TextInfo>
                  <PrimaryButton>
                    <NavLink to={`/user/tours/${card.category}/${card.slug}`}>
                      Şimdi Gör{" "}
                    </NavLink>
                  </PrimaryButton>
                </Card>
              );
            })}
        </CardSlider>
      </Content>
    </Container>
  );
};
