import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

export const HotelDetails = ({ updateFormData, formData }) => {
  const [localFormData, setLocalFormData] = useState({
    otel_name: "",
    otel_stars: "",
    otel_description: "",
    otel_details: {
      wifi: true,
      parking: false,
      food: false,
      first_aid_kit: false,
      microwave: false,
      heating: false,
      kitchen: false,
      cooling: false,
      pool: false,
      // Add other default details as needed
    },
  });

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    const parsedValue = type === "radio" ? parseInt(value, 10) : value;

    const updatedFormData = { ...localFormData, [name]: parsedValue };
    setLocalFormData(updatedFormData);
    updateFormData(updatedFormData);
  };

  const handleDetailChange = (e) => {
    const { name, checked } = e.target;
    const updatedDetails = { ...localFormData.otel_details, [name]: checked };

    const updatedFormData = { ...localFormData, otel_details: updatedDetails };
    setLocalFormData(updatedFormData);
    updateFormData(updatedFormData);
  };

  useEffect(() => {
    if (formData) {
      setLocalFormData((prevState) => ({
        ...prevState,
        ...formData,
        otel_details: { ...prevState.otel_details, ...formData.otel_details },
      }));
    }
  }, [formData]);
  return (
    <div>
      {/* Hotel Name */}
      <Form.Group className="mb-3">
        <Form.Label>Otel Adı</Form.Label>
        <Form.Control
          type="text"
          placeholder="Otel Adı"
          name="otel_name"
          value={localFormData.otel_name || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      {/* Hotel Stars */}
      <Form.Group className="mb-3">
        <Form.Label>Otel Yıldızları</Form.Label>
        <div>
          {[1, 2, 3, 4, 5, 6, 7].map((star) => (
            <Form.Check
              inline
              key={`hotel-star-${star}`}
              label={`${star} Yıldız${star > 1 ? "lar" : ""}`}
              name="otel_stars"
              type="radio"
              id={`hotel-star-${star}`}
              value={star}
              checked={localFormData.otel_stars === star}
              onChange={handleInputChange}
            />
          ))}
        </div>
      </Form.Group>

      {/* Hotel Description */}
      <Form.Group className="mb-3">
        <Form.Label>Otel Açıklaması</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Otel Açıklaması"
          name="otel_description"
          value={localFormData.otel_description || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      {/* Hotel Details Section */}
      <Form.Group className="mb-3">
        <h5>Otel Detayları</h5>
        <div>
          {Object.entries(localFormData.otel_details).map(([key, value]) => (
            <Form.Check
              inline
              key={key}
              label={
                key.charAt(0).toUpperCase() + key.slice(1).replace("_", " ")
              }
              name={key}
              type="checkbox"
              checked={value}
              onChange={handleDetailChange}
            />
          ))}
        </div>
      </Form.Group>
    </div>
  );
};

export default HotelDetails;
