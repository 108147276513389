import { useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
const FoodDetails = [
  {
    KindOf: "Sabah",
    FoodList: "Kavlati,Tatli,Cay",
  },
  {
    KindOf: "Aksam",
    FoodList: "Kofte , Tatli, Cay",
  },
  {
    KindOf: "Oglen",
    FoodList: "Cay",
  },
];
export const Food = ({ tourDetails }) => {
  return (
    <Row>
      {tourDetails.food_options.map((FoodDetail) => (
        <Col xs={12} xl={4} className="mb-2">
          <Card>
            <Card.Body className="d-flex justify-content-between">
              <span>{FoodDetail.foodOptionType}</span>
              <span>{FoodDetail.foodOptionName}</span>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};
export default Food;
