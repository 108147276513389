import { useState } from "react";
import { Card, Form } from "react-bootstrap";
import { CardHeader } from "../CRUD/CardHeader";
import Quill from "react-quill";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "common/Api/QaApi";
import { ToastContainer, toast } from "react-toastify";
import IndexQA from "./IndexQA";
export const CreateQA = () => {
  const queryClient = useQueryClient();
  const { data, isLoading, isError } = useQuery("questions", api.getQuestions, {
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const [formData, setFormData] = useState({ question: "", answer: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleQuillChange = (content) => {
    setFormData((prev) => ({ ...prev, answer: content }));
  };

  const mutationCreate = useMutation(api.createQuestion, {
    onSuccess: () => {
      queryClient.invalidateQueries("questions");
      toast.success("Question added successfully!");
      setFormData({ question: "", answer: "" });
      localStorage.setItem("shouldRefetchQA", "true");
    },
    onError: (error) => {
      const errorMsg =
        error?.response?.data?.message || "Error adding question";
      toast.error(errorMsg);
    },
  });
  const deleteQuestionMutation = useMutation(api.deleteQuestion, {
    onSuccess: () => {
      queryClient.invalidateQueries("questions");
      toast.success("Question deleted successfully!");
      localStorage.setItem("shouldRefetchQA", "true");
    },
    onError: (error) => {
      const errorMsg =
        error?.response?.data?.message || "Error deleting question.";
      toast.error(errorMsg);
    },
  });
  const editQuestionMutation = useMutation(api.updateQuestion, {
    onSuccess: () => {
      queryClient.invalidateQueries("questions");
      toast.success("Question Update successfully!");
      localStorage.setItem("shouldRefetchQA", "true");
    },
    onError: (error) => {
      const errorMsg =
        error?.response?.data?.message || "Error Update question.";
      toast.error(errorMsg);
    },
  });
  if (isLoading) return "Loading...";
  if (isError) return "An error occurred";

  return (
    <div>
      <Card>
        <CardHeader Title={"SSS"} />
        <Card.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              mutationCreate.mutate(formData);
            }}
          >
            <Form.Group className="mb-3">
              <Form.Label>Soru</Form.Label>
              <Form.Control
                type="text"
                name="question"
                value={formData.question}
                onChange={handleChange}
                placeholder="Başlık giriniz"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Cevap</Form.Label>
              <Quill value={formData.answer} onChange={handleQuillChange} />
            </Form.Group>
            <Form.Group className="mb-3 text-end">
              <button type="submit" className="btn btn-primary">
                Ekle
              </button>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
      <IndexQA
        data={data}
        deleteQuestionMutation={deleteQuestionMutation}
        editQuestionMutation={editQuestionMutation}
      />
      <ToastContainer />
    </div>
  );
};
export default CreateQA;
