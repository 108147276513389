import { useState, useEffect } from "react";
import { ListGroup } from "react-bootstrap";
import classNames from "classnames";
import HotelDetails from "pages/NewTour/Components/HotelDetails";

export const Accomodation = ({ tourDetails }) => {
  const [activeSection, setActiveSection] = useState(1);
  const [sectionTitles, setSectionTitles] = useState([]);

  useEffect(() => {
    if (tourDetails && tourDetails.accomodations) {
      const hotelNames = tourDetails.accomodations.map(
        (accomodation) => accomodation.hotel && accomodation.hotel.otel_name
      );
      setSectionTitles(hotelNames);
    }
  }, [tourDetails]);

  return (
    <div>
      <ul>
        <li>
          <ListGroup className="blurred-box-shadow list-group-horizontal">
            {sectionTitles &&
              sectionTitles.map((title, index) => (
                <ListGroup.Item
                  key={index}
                  className={`f12 ${classNames({
                    "active-list-group-item": activeSection === index + 1,
                  })}`}
                  action
                  active={activeSection === index + 1}
                  onClick={() => setActiveSection(index + 1)}
                >
                  {title}
                </ListGroup.Item>
              ))}
          </ListGroup>
        </li>
        <li>
          {tourDetails?.accomodations &&
            tourDetails?.accomodations?.map((accomodation, index) => (
              <div
                key={index}
                style={{
                  height: "350px",
                  display: activeSection === index + 1 ? "block" : "none",
                }}
              >
                <HotelDetails accomodation={accomodation} />
              </div>
            ))}
        </li>
      </ul>
    </div>
  );
};

export default Accomodation;
