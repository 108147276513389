import React from "react";

export const WorldCard = () => {
  return (
    <div>
      <form>
        <div class="row form-group">
          <label class="control-label col-sm-4 col-md-4">Kart</label>
          <div class="col-sm-3 col-md-2">World Card</div>
        </div>
        <div class="row form-group">
          <label class="control-label col-sm-4 col-md-4">Toplam Ücret</label>
          <div class="col-sm-8 col-md-8">
            <span class="toplam-ucret" data-price="4000.00" data-currency="$">
              4,000.00 $
            </span>
            (75,891.20 TL)
          </div>
        </div>
        <hr />
        <div class="row form-group">
          <label class="control-label col-sm-4 col-md-4">Ad Soyad</label>
          <div class="col-sm-8 col-md-7 pt0 pb0">
            <input
              type="text"
              name="name"
              value=""
              required=""
              class="form-control"
            />
          </div>
        </div>
        <div class="row form-group">
          <label class="control-label col-sm-4 col-md-4">Taksit</label>
          <div class="col-sm-4 col-md-3 pt0 pb0">
            <select
              name="taksit"
              class="form-control installment-field"
              required=""
            >
              <option value="1" data-newprice="75,891.20">
                Tek Çekim
              </option>

              <option value="2" data-newprice="75,891.20">
                2 Taksit
              </option>

              <option value="3" data-newprice="75,891.20">
                3 Taksit
              </option>

              <option value="4" data-newprice="75,891.20">
                4 Taksit
              </option>

              <option value="5" data-newprice="80,960.73">
                5 Taksit
              </option>

              <option value="6" data-newprice="81,651.34">
                6 Taksit
              </option>
            </select>
          </div>
        </div>
        <div class="row form-group">
          <label class="control-label col-sm-4 col-md-4">Kart Numarası</label>
          <div class="col-sm-8 col-md-7 pt0 pb0">
            <input
              type="text"
              name="kartno"
              value=""
              class="form-control"
              required=""
              data-inputmask="'mask': '9999 9999 9999 9999'"
              data-mask=""
            />
          </div>
        </div>
        <div class="row form-group">
          <label class="control-label col-sm-4 col-md-4">
            Son Kullanma Tarihi
          </label>
          <div class="col-sm-2 col-md-2 pt0 pb0">
            <select name="skt1" class="form-control">
              <option value="01">01</option>
              <option value="02">02</option>
              <option value="03">03</option>
              <option value="04">04</option>
              <option value="05">05</option>
              <option value="06">06</option>
              <option value="07">07</option>
              <option value="08">08</option>
              <option value="09">09</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>
          </div>
          <div class="col-sm-1 sep-ozel">/</div>
          <div class="col-sm-2 col-md-2 pt0 pb0">
            <select name="skt2" class="form-control">
              <option value="2023">23</option>
              <option value="2024">24</option>
              <option value="2025">25</option>
              <option value="2026">26</option>
              <option value="2027">27</option>
              <option value="2028">28</option>
              <option value="2029">29</option>
              <option value="2030">30</option>
              <option value="2031">31</option>
              <option value="2032">32</option>
              <option value="2033">33</option>
              <option value="2034">34</option>
              <option value="2035">35</option>
              <option value="2036">36</option>
              <option value="2037">37</option>
              <option value="2038">38</option>
              <option value="2039">39</option>
              <option value="2040">40</option>
              <option value="2041">41</option>
              <option value="2042">42</option>
            </select>
          </div>
        </div>
        <div class="row form-group">
          <label class="control-label col-sm-4 col-md-4">CVV2</label>
          <div class="sol-sm-3 col-md-2 pt0 pb0">
            <input
              type="text"
              name="cv2"
              value=""
              class="form-control"
              required=""
              data-inputmask="'mask': '999'"
              data-mask=""
            />
          </div>
        </div>
        <div class="row form-group">
          <label class="control-label col-sm-4 col-md-4">&nbsp;</label>
          <div class="sol-sm-8 col-md-7 pt0 pb0">
            <input type="checkbox" name="onay" value="1" required="" />{" "}
            <a
              href="https://www.gezintitatil.com/reservation/contract/R1678783171641032c37085d"
              class="modal-link"
              data-size="lg"
              data-width="60%"
              data-padding="5"
            >
              Mesafeli satış sözleşmesini okudum, kabul ediyorum.
            </a>
          </div>
        </div>
        <div class="form-group aligncenter">
          <button type="submit" class="btn btn-primary btn-sm">
            <span class="glyphicon glyphicon-forward"></span> Ödeme Yap
          </button>
        </div>
        <input
          type="hidden"
          name="reservation_code"
          value="R1678783171641032c37085d"
        />
        <input type="hidden" name="card_id" value="2" />
        <input
          type="hidden"
          name="total_price"
          class="total_price_holder"
          value="75891.20"
        />
      </form>
    </div>
  );
};
