import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { axios } from "common/utils/axios";
import { useParams } from "react-router-dom";
import ButtonGroup from "@mui/material/ButtonGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

export const PopUpEditFromWhereYouHeardAboutUS = ({
  onUpdateData,
  customerData,
}) => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [heardAboutUs, setHeardAboutUs] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(
    customerData?.map((data) => data.id) || []
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    axios
      .get("/api/heard")
      .then((response) => {
        setHeardAboutUs(response.data);
        // Update selectedOptions if customerData changes
        setSelectedOptions(customerData?.map((data) => data.id) || []);
      })
      .catch((error) => console.error(error));
  }, [customerData]);

  const handleCheckboxChange = (event) => {
    const value = Number(event.target.value);
    if (event.target.checked) {
      setSelectedOptions((prev) => [...new Set([...prev, value])]);
    } else {
      setSelectedOptions((prev) =>
        prev.filter((optionId) => optionId !== value)
      );
    }
  };

  const handleSave = async () => {
    try {
      const response = await axios.post(`/api/heard-customer`, {
        data: {
          customer_id: id,
          FromWhereHeardAboutUsId: selectedOptions,
        },
      });
      handleClose();
      toast.success("Request Success");
      onUpdateData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div style={{ float: "right" }}>
      <ButtonGroup>
        <Button
          onClick={handleClickOpen}
          variant="info"
          style={{
            background: "#d39e00",
            fontSize: "20x",
            height: "28px",
            width: "24px",
            padding: "2px",
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      </ButtonGroup>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>BİZİ NEREDEN BULDUNUZ?</DialogTitle>
        <DialogContent>
          <div className="row mb-4 d-flex">
            {heardAboutUs.map((item) => (
              <div key={item.id}>
                <input
                  style={{ marginRight: "15px" }}
                  type="checkbox"
                  value={item.id}
                  onChange={handleCheckboxChange}
                  checked={selectedOptions.includes(item.id)}
                />
                <label style={{ fontSize: "20px" }}>
                  {item.from_where_you_heard_about_us}
                </label>
              </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Kapat</Button>
          <Button onClick={handleSave}>Kaydet</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PopUpEditFromWhereYouHeardAboutUS;
