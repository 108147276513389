import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import SelectInput from "./SelectInput";

const ActiveCustomerPopUp = ({
  open,
  setOpen,
  selectedOption,
  handleOptionChange,
  handleEditeIsActive,
}) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Müsterinin dürümü Düzenleniyor</DialogTitle>
      <DialogContent>
        <div className="row mb-4 d-flex">
          <SelectInput
            name="isactive"
            className="flex-grow-1"
            label={"Müsterinin dürümü Düzenleniyor"}
            options={["active", "passive"]}
            keyName="province_name"
            value={selectedOption}
            onChange={handleOptionChange}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
          }}
        >
          Kapat
        </Button>

        <Button onClick={() => handleEditeIsActive(selectedOption)}>
          Kaydet
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActiveCustomerPopUp;
