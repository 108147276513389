import React, { useState } from "react";
import { axios } from "common/utils/axios";
import { Button, Form } from "react-bootstrap";

const CreateRole = () => {
  const [roleName, setRoleName] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/auth/createRole", {
        name: roleName,
      });
      alert("Role created successfully: " + response.data.role.name);
      setRoleName("");
    } catch (error) {
      alert("An error occurred: " + error.message);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="roleName">
        <Form.Label>Role Name</Form.Label>
        <Form.Control
          type="text"
          value={roleName}
          onChange={(e) => setRoleName(e.target.value)}
          placeholder="Enter role name"
        />
      </Form.Group>
      <Button active={true} variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
};

export default CreateRole;
