import React, { useState, useEffect } from "react";
import SelectInput from "../Components/SelectInput";
import TextInput from "../Components/TextInput";
import DateInput from "../Components/DateInput";
import { axios } from "common/utils/axios";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

export const CreateCustomerMeetings = () => {
  const { id } = useParams();
  const [customerMeetings, setcustomerMeetings] = useState({
    data: { meeting_date: "", direction: "", note: "" },
  });
  const [meetingsDirection, setMeetingsDirection] = useState([]);
  useEffect(() => {
    axios
      .get("/api/diraction")
      .then((response) => setMeetingsDirection(response.data))
      .catch((error) => console.error(error));
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setcustomerMeetings({
      ...customerMeetings,
      data: {
        ...customerMeetings.data,
        [name]: value,
      },
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      customer_id: id,
      Direction: customerMeetings.data.direction,
      MeetingDate: customerMeetings.data.meeting_date,
      Note: customerMeetings.data.note,
    };

    axios
      .post(`/api/customers/meeting`, payload)
      .then((res) => {
        toast.success("Meeting created successfully!");
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          // Display each error in its own toast.
          for (let key in error.response.data.errors) {
            toast.error(`${key}: ${error.response.data.errors[key]}`);
          }
        } else {
          toast.error("An unknown error occurred. Please try again.");
        }
      });
  };

  return (
    <div>
      <ToastContainer />
      <Form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-6 mt-4">
            <SelectInput
              name="direction"
              value={customerMeetings.data.direction}
              onChange={handleChange}
              label={"yön"}
              options={meetingsDirection.map((item) => ({
                label: item.name,
                value: item.name,
              }))}
            />
          </div>
          <div className=" col-6">
            Tarihi Ve Saat
            <DateInput
              name="meeting_date"
              value={customerMeetings.data.meeting_date}
              onChange={handleChange}
            />
          </div>
          <div className=" col-12 mt-4">
            <TextInput
              name="note"
              value={customerMeetings.data.note}
              label="Note"
              onChange={handleChange}
            />
          </div>
          <div className=" col-12">
            <Button type="submit" className="m-4">
              Ekle
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};
export default CreateCustomerMeetings;
