import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useQuery } from "react-query";
import * as api from "common/Api/BlogApi";
import Header from "common/components/hero/SliderUmageHeroAboutUs.js";
import RenderHTMLContent from "common/helpers/RenderHTMLContent";
import { HeaderHeightContext } from "layouts/UserGlobalLayout";
import "assets/styles/BlogCards.css";

export const UserPostPage = () => {
  const { slug } = useParams();
  const {
    data: post,
    isLoading,
    isError,
  } = useQuery(["post", slug], () => api.getPostBySlug(slug));

  const {
    setHeaderHeight,
    setDisplay,
    setHeaderTitlePaddingTop,
    setHeaderTitlePaddingBottom,
    setHeaderPaddingTop,
  } = useContext(HeaderHeightContext);

  // Set header display properties on mount and reset on unmount
  useEffect(() => {
    setDisplay("none");
    setHeaderHeight("0px");
    setHeaderTitlePaddingTop("0px");
    setHeaderTitlePaddingBottom("0px");

    return () => {
      setHeaderHeight(null);
      setDisplay(null);
      setHeaderPaddingTop(null);
      setHeaderTitlePaddingBottom(null);
      setHeaderTitlePaddingTop(null);
    };
  }, [
    setDisplay,
    setHeaderHeight,
    setHeaderPaddingTop,
    setHeaderTitlePaddingBottom,
    setHeaderTitlePaddingTop,
  ]);

  // Conditional rendering for loading and error states
  if (isLoading) return <div>Loading...</div>;
  if (isError || !post) return <div>Error loading the post.</div>;

  return (
    <>
      <Container className="mt-4">
        <Row>
          <Col xs={12} className="mb-4">
            <h1>{post.title}</h1>
            <RenderHTMLContent htmlContent={post.text} />
            <small className="text-muted">{post.published_at}</small>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserPostPage;
