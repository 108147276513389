import { Col, Row, Form, Modal, Button } from "react-bootstrap";

import RadioInput from "area/admin/CustomerMangement/ServiceCustomersComponents/Components/RadioInput";
import UploadFileInput from "area/admin/CustomerMangement/ServiceCustomersComponents/Components/uploadFileInput";
const familyRelationships = ["Kardeş", "Anne", "Baba", "Diğer"];
export const FamilyModalCreate = ({
  createFamilyMemberModal,
  handleCloseCreateFamilyDialog,
  handleSubmit,
  customerFamily,
  handleChange,
  handleFileChange,
}) => {
  return (
    <Modal
      centered
      show={createFamilyMemberModal}
      onHide={handleCloseCreateFamilyDialog}
      size="lg"
      style={{ overflowY: "auto" }}
    >
      <Modal.Header closeButton>
        <Modal.Title>AİLE BİLGİLERİ Ekle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row className="p-2">
            <Col md={4}>
              {/* Assuming TextInput, SelectInput, DateInput, RadioInput, and UploadFileInput are your custom components */}
              <Form.Group controlId="FirstName">
                <Form.Label>Adı</Form.Label>
                <Form.Control
                  type=""
                  name="FirstName"
                  value={customerFamily.data.FirstName}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="LastName">
                <Form.Label>SoyAdı</Form.Label>
                <Form.Control
                  type=""
                  name="LastName"
                  value={customerFamily.data.LastName}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              {/* You might need to adjust SelectInput to use React-Bootstrap's Form.Select */}
              <Form.Group controlId="RelativeRelation">
                <Form.Label>İlişki</Form.Label>
                <Form.Select
                  name="RelativeRelation"
                  value={customerFamily.data.RelativeRelation}
                  onChange={handleChange}
                >
                  <option hidden value="">
                    -- İlişki Seç --
                  </option>
                  {familyRelationships.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group controlId="Jop">
                <Form.Label>Meslek</Form.Label>
                <Form.Control
                  type=""
                  name="Jop"
                  value={customerFamily.data.Jop}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12}>
              {/* Assuming RadioInput is a custom component for handling radio inputs */}
              <RadioInput
                name="Gender"
                value={customerFamily.data.Gender}
                onChange={handleChange}
              />
            </Col>
            <Col md={4}>
              <Form.Group controlId="BirthDay">
                <Form.Label>Doğum Tarihi</Form.Label>
                <Form.Control
                  type="date"
                  name="BirthDay"
                  value={customerFamily.data.BirthDay}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="Eposta">
                <Form.Label>E-posta</Form.Label>
                <Form.Control
                  type="email"
                  name="Eposta"
                  value={customerFamily.data.Eposta}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="Telefon">
                <Form.Label>Telefon</Form.Label>
                <Form.Control
                  type=""
                  name="Telefon"
                  value={customerFamily.data.Telefon}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} className="mb-4">
              <Form.Group controlId="explantion">
                <Form.Label>Açıklama</Form.Label>
                <Form.Control
                  as="textarea"
                  name="explantion"
                  value={customerFamily.data.explantion}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12}>
              <UploadFileInput name="photo" onChange={handleFileChange} />
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => handleSubmit(e)}
          type="submit"
          style={{
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          Ekle
        </Button>
        <Button variant="secondary" onClick={handleCloseCreateFamilyDialog}>
          Kapat
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default FamilyModalCreate;
