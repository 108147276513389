import React from "react";
import { FormGroup, FormLabel, FormControl } from "react-bootstrap";

const DateInputBootsrap = ({ label, type, value, onChange, customStyle }) => {
  return (
    <FormGroup>
      <FormLabel>{label}</FormLabel>
      <FormControl type={type} value={value} onChange={onChange} />
    </FormGroup>
  );
};

export default DateInputBootsrap;
