import { axios } from "../axios";

export default function getOtels(id = null) {
  return axios
    .get("api/otel", {
      params: {
        id: id,
      },
    })
    .then((res) => {
      let postsArr = [];
      res.data.otels.map((item) => {
        let post = {
          id: item.otel_id,
          imageSrc: item.cover_picture,
          category: "Travel Tips",
          date: "April 21, 2020",
          title: "Safely Travel in Foreign Countries",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          url: "https://timerse.com",
        };

        return (postsArr = [...postsArr, post]);
      });
      return postsArr;
    });
}
