import { useState, useEffect } from "react";
import { Card, Form, Col, Row, Button } from "react-bootstrap";
import { CardHeader } from "../CRUD/CardHeader";
import Quill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { axios } from "common/utils/axios";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
export const CreatAboutUs = () => {
  const [data, setData] = useState({
    title: "",
    short_description: "",
    background_image: "",
    content: "",
  });
  const [imageSrc, setImageSrc] = useState(null);
  const [quillValue, setQuillValue] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetching the whole Data
        const response = await axios.get("/api/about-us");
        setData(response?.data[0]); // accessing the first object in the array
        setQuillValue(response.data[0]?.content); // accessing the content of the first object in the array

        // Fetching the image
        const imagePath = response.data[0].background_image; // assuming background_image contains the filename or relative path
        console.log(imagePath, "imagePath");
        setImageSrc(
          `https://backend.suarelab.com/api/about-us/images/${imagePath}`
        );
        console.log("Veri başarıyla güncellendi.", response.data);
      } catch (error) {
        console.error("Veri güncelleme hatası.", error);
        toast.error(
          `Veri güncelleme hatası.: ${
            error.response?.data?.message || error.message
          }`
        );
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setData((prevData) => ({ ...prevData, content: quillValue }));
  }, [quillValue]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleFileChange = (e) => {
    setData({ ...data, background_image: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let background_image_base64;

      if (data.background_image instanceof Blob) {
        // Convert file to base64 string
        background_image_base64 = await toBase64(data.background_image);
      }

      // Construct JSON object with all the data
      const jsonData = {
        title: data.title,
        short_description: data.short_description,
        background_image: background_image_base64,
        content: data.content,
      };

      // Send JSON object using a PUT request
      const response = await axios.put("/api/about-us/1", jsonData);
      console.log("Data updated successfully", response.data);
    } catch (error) {
      console.error("Error updating the data", error);
    }
  };

  // Helper function to convert file to base64
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });

  return (
    <div>
      <Card style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)" }}>
        <Card.Header
          style={{
            backgroundColor: "#636665",
            color: "white",
            borderRadius: "5px 5px 0 0",
          }}
        >
          <Row>
            <Col>
              <h1 style={{ padding: "10px", fontSize: "24px", margin: 0 }}>
                Hakımızda
              </h1>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Row>
                <Col>
                  <Link to="/about-us">
                    <Button style={{ color: "white" }} variant="success">
                      Hakkımızda Sayfasına Git
                    </Button>{" "}
                    /{" "}
                  </Link>
                  <Link to="/admin/about-us/index">
                    <Button
                      style={{ color: "white" }}
                      variant="outline-warning"
                    >
                      Geri
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Başlık</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={data?.title}
                onChange={handleChange}
                placeholder="Başlık giriniz"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Kısa Açıklama</Form.Label>
              <Form.Control
                type="text"
                name="short_description"
                value={data?.short_description}
                onChange={handleChange}
                placeholder="Kısa Açıklama giriniz"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Hakkımızda</Form.Label>
              <Quill value={quillValue} onChange={setQuillValue} />
            </Form.Group>
            <Form.Group className="mb-3 row">
              <div className="col-md-6 d-flex align-items-center justify-content-center">
                <Form.Label className="me-3">Arka Planı</Form.Label>
                <Form.Control
                  type="file"
                  name="background_image"
                  onChange={handleFileChange}
                />
              </div>
              <div className="col-md-6 d-flex align-items-center justify-content-center">
                {imageSrc && (
                  <img
                    alt=""
                    src={imageSrc}
                    alt="About Us Background"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                )}
              </div>
            </Form.Group>
            <Form.Group className="mb-3 text-end">
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
      <ToastContainer />
    </div>
  );
};
export default CreatAboutUs;
