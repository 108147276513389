import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

export const HotelRooms = ({ formData, updateFormData, otelData }) => {
  console.log(formData, "formData");
  // Local state for the new room type
  const [newRoomType, setNewRoomType] = useState({
    room_type_name: "",
    food_type: "",
  });

  // Function to handle adding a new room
  const addRoom = () => {
    if (newRoomType.room_type_name || newRoomType.food_type) {
      const validRoomTypes = formData.room_types.filter(
        (roomType) => roomType && roomType.room_type_name && roomType.food_type
      );
      updateFormData({
        ...formData,
        room_types: [...validRoomTypes, newRoomType],
      });
      setNewRoomType({ room_type_name: "", food_type: "" }); // Reset new room type
    } else {
      toast.warning("Eksik data");
      console.log("Please enter at least one detail for the room type.");
    }
  };

  // Function to handle updating room details
  const updateRoom = (index, key, value) => {
    const updatedRooms = formData.room_types.map((room, i) => {
      if (i === index) {
        return { ...room, [key]: value };
      }
      return room;
    });
    updateFormData({
      ...formData,
      room_types: updatedRooms,
    });
  };
  const deleteRoom = (index) => {
    const updatedRooms = formData.room_types.filter((_, i) => i !== index);
    updateFormData({
      ...formData,
      room_types: updatedRooms,
    });
  };
  React.useEffect(() => {
    if (otelData) {
      updateFormData({ ...otelData });
    }
  }, [otelData, updateFormData]);
  return (
    <div>
      <Form>
        {/* Separate row for adding a new room type */}
        <Row className="mb-3">
          <Col xl={5}>
            <Form.Group>
              <Form.Label>Oda Türü Adı</Form.Label>
              <Form.Control
                type="text"
                placeholder="Oda Türü Adı"
                value={newRoomType.room_type_name}
                onChange={(e) =>
                  setNewRoomType({
                    ...newRoomType,
                    room_type_name: e.target.value,
                  })
                }
              />
            </Form.Group>
          </Col>

          <Col xl={3}>
            <Form.Group>
              <Form.Label>Yemek Türü</Form.Label>
              <Form.Select
                value={newRoomType.food_type}
                onChange={(e) =>
                  setNewRoomType({ ...newRoomType, food_type: e.target.value })
                }
              >
                <option>Seçmek...</option>
                <option value="Vegetarian">Vejetaryen</option>
                <option value="Non-Vegetarian">Et Yiyen</option>
                <option value="Vegan">Vegan</option>
              </Form.Select>
            </Form.Group>
          </Col>

          <Col xl={2} className="d-flex align-items-end">
            <Button variant="primary" onClick={addRoom}>
              <FontAwesomeIcon icon={faPlus} /> Oda Türü Ekle
            </Button>
          </Col>
        </Row>

        {/* Rows for existing room types */}
        {formData.room_types.map((roomType, index) => (
          <Row className="mb-3" key={`room-type-${index}`}>
            <Col xl={5}>{roomType.room_type_name}</Col>
            <Col xl={3}>{roomType.food_type}</Col>
            {roomType.room_type_name && ( // Render the delete button only if roomName is not empty
              <Col xl={3} className="d-flex align-items-end">
                <Button variant="danger" onClick={() => deleteRoom(index)}>
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Col>
            )}
          </Row>
        ))}
      </Form>
    </div>
  );
};

export default HotelRooms;
