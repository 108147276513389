import { Button, Modal, Row, Col } from "react-bootstrap";
import HotelImagesSlider from "area/admin/Hotels/components/show/HotelImagesSlider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faStar,
  faLocationDot,
  faBed,
  faUtensils,
  faThermometerHalf,
  faArrowRight,
  faWifi,
  faSnowflake,
  faFirstAid,
  faFire,
  faSink,
  faMicrochip,
} from "@fortawesome/free-solid-svg-icons";
export const ShowHotelModa = ({
  setModalShowHotel,
  modalShowHotel,
  selectedHotel,
}) => {
  return (
    <Modal
      show={modalShowHotel}
      onHide={() => setModalShowHotel(false)}
      size="xl"
      centered
    >
      <Modal.Body>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <span className="blur-background">
              <span style={{ fontSize: "22px" }} className="mr-2 ">
                {selectedHotel?.otel_name}
              </span>
              {Array.from({ length: selectedHotel?.otel_stars }, (_, index) => (
                <FontAwesomeIcon key={index} icon={faStar} />
              ))}
            </span>
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <span className="blur-background">
              {selectedHotel?.otel_description}
            </span>
          </Col>
        </Row>
        <hr />
        <h3>Adres</h3>
        <Row>
          <span className="blur-background">
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              {selectedHotel?.address?.country}
            </Col>

            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              {selectedHotel?.address?.state}
            </Col>

            <Col xl={12} lg={12} md={2} sm={12} xs={12}>
              {selectedHotel?.address?.distractl}
            </Col>

            <Col xl={12} lg={2} md={12} sm={12} xs={12}>
              {selectedHotel?.address?.neighborhood}
            </Col>

            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              {selectedHotel?.address?.postal_code}
            </Col>

            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <span className="blur-background">
                {selectedHotel?.address?.street_adress}
              </span>
            </Col>
          </span>
        </Row>
        <hr />
        <Row>
          <h3>Özelikleri</h3>
          <Col xl={6}>
            <Row>
              <Col xl={4}>
                {selectedHotel?.details?.wifi === 1 && (
                  <div>
                    <FontAwesomeIcon icon={faWifi} /> <span>free wifi</span>
                  </div>
                )}
              </Col>
              <Col xl={4}>
                {selectedHotel?.details?.cooling === 1 && (
                  <div>
                    <FontAwesomeIcon icon={faSnowflake} /> <span>cooler</span>
                  </div>
                )}
              </Col>
              <Col xl={4}>
                {selectedHotel?.details?.first_aid_kit === 1 && (
                  <div>
                    <FontAwesomeIcon icon={faFirstAid} />{" "}
                    <span>first aid kit</span>
                  </div>
                )}
              </Col>
              <Col xl={4}>
                {selectedHotel?.details?.food === 1 && (
                  <div>
                    <FontAwesomeIcon icon={faUtensils} />
                    <span>food</span>
                  </div>
                )}
              </Col>
              <Col xl={4}>
                {selectedHotel?.details?.heating === 1 && (
                  <div>
                    <FontAwesomeIcon icon={faFire} /> <span>heating</span>
                  </div>
                )}
              </Col>
              <Col xl={4}>
                {selectedHotel?.details?.kitchen === 1 && (
                  <div>
                    <FontAwesomeIcon icon={faSink} /> <span>kitchen</span>
                  </div>
                )}
              </Col>
              <Col xl={4}>
                {selectedHotel?.details?.microwave === 1 && (
                  <div>
                    <FontAwesomeIcon icon={faMicrochip} />
                    <span>microwave</span>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          <Col
            xl={6}
            className="d-flex justify-content-center align-items-center"
          >
            <HotelImagesSlider photographs={selectedHotel?.photographs} />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setModalShowHotel(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ShowHotelModa;
