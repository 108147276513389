import React, { useState, useEffect } from "react";
import { axios } from "common/utils/axios";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ButtonGroup from "@mui/material/ButtonGroup";
import SelectInput from "../../ServiceCustomersComponents/Components/SelectInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

export const PopBudget = () => {
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [priceData, setPriceData] = useState([]);
  const [minBudget, setMinBudget] = useState("");
  const [maxBudget, setMaxBudget] = useState("");
  const [currencyType, setCurrencyType] = useState("");

  const CurrencyType = [
    { currencyType: "TL", abbreviation: "TL" },
    { currencyType: "USD", abbreviation: "USD" },
    { currencyType: "EUR", abbreviation: "EUR" },
    { currencyType: "SAR", abbreviation: "SAR" },
  ];
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const saveBudget = async () => {
    try {
      const response = await axios.post(`api/customer/${id}/budget`, {
        min_budget: minBudget,
        max_budget: maxBudget,
        currency_type: currencyType,
      });
      handleClose();
      console.log(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <ButtonGroup>
        <Button
          onClick={handleClickOpen}
          variant="info"
          style={{
            background: "#d39e00",
            fontSize: "20x",
            height: "28px",
            width: "24px",
            padding: "2px",
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      </ButtonGroup>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Butçe</DialogTitle>
        <DialogContent>
          <div className="row mb-12 d-flex">
            <SelectInput
              className="flex-grow-1"
              label={"para birimi türü"}
              name="ParaBirimiTürü"
              keyName="district_name"
              value={currencyType}
              onChange={(e) => setCurrencyType(e.target.value)}
              options={CurrencyType.map((item) => item.abbreviation)}
            />
          </div>
          Minimum Bütçe
          <TextField
            autoFocus
            margin="dense"
            label="Minimum Bütçe"
            fullWidth
            placeholder="2000"
            value={minBudget}
            onChange={(e) => setMinBudget(e.target.value)}
            inputProps={{
              inputMode: "numeric",
              maxLength: 6,
            }}
          />
          Max Bütçe
          <TextField
            autoFocus
            margin="dense"
            label="Max Bütçe"
            fullWidth
            placeholder="2000"
            value={maxBudget}
            onChange={(e) => setMaxBudget(e.target.value)}
            inputProps={{
              inputMode: "decimal",
              maxLength: 6,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Kapat</Button>
          <Button onClick={saveBudget}>Kaydet</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default PopBudget;
