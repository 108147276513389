import React, { useState } from "react";
import AllCards from "./AllCard";
import { BonusCard } from "./BonusCard";
import { WorldCard } from "./WorldCard";

export const CarousalPayment = () => {
  const [activeCard, setActiveCard] = useState("card0");

  const handleClick = (cardId) => {
    setActiveCard(cardId);
  };
  return (
    <div>
      <div className="detail-tab-content">
        <div class="online-section-title">
          <span class="glyphicon glyphicon-asterisk"></span> Ödeme
        </div>
        <div className="tab-content p20">
          <div className="tab-pane pane-right pt0 active">
            <nav className="nav nav-pills nav-fill" id="cardselection">
              <a
                href="#card0"
                className={`nav-item nav-link ${
                  activeCard === "card0" ? "active" : ""
                }`}
                onClick={() => handleClick("card0")}
              >
                Tüm Kartlar
              </a>
              <a
                href="#card16"
                className={`nav-item nav-link ${
                  activeCard === "card16" ? "active" : ""
                }`}
                onClick={() => handleClick("card16")}
              >
                Bonus Card
              </a>
              <a
                href="#card2"
                className={`nav-item nav-link ${
                  activeCard === "card2" ? "active" : ""
                }`}
                onClick={() => handleClick("card2")}
              >
                World Card
              </a>
            </nav>
            {activeCard === "card0" && <AllCards />}
            {activeCard === "card16" && <BonusCard />}
            {activeCard === "card2" && <WorldCard />}
          </div>
        </div>
      </div>
    </div>
  );
};
