import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { axios } from "common/utils/axios";
import "bootstrap/dist/css/bootstrap.css";
import { ToastContainer, toast } from "react-toastify";
import { getCookie } from "common/helpers/getCookie";
import HelpMessagingTableList from "area/admin/CustomerMangement/HelpMangment/Components/HelpMessagingTableList";
import ReplayModal from "area/admin/CustomerMangement/HelpMangment/Components/ReplayModal";
import ConformFinishModal from "area/admin/CustomerMangement/HelpMangment/Components/ConformFinishModal";
import DownloadFilesModal from "area/admin/CustomerMangement/HelpMangment/Components/DownloadFilesModal";
import PaginationList from "area/admin/CustomerMangement/HelpMangment/Components/PaginationList";
function HelpMessagingSystem() {
  const [files, setFiles] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [showReplayModal, setShowModal] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [conversationToFinish, setConversationToFinish] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const userCookie = getCookie("user");
  const USER_ID = userCookie ? JSON.parse(userCookie).id : null;
  const handleDownload = (conversation) => {
    setSelectedConversation(conversation);
    axios({
      url: `/api/conversations/${conversation.id}/files`,
      method: "GET",
      responseType: "blob", // Important
    })
      .then((response) => {
        // Get the original file name from the headers
        let fileName = "";
        const contentDisposition = response.headers["content-disposition"];
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
        }

        // Create a blob URL
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        // Use the original file name for download
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    axios
      .get(`/api/helpconversations?page=${page}`)
      .then((res) => {
        setConversations(res.data); // Update according to your API response
        setTotalPages(res.data.totalPages); // Update according to your API response
      })
      .catch((err) => {
        console.error(err);
      });
  }, [page]);

  const handleClick = (conversation) => {
    setSelectedConversation(conversation);
    fetchMessages(conversation.id);
    setShowModal(true);
  };

  const handleShow = (conversation) => {
    setSelectedConversation(conversation);
    fetchMessages(conversation.id);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setSelectedConversation(null);
    setShowModal(false);
    setMessage("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const body = {
      message: message,
      conversation_id: selectedConversation.id,
      is_admin: true,
      user_id: USER_ID,
    };

    axios
      .post("/api/helpmessages_lastsender", body)
      .then((res) => {
        setMessage("");
        const newMessage = {
          message: body.message,
          is_admin: body.is_admin,
        };
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      })
      .catch((err) => {
        toast.error("Reply submitted error");
        console.error(err);
      });
  };

  const handleFinish = (conversation) => {
    setConversationToFinish(conversation);
    setShowConfirmationModal(true);
  };

  const handleConfirmFinish = () => {
    axios
      .put(`/api/helpconversations/${conversationToFinish.id}/finish`)
      .then((res) => {
        setConversationToFinish(null);
        setShowConfirmationModal(false);
      })
      .catch((err) => {
        toast.error("Finish conversation error");
        console.error(err);
      });
  };

  const fetchMessages = (conversationId) => {
    axios
      .get(`/api/conversations/${conversationId}/messages`)
      .then((res) => {
        setMessages(res.data.messages);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div>
      <Card style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)" }}>
        <Card.Header
          style={{
            backgroundColor: "#636665",
            color: "white",
            borderRadius: "5px 5px 0 0",
          }}
        >
          <h1 style={{ padding: "10px", fontSize: "24px", margin: 0 }}>
            Destek Talebleri
          </h1>
        </Card.Header>
        <Card.Body>
          <HelpMessagingTableList
            conversations={conversations}
            handleClick={handleClick}
            handleDownload={handleDownload}
            handleFinish={handleFinish}
            handleShow={handleShow}
          />

          <PaginationList
            setPage={setPage}
            totalPages={totalPages}
            page={page}
          />

          <ReplayModal
            showReplayModal={showReplayModal}
            handleModalClose={handleModalClose}
            setMessage={setMessage}
            messages={messages}
            message={message}
            selectedConversation={selectedConversation}
            handleSubmit={handleSubmit}
          />

          <ConformFinishModal
            showConfirmationModal={showConfirmationModal}
            setShowConfirmationModal={setShowConfirmationModal}
            handleConfirmFinish={handleConfirmFinish}
          />

          <DownloadFilesModal
            showDownloadModal={showDownloadModal}
            setShowDownloadModal={setShowDownloadModal}
            selectedConversation={selectedConversation}
            files={files}
          />
        </Card.Body>
        <ToastContainer autoClose={2000} />
      </Card>
    </div>
  );
}

export default HelpMessagingSystem;
