import React, {useCallback} from "react";
import {GoogleMap, LoadScript, Marker} from "@react-google-maps/api";

export default function MapComponent({setOtelPositionX, setOtelPositionY}) {
	const containerStyle = {
		width: "auto",
		height: "200px",
	};
	const center = {
		lat: 40,
		lng: 32,
	};

	const handleDrag = (e) => {
		console.log("dragged", e);
		setOtelPositionX(e.latLng.lat());
		setOtelPositionY(e.latLng.lng());
	};

	const Gmap = useCallback(() => {
		return (
			<>
				<label htmlFor="ressim">Konumu Sürükle</label>
				<LoadScript googleMapsApiKey="AIzaSyB7XX2tMXlrB0G-4larvlaxYgceY8zGeA4">
					<GoogleMap
						mapContainerStyle={containerStyle}
						center={center}
						zoom={4}>
						{/* Child components, such as markers, info windows, etc. */}
						<Marker
							draggable={true}
							onDragEnd={handleDrag}
							position={center}
						/>
					</GoogleMap>
				</LoadScript>
			</>
		);
	}, []);

	return <Gmap />;
}
