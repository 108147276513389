import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const NoteViewer = ({ note }) => {
  const [showNote, setShowNote] = useState(false);
  const [currentNote, setCurrentNote] = useState("");

  const handleShowNote = (note) => {
    setCurrentNote(note);
    setShowNote(true);
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        {note && note.length > 15 ? `${note.substring(0, 15)}...` : note}
        {"  "}
        {note ? (
          <Button
            variant="info"
            className="mr-2"
            active={true}
            onClick={() => handleShowNote(note)}
            style={{ padding: "0.25rem 0.5rem", fontSize: "0.75rem" }}
          >
            <FontAwesomeIcon icon={faEye} size="xs" />
          </Button>
        ) : (
          <div></div>
        )}
      </div>

      <Modal
        show={showNote}
        onHide={() => setShowNote(false)}
        size="ml"
        scrollable
        style={{ maxHeight: "50%" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Note Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{currentNote}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            active={true}
            color="secondary"
            className="btn-secondary"
            variant="secondary"
            onClick={() => setShowNote(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NoteViewer;
