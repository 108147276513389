import React from "react";

export default function OtelFoodsComponent({
	setOtelFoodsTitle,
	setOtelFoods,
	otelFoodsTitle,
	otelFoods,
}) {
	const otelFoodsTitleHandle = (e) => {
		setOtelFoodsTitle(e.target.value);
	};
	const otelFoodsHandle = (e) => {
		setOtelFoods(e.target.value);
	};
	return (
		<div>
			<label> Yemek Başlığı</label>
			<br />
			<input
				type="text"
				value={otelFoodsTitle}
				onChange={otelFoodsTitleHandle}
			/>
			<br />
			<label> Yemek İçeriği</label>
			<br />
			<textarea
				className="w-full"
				type="text"
				value={otelFoods}
				onChange={otelFoodsHandle}
			/>

			<br />
		</div>
	);
}
