import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import styles from "assets/styles/tourstamplate.module.css";
import errorPhoto from "../../../../assets/icons/errorPhoto.jpg";
import tourtest2 from "../../../../assets/icons/tourtest2.png";
function ToursTamplateCard() {
  return (
    <div className={styles.cardWrapper}>
      <div className={styles.card}>
        <div className={styles.turSecWrapper}>
          <span className={styles.turSecText}>tur sec</span>
          <input type="checkbox" className={styles.turSecCheckbox} />
        </div>
        <div className={styles.leftSection}>
          <div>
            <img
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                width: "424px",
                height: "284px",
                objectFit: "cover",
              }}
              src={tourtest2}
              alt="Image"
            />
          </div>
        </div>
        <div className={styles.rightSection}>
          <h6>
            <strong>
              21 GECE MEKKE 4 GECE MEDİNE / RAMAZAN'DA SON 10 GÜN MEKKE 25 Gece
              26 Gün
            </strong>
          </h6>
          <div className={styles.line}></div>
          <ul>
            {["2023-06-01"].map((date, index) => (
              <li
                key={date}
                className={`${styles.listItem} ${
                  index % 2 === 0 ? styles.withBackground : ""
                }`}
              >
                <span style={{ marginRight: "10px" }}>Gidiş Tarih: </span>
                <span style={{ marginLeft: "20px", fontWeight: "900" }}>
                  {date} Cumartisi
                </span>
                <span style={{ marginRight: "10px", marginLeft: "20px" }}>
                  İstanbul
                </span>
                <div
                  className={styles.airplaneIcon}
                  style={{ marginRight: "10px" }}
                ></div>
                <span style={{ marginRight: "10px" }}>Medine</span>
              </li>
            ))}
          </ul>
          <p style={{ display: "flex", alignItems: "center" }}>
            <div
              className={styles.moonIcon}
              style={{ marginRight: "5px" }}
            ></div>{" "}
            <strong>22 Gece</strong>
          </p>
          <div className={styles.line}></div>
          <div
            className={styles.titleWithIcon}
            style={{ marginTop: "50px", fontSize: "27px" }}
          >
            <h1 style={{ color: "#4EAC74", fontWeight: "700" }}>2500$</h1>
            <p>'dan başlayan fiyatlarla</p>
          </div>
        </div>
      </div>
      <div className={styles.satinAlButtonWrapper}>
        <Button className={styles.satinAlButton}>SATIL AL</Button>
      </div>
    </div>
  );
}
export default ToursTamplateCard;
