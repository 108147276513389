import React, { useState, lazy, Suspense } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useParams } from "react-router-dom";
import { axios } from "common/utils/axios";
import { useCustomerByIdData } from "common/hooks/useCustomerByIdData ";
import Meetings2 from "area/admin/CustomerMangement/CustomerDetailsComponents/Meetings2";
import { OffersAndSales } from "area/admin/CustomerMangement/CustomerDetailsComponents/OffersAndSales";
import UserGeneralInfo from "./components/UserGeneralInfo";
import { Card, Col, Row, Form, Button } from "react-bootstrap";
import "assets/styles/UserProfile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import UserImage from "assets/images/user.jpg";
const GeneralInfo = lazy(() =>
  import("area/admin/CustomerMangement/CustomerDetailsComponents/GeneralInfo")
);
const PaymentHistory = lazy(() =>
  import(
    "area/admin/CustomerMangement/CustomerDetailsComponents/PaymentHistory"
  )
);

const Addres = lazy(() =>
  import("area/admin/CustomerMangement/CustomerDetailsComponents/Addres")
);
const Family = lazy(() =>
  import("area/admin/CustomerMangement/CustomerDetailsComponents/Family")
);
const OthorsInfo = lazy(() =>
  import("area/admin/CustomerMangement/CustomerDetailsComponents/OthorsInfo")
);
const Documentaion = lazy(() =>
  import("area/admin/CustomerMangement/CustomerDetailsComponents/Documentaion")
);
const Meetings = lazy(() =>
  import("area/admin/CustomerMangement/CustomerDetailsComponents/Meetings")
);
const Offres = lazy(() =>
  import("area/admin/CustomerMangement/CustomerDetailsComponents/Offres")
);
const Sales = lazy(() =>
  import("area/admin/CustomerMangement/CustomerDetailsComponents/Sales")
);

export const CustomerDetails = ({ userData }) => {
  const { id } = useParams();
  const [value, setValue] = useState("1");
  const customerData = useCustomerByIdData(id);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const createdDate = new Date(customerData.created_at);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const formattedDate = createdDate.toLocaleString("en-US", options);

  return (
    <div>
      <Row>
        <Col xl={12}>
          <Card className="shadow" style={{ width: "93%" }}>
            <div className="d-flex justify-content-between align-items-center pl-3 pt-2 pr-3">
              <span>Genel Bilgi</span>
              <button
                className="btn"
                onClick={() => {
                  /* handle edit action */
                }}
              >
                <FontAwesomeIcon icon={faEdit} />
              </button>
            </div>
            <hr />
            <Row
              style={{
                fontSize: "22px",
                marginTop: "50px",
                marginBottom: "65px",
                marginLeft: "40px",
              }}
            >
              <Col
                xl={4}
                style={{ paddingLeft: "30px" }}
                className="d-flex justify-content-center"
              >
                <div className=" mb-3">
                  <img
                    src={userData?.ProfilePhoto || UserImage}
                    width="180"
                    height="180"
                    className="circle-image"
                  />
                </div>
              </Col>
              <Col>
                <Row>
                  <Col xl={3} style={{ marginTop: "50px" }}>
                    <p>
                      {userData?.FirstName} {userData?.LastName}
                    </p>
                    <p>Yazılımcı</p>
                  </Col>
                  <Col xl={5} style={{ marginTop: "50px", marginLeft: "40px" }}>
                    <p>{userData?.Eposta}</p>
                    <p>{userData?.Telefon}</p>
                    <p>{userData?.BirthDate}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col className="hidden">
          <Card className="shadow">
            <span className="pl-3 pt-2">Şifre Güncelleme</span>
            <hr />
            <div>
              <Form
                className="pl-2 pr-2 pb-3"
                style={{ fontSize: "18px", borderRadius: "none" }}
              >
                <Form.Group className="mb-3" controlId="currentPassword">
                  <Form.Label>Şu Anki Şifre</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Şu Anki Şifrenizi Girin"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="newPassword">
                  <Form.Label>Yeni Şifre</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Yeni Şifrenizi Girin"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="confirmNewPassword">
                  <Form.Label>Yeni Şifre (Tekrar)</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Yeni Şifrenizi Tekrar Girin"
                  />
                </Form.Group>

                <Button
                  className="text-center"
                  style={{ width: "100%" }}
                  variant="primary"
                  type="submit"
                >
                  Güncelle
                </Button>
              </Form>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default CustomerDetails;
