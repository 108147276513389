import React from "react";
import { useState, useEffect } from "react";
import { axios } from "common/utils/axios";
import { Link } from "react-router-dom";

const ToursGridModal2 = () => {
  const [id, setId] = useState(null); // Define id here
  const [editingTour, setEditingTour] = useState(null);
  const [otels, setOtels] = useState([]);
  const [tourData, setTourData] = useState({
    data: {
      title: "",
      birinci_hotel_ismi: "",
      birinci_hotel_geceler_sayisi: "",
      birinci_hotel_Img_url: "",

      ikinci_hotel_ismi: "",
      ikinci_hotel_geceler_sayisi: "",
      ikinci_hotel_Img_url: "",

      gidis_tarih: "",
      ara_gecis_tarih: "",
      donus_tarih: "",

      iki_kisi_fiyat: "",
      uç_kisi_fiyat: "",
      dört_kisi_fiyat: "",
      özel_kisi_saısı: "",
      özel_fiyat: "",
    },
  });
  /* ******************************************* */
  /*Delet Data */

  const handleDelete = (id) => {
    axios
      .delete(`api/tours/delete/${id}`)
      .then((response) => {
        if (response.status === 200) {
          // if the response is successful, update the tours state
          const updatedTours = tours.filter((tour) => tour.id !== id);
          setTourData(updatedTours);
          window.location.reload();
        } else {
          throw new Error("Failed to delete tour");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleEditChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setEditingTour((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEditClose = () => {
    setEditingTour(null);
    setId(null);
  };
  /* ******************************************* */
  /*Recive data from dataBase */
  const [tours, setData] = useState([]);

  useEffect(() => {
    axios
      .get("api/tours")
      .then((response) => {
        const data = response.data;
        // Sort the data by date in descending order
        data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setData(data.reverse()); // reverse the sorted array
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  /* ******************************************* */

  /**********Get Otels From DataBase ******** */
  const getOtels = async () => {
    const res = await axios.get("api/otel");
    if (res) {
      console.log("otels", res);
      setOtels(res.data.otels);
    }
  };

  useEffect(() => {
    getOtels();
  }, []);
  console.log(otels);
  /************************ */

  return (
    <div>
      {tours.map((tour) => (
        <div className="container " key={tour.id}>
          <div className="row">
            <div className="col-2">
              {" "}
              {/************************ */}
              {otels.find((otel) => otel.id === tour.otel_id) && (
                <div>
                  <img
                    className="img-fluid"
                    src={
                      otels.find((otel) => otel.id === tour.otel_id)
                        .cover_picture
                    }
                    alt="otel"
                    onError={(e) => console.log("Error loading image", e)}
                  />
                  {console.log(
                    otels.find((otel) => otel.id === tour.otel_id).cover_picture
                  )}
                  <Link
                    to={`/otel/${tour.otel_id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <p>View Otel Details</p>
                  </Link>
                </div>
              )}
              {/******************** */}
            </div>
            <div className="col-2">
              {/************************ */}
              {otels.find((otel) => otel.id === tour.otel_id2) && (
                <div>
                  <img
                    className="img-fluid"
                    src={
                      otels.find((otel) => otel.id === tour.otel_id2)
                        .cover_picture
                    }
                    alt="otel"
                    onError={(e) => console.log("Error loading image", e)}
                  />
                  {console.log(
                    otels.find((otel) => otel.id === tour.otel_id2)
                      .cover_picture
                  )}
                  <Link
                    to={`/otel/${tour.otel_id2}`}
                    style={{ textDecoration: "none" }}
                  >
                    <p>View Otel Details</p>
                  </Link>
                </div>
              )}
              {/******************** */}
            </div>
            <div className="col-6">
              <div>
                <button
                  onClick={() => handleDelete(tour.id)}
                  className="btn btn-danger mr-2"
                >
                  Delete
                </button>

                <form>
                  <div className="container">
                    <div>
                      <label>Title</label>
                      <input type="text" value={tour.title} />
                    </div>
                    <div>
                      <label>Gidiş Tarihi</label>
                      <input type="date" />
                    </div>
                    <div>
                      <label>Ara Gecis Tarih</label>
                      <input type="date" />
                    </div>
                    <div>
                      <label>Donus Tarih</label>
                      <input type="date" />
                    </div>
                    <div>
                      <label>birinci Hotel</label>
                      <input type="text" value={tour.birinci_hotel_ismi} />
                    </div>
                    <div>
                      <label>birinci hotel img</label>
                      <input type="text" value={tour.birinci_hotel_Img_url} />
                    </div>
                    <div>
                      <label>birinci hotel geceler sayisi</label>
                      <input
                        type="text"
                        value={tour.birinci_hotel_geceler_sayisi}
                      />
                    </div>
                    <div>
                      <label>ikinci hotel</label>
                      <input type="text" value={tour.ikinci_hotel_ismi} />
                    </div>
                    <div>
                      <label>ikinci hotel img</label>
                      <input type="text" value={tour.ikinci_hotel_Img_url} />
                    </div>
                    <div>
                      <label>ikinci hotel sayisi</label>
                      <input
                        type="text"
                        value={tour.ikinci_hotel_geceler_sayisi}
                      />
                    </div>
                    <div>
                      <label>2 kisi sayisi</label>
                      <input type="text" value={tour.iki_kisi_fiyat} />
                    </div>
                    <div>
                      <label>3 kisi sayisi</label>
                      <input type="text" value={tour.uç_kisi_fiyat} />
                    </div>
                    <div>
                      <label>4 kisi sayisi</label>
                      <input type="text" value={tour.dört_kisi_fiyat} />
                    </div>
                    <div>
                      <label>ozel kisi sayisi</label>
                      <input type="text" value={tour.özel_kisi_saısı} />
                    </div>
                    <div>
                      <label>ozel fiyat</label>
                      <input type="text" value={tour.özel_fiyat} />
                    </div>
                    {/*********** Yeniler ************* */}
                    <div>
                      <label>gidis_binis</label>
                      <input type="text" value={tour.gidis_binis} />
                    </div>
                    <div>
                      <label>gidis_inis</label>
                      <input type="text" value={tour.gidis_inis} />
                    </div>
                    <div>
                      <label>ara_gecis_binis</label>
                      <input type="text" value={tour.ara_gecis_binis} />
                    </div>
                    <div>
                      <label>ara_gecis_inis</label>
                      <input type="text" value={tour.ara_gecis_inis} />
                    </div>
                    <div>
                      <label>donus_binis</label>
                      <input type="text" value={tour.donus_binis} />
                    </div>
                    <div>
                      <label>donus_inis</label>
                      <input type="text" value={tour.donus_inis} />
                    </div>
                    <div>
                      <label>Price_ID</label>
                      <input type="text" value={tour.price_id} />
                    </div>
                    {/******* First ******** */}
                    <div className="mt-4 mb-4">
                      <label htmlFor="otel_id">Hotel:</label>
                      <select id="otel_id" name="otel_id">
                        <option value="">Select hotel</option>
                        {otels.map((otel) => (
                          <option key={otel.id} value={otel.id}>
                            {otel.otel_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {/******* Second ******** */}
                    <div className="mt-4 mb-4">
                      <label htmlFor="otel_id2">Hotel:</label>
                      <select id="otel_id2" name="otel_id2">
                        <option value="">Select hotel</option>
                        {otels.map((otel) => (
                          <option key={otel.id} value={otel.id}>
                            {otel.otel_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <button type="submit" className="btn btn-primary">
                        Edit Tour
                      </button>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={handleEditClose}
                      >
                        Cancle
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <ul>
                <li>title {tour.title}</li>
                <li>
                  birinci hotel Geceler Syaısı{" "}
                  {tour.birinci_hotel_geceler_sayisi}
                </li>
                <li>
                  ikinci hotel Geceler Syaısı {tour.ikinci_hotel_geceler_sayisi}
                </li>
                <li>gidiş tarih {tour.gidis_tarih}</li>
                <li> ara gecisi tarih {tour.ara_gecis_tarih}</li>
                <li>donus tarıh {tour.donus_tarih}</li>
                <li> 2 kisi fiyat {tour.iki_kisi_fiyat}</li>
                <li> 3 kisi fiyat{tour.uç_kisi_fiyat}</li>
                <li> 4 kisi fiyat {tour.dört_kisi_fiyat}</li>
                <li> ozel kisi saysi {tour.özel_kisi_saısı}</li>
                <li> ozel kisi saysi fiyat {tour.özel_fiyat}</li>
                {/************** Yeniler ************ */}
                <li> gidis_binis {tour.gidis_binis}</li>
                <li> gidis_inis{tour.özel_fiyat}</li>
                <li> ara_gecis_binis{tour.ara_gecis_binis}</li>
                <li> ara_gecis_inis {tour.ara_gecis_inis}</li>
                <li> donus_binis {tour.donus_binis}</li>
                <li> donus_inis {tour.donus_inis}</li>
                <li>Binirci Hotel {tour.otel_id}</li>
                <li> ikinci Hotel{tour.otel_id2}</li>
                <li> Price_ID {tour.price_id}</li>
                {/************* Yeniler ******** */}
              </ul>
            </div>
          </div>
          <hr></hr>
        </div>
      ))}
    </div>
  );
};

export default ToursGridModal2;
