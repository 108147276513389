import React from "react";
import { Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSync } from "@fortawesome/free-solid-svg-icons";
export const SearchButtonComponent = ({ handleClearFilter, classNames }) => {
  return (
    <Col
      style={{ display: "flex", alignItems: "center" }}
      className={classNames}
    >
      <Button
        style={{
          backgroundColor: "#007bff",
          color: "white",
          padding: "8px 13px",
          borderRadius: "5px",
          border: "none",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
          fontWeight: "bold",
          cursor: "pointer",
          transition: "background-color 0.3s ease",
          display: "flex",
          alignItems: "center",
          marginRight: "10px",
        }}
        type="submit"
        onMouseEnter={(e) => (e.target.style.backgroundColor = "#004080")}
        onMouseLeave={(e) => (e.target.style.backgroundColor = "#007bff")}
      >
        <FontAwesomeIcon
          icon={faSearch}
          style={{ fontSize: "18px", marginRight: "8px" }}
        />{" "}
      </Button>
      <Button
        style={{
          backgroundColor: "#7c6b6b",
          color: "white",
          padding: "8px 13px",
          borderRadius: "5px",
          border: "none",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
          fontWeight: "bold",
          cursor: "pointer",
          transition: "background-color 0.3s ease",
          display: "flex",
          alignItems: "center",
        }}
        onClick={handleClearFilter}
        onMouseEnter={(e) => (e.target.style.backgroundColor = "#584242")}
        onMouseLeave={(e) => (e.target.style.backgroundColor = "#7c6b6b")}
      >
        <FontAwesomeIcon
          icon={faSync}
          style={{ fontSize: "16px", marginRight: "8px" }}
        />
      </Button>
    </Col>
  );
};
export default SearchButtonComponent;
