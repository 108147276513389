import { useState } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import PostImage from "area/admin/CRUD/ReactBootsrap/FormUplaodInput";
import { uploadLogo } from "common/Api/SiteSettingsApi";
export const FooterLogo = () => {
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState({
    logo: "",
    logo_classify: "footer",
  });
  const [file, setFile] = useState(null);
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, logo: reader.result });
      };
      reader.readAsDataURL(selectedFile);
    }
  };
  const mutation = useMutation(() => uploadLogo(file, "footer"), {
    onSuccess: () => {
      queryClient.invalidateQueries("MainLogo");
      toast.success("Logo uploaded successfully!");
      setFormData({ logo: "" });
    },
    onError: (error) => {
      const errorMsg = error?.response?.data?.message || "Error uploading logo";
      toast.error(errorMsg);
    },
  });
  return (
    <div>
      <Form
        onSubmit={async (e) => {
          e.preventDefault();
          console.log(formData.logo);
          const jsonData = {
            logo: formData.logo,
          };
          mutation.mutate(jsonData);
        }}
      >
        <PostImage
          imageSrc={formData.logo}
          label={"Footer Logo"}
          name={"logo"}
          onChange={handleFileChange}
        />
        <Button type="submit" variant="primary">
          Upload Logo
        </Button>
      </Form>
    </div>
  );
};
export default FooterLogo;
