import React, { useState } from "react";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import CreatButton from "area/admin/CRUD/CreatButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { uploadLogo } from "common/Api/SiteSettingsApi";
export const Logos = ({ handleClickCreatButton }) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const defaultLogoUrls = {
    footer: "https://suarelab.com/MainLogo/footer.png",
    header: "https://suarelab.com/MainLogo/header.png",
    log_in: "https://suarelab.com/MainLogo/log_in.png",
  };
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState({
    logo: "",
    logo_classify: "",
  });

  const [file, setFile] = useState(null);
  const handleImageChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile); // Set the file object for upload

      // For displaying the image preview
      const filesArray = Array.from(event.target.files).map((file) =>
        URL.createObjectURL(file)
      );
      setSelectedImages(filesArray);
      // Remember to revoke object URLs to avoid memory leaks
      event.target.files.forEach((file) => URL.revokeObjectURL(file));
    }
  };
  const handleSelectChange = (event) => {
    const logoType = event.target.value;
    setFormData({ ...formData, logo_classify: logoType });

    // Display the default image for the selected logo type
    if (logoType in defaultLogoUrls) {
      setSelectedImages([defaultLogoUrls[logoType]]);
    } else {
      setSelectedImages([]); // Clear images if no valid type is selected
    }
  };

  const mutation = useMutation(() => uploadLogo(file, formData.logo_classify), {
    onSuccess: () => {
      queryClient.invalidateQueries("MainLogo");
      toast.success("Logo uploaded successfully!");
      setSelectedImages([]);
      setFile(null);
    },
    onError: (error) => {
      const errorMsg = error?.response?.data?.message || "Error uploading logo";
      toast.error(errorMsg);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (file) {
      mutation.mutate();
    } else {
      toast.error("Please select a logo image to upload.");
    }
  };
  const handleRemoveImage = (indexToRemove) => {
    setSelectedImages(
      selectedImages.filter((_, index) => index !== indexToRemove)
    );
  };

  return (
    <Form
      onSubmit={async (e) => {
        e.preventDefault();
        console.log(formData.logo);
        const jsonData = {
          logo: formData.logo,
          logo_classify: formData.logo_classify,
        };
        mutation.mutate(jsonData);
      }}
    >
      <Card style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)" }}>
        <Card.Header
          style={{
            backgroundColor: "#636665",
            color: "white",
            borderRadius: "5px 5px 0 0",
          }}
        >
          <Row>
            <Col>
              <h1 style={{ padding: "10px", fontSize: "24px", margin: 0 }}>
                Logo Yönetimi
              </h1>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Row>
                <Col>
                  <CreatButton
                    title={"Kaydet "}
                    onClick={handleClickCreatButton}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Header>
        <Row>
          <Col md={6}>
            <Card>
              <Card.Body>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Resimleri Yükle</Form.Label>
                  <Form.Control
                    type="file"
                    multiple
                    onChange={handleImageChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Resim Seç</Form.Label>
                  <Form.Select onChange={handleSelectChange}>
                    <option hidden>Seçiniz</option>
                    <option value="header">Site Ikon Logosu</option>
                    <option value="footer">Site Alt Bant Logosu</option>
                    <option value="header">Admin Paneli Logosu</option>
                    <option value="footer">Giriş Yap Logosu</option>
                  </Form.Select>
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <Card.Body>
                <h5>Seçilen Resimler</h5>

                <span
                  style={{
                    fontWeight: "bold",
                    color: "#333",
                    marginBottom: "10px",
                    display: "block",
                  }}
                >
                  Resim boyutu 250x150 piksel olmalıdır. (max)
                </span>
                <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                  {selectedImages.map((image, index) => (
                    <div
                      key={index}
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <img
                        src={image}
                        alt={`Selected ${index + 1}`}
                        style={{
                          marginBottom: "20px",
                          width: "150px",
                          height: "150px",
                          objectFit: "cover",
                        }}
                      />
                      <button
                        type="button"
                        onClick={() => handleRemoveImage(index)}
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          border: "none",
                          backgroundColor: "rgba(255, 255, 255, 0.7)",
                          borderRadius: "50%",
                          cursor: "pointer",
                          width: "25px",
                          height: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "red",
                        }}
                      >
                        X
                      </button>
                    </div>
                  ))}
                  <Row>
                    <Col xl={6} className="mb-4">
                      <h3>
                        <strong>Site Başlık Loguso</strong>
                      </h3>
                      <img src={defaultLogoUrls.header} width="250px" />
                    </Col>
                    <Col xl={6} className="mb-4">
                      <h3>
                        <strong>Site Alt Bant Loguso</strong>
                      </h3>
                      <img src={defaultLogoUrls.footer} width="250px" />
                    </Col>
                    <Col xl={6}>
                      <h3>
                        <strong>Admin Paneli Loguso</strong>
                      </h3>
                      <img src={defaultLogoUrls.header} width="250px" />
                    </Col>
                    <Col xl={6}>
                      <h3>
                        <strong>Giriş Yap Loguso</strong>
                      </h3>
                      <img src={defaultLogoUrls.log_in} width="250px" />
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

export default Logos;
