import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
export const ReplayModal = ({
  showReplayModal,
  handleModalClose,
  setMessage,
  messages,
  message,
  selectedConversation,
  handleSubmit,
}) => {
  return (
    <Modal show={showReplayModal} onHide={handleModalClose} size="lg">
      <style type="text/css">
        {`
      .btn-close {
        color: black !important;
        background-color:black;
      }
    `}
      </style>
      <Modal.Header closeButton>
        <Modal.Title>Reply to Message</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          minHeight: "20vh",
          maxHeight: "80vh",
          overflowY: "auto",
          whiteSpace: "pre-wrap",
          padding: "1vw",
        }}
      >
        {messages.map((message, index) => (
          <div
            key={index}
            style={{
              textAlign: message.is_admin ? "left" : "right",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px 0",
              background: message.is_admin ? "#e9e9e9" : "#90caf9",
              wordWrap: "break-word",
            }}
          >
            {message.message}
          </div>
        ))}
      </Modal.Body>
      {selectedConversation?.is_active && (
        <Modal.Footer
          style={{ flexDirection: "column", alignItems: "stretch" }}
        >
          <Form>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                style={{
                  width: "100%",
                  resize: "vertical",
                }}
              />
            </Form.Group>
            <Button
              style={{
                background: "#0d6efd",
                alignSelf: "flex-end",
                margin: "0.5vw 0",
              }}
              onClick={handleSubmit}
            >
              Send
            </Button>
          </Form>
        </Modal.Footer>
      )}
    </Modal>
  );
};
export default ReplayModal;
