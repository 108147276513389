import React, { useEffect, useState, useContext, useRef } from "react";
import { useQuery, useInfiniteQuery } from "react-query";
import { fetchHotels } from "common/Api/HotelsApi";
import { Link, NavLink, useSearchParams } from "react-router-dom";
import { Card, Row, Col, Container, Button, Image } from "react-bootstrap";
import styled from "styled-components";
import otelsError from "assets/icons/errorPhoto.jpg";
import getOtels from "../../common/utils/functions/getOtels";
import Search from "common/components/searching/OtelSearchingList.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { HeaderHeightContext } from "layouts/GlobalLayout";
import HeaderContext from "redux/ContextApi/HeaderContext .jsx";
/********************* */
/********************** */
const ResponsiveImage = styled.img`
  height: 300px; /* Default height for larger screens */
  width: 420px;
  object-fit: cover;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  @media (max-width: 575.98px) {
    height: 220px; /* Height for small screens */
  }
`;
export default ({ headingText = "Oteller" }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setTitle, setImageUrl } = useContext(HeaderContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({ page: 1, perPage: 5 });
  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ["hotels", searchParams.toString()],
    ({ pageParam = 1 }) => fetchHotels({ page: pageParam }), // Ensure fetchHotels is adjusted to accept pageParam correctly
    {
      getNextPageParam: (lastPage, allPages) => {
        // This should return the parameter for the next page. Adjust according to your API's response.
        // For example, if your API returns a `nextPage` number or a cursor for the next page, use that.
        const nextPage = lastPage.current_page + 1;
        return lastPage.last_page >= nextPage ? nextPage : undefined;
      },
    }
  );
  const otelData = data?.data;
  console.log(data, "data");
  const [otels, setOtels] = useState([]);
  const [visible, setVisible] = useState(7);

  const headerHeightContext = useContext(HeaderHeightContext);
  const loadMoreRef = useRef();
  const {
    setHeaderHeight,
    setHeaderTitlePaddingTop,
    setHeaderTitlePaddingBottom,
  } = headerHeightContext;
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          if (hasNextPage) fetchNextPage();
        }
      },
      { threshold: 1 }
    );
    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }
    return () => observer.disconnect();
  }, [hasNextPage, fetchNextPage]);
  useEffect(() => {
    setImageUrl("https://backend.suarelab.com/api/about-us/images-id/1");
    // Clean up function to reset the title and image URL when the component unmounts
    return () => {
      setTitle("");
      setImageUrl("");
    };
  }, [setTitle, setImageUrl]);
  useEffect(() => {
    setHeaderHeight("0px"); // Set the header height to 200px
    setHeaderTitlePaddingTop("0px");
    setHeaderTitlePaddingBottom("0px");
    return () => {
      setHeaderHeight(null);
      setHeaderTitlePaddingBottom(null);
      setHeaderTitlePaddingTop(null); // Reset the header height when component unmounts
    };
  }, [setHeaderHeight]);
  const Image = ({ imageSrc, defaultImageSrc }) => {
    const [currentImage, setCurrentImage] = React.useState(imageSrc);

    const handleError = () => {
      setCurrentImage(defaultImageSrc);
    };

    React.useEffect(() => {
      setCurrentImage(imageSrc);
    }, [imageSrc]);

    return <img src={currentImage} onError={handleError} />;
  };
  const onLoadMoreClick = () => {
    setVisible((v) => v + 6);
  };
  useEffect(() => {
    let otels = getOtels(searchParams.get("type"), searchParams.get("key"));
    otels.then(function (result) {
      console.log(result);
      setOtels(result);
    });
  }, []);

  console.log(otels);
  const renderStars = (stars) => {
    let starIcons = [];
    for (let i = 0; i < stars; i++) {
      starIcons.push(<FontAwesomeIcon key={i} icon={faStar} />);
    }
    return starIcons;
  };

  return (
    <div style={{ marginTop: "130px" }}>
      <h2
        className="mt-4 mb-4 pl-4 d-flex justify-content-center"
        style={{ fontSize: "40px", fontWeight: "bold" }}
      >
        Oteller
      </h2>
      <div style={{ maxWidth: "1260px", margin: "0 auto" }}>
        <Row className="mb-4 d-flex justify-content-center justify-content-xl-start">
          <Search />
        </Row>
        <Row>
          {data?.pages.map((page, pageIndex) =>
            page.data.map((otel, otelIndex) => (
              <Col key={otelIndex} md={4} xxl={4} className="mb-4">
                <Card
                  style={{
                    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                    transition: "0.3s",
                  }}
                >
                  <Link to={`/otel/${otel.slug}`}>
                    <ResponsiveImage
                      variant="top"
                      src={
                        otel.photographs.find(
                          (photo) => photo.photo_type === "main"
                        )?.photo_url || otelsError
                      }
                      onError={(e) => (e.target.src = otelsError)}
                    />
                  </Link>
                  <Card.Body>
                    <Card.Title>{otel.otel_name}</Card.Title>
                    <Card.Text>
                      <div className="otel-stars">
                        {renderStars(otel.otel_stars)}
                      </div>
                      {otel.address && (
                        <span>
                          {otel.address.country}, {otel.address.state}
                        </span>
                      )}
                      {otel.featured && otel.otel_description && (
                        <div>{otel.otel_description}</div>
                      )}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))
          )}
        </Row>
        <div
          ref={loadMoreRef}
          style={{ height: 20, backgroundColor: "transparent" }}
        ></div>{" "}
        {/* Sentinel element */}
        {isFetchingNextPage && <p>Loading more...</p>}
      </div>
    </div>
  );
};
