import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import ContactDataContext from "redux/ContextApi/ContactDataContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faEnvelope,
  faPhone,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faTwitter,
  faGoogle,
  faInstagram,
  faLinkedinIn,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
function BootstrapV1Footer() {
  const contactData = useContext(ContactDataContext);
  const footerLogoUrl = "https://suarelab.com/MainLogo/footer.png";
  const [preloadedFooterLogo, setPreloadedFooterLogo] = useState(null);
  useEffect(() => {
    const img = new Image();
    img.onload = () => setPreloadedFooterLogo(footerLogoUrl);
    img.src = footerLogoUrl;
  }, []);
  return (
    <div>
      <footer
        className="text-center text-lg-start "
        style={{ backgroundColor: "rgb(232, 232, 232)", color: "#243E63" }}
      >
        <div className="container p-4 pb-0">
          <section>
            <div className="row">
              <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                <Link to="/">
                  {preloadedFooterLogo ? (
                    <img src={preloadedFooterLogo} width="240" />
                  ) : (
                    <h6 className="text-uppercase mb-4 font-weight-bold">
                      Company name
                    </h6>
                  )}
                </Link>

                <p className="mt-2">
                  Here you can use rows and columns to organize your footer
                  content. Lorem ipsum dolor sit amet, consectetur adipisicing
                  elit.
                </p>
              </div>

              <hr className="w-100 clearfix d-md-none" />

              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">
                  BAĞLANTILAR
                </h6>
                <p>
                  <Link className=" d-flex align-items-center" to={"/"}>
                    <span> Ana sayfa</span>
                  </Link>
                </p>
                <p>
                  <Link className=" d-flex align-items-center" to={"/tours"}>
                    <span> Tüm İlanlar</span>
                  </Link>
                </p>
                <p>
                  <Link className=" d-flex align-items-center" to={"/otels"}>
                    <span> Tüm Oteller</span>
                  </Link>
                </p>

                <p>
                  <Link
                    className=" d-flex align-items-center"
                    to={"/contactUs"}
                  >
                    <span> İletİşİm</span>
                  </Link>
                </p>
              </div>

              <hr className="w-100 clearfix d-md-none" />

              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">
                  İletişim
                </h6>
                <p>
                  <a href={contactData?.data?.address_link}>
                    <FontAwesomeIcon icon={faHome} className="mr-2" />{" "}
                    {contactData?.data?.open_address}
                  </a>
                </p>

                <p>
                  <a href={`mailto:${contactData?.data?.email}`}>
                    <FontAwesomeIcon icon={faEnvelope} className="mr-3" />{" "}
                    {contactData?.data?.email}
                  </a>
                </p>
                <p>
                  <FontAwesomeIcon icon={faPhone} className="mr-3" />{" "}
                  {contactData?.data?.telefon}
                </p>
              </div>

              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3 ">
                <h6 className="text-uppercase mb-4 font-weight-bold">
                  BİZİ TAKİP EDİN
                </h6>
                <div className="d-flex justify-content-start">
                  {/* Group 1 */}
                  {contactData?.data?.facebook && (
                    <a
                      className="btn btn-primary btn-floating m-1 text-white"
                      style={{ backgroundColor: "#3b5998" }}
                      href={contactData?.data?.facebook}
                      role="button"
                    >
                      <FontAwesomeIcon
                        style={{ width: "16px" }}
                        icon={faFacebookF}
                      />
                    </a>
                  )}
                  {contactData?.data?.twitter && (
                    <a
                      className="btn btn-primary btn-floating m-1 text-white"
                      style={{ backgroundColor: "#55acee" }}
                      href={contactData?.data?.twitter}
                      role="button"
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  )}
                  <a
                    className="btn btn-primary btn-floating m-1 text-white"
                    style={{ backgroundColor: "#dd4b39" }}
                    href="#!"
                    role="button"
                  >
                    <FontAwesomeIcon icon={faGoogle} />
                  </a>
                </div>
                <div className="d-flex justify-content-start">
                  {/* Group 2 */}
                  {contactData?.data?.instagram && (
                    <a
                      className="btn btn-primary btn-floating m-1 text-white"
                      style={{ backgroundColor: "#ac2bac" }}
                      href={contactData?.data?.instagram}
                      role="button"
                    >
                      <FontAwesomeIcon
                        style={{ width: "16px" }}
                        icon={faInstagram}
                      />
                    </a>
                  )}
                  {contactData?.data?.linkedin && (
                    <a
                      className="btn btn-primary btn-floating m-1 text-white"
                      style={{ backgroundColor: "#0082ca" }}
                      href={contactData?.data?.linkedin}
                      role="button"
                    >
                      <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                  )}
                  {contactData?.data?.youtube && (
                    <a
                      className="btn btn-primary btn-floating m-1 text-white"
                      style={{ backgroundColor: "rgb(199 31 31)" }}
                      href={contactData?.data?.youtube}
                      role="button"
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>

        <div
          className="text-center p-3"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
        >
          © 2024 Suare Tursis By SuareSoft
        </div>
      </footer>
    </div>
  );
}

export default BootstrapV1Footer;
