import { Pagination } from "react-bootstrap";

const PaginationControls = ({
  currentPage,
  handlePageChange,
  totalPageCount,
}) => {
  let items = [];
  for (let number = 1; number <= totalPageCount; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => handlePageChange(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  return (
    <Pagination>
      <Pagination.First
        onClick={() => handlePageChange(1)}
        disabled={currentPage === 1}
      />
      <Pagination.Prev
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />

      {/* For simplicity, showing all page numbers can be overwhelming for a large number of pages.
          You can implement a more dynamic pagination display (e.g., ellipses, first/last page) as needed. */}
      {items.slice(
        Math.max(0, currentPage - 3),
        Math.min(currentPage + 2, totalPageCount)
      )}

      <Pagination.Next
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPageCount}
      />
      <Pagination.Last
        onClick={() => handlePageChange(totalPageCount)}
        disabled={currentPage === totalPageCount}
      />
    </Pagination>
  );
};
export default PaginationControls;
