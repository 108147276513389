import React, { useState, useEffect } from "react";
import { axios } from "common/utils/axios";
import { Container, Card, Col, Row, Button, Alert } from "react-bootstrap";
import CreateButtonComponent from "area/admin/CRUD/CreateButtonComponent";
import ReservationTableList from "./components/ReservationTableList";
import ReservationCancelModal from "./components/ReservationCancelModal";
function UserReservation({ userId, userData }) {
  const [reservations, setReservations] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const url = `/api/customers/${userId}/reservations`;

    axios
      .get(url)
      .then((response) => {
        setReservations(response.data);
      })
      .catch((error) => {
        console.error(
          `There was an error retrieving the reservation data: ${error}`
        );
      });
  }, [userId]);

  const handleIptalClick = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const columns = [
    { label: "#", field: "id" },
    { label: "Code", field: "code" },
    {
      label: "Tour",
      field: "tour",
      render: (tour) => (tour ? tour.tourName : "N/A"),
    },
    {
      label: "Rezervasyon Tarihi",
      field: "reservation_date",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      label: "Son ödeme tarihi",
      field: "payment_deadline",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    { label: "Durum", field: "durum" },
  ];

  const actions = [
    {
      label: "Iptal",
      style: { backgroundColor: "#ff1a1a" },
      onClick: (item) => handleIptalClick(item),
    },
  ];
  console.log(reservations, "reservationsreservations");
  if (!reservations || reservations.length === 0) {
    return (
      <Row className="justify-content-center">
        <div className="d-flex justify-content-between mb-4">
          <h2>Kullanıcı Reservasyon</h2>
        </div>
        <Alert variant="warning">Reservasyon üyeleri bilgisi bulunamadı.</Alert>{" "}
        {/* This line displays the alert */}
      </Row>
    );
  }
  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <h2 className="">Kullanıcı Reservasyon</h2>
        <Button className="mr-2" variant="contained" disableElevation></Button>
      </div>
      <div className="d-flex justify-content-center">
        {reservations.map((reservation, index) => (
          <div key={index} style={{ width: "80%" }}>
            <Card className="shadow">
              <Card.Header>
                <div className="d-flex justify-content-between">
                  <span>
                    {reservation.code} / {reservation.tour.tourName} /{" "}
                    {reservation.tour.category}
                  </span>
                  <span>{reservation.durum}</span>
                </div>
              </Card.Header>
              <Card.Body>
                <div className="d-flex justify-content-between">
                  <Row>
                    <Col xl={6}>{reservation.tour.departureCity}</Col>
                    <Col xl={6}>{reservation.tour.returnCity}</Col>
                    <Col xl={6}>{reservation.tour.endingDate}</Col>
                    <Col xl={6}>{reservation.tour.startingDate}</Col>
                    <Col>{reservation.tour.days}</Col>
                  </Row>
                  <div>ss</div>
                </div>
              </Card.Body>
              <Card.Footer>
                <div className="d-flex justify-content-between">
                  <span>{reservation.reservation_date}</span>
                  <Button onClick={() => handleIptalClick()} variant="danger">
                    İptal
                  </Button>
                </div>
              </Card.Footer>
            </Card>
          </div>
        ))}
        {/**
<div>
<ReservationTableList
data={reservations}
columns={columns}
actions={actions}
/>
</div>

*/}
        <ReservationCancelModal
          show={show}
          handleClose={handleClose}
          userId={userId}
        />
      </div>
    </>
  );
}

export default UserReservation;
