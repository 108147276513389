import { axios } from "common/utils/axios";

export const uploadLogo = async (logoFile, logoClassify) => {
  try {
    const formData = new FormData();
    formData.append("logo", logoFile);
    formData.append("logo_classify", logoClassify);

    const response = await axios.post(`api/upload-logo`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getMainLogo = async (logoType) => {
  try {
    const response = await axios.get(`api/main-logo/${logoType}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchContactDetails = async () => {
  const { data } = await axios.get("/api/contact");
  return data;
};

export const fetchAllLogos = async () => {
  try {
    const response = await axios.get("/api/logos"); // Adjust the URL as necessary
    // Axios automatically returns the response data, so you don't need to manually parse JSON
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : "Network error"
    );
  }
};
export const getListHomePageImagess = () =>
  axios.get(`api/homepage/images`).then((response) => response.data);
