import { axios } from "common/utils/axios";
// https://backend.suarelab.com/api/

export const getPosts = () =>
  axios.get(`api/posts`).then((response) => response.data);
export const getPagenationPost = async ({ queryKey }) => {
  const [, { page = 1, pageSize = 10, search = "" }] = queryKey;
  try {
    const response = await axios.get(
      `/api/getPgenationPost?page=${page}&pageSize=${pageSize}&search=${search}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching pagenation post:", error);
    throw error;
  }
};
export const getLast3Posts = () =>
  axios.get(`api/lastposts`).then((response) => response.data);

export const getPost = (id) => axios.get(`api/posts/${id}`);
export const createPost = (data) => axios.post(`api/posts`, data);

export const updatePost = ({ id, ...data }) => {
  console.log("Updating post with ID:", id);
  console.log("Updating post with ID:", data);

  // Check if FormData has any data

  return axios.put(`api/posts/${id}`, data.data);
};
// export const updatePost = async ({ id, ...data }) => {
//   return axios.put(`api/posts/${id}`, data, {
//     headers: {
//       "Content-Type": "multipart/form-data",
//     },
//   });
// };
export const deletePost = (id) => axios.delete(`api/posts/${id}`);
export const getImage = (avatar) => axios.get(`api/posts/avatar/${avatar}`);
export const getPostBySlug = (slug) => {
  return axios.get(`api/posts/slug/${slug}`).then((response) => response.data);
};
