import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { axios } from "common/utils/axios";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";

import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Form, Row, Col, Button, Modal, Table } from "react-bootstrap";
import { toast } from "react-toastify";

import { faTrash, faEye, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function formatDate(dateString) {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
    hour12: false,
  };
  const date = new Date(dateString);
  return date.toLocaleDateString("tr-TR", options) + " ";
}
export const Offres = () => {
  const { id } = useParams();
  const [customer, setCustomer] = useState("");
  const [toursData, setToursData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [selectedTourPricing, setSelectedTourPricing] = useState([]);
  const [showEditOfferModal, setShowEditOfferModal] = useState(false);
  // const [customersOffersData, setCustomersOffersData] = useState([]);
  const [customerNewOfferData, setCustomerNewOfferData] = useState({
    customer_id: id,
    tour_id: "",
    offer_statu: "",
    stars_level: "",
    prices: [],
  });
  const mutation = useMutation((updatedOfferData) => {
    return axios.put(
      `api/tour-offer/update/${updatedOfferData.id}`,
      updatedOfferData
    );
  });
  const updatedOfferData = {
    ...customerNewOfferData,
    // Make sure you include the offer ID in your customerNewOfferData state or have another way to reference it here
  };
  const handleEditOfferClick = (offer) => {
    debugger;
    setCustomerNewOfferData({
      ...customerNewOfferData,
      id: offer.id,
      customer_id: id,
      tour_id: offer.tour_id.toString(),
      offer_statu: offer.offer_statu,
      stars_level: offer.stars_level,
      prices: offer.prices, // Assuming this is an array of { how_many_people, price, pricing_type }
    });
    console.log(customerNewOfferData, "dataafterthesetcustomerData");
    setSelectedTourPricing(offer.prices); // Make sure this aligns with how you're using it in the modal
    setShowEditOfferModal(true);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerNewOfferData({
      ...customerNewOfferData,
      [name]: value,
    });
  };
  const handleTourChange = (e) => {
    // Update the tour_id in customerNewOfferData when a tour is selected
    setCustomerNewOfferData((prevState) => ({
      ...prevState,
      tour_id: e.target.value,
    }));
  };
  const handleDeleteMeeting = async (offerId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Offer?"
    );
    if (confirmDelete) {
      await axios.delete(`/api/tour-offer/delete/${offerId}`);
    }
  };

  const handleSearch = (e) => {
    setFilterText(e.target.value);
  };
  const {
    data: customersOffersData,
    isLoading,
    error,
  } = useQuery("customersOffers", () =>
    axios.get(`/api/new-offer/${id}`).then((res) => res.data)
  );

  const handleSubmit = async (event) => {
    event.preventDefault();

    const updatedOfferData = {
      ...customerNewOfferData,
      // Make sure you include the offer ID in your customerNewOfferData state or have another way to reference it here
    };

    mutation.mutate(updatedOfferData, {
      onSuccess: () => {
        toast.success("Offer updated successfully!");
        setShowEditOfferModal(false);
        // Optionally, refetch offer data or update local state to reflect the changes
      },
      onError: (error) => {
        toast.error(`An error occurred: ${error.message}`);
      },
    });
  };
  useEffect(() => {
    axios
      .get("api/get-active-tours")
      .then((response) => setToursData(response.data))
      .catch((error) => console.error(error));
  }, []);
  const handlePriceChange = (howManyPeople, value, pricingType) => {
    const newPrices = [...customerNewOfferData.prices];
    const priceIndex = newPrices.findIndex(
      (price) => price.how_many_people === howManyPeople
    );

    if (priceIndex !== -1) {
      newPrices[priceIndex] = {
        ...newPrices[priceIndex],
        price: value,
        pricing_type: pricingType,
      };
    } else {
      newPrices.push({
        how_many_people: howManyPeople,
        price: value,
        pricing_type: pricingType,
      });
    }

    setCustomerNewOfferData((prevState) => ({
      ...prevState,
      prices: newPrices,
    }));
  };
  console.log(customersOffersData, "customersOffersDatacustomersOffersData");
  const card = (
    <React.Fragment>
      <CardContent>
        <Typography sx={{ fontSize: 20 }} color="text.secondary" gutterBottom>
          TEKLİFLER
        </Typography>
      </CardContent>
      <CardActions>
        <Link key="one" to={`CreateOffers`}>
          <Button
            size="medium"
            style={{ backgroundColor: "DodgerBlue", color: "#fff" }}
          >
            Yeni Teklif oluştur
          </Button>
        </Link>
      </CardActions>
    </React.Fragment>
  );
  return (
    <div style={{ border: "solid 1px #e2e2e2" }}>
      <Card variant="outlined">{card}</Card>
      <div
        style={{
          padding: "20px",
        }}
      >
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Müşteri ID</th>
              <th>Turlar</th>
              <th>Fiyat</th>
              <th>Teklif durumu</th>
              <th>Yıldızlama</th>
              <th>Oluşturuldu</th>
              <th>Eylem</th>
            </tr>
          </thead>
          <tbody>
            {customersOffersData?.map((item) => (
              <tr key={item.id}>
                <td>{item?.customer.full_name}</td>
                <td>{item?.tour?.tourName}</td>
                <td>
                  {item.prices?.map((price, index) => (
                    <div key={index}>
                      <span
                        style={{
                          fontSize: "13px",
                          display: "block",
                          marginRight: "3px",
                          backgroundColor: "#e0e0d1",
                          padding: "2px",
                          borderRadius: "4px",
                          marginBottom: "2px",
                        }}
                      >
                        {price.how_many_people} Kişi: {price.price}{" "}
                        {price.pricing_type}
                      </span>
                    </div>
                  ))}
                </td>
                <td>{item.offer_statu}</td>
                <td>{item.stars_level}</td>
                <td>{formatDate(item.created_at)}</td>
                <td>
                  <Button
                    variant="danger"
                    className="mr-2"
                    onClick={() => handleDeleteMeeting(item.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => handleEditOfferClick(item)}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Modal
        size="lg"
        centered
        show={showEditOfferModal}
        onHide={() => setShowEditOfferModal(false)}
      >
        <Modal.Header closeButton>
          <h3>Teklifi Düzenle</h3>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xl={4}>
                <Form.Group controlId="tours">
                  <h4>Tours</h4>
                  <Form.Select
                    name="tour_id"
                    value={customerNewOfferData.tour_id}
                    onChange={handleTourChange}
                  >
                    <option value="" disabled selected>
                      {" "}
                      -- Tur seç --{" "}
                    </option>
                    {toursData.map((tour) => (
                      <option key={tour.tourId} value={tour.tourId}>
                        {tour.tourName}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xl={4}>
                <Form.Group controlId="stars">
                  <h4>İlgi Düzeyi</h4>
                  <Form.Select
                    name="stars_level"
                    value={customerNewOfferData.stars_level}
                    onChange={handleChange}
                  >
                    <option value="Çok Düşük">Çok Düşük</option>
                    <option value="Düşük">Düşük</option>
                    <option value="Orta">Orta</option>
                    <option value="Yüksek">Yüksek</option>
                    <option value="Çok Yüksek">Çok Yüksek</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xl={4}>
                <Form.Group controlId="offers">
                  <h4>Teklif Durumu</h4>
                  <Form.Select
                    name="offer_statu"
                    value={customerNewOfferData.offer_statu}
                    onChange={handleChange}
                  >
                    <option value="Teklif Verildi">Teklif Verildi</option>
                    <option value="Teklif Kabul Edildi">
                      Teklif Kabul Edildi
                    </option>
                    <option value="Teklif Reddedildi">Teklif Reddedildi</option>
                    <option value="Teklif Revize Edildi">
                      Teklif Revize Edildi
                    </option>
                    <option value="Teklif Iptal Edildi">
                      Teklif Iptal Edildi
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>
              {customerNewOfferData.prices.map((price, index) => (
                <Col className="mt-4" xl={3} key={index}>
                  <Form.Group controlId={`pricing-${price.how_many_people}`}>
                    <Form.Label>
                      {price.how_many_people} Kişi - Fiyat ({price.pricing_type}
                      )
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name={`price-${price.how_many_people}`}
                      // Use customerNewOfferData.prices to find the value if editing, else use selectedTourPricing for a new selection
                      value={
                        customerNewOfferData.prices.find(
                          (p) => p.how_many_people === price.how_many_people
                        )?.price || price.mainPrice
                      }
                      onChange={(e) =>
                        handlePriceChange(
                          price.how_many_people,
                          e.target.value,
                          price.pricing_type
                        )
                      }
                      min="0"
                      step="0.01"
                    />
                  </Form.Group>
                </Col>
              ))}
            </Row>
            <div className="d-flex justify-content-end">
              <Button className="mt-4" type="submit">
                Teklif Düzenle
              </Button>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

export default Offres;
