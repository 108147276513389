import React, { useState, createContext, useContext } from "react";
import Header from "common/components/hero/UserHero.js";
import Footer from "common/components/footers/MiniCenteredFooter";
import HeaderContext from "redux/ContextApi/HeaderContext ";
import ContactDataContext from "redux/ContextApi/ContactDataContext";
import { Outlet } from "react-router-dom";
import { useQuery } from "react-query";
import { fetchContactDetails } from "common/Api/SiteSettingsApi";
import BubbleContactUs from "common/components/BubbleContactUs/BubbleContactUs";
import BootstrapV1Footer from "common/components/footers/BootstrapV1Footer";
export const HeaderHeightContext = createContext({
  setHeaderHeight: () => {},
  setHeaderTitlePaddingTop: () => {},
  setHeaderTitlePaddingBottom: () => {},
  setDisplay: () => {},
});

const UserGlobalLayout = () => {
  const [title, setTitle] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [headerHeight, setHeaderHeight] = useState(null);
  const [display, setDisplay] = useState(null);
  const [tabTry, setTabTry] = useState(false);
  const [headerPaddingTop, setHeaderPaddingTop] = useState(null);
  const [headerTitlePaddingTop, setHeaderTitlePaddingTop] = useState(null);
  const [headerTitlePaddingBottom, setHeaderTitlePaddingBottom] =
    useState(null);

  const { data: contactData } = useQuery(
    "contactDetails",
    fetchContactDetails,
    {
      staleTime: 3 * 24 * 60 * 60 * 1000, // 3 days in milliseconds
      refetchOnWindowFocus: true, // Refetch when window gains focus
      onSuccess: (data) => {
        console.log("Data fetched successfully:", data);
      },
    }
  );

  return (
    <ContactDataContext.Provider value={contactData}>
      <HeaderContext.Provider
        value={{ tabTry, title, imageUrl, setTitle, setImageUrl }}
      >
        <HeaderHeightContext.Provider
          value={{
            tabTry,
            headerHeight,
            display,
            headerPaddingTop,
            headerTitlePaddingTop,
            headerTitlePaddingBottom,
            setTabTry,
            setHeaderHeight,
            setDisplay,
            setHeaderPaddingTop,
            setHeaderTitlePaddingTop,
            setHeaderTitlePaddingBottom,
          }}
        >
          <Header
            tabTry={tabTry}
            title={title}
            imageUrl={imageUrl}
            display={display}
            height={headerHeight}
            headerPaddingTop={headerPaddingTop}
            headerTitlePaddingTop={headerTitlePaddingTop}
            headerTitlePaddingBottom={headerTitlePaddingBottom}
          />
          <main>
            <Outlet />
            <BubbleContactUs contactData={contactData} />
          </main>
          <BootstrapV1Footer />
        </HeaderHeightContext.Provider>
      </HeaderContext.Provider>
    </ContactDataContext.Provider>
  );
};

export default UserGlobalLayout;
