import React from "react";
import Slider from "react-slick";
import "assets/styles/HotelList.css";

const HotelImagesSlider = ({ photographs }) => {
  // Assuming photographs is an array of image objects
  const mainImage = photographs.find((photo) => photo.photo_type === "main");
  const insideImages = photographs.filter(
    (photo) => photo.photo_type === "inside"
  );
  const outsideImages = photographs.filter(
    (photo) => photo.photo_type === "outside"
  );

  // Settings for the slider
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="hotel-images-slider">
      <style>
        {`
      .hotel-images-slider .slick-prev {
        left: 0;
        margin-left: -50px;
      }
      .hotel-images-slider .slick-slide {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .hotel-images-slider .slick-next::before,
    .hotel-images-slider .slick-prev::before {
    color: red;
    z-index: 10;
}

    `}
      </style>
      <Slider {...settings}>
        {mainImage && (
          <div className="image-container">
            <img
              src={mainImage.photo_url}
              alt="Main"
              style={{ width: "300px", height: "200px" }}
            />
            <span className="image-label">Main Image</span>
          </div>
        )}
        {insideImages.map((photo, index) => (
          <div key={index} className="image-container">
            <img
              src={photo.photo_url}
              alt={`Inside ${index}`}
              style={{ width: "300px", height: "200px" }}
            />
            <span className="image-label">Inside {index}</span>
          </div>
        ))}
        {outsideImages.map((photo, index) => (
          <div key={index} className="image-container">
            <img
              src={photo.photo_url}
              alt={`Outside ${index}`}
              style={{ width: "300px", height: "200px" }}
            />
            <span className="image-label">Outside {index}</span>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HotelImagesSlider;
