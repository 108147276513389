// Your existing imports...

export const GoogleMapIframe = ({ address }) => {
  console.log(address, "address");
  const { country, state, district } = address;
  console.log(country, "address");
  console.log(state, "address");
  console.log(district, "address");

  const encodedAddress = encodeURIComponent(
    `${district}, ${state}, ${country}`
  );

  const src = `https://www.google.com/maps?q=${encodedAddress}&output=embed`;

  return (
    <iframe
      width="620"
      height="350"
      style={{ border: 0 }}
      loading="lazy"
      allowFullScreen
      src={src}
    ></iframe>
  );
};

export default GoogleMapIframe;
