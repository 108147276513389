import React, { useState } from "react";
import { NavLink as NavLinkRRD } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";
import Header, {
  NavLink,
  NavLinks,
  PrimaryLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../../../../common/components/headers/light.js";

const DropdownContent = styled.div`
  ${tw`hidden absolute bg-white text-black w-48 rounded mt-2 shadow-lg`}
`;

const Dropdown = styled.span`
  ${tw`relative`}

  &:hover ${DropdownContent} {
    ${tw`block`}
    margin-left: 280px;
    z-index: 100;
  }
`;

const DropdownLink = styled(NavLink)`
  ${tw`block px-4 py-2 hover:bg-gray-100`}
`;

export default () => {
  return (
    <Dropdown>
      <NavLink>
        <NavLinkRRD to="/user/profilim">Profilim</NavLinkRRD>
      </NavLink>
      <DropdownContent>
        <DropdownLink>
          <NavLinkRRD to="#">Bilgilerim</NavLinkRRD>
        </DropdownLink>
        <DropdownLink>
          <NavLinkRRD to="#">Teklifleri</NavLinkRRD>
        </DropdownLink>
        <DropdownLink>
          <NavLinkRRD to="#">Ödemi Hareketleri</NavLinkRRD>
        </DropdownLink>
      </DropdownContent>
    </Dropdown>
  );
};
