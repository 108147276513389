import React from "react";
import { useQuery } from "react-query";
import axios from "axios"; // Ensure axios is correctly imported

export const FetchTourImagebase64 = ({ imageId }) => {
  const { data, isLoading, error } = useQuery(
    ["base64Image", imageId],
    async () => {
      const response = await axios.get(
        `http://backend.suarelab.com/api/getBase64tourimage/${imageId}`
      );
      return response.data.base64Image;
    },
    {
      enabled: !!imageId,
    }
  );

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading image</div>;

  return (
    <img
      style={{ height: "190px", borderRadius: "0" }}
      className="responsive-slider-img"
      src={data}
      alt="Tour Image"
    />
  );
};

const fetchLogoBase64 = async () => {
  const { data } = await axios.get(
    "http://backend.suarelab.com/api/getLogoAsBase64"
  );
  return data.base64Image;
};
export const ComponentUsingLogo = () => {
  const { data: logoBase64, isLoading } = useQuery(
    "logoBase64",
    fetchLogoBase64
  );

  if (isLoading) return <div>Loading logo...</div>;

  return (
    <img
      className="mb-4 mt-3"
      width="200"
      src={logoBase64}
      alt="Company Logo"
    />
  );
};
