import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import DatePicker from "../date-picker/DatePicker"; // Adjust the import path as necessary

const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold text-gray-100 min-w-[250px] py-1 rounded-lg hover:bg-[#83C5BE] hover:text-black transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  background-color: #006D77;
`;

export default function UmraSearchingArea() {
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  console.log(dateRange, "dateRange");
  let navigate = useNavigate();

  const handleDateChange = (newValue) => {
    setDateRange(newValue);
  };

  const handleSearch = () => {
    // Convert startDate and endDate from string to Date objects before formatting
    const startDate = dateRange.startDate
      ? new Date(dateRange.startDate)
      : null;
    const endDate = dateRange.endDate ? new Date(dateRange.endDate) : null;

    const formattedStartDate = startDate
      ? startDate.toISOString().split("T")[0]
      : "";
    const formattedEndDate = endDate ? endDate.toISOString().split("T")[0] : "";

    console.log(
      formattedStartDate,
      "formattedStartDate",
      formattedEndDate,
      "formattedEndDate"
    );
    navigate(
      `/tours/Umre?startDate=${formattedStartDate}&endDate=${formattedEndDate}`
    );
  };

  return (
    <div className="w-full">
      <DatePicker onChange={handleDateChange} />
      <div className="flex justify-center">
        <SubmitButton type="button" onClick={handleSearch}>
          <span className="text">Ara</span>
        </SubmitButton>
      </div>
    </div>
  );
}
