import React from "react";
import "../../../../../assets/styles/TourDetails.css";
export const SideBarButtons = (props) => {
  return (
    <div>
      <button
        className="btn btn-success reservation-btn mt-4"
        style={{ fontSize: "35px" }}
        onClick={props.onRoomDetailsClick}
      >
        <span>Hemen Al</span>
      </button>
      <hr></hr>
      <div className="container ">
        <div className="row">
          <div className="mr-auto col-md-12 col-12 pl-0 text-center mb-4">
            <a
              href="https://www.gezintitatil.com/ajax/call-me/0/1251"
              type="button"
              className="btn btn-outline-primary online-btn modal-link call-btn"
              style={{ fontSize: "35px" }}
            >
              {/* <i className="fa fa-phone-volume fa-2x"></i> */}

              <span>Sizi Arayalım</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SideBarButtons;
