import React, { useState, useEffect } from "react";
import { Table, Form, Button, Dropdown } from "react-bootstrap";
import { isObjectEmpty } from "common/helpers/isObjectEmpty";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faSave,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
export const FoodOptions = ({ food_options, updateFoodOptionData }) => {
  const [selectedFoodOptions, setSelectedFoodOptions] = useState([]);
  const [editingFoodOptionIndex, setEditingFoodOptionIndex] = useState(null);
  const [originalFoodOptionData, setOriginalFoodOptionData] = useState(null);

  const foodOptionTypes = ["Sabah Menüsü", "Akşam Menüsü", "Öğlen Menüsü"];
  const foodOptions = [
    "Yemek A",
    "Yemek B",
    "Yemek C",
    "Yemek D",
    "Yemek E",
    "Yemek F",
    "Yemek G",
    "Yemek H",
    "Yemek I",
    "Yemek J",
  ];
  const [foodOptionRows, setFoodOptionRows] = useState(food_options);
  const [newFoodOptionData, setNewFoodOptionData] = useState({
    foodOptionType: "",
    foodOptionName: "",
  });
  const addFoodOptionRow = () => {
    const typeExists = foodOptionRows.some(
      (row) => row.foodOptionType === newFoodOptionData.foodOptionType
    );
    if (typeExists) {
      toast.warn("Bu yiyecek türü eklenmiş.");
      return;
    }

    const newRow = {
      ...newFoodOptionData,
      foodOptionName: selectedFoodOptions.join(", "),
    };
    const newRows = [...foodOptionRows, newRow];
    setFoodOptionRows(newRows);
    updateFoodOptionData(newRows);
    setNewFoodOptionData({ foodOptionType: "", foodOptionName: "" });
    setSelectedFoodOptions([]);
  };

  const removeFoodOptionRow = (index) => {
    const updatedRows = [...foodOptionRows];
    updatedRows.splice(index, 1);
    setFoodOptionRows(updatedRows);
    updateFoodOptionData(updatedRows);
  };
  const handleFoodTypeChange = (e) => {
    console.log("Selected option:", e.target.value);
    setNewFoodOptionData((prevData) => {
      console.log("Previous Data:", prevData);
      console.log("Updated Data:", {
        ...prevData,
        foodOptionType: e.target.value,
      });
      return { ...prevData, foodOptionType: e.target.value };
    });
  };

  const handleCheckboxChange = (option) => {
    setSelectedFoodOptions((prevSelected) => {
      if (prevSelected.includes(option)) {
        return prevSelected.filter((item) => item !== option);
      } else {
        return [...prevSelected, option];
      }
    });
  };
  const editFoodOptionRow = (index) => {
    setEditingFoodOptionIndex(index);
    setOriginalFoodOptionData({ ...foodOptionRows[index] });
  };

  const saveFoodOptionRow = (index) => {
    setEditingFoodOptionIndex(null);
  };

  const cancelFoodOptionEdit = () => {
    const updatedRows = [...foodOptionRows];
    updatedRows[editingFoodOptionIndex] = originalFoodOptionData;
    setFoodOptionRows(updatedRows);
    setEditingFoodOptionIndex(null);
  };
  const handleRowChange = (e, field, index) => {
    const updatedRows = [...foodOptionRows];
    updatedRows[index] = { ...updatedRows[index], [field]: e.target.value };
    setFoodOptionRows(updatedRows);
  };

  useEffect(() => {
    if (food_options) {
      const filteredFood_options = food_options.filter(
        (acc) => !isObjectEmpty(acc)
      );
      setFoodOptionRows(filteredFood_options);
    } else {
      setFoodOptionRows([]);
    }
  }, [food_options]);

  return (
    <div>
      <h5>Yemekler</h5>
      <Table responsive style={{ marginBottom: "150px" }}>
        <thead>
          <tr>
            <th>Öğün</th>
            <th>Yemek seçeneği</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Form.Group controlId="foodOptionType">
                <Form.Select
                  value={newFoodOptionData.foodOptionType} // Use the state variable here
                  name="foodOptionType"
                  onChange={handleFoodTypeChange} // Corrected event handler
                >
                  {foodOptionTypes.map((foodOptionType, index) => (
                    <option key={index} value={foodOptionType}>
                      {foodOptionType}
                    </option>
                  ))}
                  <option value="" hidden>
                    Yiyecek türü seç
                  </option>
                </Form.Select>
              </Form.Group>
            </td>
            <td>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  Yemek Seç
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{ maxHeight: "130px", overflowY: "scroll" }}
                >
                  {foodOptions.map((option, index) => (
                    <Form.Check
                      style={{
                        marginLeft: "15px",
                        marginButtom: "12px",
                      }}
                      type="checkbox"
                      id={`checkbox-${index}`}
                      label={option}
                      key={index}
                      checked={selectedFoodOptions.includes(option)}
                      onChange={() => handleCheckboxChange(option)}
                    />
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </td>

            <td>
              <Button
                active={true}
                variant="success"
                onClick={addFoodOptionRow}
              >
                <FontAwesomeIcon icon={faSave} />
              </Button>
            </td>
          </tr>
          {foodOptionRows?.map((row, index) => {
            const isEditing = index === editingFoodOptionIndex;
            return (
              <tr key={index}>
                <td>
                  {isEditing ? (
                    <Form.Select
                      value={row.foodOptionType}
                      onChange={(e) =>
                        handleRowChange(e, "foodOptionType", index)
                      }
                    >
                      {foodOptionTypes.map((type, typeIndex) => (
                        <option key={typeIndex} value={type}>
                          {type}
                        </option>
                      ))}
                    </Form.Select>
                  ) : (
                    row.foodOptionType
                  )}
                </td>
                <td>
                  {isEditing ? (
                    // Assuming you want to edit the food option names with a dropdown or checkbox
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Yemek Seç
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        style={{ maxHeight: "130px", overflowY: "scroll" }}
                      >
                        {foodOptions.map((option, optionIndex) => (
                          <Form.Check
                            style={{ marginLeft: "15px", marginButtom: "12px" }}
                            type="checkbox"
                            id={`checkbox-${optionIndex}`}
                            label={option}
                            key={optionIndex}
                            checked={selectedFoodOptions.includes(option)}
                            onChange={() => handleCheckboxChange(option)}
                          />
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    row.foodOptionName
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <>
                      <Button
                        variant="warning"
                        onClick={() => saveFoodOptionRow(index)}
                        style={{ marginRight: "5px" }}
                      >
                        <FontAwesomeIcon icon={faSave} />
                      </Button>
                      <Button
                        variant="secondary"
                        style={{ marginRight: "5px" }}
                        onClick={() => cancelFoodOptionEdit()}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="warning"
                      onClick={() => editFoodOptionRow(index)}
                      style={{ marginRight: "5px" }}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  )}
                  <Button
                    variant="danger"
                    onClick={() => removeFoodOptionRow(index)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};
export default FoodOptions;
