import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { fetchContactDetails } from "common/Api/SiteSettingsApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faEnvelope,
  faPhone,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faTwitter,
  faGoogle,
  faInstagram,
  faLinkedinIn,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
function HomePageFooterBootstrapV1() {
  const { data: contactData } = useQuery(
    "contactDetails",
    fetchContactDetails,
    {
      staleTime: 3 * 24 * 60 * 60 * 1000, // 3 days in milliseconds
      refetchOnWindowFocus: true, // Refetch when window gains focus
      onSuccess: (data) => {
        console.log("Data fetched successfully:", data);
      },
    }
  );
  const footerLogoUrl = "https://suarelab.com/MainLogo/footer.png";
  const [preloadedFooterLogo, setPreloadedFooterLogo] = useState(null);
  useEffect(() => {
    const img = new Image();
    img.onload = () => setPreloadedFooterLogo(footerLogoUrl);
    img.src = footerLogoUrl;
  }, []);
  const socialMediaLinks = [
    {
      platform: "Facebook",
      icon: faFacebookF,
      link: contactData?.data?.facebook,
    },
    { platform: "Twitter", icon: faTwitter, link: contactData?.data?.twitter },
    {
      platform: "Instagram",
      icon: faInstagram,
      link: contactData?.data?.instagram,
    },
    {
      platform: "LinkedIn",
      icon: faLinkedinIn,
      link: contactData?.data?.linkedin,
    },
    { platform: "YouTube", icon: faYoutube, link: contactData?.data?.youtube },
    // Google is shown without checking for a link, adjust as needed
    { platform: "Google", icon: faGoogle, link: "#" },
  ];
  return (
    <div>
      <footer
        className="text-center text-lg-start #243E63"
        style={{ backgroundColor: "rgb(232, 232, 232)" }}
      >
        <div className="container p-4 pb-0">
          <section>
            <div className="row">
              <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                <Link to="/">
                  {preloadedFooterLogo ? (
                    <img src={preloadedFooterLogo} width="240" />
                  ) : (
                    <h6 className="text-uppercase mb-4 font-weight-bold">
                      Company name
                    </h6>
                  )}
                </Link>

                <p className="mt-2">
                  Here you can use rows and columns to organize your footer
                  content. Lorem ipsum dolor sit amet, consectetur adipisicing
                  elit.
                </p>
              </div>

              <hr className="w-100 clearfix d-md-none" />

              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">
                  BAĞLANTILAR
                </h6>

                <p>
                  <Link className=" d-flex align-items-center" to={"/"}>
                    <span> Ana sayfa</span>
                  </Link>
                </p>
                <p>
                  <Link className=" d-flex align-items-center" to={"/tours"}>
                    <span> Tüm İlanlar</span>
                  </Link>
                </p>
                <p>
                  <Link className=" d-flex align-items-center" to={"/otels"}>
                    <span> Tüm Oteller</span>
                  </Link>
                </p>

                <p>
                  <Link
                    className=" d-flex align-items-center"
                    to={"/contactUs"}
                  >
                    <span> İletİşİm</span>
                  </Link>
                </p>
              </div>

              <hr className="w-100 clearfix d-md-none" />

              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">
                  İletİşİm
                </h6>
                <p>
                  <a href={contactData?.data?.address_link}>
                    <FontAwesomeIcon icon={faHome} className="mr-2" />{" "}
                    {contactData?.data?.open_address}
                  </a>
                </p>

                <p>
                  <a href={`mailto:${contactData?.data?.email}`}>
                    <FontAwesomeIcon icon={faEnvelope} className="mr-3" />{" "}
                    {contactData?.data?.email}
                  </a>
                </p>
                <p>
                  <FontAwesomeIcon icon={faPhone} className="mr-3" />{" "}
                  {contactData?.data?.telefon}
                </p>
              </div>

              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">
                  BİZİ TAKİP EDİN
                </h6>
                <div className="row text-white">
                  {socialMediaLinks.map(
                    (social, index) =>
                      social.link && (
                        <div key={index} className="col-4 mb-2">
                          {" "}
                          {/* Adjust col-4 to manage the width */}
                          <a
                            className="btn btn-primary btn-floating m-1"
                            style={{ backgroundColor: social.color }}
                            href={social.link}
                            role="button"
                          >
                            <FontAwesomeIcon icon={social.icon} />
                          </a>
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>

        <div
          className="text-center p-3"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
        >
          © 2024 Suare Tursis By SuareSoft
        </div>
      </footer>
    </div>
  );
}

export default HomePageFooterBootstrapV1;
