function RenderHTMLContent({ htmlContent, marginB, marginT, Color }) {
  return (
    <div
      style={{
        marginBottom: { marginB },
        marginTop: { marginT },
        color: Color,
      }}
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
}

export default RenderHTMLContent;
