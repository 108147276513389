import React, { useState, lazy, Suspense } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useParams } from "react-router-dom";
import { axios } from "common/utils/axios";
import { useQuery } from "react-query";
import { getCustomerData } from "common/Api/CustomerApi";
// import { useCustomerByIdData } from "common/hooks/useCustomerByIdData ";
import Meetings2 from "./CustomerDetailsComponents/Meetings2";
import { OffersAndSales } from "./CustomerDetailsComponents/OffersAndSales";
import EditCustomer from "./ServiceCustomersComponents/Update/EditCustomer";
const GeneralInfo = lazy(() =>
  import("./CustomerDetailsComponents/GeneralInfo")
);
const PaymentHistory = lazy(() =>
  import("./CustomerDetailsComponents/PaymentHistory")
);
const Addres = lazy(() => import("./CustomerDetailsComponents/Addres"));
const Family = lazy(() => import("./CustomerDetailsComponents/Family"));
const OthorsInfo = lazy(() => import("./CustomerDetailsComponents/OthorsInfo"));
const Documentaion = lazy(() =>
  import("./CustomerDetailsComponents/Documentaion")
);

const Meetings = lazy(() => import("./CustomerDetailsComponents/Meetings"));
const Offres = lazy(() => import("./CustomerDetailsComponents/Offres"));
const Sales = lazy(() => import("./CustomerDetailsComponents/Sales"));

export const CustomerDetails = () => {
  const { id } = useParams();
  const [value, setValue] = useState("1");
  const [openEdit, setOpenEdit] = useState(false);
  const {
    data: customerData,
    isLoadingCustomer,
    errorCustomer,
  } = useQuery(["customerData2", id], () => getCustomerData(id));
  if (isLoadingCustomer) {
    return <div>loading...</div>;
  }
  console.log(customerData, "customerData");

  // const { customerData, error, isLoading } = useCustomerByIdData(id);

  const handleChange = (event, newValue) => {
    setValue(newValue.toString());
  };

  const createdDate = new Date(customerData && customerData.created_at);

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const formattedDate = createdDate.toLocaleString("en-US", options);
  const anotherInfoData = {
    CreatedDate: customerData?.CreatedDate,
    Gender: customerData?.Gender,
    BirthDate: customerData?.BirthDate,
    Eposta: customerData?.Eposta,
    jop: customerData?.jop,
    explanation: customerData?.explanation,
  };
  return (
    <div className="mb-4">
      <div
        className="mb-4"
        style={{
          color: "white",
          backgroundColor: "#636665",
          borderRadius: "5px",
          padding: "10px",
          fontSize: "24px",
        }}
      >
        <h2>
          {customerData?.FirstName} {customerData?.LastName}
        </h2>
        <h6 style={{ color: "rgb(197, 197, 187)", marginBottom: "10px" }}>
          {customerData?.jop}
        </h6>
      </div>
      <Box
        sx={{
          width: "100%",
          typography: "body1",
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          marginBottom: "20px",
        }}
      >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="GENEL BİLGİLERİ" value="1" />
              <Tab label="ADRES" value="2" />
              <Tab label="AİLE BİLGİLERİ" value="3" />
              <Tab label="DİĞER BİLGİLERİ" value="4" />
              <Tab label="DOKÜMANLAR" value="5" />
              <Tab label="Ödeme Hareketleri" value="6" />
            </TabList>
          </Box>
          <Suspense fallback={<div>Loading...</div>}>
            <TabPanel value="1">
              <GeneralInfo
                openEdit={openEdit}
                setOpenEdit={setOpenEdit}
                customerData={customerData}
                formattedDate={formattedDate}
              />
            </TabPanel>
            <TabPanel value="2">
              <Addres customerAdresses={customerData?.addresses} />
            </TabPanel>
            <TabPanel value="3">
              <Family
                id={id}
                customerFamilyMembers={customerData?.customer_families}
              />
            </TabPanel>
            <TabPanel value="4">
              <OthorsInfo
                anotherInfoData={anotherInfoData}
                customerData={customerData}
                formattedDate={formattedDate}
                registrationTypeId={
                  customerData && customerData.registrationTypeId
                }
              />
            </TabPanel>
            <TabPanel value="5">
              <Documentaion
                id={id}
                customerDocuments={customerData?.documents}
              />
            </TabPanel>
          </Suspense>
        </TabContext>
      </Box>
      <Suspense fallback={<div>Loading...</div>}>
        <Meetings2 />
        <OffersAndSales />
        {/*<Meetings />*/}
        {/**
         */}
      </Suspense>
    </div>
  );
};
export default CustomerDetails;
