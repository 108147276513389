import React, { useState, useEffect } from "react";
import Cards from "./Cards";
import { axios } from "common/utils/axios";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
export const ShowMeetings = () => {
  const { customerId, id } = useParams();
  const [lastMeeting, setLastMeeting] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  useEffect(() => {
    axios
      .get(`api/meetings/${id}`)
      .then((response) => setLastMeeting(response.data))
      .catch((error) => console.error(error));
  }, [id]);
  console.log(lastMeeting, "lastMeetinglastMeetinglastMeetinglastMeeting");
  useEffect(() => {
    axios
      .get(`api/customers/${customerId}`)
      .then((response) => setCustomerData(response.data))
      .catch((error) => console.error(error));
  }, [id]);
  console.log(lastMeeting, "meet");
  console.log(id, "id");
  return (
    <div>
      <h1>Görüşme Detayları</h1>
      <div className="row">
        <div className="col-8">
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography variant="h5" component="div" className="mb-4">
                Arama Bilgileri
              </Typography>
              <Typography variant="body2">
                <div className="row">
                  <div className="col-6"> Tarihi Ve Saat</div>
                  <div className="col-6">
                    {" "}
                    {new Date(lastMeeting.created_at).toLocaleString([], {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    })}
                  </div>
                  <div className="col-6">Yön</div>
                  <div className="col-6">{lastMeeting.Direction}</div>
                  <div className="col-6">Not</div>
                  <div className="col-6">{lastMeeting.Note}</div>
                </div>
                <br />
              </Typography>
            </CardContent>
          </Card>
        </div>
        <div className="col-4">
          <div className="mb-4">
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography variant="h5" component="div" className="mb-4">
                  Müşteri
                </Typography>
                <Typography variant="body2">
                  <div className="row">
                    <div className="col-6">Ad Soyad</div>
                    <div className="col-6">
                      {customerData.FirstName} {customerData.LastName}
                    </div>
                    <div className="col-6">Numarası</div>
                    <div className="col-6"> {customerData.Telefon}</div>
                  </div>
                  <br />
                </Typography>
              </CardContent>
            </Card>
          </div>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography variant="body2">
                <div className="row">
                  <div className="col-6">Ekleyen Kullanıcı</div>
                  <div className="col-6"></div>
                </div>
                <br />
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default ShowMeetings;
