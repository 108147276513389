import CallerTable from "area/admin/Dashboard/components/CallerTable";
import { Box } from "@mui/material";
import { CardHeader } from "area/admin/CRUD/CardHeader";
import { Row, Col, Card } from "react-bootstrap";
import CompanysTable from "area/admin/Dashboard/components/CompanysTable";
import Chart1 from "area/admin/Dashboard/components/Chart1";
import Chart2 from "area/admin/Dashboard/components/Chart2";
import MainCardsDashboard from "area/admin/Dashboard/components/MainCardsDashboard";
export default function Dashboard() {
  return (
    <div>
      <Card>
        <CardHeader Title={"Gösterge Paneli"} />
        <div
          style={{
            alignItems: "center",
            marginLeft: "10px",
            marginRight: "10px",
            marginTop: "10px",
          }}
        >
          <MainCardsDashboard />
        </div>
        <Row className="mb-4">
          <Col xs={12} md={12} xl={9} xxl={8}>
            <ul>
              <CallerTable />
              <CompanysTable />
            </ul>
          </Col>
          <Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={4}>
            <ul className="mr-2">
              <Chart1 />
              <Chart2 />
            </ul>
          </Col>
        </Row>
      </Card>
    </div>
  );
}
