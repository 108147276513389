import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UseTourDetails from "../../../../../common/utils/functions/UseTourDetails";
export const TopSection = () => {
  /****  Start Reseve Data From Database   **** */
  const { id } = useParams();
  const tour = UseTourDetails(id);
  if (!tour) {
    return <div>Loading...</div>;
  }
  /****  End Reseve Data From Database   **** */
  return (
    <div>
      {" "}
      <div className="hotel-content-flex">
        <div className="row">
          <div className="mr-auto col-md-6 col-12">
            <h4>{tour?.title}</h4>
          </div>
          <div className="live-support text-right col-md-6 col-12">
            <h5>7/24 Canlı Destek 444 28 29</h5>
          </div>
          <div className="clearfix">her gonna be beffor</div>
        </div>
      </div>
    </div>
  );
};
export default TopSection;
