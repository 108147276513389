import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { fetchHotel } from "common/Api/HotelsApi";
import { HeaderHeightContext } from "layouts/GlobalLayout";
import { SearchFilter } from "./components/SearchFilter";
import AnimationRevealPage from "../../common/helpers/AnimationRevealPage";
import MainFeature from "../../common/components/features/TwoColSingleFeatureWithStats.js";
import Tabs from "../../common/components/cards/OtelDetails";
import Search from "common/components/searching/OtelSearchingArea.js";
//Todo
import UseOtelDetails from "../../common/utils/functions/UseOtelDetails";
import { axios } from "common/utils/axios";

export default () => {
  /****  End Reseve Data From Database   **** */
  const { hotelName } = useParams();
  const headerHeightContext = useContext(HeaderHeightContext);
  const {
    setDisplay,
    setHeaderHeight,
    setHeaderTitlePaddingTop,
    setHeaderTitlePaddingBottom,
  } = headerHeightContext;
  const {
    data: otelData,
    isLoading,
    isError,
  } = useQuery(["hotelsByName", hotelName], () => fetchHotel(hotelName), {
    enabled: !!hotelName, // Only run the query if the hotelName is not null or undefined
  });
  useEffect(() => {
    setDisplay("none");
    setHeaderHeight("0px"); // Set the header height to 200px
    setHeaderTitlePaddingTop("0px");
    setHeaderTitlePaddingBottom("0px");
    return () => {
      setHeaderHeight(null);
      setHeaderTitlePaddingBottom(null);
      setHeaderTitlePaddingTop(null); // Reset the header height when component unmounts
    };
  }, [setHeaderHeight]);
  console.log(otelData, "otel");
  console.log(hotelName, "id");
  if (!otelData) {
    return <div>Loading...</div>;
  }
  console.log("This is the oteeeel", otelData);
  return (
    <div>
      <AnimationRevealPage>
        <MainFeature otel={otelData} />
      </AnimationRevealPage>
    </div>
  );
};
