import React, { useState, lazy, Suspense } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Meetings from "./Meetings.js";
import NoteSlider from "../CustomerDetailsComponents/components/NoteSlider.js";
import { useCustomerByIdData } from "common/hooks/useCustomerByIdData ";
import { useParams } from "react-router-dom";
import { axios } from "common/utils/axios";
import MeetingNote from "./MeetingNote.js";
import "./components/NoteSlider";
import FutureMeeting from "./FutureMeeting.js";
import Notlar from "./components/Notlar.js";
export const Meetings2 = ({ userData }) => {
  const { id } = useParams();
  const [value, setValue] = useState("1");
  const effectiveId = id || userData.id;
  const customerData = useCustomerByIdData(effectiveId);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const createdDate = new Date(customerData.created_at);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const formattedDate = createdDate.toLocaleString("en-US", options);
  return (
    <div className="mb-4">
      <Box
        className="parent"
        sx={{
          width: "100%",
          typography: "body1",
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          marginBottom: "20px",
        }}
      >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="GELECEK GÖRUŞMELER" value="1" />
              <Tab label="NOTLAR" value="2" />
              <Tab label="GÖRUŞMELER" value="3" />
            </TabList>
          </Box>
          <Suspense fallback={<div>Loading...</div>}>
            <TabPanel value="1">
              <FutureMeeting />
            </TabPanel>
            <TabPanel value="2">
              <NoteSlider />
            </TabPanel>
            <TabPanel value="3">
              <Meetings effectiveId={effectiveId} />
            </TabPanel>
            <TabPanel value="4"></TabPanel>
            <TabPanel value="5"></TabPanel>
          </Suspense>
        </TabContext>
      </Box>
      <Suspense fallback={<div>Loading...</div>}></Suspense>
    </div>
  );
};
export default Meetings2;
