import { useState, useEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";
import {
  getCountries,
  getStates,
  getCities,
} from "common/Api/UniversalTutorialApi";
import MapContainer from "common/components/SelectingMap/Leaflet";
export const HotelAddress = ({ formData, updateFormData }) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  useEffect(() => {
    // Fetch countries when component mounts
    const fetchCountries = async () => {
      const countriesData = await getCountries();
      setCountries(countriesData);
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    // Fetch states when country changes
    const fetchStates = async () => {
      if (formData.otel_address.country) {
        const statesData = await getStates(formData.otel_address.country);
        setStates(statesData);
      }
    };
    fetchStates();
  }, [formData.otel_address.country]);

  useEffect(() => {
    // Fetch cities when state changes
    const fetchCities = async () => {
      if (formData.otel_address.state) {
        const citiesData = await getCities(formData.otel_address.state);
        setCities(citiesData);
      }
    };
    fetchCities();
  }, [formData.otel_address.state]);

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    updateFormData({
      ...formData,
      otel_address: {
        ...formData.otel_address,
        [name]: value,
      },
    });
  };
  const onLocationSelect = (addressData) => {
    updateFormData({
      ...formData,
      otel_address: {
        ...formData.otel_address,
        street_address: addressData.display_name, // Update the street address field
        neighborhood: addressData.address.city_district, // Update the street address field
        country: addressData.address.country, // Update the street address field
        district: addressData.address.province, // Update the street address field
        postal_code: addressData.address.postcode, // Update the street address field
        // Optionally, update other address fields based on addressData
      },
    });
  };
  console.log(countries, "countries");
  console.log(formData, "formData");
  return (
    <div>
      <h5>Address</h5>
      <Row>
        <Col xl={3}>
          <Form.Group className="mb-3">
            <Form.Label>Country (Ulke)</Form.Label>
            <Form.Select
              name="country"
              value={formData.otel_address.country}
              onChange={handleAddressChange}
            >
              <option>Choose Country...</option>
              {countries.map((country) => (
                <option key={country.country_name} value={country.country_name}>
                  {country.country_name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>

        <Col xl={3}>
          <Form.Group className="mb-3">
            <Form.Label>State/Province (Il)</Form.Label>
            <Form.Select
              name="state"
              value={formData.otel_address.state}
              onChange={handleAddressChange}
            >
              <option>Choose State/Province...</option>
              {states.map((state) => (
                <option key={state.state_name} value={state.state_name}>
                  {state.state_name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>

        <Col xl={3}>
          <Form.Group className="mb-3">
            <Form.Label>District (Ilce)</Form.Label>
            <Form.Select
              name="district"
              value={formData.otel_address.district}
              onChange={handleAddressChange}
            >
              <option>Choose District...</option>
              {cities.map((city) => (
                <option key={city.city_name} value={city.city_name}>
                  {city.city_name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>

        <Form.Group className="mb-3">
          <Form.Label>Full Street Address (Acik Adres)</Form.Label>
          <Form.Control
            type="text"
            name="street_address"
            placeholder="Full Street Address"
            value={formData.otel_address.street_address}
            onChange={handleAddressChange}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Postal Code (Postal Kodu)</Form.Label>
          <Form.Control
            type="text"
            name="postal_code"
            placeholder="Postal Code"
            value={formData.otel_address.postal_code}
            onChange={handleAddressChange}
          />
        </Form.Group>
        <div className="d-flex justify-content-center">
          <MapContainer
            onLocationSelect={onLocationSelect}
            Width="85%"
            Height="350px"
          />
        </div>
      </Row>
    </div>
  );
};
export default HotelAddress;
