import React, { useState, useEffect, useRef } from "react";
import { axios } from "common/utils/axios";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import CreatCustomer from "area/admin/CRUD/CreateButtonComponent";
import LineAdvance from "area/admin/CRUD/LineAdvance";
import CustomerListTable from "./Components/CustomerListTable";
import { useCustomerPagenationList } from "common/hooks/useCustomerPagenationList";
import PaginationComponent from "./Components/CustomerListPagenation";
import SearchFilterComponent from "area/admin/CRUD/ReactBootsrap/SearchFilterComponent";
import DropDownInputBootsrap from "area/admin/CRUD/ReactBootsrap/DropDownInputBootsrap";
import { useQuery, useQueryClient } from "react-query";
import DateInputBootsrap from "area/admin/CRUD/ReactBootsrap/DateInputBootsrap";
import "assets/styles/CustomerManger.css";
import { faFilter, faSearch, faSync } from "@fortawesome/free-solid-svg-icons";
import CreatCustomerModal from "area/admin/CustomerMangement/ServiceCustomersComponents/Create/CreatCustomerModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { toast } from "react-toastify";
import {
  Pagination,
  Card,
  Row,
  Col,
  Dropdown,
  Form,
  Button,
  Modal,
} from "react-bootstrap";

const activeNonActive = [
  { label: "Aktif", value: "aktif" }, // Correct value to trigger active filter
  { label: "Pasif", value: "pasif" }, // Assumed correct value for inactive
];

export const Customers = () => {
  const maxPagesToShow = 16;

  const dropdownRef = useRef(null);
  const queryClient = useQueryClient();
  const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(6);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showCreatCustomer, setShowCreatCustomer] = useState(false);
  const [customersData, setCustomersData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [searchFilter, setSearchFilter] = useState({
    firstName: "",
    lastName: "",
    telefon: "",
  });
  const [filterResult, setfilterResult] = useState([]);

  const {
    customerData: customerDataFromHook,
    error,
    isLoading,
  } = useCustomerPagenationList(currentPage + 1, pageSize, searchFilter);

  const initialFilter = {
    //Change the data
    FirstName: "",
    IsActive: "",
    Telefon: "",
    Eposta: "",
    jop: "",
    isActive: "",
  };
  const [filter, setFilter] = useState(initialFilter);
  const [customerToUpdate, setCustomerToUpdate] = useState({
    id: null,
    isActive: null,
  });

  useEffect(() => {
    // Directly set customersData to the array inside the `data` property
    setCustomersData(customerDataFromHook?.data || []);

    // If you need to use total pages and total items elsewhere in your component
    setTotalPages(customerDataFromHook?.last_page || 0);
    setTotalItems(customerDataFromHook?.total || 0);

    // Use totalPages and totalItems as needed, or set them to state if necessary
  }, [customerDataFromHook]);
  useEffect(() => {
    // Step 2: Set up an event listener for clicks
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false); // Step 3: Determine click inside or outside
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Step 4: Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleDelete = (customerId) => {
    if (window.confirm("Are you sure you want to delete this customer?")) {
      axios
        .delete(`/api/customers/delete/${customerId}`)
        .then((response) => {
          setCustomersData(
            customersData.filter((customer) => customer.id !== customerId)
          );
        })
        .catch((error) => {
          // handle error response
        });
    }
  };
  const applyFilters = () => {
    queryClient.invalidateQueries("hotels");
  };
  const pagesCount = Math.ceil(customersData.length / pageSize);
  let startPage, endPage;
  if (pagesCount <= maxPagesToShow) {
    startPage = 0;
    endPage = pagesCount;
  } else {
    // More than maxPagesToShow total pages, so calculate start and end pages
    if (currentPage <= halfMaxPagesToShow) {
      startPage = 0;
      endPage = maxPagesToShow;
    } else if (currentPage + halfMaxPagesToShow >= pagesCount) {
      startPage = pagesCount - maxPagesToShow;
      endPage = pagesCount;
    } else {
      startPage = currentPage - halfMaxPagesToShow;
      endPage = currentPage + halfMaxPagesToShow;
    }
  }

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  // PaginationcurrentPage
  const pages = [];
  for (let i = 0; i < Math.ceil(customersData.length / pageSize); i++) {
    pages.push(
      <Pagination.Item
        key={i}
        active={i === currentPage}
        onClick={() => setCurrentPage(i)}
      >
        {i + 1}
      </Pagination.Item>
    );
  }
  const dataToDisplay = filterResult.length > 0 ? filterResult : customersData;
  const paginatedData = customersData.slice(
    currentPage * pageSize,
    (currentPage + 1) * pageSize
  );
  const handleOpenModal = (customerId) => {
    setCustomerId(customerId);
    setOpenEdit(true);
  };
  // Filter Methodes
  const handleClearFilter = () => {
    setFilter({
      FirstName: "",
      IsActive: "",
      Telefon: "",
      Eposta: "",
      jop: "",
    });
  };

  //S3
  const handleSearchFilter = (e) => {
    e.preventDefault();
    const url = `/api/customers/filter`;

    const dataToSend = {
      ...filter,
      name_or_code: searchFilter,
    };

    axios
      .post(url, dataToSend)
      .then((response) => {
        setfilterResult(response.data);
        console.log(filterResult, "filterResult");
      })
      .catch((error) => {
        console.error(
          `There was an error retrieving the reservation data: ${error}`
        );
      });
  };
  const handleFilterInputChange = (field, value) => {
    setFilter({ ...filter, [field]: value });
  };
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>An error occurred: {error.message}</div>;
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  console.log(customerDataFromHook, "customerDataFromHook");

  return (
    <div>
      <Card style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)" }}>
        <div className="mb-4">
          <Card.Header
            style={{
              backgroundColor: "#636665",
              color: "white",
              borderRadius: "5px 5px 0 0",
            }}
          >
            <Row className="pt-3">
              <Col>
                <h1 style={{ padding: "10px", fontSize: "24px", margin: 0 }}>
                  Müşteri Yönetimi
                </h1>
              </Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <CreatCustomer
                  buttonTitle={"Müşteri Ekle"}
                  onClick={setShowCreatCustomer}
                  title=""
                />
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md={6} sm={12}>
                <h4>Sistemde Kayıtlı Müşterler Listeleniyor</h4>
              </Col>
              <Col>
                <SearchFilterComponent setSearchFilter={setSearchFilter} />
              </Col>
              <Col md={2} sm={2}>
                <div ref={dropdownRef}>
                  <Dropdown show={isDropdownOpen}>
                    <Dropdown.Toggle
                      onClick={toggleDropdown}
                      style={{
                        border: "none",
                        backgroundColor: "#1cab1c",
                        fontWeight: "700",
                        borderRadius: "7px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                      }}
                      id="dropdown-basic"
                    >
                      Gelişmiş Arama{" "}
                      <FontAwesomeIcon icon={faFilter} className="ml-2" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      className="dropdown-menu-custom"
                      style={{
                        maxHeight: "400px",
                        padding: "15px",
                      }}
                    >
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form onSubmit={handleSearchFilter}>
                          <Row>
                            <Col md={5}>
                              <DropDownInputBootsrap
                                id={"IsActive"}
                                value={searchFilter?.isActive}
                                onChange={(e) =>
                                  setSearchFilter({
                                    ...searchFilter,
                                    isActive: e.target.value,
                                  })
                                }
                                options={activeNonActive}
                                label={"aktif / pasif"}
                              />
                            </Col>

                            <Col md={6}>
                              <DateInputBootsrap
                                id={"creat_date"}
                                type={"date"}
                                label={"Oluşturma Tarih"}
                                onChange={handleFilterInputChange}
                              />
                            </Col>

                            <Col md={1} xl={1}>
                              <Row>
                                <Col className="mb-2 mt-2">
                                  {" "}
                                  <Button
                                    style={{
                                      backgroundColor: "#007bff",
                                      color: "white",
                                      padding: "8px 10px",
                                      borderRadius: "5px",
                                      border: "none",
                                      boxShadow:
                                        "0px 2px 4px rgba(0, 0, 0, 0.2)",
                                      fontWeight: "bold",
                                      cursor: "pointer",
                                      transition: "background-color 0.3s ease",
                                      display: "flex",
                                      alignItems: "center",
                                      marginRight: "10px",
                                    }}
                                    type="submit"
                                    onMouseEnter={(e) =>
                                      (e.target.style.backgroundColor =
                                        "#004080")
                                    }
                                    onMouseLeave={(e) =>
                                      (e.target.style.backgroundColor =
                                        "#007bff")
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={faSearch}
                                      style={{
                                        fontSize: "15px",
                                        marginRight: "2px",
                                      }}
                                    />{" "}
                                  </Button>
                                </Col>
                                <Col>
                                  <Button
                                    style={{
                                      backgroundColor: "#7c6b6b",
                                      color: "white",
                                      padding: "8px 10px",
                                      borderRadius: "5px",
                                      border: "none",
                                      boxShadow:
                                        "0px 2px 4px rgba(0, 0, 0, 0.2)",
                                      fontWeight: "bold",
                                      cursor: "pointer",
                                      transition: "background-color 0.3s ease",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={handleClearFilter}
                                    onMouseEnter={(e) =>
                                      (e.target.style.backgroundColor =
                                        "#584242")
                                    }
                                    onMouseLeave={(e) =>
                                      (e.target.style.backgroundColor =
                                        "#7c6b6b")
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={faSync}
                                      style={{ fontSize: "16px" }}
                                    />
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Form>
                      </Row>
                      {/* Dropdown items here */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </Row>

            <LineAdvance />
            <CustomerListTable
              handleOpenModal={handleOpenModal}
              setCustomersData={setCustomersData}
              setCustomerToUpdate={setCustomerToUpdate}
              customersData={customersData}
              customerToUpdate={customerToUpdate}
              handleDelete={handleDelete}
              handleCloseEdit={handleCloseEdit}
              setOpenEdit={setOpenEdit}
              openEdit={openEdit}
              paginatedData={paginatedData}
              customerId={customerId}
              open={open}
              setOpen={setOpen}
            />
            <PaginationComponent
              queryClient={queryClient}
              applyFilters={applyFilters}
              setSearchFilter={setSearchFilter}
              totalItems={totalItems}
              itemsPerPage={pageSize}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              onChangePage={(page) => {
                console.log("Changing to page:", page);
                setCurrentPage(page);
              }}
              onChangePageSize={(size) => {
                setPageSize(parseInt(size, 10));
                setCurrentPage(0); // Reset to first page after changing page size
              }}
              totalPages={totalPages}
            />
          </Card.Body>
        </div>
      </Card>
      {/**ToDO Making Creat Customer Modal */}
      <CreatCustomerModal
        showCreatCustomer={showCreatCustomer}
        setShowCreatCustomer={setShowCreatCustomer}
      />
    </div>
  );
};
export default Customers;
