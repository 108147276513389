import { axios } from "common/utils/axios";

export const fetchReservations = async (
  page,
  pageSize,
  search = "",
  filters = {}
) => {
  const { data } = await axios.get("/api/reservation_management", {
    params: { page, pageSize, search, ...filters }, // Include search parameter in the request
  });
  return data;
};
