import React from "react";
import { Pagination, Dropdown } from "react-bootstrap";

const PaginationComponent = ({
  setSearchFilter,
  setCurrentPage,
  totalItems,
  itemsPerPage,
  currentPage,
  onChangePage,
  applyFilters,
  queryClient,
  onChangePageSize,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const maxPagesToShow = 16; // Maximum number of pages to display at once
  let pages = [];
  const itemsPerPageOptions = [5, 10, 15, 20];
  const getPageItem = (page) => (
    <Pagination.Item
      key={page}
      active={page === currentPage}
      onClick={() => onChangePage(page)}
    >
      {page + 1}
    </Pagination.Item>
  );

  const addEllipsis = () => <Pagination.Ellipsis disabled key="ellipsis" />;

  if (totalPages <= maxPagesToShow) {
    for (let i = 0; i < totalPages; i++) {
      pages.push(getPageItem(i));
    }
  } else {
    let startPage, endPage;
    if (currentPage <= 6) {
      startPage = 0;
      endPage = maxPagesToShow - 2;
    } else if (currentPage + 6 >= totalPages) {
      startPage = totalPages - maxPagesToShow + 2;
      endPage = totalPages;
    } else {
      startPage = currentPage - 4;
      endPage = currentPage + 5;
    }

    if (startPage > 0) {
      pages.push(getPageItem(0));
      pages.push(addEllipsis());
    }

    for (let i = startPage; i < endPage; i++) {
      pages.push(getPageItem(i));
    }

    if (endPage < totalPages) {
      pages.push(addEllipsis());
      pages.push(getPageItem(totalPages - 1));
    }
  }
  const handleItemsPerPageChange = (number) => {
    setSearchFilter((prevFilters) => ({
      ...prevFilters,
      page: 1,
      perPage: number,
    }));
    setCurrentPage(1); // Reset to first page when items per page change
    // Optionally, you can immediately fetch the updated list here or use `applyFilters` to do so
    applyFilters(); // Reset to first page when items per page change
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        padding: "1rem",
      }}
    >
      {/* Central Section */}
      <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
        <Pagination>
          <Pagination.First
            disabled={currentPage === 0}
            onClick={() => onChangePage(0)}
          />
          <Pagination.Prev
            disabled={currentPage === 0}
            onClick={() => onChangePage(currentPage - 1)}
          />

          {pages}

          <Pagination.Next
            disabled={currentPage === totalPages - 1}
            onClick={() => onChangePage(currentPage + 1)}
          />
          <Pagination.Last
            disabled={currentPage === totalPages - 1}
            onClick={() => onChangePage(totalPages - 1)}
          />
        </Pagination>
      </div>

      {/* Dropdown Section */}
      <div>
        <div className="d-flex justify-content-between align-items-center mt-3">
          <div></div> {/* Empty div for spacing */}
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Sayifada
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {itemsPerPageOptions.map((option) => (
                <Dropdown.Item
                  key={option}
                  onClick={() => handleItemsPerPageChange(option)}
                >
                  {option}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default PaginationComponent;
