import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { deleteCookie } from "common/helpers/deleteCookie";
const LogoutButton = ({ title, classNameStyle, titleStyle }) => {
  const navigate = useNavigate();

  const [gapiReady, setGapiReady] = useState(false);

  useEffect(() => {
    if (window.gapi) {
      window.gapi.load("auth2", () => {
        window.gapi.auth2
          .init({
            client_id:
              "3246594183-cnrr1p3rkr3vlj859k5b04gslog825oe.apps.googleusercontent.com", // replace with your client id
          })
          .then(() => setGapiReady(true));
      });
    }
  }, []);

  const handleClick = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    fetch("https://backend.suarelab.com/api/testlogout", requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Logout failed");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Logout success:", data);
        deleteCookie("user");
        deleteCookie("role");
        deleteCookie("token");

        // Sign out of Google
        if (gapiReady) {
          const auth2 = window.gapi.auth2.getAuthInstance();
          if (auth2 != null) {
            auth2.signOut().then(
              auth2.disconnect().then(() => {
                console.log("Google sign out successful");
              })
            );
          }
        }

        navigate("/");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <span
      className={classNameStyle}
      style={{ display: "flex", alignItems: "center" }}
    >
      <button
        onClick={handleClick}
        style={{
          background: "transparent",
          border: "none",
          cursor: "pointer",
          color: "white",
          marginLeft: "8px",
        }}
      >
        <FontAwesomeIcon icon={faSignOutAlt} style={{ fontSize: "22px" }} />
      </button>
      <span onClick={handleClick} style={{ cursor: "pointer", ...titleStyle }}>
        {" "}
        {title}
      </span>
    </span>
  );
};

export default LogoutButton;
