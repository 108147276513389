import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import { Col, Row } from "react-bootstrap";
import { axios } from "common/utils/axios";
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { getCookie } from "common/helpers/getCookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

function UserHelpp({ userId }) {
  const userCookie = getCookie("user");
  const USER_ID = userCookie ? JSON.parse(userCookie).id : null;
  console.log(USER_ID, "user");
  const [show, setShow] = useState(null);
  const handleClose = () => setShow(null);
  const [currentMessages, setCurrentMessages] = useState([]);

  const [selectedFileDetails, setSelectedFileDetails] = useState([]);
  const [reason, setReason] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [files, setFiles] = useState(null);
  const UserId = { userId };
  const [newMessage, setNewMessage] = useState("");

  const [activeConversations, setActiveConversations] = useState(
    JSON.parse(localStorage.getItem(`activeConversations_${USER_ID}`)) || []
  );
  const [allConversations, setAllConversations] = useState([]);

  const handleFileChange = (e) => {
    setFiles(e.target.files);

    // create an array to store file details
    const filesArray = Array.from(e.target.files).map((file) => ({
      name: file.name,
      size: file.size,
      type: file.type,
    }));

    setSelectedFileDetails(filesArray);
  };
  const renderFileDetails = () => (
    <ul>
      {selectedFileDetails.map((file, index) => (
        <li key={index}>
          <strong>Name:</strong> {file.name} <strong>Size:</strong> {file.size}{" "}
          bytes <strong>Type:</strong> {file.type}
        </li>
      ))}
    </ul>
  );
  useEffect(() => {
    fetchActiveConversations();
    fetchAllConversations();
  }, []);

  useEffect(() => {
    localStorage.setItem(
      `activeConversations_${USER_ID}`,
      JSON.stringify(activeConversations)
    );
  }, [activeConversations]);

  const fetchActiveConversations = async () => {
    try {
      const response = await axios.get(
        `/api/helpconversations/user/${USER_ID}/active`
      );
      const activeConversationsWithAdminResponses = await Promise.all(
        response.data.map(async (conversation) => {
          const adminResponseResponse = await axios.get(
            `/api/adminresponses/conversation/${conversation.id}`
          );
          return {
            ...conversation,
            adminResponses: adminResponseResponse.data,
          };
        })
      );
      setActiveConversations(activeConversationsWithAdminResponses);
    } catch (error) {
      console.error("Failed to fetch active conversations:", error);
      toast.error("Failed to fetch active conversations.");
    }
  };
  const fetchAllConversations = async () => {
    try {
      const response = await axios.get(
        `/api/helpconversations/user/${USER_ID}/all`
      );
      const activeConversationsWithAdminResponses = await Promise.all(
        response.data.map(async (conversation) => {
          const adminResponseResponse = await axios.get(
            `/api/adminresponses/conversation/${conversation.id}`
          );
          return {
            ...conversation,
            adminResponses: adminResponseResponse.data,
          };
        })
      );
      setAllConversations(activeConversationsWithAdminResponses);
    } catch (error) {
      console.error("Failed to fetch active conversations:", error);
      toast.error("Failed to fetch active conversations.");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const activeConversationWithSameReason = activeConversations.find(
      (conversation) => conversation.reason === reason
    );
    if (activeConversationWithSameReason) {
      toast.warn(
        "You already have an active help request with the same reason."
      );
      return;
    }

    try {
      const formData = new FormData();
      formData.append("reason", reason);
      formData.append("customer_id", USER_ID);
      formData.append("user_id", USER_ID);
      formData.append("message", message);
      formData.append("status", status);
      formData.append("is_active", isActive ? 1 : 0);
      if (files) {
        for (let i = 0; i < files.length; i++) {
          formData.append("files[]", files[i]);
        }
      }

      const response = await axios.post("/api/helpconversations", formData);
      toast.success("Help request submitted successfully.");
      localStorage.removeItem(`activeConversations_${USER_ID}`);
      fetchActiveConversations();

      // Clear the form
      setReason("");
      setMessage("");
      setStatus("");
      setIsActive(false);
      setFiles(null);
    } catch (error) {
      toast.error("An error occurred while submitting the form.");
      console.error(error);
    }
  };
  const handleShow = async (index) => {
    setShow(index);
    try {
      const conversation = allConversations[index];
      const response = await axios.get(
        `/api/conversations/${conversation.id}/messages`
      );
      setCurrentMessages(response.data.messages);
    } catch (error) {
      console.error("Failed to fetch messages:", error);
      toast.error("Failed to fetch messages.");
    }
  };
  const handleSendMessage = async () => {
    try {
      const conversation = allConversations[show]; // Get the current conversation

      const response = await axios.post("/api/helpmessages_lastsender", {
        // Use the correct endpoint
        conversation_id: conversation.id,
        user_id: USER_ID,
        message: newMessage,
        is_admin: false,
      });

      if (response.status === 201) {
        // Add the new message to the current messages in the modal
        setCurrentMessages([...currentMessages, response.data.message]);
        setNewMessage("");
      } else if (response.status === 400 && response.data.error) {
        // Handle the specific error from the server
        toast.error(response.data.error);
      } else {
        throw new Error();
      }
    } catch (error) {
      toast.error("An error occurred while sending the message.");
      console.error(error);
    }
  };

  const renderConversations = () => {
    return (
      <div style={{ fontSize: "18px" }}>
        <h1
          style={{ textAlign: "center", fontSize: "30px", marginTop: "50++px" }}
        >
          Gönderilen Mesajlar
        </h1>
        <Table striped bordered hover style={{ marginTop: "20px" }}>
          <thead>
            <tr>
              <th>Sebep</th>
              <th>Durum</th>
              <th>Mesaj</th>
              <th>Aktif</th>
              <th>Showönetici Yanıtları</th>
              <th>Göster</th>
            </tr>
          </thead>
          <tbody>
            {allConversations.map((conversation, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td>{conversation.reason}</td>
                  <td>{conversation.status}</td>
                  <td>{conversation.message.slice(0, 60) + "..."}</td>
                  <td
                    className={
                      conversation.is_active ? "text-green-500" : "text-red-500"
                    }
                  >
                    {conversation.is_active ? "Active" : "Inactive"}
                  </td>
                  <td>
                    {Array.isArray(conversation.adminResponses) &&
                    conversation.adminResponses.length > 0 ? (
                      conversation.adminResponses.map(
                        (response, responseIndex) => (
                          <div key={responseIndex}>{response.response}</div>
                        )
                      )
                    ) : (
                      <div>Henüz cevap yok</div>
                    )}
                  </td>
                  <td>
                    {conversation.is_active ? (
                      <Button
                        active={true}
                        variant="link"
                        onClick={() => handleShow(index)}
                      >
                        &#9658;
                      </Button>
                    ) : (
                      <span style={{ color: "green" }}>&#10004;</span>
                    )}
                  </td>
                </tr>
                <Modal
                  show={show === index}
                  onHide={() => {
                    handleClose();
                    setNewMessage("");
                  }}
                  dialogClassName={{ maxWidth: "95%" }}
                  aria-labelledby="example-custom-modal-styling-title"
                >
                  <Modal.Header closeButton>
                    <div>
                      <Modal.Title id="example-custom-modal-styling-title">
                        <FontAwesomeIcon icon={faUser} /> Admin
                      </Modal.Title>
                      <p className="text-muted small">
                        Bir kerede bir mesaj gönderebilirsiniz
                      </p>
                    </div>
                  </Modal.Header>

                  <Modal.Body
                    style={{
                      minWidth: "80%",
                      minHeight: "200px",
                      maxHeight: "80vh",
                      overflowY: "auto",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {currentMessages.map((message, messageIndex) => (
                      <div
                        key={messageIndex}
                        style={{
                          textAlign: message.is_admin ? "left" : "right",
                          padding: "10px",
                          borderRadius: "5px",
                          margin: "10px 0",
                          background: message.is_admin ? "#e9e9e9" : "#90caf9",
                        }}
                      >
                        {message.message}
                      </div>
                    ))}
                  </Modal.Body>

                  <Modal.Footer>
                    <div style={{ width: "100%" }}>
                      <Form.Group>
                        <Form.Control
                          as="textarea"
                          placeholder="Mesajınızı buraya yazın..."
                          value={newMessage}
                          onChange={(e) => setNewMessage(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                    <div>
                      <Button
                        className="mr-3"
                        style={{ backgroundColor: "#157347" }}
                        variant="primary"
                        onClick={handleSendMessage}
                      >
                        Gönder
                      </Button>
                      <Button
                        active={true}
                        variant="secondary"
                        onClick={handleClose}
                      >
                        Kapat
                      </Button>
                    </div>
                  </Modal.Footer>
                </Modal>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };
  return (
    <Container>
      <ToastContainer />
      <Row>
        <Col xl={12}>
          <Card style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)" }}>
            <Card.Body>
              <Card.Title style={{ textAlign: "center" }}>
                Bize Yazın
              </Card.Title>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="reasonSelect">
                  <Form.Label>Sebep</Form.Label>
                  <Form.Control
                    as="select"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  >
                    <option value="">Bir sebep seçin</option>
                    <option value="Rezervasyon">Rezervasyon</option>
                    <option value="Satış">Satış</option>
                    <option value="Ürünler">Ürünler</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="messageText">
                  <Form.Label>Mesaj</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="statusSelect">
                  <Form.Label>Durum</Form.Label>
                  <Form.Control
                    as="select"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="">Bir durum seçin</option>
                    <option value="Acil">Acil</option>
                    <option value="Orta Acil">Orta Acil</option>
                    <option value="Genel">Genel</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <label htmlFor="filesInput">Dosyaları yükle</label>
                  <input
                    id="filesInput"
                    type="file"
                    onChange={handleFileChange}
                    multiple
                  />
                  {renderFileDetails()}
                </Form.Group>
                <div className="d-flex justify-content-end">
                  <Button
                    type="submit"
                    style={{
                      marginButtom: "30px",
                      backgroundColor: "#157347",
                      borderRadius: "15px",
                      padding: "10px 20px",
                    }}
                  >
                    Send
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col style={{ marginTop: "50px" }} xl={12}>
          {renderConversations()}
        </Col>
      </Row>
    </Container>
  );
}

export default UserHelpp;
