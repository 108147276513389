import React from "react";

export default function OtelOtherInformation({
	setOtherInformations,
	otherInformations,
}) {
	const handleOtherInformations = (e, type) => {
		console.log(e.target.value);
		console.log(type);
		switch (type) {
			case "animal":
				setOtherInformations({
					animal: e.target.value,
					check_in: otherInformations?.check_in,
					check_out: otherInformations?.check_out,
					payment_info: otherInformations?.payment_info,
				});
				break;
			case "check_in":
				setOtherInformations({
					animal: otherInformations?.animal,
					check_in: e.target.value,
					check_out: otherInformations?.check_out,
					payment_info: otherInformations?.payment_info,
				});
				break;
			case "check_out":
				setOtherInformations({
					animal: otherInformations?.animal,
					check_in: otherInformations?.check_in,
					check_out: e.target.value,
					payment_info: otherInformations?.payment_info,
				});
				break;
			case "payment_info":
				setOtherInformations({
					animal: otherInformations?.animal,
					check_in: otherInformations?.check_in,
					check_out: otherInformations?.check_out,
					payment_info: e.target.value,
				});
				break;
			default:
				break;
		}
	};
	return (
		<div>
			<label>Evcil Hayvan </label>
			<input
				type="text"
				value={otherInformations?.animal}
				onChange={(e) => handleOtherInformations(e, "animal")}
			/>
			<br />
			<label>Check/İn </label>
			<input
				type="text"
				value={otherInformations?.check_in}
				onChange={(e) => handleOtherInformations(e, "check_in")}
			/>
			<br />
			<label>Check/Out </label>
			<input
				type="text"
				value={otherInformations?.check_out}
				onChange={(e) => handleOtherInformations(e, "check_out")}
			/>
			<br />
			<label>Ödeme yöntemleri</label>
			<input
				type="text"
				value={otherInformations?.payment_info}
				onChange={(e) => handleOtherInformations(e, "payment_info")}
			/>
			<br />
		</div>
	);
}
