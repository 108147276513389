import React, { useState, lazy, Suspense } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Offers from "./Offres";
import Sales from "./Sales";
import ReservationManagementList from "../ReservationMangment/Reservation";
export const OffersAndSales = () => {
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="mb-4">
      <Box
        className="parent"
        sx={{
          width: "100%",
          typography: "body1",
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          marginBottom: "20px",
        }}
      >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Teklifler" value="1" />

              <Tab label="Rezervazyon" value="2" />
            </TabList>
          </Box>
          <Suspense fallback={<div>Loading...</div>}>
            <TabPanel value="1">
              <Offers />
            </TabPanel>

            <TabPanel value="2">
              <ReservationManagementList />
            </TabPanel>
          </Suspense>
        </TabContext>
      </Box>
      <Suspense fallback={<div>Loading...</div>}></Suspense>
    </div>
  );
};
