import Sitsettings from "area/admin/SiteSettings/SiteSettings";
import FromWhereYouHeard from "area/admin/SiteSettings/components/FromWhereYouHeard";
import RegistrationType from "area/admin/SiteSettings/components/RegistrationType";
import FamilyRelativeRelationship from "area/admin/SiteSettings/components/FamilyRelativeRelationship";
import CustomerDocumentType from "area/admin/SiteSettings/components/CustomerDocumentType";
import CustomerMeetingsDirection from "area/admin/SiteSettings/components/CustomerMeetingsDirection";
import CreatePost from "area/admin/Blog/CreatePost";
import CreateComment from "area/admin/Comments/CreateComment";
import CreateQA from "area/admin/QA/CreateQA";
import CreatAboutUs from "area/admin/AboutUs/CreatAboutUs";
import ReservationManagementList from "area/admin/ReservationMangment/ReservationManagementList";
import CreateReservationMangment from "area/admin/ReservationMangment/components/CreateReservationMangment";
import HelpMessagingSystem from "area/admin/CustomerMangement/HelpMangment/HelpMessagingSystem";
import Chat from "area/admin/CustomerMangement/HelpMangment/UserChat";
import EditReservation from "area/admin/CustomerMangement/ReservationMangment/EditReservation";
import ReservationCustomerMangmentList from "area/admin/CustomerMangement/ReservationMangment/Reservation";
import CreateReservation from "area/admin/CustomerMangement/ReservationMangment/CreateReservation";
import OtelsServiceManagement from "../area/admin/ServiceManagement/Otels";
import ToursAdmin from "area/admin/SiteManagement/Tours";
import ToursGridModal2 from "area/admin/ServiceManagement/ServiceToursComponents/ToursGridModal2";
import PricesServiceManagement from "area/admin/SiteSettings/components/Price";
import CategoriesServiceManagement from "area/admin/SiteSettings/components/Categories";
import Dashboard from "area/admin/Dashboard";
import AdminLayout from "../layouts/AdminLayout";
import Advertisment2 from "area/admin/AdvertisementManegment/Advertisment";
import Customers from "area/admin/CustomerMangement/Customers";
import EditCustomer from "area/admin/CustomerMangement/ServiceCustomersComponents/Update/EditCustomer";
import CustomersDetails from "area/admin/CustomerMangement/CustomersDetails";
import CreateCustomre from "area/admin/CustomerMangement/ServiceCustomersComponents/Create/CreateCustomre";
import CreateCustomerFamily from "area/admin/CustomerMangement/ServiceCustomersComponents/Create/CreateCustomerFamily";
import CreateCustomerDocuments from "area/admin/CustomerMangement/ServiceCustomersComponents/Create/CreateCustomerDocuments";
import CreateCustomerMeetings from "area/admin/CustomerMangement/ServiceCustomersComponents/Create/CreateCustomerMeetings";
import CreatePastCustomerMeetings from "area/admin/CustomerMangement/ServiceCustomersComponents/Create/CreatePastCustomerMeetings";
import EditCustomerMeetings from "area/admin/CustomerMangement/ServiceCustomersComponents/Update/EditCustomerMeetings";
import CreateMeeting from "area/admin/CustomerMangement/ServiceCustomersComponents/Create/CreateMeeting";
import CreateOffers from "area/admin/CustomerMangement/ServiceCustomersComponents/Create/CreateOffers";
import LastShowMeetings from "area/admin/CustomerMangement/CustomerDetailsComponents/components/LastShowMeetings";
import CreateCustomerAdress from "area/admin/CustomerMangement/ServiceCustomersComponents/Create/CreateCustomerAddress";
import EditCustomerFamily from "area/admin/CustomerMangement/ServiceCustomersComponents/Update/EditCustomerFamily";
import ShowMeetings from "area/admin/CustomerMangement/CustomerDetailsComponents/components/ShowMeetings";
import CreateSales from "area/admin/CustomerMangement/ServiceCustomersComponents/Create/CreateSales";
import CustomersMeetings from "area/admin/CustomerMangement/CustomersMeetings";
import CustomersSales from "area/admin/CustomerMangement/CustomersSales";
import CustomersOffers from "area/admin/CustomerMangement/CustomersOffers";
import Companies from "area/admin/Companies/Companies";
import ContactUs from "area/admin/SiteSettings/components/ContactUs";
import Logos from "area/admin/SiteSettings/components/Logos";
import HomePage from "area/admin/SiteSettings/components/HomePage";
import Hotels from "area/admin/Hotels/Hotels";
import CreatePostModal from "area/admin/Blog/CreatePostModal";
import IndexAboutUS from "area/admin/AboutUs/IndexAboutUS";
import Categories from "area/admin/SiteSettings/components/Categories";
import CreateMeetingWithCustomerID from "area/admin/CustomerMangement/ServiceCustomersComponents/Create/CreateMeetingWithCustomerID";
import CreateOffersWithCustomerID from "area/admin/CustomerMangement/ServiceCustomersComponents/Create/CreateOffersWithCustomerID";
import CreateComments from "area/admin/Comments/CreateComments";
export const AdminRoutes = [
  {
    path: "/admin",
    element: <AdminLayout />,

    auth: "admin",
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "siteManagement",
        children: [
          {
            path: "tours-grid",
            element: <ToursGridModal2 />,
          },
        ],
      },
      {
        path: "ReservationManagement/",
        children: [
          { path: "list", element: <ReservationManagementList /> },
          { path: "create", element: <CreateReservationMangment /> },
        ],
      },
      {
        path: "Companies/",
        element: <Companies />,
      },
      {
        path: "customerManagement",
        children: [
          {
            path: "customers",
            element: <Customers />,
          },
          {
            path: "CustomersMeetings",
            element: <CustomersMeetings />,
          },
          {
            path: "CustomersSales",
            element: <CustomersSales />,
          },
          {
            path: "CustomersOffers",
            element: <CustomersOffers />,
          },
          {
            path: "CustomersOffers/CreateOffers",
            element: <CreateOffers />,
          },
          {
            path: "customers/:id/:userName?/CreateOffers",
            element: <CreateOffersWithCustomerID />,
          },
          {
            path: "CreateCustomre",
            element: <CreateCustomre />,
          },
          {
            path: "customers/:id/family",
            element: <CreateCustomerFamily />,
          },
          {
            path: "customers/:id/Documents",
            element: <CreateCustomerDocuments />,
          },
          {
            path: "customers/:id?/Meetings",
            element: <CreateCustomerMeetings />,
          },
          {
            path: "customers/:id?/PastMeetings",
            element: <CreatePastCustomerMeetings />,
          },
          {
            path: "customers/:id?/Meetings/edit/:meetingId?",
            element: <EditCustomerMeetings />,
          },

          {
            path: "customers/edit/:id",
            element: <EditCustomer />,
          },
          {
            path: "customers/:id/:userName?",
            element: <CustomersDetails />,
          },
          // {
          //   path: "customers/:id/CreateMeeting",
          //   element: <CreateMeeting />,
          // },
          {
            path: "CustomersMeetings/create",
            element: <CreateMeeting />,
          },
          {
            path: "customers/:id/CreateOffers",
            element: <CreateOffers />,
          },

          {
            path: "customers/:id/CreateSales",
            element: <CreateSales />,
          },
          {
            path: "customers/CreateSales",
            element: <CreateSales />,
          },
          {
            path: "customers?/:customerId?/meeting/:id",
            element: <ShowMeetings />,
          },
          {
            path: "customers?/:customerId?/Lastmeeting/",
            element: <LastShowMeetings />,
          },
          {
            path: "customers/:id?/CreateCustomerAdress/",
            element: <CreateCustomerAdress />,
          },
          {
            path: "customers/:id?/EditCustomerFamily/:memberId?",
            element: <EditCustomerFamily />,
          },

          {
            path: "customers/:id?/ReservationManagementList/",
            element: <ReservationCustomerMangmentList />,
          },
          {
            path: "customers/:id?/ReservationManagementList/edit_reservation/:reservation_id",
            element: <EditReservation />,
          },
          {
            path: "customers/:id?/edit_reservation/:reservation_id",
            element: <EditReservation />,
          },

          {
            path: "customers/:id?/ReservationManagementList/CreateReservation",
            element: <CreateReservation />,
          },
          {
            path: "customers/:id?/CreateReservation",
            element: <CreateReservation />,
          },
        ],
      },
      {
        path: "helpManagment",
        children: [
          { path: "helpe/Messages/:conversationId", element: <Chat /> },
          { path: "helpSystem", element: <HelpMessagingSystem /> },
        ],
      },

      {
        path: "advertismentManagement",
        children: [
          {
            path: "advertisment",
            element: <Advertisment2 />,
          },
        ],
      },
      {
        path: "QA",
        children: [{ path: "create", element: <CreateQA /> }],
      },
      {
        path: "about-us",
        children: [{ path: "create", element: <CreatAboutUs /> }],
      },
      {
        path: "Comments",
        children: [
          { path: "index", element: <CreateComment /> },
          { path: "create", element: <CreateComments /> },
        ],
      },
      {
        path: "Post",
        children: [
          { path: "index", element: <CreatePost /> },
          { path: "create", element: <CreatePostModal /> },
        ],
      },
      {
        path: "tours",
        element: <ToursAdmin />,
      },
      {
        path: "tour-category",
        element: <Categories />,
      },
      {
        path: "Hotels",
        element: <Hotels />,
      },
      {
        path: "serviceManagement",
        children: [
          {
            path: "otels",
            element: <OtelsServiceManagement />,
          },
          {
            path: "prices",
            element: <PricesServiceManagement />,
          },
          {
            path: "Categories",
            element: <CategoriesServiceManagement />,
          },
          {
            path: "FromWhereYouHeard",
            element: <FromWhereYouHeard />,
          },
          {
            path: "RegistrationType",
            element: <RegistrationType />,
          },
          {
            path: "FamilyRelativeRelationship",
            element: <FamilyRelativeRelationship />,
          },
          {
            path: "CustomerDocumentType",
            element: <CustomerDocumentType />,
          },
          {
            path: "CustomerMeetingsDirection",
            element: <CustomerMeetingsDirection />,
          },

          {
            path: "sitsettings",
            element: <Sitsettings />,
            children: [],
          },
        ],
      },
    ],
  },
  {
    path: "/admin",
    element: <AdminLayout />,

    auth: "admin",
    children: [
      { path: "Contact-Us", element: <ContactUs /> },
      { path: "Logos", element: <Logos /> },
      { path: "HomePage", element: <HomePage /> },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "siteManagement",
        children: [
          {
            path: "tours-grid",
            element: <ToursGridModal2 />,
          },
        ],
      },
      {
        path: "ReservationManagement/",
        children: [
          { path: "list", element: <ReservationManagementList /> },
          { path: "create", element: <CreateReservationMangment /> },
        ],
      },
      {
        path: "customerManagement",
        children: [
          {
            path: "customers",
            element: <Customers />,
          },
          {
            path: "CustomersMeetings",
            element: <CustomersMeetings />,
          },
          {
            path: "CustomersSales",
            element: <CustomersSales />,
          },
          {
            path: "CustomersOffers",
            element: <CustomersOffers />,
          },
          {
            path: "CreateCustomre",
            element: <CreateCustomre />,
          },
          {
            path: "customers/:id/family",
            element: <CreateCustomerFamily />,
          },
          {
            path: "customers/:id/Documents",
            element: <CreateCustomerDocuments />,
          },
          {
            path: "customers/:id?/Meetings",
            element: <CreateCustomerMeetings />,
          },
          {
            path: "customers/:id?/PastMeetings",
            element: <CreatePastCustomerMeetings />,
          },
          {
            path: "customers/:id?/Meetings/edit/:meetingId?",
            element: <EditCustomerMeetings />,
          },

          {
            path: "customers/edit/:id",
            element: <EditCustomer />,
          },
          {
            path: "customers/:id/:userName",
            element: <CustomersDetails />,
          },

          {
            path: "customers/:id/:userName?/CreateMeeting",
            element: <CreateMeetingWithCustomerID />,
          },
          {
            path: "customers/Meetings/create",
            element: <CreateMeeting />,
          },
          {
            path: "customers/:id/CreateOffers",
            element: <CreateOffers />,
          },
          {
            path: "customers/CreateOffers",
            element: <CreateOffers />,
          },
          {
            path: "customers/:id/CreateSales",
            element: <CreateSales />,
          },
          {
            path: "customers/CreateSales",
            element: <CreateSales />,
          },
          {
            path: "customers?/:customerId?/meeting/:id",
            element: <ShowMeetings />,
          },
          {
            path: "customers?/:customerId?/Lastmeeting/",
            element: <LastShowMeetings />,
          },
          {
            path: "customers/:id?/CreateCustomerAdress/",
            element: <CreateCustomerAdress />,
          },
          {
            path: "customers/:id?/EditCustomerFamily/:memberId?",
            element: <EditCustomerFamily />,
          },

          {
            path: "customers/:id?/ReservationManagementList/",
            element: <ReservationCustomerMangmentList />,
          },
          {
            path: "customers/:id?/ReservationManagementList/edit_reservation/:reservation_id",
            element: <EditReservation />,
          },
          {
            path: "customers/:id?/edit_reservation/:reservation_id",
            element: <EditReservation />,
          },

          {
            path: "customers/:id?/ReservationManagementList/CreateReservation",
            element: <CreateReservation />,
          },
          {
            path: "customers/:id?/CreateReservation",
            element: <CreateReservation />,
          },
        ],
      },
      {
        path: "helpManagment",
        children: [
          { path: "helpe/Messages/:conversationId", element: <Chat /> },
          { path: "helpSystem", element: <HelpMessagingSystem /> },
        ],
      },

      {
        path: "advertismentManagement",
        children: [
          {
            path: "TourMangment",
            element: <Advertisment2 />,
          },
        ],
      },
      {
        path: "Transport-company",
        element: <Companies />,
      },
      {
        path: "Transport-company",
        element: <Companies />,
      },
      {
        path: "QA",
        children: [{ path: "create", element: <CreateQA /> }],
      },
      {
        path: "about-us",
        children: [
          { path: "create", element: <CreatAboutUs /> },
          { path: "index", element: <IndexAboutUS /> },
        ],
      },
      {
        path: "Comments",
        children: [{ path: "create", element: <CreateComment /> }],
      },
      {
        path: "Post",
        children: [
          { path: "index", element: <CreatePost /> },
          { path: "create", element: <CreatePostModal /> },
        ],
      },
      {
        path: "tours",
        element: <ToursAdmin />,
      },
      {
        path: "serviceManagement",
        children: [
          {
            path: "otels",
            element: <OtelsServiceManagement />,
          },
          {
            path: "prices",
            element: <PricesServiceManagement />,
          },
          {
            path: "Categories",
            element: <CategoriesServiceManagement />,
          },
          {
            path: "FromWhereYouHeard",
            element: <FromWhereYouHeard />,
          },
          {
            path: "RegistrationType",
            element: <RegistrationType />,
          },
          {
            path: "FamilyRelativeRelationship",
            element: <FamilyRelativeRelationship />,
          },
          {
            path: "CustomerDocumentType",
            element: <CustomerDocumentType />,
          },
          {
            path: "CustomerMeetingsDirection",
            element: <CustomerMeetingsDirection />,
          },

          {
            path: "sitsettings",
            element: <Sitsettings />,
          },
        ],
      },
    ],
  },
];
