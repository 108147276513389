import React, { useEffect, useState } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import styled from "styled-components";
import "../../../assets/styles/TourList.css";

import Header from "../../../common/components/headers/light.js";
import AnimationRevealPage from "../../../common/helpers/AnimationRevealPage.js";
import { SectionHeading } from "../../../common/components/misc/Headings";
import CheckBoxFilter from "./components/CheckBoxFilter";
import RangeSlider from "./components/RangeFilter";
import DaysRange from "./components/DaysRange";
import PriceCurrent from "./components/PriceCurrent";
import ButtonFilter from "./components/ButtonFilter";
import TouersList from "./components/TouresList";
import ImageSlider from "./components/Slider";
import Footer from "../../../common/components/footers/FiveColumnWithInputForm.js";
import ToursTamplateCardWithOtels from "../../Tours/ToursTemplate/components/ToursTamplateCardWithOtels";
import { axios } from "common/utils/axios";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
const HeadingRow = tw.div`flex mt-12`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Image = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;

export default ({ headingText = "Turlar", tours, setTours }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 705);
  const [showFilter, setShowFilter] = useState(false);
  const [visibleTours, setVisibleTours] = useState(3);
  const [otels, setOtels] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredTourListPrice, setFilteredTourList] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [filteredTours, setFilteredTours] = useState(tours);
  const [min, setMin] = useState(100);
  const [max, setMax] = useState(3500);
  const [prices, setPrices] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [minDays, setMinDays] = useState(0);
  const [maxDays, setMaxDays] = useState(60);
  const [daysOfTours, setDaysOfTours] = useState({});
  /********************** Umra Slider Images ****************************** */
  const images = [
    "https://avatars.mds.yandex.net/get-mpic/5261272/img_id6330954573064020671.jpeg/orig",
    "https://pbs.twimg.com/media/E6mkeTIX0AcWnXT?format=jpg&name=large",
  ];
  /************************** API ******************************* */
  /************ Get otels From DataBase ************ */
  const getOtels = async () => {
    const res = await axios.get("api/otel");
    if (res) {
      console.log("otels", res);
      setOtels(res.data.otels);
    }
  };
  useEffect(() => {
    getOtels();
  }, []);

  /**********Get Current Price From DataBase********* */
  useEffect(() => {
    axios.get("/api/prices").then((response) => {
      setPrices(response.data);
    });
  }, []);
  useEffect(() => {
    axios.get("/api/categories").then((response) => {
      setCategories(response.data);
    });
  }, []);

  /************************** Filters ************************************ */
  /************ Filter Tours on Date ******** */
  const handleFilter = () => {
    const filteredTours = tours.filter((tour) => {
      const tourMonth = new Date(tour.gidis_tarih).toLocaleString("default", {
        month: "long",
      });
      return selectedMonths.includes(tourMonth);
    });
    setFilteredTours(filteredTours);
  };
  const handleFilterChange = (months) => {
    setSelectedMonths(months);
  };

  const handleFilterDateChange = (event) => {
    const { name, value } = event.target;
    if (name === "minDays") {
      setMinDays(Math.min(value, maxDays));
    } else if (name === "maxDays") {
      setMaxDays(Math.max(value, minDays));
    }
  };

  /*********************************************************************** */
  /********** Handle Currency Changes ************* */
  const handleCurrencyChange = (event) => {
    setSelectedCurrency(event.target.value);
  };
  /************** On Loade Showing Tours count ********** */
  const onLoadMoreClick = () => {
    setVisibleTours(visibleTours + 4);
  };

  /* Mobil filter Modal */
  const handleShowFilter = () => setShowFilter(true);
  const handleCloseFilter = () => setShowFilter(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 705);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      <div style={{ marginTop: "10px" }}>
        <Header />
      </div>
      <ImageSlider images={images} />
      <HeadingRow style={{ marginLeft: "18px" }}>
        <Heading>{headingText}</Heading>
      </HeadingRow>
      {/*Mobile Filter Modal */}
      {isMobile && (
        <div className="d-flex justify-content-end">
          <Button onClick={handleShowFilter}>
            {" "}
            <FontAwesomeIcon icon={faFilter} /> Filter
          </Button>
        </div>
      )}

      <Modal show={showFilter} onHide={handleCloseFilter}>
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <aside className="filter-sidebar${isMobile ? ' modal-sidebar' : ''}">
            <RangeSlider min={min} max={max} setMin={setMin} setMax={setMax} />
            <DaysRange
              minDays={minDays}
              maxDays={maxDays}
              handleFilterDateChange={handleFilterDateChange}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1rem",
              }}
            >
              <span style={{ fontFamily: "Arial, sans-serif" }}>{maxDays}</span>
              <span style={{ fontFamily: "Arial, sans-serif" }}>{minDays}</span>
            </div>
            <PriceCurrent
              setPrices={setPrices}
              prices={prices}
              onChange={handleCurrencyChange}
            />
            <CheckBoxFilter
              handleFilterChange={handleFilterChange}
              selectedMonths={selectedMonths}
              setSelectedMonths={setSelectedMonths}
            />
            <ButtonFilter onFilter={handleFilter} />
          </aside>
        </Modal.Body>
      </Modal>
      <div style={{ margin: "2rem 1rem" }}>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
          {/* Start Side bar */}
          <aside className="filter-sidebar">
            <RangeSlider min={min} max={max} setMin={setMin} setMax={setMax} />
            <DaysRange
              minDays={minDays}
              maxDays={maxDays}
              handleFilterDateChange={handleFilterDateChange}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1rem",
              }}
            >
              <span style={{ fontFamily: "Arial, sans-serif" }}>{maxDays}</span>
              <span style={{ fontFamily: "Arial, sans-serif" }}>{minDays}</span>
            </div>
            <PriceCurrent
              setPrices={setPrices}
              prices={prices}
              onChange={handleCurrencyChange}
            />
            <CheckBoxFilter
              handleFilterChange={handleFilterChange}
              selectedMonths={selectedMonths}
              setSelectedMonths={setSelectedMonths}
            />
            <ButtonFilter onFilter={handleFilter} />
          </aside>
          <div className="container">
            <ToursTamplateCardWithOtels
              tours={tours}
              otels={otels}
              categories={categories}
              filteredTours={filteredTours}
              min={min}
              max={max}
              setTours={setTours}
              daysOfTours={daysOfTours}
              minDays={minDays}
              maxDays={maxDays}
              selectedMonths={selectedMonths}
              filteredTourListPrice={filteredTourListPrice}
              prices={prices}
              onLoadMoreClick={onLoadMoreClick}
              visibleTours={visibleTours}
              selectedCurrency={selectedCurrency}
            />
          </div>
          {/*End Tours list */}
        </div>
      </div>
      <Footer />
    </div>
  );
};
