import React, { useState, useEffect } from "react";
import { axios } from "common/utils/axios";
import { Link } from "react-router-dom";
import {
  Table,
  Button,
  Form,
  Modal,
  Col,
  Row,
  Card,
  Alert,
} from "react-bootstrap";
import AddressModalCreate from "./components/AddressModalCreate";
import { toast, ToastContainer } from "react-toastify";
import useSetActiveAddress from "common/hooks/useSetActiveAddress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faPencilAlt,
  faTrashAlt,
  faEdit,
  faCheckCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import AddressModalEdit from "./components/AddressModalEdit";
const styles = {
  smallerText: {
    fontSize: "11px",
  },
  radioButtonCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
const componentStyle = {
  fontSize: "18px",
};
const tableCellStyle = {
  verticalAlign: "middle",
};
function UserAdress({ userId, userData }) {
  const [openCreateAdress, handleCloseCreatAdress] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [show, setShow] = useState(false);
  const [addressToDelete, setAddressToDelete] = useState(null);
  const [il, setIl] = useState([]);
  const [ilce, setIlce] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [mahalle, setMahalle] = useState([]);
  const [currentAddress, setCurrentAddress] = useState({});
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [filteredIlce, setFilteredIlce] = useState([]);
  const [filteredMahalle, setFilteredMahalle] = useState([]);
  const [address, setAddress] = useState({
    il: "",
    ilce: "",
    mahalle: "",
    adres_title: "",
    adres: "",
  });
  const handleClose = () => setShow(false);
  const handleShow = (address) => {
    setCurrentAddress(address);
    setShow(true);
  };

  const handleDeleteAddress = (addressId) => {
    setOpenDialog(true);
    setAddressToDelete(addressId);
  };
  const handleOpenModal = () => setModalShow(true);

  // Function to close the modal
  const handleCloseModal = () => setModalShow(false);
  // New function
  // const handleSetActive = (addressId) => {
  //   if (selectedAddress !== addressId) {
  //     axios
  //       .put(`/api/addresses/${addressId}/setActive`)
  //       .then((response) => {
  //         setSelectedAddress(addressId);
  //         setAddresses((prevAddresses) =>
  //           prevAddresses.map((address) => {
  //             if (address.id === addressId) {
  //               return { ...address, active: true };
  //             } else {
  //               return { ...address, active: false };
  //             }
  //           })
  //         );
  //         // Show a toast on success
  //         toast.success("Address changed successfully!");
  //       })
  //       .catch((error) => {
  //         console.error("API call error:", error);
  //         // Show a toast on error
  //         toast.error("Failed to change address.");
  //       });
  //   }
  // };

  const handleDelete = (addressId) => {
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this address?"
    );
    if (userConfirmed) {
      axios
        .delete(`/api/addresses/${addressId}/delete`)
        .then((response) => {
          // Remove the address from the state
          setAddresses(addresses.filter((address) => address.id !== addressId));
          toast.success("Address deleted successfully!");
        })
        .catch((error) => {
          console.error("API call error:", error);
          toast.error("Failed to delete address.");
        });
    }
  };
  const handleUpdate = (addressId, updatedData) => {
    axios
      .put(`/api/addresses/${addressId}`, updatedData)
      .then((response) => {
        // Update the state with the updated address
        setAddresses((prevAddresses) =>
          prevAddresses.map((address) =>
            address.id === addressId ? response.data : address
          )
        );
        toast.success("Address updated successfully!");
      })
      .catch((error) => {
        console.error("API call error:", error);
        toast.error("Failed to update address.");
      });
  };
  useEffect(() => {
    if (userData && userData.addresses) {
      const activeAddress = userData.addresses.find(
        (address) => address.active
      );
      if (activeAddress) {
        setSelectedAddress(activeAddress.id);
      }
    }
  }, [userData]);

  const setActive = useSetActiveAddress();
  const handleSetActive = (addressId) => {
    if (selectedAddress !== addressId) {
      setActive.mutate({ addressId }, { context: { setSelectedAddress } });
    }
  };
  useEffect(() => {
    axios
      .get("api/user/addresses")
      .then((response) => {
        setAddresses(response.data);
      })
      .catch((error) => {
        console.error("There was an error retrieving the addresses!", error);
      });
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    const addressData = {
      customer_id: userId,
      ...address,
    };
    axios
      .post("api/addresses", addressData)
      .then((response) => {
        // Handle the response as needed
        console.log(response.data);
        toast.success("Adres başarıyla eklendi");
        handleClose(false);
      })
      .catch((error) => {
        // Handle any errors that

        console.error(error);
        toast.error("Adres başarsız eklendi"); // Show error toast
      });
    setModalShow(false);
  };
  const handleChange = (e) => {
    setAddress((prevAddress) => ({
      ...prevAddress,
      [e.target.name]: e.target.value,
    }));

    if (e.target.name === "ilce") {
      const selectedIlce = filteredIlce.find(
        (item) => item.district_name === e.target.value
      );
      if (selectedIlce) {
        axios
          .get(`/api/neighbor?district_key=${selectedIlce.district_key}`)
          .then((response) => {
            setMahalle(response.data);
            setFilteredMahalle(response.data);
            setAddress((prevAddress) => ({
              ...prevAddress,
              mahalle:
                response.data.length > 0
                  ? response.data[0].neighborhood_name
                  : "",
            }));
          })
          .catch((error) => {
            console.error("API call error:", error);
          });
      }
    }
  };
  useEffect(() => {
    axios.get("/api/districts").then((response) => setIlce(response.data));
    axios.get("/api/provinces").then((response) => setIl(response.data));
  }, []);
  useEffect(() => {
    if (address.il) {
      const selectedIl = il.find((item) => item.province_name === address.il);
      const filteredIlce = ilce.filter(
        (item) => item.district_province_key === selectedIl.province_key
      );
      console.log("filteredIlce:", filteredIlce); // Debug statement
      setFilteredIlce(filteredIlce);
      setAddress((prevAddress) => ({
        ...prevAddress,
        ilce: filteredIlce.length > 0 ? filteredIlce[0].district_name : "",
      }));
    }
  }, [address.il]);

  if (!userData || userData.addresses.length === 0) {
    return (
      <div>
        <Row className="justify-content-center">
          <ToastContainer />
          <div className="d-flex justify-content-between mb-4">
            <h2 className="">Kullanıcı Adresi</h2>
            <Button
              onClick={handleOpenModal}
              className="mr-2"
              variant="contained"
              disableElevation
            >
              <FontAwesomeIcon
                icon={faPlus}
                className="fas fa-eye showIcon userMenuIcon"
              />
            </Button>
          </div>
          <Alert variant="warning">adres üyeleri bilgisi bulunamadı.</Alert>{" "}
          {/* This line displays the alert */}
        </Row>
        <AddressModalCreate
          modalShow={modalShow}
          handleCloseModal={handleCloseModal}
          handleSubmit={handleSubmit}
          address={address}
          handleChange={handleChange}
          handleCloseCreatAdress={handleCloseCreatAdress}
          il={il}
          filteredIlce={filteredIlce}
          filteredMahalle={filteredMahalle}
        />
      </div>
    );
  }
  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <h2 className="">Adreslerim</h2>
        <Button
          onClick={() => handleCloseCreatAdress(true)}
          className="mr-2"
          variant="contained"
          disableElevation
        >
          <FontAwesomeIcon
            icon={faPlus}
            className="fas fa-eye showIcon userMenuIcon"
          />
        </Button>
      </div>
      {userData.addresses.map((address) => (
        <div className="d-flex justify-content-center">
          <Card className="shadow  mb-4" style={{ width: "80%" }}>
            <Card.Body>
              <div className="d-flex justify-content-between">
                <span>{address.adres_title}</span>
                <Button variant="warning" onClick={() => handleShow(address)}>
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              </div>
              <div>
                {address.il} / {address.ilce} / {address.mahalle}
              </div>
              <div>
                <span>Açık Adres : </span> {address.adres}
              </div>
            </Card.Body>
            <Card.Footer>
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className="custom-radio-container"
                  onClick={() => handleSetActive(address.id)}
                >
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className={`custom-radio-icon ${
                      selectedAddress === address.id ? "selected" : ""
                    }`}
                  />
                  <span className="custom-radio-label ml-2">
                    {selectedAddress === address.id
                      ? "Varsayilan"
                      : "Varsayilan Yap"}
                  </span>
                </div>
                <Button
                  style={{ backgroundColor: "#e74c3c", color: "white" }}
                  variant="contained"
                  onClick={() => handleDelete(address.id)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </div>
            </Card.Footer>
          </Card>
        </div>
      ))}

      <div style={componentStyle}>
        <ToastContainer position="top-right" autoClose={5000} />{" "}
        {/**
        <div className="d-flex justify-content-between mb-4">
          <h2 className="">Kullanıcı Adresi</h2>
          <Button
            onClick={() => handleCloseCreatAdress(true)}
            className="mr-2"
            variant="contained"
            disableElevation
          >
            <FontAwesomeIcon
              icon={faPlus}
              className="fas fa-eye showIcon userMenuIcon"
            />
          </Button>
        </div>
    
      
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Address Title</th>
              <th>Address</th>
              <th>City</th>
              <th>District</th>
              <th>Neighborhood</th>
              <th style={tableCellStyle}>Active</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {userData.addresses.map((address) => (
              <tr key={address.id}>
                <td>{address.adres_title}</td>
                <td>{address.adres}</td>
                <td>{address.il}</td>
                <td>{address.ilce}</td>
                <td>{address.mahalle}</td>
                <td style={styles.radioButtonCenter}>
                  <Form.Check
                    type="radio"
                    name="activeAddress"
                    checked={address.active}
                    onChange={() => handleSetActive(address.id)}
                  />
                </td>
                <td>
                  <Button
                    style={{ backgroundColor: "dodgerblue" }}
                    onClick={() => handleShow(address)}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </Button>
                  <Button
                    onClick={() => handleDelete(address.id)}
                    style={{
                      marginRight: "10px",
                      marginLeft: "10px",
                      backgroundColor: "#ff1a1a",
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </Button>

                  {address.active ? <p>Varsayılan</p> : <p>Varsayılan yap</p>}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
         */}
        <AddressModalEdit
          handleUpdate={handleUpdate}
          show={show}
          handleClose={handleClose}
          currentAddress={currentAddress}
        />
        <Modal centered show={modalShow} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>YENİ ADRES EKLE</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <Row className="mb-2">
                <Col xs={12} md={12} xl={4} xxl={4}>
                  <Form.Group>
                    <Form.Label>il</Form.Label>
                    <Form.Control
                      as="select"
                      name="il"
                      value={address.il}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled>
                        Select il
                      </option>{" "}
                      {il.map((item, index) => (
                        <option key={index} value={item.province_name}>
                          {item.province_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} md={12} xl={4} xxl={4}>
                  <Form.Group>
                    <Form.Label>Ilce</Form.Label>
                    <Form.Control
                      as="select"
                      name="ilce"
                      value={address.ilce}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled>
                        Select Ilce
                      </option>{" "}
                      {filteredIlce.map((item, index) => (
                        <option key={index} value={item.district_name}>
                          {item.district_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} md={12} xl={4} xxl={4}>
                  <Form.Group>
                    <Form.Label>Mahalle</Form.Label>
                    <Form.Control
                      as="select"
                      name="mahalle"
                      value={address.mahalle}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled>
                        Select Mahalle
                      </option>{" "}
                      {filteredMahalle.map((item, index) => (
                        <option key={index} value={item.neighborhood_name}>
                          {item.neighborhood_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group>
                <Form.Label>Address Title</Form.Label>
                <Form.Control
                  type="text"
                  name="adres_title"
                  value={address.adres_title}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Adres</Form.Label>
                <Form.Control
                  type="text"
                  name="adres"
                  value={address.adres}
                  onChange={handleChange}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => handleCloseCreatAdress(false)}
              >
                Kapat
              </Button>
              <Button variant="primary" type="submit">
                Ekle
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    </>
  );
}

export default UserAdress;
