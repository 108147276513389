import React from "react";
import Button from "react-bootstrap/Button";
import styles from "assets/styles/tourstamplate.module.css";
export const PraintBttons = () => {
  return (
    <div className={`d-flex justify-content-center ${styles.buttonContainer}`}>
      <Button className={`mx-3 ${styles.button}`}>YAZIR</Button>
      <Button className={`mx-3 ${styles.button}`}>E-POSTA GÖNDER</Button>
    </div>
  );
};
export default PraintBttons;
