import { axios } from "common/utils/axios";

export const getQuestions = () =>
  axios.get(`api/questions`).then((response) => response.data);
export const getQuestion = (id) => axios.get(`api/questions/${id}`);
export const createQuestion = (data) => axios.post(`api/questions`, data);
export const updateQuestion = ({ id, ...data }) =>
  axios.put(`api/questions/${id}`, data);

export const deleteQuestion = (id) => axios.delete(`api/questions/${id}`);
