import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactDOM from "react-dom";
import Badge from "@mui/material/Badge";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUsers,
  faTools,
  faQuestionCircle,
  faBolt,
  faCalendar,
  faCommentDots,
  faBriefcase,
  faInfoCircle,
  faFileAlt,
  faHotel,
  faMapLocationDot,
  faTruckPlane,
  faList,
  faHandshake,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";

import LogoutButton from "../LogoutButton";
import styles from "assets/styles/SideBar2.module.css";
import "assets/styles/SideBar.css";

export function SkinnySidebar({ setShowMenu }) {
  const iconRef = useRef([]);
  const location = useLocation();
  const [hoveredItem, setHoveredItem] = useState(null);
  const [currentMenuItems, setCurrentMenuItems] = useState([]);
  const [menuVisible, setMenuVisible] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const [activeConversationsCount, setActiveConversationsCount] = useState(0);

  const menuRef = useRef(null);
  let hideTimeout = useRef(null);
  let hoverTimeout = null;

  useEffect(() => {
    fetch("https://backend.suarelab.com/api/activeConversationsCount")
      .then((response) => response.json())
      .then((data) => {
        setActiveConversationsCount(data.active_conversations_count);
        setIsLoading(false);
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  const initiateHideMenu = () => {
    hideTimeout.current = setTimeout(() => {
      setMenuVisible(false);
    }, 300);
  };

  const cancelHideMenu = () => {
    if (hideTimeout.current) {
      clearTimeout(hideTimeout.current);
    }
  };

  const handleMouseEnter = (item, index) => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
    }

    if (item.menuItems) {
      const rect = iconRef.current[index].getBoundingClientRect();
      setPosition({
        top: rect.top + window.scrollY,
        left: rect.right + 5,
      });
      setCurrentMenuItems(item.menuItems);
      setMenuVisible(true);
    }
  };

  const handleMouseLeave = (item) => {
    if (item.menuItems) {
      hoverTimeout = setTimeout(() => {
        setMenuVisible(false);
      }, 300); // 300ms delay to hide
    }
  };

  const portalRoot = document.getElementById("portal-root");
  const Menu = menuVisible && (
    <div
      ref={menuRef}
      onMouseEnter={() => {
        if (hoverTimeout) {
          clearTimeout(hoverTimeout);
        }
      }}
      onMouseLeave={() => {
        hoverTimeout = setTimeout(() => {
          setMenuVisible(false);
        }, 300); // 300ms delay to hide
      }}
      style={{
        position: "fixed",
        top: `${position.top}px`,
        left: `${position.left}px`,
        zIndex: 1000,
        backgroundColor: "#767373",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      {currentMenuItems.map((item) => (
        <Link
          key={item.link}
          to={item.link}
          style={{
            paddingLeft: "2.5rem",
            paddingRight: "2.5rem",
            paddingTop: "1rem",
            paddingBottom: "1rem",
            display: "flex",
            alignItems: "center",
          }}
          className="block rounded"
        >
          {item.icon && (
            <span style={{ color: "white" }} className="mr-2">
              {item.icon}
            </span>
          )}
          <span style={{ color: "white" }}>{item.label}</span>
        </Link>
      ))}
    </div>
  );

  return (
    <div style={{ backgroundColor: "#212529", minHeight: "100vh" }}>
      <div
        className="flex flex-col items-center justify-center h-full space-y-6"
        style={{ color: "white" }}
      >
        {/**
      <Link to="/" title="Company Profile" className="mt-4">
      <FontAwesomeIcon icon={faBriefcase} style={{ fontSize: "25px" }} />
      </Link>
    */}
        {[
          {
            icon: (
              <FontAwesomeIcon
                icon={faBriefcase}
                style={{ fontSize: "25px" }}
              />
            ),
            link: "/admin/dashboard",
            label: "Gösterge Paneli",
            menuItems: [
              {
                icon: (
                  <FontAwesomeIcon icon={faHome} style={{ fontSize: "22px" }} />
                ),
                label: "Gösterge Paneli",
                link: "/admin/dashboard",
              },
            ],
          },
          {
            icon: (
              <FontAwesomeIcon icon={faUsers} style={{ fontSize: "22px" }} />
            ),
            link: "/admin/customerManagement/customers",
            label: "Müşteri Yönetimi",
            menuItems: [
              {
                icon: (
                  <FontAwesomeIcon
                    icon={faUsers}
                    style={{ fontSize: "22px" }}
                  />
                ),
                link: "/admin/customerManagement/customers",
                label: "Müşteri Yönetimi",
              },
              {
                link: "/admin/customerManagement/CustomersMeetings",
                label: "Müşteri Görüşmeleri",

                icon: (
                  <FontAwesomeIcon
                    icon={faHandshake}
                    style={{ fontSize: "22px" }}
                  />
                ),
              },
              {
                link: "/admin/customerManagement/CustomersOffers/",
                label: "Müşteri Teklifleri",

                icon: <FontAwesomeIcon icon={faUserTie} />,
              },
              // {
              //   link: "/admin/customerManagement/CustomersOffers",
              //   label: "Müşteri Teklifleri",
              // },
              // {
              //   link: "/admin/customerManagement/CustomersSales",
              //   label: "Müşteri Satış",
              // },
            ],
          },
          {
            icon: (
              <FontAwesomeIcon icon={faBolt} style={{ fontSize: "22px" }} />
            ),
            link: "/admin/advertismentManagement/TourMangment",
            label: "Tur Yönetimi",
            menuItems: [
              {
                icon: (
                  <FontAwesomeIcon
                    icon={faMapLocationDot}
                    style={{ fontSize: "22px" }}
                  />
                ),
                label: "Tur Yönetimi",
                link: "/admin/advertismentManagement/TourMangment",
              },
              {
                icon: (
                  <FontAwesomeIcon
                    icon={faHotel}
                    style={{ fontSize: "22px" }}
                  />
                ),
                label: "Otel Yönetimi",
                link: "/admin/Hotels",
              },

              {
                label: "Tur Kategoriler",
                link: "/admin/tour-category",

                icon: <FontAwesomeIcon icon={faList} />,
              },
              {
                label: "Nakliye Firmalar",
                link: "/admin/Transport-company",
                icon: (
                  <FontAwesomeIcon
                    icon={faTruckPlane}
                    style={{ fontSize: "22px" }}
                  />
                ),
              },
            ],
          },
          {
            icon: (
              <FontAwesomeIcon
                icon={faCommentDots}
                style={{ fontSize: "22px" }}
              />
            ),
            link: "/admin/post/index",
            label: "Bloglar",
            menuItems: [
              {
                icon: (
                  <FontAwesomeIcon
                    icon={faCommentDots}
                    style={{ fontSize: "22px" }}
                  />
                ),
                link: "/admin/post/index",
                label: "Bloglar",
              },
            ],
          },
          {
            icon: (
              <FontAwesomeIcon icon={faTools} style={{ fontSize: "22px" }} />
            ),

            label: "Service Yorumlar",
            menuItems: [
              {
                icon: (
                  <FontAwesomeIcon
                    icon={faTools}
                    style={{ fontSize: "22px" }}
                  />
                ),
                label: "İletişim Bilgileri",
                link: "/admin/contact-us",
              },
              {
                icon: (
                  <FontAwesomeIcon
                    icon={faTools}
                    style={{ fontSize: "22px" }}
                  />
                ),
                label: "Logolar",
                link: "/admin/Logos",
              },
              {
                icon: (
                  <FontAwesomeIcon
                    icon={faTools}
                    style={{ fontSize: "22px" }}
                  />
                ),
                label: "Ana Sayfa",
                link: "/admin/HomePage",
              },

              {
                icon: (
                  <FontAwesomeIcon
                    icon={faFileAlt}
                    style={{ fontSize: "22px" }}
                  />
                ),
                link: "/admin/comments/index",
                label: "Yorumlar",
              },
              {
                icon: (
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{ fontSize: "22px" }}
                  />
                ),
                link: "/admin/about-us/index",
                label: "Hakkımızda",
              },
              {
                icon: (
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    style={{ fontSize: "22px" }}
                  />
                ),
                link: "/admin/Qa/create",
                label: "SSS",
              },
            ],
          },
          {
            icon: (
              <FontAwesomeIcon icon={faCalendar} style={{ fontSize: "22px" }} />
            ),
            link: "/admin/ReservationManagement/list",
            label: "Rezervasyon Yönetimi",
            menuItems: [
              {
                icon: (
                  <FontAwesomeIcon
                    icon={faCalendar}
                    style={{ fontSize: "22px" }}
                  />
                ),
                link: "/admin/ReservationManagement/list",
                label: "Rezervasyon Yönetimi",
              },
            ],
          },

          {
            icon: (
              <Badge badgeContent={activeConversationsCount} color="error">
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  style={{ fontSize: "22px" }}
                />
              </Badge>
            ),
            link: "/admin/helpManagment/helpsystem",
            label: "Help",
          },
        ].map((item, index) => (
          <div
            style={{ marginTop: "20px" }}
            key={item.link}
            ref={(el) => {
              if (item.menuItems) {
                iconRef.current[index] = el;
              }
            }}
            onMouseEnter={() => handleMouseEnter(item, index)}
            onMouseLeave={() => handleMouseLeave(item)}
            className={`iconLink ${styles.iconLink}`}
          >
            <Link
              to={item.link}
              style={
                hoveredItem === item.link || location.pathname === item.link
                  ? { color: "black" }
                  : {}
              }
            >
              {item.icon}
            </Link>
          </div>
        ))}
        <span className="mb-4">
          <LogoutButton />
        </span>
      </div>
      {ReactDOM.createPortal(Menu, portalRoot)}
    </div>
  );
}

export default SkinnySidebar;
