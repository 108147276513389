import { useQuery } from "react-query";
import { axios } from "common/utils/axios";

const fetchCustomerById = (id) => {
  return axios.get(`/api/customers/${id}`).then((res) => res.data);
};

export const useCustomerByIdData = (id) => {
  const {
    data: customerData,
    error,
    isLoading,
  } = useQuery(["customerData", id], () => fetchCustomerById(id), {
    enabled: !!id, // Only run the query if the `id` exists
  });

  return {
    customerData,
    error,
    isLoading,
  };
};
