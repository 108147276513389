import React from "react";
import { Table, Button } from "react-bootstrap";

export const HelpMessagingTableList = ({
  conversations,
  handleClick,
  handleDownload,
  handleFinish,
  handleShow,
}) => {
  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>#</th>
          <th>User</th>
          <th>Neden</th>
          <th>Mesaj</th>
          <th>Durum</th>
          <th>Eylem</th>
        </tr>
      </thead>
      <tbody>
        {conversations.map((conversation, index) => (
          <tr key={conversation.id}>
            <td>{index + 1}</td>
            <td>{conversation.user_name}</td>
            <td>{conversation.reason}</td>
            <td>{conversation.message}</td>
            <td>{conversation.status}</td>
            <td>
              {conversation.is_active ? (
                <>
                  <Button
                    style={{
                      backgroundColor: "#007bff",
                      marginRight: "10px",
                    }}
                    variant="primary"
                    onClick={() => handleClick(conversation)}
                  >
                    Cevap
                  </Button>
                  {conversation.is_active && conversation.file_path ? (
                    <Button
                      style={{
                        backgroundColor: "#0d6efd",
                        marginRight: "10px",
                      }}
                      variant="primary"
                      onClick={() => handleDownload(conversation)}
                    >
                      İndirmeler
                    </Button>
                  ) : null}
                </>
              ) : (
                <></>
              )}
              {conversation.is_active ? (
                <Button
                  style={{ backgroundColor: "red" }}
                  variant="primary"
                  onClick={() => handleFinish(conversation)}
                >
                  Bitir
                </Button>
              ) : (
                <></>
              )}
              {!conversation.is_active ? (
                <Button
                  style={{ backgroundColor: "#0b5ed7" }}
                  onClick={() => handleShow(conversation)}
                >
                  Göster
                </Button>
              ) : null}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
export default HelpMessagingTableList;
