import React, { useState, useEffect } from "react";
import { axios } from "common/utils/axios";
import { toast, ToastContainer } from "react-toastify";
import { useParams, Link } from "react-router-dom";
import { Button, Form, Row, Col, Modal } from "react-bootstrap";
import PopUpReserversationTours from "./PopUpReserversationTours";
import AsyncSelect from "react-select/async";
import EditReservastion from "area/admin/ReservationMangment/components/EditReservastion";
export const EditReservsationModal = ({
  currentReservation,
  currentCode,
  setModalShowEdit,
  modalShowEdit,
  updateReservations,
}) => {
  const reservationId = currentReservation?.id;
  console.log("reservationId", reservationId);
  const [resultPrice, setResult] = useState("");
  const [selectedTour, setSelectedTour] = useState(
    currentReservation ? currentReservation.tour : null
  );
  const [customer, setCustomer] = useState(
    currentReservation
      ? {
          value: currentReservation.customer_id,
          label: `${currentReservation.customer.FirstName} ${currentReservation.customer.LastName}`,
        }
      : null
  );
  const [reservationDate, setReservationDate] = useState("");
  const [paymentDeadline, setPaymentDeadline] = useState("");
  const [tour, setTour] = useState("");
  const [durum, setDurum] = useState(
    currentReservation ? currentReservation.durum : ""
  );
  const [price, setPrice] = useState("");
  const [restPrice, setRestPrice] = useState("");
  const [curronse, setCurronse] = useState("");

  const [tours, setTours] = useState([]);
  const [Prices, setPrices] = useState([]);
  const [note, setNote] = useState(
    currentReservation ? currentReservation.note : ""
  );
  const [reservationCreated, setReservationCreated] = useState(false);

  let tourDate = selectedTour?.tourDate;

  let now = new Date();
  let year = now.getFullYear();
  let month = ("0" + (now.getMonth() + 1)).slice(-2);
  let day = ("0" + now.getDate()).slice(-2);
  let hour = ("0" + now.getHours()).slice(-2);
  let minute = ("0" + now.getMinutes()).slice(-2);
  let dateTime = `${year}-${month}-${day}T${hour}:${minute}`;

  const durums = [
    { durum: "kismi odemi" },
    { durum: "paid" },
    { durum: "diğer" },
  ];

  const loadCustomers = (inputValue = "") => {
    return axios
      .get("/api/customers-listCustomers", {
        params: {
          search: inputValue,
        },
      })
      .then((res) => {
        return res.data.map((customer) => ({
          value: customer.id,
          label: `${customer.FirstName} ${customer.LastName}`,
        }));
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let adjustedPaymentDeadline = paymentDeadline;

    // check if paymentDeadline year is less than 2020
    const paymentDeadlineYear = new Date(paymentDeadline).getFullYear();
    if (
      paymentDeadlineYear < 2020 ||
      paymentDeadlineYear == null ||
      paymentDeadlineYear == 1 / 1 / 1970
    ) {
      adjustedPaymentDeadline = "no payment founded"; // set it to null
    }
    const url = `api/reservation_management/${reservationId}`;
    try {
      const response = await axios.put(url, {
        // Handl this
        customer_id: customer.value,
        reservation_date: tourDate,
        payment_deadline: adjustedPaymentDeadline,
        tour_id: selectedTour.tourId,
        durum: durum,
        note: note,
        price: price,
        restPrice: resultPrice == price ? 0 : resultPrice,
        curronse: curronse.abbreviation,
      });

      // Extract only the relevant data from the API response
      const newReservation = {
        code: response.data.code,
        tour_id: selectedTour.tourId,
        customer: {
          FirstName: customer.label.split(" ")[0],
          LastName: customer.label.split(" ")[1],
        },
        created_by: {
          name: "Creator Name",
        },
        durum: durum,
        price: price,
        restPrice: resultPrice == price ? 0 : resultPrice,
        reservation_date: tourDate,
        payment_deadline: adjustedPaymentDeadline,
        note: note,
      };

      // Call the updateReservationsData function with the new reservation data
      updateReservations(newReservation);
      toast.success("Reservation created successfully");
      setModalShowEdit(false);
    } catch (err) {
      console.error(err);
      toast.error("An error occurred while creating the reservation");
    }
  };

  if (reservationCreated) {
    return (
      <div>
        <p>Reservation created successfully</p>
        <Link to={`/admin/ReservationManagement/list`}>
          Go to Reservation Management List
        </Link>
      </div>
    );
  }
  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    return inputValue;
  };
  const handleReservationDateChange = (e) => {
    setReservationDate(e.target.value);
  };
  console.log(currentReservation, "reservation");
  return (
    <div>
      {" "}
      <Modal
        show={modalShowEdit}
        onHide={() => setModalShowEdit(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Rezervasyonu düzenle
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <Row>
              <Col xl={6}>
                <Form.Group controlId="formTour">
                  <Form.Label>Müşteri</Form.Label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    value={customer}
                    getOptionLabel={(e) => e.label}
                    getOptionValue={(e) => e.value}
                    loadOptions={loadCustomers}
                    onInputChange={handleInputChange}
                    onChange={(e) => setCustomer(e)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col xl={6} style={{ display: "flex", alignItems: "center" }}>
                <PopUpReserversationTours handleSaveTours={setSelectedTour} />
                {selectedTour && (
                  <span
                    style={{
                      textAlign: "center",
                      width: "50%",
                      marginTop: "30px",
                      marginLeft: "30px",
                      color: "white",
                      backgroundColor: "green",
                      borderRadius: "8px",
                    }}
                  >
                    {selectedTour?.tourName}
                  </span>
                )}
              </Col>
              <Col xl={4}>
                <Form.Group controlId="formReservationDate">
                  <Form.Label>Rezervasyon Tarihi</Form.Label>
                  <Form.Control
                    type="date"
                    value={selectedTour?.tourDate}
                    onChange={handleReservationDateChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col xl={4}>
                <Form.Group controlId="formPaymentDeadlinePrice">
                  <Form.Group controlId="formPaymentDeadlinePrice">
                    <Form.Label>Fiyat</Form.Label>
                    <Form.Control
                      as="select"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      required
                    >
                      <option value="">-- Bir Fiyat Seçin --</option>
                      {selectedTour &&
                        selectedTour?.pricing.map((priceOption, index) => (
                          <option key={index} value={priceOption.mainPrice}>
                            {priceOption?.howManyPeople} kişi fiyat{" "}
                            {priceOption?.mainPrice} {curronse.abbreviation}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>
                </Form.Group>
              </Col>
              <Col xl={4}>
                <Form.Group controlId="formTour">
                  <Form.Label>Durum</Form.Label>
                  <Form.Control
                    as="select"
                    value={durum}
                    onChange={(e) => setDurum(e.target.value)}
                    required
                  >
                    <option value="">-- Bir durum seçin --</option>
                    {durums.map((durum, index) => (
                      <option value={durum.durum} key={index}>
                        {durum.durum}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              {durum === "kismi odemi" || durum === "another" ? (
                <div>
                  <Row>
                    <Col md={6}>
                      <Form.Group
                        controlId="formRestPrice"
                        className="form-group"
                      >
                        <Form.Label>fiyatın geri kalanı</Form.Label>
                        <Form.Control
                          as="input"
                          type="text"
                          placeholder=""
                          value={restPrice}
                          onChange={(e) => {
                            const numericValue = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            ); // Filter out non-numeric characters
                            setRestPrice(numericValue);
                          }}
                          className="form-control"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <p>
                        Rest of Price: {resultPrice} {curronse.abbreviation}
                      </p>
                    </Col>
                  </Row>

                  <Form.Group controlId="formPaymentDeadline">
                    <Form.Label>Payment Deadline</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      min={dateTime}
                      value={paymentDeadline}
                      onChange={(e) => setPaymentDeadline(e.target.value)}
                      required
                    />
                  </Form.Group>
                </div>
              ) : (
                <div></div>
              )}

              <Form.Group controlId="formNote" className="form-group">
                <Form.Label className="form-label">Note</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Enter note"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  className="form-control"
                />
              </Form.Group>
            </Row>
            <Button style={{ backgroundColor: "dodgerblue" }} type="submit">
              Ekle
            </Button>

            <Button
              active={true}
              className="btn btn-secondary ml-2"
              onClick={() => setModalShowEdit(false)}
            >
              Kapat
            </Button>
            <ToastContainer />
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default EditReservsationModal;
