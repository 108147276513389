import React from "react";

export default function OtelImageComponent({editOtelImage, setOtelImage}) {
	const handleOnChangeUrl = (e) => {
		console.log(e.target.value);
		setOtelImage(e.target.value);
	};
	return (
		<div>
			<label>Resmin Kendisi</label>
			<br />
			<img
				className="w-2/5"
				src={editOtelImage}
				alt={""}
			/>
			<label>Resmin Adresi</label>
			<br />
			<textarea
				className="border-2"
				rows="4"
				cols="50"
				value={editOtelImage}
				onChange={handleOnChangeUrl}></textarea>
			<br />
		</div>
	);
}
