import { useState, useEffect } from "react";
import { axios } from "common/utils/axios";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { Modal, Form, Button, Card, Col, Row } from "react-bootstrap";
import EditCustomer from "./EditCustomer";
import "assets/styles/CustomeGeneral.css";
const fields = [
  { label: "First Name", name: "firstName" },
  { label: "Last Name", name: "lastName" },
  { label: "Telefon", name: "telefon" },
  { label: "Jop", name: "jop" },

  { label: "Eposta", name: "eposta" },
];
const fetchCustomerData = async (customerId) => {
  const { data } = await axios.get(`api/customers/${customerId}`);
  return data;
};

const EditeCustomerModal = ({
  openEdit,
  setOpenEdit,
  customerIdFromTable,
  selectedOption,
  handleOptionChange,
  handleEditeIsActive,
  customerId,
}) => {
  const { id } = useParams();
  let CustomerParamsIdOrTableId = id || customerIdFromTable;
  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    telefon: "",
    jop: "",
    faceBook: "",
    instagram: "",
    tweeter: "",
    eposta: "",
    adres: "",

    registrationTypeId: "",
    birthDate: "",
    gender: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const {
    data: customerData,
    isLoading,
    error,
    refetch,
  } = useQuery(
    ["customer", CustomerParamsIdOrTableId],
    () => fetchCustomerData(CustomerParamsIdOrTableId),
    {
      enabled: false, // This query will not execute until the id exists
    }
  );

  const handleFormSubmit = (event) => {
    event.preventDefault();
    axios
      .put(`api/customers/update/${CustomerParamsIdOrTableId}`, formState)
      .then(({ data }) => {
        console.log(data);
        toast.success("Update successful"); // Display success toast
        setOpenEdit(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Update failed");
        toast.error("Error ");
      });
  };
  useEffect(() => {
    if (openEdit && CustomerParamsIdOrTableId) {
      refetch();
    }
  }, [openEdit, CustomerParamsIdOrTableId, refetch]);
  useEffect(() => {
    if (customerData && openEdit) {
      setFormState({
        firstName: customerData.FirstName,
        lastName: customerData.LastName,
        telefon: customerData.Telefon,
        jop: customerData.jop,
        eposta: customerData.Eposta,
        birthDate: customerData.birthDate,
        gender: customerData.Gender,
      });
    }
  }, [customerData, openEdit]);

  return (
    <Modal show={openEdit} onHide={() => setOpenEdit(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Müşteriyi Düzenle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleFormSubmit}>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Adı:</Form.Label>
                <Form.Control
                  type=""
                  name="firstName"
                  value={formState.firstName || ""}
                  onChange={handleChange}
                  className="custom-input"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Soyadı:</Form.Label>
                <Form.Control
                  type=""
                  name="lastName"
                  value={formState.lastName || ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group>
            <Form.Label>Telefon:</Form.Label>
            <Form.Control
              type=""
              name="telefon"
              value={formState.telefon || ""}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>iş:</Form.Label>
            <Form.Control
              type=""
              name="jop"
              value={formState.jop || ""}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>E-posta:</Form.Label>
            <Form.Control
              type="email"
              name="eposta"
              value={formState.eposta || ""}
              onChange={handleChange}
            />
          </Form.Group>
          {/* Add other fields as needed */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={(event) => handleFormSubmit(event)} type="submit">
          Düzenle
        </Button>
        <Button
          active={true}
          variant="secondary"
          onClick={() => setOpenEdit(false)}
        >
          Kapat
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditeCustomerModal;
