import { useEffect, useState } from "react";

function useFacebook(appId) {
  const [status, setStatus] = useState("pending");

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: appId,
        cookie: true,
        xfbml: true,
        version: "v15.0",
      });

      window.FB.getLoginStatus((response) => {
        if (response.status === "connected") {
          setStatus("connected");
        } else {
          setStatus("disconnected");
        }
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, [appId]);

  return status;
}
export default useFacebook;
