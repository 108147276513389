export const getTodayDateString = () => {
  const today = new Date();
  const year = today.getFullYear();
  let month = today.getMonth() + 1; // we + 1  because the idiot js returns moths from 0-11
  let day = today.getDate();

  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;

  return `${year}-${month}-${day}`;
};
