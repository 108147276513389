import { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { faEllipsisV, faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, Link } from "react-router-dom";
export const AdminHeadr = ({ setAnchorEl, anchorEl, toggleSidebar }) => {
  const styles = {
    navbarSection: {
      position: "sticky",
      top: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "10px 20px",
      borderRadius: "8px",
      boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
      background: "linear-gradient(90deg, #f8f8f8, #e6e6e6)",
      zIndex: 2,
    },
    navbarUsername: {
      color: "#4A4A4A",
      fontSize: "1.1em",
      fontWeight: "500",
      marginRight: "1rem",
      cursor: "pointer",
    },
    navbarIcon: {
      padding: "5px",
      borderRadius: "50%",
      background: "#f5f5f5",
      boxShadow: "0 1px 5px rgba(0,0,0,0.1)",
      cursor: "pointer",
    },
    navbarAvatar: {
      width: "50px",
      height: "50px",
      marginLeft: "10px",
      marginRight: "10px",
      boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
      borderRadius: "50%",
    },
    sidebarToggleIcon: {
      padding: "5px",
      borderRadius: "50%",
      background: "#f5f5f5",
      boxShadow: "0 1px 5px rgba(0,0,0,0.1)",
      cursor: "pointer",
    },
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogOutClick = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    fetch("https://backend.suarelab.com/api/testlogout", requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Logout failed");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Logout success:", data);
        localStorage.removeItem("user");
        localStorage.removeItem("role"); // Make sure to remove role as well

        // Sign out of Google
        if (gapiReady) {
          const auth2 = window.gapi.auth2.getAuthInstance();
          if (auth2 != null) {
            auth2.signOut().then(
              auth2.disconnect().then(() => {
                console.log("Google sign out successful");
              })
            );
          }
        }

        navigate("/");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const navigate = useNavigate();

  const [gapiReady, setGapiReady] = useState(false);

  useEffect(() => {
    if (window.gapi) {
      window.gapi.load("auth2", () => {
        window.gapi.auth2
          .init({
            client_id:
              "3246594183-cnrr1p3rkr3vlj859k5b04gslog825oe.apps.googleusercontent.com", // replace with your client id
          })
          .then(() => setGapiReady(true));
      });
    }
  }, []);
  return (
    <div style={styles.navbarSection}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <FontAwesomeIcon
          icon={faBars}
          style={styles.sidebarToggleIcon}
          onClick={toggleSidebar}
        />
        <Link
          to="/"
          target="_blank"
          style={{
            marginLeft: "30px",
            textDecoration: "none",
            color: "#4A4A4A",
            fontSize: "1.1em",
            fontWeight: "500",
          }}
        >
          Ön Sayfa
        </Link>
      </div>
      <div className="flex items-center">
        <span style={styles.navbarUsername} onClick={handleClick}>
          <img
            src="https://suarelab.com/MainLogo/header.png"
            width={150}
            height={150}
          />
          {/**
          <Avatar
          src="path_to_avatar.jpg"
          alt="User Avatar"
          style={styles.navbarAvatar}
          />
        */}
        </span>
        <IconButton onClick={handleClick} style={styles.navbarIcon}>
          <FontAwesomeIcon icon={faEllipsisV} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>Profilim</MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to="/resetPassword">Şifre Değiştir</Link>
          </MenuItem>
          <MenuItem onClick={handleLogOutClick}>Çıkış Yap</MenuItem>
        </Menu>
      </div>
    </div>
  );
};
export default AdminHeadr;
