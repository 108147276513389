import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneVolume,
  faEnvelope,
  faComments,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import { ReactComponent as Whatsapp } from "assets/icons/whatsapp.svg";
import WhatsAppPng from "assets/icons/whatsapp.png";
import whatsapp64px from "assets/icons/whatsapp64px.png";
import "assets/styles/ContactUs.css"; // Import your CSS file

const BubbleContactUs = ({ contactData }) => {
  const [showIcons, setShowIcons] = useState(true);

  const handleArrowClick = () => {
    setShowIcons(!showIcons);
  };
  const toggleIcons = () => {
    setShowIcons(!showIcons);
  };
  const toggleOPen = () => {
    setShowIcons(true);
  };
  console.log(contactData, "contactData");
  return (
    <div className="contact-us-container">
      <button className="arrow-button" onClick={() => toggleOPen()}>
        <FontAwesomeIcon
          icon={faComments}
          style={{ color: "white", fontSize: "22px" }}
          primaryColor="white"
          secondaryColor="#a6a6a6"
        />
      </button>
      {showIcons && (
        <div className="icons-container">
          <button className="close-button" onClick={toggleIcons}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <a
            target="blank"
            href={`https://wa.me/${contactData?.data.whatsapp}`}
            className="icon whatsapp-icon"
          >
            {" "}
            <img src={whatsapp64px} />
          </a>
          <a
            href={`tel:${contactData?.data.telefon}`}
            className="icon messenger-icon"
          >
            <FontAwesomeIcon icon={faPhoneVolume} style={{ color: "white" }} />
          </a>
          <a
            href={`mailto:${contactData?.data.email}`}
            className="icon email-icon"
          >
            <FontAwesomeIcon icon={faEnvelope} style={{ color: "white" }} />
          </a>
        </div>
      )}
    </div>
  );
};

export default BubbleContactUs;
