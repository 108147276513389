import React from "react";
import { AccommodationInformation } from "./AccommodationInformation";
import { InformationofAccommodationPersons } from "./InformationofAccommodationPersons";
import { ReservationAndBillingInformation } from "./ReservationAndBillingInformation";
import { RezervasyonNot } from "./RezervasyonNot";
import { SideBar } from "../SideBar";
import { Wizard } from "../Wizard";
export const ApprovalMainContent = () => {
  return (
    <div>
      <div className="main-content-bottom">
        <div className="row">
          <div className="col-12 col-md-8 content-body">
            <div className="detail-tab-content">
              <Wizard currentStep={1} />
              <form>
                <AccommodationInformation />
                <InformationofAccommodationPersons />
                <ReservationAndBillingInformation />
                <RezervasyonNot />
              </form>
            </div>
          </div>
          <div class="col-12 col-md-3 mb-4">
            <SideBar />
          </div>
        </div>
      </div>
    </div>
  );
};
