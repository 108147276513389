import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

import Typography from "@mui/material/Typography";
import { useQuery } from "react-query";
import { axios } from "common/utils/axios";
import { useCustomerData } from "common/hooks/useCustomerData";
import { faTrash, faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { Table, Form, Col, Row, Modal, Button } from "react-bootstrap"; // Import Table from react-bootstrap
const Direction = ["Online", "Offsite"];
function formatDate(dateString) {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
    hour12: false,
  };
  const date = new Date(dateString);
  return date.toLocaleDateString("tr-TR", options) + " ";
}

export const FutureMeeting = () => {
  const { id, userName } = useParams();
  const [showEditMeetingModal, setShowEditMeetingModal] = useState(false);
  const [meetingId, setMeetingId] = useState("");
  const [customerMeetings, setCustomerMeetings] = useState({
    data: { MeetingDate: "", Direction: "", Note: "" },
  });
  const formattedUserName = userName?.replace(/-/g, " ");
  // API Calls

  const { data, isLoading, isError, error } = useQuery("upcomingMeetings", () =>
    axios.get(`/api/customers/upcomingmeetings/${id}`).then((res) => res.data)
  );

  const handleDeleteMeeting = async (meetingId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this meeting?"
    );
    if (confirmDelete) {
      try {
        // Update the URL to match the Laravel route definition
        await axios.delete(`/api/customers/meetings/${meetingId}`);

        toast.success("Meeting deleted successfully!");
      } catch (error) {
        console.error(error);

        let errorMessage =
          error.response?.data?.message || "Something went wrong!";
        toast.error(errorMessage);
      }
    }
  };
  const handleShowEditAndMeetingId = (meetingId) => {
    setShowEditMeetingModal(true);
    setMeetingId(meetingId);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerMeetings((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [name]: value,
      },
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      ...customerMeetings.data, // Spread existing meeting data
      customer_name: formattedUserName, // Include the formatted user name
    };
    console.log(
      customerMeetings.data,
      "customerMeetings.datacustomerMeetings.datacustomerMeetings.data"
    );
    axios
      .put(`/api/customers/${id}/meetings/${meetingId}`, payload)
      .then((res) => {
        console.log(res);
        toast.success("Meeting updated successfully!");
        setShowEditMeetingModal(false);
      })
      .catch((error) => {
        console.error(error);
        let errorMessage = error.response?.data?.message;
        if (!errorMessage) {
          errorMessage = "Something went wrong!";
        }

        toast.error(errorMessage);
      });
  };
  console.log(formattedUserName, "formattedUserNameformattedUserName");
  const card = (
    <React.Fragment>
      <CardContent>
        <Typography sx={{ fontSize: 20 }} color="text.secondary" gutterBottom>
          GÖRÜŞMELER
        </Typography>
      </CardContent>
      <CardActions>
        <Link key="one" to={`CreateMeeting`}>
          <Button
            size="medium"
            style={{ backgroundColor: "DodgerBlue", color: "#fff" }}
          >
            GÖRÜŞME oluşturuldu
          </Button>
        </Link>
      </CardActions>
    </React.Fragment>
  );

  return (
    <div>
      <div>
        <Box sx={{ minWidth: 275 }}>
          <Card variant="outlined">{card}</Card>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Müşteri</th>
                <th>Yön</th>
                <th>Not</th>
                <th>Görüşme Tarihi</th>
                <th>Oluşturuldu</th>
                <th>Eylem</th>
              </tr>
            </thead>
            <tbody>
              {data?.upcomingMeetings?.map((row) => (
                <tr key={row.id}>
                  <td>{row.customer_name}</td>
                  <td>{row.Direction}</td>
                  <td>{row.Note}</td>
                  <td>{row.MeetingDate}</td>
                  <td>{formatDate(row.created_at)}</td>
                  <td>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        variant="danger"
                        style={{
                          marginLeft: "5px",
                          marginRight: "5px",
                          color: "white",
                        }}
                        onClick={() => handleDeleteMeeting(row.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>

                      <Button
                        onClick={() => {
                          handleShowEditAndMeetingId(row.id);
                        }}
                        style={{
                          height: "36px",

                          color: "#fff",
                          fontWeight: "bold",
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
      </div>
      <Modal
        centered
        show={showEditMeetingModal}
        onHide={() => setShowEditMeetingModal(false)}
      >
        <Modal.Header closeButton>
          <h3>Toplantıyı Düzenle</h3>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group className="">
                <Form.Label>Yön</Form.Label>
                <Form.Select
                  name="Direction"
                  value={customerMeetings.data.Direction}
                  onChange={handleChange}
                  aria-label="Direction select"
                >
                  {Direction &&
                    Direction?.map((direction, index) => (
                      <option key={index} value={direction}>
                        {direction}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Tarihi Ve Saat</Form.Label>
                <Form.Control
                  type="datetime-local"
                  name="MeetingDate"
                  value={customerMeetings.data.MeetingDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={12} className="mt-4">
              <Form.Group>
                <Form.Label>Not</Form.Label>
                <Form.Control
                  as="textarea"
                  name="Note"
                  value={customerMeetings.data.Note}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={12} className="d-flex justify-content-end">
              <Button className="m-4" onClick={handleSubmit}>
                Ekle
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FutureMeeting;
