import React, { useState, useEffect } from "react";
import SelectInput from "../Components/SelectInput";
import TextInput from "../Components/TextInput";
import { axios } from "common/utils/axios";
import { useCustomerData } from "common/hooks/useCustomerData";
import { useToursData } from "common/hooks/useToursData";
import { Button } from "react-bootstrap";
export const CreateSales = () => {
  const customersData = useCustomerData();
  const toursData = useToursData();
  const [customerSalesData, setCustomerSalesData] = useState({
    customer_id: "",
    tour_id: "",
    price_2: "",
    price_3: "",
    price_4: "",
    price_5: "",
    price: "",
    offer_status: "",
    stars_level: "",
  });
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!customerSalesData.tour_id) {
      console.error("Please select a tour.");
      return;
    }
    if (!customerSalesData.customer_id) {
      console.error("Please select a tour.");
      return;
    }
    axios
      .post("/api/customer-sales", customerSalesData)
      .then((response) => console.log(response.data))
      .catch((error) => console.error(error));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerSalesData({
      ...customerSalesData,
      [name]: value,
    });
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div
          style={{
            backgroundColor: "#cdcdcb",
            padding: "5px",
            borderRadius: "4px",
            marginBottom: "15px",
          }}
        >
          <h2>Satış Oluştur</h2>
        </div>
        <div className="mb-4">
          <h3>Müşteri Seçimi</h3>
          <SelectInput
            name="customer_id"
            value={customerSalesData.customer_id}
            onChange={handleChange}
            label={"Muşteriler"}
            options={customersData.map((item) => ({
              value: item.id,
              label: `${item.FirstName} ${item.LastName}`, // Concatenate the first name and last name
            }))}
            keyName="customer"
          />
        </div>
        <h3>Turlar</h3>
        <SelectInput
          name="tour_id"
          value={customerSalesData.tour_id}
          onChange={handleChange}
          label={"Tour"}
          options={toursData.map((tour) => ({
            value: tour.id,
            label: tour.title, // Use the tour title as a label
          }))}
          keyName="tours"
        />
        <TextInput
          name="price"
          onChange={(event) => {
            const inputValue = event.target.value;
            if (/^\d*$/.test(inputValue) && inputValue.length <= 13) {
              setCustomerSalesData({
                ...customerSalesData,
                price: inputValue,
              });
            }
          }}
          value={customerSalesData.price}
        />
        <Button type="submit">Satış Ekle</Button>
      </form>
    </div>
  );
};
export default CreateSales;
