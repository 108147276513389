import { Card, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEdit } from "@fortawesome/free-solid-svg-icons";
export default () => {
  return (
    <li>
      <Card>
        <Card.Header> Son Kaydedilen Firmalar</Card.Header>
        <Card.Body>
          <Table bordered hover>
            <thead>
              <tr>
                <th>Firma</th>
                <th>Adı</th>
                <th>Soyadı</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Hayat</td>
                <td>Nabil Yahya</td>
                <td>Yahya</td>
              </tr>
              <tr>
                <td>Hty</td>
                <td>Yamen Yahya</td>

                <td>Yahya</td>
              </tr>
              <tr>
                <td>Akyol</td>
                <td>Jad Yahya</td>
                <td>Yahya</td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </li>
  );
};
