import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { axios } from "common/utils/axios";
import { toast, ToastContainer } from "react-toastify";

function CreateReservation() {
  const { id } = useParams();
  const [reservationDate, setReservationDate] = useState("");
  const [paymentDeadline, setPaymentDeadline] = useState("");
  const [tour, setTour] = useState("");
  const [tours, setTours] = useState([]);
  const [reservationCreated, setReservationCreated] = useState(false);

  useEffect(() => {
    const url = `/api/tours`;

    axios
      .get(url)
      .then((response) => {
        setTours(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = `api/reservation_management`;

    try {
      await axios.post(url, {
        customer_id: id,
        reservation_date: reservationDate,
        payment_deadline: paymentDeadline,
        tour_id: tour,
      });
      toast.success("Reservation created successfully");
      setReservationCreated(true);
    } catch (err) {
      console.error(err);
      toast.error("An error occurred while creating the reservation");
    }
  };

  if (reservationCreated) {
    return (
      <div>
        <p>Reservation created successfully</p>
        <Link
          to={`/admin/customerManagement/customers/${id}/ReservationManagementList`}
        >
          Go to Reservation Management List
        </Link>
      </div>
    );
  }

  return (
    <Form onSubmit={handleSubmit} style={{ width: "50%", margin: "auto" }}>
      <Form.Group controlId="formReservationDate">
        <Form.Label>Reservation Date</Form.Label>
        <Form.Control
          type="date"
          value={reservationDate}
          onChange={(e) => setReservationDate(e.target.value)}
          required
        />
      </Form.Group>
      <Form.Group controlId="formPaymentDeadline">
        <Form.Label>Payment Deadline</Form.Label>
        <Form.Control
          type="date"
          value={paymentDeadline}
          onChange={(e) => setPaymentDeadline(e.target.value)}
          required
        />
      </Form.Group>
      <Form.Group controlId="formTour">
        <Form.Label>Tour</Form.Label>
        <Form.Control
          as="select"
          value={tour}
          onChange={(e) => setTour(e.target.value)}
          required
        >
          <option value="">-- Select a Tour --</option>
          {tours.map((tour, index) => (
            <option value={tour.id} key={index}>
              {tour.title}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Button style={{ backgroundColor: "dodgerblue" }} type="submit">
        Submit
      </Button>
      <Link
        to={`/admin/customerManagement/customers/${id}/ReservationManagementList`}
        className="btn btn-secondary ml-2"
      >
        Cancel
      </Link>
      <ToastContainer /> {/* Add this line to render the toast notifications */}
    </Form>
  );
}

export default CreateReservation;
