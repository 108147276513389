import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";
import tw from "twin.macro";

import DatePicker from "../date-picker/DatePicker";
import { axios } from "../../utils/axios";

const Input = tw.input`relative w-full px-8 py-5 rounded-lg font-medium bg-white border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold text-gray-100 min-w-[250px] py-1 rounded-lg hover:bg-[#83C5BE] hover:text-black transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  background-color: #006D77; // Custom background color
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const StyledDatePicker = styled(DatePicker)`
  .datepicker-input::placeholder {
    color: #9ca3af; /* Placeholder color */
  }
`;
export default function TourSearchingArea() {
  const [search_params, setSearch_params] = useState([]);
  const [search_params_filtered, setSearch_params_filtered] = useState([]);
  const [showSelect, setShowSelect] = useState(false);

  const whereTour = useRef();

  let navigate = useNavigate();

  const handleChange = (event) => {
    let newArr = [];
    search_params?.map((item) =>
      item?.value?.toUpperCase().includes(event.target.value.toUpperCase())
        ? (newArr = [...newArr, item])
        : " "
    );
    setSearch_params_filtered(newArr);
  };
  const handleFocus = () => {
    setShowSelect(true);
  };
  const handleBlur = () => {
    setTimeout(run, 200);
    function run() {
      setShowSelect(false);
    }
  };
  const hanleTourClick = (e) => {
    whereTour.current.value = e.target.dataset.value;
    whereTour.current.key = e.target.dataset.key;
    whereTour.current.datatype = e.target.dataset.type;
  };
  function handleSearch() {
    return navigate(
      `/tours?key=${whereTour.current.key}&type=${whereTour.current.datatype}`
    );
  }
  function getSearchParams() {
    //ToDo
    //api/search_params_tour the real one
    // axios.get("api/tours").then((item) => {
    //   setSearch_params(item.data);
    //   setSearch_params_filtered(item.data);
    // });
  }
  useEffect(() => {
    getSearchParams();
  }, []);

  return (
    <div className="w-full ">
      <Input
        className="w-full"
        ref={whereTour}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder="Tour Adı... Konum..."
      />
      <div
        className={`absolute z-10 overflow-auto h-48 border right-[0] w-full scrollbar bg-white ${
          showSelect ? "" : "hidden"
        }`}
      >
        <div className="p-5 shadow-xl">
          <ul>
            {search_params_filtered?.map((item, index) => (
              <li
                className="even:bg-gray-100 odd:bg-primary-500 odd:text-white p-2 my-2 rounded border"
                key={index}
                data-type={item.type}
                data-value={item.value}
                data-key={item.key}
                onClick={hanleTourClick}
              >
                {item.value}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <StyledDatePicker className="datepicker-input" />
      {/* <Input placeholder="Tour Adı... Konum..." /> */}
      <div className="flex justify-center">
        <SubmitButton type="submit" onClick={handleSearch}>
          <span className="text">Ara</span>
        </SubmitButton>
      </div>
    </div>
  );
}
