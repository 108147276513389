const HotelAdress = ({
  HotelAddress,
  hotelAddress = "123+Main+Street,+New+York,+NY",
}) => {
  console.log(HotelAddress, "HotelAddress");
  const country = "Turkey";
  const state = "Bursa";
  const city = "Osmangazi";
  const neighborhood = "Çırpan";
  const street = "Koru Sokak";
  const buildingNumber = "No 9";
  const apartmentNumber = "D1";

  const address = `${city}, ${state}, ${country} , ${neighborhood} , ${street} ,${buildingNumber} , ${apartmentNumber}`;
  const encodedAddress = encodeURIComponent(address);

  return (
    <div className="map-container">
      <iframe
        title="Hotel Location"
        src={`https://www.google.com/maps?q=${encodedAddress}&output=embed`}
        width="100%"
        height="200"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
      ></iframe>
    </div>
  );
};
export default HotelAdress;
