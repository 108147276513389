import { Row, Col, Card } from "react-bootstrap";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faCloud, faStar } from "@fortawesome/free-solid-svg-icons";
import { CardContent, Typography, Box } from "@mui/material";
export default () => {
  const cardStyles = [
    {
      bg: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
      Icon: faEnvelope,
      title: "Firmalar",
      number: "5",
    },
    {
      bg: "linear-gradient(45deg, #66BB6A 30%, #98EE99 90%)",
      Icon: faCloud,
      title: "Aktif İlanlar",
      number: "5",
    },
    {
      bg: "linear-gradient(45deg, #BA68C8 30%, #FF94C9 90%)",
      Icon: faStar,
      title: "Pasif İlanlar",
      number: "5",
    },
  ];
  const hoverAnimation = {
    scale: 1.1,
    boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.3)",
  };

  return (
    <Row>
      {cardStyles.map((style, index) => (
        <Col key={index} xs={12} md={4}>
          <motion.div whileHover={hoverAnimation} sx={{ m: 1 }}>
            <Card
              className="mb-4"
              sx={{
                minWidth: 290,
                height: 150,
                maxWidth: 300,
                width: "100%",
                background: style.bg,
                boxShadow: "3px 3px 5px 2px rgba(255, 105, 135, .3)",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography>{style.title}</Typography>
                  <Typography variant="h6">{style.number}</Typography>
                </Box>
                <FontAwesomeIcon icon={style.Icon} sx={{ fontSize: 60 }} />
              </CardContent>
            </Card>
          </motion.div>
        </Col>
      ))}
    </Row>
  );
};
