import React, { useState, useEffect } from "react";
import SelectInput from "../Components/SelectInput";
import TextInput from "../Components/TextInput";
import { axios } from "common/utils/axios";
import { useCustomerData } from "common/hooks/useCustomerData";
import { useToursData } from "common/hooks/useToursData";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { Button, Col, Row, Form, Card } from "react-bootstrap";
import AsyncSelect from "react-select/async";
const getCurrentDateTime = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};
const CreateOffers = () => {
  const [toursData, setToursData] = useState([]);
  const [selectedTourPricing, setSelectedTourPricing] = useState([]);
  const [selectedTour, setSelectedTour] = useState(null);
  const [customer, setCustomer] = useState("");
  const [customerNewOfferData, setCustomerNewOfferData] = useState({
    customer_id: "",
    tour_id: "",
    offer_statu: "",
    stars_level: "",
    prices: [],
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Update customerNewOfferData with selected customer id
    const updatedOfferData = {
      ...customerNewOfferData,
      customer_id: customer ? customer.value : "",
    };
    console.log(updatedOfferData, "updatedOfferData");
    try {
      const response = await axios.post("/api/tour-offer", updatedOfferData);
      toast.success("Yeni Teklif Eklendi!"); // Show success toast
      setTimeout(() => {
        window.location.href = "/admin/customerManagement/CustomersOffers"; // Redirect after a delay
      }, 2500);
    } catch (error) {
      toast.error("An error occurred. Please try again later."); // Show error toast
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerNewOfferData({
      ...customerNewOfferData,
      [name]: value,
    });
  };
  const handlePriceChange = (howManyPeople, value, pricingType) => {
    const newPrices = [...customerNewOfferData.prices];
    const priceIndex = newPrices.findIndex(
      (price) => price.how_many_people === howManyPeople
    );

    if (priceIndex !== -1) {
      newPrices[priceIndex] = {
        ...newPrices[priceIndex],
        price: value,
        pricing_type: pricingType,
      };
    } else {
      newPrices.push({
        how_many_people: howManyPeople,
        price: value,
        pricing_type: pricingType,
      });
    }

    setCustomerNewOfferData((prevState) => ({
      ...prevState,
      prices: newPrices,
    }));
  };
  const handleTourChange = (e) => {
    const tourId = e.target.value;
    const selectedTour = toursData.find(
      (tour) => tour.tourId.toString() === tourId
    );

    if (selectedTour && selectedTour.pricing) {
      setSelectedTourPricing(selectedTour.pricing);
      setCustomerNewOfferData((prevState) => ({
        ...prevState,
        tour_id: tourId,
        prices: selectedTour.pricing.map((p) => ({
          how_many_people: p.howManyPeople,
          price: p.mainPrice,
          pricing_type: p.pricingType,
        })),
      }));
    } else {
      setSelectedTourPricing([]);
      setCustomerNewOfferData((prevState) => ({
        ...prevState,
        tour_id: tourId,
        prices: [],
      }));
    }
  };
  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    return inputValue;
  };
  const loadCustomers = (inputValue = "") => {
    return axios
      .get("/api/customers-listCustomers", {
        params: {
          search: inputValue,
        },
      })
      .then((res) => {
        return res.data.map((customer) => ({
          value: customer.id,
          label: `${customer.FirstName} ${customer.LastName}`,
        }));
      });
  };
  useEffect(() => {
    axios
      .get("api/get-active-tours")
      .then((response) => setToursData(response.data))
      .catch((error) => console.error(error));
  }, []);

  console.log(toursData, "toursDatatoursDatatoursData");
  return (
    <div className="form-container">
      <div
        style={{
          display: "flex", // Use flexbox
          justifyContent: "space-between", // Space between the title and the button
          alignItems: "center", // Vertically center align items
          backgroundColor: "rgb(99, 102, 101)",
          padding: "5px",
          borderRadius: "4px",
          marginBottom: "15px",
          fontWeight: "bold",
          padding: "10px",
          color: "white",
        }}
      >
        <h2>Yeni Teklif</h2>
        <Link
          to="/admin/customerManagement/CustomersOffers"
          style={{ textDecoration: "none" }}
        >
          <Button
            variant="outline-warning"
            type="button"
            style={{
              padding: "10px",
              borderRadius: "4px",
              color: "white",
              marginRight: "10px",
            }}
          >
            Geri
          </Button>
        </Link>
      </div>

      <div className="p-2">
        <Card
          className="p-4"
          style={{
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          }}
        >
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xl={3}>
                <Form.Group controlId="formTour">
                  <h3>Müşteri</h3>

                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    value={customer}
                    getOptionLabel={(e) => e.label}
                    getOptionValue={(e) => e.value}
                    loadOptions={loadCustomers}
                    onInputChange={handleInputChange}
                    onChange={(e) => setCustomer(e)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col xl={3}>
                <Form.Group controlId="tours">
                  <h3>Turlar</h3>
                  <Form.Select
                    name="tour_id"
                    value={customerNewOfferData.tour_id}
                    onChange={handleTourChange}
                  >
                    <option value="" disabled selected>
                      {" "}
                      -- Tur seç --{" "}
                    </option>
                    {toursData.map((tour) => (
                      <option key={tour.tourId} value={tour.tourId}>
                        {tour.tourName}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xl={3}>
                <Form.Group controlId="stars">
                  <h3>İlgi Düzeyi</h3>
                  <Form.Select
                    name="stars_level"
                    value={customerNewOfferData.stars_level}
                    onChange={handleChange}
                  >
                    <option value="" disabled selected>
                      {" "}
                      -- İlgi Düzeyi Seç --{" "}
                    </option>
                    <option value="Çok Düşük">Çok Düşük</option>
                    <option value="Düşük">Düşük</option>
                    <option value="Orta">Orta</option>
                    <option value="Yüksek">Yüksek</option>
                    <option value="Çok Yüksek">Çok Yüksek</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xl={3}>
                <Form.Group controlId="offers">
                  <h3>Teklif Durumu</h3>
                  <Form.Select
                    name="offer_statu"
                    value={customerNewOfferData.offer_statu}
                    onChange={handleChange}
                  >
                    <option value="" disabled selected>
                      {" "}
                      -- Durumu Seç --{" "}
                    </option>

                    <option value="Teklif Verildi">Teklif Verildi</option>
                    <option value="Teklif Kabul Edildi">
                      Teklif Kabul Edildi
                    </option>
                    <option value="Teklif Reddedildi">Teklif Reddedildi</option>
                    <option value="Teklif Revize Edildi">
                      Teklif Revize Edildi
                    </option>
                    <option value="Teklif Iptal Edildi">
                      Teklif Iptal Edildi
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>
              {selectedTourPricing.map((price, index) => (
                <Col className="mt-4" xl={3} key={index}>
                  <Form.Group controlId={`pricing-${price.howManyPeople}`}>
                    <Form.Label>
                      {price.howManyPeople} Kişi - Fiyat ({price.pricingType})
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name={`price-${price.howManyPeople}`}
                      value={
                        customerNewOfferData.prices.find(
                          (p) => p.how_many_people === price.howManyPeople
                        )?.price || price.mainPrice
                      }
                      onChange={(e) =>
                        handlePriceChange(
                          price.howManyPeople,
                          e.target.value,
                          price.pricingType
                        )
                      }
                      min="0"
                      step="0.01"
                    />
                  </Form.Group>
                </Col>
              ))}
            </Row>
            <div className="d-flex justify-content-end">
              <Button className="mt-4" type="submit">
                Teklif Ekle
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default CreateOffers;
