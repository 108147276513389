import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import FamilyModalCreate from "./components/FamilyModalCreate";
import FamilyModalEdit from "./components/FamilyModalEdit";
import FamilyModalDelete from "./components/FamilyModalDelete";
import {
  Card,
  ListGroup,
  ListGroupItem,
  Col,
  Row,
  Button,
  Modal,
  Form,
  Alert,
} from "react-bootstrap";
import { axios } from "common/utils/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faPencilAlt,
  faTrashAlt,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import UploadFileInput from "area/admin/CustomerMangement/ServiceCustomersComponents/Components/uploadFileInput";
import RadioInput from "area/admin/CustomerMangement/ServiceCustomersComponents/Components/RadioInput";

const styles = {
  smallerText: {
    fontSize: "18px",
  },
  cardShadow: {
    width: "18rem",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
  },
};
const familyRelationships = ["Kardeş", "Anne", "Baba", "Diğer"];
export const UserFamily = ({ userData, userId }) => {
  console.log(userData, "userData");
  // const [customers, setCustomers] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [createFamilyMemberModal, setCreateFamilyMemberModal] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState(null);
  const [customerFamily, setCustomerFamily] = useState({
    data: {
      FirstName: "",
      LastName: "",
      RelativeRelation: "",
      Jop: "",
      Gender: "",
      BirthDay: "",
      Telefon: "",
      Eposta: "",
      explantion: "",
      photo: "",
    },
  });
  // useEffect(() => {
  //   fetchCustomers();
  // }, []);

  // const fetchCustomers = async () => {
  //   try {
  //     const response = await axios.get(`/api/customers/${userData.id}/family`);
  //     const data = response.data;
  //     setCustomers(data);
  //   } catch (error) {
  //     console.error("Error fetching customers:", error);
  //   }
  // };

  // const userId = location.state ? location.state.userId : null;

  // const { id } = useParams();
  // const [familyRelationships, setFamilyRelationships] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (const key in customerFamily.data) {
      if (key === "photo" && customerFamily.data[key]) {
        formData.append(
          `data[${key}]`,
          customerFamily.data[key],
          customerFamily.data[key].name
        );
      } else {
        formData.append(`data[${key}]`, customerFamily.data[key]);
      }
    }
    axios
      .post(`api/customers/${userId}/family`, formData)
      .then((res) => {
        console.log(res);
        toast.success("Family member added successfully!");
        setCreateFamilyMemberModal(false);
        handleCloseCreateFamilyDialog();
      })
      .catch((error) => {
        console.error(error);
        toast.error("An error occurred while adding the family member!");
      });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerFamily({
      ...customerFamily,
      data: {
        ...customerFamily.data,
        [name]: value,
      },
    });
  };
  // Add this function inside CreateCustomerFamily component
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setCustomerFamily({
      ...customerFamily,
      data: {
        ...customerFamily.data,
        [name]: files[0],
      },
    });
  };
  const handleCloseCreateFamilyDialog = () => {
    setCreateFamilyMemberModal(false);
  };
  const handleOpenCreateFamilyDialog = () => {
    setCreateFamilyMemberModal(true);
  };

  const handleClose = () => setShow(false);
  const handleShow = (customer) => {
    setSelectedCustomer(customer);
    setShow(true);
  };

  const handleEdit = () => {
    handleClose();
  };
  const handleDelete = () => {
    if (!customerToDelete) return;

    axios
      .delete(`/api/customer-family/${userData.id}`)
      .then((response) => {
        console.log(response.data);
        // Hide the delete confirmation modal
        setShowDeleteConfirm(false);
        // Remove the deleted customer from the state

        // Show a success toast
        toast.success("Customer deleted successfully");
      })
      .catch((error) => console.error(error));
  };
  if (!userData || userData.customer_families.length === 0) {
    return (
      <div>
        <Row className="justify-content-center">
          <ToastContainer />
          <div className="d-flex justify-content-between mb-4">
            <h2>Ailem</h2>
            <Button
              className="mr-2"
              variant="contained"
              disableElevation
              onClick={() => setCreateFamilyMemberModal(true)}
            >
              <FontAwesomeIcon
                icon={faPlus}
                className="fas fa-eye showIcon userMenuIcon"
              />
            </Button>
          </div>
          <Alert variant="warning">Aile üyeleri bilgisi bulunamadı.</Alert>{" "}
          {/* This line displays the alert */}
        </Row>
        <FamilyModalCreate
          createFamilyMemberModal={createFamilyMemberModal}
          handleCloseCreateFamilyDialog={handleCloseCreateFamilyDialog}
          handleSubmit={handleSubmit}
          customerFamily={customerFamily}
          handleChange={handleChange}
          handleFileChange={handleFileChange}
        />
      </div>
    );
  }

  return (
    <Row>
      <ToastContainer />
      <div className="d-flex justify-content-between mb-4">
        <h2>Ailem</h2>
        <Button
          className="mr-2"
          variant="contained"
          disableElevation
          onClick={() => setCreateFamilyMemberModal(true)}
        >
          <FontAwesomeIcon
            icon={faPlus}
            className="fas fa-eye showIcon userMenuIcon"
          />
        </Button>
      </div>
      {userData.customer_families.map((customer) => (
        <div className="d-flex justify-content-center">
          <Card className="shadow mb-3" style={{ width: "80%" }}>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <img
                      src={`https://backend.suarelab.com/api/uploads/familyMembers/${customer.photo}`}
                      alt="User"
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div>
                    <div>{`${customer.FirstName} ${customer.LastName}`}</div>
                    <div style={{ fontSize: "12px" }}>{customer.Telefon}</div>
                    <div style={{ fontSize: "12px" }}>{customer.Eposta}</div>
                    <div style={{ fontSize: "12px" }}>
                      Creation Date | {customer.Gender ? "Male" : "Female"} |{" "}
                      {customer.Jop}
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    onClick={() => handleShow(customer)}
                    className="btn btn-primary me-2"
                  >
                    <FontAwesomeIcon icon={faEdit} />
                    Güngel
                  </button>
                  <button
                    onClick={() => {
                      setCustomerToDelete(customer);
                      setShowDeleteConfirm(true);
                    }}
                    className="btn btn-danger"
                  >
                    <FontAwesomeIcon icon={faTrash} /> Sil
                  </button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      ))}

      <FamilyModalEdit
        show={show}
        handleClose={handleClose}
        handleEdit={handleEdit}
        selectedCustomer={selectedCustomer}
      />
      {/**Delete Modal */}
      <FamilyModalDelete
        setShowDeleteConfirm={setShowDeleteConfirm}
        handleDelete={handleDelete}
        showDeleteConfirm={showDeleteConfirm}
      />
      <Modal
        centered
        show={createFamilyMemberModal}
        onHide={handleCloseCreateFamilyDialog}
        size="lg"
        style={{ overflowY: "auto" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>AİLE BİLGİLERİ Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="p-2">
              <Col md={4}>
                {/* Assuming TextInput, SelectInput, DateInput, RadioInput, and UploadFileInput are your custom components */}
                <Form.Group controlId="FirstName">
                  <Form.Label>Adı</Form.Label>
                  <Form.Control
                    type=""
                    name="FirstName"
                    value={customerFamily.data.FirstName}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="LastName">
                  <Form.Label>SoyAdı</Form.Label>
                  <Form.Control
                    type=""
                    name="LastName"
                    value={customerFamily.data.LastName}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                {/* You might need to adjust SelectInput to use React-Bootstrap's Form.Select */}
                <Form.Group controlId="RelativeRelation">
                  <Form.Label>İlişki</Form.Label>
                  <Form.Select
                    name="RelativeRelation"
                    value={customerFamily.data.RelativeRelation}
                    onChange={handleChange}
                  >
                    <option hidden value="">
                      -- İlişki Seç --
                    </option>
                    {familyRelationships.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group controlId="Jop">
                  <Form.Label>Meslek</Form.Label>
                  <Form.Control
                    type=""
                    name="Jop"
                    value={customerFamily.data.Jop}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12}>
                {/* Assuming RadioInput is a custom component for handling radio inputs */}
                <RadioInput
                  name="Gender"
                  value={customerFamily.data.Gender}
                  onChange={handleChange}
                />
              </Col>
              <Col md={4}>
                <Form.Group controlId="BirthDay">
                  <Form.Label>Doğum Tarihi</Form.Label>
                  <Form.Control
                    type="date"
                    name="BirthDay"
                    value={customerFamily.data.BirthDay}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="Eposta">
                  <Form.Label>E-posta</Form.Label>
                  <Form.Control
                    type="email"
                    name="Eposta"
                    value={customerFamily.data.Eposta}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="Telefon">
                  <Form.Label>Telefon</Form.Label>
                  <Form.Control
                    type=""
                    name="Telefon"
                    value={customerFamily.data.Telefon}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} className="mb-4">
                <Form.Group controlId="explantion">
                  <Form.Label>Açıklama</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="explantion"
                    value={customerFamily.data.explantion}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12}>
                <UploadFileInput name="photo" onChange={handleFileChange} />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={(e) => handleSubmit(e)}
            type="submit"
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
            }}
          >
            Ekle
          </Button>
          <Button variant="secondary" onClick={handleCloseCreateFamilyDialog}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal>
    </Row>
  );
};

export default UserFamily;
