import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { Container, ContentWithPaddingLg } from "../misc/Layouts.js";
import HacSearchingArea from "../searching/HacSearchingArea";
import UmraSearchingArea from "../searching/UmraSearchingArea";
import TourSearchingArea from "../searching/TourSearchingArea";
import OtelSearchingArea from "../searching/OtelSearchingArea";
import "assets/styles/Style.css";
const HeaderRow = tw.div`flex justify-center items-center flex-col xl:flex-row`;
const TabsControl = tw.div`flex justify-center items-center text-center bg-gray-200 px-2 py-2 rounded leading-none xl:mt-0 space-x-2`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-4 py-3 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base text-center`}
  flex: 1; // Ensure each tab takes up equal space
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${(props) =>
    props.active &&
    `background-color: #006D77; color: white;`}// Updated active state
`;

const TabContent = tw(motion.div)`mt-6 flex w-full justify-center`;

export default ({
  heading = "Checkout the Menu",
  tabs = {
    Hac: { content: <HacSearchingArea /> },
    Umre: { content: <UmraSearchingArea /> },
    Tur: { content: <TourSearchingArea /> },
    Otel: { content: <OtelSearchingArea /> },
  },
}) => {
  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  return (
    <div style={{ width: "90%" }}>
      <Container className="z-10 border bg-white px-12 py-6">
        <HeaderRow className="bg-yellow-300 text-center">
          <TabsControl className="mx-auto w-full text-center">
            {Object.keys(tabs).map((tabName, index) => (
              <TabControl
                className="text-center"
                key={index}
                active={activeTab === tabName}
                onClick={() => setActiveTab(tabName)}
              >
                {tabName}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>

        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale: 1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale: 0.8,
                display: "none",
              },
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >
            {tabs[tabKey].content}
          </TabContent>
        ))}
      </Container>
    </div>
  );
};
