import React, { useState, useEffect } from "react";

function RangeSlider({ min, max, setMin, setMax }) {
  // const [min, setMin] = useState(100);
  // const [max, setMax] = useState(3500);
  const handleMinChange = (e) => {
    const value = Math.min(Number(e.target.value), max - 1);
    setMin(value);
  };

  const handleMaxChange = (e) => {
    const value = Math.max(Number(e.target.value), min + 1);
    setMax(value);
  };
  return (
    <div>
      <h4 className="mb-2">Fiyat Aralığı</h4>
      <div className="container">
        <div className="slider-track slider-track__color">
          <input
            className="slider"
            id="slider-a"
            type="range"
            min="100"
            max="3500"
            onChange={handleMinChange}
            value={min}
            data-popup-enabled="true"
            data-show-value="true"
          />
          <input
            className="slider"
            id="slider-b"
            type="range"
            min="100"
            max="3500"
            onChange={handleMaxChange}
            value={max}
            data-popup-enabled="true"
            data-show-value="true"
          />
        </div>
      </div>
      <div className="d-flex flex-row-reverse mt-4">
        <span className="rang-font">{max}</span>
        <span className="flex-fill rang-font">{min}</span>
      </div>
    </div>
  );
}

export default RangeSlider;
