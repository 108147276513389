import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { Col, Row, Card, Form, Button } from "react-bootstrap";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { axios } from "common/utils/axios";
import { useLocation } from "react-router-dom";
// import { getTours } from "common/Api/AdvertismentApi";

import ReactSelect from "react-select";
import TourFilter from "pages/NewTour/ToursFilter";
import ToggleButton from "./Components/PriceToggelButtons";
import GlobalTourBG from "assets/images/BG/GlobalTourBG4.png";
import ToursFilterMobile from "pages/NewTour/ToursFilterMobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FiyatCollapsibleCard from "pages/NewTour/Components/FilterCollapes";
import AddressCollapsibleCard from "pages/NewTour/Components/FilterCollapes";
import MonthCollapsibleCard from "pages/NewTour/Components/FilterCollapes";
import DaysCollapsibleCard from "pages/NewTour/Components/FilterCollapes";
import PublishdateCollapsibleCard from "pages/NewTour/Components/FilterCollapes";
import OtelRoomsCollapsibleCard from "pages/NewTour/Components/FilterCollapes";
import AccommodationCollapsibleCard from "pages/NewTour/Components/FilterCollapes";
import VisaCollapsibleCard from "pages/NewTour/Components/FilterCollapes";
import CapacityCollapsibleCard from "pages/NewTour/Components/FilterCollapes";
import SeasonCollapsibleCard from "pages/NewTour/Components/FilterCollapes";
import TourCards from "pages/NewTour/Components/TourCards";
import { ToastContainer } from "react-toastify";
import { mapMonthNameToNumber } from "common/helpers/monthNameToNumber";
import { HeaderHeightContext } from "layouts/GlobalLayout";
import {
  faFilter,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import Footer from "common/components/footers/MiniCenteredFooter.js";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "assets/styles/NewTour.css";

const images = [
  { src: "https://via.placeholder.com/150", alt: "Placeholder Image 1" },
  { src: "https://via.placeholder.com/150", alt: "Placeholder Image 2" },
  // Add more placeholder images as needed
];

const months = [
  "Ocak",
  "Şubat",
  "Mart",
  "Nisan",
  "Mayıs",
  "Haziran",
  "Temmuz",
  "Ağustos",
  "Eylül",
  "Ekim",
  "Kasım",
  "Aralık",
];
const days = ["3", "8", "10", "15", "20", "24", "30", "+ 30"];
const timeOptions = [
  { label: "Son 24 saat", value: "24-hours" },
  { label: "Son 3 gün içinde", value: "3-days" },
  { label: "Son 7 gün içinde", value: "7-days" },
  { label: "Son 15 gün içinde", value: "15-days" },
  { label: "Son 30 gün içinde", value: "30-days" },
];
const seasonOptions = [
  { label: "İlk Bahar", value: "7-days" },
  { label: "Yaz", value: "24-hours" },
  { label: "Son Bahar", value: "15-days" },
  { label: "Kış", value: "3-days" },
  { label: "Hepsi", value: "30-days" },
];

const NewToursPage = () => {
  const location = useLocation();
  const { categoryName } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");

  const observer = useRef();
  const headerHeightContext = useContext(HeaderHeightContext);
  const {
    setHeaderHeight,
    setDisplay,
    setHeaderTitlePaddingTop,
    setHeaderTitlePaddingBottom,
    setHeaderPaddingTop,
  } = headerHeightContext;
  const [selectedTours, setSelectedTours] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [tours, setTours] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [, forceUpdate] = useState();
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedseason, setSelectedseason] = useState("");
  const [selectedMonth, setSelectedMonth] = useState([]);
  const [selectedDays, setSelecteDays] = useState([]);
  const [withAccommodation, setWithAccommodation] = useState(null);
  const [withVisa, setWithVisa] = useState(null);
  const [nerden, setNerden] = useState("");
  const [nereye, setNereye] = useState("");
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const [collapseStates, setCollapseStates] = useState({
    fiyat: true,
    address: true,
    month: true,
    days: true,
    publishDate: true,
    otelRooms: false,
    accommodation: false,
    visa: false,
    capacity: false,
    season: false,
  });
  const [selectedButtons, setSelectedButtons] = useState({
    TRY: false,
    USD: false,
    EUR: false,
    GBP: false,
  });

  const getSelectedCurrency = () => {
    return (
      Object.keys(selectedButtons).find((key) => selectedButtons[key]) || "TRY"
    );
  };

  const toggleCollapse = (key) => {
    setCollapseStates((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };
  const handleCheckboxPdfChange = (tourId) => {
    console.log("Selected tourId before update:", tourId);
    setSelectedTours((prevSelectedTours) => {
      console.log("Current selectedTours:", prevSelectedTours);
      const isAlreadySelected = prevSelectedTours.includes(tourId);
      const newSelectedTours = isAlreadySelected
        ? prevSelectedTours.filter((id) => id !== tourId)
        : [...prevSelectedTours, tourId];
      console.log("Updated selectedTours:", newSelectedTours);
      return newSelectedTours;
    });
  };
  console.log(selectedTours, "selectedTours");
  const lastTourElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && currentPage < totalPages) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, currentPage, totalPages]
  );

  const resetFilters = () => {
    setTours([]);
    setCurrentPage(1);
    setSelectedButtons({ TRY: false, USD: false, EUR: false, GBP: false });
    setMinPrice("");
    setMaxPrice("");
    setNerden("");
    setNereye("");
    setSelectedMonth([]);
    setSelecteDays([]);
    setIsFilterApplied(false);

    setTimeout(() => {
      refetch(); // Refetch after a delay
    }, 700);
    forceUpdate({});
  };

  const getTours = async (filters = {}) => {
    setIsLoading(true);
    setIsError(false);
    const queryParams = new URLSearchParams(filters).toString();

    try {
      const response = await axios.get(`api/newtour?${queryParams}`);
      setTotalPages(response.data.last_page);
      console.log("Fetched tours:", response.data.data);
      // Create a new set of unique tour IDs from the current and new tours
      const uniqueTourIds = new Set([
        ...tours.map((tour) => tour.tourId),
        ...response.data.data.map((tour) => tour.tourId),
      ]);

      // Filter the combined array of tours to include only those with unique IDs
      const uniqueTours = [...tours, ...response.data.data].filter((tour) =>
        uniqueTourIds.has(tour.tourId)
      );

      setTours(uniqueTours);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const createFilterObject = (page = 1) => {
    const filters = { page: page };
    const selectedCurrency = getSelectedCurrency();
    if (startDate) filters.startDate = startDate;
    if (endDate) filters.endDate = endDate;
    if (categoryName) filters.categoryName = categoryName;
    if (selectedDays) filters.selectedDays = selectedDays;
    if (selectedMonth.length > 0) {
      filters.selectedMonth = selectedMonth.map(mapMonthNameToNumber);
    }
    if (minPrice) filters.minPrice = minPrice;
    if (maxPrice) filters.maxPrice = maxPrice;
    if (selectedCurrency) filters.currency = selectedCurrency;
    if (nerden) filters.fromWhere = nerden;

    return filters;
  };

  const fetchToursWithFilters = async (filters) => {
    if (isLoading) return;

    setIsLoading(true);

    try {
      const response = await axios.get(
        `api/newtour?${new URLSearchParams(filters).toString()}`
      );
      setTotalPages(response.data.last_page);

      let fetchedTours = response.data.data;
      if (filters.page === 1) {
        // For the first page, replace the tours
        setTours(fetchedTours);
      } else {
        // For subsequent pages, append the tours
        setTours((prevTours) => [...prevTours, ...fetchedTours]);
      }
    } catch (error) {
      console.error("Error fetching tours:", error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };
  const filterObject = createFilterObject();
  const filterKey = JSON.stringify(filterObject);
  const {
    data: queryTours,
    isLoadingData,
    isErrorData,
    error,
    refetch,
  } = useQuery(["tours", "filterKey"], () =>
    fetchToursWithFilters(filterObject)
  );
  const applyFilters = () => {
    const filters = createFilterObject();
    setTours([]);
    setIsFilterApplied(true);
    setCurrentPage(1);
    setTimeout(() => {
      fetchToursWithFilters({ ...filters, page: 1 }); // Refetch after a delay
    }, 900);
  };
  useEffect(() => {
    const filters = createFilterObject(currentPage);
    console.log("Fetching tours for page:", currentPage);
    fetchToursWithFilters(filters);
  }, [currentPage]);

  useEffect(() => {
    if (!isFilterApplied) return;
    const filters = createFilterObject();
    setTours([]);
    setCurrentPage(1);
    fetchToursWithFilters({ ...filters, page: currentPage });
    setIsFilterApplied(false);
  }, [
    tours,
    isFilterApplied,
    currentPage,
    minPrice,
    maxPrice,
    selectedTime,
    selectedMonth,
    selectedDays,
    selectedseason,
    withAccommodation,
    withVisa,
    nerden,
    nereye,
    selectedButtons,
    categoryName,
    // ... other filters
  ]);

  const handleScroll = () => {
    const bottomReached =
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight;

    if (bottomReached && currentPage < totalPages && !isLoading) {
      console.log("Bottom reached, current page before update:", currentPage);
      setCurrentPage((prevPage) => {
        console.log("Updating page to:", prevPage + 1);
        return prevPage + 1;
      });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [currentPage, totalPages, isLoading]);

  const handleButtonClick = (name) => {
    setSelectedButtons((prev) => {
      const resetButtons = Object.keys(prev).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});

      // Set the clicked button to true
      return { ...resetButtons, [name]: true };
    });
  };

  const handleCheckboxChange = (option, setSelecter) => {
    setSelecter((prevSelected) => {
      if (prevSelected.includes(option)) {
        return prevSelected.filter((item) => item !== option);
      } else {
        return [...prevSelected, option];
      }
    });
  };
  useEffect(() => {
    setHeaderPaddingTop("0");
    setDisplay("none");
    setHeaderHeight("0px"); // Set the header height to 200px
    setHeaderTitlePaddingTop("0px");
    setHeaderTitlePaddingBottom("0px");
    return () => {
      setHeaderHeight(null);
      setDisplay(null);
      setHeaderPaddingTop(null);
      setHeaderTitlePaddingBottom(null);
      setHeaderTitlePaddingTop(null); // Reset the header height when component unmounts
    };
  }, [setHeaderHeight]);

  console.log(tours, "tours");
  if (isLoadingData) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div
      style={{
        backgroundImage: `url(${GlobalTourBG})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        className="pl-md-4 pr-md-4 pb-md-4 mb-2"
        style={{ paddingTop: "80px" }}
      >
        <Row>
          {startDate && startDate ? (
            <Col xl={12} className="d-flex justify-content-center">
              <Card className="shadow p-2" style={{ borderRadius: "0px" }}>
                <div>
                  <h5 className="monsterratFont ">
                    Zamanlamaya göre uçuş programı
                    <span className="w600 ml-2">
                      {startDate ? startDate : ""}
                    </span>{" "}
                    - <span className="w600"> {endDate ? endDate : ""}</span>
                  </h5>
                </div>
              </Card>
            </Col>
          ) : (
            ""
          )}
          <Col className="d-flex justify-content-end mb-2">
            <Button
              style={{ borderRadius: "0px !important" }}
              variant="primary"
              className="d-md-none greenBC monsterratFont w600 filter-button"
              onClick={handleShowModal}
            >
              <FontAwesomeIcon icon={faFilter} /> Filters
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-xxl-center">
          <Col md={2} className="d-none d-md-block">
            <button
              style={{ width: "100%" }}
              className="filter-tour-button mb-2"
              onClick={applyFilters}
            >
              Filtreleri Uygula
            </button>
            <button
              style={{ width: "100%" }}
              className="clear-filter-tour-button mb-2"
              onClick={resetFilters}
            >
              Filtreleri Temizle
            </button>

            <FiyatCollapsibleCard
              open={collapseStates.fiyat}
              toggleCollapse={() => toggleCollapse("fiyat")}
              title={"Fiyat"}
            >
              <Row className="d-flex justify-content-start align-items-center mb-2 pl-4">
                {Object.keys(selectedButtons).map((name) => (
                  <ToggleButton
                    key={name}
                    name={name}
                    isSelected={selectedButtons[name]}
                    onClick={() => handleButtonClick(name)}
                  />
                ))}
              </Row>
              <div className="form-group-container mb-2 pl-3">
                <Form.Group>
                  <Form.Control
                    min={0}
                    type="number"
                    className="small-input"
                    placeholder={`Min ${getSelectedCurrency()}`}
                    value={minPrice}
                    onChange={(e) => setMinPrice(e.target.value)}
                  />
                </Form.Group>
                <span className="dash-between">-</span>
                <Form.Group>
                  <Form.Control
                    min={minPrice}
                    type="number"
                    className="small-input"
                    placeholder={`Max ${getSelectedCurrency()}`}
                    value={maxPrice}
                    onChange={(e) => setMaxPrice(e.target.value)}
                  />
                </Form.Group>
              </div>
            </FiyatCollapsibleCard>
            <AddressCollapsibleCard
              title={"Konum / Yön"}
              open={collapseStates.address}
              toggleCollapse={() => toggleCollapse("address")}
            >
              {" "}
              <div className="select-container mb-2 pl-3">
                <div className="select-small">
                  <ReactSelect
                    value={nerden}
                    onChange={(selectedOption) => setNerden(selectedOption)}
                    options={[
                      { value: "option1", label: "istabul" },
                      { value: "option2", label: "ankara" },
                    ]}
                    isSearchable
                    placeholder="Nerden"
                  />
                </div>
                <div className="select-small">
                  <ReactSelect
                    value={nereye}
                    onChange={(selectedOption) => setNereye(selectedOption)}
                    options={[
                      { value: "option1", label: "Meke" },
                      { value: "option2", label: "Medine" },
                    ]}
                    isSearchable
                    placeholder="Nereye"
                  />
                </div>
              </div>
            </AddressCollapsibleCard>
            <MonthCollapsibleCard
              title={"Aylar"}
              open={collapseStates.month}
              toggleCollapse={() => toggleCollapse("month")}
            >
              {months.map((option, index) => (
                <Form.Check
                  className="mb-2 custom-checkbox"
                  style={{
                    fontSize: "12px",
                    marginLeft: "15px",
                  }}
                  type="checkbox"
                  id={`checkbox-${index}`}
                  label={option}
                  key={index}
                  checked={selectedMonth.includes(option)}
                  onChange={() =>
                    handleCheckboxChange(option, setSelectedMonth)
                  }
                />
              ))}
            </MonthCollapsibleCard>
            <DaysCollapsibleCard
              title={"Gün Sayısı"}
              open={collapseStates.days}
              toggleCollapse={() => toggleCollapse("days")}
            >
              {days.map((option, index) => (
                <Form.Check
                  className="mb-2 custom-checkbox"
                  style={{
                    fontSize: "12px",
                    marginLeft: "15px",
                  }}
                  type="checkbox"
                  id={`checkbox-${index}D`}
                  label={`${option} Günlük`}
                  key={index}
                  checked={selectedDays.includes(option)}
                  onChange={() => handleCheckboxChange(option, setSelecteDays)}
                />
              ))}
            </DaysCollapsibleCard>
            <PublishdateCollapsibleCard
              title={"İlan Tarihi"}
              open={collapseStates.publishDate}
              toggleCollapse={() => toggleCollapse("publishDate")}
            >
              <div className="time-options pl-4">
                {timeOptions.map((option) => (
                  <label key={option.value} className="time-option">
                    <input
                      type="radio"
                      name="time"
                      value={option.value}
                      checked={selectedTime === option.value}
                      onChange={() => setSelectedseason(option.value)}
                    />
                    {option.label}
                  </label>
                ))}
              </div>
            </PublishdateCollapsibleCard>
            <OtelRoomsCollapsibleCard
              title={"Otel Odaları"}
              open={collapseStates.otelRooms}
              toggleCollapse={() => toggleCollapse("otelRooms")}
            ></OtelRoomsCollapsibleCard>
            <AccommodationCollapsibleCard
              title={"Mahrem izni"}
              open={collapseStates.accommodation}
              toggleCollapse={() => toggleCollapse("accommodation")}
            >
              <div className="accommodation-options">
                <label className="accommodation-option">
                  <input
                    type="radio"
                    name="accommodation"
                    value="false"
                    checked={withAccommodation === "false"}
                    onChange={() => setWithAccommodation("false")}
                  />
                  Hayır
                </label>
                <label className="accommodation-option">
                  <input
                    type="radio"
                    name="accommodation"
                    value="true"
                    checked={withAccommodation === "true"}
                    onChange={() => setWithAccommodation("true")}
                  />
                  Evet
                </label>
              </div>
            </AccommodationCollapsibleCard>
            <VisaCollapsibleCard
              title={"Vize"}
              open={collapseStates.visa}
              toggleCollapse={() => toggleCollapse("visa")}
            >
              {" "}
              <div className="accommodation-options">
                <label className="accommodation-option">
                  <input
                    type="radio"
                    name="visa"
                    value="false"
                    checked={withVisa === "false"}
                    onChange={() => setWithVisa("false")}
                  />
                  Hayır
                </label>
                <label className="accommodation-option">
                  <input
                    type="radio"
                    name="visa"
                    value="true"
                    checked={withVisa === "true"}
                    onChange={() => setWithVisa("true")}
                  />
                  Evet
                </label>
              </div>
            </VisaCollapsibleCard>
            <CapacityCollapsibleCard
              title={"Kapasite"}
              open={collapseStates.capacity}
              toggleCollapse={() => toggleCollapse("capacity")}
            ></CapacityCollapsibleCard>
            <SeasonCollapsibleCard
              title={"Sezon"}
              open={collapseStates.season}
              toggleCollapse={() => toggleCollapse("season")}
            >
              <div className="time-options pl-2">
                {seasonOptions.map((option) => (
                  <label key={option.value} className="time-option">
                    <input
                      type="radio"
                      name="time"
                      value={option.value}
                      checked={selectedseason === option.value}
                      onChange={() => setSelectedseason(option.value)}
                    />
                    {option.label}
                  </label>
                ))}
              </div>
            </SeasonCollapsibleCard>
            <button
              style={{ width: "100%" }}
              className="clear-filter-tour-button mb-2"
              onClick={resetFilters}
            >
              Filtreleri Temizle
            </button>
            <button
              style={{ width: "100%" }}
              className="filter-tour-button"
              onClick={applyFilters}
            >
              Filtreleri Uygula
            </button>
          </Col>

          <Col md={10} xxl={8} xs={12}>
            {isLoading && <div className="loading-spinner">Loading...</div>}
            {tours.map((tour) => (
              <div key={tour.tourId}>
                <TourCards
                  tours={tours}
                  tourData={tour}
                  selectedTours={selectedTours}
                  categoryName={categoryName}
                  handleCheckboxChange={handleCheckboxPdfChange}
                  isSelected={selectedTours.includes(tour.tourId)}
                />
              </div>
            ))}

            <div className="d-flex justify-content-center">
              <button className="loadMoreButton">Daha Fazla</button>
            </div>
          </Col>
        </Row>
      </div>
      <ToursFilterMobile
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        selectedButtons={selectedButtons}
        handleButtonClick={handleButtonClick}
        getSelectedCurrency={getSelectedCurrency}
        setMinPrice={setMinPrice}
        setMaxPrice={setMaxPrice}
        setNerden={setNerden}
        setNerey={setNereye}
        setSelectedTime={setNereye}
        setWithAccommodation={setWithAccommodation}
        setWithVisa={setWithVisa}
        withVisa={setWithVisa}
        ToggleButton={ToggleButton}
        withAccommodation={withAccommodation}
        minPrice={minPrice}
        maxPrice={maxPrice}
        nerden={nerden}
        nereye={nereye}
        timeOptions={timeOptions}
        selectedTime={selectedTime}
      />
      <ToastContainer />
    </div>
  );
};
export default NewToursPage;
