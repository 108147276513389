import { useState, useEffect } from "react";
import { Row, Col, Form, Button, Modal, ListGroup } from "react-bootstrap";
import TourSection from "area/admin/AdvertisementManegment/Components/Edit/TourSection";
import Accomodation from "area/admin/AdvertisementManegment/Components/Edit/Accomodation";
import FoodOptions from "area/admin/AdvertisementManegment/Components/Edit/FoodOptions";
import Transport from "area/admin/AdvertisementManegment/Components/Edit/Transport";
import Trips from "area/admin/AdvertisementManegment/Components/Edit/Trips";
import Prices from "area/admin/AdvertisementManegment/Components/Edit/Prices";
import NewPricing from "area/admin/AdvertisementManegment/Components/Edit/NewPricing";
import classNames from "classnames";
import DropzoneComponent from "area/admin/CRUD/DropzoneComponent";
import { useMutation } from "react-query";
import { updateTour } from "common/Api/AdvertismentApi";
import CustomeDropZone from "area/admin/AdvertisementManegment/Components/CustomeDropZone";
import { axios } from "common/utils/axios";
import { toast } from "react-toastify";
export default ({
  updateMainPhotograph,
  updateInsidePhotographs,
  updateOutsidePhotographs,
  tourDetails,
  setShowEditTour,
  showEditTour,
  activeSection,
  sectionTitles,
  setActiveSection,
  handleNextSection,
  handlePreviousSection,
}) => {
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [formData, setFormData] = useState({
    tourName: "",
    dealer: "",
    days: "",
    nights: "",
    startingDate: "",
    endingDate: "",
    departureCity: "",
    returnCity: "",
    publicationDate: "",
    publicationEndDate: "",
    capacity: "",
    season: "",
    childAge: "",
    childs: "",
    category: "",
    intimateControl: false,
    withAccommodation: false,
    showOnWebsite: false,
    lock: false,
    visa: false,

    food_options: [{ foodOptionName: "", foodOpationtype: "" }],

    pricing: {
      mainPrice: "",
      pricingType: "",
      description: "",
      installments: [{ Months: "", installmentPrice: "" }],
      discount_prices: [
        { discountPrice: "", issuedOptionPrice: "", addedOptionPrice: "" },
      ],
    },

    trips: [
      {
        tripTitle: "",
        tripDescription: "",
        howManyDays: "",
        timing: "",
        packagePricing: "",
      },
    ],

    transportations: [
      {
        company: "",
        fromLocation: "",
        toLocation: "",
        departureDateTime: "",
        arrivalDateTime: "",
        expeditionNumber: "",
      },
    ],
    accomodations: [
      {
        city: "",
        otel: "",
        startDate: "",
        endDate: "",
        nights: "",
      },
    ],
    photos: [],
  });
  useEffect(() => {
    if (tourDetails && showEditTour && typeof setFormData === "function") {
      setFormData({
        tourId: tourDetails.tourId,
        tourName: tourDetails.tourName,
        category: tourDetails.category,
        dealer: tourDetails.dealer,
        days: tourDetails.days,
        nights: tourDetails.nights,
        startingDate: tourDetails.startingDate,
        endingDate: tourDetails.endingDate,
        departureCity: tourDetails.departureCity,
        returnCity: tourDetails.returnCity,
        publicationDate: tourDetails.publicationDate,
        publicationEndDate: tourDetails.publicationEndDate,
        capacity: tourDetails.capacity,
        season: tourDetails.season,
        childAge: tourDetails.childAge,
        childs: tourDetails.childs,
        intimateControl: tourDetails.intimateControl,
        withAccommodation: tourDetails.withAccommodation,
        showOnWebsite: tourDetails.showOnWebsite,
        lock: tourDetails.lock,
        visa: tourDetails.visa,
        accomodations: tourDetails.accomodations.map((accomodation) => ({
          otel_id: accomodation.otel_id,
          otel_name: accomodation.hotel_name.otel_name,
          city: accomodation.city,
          otel: accomodation.otel,
          nights: accomodation.nights,
          startDate: formatDate(accomodation.startDate),
          endDate: formatDate(accomodation.endDate),
        })),
        food_options: tourDetails.food_options.map((option) => ({
          id: option.id,
          foodOptionName: option.foodOptionName,
          foodOptionType: option.foodOptionType,
        })),

        transportations: tourDetails.transportations.map((transportations) => ({
          transportationId: transportations.transportationId,
          company: transportations.company,
          fromLocation: transportations.fromLocation,
          toLocation: transportations.toLocation,
          departureDateTime: formatDateTime(transportations.departureDateTime),
          arrivalDateTime: formatDateTime(transportations.arrivalDateTime),
          expeditionNumber: transportations.expeditionNumber,
        })),

        trips: tourDetails.trips.map((trips) => ({
          tripId: trips.tripId,
          tripTitle: trips.tripTitle,
          tripDescription: trips.tripDescription,
          howManyDays: trips.howManyDays,
          timing: formatTime(trips.timing),
          packagePricing: trips.packagePricing,
        })),

        pricing: tourDetails.pricing.map((price) => ({
          pricingId: price.pricingId,
          howManyPeople: price.howManyPeople,
          mainPrice: price.mainPrice,
          pricingType: price.pricingType,
          description: price.description,
          discountPrice: price.discountPrice,
          issuedOptionPrice: price.issuedOptionPrice,
          addedOptionPrice: price.addedOptionPrice,
          installments: price.installments.map((installment) => ({
            id: installment.id,
            Months: installment.Months,
            installmentPrice: installment.installmentPrice,
          })),
        })),

        mainPhotograph: tourDetails.mainPhotograph || null,
        insidePhotographs: Array.isArray(tourDetails.insidePhotographs)
          ? tourDetails.insidePhotographs.map((photo) => photo)
          : [],
        outsidePhotographs: Array.isArray(tourDetails.outsidePhotographs)
          ? tourDetails.outsidePhotographs.map((photo) => photo)
          : [],
      });
      setIsDataLoaded(true);
    }
  }, [tourDetails, showEditTour, setFormData]);

  const updateAccomodationData = (accomodationData) => {
    setFormData({ ...formData, accomodations: accomodationData });
  };
  const updateFoodOptionData = (food_optionData) => {
    setFormData({ ...formData, food_options: food_optionData });
  };
  const updateTransportData = (transportData) => {
    setFormData({ ...formData, transportations: transportData });
  };
  const updateTripData = (tripData) => {
    setFormData({ ...formData, trips: tripData });
  };
  const updateTourSectionData = (updatedData) => {
    setFormData({ ...formData, ...updatedData });
  };
  const updatePriceData = (index, updatedPrice) => {
    const newPricing = formData.pricing.map((price, idx) => {
      if (idx === index) {
        return updatedPrice;
      }
      return price;
    });
    setFormData({ ...formData, pricing: newPricing });
  };

  const updateDiscountPriceData = (discountPrices) => {
    setFormData({
      ...formData,
      pricing: {
        ...formData.pricing,
        discount_prices: discountPrices,
      },
    });
  };
  const updateInstallmentsData = (priceIndex, updatedInstallments) => {
    const newPricing = formData.pricing.map((price, idx) => {
      if (idx === priceIndex) {
        return { ...price, installments: updatedInstallments };
      }
      return price;
    });
    setFormData({ ...formData, pricing: newPricing });
  };
  const handleFileAddition = (newFile) => {
    console.log("File added:", newFile);
    setFormData({
      ...formData,
      images: [...formData.images, newFile],
    });
  };
  const handleFileRemoval = (fileToRemove) => {
    if (Array.isArray(formData.images)) {
      setFormData({
        ...formData,
        images: formData.images.filter((file) => file !== fileToRemove),
      });
    } else {
      // Handle the case where formData.images is not an array
      console.error("formData.images is not an array");
    }
  };
  console.log(showEditTour, "showEditTour");
  console.log(tourDetails, "tourDetails");
  console.log(formData, "formData");
  const {
    mutate: updateTourData,
    isLoading,
    isError,
    error,
  } = useMutation(updateTour, {
    onSuccess: () => {
      // Handle successful update
      console.log("Tour updated successfully");
      setShowEditTour(false);
    },
    onError: (error) => {
      // Handle error case
      console.error("Error updating tour:", error);
    },
  });

  const handleSubmit = async () => {
    console.log("handleSubmit called");
    try {
      let allPhotosBase64 = [];

      // Convert mainPhotograph if it's a File object
      if (formData.mainPhotograph instanceof File) {
        const mainPhotoBase64 = await toBase64(formData.mainPhotograph);
        formData.mainPhotograph = mainPhotoBase64; // Update the formData with the Base64 string
      }

      // Similarly convert insidePhotographs and outsidePhotographs if they are File objects
      if (Array.isArray(formData.insidePhotographs)) {
        allPhotosBase64 = await Promise.all(
          formData.insidePhotographs.map((photo) =>
            photo instanceof File ? toBase64(photo) : photo
          )
        );
        formData.insidePhotographs = allPhotosBase64; // Update the formData
      }

      if (Array.isArray(formData.outsidePhotographs)) {
        allPhotosBase64 = await Promise.all(
          formData.outsidePhotographs.map((photo) =>
            photo instanceof File ? toBase64(photo) : photo
          )
        );
        formData.outsidePhotographs = allPhotosBase64; // Update the formData
      }
      if (Array.isArray(formData.accomodations)) {
        formData.accomodations = formData.accomodations.map((acc) => ({
          ...acc,
          otel_id: String(acc.otel_id), // Convert otel_id to a string
        }));
      }
      // Prepare the submission data
      const dataToSubmit = { ...formData };

      // Construct the endpoint URL with the tour ID
      const url = `api/newtour/${dataToSubmit.tourId}`;
      console.log(dataToSubmit, "dataToSubmit befor send it ");
      // Perform the API call using axios
      const response = await axios.put(url, dataToSubmit);

      // Handle the successful responset

      toast.success("Tur başarıyla güncellendi");
      console.log("Tur başarıyla güncellendi", response.data);

      setShowEditTour(false); // Example: Close a modal or reset form upon success

      // Optionally, you could refresh the data on the page or navigate the user away
    } catch (error) {
      toast.error("Tur başarsız");
      toast.error(error.response ? error.response.data : error);
      // Handle errors from the API call or the preparation steps
      console.error(
        "Tur güncelleme hatası.:",
        error.response ? error.response.data : error
      );
      // Example: Show an error message based on the error
    } finally {
      // Example: setIsLoading(false); to reset loading state in your UI
    }
  };

  // Helper function to convert a file to a Base64 string
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };
  const formatDateTime = (dateTimeStr) => {
    const dateTime = new Date(dateTimeStr);
    const date = `${dateTime.getFullYear()}-${String(
      dateTime.getMonth() + 1
    ).padStart(2, "0")}-${String(dateTime.getDate()).padStart(2, "0")}`;
    const time = `${String(dateTime.getHours()).padStart(2, "0")}:${String(
      dateTime.getMinutes()
    ).padStart(2, "0")}`;
    return `${date} ${time}`;
  };
  const formatTime = (timeStr) => {
    // Split time and AM/PM part
    const parts = timeStr.split(/[: ]/); // Splits by colon and space
    if (parts.length >= 3) {
      let hours = parseInt(parts[0], 10);
      const minutes = parseInt(parts[1], 10);
      const ampm = parts[2].toUpperCase();

      if (ampm === "PM" && hours < 12) {
        hours += 12;
      } else if (ampm === "AM" && hours === 12) {
        hours = 0;
      }

      if (!isNaN(hours) && !isNaN(minutes)) {
        return `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}`;
      }
    }
    return null; // or some default value like '00:00'
  };
  console.log("Tour ID being sent:", formData.tourId);
  return (
    <Modal
      show={showEditTour}
      onHide={() => setShowEditTour(false)}
      size="xl"
      centered
    >
      <Modal.Body style={{ maxHeight: "400px", overflowY: "auto" }}>
        <Row className="mb-4 text-center ">
          <Col md={12}>
            <ListGroup className="list-group-horizontal-md">
              {sectionTitles &&
                sectionTitles?.map((title, index) => (
                  <ListGroup.Item
                    key={index}
                    className={classNames({
                      "active-list-group-item": activeSection === index + 1,
                    })}
                    action
                    active={activeSection === index + 1}
                    onClick={() => setActiveSection(index + 1)}
                  >
                    {title}
                  </ListGroup.Item>
                ))}
            </ListGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {activeSection === 1 && (
              <TourSection
                formData={formData}
                handleChange={updateTourSectionData}
              />
            )}
            {activeSection === 2 && (
              <div style={{ height: "350px " }}>
                <Accomodation
                  accomodations={formData?.accomodations}
                  updateAccomodationData={updateAccomodationData}
                />
              </div>
            )}
            {activeSection === 3 && (
              <div style={{ height: "350px " }}>
                <FoodOptions
                  food_options={formData?.food_options}
                  updateFoodOptionData={updateFoodOptionData}
                />
              </div>
            )}

            {activeSection === 4 && (
              <div style={{ height: "350px " }}>
                <Transport
                  transportations={formData?.transportations}
                  updateTransportData={updateTransportData}
                />
              </div>
            )}

            {activeSection === 5 && (
              <div style={{ height: "350px " }}>
                <Trips
                  activeSection={activeSection}
                  trips={formData?.trips}
                  updateTripData={updateTripData}
                />
              </div>
            )}

            {activeSection === 6 && (
              <div style={{ height: "400px " }}>
                <NewPricing
                  formData={formData}
                  setFormData={setFormData}
                  updatePriceData={updatePriceData}
                  updateInstallmentsData={updateInstallmentsData}
                />
              </div>
            )}

            {activeSection === 7 && (
              <div style={{ height: "350px" }}>
                <Form.Group controlId="gallery">
                  <Form.Label>
                    <h4>Galeri</h4>
                    <hr />
                  </Form.Label>
                  <CustomeDropZone
                    formData={formData}
                    updateMainPhotograph={updateMainPhotograph}
                    updateInsidePhotographs={updateInsidePhotographs}
                    updateOutsidePhotographs={updateOutsidePhotographs}
                  />
                </Form.Group>
              </div>
            )}
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        {activeSection < 7 ? (
          <Button variant="success" onClick={handleSubmit}>
            Kaydet
          </Button>
        ) : (
          <></>
        )}
        <Button variant="secondary" onClick={() => setShowEditTour(false)}>
          Close
        </Button>
        {activeSection > 1 && (
          <Button variant="warning" onClick={handlePreviousSection}>
            Previous
          </Button>
        )}
        {activeSection < 7 ? (
          <Button variant="primary" onClick={() => handleNextSection({})}>
            Next
          </Button>
        ) : (
          <Button variant="success" onClick={handleSubmit}>
            Kaydet
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
