import React, { useState } from "react";
import "./Style.css";
import Header from "../../common/components/headers/light";
import Footer from "../../common/components/footers/FiveColumnWithInputForm.js";
import AnimationRevealPage from "../../common/helpers/AnimationRevealPage.js";
import InformationsMainContent from "./components/informations/InformationsMainContent";
import { MainTitle } from "./components/MainTitle";
import { ApprovalMainContent } from "./components/approval/ApprovalMainContent";
import { OnlinePaymentMainContent } from "./components/OnlinePayment/OnlinePaymentMainContent";
import { SideBar } from "./components/SideBar";
import { Wizard } from "./components/Wizard";
export const OnlineRezervasyon = () => {
  //ToDo
  // Adding Submit to go next with data
  // const [informationData, setInformationData] = useState(null);
  // const handleInformationSubmit = (data) => {
  //   setInformationData(data);
  //   setShowApproval(true);
  // };
  /******** Start Wizard ********** */
  const [activeStep, setActiveStep] = useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  /******End Wizard ******** */
  const [displayedComponent, setDisplayedComponent] = useState(
    "InformationsMainContent"
  );

  const handleIleriClick = () => {
    setDisplayedComponent("ApprovalMainContent");
  };

  const handleOnayClick = () => {
    setDisplayedComponent("OnlinePaymentMainContent");
  };
  const handleGeriClick = () => {
    if (displayedComponent === "ApprovalMainContent") {
      setDisplayedComponent("InformationsMainContent");
    } else if (displayedComponent === "OnlinePaymentMainContent") {
      setDisplayedComponent("ApprovalMainContent");
    }
  };

  return (
    <AnimationRevealPage>
      <Header />

      <MainTitle />
      {/* {displayedComponent === "InformationsMainContent" && (
        <InformationsMainContent />
      )} */}
      {displayedComponent === "InformationsMainContent" && (
        <div>
          <InformationsMainContent />
          <div className="row justify-content-end">
            <div className="col-auto">
              <button
                onClick={handleIleriClick}
                type="submit"
                className="btn btn-primary btn-sm f mb-4 ileri"
              >
                İleri
              </button>
            </div>
          </div>
        </div>
      )}
      {displayedComponent === "ApprovalMainContent" && <ApprovalMainContent />}
      {displayedComponent === "OnlinePaymentMainContent" && (
        <div>
          <OnlinePaymentMainContent />
          <div className="row justify-content-between">
            <div className="col-auto">
              <button
                onClick={handleGeriClick}
                type="submit"
                className="btn btn-secondary btn-sm f mb-4 ileri"
              >
                Geri
              </button>
            </div>
          </div>
        </div>
      )}
      {displayedComponent === "ApprovalMainContent" && (
        <div>
          <div className="row justify-content-between">
            <div className="col-auto">
              <button
                onClick={handleGeriClick}
                type="submit"
                className="btn btn-secondary btn-sm f mb-4 ileri"
              >
                Geri
              </button>
            </div>
            <div className="col-auto">
              <button
                onClick={handleOnayClick}
                type="submit"
                className="btn btn-primary btn-sm f mb-4 ileri"
              >
                İleri
              </button>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </AnimationRevealPage>
  );
};
export default OnlineRezervasyon;
