import React from "react";

export const RezervasyonNot = () => {
  return (
    <div>
      <div class="online-section-title p3">
        <span class="glyphicon glyphicon-info-sign"></span> Rezervasyon Notu
      </div>
      <div class="pull-right price-alan p2">
        <strong>Toplam Ücret: </strong>{" "}
        <span data-price="4000.00" data-currency="$">
          4.000,00 $
        </span>
      </div>
      <div class="rez-buttonlar">
        {/* <a
          href="https://www.gezintitatil.com/tour-reservation/modify/R1678783171641032c37085d"
          class="btn btn-sm btn-primary float-left"
        >
          <span class="glyphicon glyphicon-chevron-left"></span> Geri
        </a> */}
        {/* <a
          href="https://www.gezintitatil.com/tour-reservation/payment/R1678783171641032c37085d"
          class="btn btn-sm btn-primary float-right"
        >
          Onaylıyorum <span class="glyphicon glyphicon-chevron-right"></span>
        </a> */}
        <div class="clearfix"></div>
      </div>
    </div>
  );
};
