import React, { useState } from "react";
import { css } from "styled-components/macro"; //eslint-disable-line
import tw from "twin.macro";
import styled from "styled-components";
import { motion } from "framer-motion";

import { ReactComponent as SvgDecoratorBlob1 } from "../../../assets/images/svg-decorator-blob-5.svg";
import { ReactComponent as StarIcon } from "../../../assets/images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../../assets/images/svg-decorator-blob-7.svg";
import Video from "../video/Video";

import { Container, ContentWithPaddingXl } from "../misc/Layouts.js";
import { SectionHeading } from "../misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "../misc/Buttons.js";
import Gmap from "../maps/gmap";
import RoomCards from "../try/RoomCards";
import FourInput from "./FourInput";
import OtelFeatures from "../cards/OtelFeatures";
import Descriptions from "../descriptions/Descriptions";
import SliderArea from "../slider/slider";

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = styled.div`
  @media (max-width: 600px) {
    flex-wrap: nowrap;
  }
  ${tw`
  flex flex-wrap bg-gray-100 px-2 py-2 rounded leading-none mt-12 xl:mt-0
  
`}
`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${(props) => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;
const rooms = [
  {
    id: 1,
    name: "Oda 1",
    price: 50,
    image:
      "https://media.istockphoto.com/id/1322277517/photo/wild-grass-in-the-mountains-at-sunset.jpg?s=612x612&w=0&k=20&c=6mItwwFFGqKNKEAzv0mv6TaxhLN3zSE43bWmFN--J5w=",
  },
  {
    id: 2,
    name: "Oda 2",
    price: 60,
    image:
      "https://media.istockphoto.com/id/1322277517/photo/wild-grass-in-the-mountains-at-sunset.jpg?s=612x612&w=0&k=20&c=6mItwwFFGqKNKEAzv0mv6TaxhLN3zSE43bWmFN--J5w=",
  },
  {
    id: 3,
    name: "Oda 3",
    price: 70,
    image:
      "https://media.istockphoto.com/id/1322277517/photo/wild-grass-in-the-mountains-at-sunset.jpg?s=612x612&w=0&k=20&c=6mItwwFFGqKNKEAzv0mv6TaxhLN3zSE43bWmFN--J5w=",
  },
  {
    id: 4,
    name: "Oda 1",
    price: 50,
    image:
      "https://media.istockphoto.com/id/1322277517/photo/wild-grass-in-the-mountains-at-sunset.jpg?s=612x612&w=0&k=20&c=6mItwwFFGqKNKEAzv0mv6TaxhLN3zSE43bWmFN--J5w=",
  },
  {
    id: 5,
    name: "Oda 2",
    price: 60,
    image:
      "https://media.istockphoto.com/id/1322277517/photo/wild-grass-in-the-mountains-at-sunset.jpg?s=612x612&w=0&k=20&c=6mItwwFFGqKNKEAzv0mv6TaxhLN3zSE43bWmFN--J5w=",
  },
  {
    id: 6,
    name: "Oda 3",
    price: 70,
    image:
      "https://media.istockphoto.com/id/1322277517/photo/wild-grass-in-the-mountains-at-sunset.jpg?s=612x612&w=0&k=20&c=6mItwwFFGqKNKEAzv0mv6TaxhLN3zSE43bWmFN--J5w=",
  },
];
export default ({
  heading = "Checkout the Menu",
  otel = null,
  tabs = {
    Informatinos: "",
    Main: "",
    Soup: "",
    Desserts: "",
  },
}) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const tabsKeys = Object.keys(tabs);

  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  return (
    <Container>
      {console.log(otel, "555")}
      <ContentWithPaddingXl>
        <HeaderRow>
          <Header>{heading}</Header>
          <TabsControl>
            {Object.keys(tabs).map((tabName, index) => (
              <TabControl
                key={index}
                active={activeTab === tabName}
                onClick={() => setActiveTab(tabName)}
              >
                {tabName}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>

        {tabsKeys?.map((tabKey, index) => {
          if (tabKey === activeTab) {
            console.log("here", tabKey);
            if (tabKey === "Informatinos") {
              return (
                <div key={index}>
                  <div className="grid grid-cols-3 gap-3">
                    <div className="col-span-2 p-3">
                      <div className="bg-gray-100 h-full w-full">
                        <Descriptions desc={otel?.otel_description} />
                      </div>
                    </div>
                    <div className=" grid grid-rows-3">
                      <div className="grid p-3">
                        <OtelFeatures otel={otel} />
                      </div>
                      <div className="grid p-3">
                        <FourInput otel={otel} />
                      </div>
                      <div className="p-3 h-full w-full">
                        <div className="h-full w-full bg-gray-100 p-3">
                          <Gmap
                            PositionX={otel?.x_axsis}
                            PositionY={otel?.y_axsis}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else if (tabKey === "Soup") {
              return <RoomCards rooms={otel?.rooms} />;
            } else if (tabKey === "Main") {
              return <Video video={otel?.video_link} />;
            } else if (tabKey === "Desserts") {
              return <SliderArea sliderArea={otel?.sliders} />;
            }
          }
        })}
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

/* This function is only there for demo purposes. It populates placeholder cards */
// const getRandomCards = () => {
// 	const cards = [
// 		{
// 			imageSrc:
// 				"https://images.unsplash.com/photo-1512621776951-a57141f2eefd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
// 			title: "Chicken Chilled",
// 			content: "Chicken Main Course",
// 			price: "$5.99",
// 			rating: "5.0",
// 			reviews: "87",
// 			url: "#",
// 		},
// 		{
// 			imageSrc:
// 				"https://images.unsplash.com/photo-1582254465498-6bc70419b607?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
// 			title: "Samsa Beef",
// 			content: "Fried Mexican Beef",
// 			price: "$3.99",
// 			rating: "4.5",
// 			reviews: "34",
// 			url: "#",
// 		},
// 		{
// 			imageSrc:
// 				"https://images.unsplash.com/photo-1565310022184-f23a884f29da?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
// 			title: "Carnet Nachos",
// 			content: "Chilli Crispy Nachos",
// 			price: "$3.99",
// 			rating: "3.9",
// 			reviews: "26",
// 			url: "#",
// 		},
// 		{
// 			imageSrc:
// 				"https://images.unsplash.com/photo-1534422298391-e4f8c172dddb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
// 			title: "Guacamole Mex",
// 			content: "Mexican Chilli",
// 			price: "$3.99",
// 			rating: "4.2",
// 			reviews: "95",
// 			url: "#",
// 		},
// 		{
// 			imageSrc:
// 				"https://images.unsplash.com/photo-1550461716-dbf266b2a8a7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
// 			title: "Chillie Cake",
// 			content: "Deepfried Chicken",
// 			price: "$2.99",
// 			rating: "5.0",
// 			reviews: "61",
// 			url: "#",
// 		},
// 		{
// 			imageSrc:
// 				"https://images.unsplash.com/photo-1476224203421-9ac39bcb3327??ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
// 			title: "Nelli",
// 			content: "Hamburger & Fries",
// 			price: "$7.99",
// 			rating: "4.9",
// 			reviews: "89",
// 			url: "#",
// 		},
// 		{
// 			imageSrc:
// 				"https://images.unsplash.com/photo-1455619452474-d2be8b1e70cd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
// 			title: "Jalapeno Poppers",
// 			content: "Crispy Soyabeans",
// 			price: "$8.99",
// 			rating: "4.6",
// 			reviews: "12",
// 			url: "#",
// 		},
// 		{
// 			imageSrc:
// 				"https://images.unsplash.com/photo-1473093226795-af9932fe5856?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
// 			title: "Cajun Chicken",
// 			content: "Roasted Chicken & Egg",
// 			price: "$7.99",
// 			rating: "4.2",
// 			reviews: "19",
// 			url: "#",
// 		},
// 	];

// 	// Shuffle array
// 	return cards.sort(() => Math.random() - 0.5);
// };
