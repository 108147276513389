import { useRef, useState, useEffect, useContext } from "react";
import { Col, Row, Card, ListGroup, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { fetchTourBySlug } from "common/Api/AdvertismentApi";
import GlobalTourBG from "assets/images/BG/GlobalTourBG4.png";
import Slider from "react-slick";
import classNames from "classnames";
import TourComments from "pages/NewTour/Components/TourComments";
import Accomodation from "pages/NewTour/Components/Accomodation";
import Trips from "pages/NewTour/Components/Trips";
import Transportaion from "pages/NewTour/Components/Transportaion";
import Food from "pages/NewTour/Components/Food";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faSun,
  faMoon,
  faLocationDot,
  faUtensils,
  faSquareH,
  faCalendarDays,
  faCircleCheck,
  faCircleXmark,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Age } from "assets/icons/Age.svg";
import { ReactComponent as Group } from "assets/icons/Group.svg";

import { HeaderHeightContext } from "layouts/GlobalLayout";
import "assets/styles/NewTourDetails.css";

const sectionTitles = ["Konaklama", "Yemek", "Ulaşım", "Gezi"];
const cardData = [
  {
    title: "Hac and Umra Program",
    content: "This is card 1",
    imageUrl:
      "https://rjeem.com/wp-content/uploads/2018/05/D8A7D984D8ADD8B1D985-1.jpg",
  },
  {
    title: "Kandil is for every one",
    content: "This is card 2",
    imageUrl:
      "https://wallpapers.com/images/hd/kaaba-pilgrimage-rose-filter-n6mdiojcy40lha4q.jpg",
  },
  {
    title: "20 Days of Umra jurny",
    content: "This is card 2",
    imageUrl: "https://i.ytimg.com/vi/Cs311ETA0kU/maxresdefault.jpg",
  },
  {
    title: "Hac road for everyone ",
    content: "This is card 2",
    imageUrl: "https://images.newsru.co.il/l/81/87/818738.jpg",
  },

  // ... add more cards as needed
];
export const NewTourDetails = () => {
  const { tourName } = useParams();
  const [filters, setFilters] = useState({ page: 1, perPage: 5 });
  const [activeSection, setActiveSection] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [adultCount, setAdultCount] = useState(1);
  const [kidCount, setKidCount] = useState(0);
  const [formData, setFormData] = useState({});
  const [priceForGroup, setPriceForGroup] = useState("Not available");
  const headerHeightContext = useContext(HeaderHeightContext);
  const {
    setHeaderHeight,
    setDisplay,
    setHeaderTitlePaddingTop,
    setHeaderTitlePaddingBottom,
    setHeaderPaddingTop,
  } = headerHeightContext;
  const incrementAdults = () => {
    setAdultCount((prevCount) => prevCount + 1);
  };
  const findPricingForGroup = (adultCount, kidCount, pricingArray) => {
    // Sort pricing by howManyPeople in ascending order to ensure correct order
    const sortedPricing = pricingArray.sort(
      (a, b) => a.howManyPeople - b.howManyPeople
    );

    // Find the first pricing where the total count is less than or equal to howManyPeople
    const totalPeople = adultCount + kidCount;
    const appropriatePricing = sortedPricing.find(
      (pricing) => totalPeople <= pricing.howManyPeople
    );

    return appropriatePricing ? appropriatePricing.mainPrice : "Not available";
  };

  const {
    data: tourDetails,
    isLoading,
    isError,
    error,
  } = useQuery(["tourDetails", tourName], () => fetchTourBySlug(tourName), {});

  const pricingDetails = tourDetails ? tourDetails.pricing : [];
  useEffect(() => {
    // Recompute price whenever adultCount or kidCount changes
    setPriceForGroup(findPricingForGroup(adultCount, kidCount, pricingDetails));
  }, [adultCount, kidCount, pricingDetails]);
  useEffect(() => {
    setDisplay("none");
    setHeaderPaddingTop("0");
    setHeaderHeight("0px"); // Set the header height to 200px
    setHeaderTitlePaddingTop("0px");
    setHeaderTitlePaddingBottom("0px");
    return () => {
      setHeaderHeight(null);
      setDisplay(null);
      setHeaderPaddingTop(null);
      setHeaderTitlePaddingBottom(null);
      setHeaderTitlePaddingTop(null); // Reset the header height when component unmounts
    };
  }, [setHeaderHeight]);
  const decrementAdults = () => {
    if (adultCount > 1) {
      setAdultCount((prevCount) => prevCount - 1);
    }
  };

  const incrementKids = () => {
    setKidCount((prevCount) => prevCount + 1);
  };
  const decrementKids = () => {
    if (kidCount > 0) {
      setKidCount((prevCount) => prevCount - 1);
    }
  };
  const sliderRef = useRef(null);
  const images = [
    "https://grozny-inform.ru/LoadedImages/2023/01/10/pvapvapva.jpeg",
    "https://www.mosoah.com/wp-content/uploads/2022/03/%D8%A3%D9%81%D8%B6%D9%84-%D8%AD%D9%85%D9%84%D8%A7%D8%AA-%D8%A7%D9%84%D8%AD%D8%AC.jpg",
    "https://i.ytimg.com/vi/jzV33AzkqyE/maxresdefault.jpg",
    // ... other images
  ];
  const goToSlide = (index) => {
    if (sliderRef && sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };
  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 2500,
    speed: 500,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const settings2 = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 2500,
    speed: 500,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow2 />,
    prevArrow: <SamplePrevArrow2 />,
    responsive: [
      {
        breakpoint: 1024, // Screen width up to 1024px
        settings: {
          slidesToShow: 3, // Show 3 slides for screens up to 1024px wide
        },
      },
      {
        breakpoint: 600, // Screen width up to 600px
        settings: {
          slidesToShow: 2, // Show 2 slides for screens up to 600px wide
        },
      },
      {
        breakpoint: 480, // Screen width up to 480px
        settings: {
          slidesToShow: 1, // Show 1 slide for screens up to 480px wide
        },
      },
    ],
  };
  console.log(tourDetails, "tourDetails");
  function SampleNextArrow2(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          right: "50px",
          top: "130px",
          zIndex: 2,
        }}
        onClick={onClick}
      >
        {/* Adjust the icon style here */}
      </div>
    );
  }
  function SamplePrevArrow2(props) {
    const { className, style, onClick } = props;

    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
      setFilters({ ...filters, page: pageNumber });
    };
    const handleNextSection = (sectionData) => {
      setFormData((prevFormData) => ({ ...prevFormData, ...sectionData }));
      setActiveSection((prev) => Math.min(prev + 1, 7));
    };

    const handlePreviousSection = () => {
      setActiveSection((prev) => Math.max(prev - 1, 1));
    };
    if (isLoading) {
      return <div>Loading...</div>;
    }

    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          left: "5px",
          top: "130px",
          zIndex: 1,
        }}
        onClick={onClick}
      ></div>
    );
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          right: "45px",
          top: window.innerWidth <= 768 ? "110px" : "210px",
        }}
        onClick={onClick}
      >
        <i style={{ fontSize: "30px" }}>Next</i>{" "}
        {/* Adjust the icon style here */}
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;

    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
      setFilters({ ...filters, page: pageNumber });
    };
    const handleNextSection = (sectionData) => {
      setFormData((prevFormData) => ({ ...prevFormData, ...sectionData }));
      setActiveSection((prev) => Math.min(prev + 1, 7));
    };

    const handlePreviousSection = () => {
      setActiveSection((prev) => Math.max(prev - 1, 1));
    };
    if (isLoading) {
      return <div>Loading...</div>;
    }

    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          left: "25px",
          top: window.innerWidth <= 768 ? "110px" : "210px",
          zIndex: 1,
        }}
        onClick={onClick}
      />
    );
  }

  const findPricingForAdults = (adultCount, pricingArray) => {
    // Sort pricing by howManyPeople in ascending order to ensure correct order
    const sortedPricing = pricingArray.sort(
      (a, b) => a.howManyPeople - b.howManyPeople
    );

    // Find the first pricing where adultCount is less than or equal to howManyPeople
    const appropriatePricing = sortedPricing.find(
      (pricing) => adultCount <= pricing.howManyPeople
    );

    return appropriatePricing ? appropriatePricing.mainPrice : "Not available";
  };

  const priceForAdults = findPricingForAdults(adultCount, pricingDetails);
  return (
    <div
      style={{
        backgroundImage: `url(${GlobalTourBG})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="pl-4 pr-4 pb-4" style={{ paddingTop: "80px" }}>
        {/*First Row Section  */}
        <Row className="justify-content-xxl-center">
          <Col xs={12} md={12} xl={9} xxl={8}>
            <Card style={{ borderRadius: "0" }} className="blurred-box-shadow ">
              <Card.Header
                className="monsterratFont w600 grayC"
                style={{
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  fontSize: "20px",
                }}
              >
                <div className="d-flex justify-content-between">
                  <span>{tourDetails && tourDetails?.tourName}</span>
                  <span>{tourDetails && tourDetails?.category}</span>
                </div>
              </Card.Header>
              {/**Card Body */}
              <Card.Body className="cardBodyStyleTourDetails">
                <Slider
                  ref={sliderRef}
                  {...settings}
                  className="sliderContainer"
                >
                  {/**Tour Slider Images */}
                  {tourDetails &&
                  tourDetails.photos &&
                  tourDetails.photos.length > 0
                    ? tourDetails.photos.map((photo, index) => (
                        <div key={index} className="image-container">
                          <img
                            className="responsive-slider-img"
                            style={{ borderRadius: "0" }}
                            src={photo.file_path}
                            alt={`Tour Image ${index}`}
                          />
                        </div>
                      ))
                    : images.map((image, index) => (
                        <div key={index} className="image-container">
                          <img
                            style={{ borderRadius: "0" }}
                            src={image}
                            alt={`Slide ${index}`}
                          />
                        </div>
                      ))}
                </Slider>
              </Card.Body>
              {/**Card Footer */}
              <Card.Footer>
                <div className="thumbnail-container">
                  {tourDetails &&
                  tourDetails.photos &&
                  tourDetails.photos.length > 0
                    ? tourDetails.photos.map((photo, index) => (
                        <img
                          key={index}
                          className="thumbnail"
                          src={photo.file_path}
                          alt={`Thumbnail ${index}`}
                          onClick={() => goToSlide(index)}
                        />
                      ))
                    : images.map((image, index) => (
                        <img
                          key={index}
                          className="thumbnail"
                          src={image}
                          alt={`Thumbnail ${index}`}
                          onClick={() => goToSlide(index)}
                        />
                      ))}
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col xs={12} md={12} xl={3} xxl={2} className="d-none d-md-block">
            <Card
              style={{ borderRadius: "0", maxHeight: "545px" }}
              className="monsterratFont blurred-box-shadow "
            >
              <Card.Header
                style={{ borderBottom: "none", backgroundColor: "white" }}
              >
                <Col className="grayC w600" xl={12}>
                  {tourDetails && tourDetails?.tourName}
                </Col>

                <Col style={{ fontSize: "13px" }} xl={12} className="mb-2">
                  <FontAwesomeIcon
                    style={{ fontSize: "12px", color: "#e8c50a" }}
                    icon={faStar}
                  />{" "}
                  4.3 (251)
                </Col>
                <hr />
                <Row>
                  <Col
                    xl={6}
                    className="d-flex align-items-center"
                    style={{ fontSize: "14px" }}
                  >
                    <Age style={{ width: "17px" }} className="me-2" /> Yaş :{" "}
                    {tourDetails && tourDetails?.childAge} 80
                  </Col>
                  <Col
                    xl={6}
                    className="d-flex align-items-center"
                    style={{ fontSize: "14px" }}
                  >
                    <Group style={{ width: "17px" }} className="me-2" /> Grup :
                    {tourDetails && tourDetails?.capacity}
                  </Col>
                  <Col xl={6} style={{ fontSize: "14px" }}>
                    <FontAwesomeIcon icon={faSun} /> Günler :{" "}
                    {tourDetails && tourDetails?.days}
                  </Col>
                  <Col xl={6} style={{ fontSize: "14px" }}>
                    <FontAwesomeIcon icon={faMoon} /> Geceler :{" "}
                    {tourDetails && tourDetails?.nights}
                  </Col>

                  <Col
                    xl={6}
                    style={{ fontSize: "14px" }}
                    className="mb-2 mt-2"
                  >
                    <FontAwesomeIcon icon={faUtensils} /> Yemek{" "}
                    <FontAwesomeIcon
                      style={{ color: "green" }}
                      icon={faCircleCheck}
                    />
                  </Col>

                  <Col xl={6} style={{ fontSize: "14px" }} className="mb-2 ">
                    Intimate :{" "}
                    {tourDetails &&
                      (tourDetails.intimateControl ? (
                        <FontAwesomeIcon
                          style={{ color: "green" }}
                          icon={faCircleCheck}
                        />
                      ) : (
                        <FontAwesomeIcon
                          style={{ color: "#cc1d1d" }}
                          icon={faCircleXmark}
                        />
                      ))}
                  </Col>
                  <Col xl={6} style={{ fontSize: "14px" }} className="mb-2 ">
                    <FontAwesomeIcon icon={faSquareH} /> Otel : 2
                  </Col>
                  <Col className="mb-1" xl={12} style={{ fontSize: "13px" }}>
                    <FontAwesomeIcon icon={faLocationDot} />{" "}
                    <span className="w600">
                      {tourDetails && tourDetails?.departureCity}
                      {"  "}
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                    {"  "}
                    <span className="w600">
                      {" "}
                      {tourDetails && tourDetails?.returnCity}
                    </span>
                  </Col>
                  <Col xl={12} style={{ fontSize: "13px" }} className="mb-2">
                    <FontAwesomeIcon icon={faLocationDot} className="mr-1" />
                    <span className="w600">Make</span>
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className="mr-1 ml-1"
                    />{" "}
                    <span className="w600">Istanbul</span>
                  </Col>
                  <Col
                    className="mb-1"
                    xl={6}
                    xxl={6}
                    style={{ fontSize: "12px" }}
                  >
                    <FontAwesomeIcon icon={faCalendarDays} /> Başlangıç :
                    <br /> {tourDetails && tourDetails?.startingDate}
                  </Col>
                  <Col
                    className="mb-1"
                    xl={6}
                    xxl={6}
                    style={{ fontSize: "12px" }}
                  >
                    <FontAwesomeIcon icon={faCalendarDays} /> Bitiş :
                    <br />
                    {tourDetails && tourDetails?.endingDate}
                  </Col>
                </Row>
              </Card.Header>

              <Card.Body>
                <Col
                  className="mb-3 counter-container blur-background2"
                  xl={12}
                >
                  <span>Yetişkin</span>
                  <div className="button-input-group">
                    <button onClick={decrementAdults}>-</button>
                    <input type="text" value={adultCount} readOnly />
                    <button onClick={incrementAdults}>+</button>
                  </div>
                </Col>
                <Col
                  className="mb-3 counter-container blur-background2"
                  xl={12}
                >
                  <span>Çocuk</span>
                  <div className="button-input-group">
                    <button onClick={decrementKids}>-</button>
                    <input type="text" value={kidCount} readOnly />
                    <button onClick={incrementKids}>+</button>
                  </div>
                </Col>

                <Col
                  style={{ fontSize: "27px", fontWeight: "bold" }}
                  className="mb-3 barlow d-flex justify-content-center"
                  xl={12}
                >
                  {priceForGroup}$
                </Col>
                <Col className="mb-3 d-flex justify-content-center" xl={12}>
                  <button className="satinAlButton">Satin AL</button>
                </Col>
                {/**
                <Col className="d-flex justify-content-center" xl={12}>
                <button className="reservasionButton">Reservasion</button>
                </Col>
              */}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Second Row Section Tour Data  */}
        <Row className="justify-content-center mt-3 ">
          <Col
            md={12}
            xl={12}
            xxl={10}
            className="justify-content-xxl-center  mb-3"
          >
            <ul>
              {/* Header Of the Collabes  */}
              <li className="mb-2">
                <ListGroup className="blurred-box-shadow list-group-horizontal">
                  {sectionTitles &&
                    sectionTitles?.map((title, index) => (
                      <ListGroup.Item
                        key={index}
                        className={`custom-list-group-item ${classNames({
                          "active-list-group-item": activeSection === index + 1,
                        })}`}
                        action
                        active={activeSection === index + 1}
                        onClick={() => setActiveSection(index + 1)}
                      >
                        {title}
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              </li>
              {/* Body OF the Collabes */}
              <li
                className="blurred-box-shadow p-3 ListGroup-body"
                style={{ backgroundColor: "white" }}
              >
                {activeSection === 1 && (
                  <div className="collapse-body2" style={{ height: "40rem" }}>
                    <Accomodation tourDetails={tourDetails} />
                  </div>
                )}
                {activeSection === 2 && (
                  <div style={{ height: "370px " }}>
                    <Food tourDetails={tourDetails} />
                  </div>
                )}

                {activeSection === 3 && (
                  <div
                    style={{
                      height: "370px ",
                      overflow: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <Transportaion tourDetails={tourDetails} />
                  </div>
                )}

                {activeSection === 4 && (
                  <div
                    style={{
                      padding: "2px",
                      height: "370px ",
                      overflow: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <Trips tourDetails={tourDetails} />
                  </div>
                )}
              </li>
            </ul>
          </Col>

          {/*Tour Slider */}
          <Col xl={12} md={12} xs={12} xxl={10} className="mt-1">
            <Card className="blurred-box-shadow">
              <Card.Body>
                <Col xl={12}>
                  <h2 style={{ fontSize: "30px" }}>
                    Diğer {tourDetails && tourDetails?.category} Programları
                  </h2>
                </Col>
                <Col xl={12}>
                  <Slider {...settings2}>
                    {cardData.map((card, index) => (
                      <div key={index}>
                        <Card className=" mr-2 blurred-box-shadow">
                          <Card.Body className="cardBodyStyleTourDetails">
                            <Card.Img
                              src={card.imageUrl}
                              style={{ height: "227px " }}
                            />
                            <Card.Title
                              className="grayC w600 monsterratFont d-flex justify-content-between"
                              style={{ padding: "10px" }}
                            >
                              <ul>
                                <li>
                                  <span>{card.title}</span>
                                </li>
                                <li style={{ fontSize: "12px" }}>
                                  3500 TL menimum . 3 Kisi
                                </li>
                              </ul>
                              <span style={{ fontSize: "15px" }}>
                                <FontAwesomeIcon
                                  style={{
                                    color: "rgb(232, 197, 10)",
                                    marginRight: "5px",
                                  }}
                                  icon={faStar}
                                />
                                4.3(1500+)
                              </span>
                            </Card.Title>
                          </Card.Body>
                        </Card>
                      </div>
                    ))}
                  </Slider>
                </Col>
              </Card.Body>
            </Card>
          </Col>

          {/*Tour Reveiews */}
          <Col xl={12} xs={12} md={12} xxl={10} className="mt-4">
            <Card className="blurred-box-shadow">
              <Card.Body>
                <Card.Title>İncelemeler</Card.Title>
                <div>
                  <span style={{ fontSize: "30px" }}>
                    4.3 <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                  </span>
                  <span></span>
                </div>
                <hr />
                <TourComments />
                <hr style={{ margin: "0.4rem" }} />
                <span className="d-flex justify-content-center text-center">
                  <button className="d-flex justify-content-center text-center monsterratFont grayC">
                    Daha Fazla Goster
                  </button>
                </span>
                <hr style={{ margin: "0.4rem" }} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default NewTourDetails;
