import React from "react";

export const MainTitle = () => {
  return (
    <div class="container">
      <div class="row">
        <div class="container">
          <div class="search-content-bottom search-listing-bottom">
            <h1 class="kutsal">ONLINE REZERVASYON</h1>
          </div>
        </div>
      </div>
    </div>
  );
};
