import { useState, useEffect } from "react";
import Badge from "@mui/material/Badge";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import styles from "assets/styles/MobileSidebar.module.css"; // Import the CSS Module
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUsers,
  faCog,
  faQuestionCircle,
  faHeartbeat,
  faCalendar,
  faComments,
  faInfoCircle,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import LogoutButton from "../LogoutButton";
import { HamburgerButton } from "area/admin/baseComponents/HumbergerButton";

export const MobileSidebar = ({ showMenu, setShowMenu, toggleSidebar }) => {
  const [activeConversationsCount, setActiveConversationsCount] = useState(0);

  useEffect(() => {
    fetch("https://backend.suarelab.com/api/activeConversationsCount")
      .then((response) => response.json())
      .then((data) => {
        setActiveConversationsCount(data.active_conversations_count);
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  const [expandedItem, setExpandedItem] = useState(null); // Track which item is expanded

  const toggleExpand = (index) => {
    if (expandedItem === index) {
      setExpandedItem(null); // Collapse if it's already expanded
    } else {
      setExpandedItem(index); // Expand the clicked item
    }
  };

  const sidebarItems = [
    {
      icon: <FontAwesomeIcon icon={faHome} size="lg" />,
      link: "/admin/dashboard",
      label: "Dashboard",
      menuItems: [
        {
          icon: <FontAwesomeIcon icon={faHome} size="lg" />,
          label: "Dashboard",
          link: "/admin/dashboard",
        },
      ],
    },
    {
      icon: <FontAwesomeIcon icon={faCog} size="lg" />,
      link: "/admin/serviceManagement/sitsettings",
      label: "Service Management",
      menuItems: [
        {
          icon: <FontAwesomeIcon icon={faCog} size="lg" />,
          label: "Site Ayarlari",
          link: "/admin/serviceManagement/sitsettings",
        },
        {
          icon: <FontAwesomeIcon icon={faComments} size="lg" />,
          link: "/admin/post/create",
          label: "Postlar",
        },
        {
          icon: <FontAwesomeIcon icon={faFileAlt} size="lg" />,
          link: "/admin/comments/create",
          label: "Comments",
        },
        {
          icon: <FontAwesomeIcon icon={faInfoCircle} size="lg" />,
          link: "/admin/about-us/create",
          label: "About Us",
        },
        {
          icon: <FontAwesomeIcon icon={faQuestionCircle} size="lg" />,
          link: "/admin/Qa/create",
          label: "SSS",
        },
      ],
    },
    {
      icon: <FontAwesomeIcon icon={faUsers} size="lg" />,
      link: "/admin/customerManagement/customers",
      label: "Customer Management",
      menuItems: [
        {
          icon: <FontAwesomeIcon icon={faUsers} size="lg" />,
          label: "Customer Managementi",
          link: "/admin/customerManagement/customers",
        },
        {
          link: "/admin/customerManagement/CustomersMeetings",
          label: "Müşteri Toplantılar",
        },
        {
          link: "/admin/customerManagement/CustomersOffers",
          label: "Müşteri Teklifleri",
        },
        {
          link: "/admin/customerManagement/CustomersSales",
          label: "Müşteri Satış",
        },
      ],
    },
    {
      icon: <FontAwesomeIcon icon={faHeartbeat} size="lg" />,
      link: "/admin/advertismentManagement/advertisment",
      label: "Advertisment Management",
      menuItems: [
        {
          icon: <FontAwesomeIcon icon={faHeartbeat} size="lg" />,
          label: "Advertisment Management",
          link: "/admin/advertismentManagement/advertisment",
        },
      ],
    },
    {
      icon: <FontAwesomeIcon icon={faCalendar} size="lg" />,
      link: "/admin/ReservationManagement/list",
      label: "Reservation Management",
      menuItems: [
        {
          icon: <FontAwesomeIcon icon={faCalendar} size="lg" />,
          link: "/admin/ReservationManagement/list",
          label: "Reservation Management",
        },
      ],
    },
    {
      icon: (
        <Badge badgeContent={activeConversationsCount} color="error">
          <FontAwesomeIcon icon={faQuestionCircle} size="lg" />
        </Badge>
      ),
      link: "/admin/helpManagment/helpsystem",
      label: "Help",
      menuItems: [
        {
          icon: (
            <Badge badgeContent={activeConversationsCount} color="error">
              <FontAwesomeIcon icon={faQuestionCircle} size="lg" />
            </Badge>
          ),
          link: "/admin/helpManagment/helpsystem",
          label: "Help",
        },
      ],
    },
  ];

  const slideIn = {
    hidden: {
      opacity: 0,
      height: 0,
    },
    visible: {
      opacity: 1,
      height: "auto",
      transition: {
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      height: 0,
      transition: {
        duration: 0.3,
      },
    },
  };

  return (
    <div style={{ backgroundColor: "#1C1E1E", height: "100vh" }}>
      <HamburgerButton
        toggleSidebar={toggleSidebar}
        isOpen={showMenu}
        toggle={() => setShowMenu(!showMenu)}
      />
      <div className="mb-4">
        {sidebarItems.length > 0 &&
          sidebarItems.map((item, index) => (
            <div key={index}>
              <div onClick={() => toggleExpand(index)} className={styles.link}>
                {item.icon} {item.label}
              </div>
              <AnimatePresence>
                {expandedItem === index && (
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={slideIn}
                    className={styles.subMenu}
                  >
                    {Array.isArray(item.menuItems) &&
                      item.menuItems.map((subItem, subIndex) => (
                        <Link
                          to={subItem.link}
                          key={subIndex}
                          className={styles.subLink}
                        >
                          {subItem.icon ? subItem.icon : null} {subItem.label}
                        </Link>
                      ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))}
      </div>
      <LogoutButton />
    </div>
  );
};

export default MobileSidebar;
