import React, { useState, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { getTour, deleteTour, getTourImages } from "common/Api/AdvertismentApi";
import { Table, Button, Pagination, Modal, Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import BooleanIcon from "./Components/BooleanIcon";
import LineAdvance from "../CRUD/LineAdvance";
import ShowTourDataModal from "area/admin/AdvertisementManegment/Components/ShowTourDataModal";
import ShowConformDeleteModal from "area/admin/AdvertisementManegment/Components/ConformDeleteTourModal";
import ShowMobileTourData from "area/admin/AdvertisementManegment/Components/ShowMobileTourData";
import EditTourModal from "area/admin/AdvertisementManegment/Components/Edit/EditTourModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faEdit,
  faTrashAlt,
  faEye,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
export const AdvertismentList = ({
  updateMainPhotograph,
  updateInsidePhotographs,
  updateOutsidePhotographs,
  data,
  isLoading,
  isError,
  setFormData,
  setCurrentPage,
  currentPage,
  setActiveSection,
  activeSection,
  handleNextSection,
  handlePreviousSection,
  sectionTitles,
  handlePageChange,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showEditTour, setShowEditTour] = useState(false);
  const [showImagesTour, setImagesTour] = useState(false);
  const [tourToDelete, setTourToDelete] = useState(null);
  const [selectedTour, setSelectedTour] = useState(null);
  const [selectedTourId, setSelectedTourId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const queryClient = useQueryClient();
  const itemsPerPageOptions = [5, 10, 15, 20];
  const {
    data: tourImages,
    isLoadingTourImages,
    refetch: refetchTourImages,
  } = useQuery(
    ["tourImages", selectedTourId],
    () => getTourImages(selectedTourId),
    { enabled: false }
  );

  ///
  // Show Tour using tourId
  ///
  const { data: tourDetails, refetch } = useQuery(
    ["tourDetails", selectedTourId],
    () => getTour(selectedTourId),
    { enabled: false }
  );

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Pagination items generation with safety check
  const totalPages = data ? data.last_page : 0;
  const paginationItems =
    totalPages > 0 ? (
      <>
        <Pagination.Prev
          onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
          disabled={currentPage === 1}
        />
        {[...Array(totalPages).keys()].map((pageNumber) => (
          <Pagination.Item
            key={pageNumber + 1}
            active={pageNumber + 1 === currentPage}
            onClick={() => handlePageChange(pageNumber + 1)}
          >
            {pageNumber + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() =>
            handlePageChange(Math.min(totalPages, currentPage + 1))
          }
          disabled={currentPage === totalPages}
        />
      </>
    ) : null;

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error loading data</div>;
  const tourData = data && data.data ? data.data : [];
  console.log(tourData, "tourData");
  const handleItemsPerPageChange = (number) => {
    itemsPerPageOptions(number);
    setCurrentPage(1); // Reset to first page when items per page change
  };

  const handleShowClick = (row, status) => {
    setSelectedTourId(row);
    const tour = tourData.find((t) => t.tourId === row);
    setSelectedTour(tour);
    setTimeout(() => {
      refetch({ queryKey: ["tourDetails", row] })
        .then(() => {
          if (status === "show") {
            setShowModal(true);
          } else if (status === "image") {
            setImagesTour(true);
            refetchTourImages()
              .then(() => console.log("success fetching tour images "))
              .catch((error) => {
                console.error("Error fetching tour images:", error);
              });
          } else if (true) {
            setShowEditTour(true);
            refetchTourImages()
              .then(() => console.log("success fetching tour images "))
              .catch((error) => {
                console.error("Error fetching tour images:", error);
              });
            console.log(showEditTour, "sHow Efter ");
          }
        })
        .catch((error) => {
          console.error("Error fetching tour details:", error);
        });
    }, 50);
  };

  ///
  // Delete Tour using tourId
  ///
  const handleDeleteTour = async (id) => {
    try {
      await deleteTour(id);
      queryClient.invalidateQueries(["firstFiveTours", currentPage]);
      toast.success("Tur başarıyla silindi.");
      setShowConfirmModal(false);
    } catch (error) {
      toast.warn("Tur silme hatası.", error);
    }
  };
  const handleDeleteClick = (tourId) => {
    setTourToDelete(tourId);
    setShowConfirmModal(true);
  };
  console.log(tourData, "tourData");
  return (
    <>
      {windowWidth > 768 ? (
        <Table bordered hover responsive>
          <thead className="text-center">
            <tr>
              <th>Tur Adı</th>
              <th>Ulaşım Şirketi</th>
              <th>Ketegori</th>
              <th>Aktif</th>
              <th>Eylem</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {tourData.map((row) => (
              <tr key={row.tourId}>
                <Link to={`/tours/${row.category}/${row.slug}`}>
                  <td style={{ backgroundColor: "blue !important" }}>
                    {row.tourName}
                  </td>
                </Link>
                <td>{row?.company?.companyName}</td>
                <Link to={`/tours/${row.category}`}>
                  <td>{row.category}</td>
                </Link>
                <td>
                  <BooleanIcon value={row.showOnWebsite} />
                </td>

                <td>
                  {" "}
                  <>
                    <Button
                      variant="secondary"
                      className="me-2"
                      onClick={() => handleShowClick(row.tourId, "image")}
                    >
                      <FontAwesomeIcon icon={faImage} />
                    </Button>
                    <Button
                      variant="success"
                      className="me-2"
                      onClick={() => handleShowClick(row.tourId, "show")}
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </Button>
                    <Button
                      variant="primary"
                      className="me-2"
                      onClick={() => handleShowClick(row.tourId)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteClick(row.tourId, "edit")}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  </>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        tourData.map((row) => (
          <ShowMobileTourData
            key={row.id}
            row={row}
            handleShowClick={handleShowClick}
            handleDeleteClick={handleDeleteClick}
          />
        ))
      )}
      <div className="d-flex justify-content-between align-items-center mt-3">
        <div></div> {/* Empty div for spacing */}
        <div style={{ paddingTop: "15px" }}>
          {paginationItems && <Pagination>{paginationItems}</Pagination>}
        </div>
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            sayfada
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {itemsPerPageOptions.map((option) => (
              <Dropdown.Item
                key={option}
                onClick={() => handleItemsPerPageChange(option)}
              >
                {option}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Modal show={showImagesTour} onHide={() => setImagesTour(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Tour Images</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
          {selectedTour && selectedTour.photos ? (
            selectedTour.photos.map((image, index) => (
              <React.Fragment key={index}>
                <img
                  src={image.file_path}
                  alt={`Tour Image ${index + 1}`}
                  style={{ maxWidth: "100%", marginBottom: "15px" }}
                />
                <LineAdvance />
              </React.Fragment>
            ))
          ) : (
            <p>No images available</p>
          )}
        </Modal.Body>
      </Modal>

      <ShowTourDataModal
        showModal={showModal}
        setShowModal={setShowModal}
        tourDetails={tourDetails}
      />
      <ShowConformDeleteModal
        setShowConfirmModal={setShowConfirmModal}
        handleDeleteTour={handleDeleteTour}
        showConfirmModal={showConfirmModal}
        tourToDelete={tourToDelete}
      />
      <EditTourModal
        updateMainPhotograph={updateMainPhotograph}
        updateInsidePhotographs={updateInsidePhotographs}
        updateOutsidePhotographs={updateOutsidePhotographs}
        tourImages={tourImages}
        setFormData={setFormData}
        setActiveSection={setActiveSection}
        activeSection={activeSection}
        tourDetails={tourDetails?.data}
        setShowEditTour={setShowEditTour}
        showEditTour={showEditTour}
        handleNextSection={handleNextSection}
        handlePreviousSection={handlePreviousSection}
        sectionTitles={sectionTitles}
      />
    </>
  );
};
export default AdvertismentList;
