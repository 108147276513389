import React, { useState, useEffect } from "react";
import TourList from "../TourList/TourList/TourList";
import { axios } from "common/utils/axios";
import { useParams } from "react-router-dom";
import ToursTamplateCardWithOtels from "./ToursTemplate/components/ToursTamplateCardWithOtels";
function CategoryTours() {
  const [tours, setTours] = useState([]);
  const [category, setCategory] = useState({});
  const { slug } = useParams();
  console.log(tours, "tours2222");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`api/categories/${slug}/tours`);
        setTours(response.data);
        setCategory(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [slug]);

  return (
    <div>
      {/* <h1>{category.name} Tours</h1> */}
      <TourList tours={tours} setTours={setTours} />
    </div>
  );
}

export default CategoryTours;
