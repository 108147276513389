import React from "react";

export const ReservationNote = () => {
  return (
    <div>
      <div className="online-section-title p3">
        <span className="glyphicon glyphicon-info-sign"></span> Rezervasyon Notu
      </div>
      <div className="row">
        <div className="col-12 col-md-12 p5">
          <textarea
            className="data[not]"
            class="form-control"
            rows="4"
            style={{ height: 49 }}
          ></textarea>
        </div>
      </div>
    </div>
  );
};
export default ReservationNote;
