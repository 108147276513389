import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { axios } from "common/utils/axios";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useCustomerData } from "common/hooks/useCustomerData";
import { useToursData } from "common/hooks/useToursData";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export const Sales = () => {
  const { id } = useParams();
  const [customersSalesData, setCustomersSalesData] = useState([]);
  const customersData = useCustomerData();
  const toursData = useToursData();
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [meetings, setMeetings] = useState([]);

  const columns = [
    { name: "Müşteri ID", selector: (row) => row.customer_id, sortable: true },
    { name: "Tours", selector: (row) => row.tour_id, sortable: true },
    {
      name: "Fiyat",
      selector: (row) => (
        <div className="mb-2">
          <div className="mb-2">
            <span
              style={{
                marginRight: "3px",
                backgroundColor: "#e0e0d1",
                padding: "2px",
                borderRadius: "4px",
              }}
            >
              2 Kişi {row.price}
            </span>
            <span
              style={{
                marginRight: "3px",
                backgroundColor: "#e0e0d1",
                padding: "2px",
                borderRadius: "4px",
              }}
            >
              3 Kişi {row.price}
            </span>
          </div>
          <div>
            <span
              style={{
                marginRight: "3px",
                backgroundColor: "#e0e0d1",
                padding: "2px",
                borderRadius: "4px",
              }}
            >
              4 Kişi {row.price}
            </span>
            <span
              style={{
                marginRight: "3px",
                backgroundColor: "#e0e0d1",
                padding: "2px",
                borderRadius: "4px",
              }}
            >
              5 Kişi
              {row.price}
            </span>
          </div>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      minWidth: "250px",
      maxWidth: "250px",
    },

    { name: "created", selector: (row) => row.created_at, sortable: true },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <Button
            style={{ backgroundColor: "#ff1a1a" }}
            className="mr-2 ml-2"
            variant="contained"
            color="secondary"
            onClick={() => handleDeleteMeeting(row.id)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      minWidth: "250px",
      maxWidth: "250px",
    },
  ];

  /**************************End Data Table ********************************************** */
  const filteredItems = customersSalesData.filter(
    (item) =>
      item.customer_id &&
      item.customer_id
        .toString()
        .toLowerCase()
        .includes(filterText.toLowerCase()) &&
      item.customer_id === id // Compare the strings directly
  );
  const mappedItems = filteredItems.map((item) => {
    const customer = customersData.find((c) => c.id == item.customer_id);
    const tours = toursData.find((c) => c.id == item.tour_id);
    return customer
      ? {
          ...item,
          customer_id: `${customer.FirstName} ${customer.LastName}`,
          tour_id: tours ? `${tours.title}` : "N/A", // Check if tours object exists before accessing the title property
        }
      : item;
  });

  /*********************Fething Api Customer meetings******************* */
  const handleSearch = (e) => {
    setFilterText(e.target.value);
  };
  /******************** API ****************** */
  useEffect(() => {
    axios
      .get(`/api/customer-sales`)
      .then((response) => setCustomersSalesData(response.data))
      .catch((error) => console.error(error));
  }, []);

  const fetchMeetings = async () => {
    const response = await axios.get(`/api/customers/${id}/sales`);
    setMeetings(response.data);
  };

  const handleDeleteMeeting = async (saleId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this meeting?"
    );
    if (confirmDelete) {
      await axios.delete(`api/customer/${id}/sales/${saleId}`);
      fetchMeetings();
    }
  };

  const card = (
    <React.Fragment>
      <CardContent>
        <Typography sx={{ fontSize: 20 }} color="text.secondary" gutterBottom>
          SATIŞLAR
        </Typography>
      </CardContent>
      <CardActions>
        <Link key="one" to={`CreateSales`}>
          <Button
            size="meduim"
            style={{ backgroundColor: "DodgerBlue", color: "#fff" }}
          >
            SATIŞ oluştur
          </Button>
        </Link>
      </CardActions>
    </React.Fragment>
  );
  /******************* Data Table Style ********************** */
  const customStyles = {
    rows: {
      style: {
        fontSize: "20px", // Custom font size
      },
    },
    headCells: {
      style: {
        fontSize: "20px", // Custom font size
      },
    },
  };

  return (
    <div>
      <div
        style={{
          padding: "20px",
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow:
            " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <Box sx={{ minWidth: 275 }}>
          <Card variant="outlined">{card}</Card>

          {customersSalesData.length > 0 && ( // render the table only when data is available
            <div style={{ backgroundColor: "white", borderRadius: "8px" }}>
              <DataTable
                columns={columns}
                data={mappedItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                subHeader
                subHeaderAlign="right"
                persistTableHead
                style={{ fontSize: "100px !important" }}
                customStyles={customStyles}
              />
            </div>
          )}
        </Box>
      </div>
    </div>
  );
};
export default Sales;
