import React from "react";
import Categories from "./components/Categories";
import CustomerDocumentType from "./components/CustomerDocumentType";
import CustomerMeetingsDirection from "./components/CustomerMeetingsDirection";
import FamilyRelativeRelationship from "./components/FamilyRelativeRelationship";
import FromWhereYouHeard from "./components/FromWhereYouHeard";
import Price from "./components/Price";
import CreateRole from "./components/CreateRole";
import FooterLogo from "area/admin/SiteSettings/components/FooterLogo";
import HeaderLogo from "area/admin/SiteSettings/components/HeaderLogo";
import LogInLogo from "area/admin/SiteSettings/components/LogInLogo";
import RegistrationType from "./components/RegistrationType";
export const SiteSettings = () => {
  return (
    <div>
      <h1>Site Ayarları</h1>
      <div className="mt-4">
        <h2>Tours</h2>
      </div>
      <Categories />
      <Price />
      <hr></hr>
      <div>
        <h2> Customer</h2>
      </div>
      <CustomerDocumentType />
      <CustomerMeetingsDirection />
      <FamilyRelativeRelationship />
      <FromWhereYouHeard />
      <RegistrationType />
      <FooterLogo />
      <HeaderLogo />
      <LogInLogo />
      <h2>Auth</h2>
      <CreateRole />
    </div>
  );
};
export default SiteSettings;
