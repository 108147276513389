import React from "react";

export const SimpleFooter = () => {
  return (
    <div
      style={{
        marginTop: "40px",
        backgroundColor: "#333",
        padding: "20px",
        color: "white",
        textAlign: "center",
      }}
    >
      <p>Copyright © 2023 SuareSoft. All rights reserved.</p>
    </div>
  );
};
export default SimpleFooter;
