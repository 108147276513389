import React, { useState, useEffect } from "react";
import RadioInput from "../Components/RadioInput";
import UploadFileInput from "../Components/uploadFileInput";
import { axios } from "common/utils/axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Row, Form, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const familyRelationships = ["Kardeş", "Anne", "Baba", "Diğer"];
export const CreateCustomerFamily = ({
  handleCloseCreateFamilyDialog,
  setOpenCreateFamilyDialog,
}) => {
  const location = useLocation();
  const userId = location.state ? location.state.userId : null;
  console.log(userId, " familyuseridddd");
  const { id } = useParams();
  // const [familyRelationships, setFamilyRelationships] = useState([]);
  const [customerFamily, setCustomerFamily] = useState({
    data: {
      FirstName: "",
      LastName: "",
      RelativeRelation: "",
      Jop: "",
      Gender: "",
      BirthDay: "",
      Telefon: "",
      Eposta: "",
      explantion: "",
      photo: "",
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (const key in customerFamily.data) {
      if (key === "photo" && customerFamily.data[key]) {
        formData.append(
          `data[${key}]`,
          customerFamily.data[key],
          customerFamily.data[key].name
        );
      } else {
        formData.append(`data[${key}]`, customerFamily.data[key]);
      }
    }
    axios
      .post(`api/customers/${id}/family`, formData)
      .then((res) => {
        console.log(res);
        toast.success("Family member added successfully!");
        setOpenCreateFamilyDialog(false);
        handleCloseCreateFamilyDialog();
      })
      .catch((error) => {
        console.error(error);
        toast.error("An error occurred while adding the family member!");
      });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerFamily({
      ...customerFamily,
      data: {
        ...customerFamily.data,
        [name]: value,
      },
    });
  };
  // Add this function inside CreateCustomerFamily component
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setCustomerFamily({
      ...customerFamily,
      data: {
        ...customerFamily.data,
        [name]: files[0],
      },
    });
  };
  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row className="p-2">
          <Col md={4}>
            {/* Assuming TextInput, SelectInput, DateInput, RadioInput, and UploadFileInput are your custom components */}
            <Form.Group controlId="FirstName">
              <Form.Label>Adı</Form.Label>
              <Form.Control
                type=""
                name="FirstName"
                value={customerFamily.data.FirstName}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="LastName">
              <Form.Label>SoyAdı</Form.Label>
              <Form.Control
                type=""
                name="LastName"
                value={customerFamily.data.LastName}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            {/* You might need to adjust SelectInput to use React-Bootstrap's Form.Select */}
            <Form.Group controlId="RelativeRelation">
              <Form.Label>İlişki</Form.Label>
              <Form.Select
                name="RelativeRelation"
                value={customerFamily.data.RelativeRelation}
                onChange={handleChange}
              >
                <option hidden value="">
                  -- İlişki Seç --
                </option>
                {familyRelationships.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group controlId="Jop">
              <Form.Label>Meslek</Form.Label>
              <Form.Control
                type=""
                name="Jop"
                value={customerFamily.data.Jop}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            {/* Assuming RadioInput is a custom component for handling radio inputs */}
            <RadioInput
              name="Gender"
              value={customerFamily.data.Gender}
              onChange={handleChange}
            />
          </Col>
          <Col md={4}>
            <Form.Group controlId="BirthDay">
              <Form.Label>Doğum Tarihi</Form.Label>
              <Form.Control
                type="date"
                name="BirthDay"
                value={customerFamily.data.BirthDay}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="Eposta">
              <Form.Label>E-posta</Form.Label>
              <Form.Control
                type="email"
                name="Eposta"
                value={customerFamily.data.Eposta}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="Telefon">
              <Form.Label>Telefon</Form.Label>
              <Form.Control
                type=""
                name="Telefon"
                value={customerFamily.data.Telefon}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col xs={12} className="mb-4">
            <Form.Group controlId="explantion">
              <Form.Label>Açıklama</Form.Label>
              <Form.Control
                as="textarea"
                name="explantion"
                value={customerFamily.data.explantion}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <UploadFileInput name="photo" onChange={handleFileChange} />
          </Col>
          <Col md={{ span: 10, offset: 2 }} className="text-end">
            <Button
              type="submit"
              style={{
                backgroundColor: "#3199c5",
                color: "white",
                paddingLeft: "30px",
                paddingRight: "30px",
              }}
            >
              Ekle
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export default CreateCustomerFamily;
