import { useState, useEffect } from "react";
const IMAGE_SLIDE_TIME = 5000; // Time to wait before showing next image (in milliseconds)

const ImageSlider = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((currentImageIndex + 1) % images.length);
    }, IMAGE_SLIDE_TIME);

    return () => clearInterval(timer);
  }, [currentImageIndex, images.length]);

  return (
    <div className="slider-container">
      {images.map((imageUrl, index) => (
        <div
          key={index}
          className={`slide${index === currentImageIndex ? " active" : ""}`}
          style={{ backgroundImage: `url(${imageUrl})` }}
        >
          <h1 className="title">Umra</h1>
        </div>
      ))}
    </div>
  );
};

export default ImageSlider;
