import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faDownload, faEye } from "@fortawesome/free-solid-svg-icons";
export default () => {
  return (
    <>
      <button
        style={{
          marginRight: "7px",
          backgroundColor: "#86d858",
          paddingLeft: "10px",
          paddingRight: "10px",
          paddingBottom: "3px",
          paddingTop: "3px",

          borderRadius: "6px",
        }}
      >
        <FontAwesomeIcon icon={faPlay} style={{ color: "white" }} />
      </button>
      {/**
      <button
        style={{
          marginRight: "7px",
          backgroundColor: "#e35920",

          paddingLeft: "10px",
          paddingRight: "10px",
          paddingBottom: "3px",
          paddingTop: "3px",
          borderRadius: "6px",
        }}
      >
        <FontAwesomeIcon icon={faDownload} style={{ color: "white" }} />
      </button>
   
    
      <button
        style={{
          backgroundColor: "#0d6efd",

          paddingLeft: "10px",
          paddingRight: "10px",
          paddingBottom: "3px",
          paddingTop: "3px",
          borderRadius: "6px",
        }}
      >
        <FontAwesomeIcon icon={faEye} style={{ color: "white" }} />
      </button>
       */}
    </>
  );
};
