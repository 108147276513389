import React from "react";

export const Wizard = ({ currentStep }) => {
  const stepClasses = [
    currentStep === 0 ? "active" : "disabled",
    currentStep === 1 ? "active" : "disabled",
    currentStep === 2 ? "active" : "disabled",
    currentStep === 3 ? "active" : "disabled",
  ];

  const progressClasses = [
    currentStep === 0 ? "bg-blue" : "",
    currentStep === 1 ? "bg-blue" : "",
    currentStep === 2 ? "bg-blue" : "",
    currentStep === 3 ? "bg-blue" : "",
  ];

  return (
    <div>
      <div className="row bs-wizard">
        <div className={`col-3 bs-wizard-step ${stepClasses[0]}`}>
          <div className="text-center bs-wizard-stepnum">Bilgiler</div>
          <div
            className={`progress ${
              progressClasses[0] || progressClasses[1] || progressClasses[2]
            }`}
          >
            <div className="progress-bar"></div>
          </div>
          <a href="#" className="bs-wizard-dot noclick"></a>
        </div>
        <div
          className={`col-3 bs-wizard-step ${
            stepClasses[1] || progressClasses[2]
          } `}
        >
          <div className="text-center bs-wizard-stepnum">Onay</div>
          <div
            className={`progress ${progressClasses[1] || progressClasses[2]} `}
          >
            <div className="progress-bar"></div>
          </div>
          <a href="#" className="bs-wizard-dot noclick"></a>
        </div>
        <div className={`col-3 bs-wizard-step ${stepClasses[2]}`}>
          <div className="text-center bs-wizard-stepnum">Online Ödeme</div>
          <div className={`progress ${progressClasses[2]}`}>
            <div className="progress-bar"></div>
          </div>
          <a href="#" className="bs-wizard-dot noclick"></a>
        </div>
        <div className={`col-3 bs-wizard-step ${stepClasses[3]}`}>
          <div className="text-center bs-wizard-stepnum">Sonuç</div>
          <div className={`progress ${progressClasses[3]}`}>
            <div className="progress-bar"></div>
          </div>
          <a href="#" className="bs-wizard-dot noclick"></a>
        </div>
      </div>
    </div>
  );
};
