import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { axios } from "common/utils/axios";
import SelectInput from "../Components/SelectInput";
import { useCustomerByIdData } from "common/hooks/useCustomerByIdData ";
import { useCustomerData } from "common/hooks/useCustomerData";
import { ToastContainer, toast } from "react-toastify";
import { Form, Button, Row, Col, Card } from "react-bootstrap";
import AsyncSelect from "react-select/async";
const Direction = ["Online", "Offsite"];
export const CreateMeeting = ({ setShowModal }) => {
  const { id } = useParams();
  const [diraction, serDirection] = useState([]);
  const customerData = useCustomerByIdData(id);
  const customersData = useCustomerData();
  const [customer, setCustomer] = useState("");
  const [formData, setFormData] = useState({
    Direction: "",
    MeetingDate: "",
    Note: "",
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const url = "/api/customers/meeting";
  const handleSubmit = (event) => {
    event.preventDefault();
    const dataToSend = {
      ...formData,
      customer_id: customer.value.toString(),
      customer_name: customer.label,
    };

    axios
      .post(url, dataToSend)
      .then((response) => {
        console.log(response.data);
        toast.success("Meeting created successfully!");
        setTimeout(() => {
          window.location.pathname =
            "/admin/customerManagement/CustomersMeetings";
        }, 800); // Delay in milliseconds
        setShowModal(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Error occurred while creating the meeting.");
      });
  };
  const loadCustomers = (inputValue = "") => {
    return axios
      .get("/api/customers-listCustomers", {
        params: {
          search: inputValue,
        },
      })
      .then((res) => {
        return res.data.map((customer) => ({
          value: customer.id,
          label: `${customer.FirstName} ${customer.LastName}`,
        }));
      });
  };
  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    return inputValue;
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div
          style={{
            display: "flex", // Use flexbox
            justifyContent: "space-between", // Space between the title and the button
            alignItems: "center", // Vertically center align items
            backgroundColor: "rgb(99, 102, 101)",
            padding: "5px",
            borderRadius: "4px",
            marginBottom: "15px",
            fontWeight: "bold",
            padding: "10px",
            color: "white",
          }}
        >
          <h2>Toplantı Oluştur</h2>
          <Link
            to="/admin/customerManagement/CustomersMeetings"
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="outline-warning"
              type="button"
              style={{
                padding: "10px",
                borderRadius: "4px",
                color: "white",
                marginRight: "10px",
              }}
            >
              Geri
            </Button>
          </Link>
        </div>
        <div className="">
          <Card
            className="p-4"
            style={{
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <Row>
              <Col xl={4}>
                <Form.Group controlId="formTour">
                  <h3>Müşteri</h3>

                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    value={customer}
                    getOptionLabel={(e) => e.label}
                    getOptionValue={(e) => e.value}
                    loadOptions={loadCustomers}
                    onInputChange={handleInputChange}
                    onChange={(e) => setCustomer(e)}
                    required
                  />
                </Form.Group>
              </Col>

              <Col xl={4}>
                <h3>Yön</h3>
                <Form.Control
                  as="select"
                  value={formData.Direction}
                  name="Direction"
                  onChange={handleChange}
                >
                  <option hidden>-- Yön seç --</option>
                  {Direction.map((direction, index) => (
                    <option key={index} value={direction}>
                      {direction}
                    </option>
                  ))}
                </Form.Control>
              </Col>
              <Col xl={4}>
                <h3>Tarih ve Saat</h3>
                <Form.Group controlId="exampleForm.ControlDateTime">
                  <Form.Control
                    type="datetime-local"
                    name="MeetingDate"
                    value={formData.MeetingDate}
                    onChange={handleChange}
                    min={getCurrentDateTime()}
                  />
                </Form.Group>
              </Col>

              <h3>Note</h3>
              <Form.Group controlId="exampleForm.ControlTextarea">
                <Form.Control
                  as="textarea"
                  name="Note"
                  value={formData.Note}
                  onChange={handleChange}
                  rows={5}
                  placeholder="Notunuzu girin"
                />
              </Form.Group>
            </Row>
          </Card>
        </div>
        <div className="d-flex justify-content-end">
          <Button className="mt-4" type="submit">
            Toplantı Oluştur
          </Button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};
// ToDo
const getCurrentDateTime = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};
export default CreateMeeting;
