import React, { useState, useRef } from "react";
import { Form, Button, Modal, Row, Col, Card } from "react-bootstrap";
import CreatButton from "area/admin/CRUD/CreatButton";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useQuery } from "react-query";
import { getListHomePageImagess } from "common/Api/SiteSettingsApi";
import { useMutation } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { axios } from "common/utils/axios";
import { toast } from "react-toastify";
export const HomePage = ({ handleClickCreatButton }) => {
  const [metin, setMetin] = useState("");
  const fileInputRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selectedImages, setSelectedImages] = useState([]);
  const [allImages, setAllImages] = useState([]);

  const { data, error, isLoading } = useQuery(
    "HomPageImages",
    getListHomePageImagess,
    {
      onSuccess: (data) => {
        localStorage.setItem("cachedPosts", JSON.stringify(data));
        setAllImages(data);
      },
    }
  );

  const handleMetinChange = (event) => {
    setMetin(event.target.value);
  };

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : allImages.length - 1
    );
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex < allImages.length - 1 ? prevIndex + 1 : 0
    );
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setShowModal(true);
  };
  const handleSelectImage = () => {
    const selectedImageUrl = allImages[currentImageIndex];
    handleImageSelect(selectedImageUrl);
    closeModal();
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(selectedImages);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSelectedImages(items);
  };

  const removeImage = (index) => {
    const newImages = selectedImages.filter((_, i) => i !== index);
    setSelectedImages(newImages);
    // Step 2: Reset the file input if there are no selected images left
    if (newImages.length === 0 && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  const handleImageSelect = (imageUrl) => {
    if (!selectedImages.includes(imageUrl)) {
      setSelectedImages([...selectedImages, imageUrl]);
    }
  };
  const uploadImagesMutation = useMutation((imageData) => {
    return axios.post("/api/homepage/uploadImages", imageData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  });
  const handleUploadImages = () => {
    const formData = new FormData();
    selectedImages.forEach((image, index) => {
      // Check if the image is a string URL or a File object
      if (typeof image === "string") {
        // If it's a string URL, you may convert it to File as before or skip if not needed
        fetchImageAsFile(image).then((file) => {
          formData.append(`images[${index}]`, file);
          // Consider moving the mutation inside or using Promise.all to wait for all fetches
        });
      } else {
        // Directly append File object
        formData.append(`images[${index}]`, image);
      }
    });

    // Note: If using fetchImageAsFile, ensure all promises resolve before mutating
    uploadImagesMutation.mutate(formData, {
      onSuccess: (response) => {
        toast.success("Images uploaded successfully");
        selectedImages("");
        console.log("Images uploaded successfully:", response.data);
      },
      onError: (error) => {
        toast.error("Error uploading images");
        console.error("Error uploading images:", error);
      },
    });
  };

  // Dummy function to illustrate fetching an image and converting it to a File object
  // This needs to be implemented based on your actual needs
  async function fetchImageAsFile(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], "filename.jpg", { type: "image/jpeg" });
  }
  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    setSelectedImages((currentImages) => [...currentImages, ...files]);
  };

  const deleteImage = async (imageId) => {
    try {
      const response = await axios.delete(`/api/homepage/images/${imageId}`);
      toast.success(response.data.message);
      // Remove the deleted image from the allImages state
      setAllImages(allImages.filter((image) => image.id !== imageId));
      setShowModal(false);
    } catch (error) {
      toast.error("Error deleting image");
      console.error(
        "Error deleting image:",
        error.response?.data?.message || error.message
      );
    }
  };
  console.log(allImages, "allImages");
  return (
    <Card style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)" }}>
      <Card.Header
        style={{
          backgroundColor: "#636665",
          color: "white",
          borderRadius: "5px 5px 0 0",
        }}
      >
        <Row>
          <Col>
            <h1 style={{ padding: "10px", fontSize: "24px", margin: 0 }}>
              Anasayfa
            </h1>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Row>
              <Col>
                <CreatButton title={"Kaydet "} onClick={handleUploadImages} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Header>
      <Row>
        <Col md={6}>
          <Card>
            <Card.Body>
              <h5>Seçilen Ana Resimler</h5>
              <Row>
                <Col>
                  <Form.Group controlId="formFileMultiple" className="mb-3">
                    <Form.Label>Görseller yükle</Form.Label>
                    <Form.Control
                      type="file"
                      multiple
                      onChange={handleFileSelect}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="selectedImages">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {selectedImages.map((image, index) => (
                        <Draggable
                          key={image}
                          draggableId={image}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                              }}
                            >
                              <span
                                style={{
                                  marginRight: "10px",
                                  fontWeight: "bold",
                                }}
                              >
                                {index + 1}
                              </span>
                              <img
                                src={image}
                                alt={`Selected ${index + 1}`}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  objectFit: "cover",
                                }}
                              />
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={() => removeImage(index)}
                                style={{ marginLeft: "10px" }}
                              >
                                Kaldır
                              </Button>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <Card.Body>
              <h5>Tüm Görseller</h5>-
              <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                {allImages.map((image, index) => (
                  <img
                    key={index}
                    src={image?.image_url}
                    alt={`Image ${index + 1}`}
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      cursor: "pointer",
                    }}
                    onClick={() => openModal(index)}
                  />
                ))}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal show={showModal} onHide={closeModal} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Görselleri</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={allImages[currentImageIndex]?.image_url}
            alt="Preview"
            style={{ width: "100%", height: "400px" }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlePrev}>
            Previous
          </Button>
          <Button variant="secondary" onClick={handleNext}>
            Next
          </Button>
          <Button
            variant="danger"
            onClick={() => deleteImage(allImages[currentImageIndex].id)}
          >
            Sil
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export default HomePage;
