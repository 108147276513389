import { useContext, useEffect } from "react";
import FAQ from "common/components/faqs/SimpleWithSideImage";
import HeaderContext from "redux/ContextApi/HeaderContext ";
export const QS = () => {
  const { setTitle, setImageUrl } = useContext(HeaderContext);
  useEffect(() => {
    setTitle("sıkça sorulan sorular");
    setImageUrl("https://backend.suarelab.com/api/about-us/images/SSS.jpg");
    // Clean up function to reset the title and image URL when the component unmounts
    return () => {
      setTitle("");
      setImageUrl("");
    };
  }, [setTitle, setImageUrl]);
  return (
    <>
      <FAQ />
    </>
  );
};
export default QS;
