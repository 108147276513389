import React, { useState, useEffect, Component } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import tw from "twin.macro";
import { PrimaryButton } from "../../../../common/components/misc/Buttons";
import { axios } from "common/utils/axios";
import ToursTamplateCardWithOtels from "../../../Tours/ToursTemplate/components/ToursTamplateCardWithOtels";
const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

const TouersList = ({
  tours,
  otels,
  filteredTours,
  min,
  max,
  prices,
  onLoadMoreClick,
  visibleTours,
  selectedCurrency,
  maxDays,
  minDays,
}) => {
  const toursToDisplay = filteredTours.length ? filteredTours : tours;
  const filteredToursonPrice = toursToDisplay.filter((tour) => {
    // Parse the start and end dates from the tour object
    const startDate = new Date(tour.gidis_tarih);
    const endDate = new Date(tour.donus_tarih);
    // Calculate the difference between the start and end dates in days
    const daysBetween = Math.ceil(
      (endDate - startDate) / (1000 * 60 * 60 * 24)
    );
    // Apply filters for price, currency, and date range
    return (
      tour.iki_kisi_fiyat >= min &&
      tour.iki_kisi_fiyat <= max &&
      (!selectedCurrency || tour.price_id === selectedCurrency) &&
      daysBetween >= minDays &&
      daysBetween <= maxDays
    );
  });

  /************************* Find Data By Id *************************************** */
  /**********Find The Otels By Thier Id ******** */
  function findHotelById(id) {
    return otels.find((otel) => otel.otel_id === id);
  }
  function findHotelById2(id) {
    return otels.find((otel) => otel.otel_id === id);
  }
  /**********Find The Current Price By Thier Id ******** */
  function findPriceById(id) {
    return prices.find((price) => price.id === id);
  }
  /*********************************************************************************** */
  console.log(filteredToursonPrice, "filteredToursonPrice");
  return (
    <div>
      <ToursTamplateCardWithOtels />
      {filteredToursonPrice
        .filter((tour) => new Date(tour.gidis_tarih) >= new Date())
        .slice(0, visibleTours)
        .map((tour) => (
          <div id="allTours " className="touers-bord mb-3" key={tour.id}>
            <div className="container listing-right pricing-bg tour-row">
              <div className="row">
                {/*First in row */}
                <div className="col-6 col-lg-2">
                  <div className="row-thumb">
                    {/************************ */}
                    {otels.find((otel) => otel.otel_id === tour.otel_id) && (
                      <div>
                        <img
                          className="img-fluid"
                          src={findHotelById(tour.otel_id).cover_picture}
                          alt="otel"
                          onError={(e) => console.log("Error loading image", e)}
                        />
                        <Link
                          to={`/otel/${tour.otel_id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            {
                              otels.find(
                                (otel) => otel.otel_id === tour.otel_id
                              ).otel_name
                            }
                          </p>
                        </Link>
                      </div>
                    )}
                    {/******************** */}
                    <div className="tour-hotel-desc">
                      {" "}
                      Geceler {tour.birinci_hotel_geceler_sayisi}{" "}
                    </div>
                  </div>
                </div>
                {/*End First in row */}
                {/*Second in row */}
                <div className="col-6 col-lg-2">
                  <div className="row-thumb">
                    {/************************ */}
                    {otels.find((otel) => otel.otel_id === tour.otel_id2) && (
                      <div>
                        <img
                          className="img-fluid"
                          src={findHotelById2(tour.otel_id2).cover_picture}
                          alt="otel"
                          onError={(e) => console.log("Error loading image", e)}
                        />
                        <Link
                          to={`/otel/${tour.otel_id2}`}
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            {
                              otels.find(
                                (otel) => otel.otel_id === tour.otel_id2
                              ).otel_name
                            }
                          </p>
                        </Link>
                      </div>
                    )}
                    {/******************** */}
                    <div className="tour-hotel-desc">
                      {" "}
                      Geceler {tour.ikinci_hotel_geceler_sayisi}
                    </div>
                  </div>
                </div>
                {/*End Second in row */}
                {/*Descraption About  */}
                <div className="col-lg-8">
                  <ul className="list-unstyled col-md-12">
                    <li className="media">
                      <div className="media-body">
                        <p className="mt-0 mb-1 tour-name">
                          <a href="https://ar.trivago.com/" id="title">
                            {tour.title}
                          </a>
                        </p>
                        <div className="container">
                          <div className="row">
                            <span className="hotel-range col-md-8 mt-2">
                              <FontAwesomeIcon
                                icon={faMoon}
                                className="moon-icon"
                              />
                              <FontAwesomeIcon
                                icon={faMoon}
                                className="moon-icon"
                              />
                              <FontAwesomeIcon
                                icon={faMoon}
                                className="moon-icon"
                              />
                            </span>
                          </div>
                        </div>
                        <div className="container">
                          <div className="row">
                            <ul className="list-group col-6 col-md-4 list-tour-dates touers-list-group">
                              <li className="list-group-item list-group-item2">
                                <span>
                                  <strong className="list-group-item2">
                                    Gidiş Tar
                                  </strong>
                                  .:
                                </span>
                                <br></br>
                                {tour.gidis_tarih}
                              </li>
                              <li className="list-group-item  list-group-item2">
                                <span>
                                  <strong className="list-group-item2">
                                    Ara Geçiş Tar
                                  </strong>
                                  .:
                                </span>
                                <br></br>
                                {tour.ara_gecis_tarih}
                              </li>
                              <li className="list-group-item list-group-item2">
                                <span>
                                  <strong className="list-group-item2">
                                    Dönüş Tar
                                  </strong>
                                  .:
                                </span>
                                <br></br>
                                {tour.donus_tarih}
                              </li>
                            </ul>
                            <ul className="list-group col-6 col-md-4 list-tour-dates touers-list-group">
                              <li className="list-group-item">
                                <span className="location-title">
                                  {tour.gidis_binis}{" "}
                                </span>
                                SAW
                                <img
                                  src="https://www.gezintitatil.com/elci-assets/img/airplane.png"
                                  className="touers-icons"
                                />{" "}
                                <span className="location-title">
                                  {" "}
                                  {tour.gidis_inis}{" "}
                                </span>
                              </li>

                              <li className="list-group-item">
                                <span className="location-title">
                                  {" "}
                                  {tour.ara_gecis_binis}{" "}
                                </span>
                                <img
                                  src="https://www.gezintitatil.com/elci-assets/img/bus.png"
                                  className="touers-icons"
                                />{" "}
                                <span className="location-title">
                                  {" "}
                                  {tour.ara_gecis_inis}{" "}
                                </span>
                              </li>
                              <li className="list-group-item">
                                <span className="location-title">
                                  {" "}
                                  {tour.donus_binis}{" "}
                                </span>
                                <img
                                  src="https://www.gezintitatil.com/elci-assets/img/airplane.png"
                                  className="touers-icons"
                                />{" "}
                                <span className="location-title">
                                  {" "}
                                  {tour.donus_inis}{" "}
                                </span>
                                SAW
                              </li>
                            </ul>
                            <div className="col-12 col-md-4 tour-logo mt-4">
                              <img src={tour.tour_Img_url} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="container">
                    <div className="row">
                      <ul className="list-inline col-md-8 price-list ">
                        <div className="row">
                          <div className="col-6">
                            {tour.iki_kisi_fiyat &&
                            tour.iki_kisi_fiyat !== 0 ? (
                              <li>
                                <FontAwesomeIcon icon={faUser} />
                                <FontAwesomeIcon icon={faUser} /> 2 kişi{" "}
                                {tour.iki_kisi_fiyat} {}
                                {/********** Get Current Price ************* */}
                                {prices.find(
                                  (price) => price.id === tour.price_id
                                ) && findPriceById(tour.price_id).abbreviation}
                                {/*********** Get Current Price ************ */}
                              </li>
                            ) : null}
                            {tour.uç_kisi_fiyat && tour.uç_kisi_fiyat !== 0 ? (
                              <li>
                                <FontAwesomeIcon icon={faUser} />
                                <FontAwesomeIcon icon={faUser} /> 3 kişi{" "}
                                {tour.uç_kisi_fiyat}{" "}
                                {/********** Get Current Price ************* */}
                                {prices.find(
                                  (price) => price.id === tour.price_id
                                ) && findPriceById(tour.price_id).abbreviation}
                                {/*********** Get Current Price ************ */}
                              </li>
                            ) : null}
                          </div>
                          <div className="col-6">
                            {tour.dört_kisi_fiyat &&
                            tour.dört_kisi_fiyat !== 0 ? (
                              <li>
                                <FontAwesomeIcon icon={faUser} />
                                <FontAwesomeIcon icon={faUser} /> 4 kişi{" "}
                                {tour.dört_kisi_fiyat}{" "}
                                {/********** Get Current Price ************* */}
                                {prices.find(
                                  (price) => price.id === tour.price_id
                                ) && findPriceById(tour.price_id).abbreviation}
                                {/*********** Get Current Price ************ */}
                              </li>
                            ) : null}
                            {tour.özel_kisi_saısı &&
                            tour.özel_kisi_saısı !== 0 &&
                            tour.özel_fiyat &&
                            tour.özel_fiyat !== 0 ? (
                              <li>
                                <FontAwesomeIcon icon={faUser} />
                                <FontAwesomeIcon icon={faUser} />{" "}
                                {tour.özel_kisi_saısı} kişi {tour.özel_fiyat}{" "}
                                {/********** Get Current Price ************* */}
                                {prices.find(
                                  (price) => price.id === tour.price_id
                                ) && findPriceById(tour.price_id).abbreviation}
                                {/*********** Get Current Price ************ */}
                              </li>
                            ) : null}
                          </div>
                        </div>
                      </ul>
                      <div className="col-md-4 tour-buy">
                        <div className="buy-button mb-4 px-4 center">
                          <Link to={`/tour/${tour.id}`}>
                            <button className="tn btn-outline-primary buy-btn">
                              {" "}
                              Satın Al
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*End Descraption About */}
              </div>
            </div>
          </div>
        ))}
      {visibleTours < tours.length && (
        <ButtonContainer>
          <LoadMoreButton onClick={onLoadMoreClick}>Load More</LoadMoreButton>
        </ButtonContainer>
      )}
    </div>
  );
};
export default TouersList;
