import { Col, Row, Form, Modal, Button } from "react-bootstrap";
export const AddressModalCreate = ({
  modalShow,
  handleCloseModal,
  handleSubmit,
  handleChange,
  handleCloseCreatAdress,
  il,
  filteredIlce,
  filteredMahalle,
  address,
}) => {
  return (
    <Modal centered show={modalShow} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>YENİ ADRES EKLE</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row className="mb-2">
            <Col xs={12} md={12} xl={4} xxl={4}>
              <Form.Group>
                <Form.Label>il</Form.Label>
                <Form.Control
                  as="select"
                  name="il"
                  value={address.il}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Select il
                  </option>{" "}
                  {il.map((item, index) => (
                    <option key={index} value={item.province_name}>
                      {item.province_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={12} md={12} xl={4} xxl={4}>
              <Form.Group>
                <Form.Label>Ilce</Form.Label>
                <Form.Control
                  as="select"
                  name="ilce"
                  value={address.ilce}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Select Ilce
                  </option>{" "}
                  {filteredIlce.map((item, index) => (
                    <option key={index} value={item.district_name}>
                      {item.district_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={12} md={12} xl={4} xxl={4}>
              <Form.Group>
                <Form.Label>Mahalle</Form.Label>
                <Form.Control
                  as="select"
                  name="mahalle"
                  value={address.mahalle}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Select Mahalle
                  </option>{" "}
                  {filteredMahalle.map((item, index) => (
                    <option key={index} value={item.neighborhood_name}>
                      {item.neighborhood_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group>
            <Form.Label>Address Title</Form.Label>
            <Form.Control
              type="text"
              name="adres_title"
              value={address.adres_title}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Adres</Form.Label>
            <Form.Control
              type="text"
              name="adres"
              value={address.adres}
              onChange={handleChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleCloseCreatAdress(false)}
          >
            Kapat
          </Button>
          <Button variant="primary" type="submit">
            Ekle
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default AddressModalCreate;
