import React, { useState, useEffect } from "react";
import { axios } from "common/utils/axios";
import { useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
} from "@mui/material";
import { useLastMeetingShow } from "common/hooks/useLastMeetingShow";
export const LastShowMeetings = ({ lastMeetingId, setEditShowModal }) => {
  const { customerId, id } = useParams();
  const [customerData, setCustomerData] = useState([]);
  const { lastMeeting, error, isLoading } = useLastMeetingShow(lastMeetingId);

  useEffect(() => {
    axios
      .get(`api/customers/${customerId}`)
      .then((response) => setCustomerData(response.data))
      .catch((error) => console.error(error));
  }, [id]);
  console.log(lastMeeting, "meet");
  console.log(id, "id");
  return (
    <div style={{ padding: "20px" }}>
      <Typography variant="h4" gutterBottom>
        Görüşme Detayları
      </Typography>

      <Grid container spacing={4}>
        {/* Left Grid */}
        <Grid item xs={8}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Arama Bilgileri
              </Typography>
              <List>
                <ListItem>
                  <Typography variant="body2">Tarihi Ve Saat:</Typography>
                  <Typography variant="body1">
                    {new Date(lastMeeting?.created_at).toLocaleString([], {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    })}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body2">Yön:</Typography>
                  <Typography variant="body1">
                    {lastMeeting?.Direction}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body2">Not:</Typography>
                  <Typography variant="body1">{lastMeeting?.Note}</Typography>
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* Right Grid */}
        <Grid item xs={4}>
          {customerData.FirstName && (
            <Card elevation={3} style={{ marginBottom: "20px" }}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Müşteri
                </Typography>
                <List>
                  <ListItem>
                    <Typography variant="body2">Ad Soyad:</Typography>
                    <Typography variant="body1">
                      {customerData.FirstName} {customerData.LastName}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="body2">Numarası:</Typography>
                    <Typography variant="body1">
                      {customerData.Telefon}
                    </Typography>
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          )}

          <Card elevation={3}>
            <CardContent>
              <List>
                <ListItem>
                  <Typography variant="body2">Ekleyen Kullanıcı:</Typography>
                  <Typography variant="body1"></Typography>
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
export default LastShowMeetings;
