import { useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "assets/styles/TourHotelImages.css";
const images = [
  "https://bumtour.ru/uploads/posts/2022-09/1662749880_1512mv-buhhitw_exteriro001-gallery.jpg",
  "https://i.ytimg.com/vi/P11SP8jVs7w/maxresdefault.jpg",
  "https://fintechnews.sg/wp-content/uploads/2018/05/Deluxe-room-M-Hotel-Singapore.jpg",
  "https://fintechnews.sg/wp-content/uploads/2018/05/Deluxe-room-M-Hotel-Singapore.jpg",
  "https://fintechnews.sg/wp-content/uploads/2018/05/Deluxe-room-M-Hotel-Singapore.jpg",
];
export const HotelImages = ({ HotelPhotographs }) => {
  const mainImage = HotelPhotographs?.find(
    (photo) => photo.photo_type === "main"
  );
  const insideOutsideImages = HotelPhotographs?.filter(
    (photo) => photo.photo_type === "inside" || photo.photo_type === "outside"
  );

  const [showModal, setShowModal] = useState(false);
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <ul>
        <li>
          <Row className="mt-2">
            <Col xl={9}>
              {/* Large Image */}
              {mainImage && (
                <img
                  src={mainImage?.photo_url}
                  alt="Large"
                  className="large-image"
                  onClick={() => setShowModal(true)}
                />
              )}
            </Col>
            <Col xl={3} className="hide-on-small-screen">
              {/* Medium Images */}
              {insideOutsideImages?.map((photo) => (
                <img
                  style={{ marginBottom: "10px" }}
                  onClick={() => setShowModal(true)}
                  key={photo.photo_id}
                  src={photo.photo_url}
                  alt={`Hotel ${photo.photo_type} Image`}
                />
              ))}
            </Col>
          </Row>
        </li>

        {/* Thumbnail
        <li className="mt-2">
        <div className="small-images">
        <Row>
        {images.map((image, index) => (
                <Col>
                  <img
                    key={index}
                    src={image}
                    alt={`Thumbnail ${index}`}
                    onClick={() => setShowModal(true)}
                    className="thumbnail-image"
                  />
                </Col>
                ))}
                </Row>{" "}
                </div>
                </li>
              images */}
      </ul>

      {/* Modal with Image Slider */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Body>
          <Slider {...sliderSettings}>
            {HotelPhotographs?.map((image, index) => (
              <div key={index}>
                <img
                  src={image.photo_url}
                  alt={`Slide ${index}`}
                  className="modal-image"
                />
              </div>
            ))}
          </Slider>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default HotelImages;
