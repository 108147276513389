import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

const BooleanIcon = ({ value }) => {
  const iconStyle = {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    width: "24px",
    height: "24px",
    border: `2px solid ${value ? "green" : "red"}`,
  };

  return (
    <span style={iconStyle}>
      <FontAwesomeIcon
        icon={value ? faCheck : faTimes}
        style={{ color: value ? "green" : "red" }}
      />
    </span>
  );
};

export default BooleanIcon;
