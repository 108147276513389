import React from "react";
import { CarousalPayment } from "./CarousalPayment";
import { Wizard } from "../Wizard";
export const OnlinePaymentMainContent = () => {
  return (
    <div>
      OnlinePaymentMainContent
      <Wizard currentStep={2} />
      <CarousalPayment />
    </div>
  );
};
