import React, { useEffect, useState } from "react";
import { axios } from "common/utils/axios";
const months = [
  { name: "January", label: "ocak" },
  { name: "February", label: "şubat" },
  { name: "March", label: "mart" },
  { name: "April", label: "nisan" },
  { name: "May", label: "mayıs" },
  { name: "June", label: "haziran" },
  { name: "July", label: "temmuz" },
  { name: "August", label: "ağustos" },
  { name: "September", label: "eylül" },
  { name: "October", label: "ekim" },
  { name: "November", label: "kasım" },
  { name: "December", label: "aralık" },
];
const CheckBoxFilter = ({
  handleFilterChange,
  selectedMonths,
  setSelectedMonths,
}) => {
  const [availableMonths, setAvailableMonths] = useState([]);
  const handleCheckboxChange = (event) => {
    const month = event.target.value;
    let updatedMonths = [...selectedMonths]; // Make a copy of the selectedMonths array
    if (event.target.checked) {
      updatedMonths.push(month); // Add the new month to the updatedMonths array
    } else {
      updatedMonths = updatedMonths.filter((m) => m !== month); // Remove the month from the updatedMonths array
    }
    setSelectedMonths(updatedMonths); // Update the selectedMonths state with the updatedMonths array
  };

  useEffect(() => {
    const fetchAvailableMonths = async () => {
      try {
        const response = await axios.get("/api/getAvailableMonths");
        console.log(response, "response.data");
        setAvailableMonths(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAvailableMonths();
  }, []);

  return (
    <div>
      <div className="m-4">
        <h4>Ay Olarak</h4>
        {months
          .filter((month) => availableMonths.includes(month.name))
          .map((month) => {
            console.log(month.name, "month.name");
            return (
              <div className="form-check" key={month.name}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={month.name}
                  id={`month-${month.name}`}
                  checked={selectedMonths.includes(month.name)}
                  onChange={handleCheckboxChange}
                />
                <label
                  className="form-check-label"
                  htmlFor={`month-${month.name}`}
                >
                  {month.label}
                </label>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default CheckBoxFilter;
