import { useEffect, useState } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { axios } from "common/utils/axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
export const IndexAboutUS = () => {
  const [showFullContent, setShowFullContent] = useState(false);
  const [data, setData] = useState({
    title: "",
    short_description: "",
    background_image: "",
    content: "",
  });
  const [imageSrc, setImageSrc] = useState(null);
  const [quillValue, setQuillValue] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetching the whole Data
        const response = await axios.get("/api/about-us");
        setData(response?.data[0]); // accessing the first object in the array
        setQuillValue(response.data[0]?.content); // accessing the content of the first object in the array

        // Fetching the image
        const imagePath = response.data[0].background_image; // assuming background_image contains the filename or relative path
        console.log(imagePath, "imagePath");
        setImageSrc(
          `https://backend.suarelab.com/api/about-us/images/${imagePath}`
        );
        console.log("Veri başarıyla güncellendi.", response.data);
      } catch (error) {
        console.error("Veri güncelleme hatası.", error);
        toast.error(
          `Veri güncelleme hatası.: ${
            error.response?.data?.message || error.message
          }`
        );
      }
    };

    fetchData();
  }, []);

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  const displayedContent = showFullContent
    ? data.content
    : `${data.content.slice(0, 500)}${showFullContent ? "..." : ""}`;

  return (
    <div>
      <Card style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)" }}>
        <Card.Header
          style={{
            backgroundColor: "#636665",
            color: "white",
            borderRadius: "5px 5px 0 0",
          }}
        >
          <Row>
            <Col>
              <h1 style={{ padding: "10px", fontSize: "24px", margin: 0 }}>
                Hakımızda
              </h1>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Row>
                <Col>
                  <Link to="/about-us">
                    <Button style={{ color: "white" }} variant="success">
                      Hakkımızda Sayfasına Git
                    </Button>{" "}
                    /{" "}
                  </Link>
                  <Link to="/admin/about-us/create">
                    <Button
                      style={{ color: "white" }}
                      variant="outline-warning"
                    >
                      Hakımızda Düzenle
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Header>
        <Row className="p-3">
          <Col xl={8}>
            <h3>Metin</h3>
            <div dangerouslySetInnerHTML={{ __html: displayedContent }} />
            {!showFullContent && (
              <button onClick={toggleContent}>Read More</button>
            )}
          </Col>
          <Col xl={2}>
            <h3>Kısa Açıklama</h3>
            <p>{data.title}</p>
            <img src={imageSrc} />
          </Col>
        </Row>
      </Card>
    </div>
  );
};
export default IndexAboutUS;
