import React, {useState} from "react";

export default function OtelHoneymoonComponent({
	setSpecialForHoneymoon,
	specialForHoneymoon,
}) {
	const [specialForHoneymoonSingle, setSpecialForHoneymoonSingle] = useState();

	const handleSpecialForHoneymoon = (e) => {
		setSpecialForHoneymoonSingle(e.target.value);
	};
	const handleSpecialForHoneymoonAdd = () => {
		setSpecialForHoneymoon([...specialForHoneymoon, specialForHoneymoonSingle]);
		setSpecialForHoneymoonSingle("");
	};
	const deleteHoneymoon = (e) => {
		console.log(e.target.value);
		let arr = specialForHoneymoon;
		arr.splice(e.target.value, 1);
		setSpecialForHoneymoon([...arr]);
	};
	return (
		<div>
			<label>Balayı Özel </label>
			<br />
			<input
				type="text"
				value={specialForHoneymoonSingle}
				onChange={handleSpecialForHoneymoon}
			/>
			<br />
			<button onClick={handleSpecialForHoneymoonAdd}>add</button>

			{specialForHoneymoon?.map((item, index) => {
				return (
					<>
						<div
							key={index}
							className="inline-block p-3">
							<span>{item} </span>
							<button
								value={index}
								onClick={deleteHoneymoon}
								className="text-red-500">
								X
							</button>
						</div>
					</>
				);
			})}
		</div>
	);
}
