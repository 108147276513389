import { Navigate, useLocation } from "react-router-dom";
import { getCookie } from "common/helpers/getCookie";
export default function PrivateRoute({ children, who }) {
  const location = useLocation();
  const role = getCookie("role");
  const token = getCookie("token");
  console.log(role);

  if (!token) {
    return (
      <Navigate to="/login" replace state={{ return_url: location.pathname }} />
    );
  }

  // if user role is not specified, any authenticated user can access
  if (!role) {
    return (
      <Navigate
        to="/auth/login"
        replace={true}
        state={{ return_url: location.pathname }}
      />
    );
  }

  // check if user role matches required role
  if (!who || who === role) {
    return children;
  } else {
    return (
      <Navigate
        to="/auth/login"
        replace={true}
        state={{ return_url: location.pathname }}
      />
    );
  }
}
