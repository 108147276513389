import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { axios } from "common/utils/axios";

import illustration from "../assets/images/signup-illustration.svg";
import logo from "../assets/images/logo.svg";
import googleIconImageSrc from "../assets/images/google-icon.png";
import twitterIconImageSrc from "../assets/images/facebook.png";
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/user-plus.svg";
import AkyolTurizmLogo from "assets/icons/AkyolTurizmLogo.svg";
import AnimationRevealPage from "../common/helpers/AnimationRevealPage.js";
import { useNavigate, Link } from "react-router-dom";
import { Container as ContainerBase } from "../common/components/misc/Layouts";
import GoogleLoginButton from "common/components/GoogleLoginButton";
import useFacebook from "common/hooks/useFacebook";
const Container = tw(
  ContainerBase
)`min-h-screen bg-custom-green text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-custom-green text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${AkyolTurizmLogo}");`}
  ${tw`m-12 xl:m-16 w-full max-w-lg bg-contain bg-center bg-no-repeat`}
`;
const FacebookButton = styled.button`
  margin-top: 20px;
  color: #fff;
  background-color: #4267b2;
  border-radius: 4px;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #314e86;
  }

  &:active {
    background-color: #233b63;
  }
`;
export default ({
  logoLinkUrl = "#",
  illustrationImageSrc = illustration,
  headingText = "Tursis'e kaydolun",
  socialButtons = [
    {
      iconImageSrc: googleIconImageSrc,
      text: "Sign Up With Google",
      url: "https://google.com",
    },
    {
      iconImageSrc: twitterIconImageSrc,
      text: "Sign Up With facebook",
      url: "https://facebook.com",
    },
  ],
  submitButtonText = "Üye olmak",
  SubmitButtonIcon = SignUpIcon,
  tosUrl = "#",
  privacyPolicyUrl = "#",
  signInUrl = "#",
}) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    password: "",
    password_confirmation: "",
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if passwords match
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    // If passwords match, proceed to make the request
    const requestPayload = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      password: formData.password,
      role: "user",
    };

    axios
      .post("/api/register", requestPayload)
      .then((response) => {
        // handle response
        console.log(response);
        if (response.data.role == "admin") {
          navigate("/admin");
        }
        if (response.data.role == "user") {
          navigate("/user");
        }
        if (response.data.role == "staff") {
          navigate("/staff");
        }
        if (response.data.role == "manager") {
          navigate("/manager");
        }
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  };
  const status = useFacebook("1845526085821908");
  const handleLogin = () => {
    if (status !== "connected") {
      window.FB.login((response) => {
        if (response.status === "connected") {
          // Logged into your app and Facebook.
          console.log("User has successfully logged in");
        } else {
          // The person is not logged into your app or we are unable to tell.
          console.log("User could not log in");
        }
      });
    }
  };
  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <MainContent>
              <Heading>{headingText}</Heading>
              <FormContainer>
                <SocialButtonsContainer>
                  {" "}
                  <GoogleLoginButton />
                </SocialButtonsContainer>
                <SocialButtonsContainer>
                  <FacebookButton onClick={handleLogin}>
                    Facebook ile Giriş Yap
                  </FacebookButton>
                </SocialButtonsContainer>

                <DividerTextContainer>
                  <DividerText>Veya e-postanızla kaydolun</DividerText>
                </DividerTextContainer>
                <Form onSubmit={handleSubmit}>
                  <Input
                    name="firstName"
                    type="text"
                    placeholder="Adı"
                    onChange={handleInputChange}
                  />
                  <Input
                    name="lastName"
                    type="text"
                    placeholder="Soy Adı"
                    onChange={handleInputChange}
                  />
                  <Input
                    name="email"
                    type="email"
                    placeholder="E-Posta"
                    onChange={handleInputChange}
                  />
                  <Input
                    name="confirmEmail"
                    type="email"
                    placeholder="E-posta Onayla"
                    onChange={handleInputChange}
                  />
                  <Input
                    name="password"
                    type="password"
                    placeholder="Şifre"
                    onChange={handleInputChange}
                  />
                  <Input
                    name="confirmPassword"
                    type="password"
                    placeholder="Şifreyi Onayla"
                    onChange={handleInputChange}
                  />
                  <SubmitButton type="submit">
                    <SubmitButtonIcon className="icon" />
                    <span className="text">{submitButtonText}</span>
                  </SubmitButton>
                  <p tw="mt-6 text-xs text-gray-600 text-center">
                    Tursis{" "}
                    <a
                      href={tosUrl}
                      tw="border-b border-gray-500 border-dotted"
                    >
                      Hizmet Koşullarına
                    </a>{" "}
                    ve{" "}
                    <a
                      href={privacyPolicyUrl}
                      tw="border-b border-gray-500 border-dotted"
                    >
                      Gizlilik Politikasına uymayı
                    </a>{" "}
                    kabul ediyorum
                  </p>

                  <p tw="mt-8 text-sm text-gray-600 text-center">
                    Zaten hesabınız var mı?
                    <Link to="/login">Oturum aç</Link>
                  </p>
                </Form>
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustrationImageSrc} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
};
