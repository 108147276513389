import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Table, Form, Button } from "react-bootstrap";
import { getTodayDateString } from "common/helpers/getTodayDateString";
import { isObjectEmpty } from "common/helpers/isObjectEmpty";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { getHotelsName } from "common/Api/HotelsApi";
export const Accomodation = ({ accomodations, updateAccomodationData }) => {
  const [filters, setFilters] = useState();
  const [accomodationsRows, setAccomodationRows] = useState(accomodations);
  const [newAccomodationData, setNewAccomodationData] = useState({
    city: "",
    otel_id: "",
    startDate: "",
    endDate: "",
    nights: "",
  });

  const addAccomodationRow = () => {
    if (isObjectEmpty(newAccomodationData)) {
      toast.warn("bazı veriler eksik");
      return;
    } else {
      console.log("Tüm veriler kayıp.");
    }
    const newRows = [...accomodationsRows, newAccomodationData];
    setAccomodationRows(newRows);
    updateAccomodationData(newRows);
    setNewAccomodationData({
      city: "",
      otel_id: "",
      startDate: "",
      endDate: "",
      nights: "",
    });
  };
  const handleInputChange = (e, field) => {
    let value = e.target.value;
    console.log(`Field: ${field}, Value: ${value}`);
    if (field === "startDate" || field === "endDate") {
      value = value.replace("T", " "); // Replace T with a space
    }
    setNewAccomodationData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const removeAccomodationsRowsRow = (index) => {
    const updatedRows = [...accomodationsRows];
    updatedRows.splice(index, 1);
    setAccomodationRows(updatedRows);
  };
  useEffect(() => {
    if (accomodations) {
      const filteredAccomodations = accomodations.filter(
        (acc) => !isObjectEmpty(acc)
      );
      setAccomodationRows(filteredAccomodations);
    } else {
      setAccomodationRows([]);
    }
  }, [accomodations]);
  const {
    data: hotelNameAndId,
    isLoading,
    isError,
  } = useQuery(["HotelsName", filters], () => getHotelsName({ filters }), {
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
  console.log(hotelNameAndId, "hotelNameAndId");
  return (
    <div>
      <h5>Konaklama</h5>
      <Table responsive>
        <thead>
          <tr>
            <th style={{ width: "25%" }}>Şehir</th>
            <th style={{ width: "25%" }}>Otel Adı</th>
            <th style={{ width: "15%" }}>Başlancı Tarıhı</th>
            <th style={{ width: "10%" }}>Gece</th>
            <th style={{ width: "15%" }}>Bitiş Tarıhı</th>
            <th style={{ width: "10%" }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Form.Select
                value={newAccomodationData?.city}
                onChange={(e) => handleInputChange(e, "city")}
              >
                <option value="" hidden>
                  Şehir seç
                </option>
                <option value="Istanbul">Istanbul</option>
                <option value="Ankara">Ankara</option>
                <option value="Izmir">Izmir</option>
              </Form.Select>
            </td>
            <td>
              <Form.Select
                value={newAccomodationData?.otel_id}
                onChange={(e) => handleInputChange(e, "otel_id")}
              >
                <option value="" hidden>
                  Otel seç
                </option>
                {hotelNameAndId &&
                  hotelNameAndId.map((hotel, index) => (
                    <option key={index} value={hotel.otel_id}>
                      {hotel.otel_name}
                    </option>
                  ))}
              </Form.Select>
            </td>
            <td>
              <Form.Control
                type="date"
                value={newAccomodationData?.startDate}
                onChange={(e) => handleInputChange(e, "startDate")}
                min={getTodayDateString()}
              />
            </td>
            <td>
              <Form.Control
                type="number"
                min={1}
                max={356}
                value={newAccomodationData?.nights}
                onChange={(e) => handleInputChange(e, "nights")}
              />
            </td>
            <td>
              <Form.Control
                type="date"
                value={newAccomodationData?.endDate}
                onChange={(e) => handleInputChange(e, "endDate")}
                min={newAccomodationData?.startDate || getTodayDateString()}
              />
            </td>
            <td>
              <Button
                active={true}
                variant="success"
                onClick={addAccomodationRow}
              >
                <FontAwesomeIcon icon={faSave} />
              </Button>
            </td>
          </tr>
          {accomodationsRows &&
            accomodationsRows.map((row, index) => {
              return (
                <tr key={row.id}>
                  <td>{row.city}</td>
                  <td>{row.otel_id}</td>
                  <td>{row.startDate}</td>
                  <td>{row.nights}</td>
                  <td>{row.endDate}</td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => removeAccomodationsRowsRow(index)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};
export default Accomodation;
