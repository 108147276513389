import React from "react";
import "../../Style.css";
export const InformationofAccommodationPersons = () => {
  return (
    <div>
      <div className="online-section-title mb-4 p3">
        <span className="glyphicon glyphicon-info-sign"></span> Konaklayacak
        Kişilerin Bilgileri
      </div>
      <div className="">
        <div className="row rez-person">
          <div className="col-4 col-md-2 p2">
            <label className="control-label mb0 p2 fw-600">Cinsiyet</label>
            <div>Bay</div>
          </div>
          <div className="col-4 col-md-3 p2">
            <label className="control-label mb0 p2 fw-600">Ad</label>
            <div>asd</div>
          </div>
          <div className="col-4 col-md-3 p2">
            <label className="control-label mb0 p2 fw-600">Soyad</label>
            <div>asd</div>
          </div>
          <div className="col-12 col-md-4 p2">
            <label className="control-label mb0 p2 fw-600">Doğum Tarihi</label>
            <div>06.07.1997</div>
          </div>
        </div>
        <div className="row rez-person">
          <div className="col-4 col-md-2 ">
            <label className="control-label mb0 p2 fw-600">Cinsiyet</label>
            <div>Bayan</div>
          </div>
          <div className="col-4 col-md-3 p2">
            <label className="control-label mb0 p2 fw-600">Ad</label>
            <div>asd</div>
          </div>
          <div className="col-4 col-md-3 ">
            <label className="control-label mb0 p2 fw-600">Soyad</label>
            <div>asd</div>
          </div>
          <div className="col-12 col-md-4 p2">
            <label claclassNamess="control-label mb0 p2 fw-600">
              Doğum Tarihi
            </label>
            <div>04.03.1998</div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
};
