import React, {useState} from "react";

export default function OtelSliderComponent({setSlider, slider}) {
	const [sliderImage, setSliderImage] = useState();
	const [sliderImageOrder, setSliderImageOrder] = useState();

	const handleSliderImage = (e) => {
		setSliderImage(e.target.value);
	};
	const handlesliderImageOrder = (e) => {
		setSliderImageOrder(e.target.value);
	};
	const handleSlider = () => {
		setSlider([...slider, {image: sliderImage, order: sliderImageOrder}]);
		setSliderImageOrder("");
		setSliderImage("");
	};
	const deleteSlider = (e) => {
		console.log(e.target.value);
		let arr = slider;
		arr.splice(e.target.value, 1);
		setSlider([...arr]);
	};
	return (
		<div>
			<label>Slider Fotoğrafları </label>
			<br />
			<label>Slider Resmi Linki:</label>
			<input
				type="text"
				value={sliderImage}
				onChange={handleSliderImage}
			/>
			<br />
			<label>Order: </label>
			<input
				type="text"
				value={sliderImageOrder}
				onChange={handlesliderImageOrder}
			/>
			<br />
			<button onClick={handleSlider}>add</button>

			{slider?.map((item, index) => {
				return (
					<div key={index}>
						<span>link:{item.image}</span>
						<br />
						<span>order:{item.order}</span> <br />
						<button
							value={index}
							onClick={deleteSlider}
							className="text-red-500">
							X
						</button>
						<br />
						<br />
					</div>
				);
			})}

			<br />
		</div>
	);
}
