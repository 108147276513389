import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TopSection from "./TopSection";
import ButtomSection from "./ButtomSection";
const TourDetailsSideBar = () => {
  return (
    <div className="col-lg-4 col-xl-6 col-md-12 float-right">
      <div className="detail-right-content-wrapper">
        <div className="detail-right-content">
          {/** Frsit Top Seaction */}
          <TopSection />
          {/* Section Image Section */}
          <ButtomSection />
        </div>
      </div>
    </div>
  );
};
export default TourDetailsSideBar;
