import { useEffect } from "react";
import { Row, Col, Form, Button, Modal, ListGroup } from "react-bootstrap";
import TourSection from "area/admin/AdvertisementManegment/Components/Create/TourSection";
import Accomodation from "area/admin/AdvertisementManegment/Components/Create/Accomodation";
import FoodOptions from "area/admin/AdvertisementManegment/Components/Create/FoodOptions";
import Transport from "area/admin/AdvertisementManegment/Components/Create/Transport";
import Trips from "area/admin/AdvertisementManegment/Components/Create/Trips";
import NewPricing from "area/admin/AdvertisementManegment/Components/Create/NewPricing";
import DropzoneComponent from "area/admin/CRUD/DropzoneComponent";
import Prices from "area/admin/AdvertisementManegment/Components/Create/Prices";
import classNames from "classnames";
import TourInformationImage from "assets/images/Advertisment/TurBilgileri.png";
import accomodationImage from "assets/images/Advertisment/Konaklama.png";
import foodImage from "assets/images/Advertisment/Yemek.png";
import trasportImage from "assets/images/Advertisment/Ulaşım.png";
import TripImage from "assets/images/Advertisment/Gezi.png";
import priceImage from "assets/images/Advertisment/Fiyatlar.png";
import CustomeDropZone from "area/admin/AdvertisementManegment/Components/CustomeDropZone";
export default ({
  updateMainPhotograph,
  updateInsidePhotographs,
  updateOutsidePhotographs,
  formData,
  setFormData,
  setModalShowCreate2,
  modalShowCreate2,
  activeSection,
  sectionTitles,
  setActiveSection,
  handleSubmitFinal,
  handleNextSection,
  handlePreviousSection,
  handleChange,
  updateTransportData,
  updateAccomodationData,
  updateFoodOptionData,
  updateTripData,
  updateTourSectionData,
  updatePriceData,
  updateDiscountPriceData,
  updateInstallmentsData,
  updateTourPhotosData,
}) => {
  const headerBackgroundImages = {
    1: TourInformationImage,
    2: accomodationImage,
    3: foodImage,
    4: trasportImage,
    5: TripImage,
    6: priceImage,
    // ... and so on for each section
  };
  const headerBackground =
    headerBackgroundImages[activeSection] || "defaultBackgroundImageUrl";
  const handleFileAddition = (newFile) => {
    setFormData({
      ...formData,
      images: [...formData.images, newFile],
    });
  };
  const handleFileRemoval = (fileToRemove) => {
    setFormData({
      ...formData,
      images: formData.images.filter((file) => file !== fileToRemove),
    });
  };
  console.log(formData, "formData in creat tour modal");
  return (
    <Modal
      show={modalShowCreate2}
      onHide={() => setModalShowCreate2(false)}
      size="xl"
      centered
    >
      <Modal.Header
        style={{
          backgroundImage: `url(${headerBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></Modal.Header>

      <Modal.Body style={{ maxHeight: "400px", overflowY: "auto" }}>
        <Row className="mb-4 text-center ">
          <Col md={12}>
            <ListGroup className="list-group-horizontal-md">
              {sectionTitles &&
                sectionTitles?.map((title, index) => (
                  <ListGroup.Item
                    key={index}
                    className={classNames({
                      "active-list-group-item": activeSection === index + 1,
                    })}
                    action
                    active={activeSection === index + 1}
                    onClick={() => setActiveSection(index + 1)}
                  >
                    {title}
                  </ListGroup.Item>
                ))}
            </ListGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {activeSection === 1 && (
              <TourSection
                formData={formData}
                handleChange={updateTourSectionData}
              />
            )}
            {activeSection === 2 && (
              <div style={{ height: "350px " }}>
                <Accomodation
                  accomodations={formData?.accomodations}
                  updateAccomodationData={updateAccomodationData}
                />
              </div>
            )}
            {activeSection === 3 && (
              <div style={{ height: "350px " }}>
                <FoodOptions
                  food_options={formData?.food_options}
                  updateFoodOptionData={updateFoodOptionData}
                />
              </div>
            )}

            {activeSection === 4 && (
              <div style={{ height: "350px " }}>
                <Transport
                  transportations={formData?.transportations}
                  updateTransportData={updateTransportData}
                />
              </div>
            )}

            {activeSection === 5 && (
              <div style={{ height: "350px " }}>
                <Trips
                  activeSection={activeSection}
                  trips={formData.trips}
                  updateTripData={updateTripData}
                />
              </div>
            )}
            {activeSection === 6 && (
              <div style={{ height: "400px " }}>
                <NewPricing
                  formData={formData}
                  setFormData={setFormData}
                  updatePriceData={updatePriceData}
                  updateInstallmentsData={updateInstallmentsData}
                />
              </div>
            )}
            {activeSection === 7 && (
              <div style={{ height: "350px" }}>
                <Form.Group controlId="gallery">
                  <Form.Label>
                    <h4>Galeri</h4>
                    <hr />
                  </Form.Label>
                  <CustomeDropZone
                    updateMainPhotograph={updateMainPhotograph}
                    updateInsidePhotographs={updateInsidePhotographs}
                    updateOutsidePhotographs={updateOutsidePhotographs}
                  />
                  {/*
                
                  <DropzoneComponent
                    updateTourPhotosData={updateTourPhotosData}
                    onFilesAdded={handleFileAddition}
                    onFilesRemoved={handleFileRemoval}
                  />
                */}
                </Form.Group>
              </div>
            )}
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => setModalShowCreate2(false)}>
          Kapat
        </Button>
        {activeSection > 1 && (
          <Button variant="warning" onClick={handlePreviousSection}>
            Önceki
          </Button>
        )}
        {activeSection < 7 ? (
          <Button variant="primary" onClick={() => handleNextSection({})}>
            Sonraki
          </Button>
        ) : (
          <Button variant="success" onClick={handleSubmitFinal}>
            Kaydet
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
