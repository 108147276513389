import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UseTourDetails from "../../../../../common/utils/functions/UseTourDetails";
export const SideBarDateSection = () => {
  /****  Start Reseve Data From Database   **** */
  const { id } = useParams();
  const tour = UseTourDetails(id);
  if (!tour) {
    return <div>Loading...</div>;
  }
  /****  End Reseve Data From Database   **** */
  return (
    <div className="container entry-date py-2 hotel-accom-calc-row">
      <div className="form-control form-control-font">{tour?.gidis_tarih}</div>
    </div>
  );
};
export default SideBarDateSection;
