import React, { useState, useEffect } from "react";
import CallPopup from "area/admin/CallPopup";
import Pusher from "pusher-js";
import { Outlet } from "react-router-dom";
import Sidebar from "react-sidebar";
import LineAdvance from "area/admin/CRUD/LineAdvance";
import SkinnySidebar from "area/admin/baseComponents/SkinnySidebar";
import SimpleFooter from "common/components/footers/SimpleFooter";
import AdminHeadr from "common/components/headers/AdminHeadr";
import MobileSidebar from "area/admin/baseComponents/MobileSidebar";
import TourBG from "assets/images/BG/TourBG2.jpg";
const outletStyle = {
  overflowY: "auto",
};
const outletBackgroundStyle = {
  ...outletStyle,
  backgroundImage: `url(${TourBG})`,
  backgroundSize: "contain",
  backgroundPosition: "center",
};

export default function AdminLayout() {
  const [showPopup, setShowPopup] = useState(false);
  const [callerNumber, setCallerNumber] = useState(null);
  const [callSecnario, setCallSecnario] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth < 680) {
        setSidebarOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const SidebarContent =
    windowWidth < 680 ? (
      <MobileSidebar
        toggleSidebar={toggleSidebar}
        setShowMenu={setShowMenu}
        showMenu={showMenu}
      />
    ) : (
      <SkinnySidebar setShowMenu={setShowMenu} showMenu={showMenu} />
    );

  // Call webSocket Handeling
  useEffect(() => {
    // Initialize Pusher
    const pusher = new Pusher("7b16d310c2769826e7f7", {
      cluster: "eu",
      encrypted: true,
    });
    const channel = pusher.subscribe("calls");
    // Bind and listen to the event
    channel.bind("App\\Events\\CallEvent", (data) => {
      console.log(data, "data");
      setShowPopup(true);
      setCallerNumber(data.callerNumber);
      setCallSecnario(data.action);
    });
    return () => {
      // Unbind previous bindings before unmounting
      channel.unbind_all();
      pusher.unsubscribe("calls");
    };
  }, []);
  return (
    <div
      className="flex flex-col min-h-screen"
      style={{ backgroundColor: "#f9f9f9" }}
    >
      <div className="flex-1 grid md:grid-cols-1 lg:grid-cols-6">
        <Sidebar
          sidebar={SidebarContent}
          open={sidebarOpen}
          onSetOpen={toggleSidebar}
          styles={{
            sidebar: {
              position: "fixed",
              top: 0,
              bottom: 0,
              zIndex: 1000,
              backgroundColor: "#1C1E1E",
              maxHeight: "100vh",
              overflowY: "auto",
            },
            overlay: { display: "none" },
          }}
          className="sidebar-width"
        >
          <div
            className={`pl-4 pr-4 flex flex-col min-h-full ${
              sidebarOpen ? "w-9/9" : "sm:w-5/6 lg:w-full"
            } ${sidebarOpen ? "pl-2" : ""}`}
            style={{
              backgroundColor: "#f9f9f9",
              marginLeft: sidebarOpen ? "55px" : "0",
            }}
          >
            <AdminHeadr
              setAnchorEl={setAnchorEl}
              anchorEl={anchorEl}
              toggleSidebar={toggleSidebar}
            />
            <LineAdvance />
            <div className="flex-1" style={outletBackgroundStyle}>
              <Outlet />
            </div>
          </div>
          <SimpleFooter />
        </Sidebar>
      </div>
      <CallPopup
        show={showPopup}
        callerNumber={callerNumber}
        callSecnario={callSecnario}
      />
    </div>
  );
}
