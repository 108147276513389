import { isObjectEmpty } from "common/helpers/isObjectEmpty";
import React, { useState, useEffect } from "react";
import { Table, Form, Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEdit,
  faSave,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
export const Trips = ({ trips, updateTripData }) => {
  const [ulasimRows, setUlasimRows] = useState(trips);
  const [editingTripIndex, setEditingTripIndex] = useState(null);
  const [originalTripData, setOriginalTripData] = useState(null);

  const [newUlasimData, setNewUlasimData] = useState({
    tripTitle: "",
    tripDescription: "",
    howManyDays: "",
    timing: "",
    packagePricing: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [currentDescription, setCurrentDescription] = useState("");
  const addUlasimRow = () => {
    if (isObjectEmpty(newUlasimData)) {
      return toast.warn("bazı veriler eksik");
    } else {
      console.log("All data is missing");
    }
    const newRows = [...ulasimRows, newUlasimData];
    setUlasimRows(newRows);
    updateTripData(newRows);
    setNewUlasimData({
      tripTitle: "",
      tripDescription: "",
      howManyDays: "",
      timing: "",
      packagePricing: "",
    });
  };

  const removeUlasimRow = (index) => {
    const updatedRows = [...ulasimRows];
    updatedRows.splice(index, 1);
    setUlasimRows(updatedRows);
    updateTripData(updatedRows);
  };
  const handleInputChange = (e, field) => {
    setNewUlasimData((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };
  const editTripRow = (index) => {
    setEditingTripIndex(index);
    setOriginalTripData({ ...ulasimRows[index] });
  };

  const saveTripRow = () => {
    setEditingTripIndex(null);
  };

  const cancelTripEdit = () => {
    const updatedRows = [...ulasimRows];
    updatedRows[editingTripIndex] = originalTripData;
    setUlasimRows(updatedRows);
    setEditingTripIndex(null);
  };
  const handleRowChange = (e, field, index) => {
    const updatedRows = [...ulasimRows];
    updatedRows[index] = { ...updatedRows[index], [field]: e.target.value };
    setUlasimRows(updatedRows);
  };
  useEffect(() => {
    if (trips) {
      const filteredTripss = trips.filter((acc) => !isObjectEmpty(acc));
      setUlasimRows(filteredTripss);
    } else {
      setUlasimRows([]);
    }
  }, [trips]);

  const openModalWithDescription = (description) => {
    setCurrentDescription(description);
    setShowModal(true);
  };
  return (
    <div>
      <h5>Gezi</h5>
      <Table responsive>
        <thead>
          <tr>
            <th>Başlık</th>
            <th>ihtiyatlılık</th>
            <th>Kaç gün</th>
            <th>Zamanlama</th>
            <th>Paket Fiyatlandırması</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Form.Control
                type="text"
                name="tripTitle"
                value={newUlasimData.tripTitle}
                onChange={(e) => handleInputChange(e, "tripTitle")}
              />
            </td>
            <td>
              <Form.Control
                type="text"
                name="tripDescription"
                value={newUlasimData.tripDescription}
                onChange={(e) => handleInputChange(e, "tripDescription")}
              />
            </td>
            <td style={{ width: "10%" }}>
              <Form.Control
                style={{ width: "100%" }}
                min={0}
                type="number"
                name="howManyDays"
                value={newUlasimData.howManyDays}
                onChange={(e) => handleInputChange(e, "howManyDays")}
              />
            </td>
            <td>
              <Form.Control
                type="time"
                name="timing"
                value={newUlasimData.timing}
                onChange={(e) => handleInputChange(e, "timing")}
              />
            </td>
            <td style={{ width: "10%" }}>
              <Form.Control
                style={{ width: "100%" }}
                min={0}
                type="number"
                name="packagePricing"
                value={newUlasimData.packagePricing}
                onChange={(e) => handleInputChange(e, "packagePricing")}
              />
            </td>

            <td className="text-center">
              <Button active={true} variant="success" onClick={addUlasimRow}>
                <FontAwesomeIcon icon={faSave} />
              </Button>
            </td>
          </tr>
          {ulasimRows.map((row, index) => {
            const isEditing = index === editingTripIndex;
            return (
              <tr key={index} style={{ textAlign: "center" }}>
                <td>
                  {isEditing ? (
                    <Form.Control
                      type="text"
                      value={row.tripTitle}
                      onChange={(e) => handleRowChange(e, "tripTitle", index)}
                    />
                  ) : (
                    row.tripTitle
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <Form.Control
                      as="textarea"
                      value={row.tripDescription}
                      onChange={(e) =>
                        handleRowChange(e, "tripDescription", index)
                      }
                    />
                  ) : (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span>
                        {row.tripDescription.length > 25
                          ? `${row.tripDescription.substring(0, 25)}...`
                          : row.tripDescription}
                      </span>
                      {row.tripDescription.length > 25 && (
                        <FontAwesomeIcon
                          style={{ cursor: "pointer", marginLeft: "5px" }}
                          icon={faEye}
                          onClick={() =>
                            openModalWithDescription(row.tripDescription)
                          }
                        />
                      )}
                    </div>
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <Form.Control
                      type="number"
                      min={0}
                      value={row.howManyDays}
                      onChange={(e) => handleRowChange(e, "howManyDays", index)}
                    />
                  ) : (
                    row.howManyDays
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <Form.Control
                      type="time"
                      value={row.timing}
                      onChange={(e) => handleRowChange(e, "timing", index)}
                    />
                  ) : (
                    row.timing
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <Form.Control
                      type="number"
                      min={0}
                      value={row.packagePricing}
                      onChange={(e) =>
                        handleRowChange(e, "packagePricing", index)
                      }
                    />
                  ) : (
                    row.packagePricing
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <>
                      <Button
                        variant="warning"
                        onClick={() => saveTripRow(index)}
                        style={{ marginRight: "5px" }}
                      >
                        <FontAwesomeIcon icon={faSave} />
                      </Button>
                      <Button
                        className="mr-2"
                        variant="secondary"
                        onClick={cancelTripEdit}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="warning"
                      onClick={() => editTripRow(index)}
                      style={{ marginRight: "5px" }}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  )}
                  <Button
                    variant="danger"
                    onClick={() => removeUlasimRow(index)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>{currentDescription}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default Trips;
