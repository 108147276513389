import React from "react";
import "../../Style.css";
export const AccommodationInformation = () => {
  return (
    <div className="detail-tab-content mb-4 mt-4">
      <form>
        <div class="clearfix"></div>
        <div class="online-section-title p3">
          <span class="glyphicon glyphicon-info-sign"></span> Konaklama
          Bilgileri
        </div>
        {/******************************************* */}
        <div class="form-horizontal">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group row">
                <label class="control-label col-12 col-sm-12 col-md-4 fw-600">
                  Otel Adı :
                </label>
                <div class="col-12 col-sm-12 col-md-8">
                  200. Grup – RAMAZAN - 43 Gün – 9 Gece Medine / 33 Gece Mekke
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label col-sm-12 col-md-4 fw-600">
                  Giriş Tarihi :
                </label>
                <div class="col-sm-12 col-md-8 ">13.03.2023</div>
              </div>
              <div class="form-group row">
                <label class="control-label col-sm-12 col-md-4 fw-600">
                  Çıkış Tarihi :
                </label>
                <div class="col-sm-12 col-md-8">25.04.2023</div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group row">
                <label class="control-label col-sm-12 col-md-4 fw-600">
                  Konsept :
                </label>
                <div class="col-sm-12 col-md-8">Yarım Pansiyon</div>
              </div>
              <div class="form-group row">
                <label class="control-label col-sm-12 col-md-4 fw-600">
                  Seçenek :
                </label>
                <div class="col-sm-12 col-md-8">2'Lİ ODA</div>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
          {/******************************************* */}
        </div>
      </form>
      <hr />
    </div>
  );
};
export default AccommodationInformation;
