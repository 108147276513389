import React from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, InputGroup, FormControl, Button } from "react-bootstrap";
export const SearchFilterComponent = ({
  setSearchFilter,
  searchFilter,
  handleSearchFilter,
}) => {
  return (
    <Row>
      <Col>
        {" "}
        <InputGroup className="mb-3">
          {" "}
          <FormControl
            type="text"
            value={searchFilter?.firstName}
            onChange={(e) =>
              setSearchFilter({ ...searchFilter, firstName: e.target.value })
            }
            placeholder="Başlık , Açıklama , Metin ..."
          />
          <Button
            style={{ backgroundColor: "rgb(0, 123, 255)" }}
            onClick={handleSearchFilter}
          >
            <FontAwesomeIcon
              icon={faSearch}
              style={{ fontSize: "18px", marginRight: "8px" }}
              onClick={handleSearchFilter}
            />
          </Button>
        </InputGroup>
      </Col>
    </Row>
  );
};
export default SearchFilterComponent;
