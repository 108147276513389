import React from "react";
import { useState, useEffect } from "react";
import { axios } from "common/utils/axios";
import { ToastContainer, toast } from "react-toastify";

const CreateTours = () => {
  const [otels, setOtels] = useState([]);
  const [prices, setPrices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tourData, setTourData] = useState({
    data: {
      title: "",
      birinci_hotel_geceler_sayisi: "",
      ikinci_hotel_geceler_sayisi: "",
      gidis_tarih: "",
      ara_gecis_tarih: "",
      donus_tarih: "",
      iki_kisi_fiyat: "",
      uç_kisi_fiyat: "",
      dört_kisi_fiyat: "",
      özel_kisi_saısı: "",
      özel_fiyat: "",
      gidis_binis: "",
      gidis_inis: "",
      ara_gecis_binis: "",
      ara_gecis_inis: "",
      donus_binis: "",
      donus_inis: "",
      otel_id: "",
      otel_id2: "",
      price_id: "",
      category_id: "",
    },
  });
  {
    /****** Otels ***** */
  }
  const getOtels = async () => {
    const res = await axios.get("api/otel");
    if (res) {
      console.log("otels", res);
      setOtels(res.data.otels);
    }
  };
  useEffect(() => {
    getOtels();
  }, []);
  {
    /******** Prices ******** */
  }
  useEffect(() => {
    axios
      .get("api/prices")
      .then((response) => setPrices(response.data))
      .catch((error) => console.error(error));
  }, []);
  {
    /************************ */
  }
  /******** Categories ******** */

  useEffect(() => {
    axios
      .get("/api/categories")
      .then((response) => setCategories(response.data))
      .catch((error) => console.error(error));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("api/create-tour", {
        data: {
          ...tourData.data,
          // price_id: tourData.data.price_id,
        },
      })
      .then((response) => {
        console.log(response.data);
        toast.success("Tour created successfully!");
        // reset tour data
        setTourData(tourData);
      })
      .catch((error) => {
        console.error(error);
        toast.error("An error occurred while creating tour.");
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTourData((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [name]: value,
      },
    }));

    // Update the price_id state when the user selects a price
    if (name === "price_id") {
      const priceId = parseInt(value); // Convert value to integer
      setTourData((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          price_id: priceId || "", // Set price_id to priceId or an empty string
        },
      }));
    }

    // Update the category_id state when the user selects a category
    if (name === "category_id") {
      const categoryId = parseInt(value); // Convert value to integer
      setTourData((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          category_id: categoryId || "", // Set category_id to categoryId or an empty string
        },
      }));
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="container list-group">
          <div className="row justify-content-center mb-3">
            <div className="col-5 list-group-item text-center mr-4 rounded ">
              <input
                type="text"
                name="title"
                placeholder="Title"
                className="p-3"
                onChange={handleChange}
                value={tourData.data.title}
              ></input>
            </div>
            <div className="col-5 list-group-item text-center mr-4 rounded ">
              <input
                type="text"
                name="tour_Img_url"
                placeholder="tour_Img_url"
                className="p-3"
                onChange={handleChange}
                value={tourData.data.tour_Img_url}
              ></input>
            </div>
          </div>

          <div className="row justify-content-center mb-3">
            <div className="col-3 list-group-item text-center mr-4 rounded p-3">
              <label>
                Gidiş Tarihi:{" "}
                <input
                  type="date"
                  name="gidis_tarih"
                  onChange={handleChange}
                  value={tourData.data.gidis_tarih}
                />
              </label>
            </div>
            <div className="col-3  list-group-item text-center mr-4 rounded p-3">
              <label>
                Ara Gecis Tarih:{" "}
                <input
                  type="date"
                  name="ara_gecis_tarih"
                  onChange={handleChange}
                  value={tourData.data.ara_gecis_tarih}
                />
              </label>
            </div>
            <div className="col-3  list-group-item text-center mr-2 rounded p-3">
              <label>
                Donus Tarih:{" "}
                <input
                  type="date"
                  name="donus_tarih"
                  onChange={handleChange}
                  value={tourData.data.donus_tarih}
                />
              </label>
            </div>
          </div>
          <div className="row justify-content-center mb-4">
            <div className="col-3  list-group-item text-center mr-2 rounded ">
              <input
                type="text"
                name="birinci_hotel_geceler_sayisi"
                placeholder="Birinci Hotel Geceler saysi"
                onChange={handleChange}
                value={tourData.data.birinci_hotel_geceler_sayisi}
              ></input>
            </div>
          </div>
          <div className="row justify-content-center mb-4">
            <div className="col-3  list-group-item text-center mr-2 rounded ">
              <input
                type="text"
                name="ikinci_hotel_geceler_sayisi"
                placeholder="Ikinci Hotel Geceler saysi"
                onChange={handleChange}
                value={tourData.data.ikinci_hotel_geceler_sayisi}
              ></input>
            </div>
          </div>
          {/*Select Price By Id */}
          <div className="mt-4 mb-4">
            <label htmlFor="price_id">Current Price:</label>
            <select id="price_id" name="price_id" onChange={handleChange}>
              <option value="">Select Current Price</option>
              {prices.map((price) => (
                <option key={price.id} value={price.id}>
                  {price.abbreviation}
                </option>
              ))}
            </select>
          </div>
          {/*Select Category By id*/}
          <label htmlFor="category_id">Category</label>
          <select id="category_id" name="category_id" onChange={handleChange}>
            <option value="">Select a category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.category}
              </option>
            ))}
          </select>
          {/** */}
          <div className="row justify-content-center">
            <div className="col-2 mr-2">
              <label>
                2 kişi fiyat
                <input
                  type="text"
                  name="iki_kisi_fiyat"
                  placeholder="2 kisi saısı "
                  className="p-2 rounded"
                  onChange={handleChange}
                  value={tourData.data.iki_kisi_fiyat}
                ></input>
              </label>
            </div>
            <div className="col-2 mr-2">
              <label>
                3 kişi fiyat
                <input
                  type="text"
                  name="uç_kisi_fiyat"
                  placeholder="3 kisi saısı "
                  className="p-2 rounded"
                  onChange={handleChange}
                  value={tourData.data.uç_kisi_fiyat}
                ></input>
              </label>
            </div>
            <div className="col-2 mr-2">
              <label>
                4 kişi fiyat
                <input
                  type="text"
                  name="dört_kisi_fiyat"
                  placeholder="4 kisi saısı "
                  className="p-2 rounded"
                  onChange={handleChange}
                  value={tourData.data.dört_kisi_fiyat}
                ></input>
              </label>
            </div>
            <div className="col-2 mr-2">
              <label>
                özel kisi saısı
                <input
                  type="text"
                  name="özel_kisi_saısı"
                  placeholder="özel kisi saısı "
                  className="p-2 rounded"
                  onChange={handleChange}
                  value={tourData.data.özel_kisi_saısı}
                ></input>
              </label>
            </div>
            <div className="col-2 mr-2">
              <label>
                özel fiyat
                <input
                  type="text"
                  name="özel_fiyat"
                  placeholder="özel fiyat "
                  className="p-2 rounded"
                  onChange={handleChange}
                  value={tourData.data.özel_fiyat}
                ></input>
              </label>
            </div>
          </div>
          {/******************************************************************************************* */}
          <div className="row justify-content-center">
            <div className="col-2 mr-2">
              <label>
                gidis_binis
                <input
                  type="text"
                  name="gidis_binis"
                  placeholder="gidis_binis "
                  className="p-2 rounded"
                  onChange={handleChange}
                  value={tourData.data.gidis_binis}
                ></input>
              </label>
            </div>
            <div className="col-2 mr-2">
              <label>
                gidis_inis
                <input
                  type="text"
                  name="gidis_inis"
                  placeholder=" gidis_inis "
                  className="p-2 rounded"
                  onChange={handleChange}
                  value={tourData.data.gidis_inis}
                ></input>
              </label>
            </div>

            <div className="col-2 mr-2">
              <label>
                ara_gecis_binis
                <input
                  type="text"
                  name="ara_gecis_binis"
                  placeholder=" ara_gecis_binis"
                  className="p-2 rounded"
                  onChange={handleChange}
                  value={tourData.data.ara_gecis_binis}
                ></input>
              </label>
            </div>
            <div className="col-2 mr-2">
              <label>
                ara_gecis_inis
                <input
                  type="text"
                  name="ara_gecis_inis"
                  placeholder="ara_gecis_inis "
                  className="p-2 rounded"
                  onChange={handleChange}
                  value={tourData.data.ara_gecis_inis}
                ></input>
              </label>
            </div>
            <div className="col-2 mr-2">
              <label>
                donus_binis
                <input
                  type="text"
                  name="donus_binis"
                  placeholder="donus_binis"
                  className="p-2 rounded"
                  onChange={handleChange}
                  value={tourData.data.donus_binis}
                ></input>
              </label>
            </div>
            <div className="col-2 mr-2">
              <label>
                donus_inis
                <input
                  type="text"
                  name="donus_inis"
                  placeholder=" donus_inis"
                  className="p-2 rounded"
                  onChange={handleChange}
                  value={tourData.data.donus_inis}
                ></input>
              </label>
            </div>
          </div>
          {/******* First ******** */}
          <div className="mt-4 mb-4">
            <label htmlFor="otel_id">Hotel:</label>
            <select id="otel_id" name="otel_id" onChange={handleChange}>
              <option value="">Select hotel</option>
              {otels.map((otel) => (
                <option key={otel.id} value={otel.otel_id}>
                  {otel.otel_name}
                </option>
              ))}
            </select>
          </div>
          {/******* Second ******** */}
          <div className="mt-4 mb-4">
            <label htmlFor="otel_id2">Hotel:</label>
            <select id="otel_id2" name="otel_id2" onChange={handleChange}>
              <option value="">Select hotel</option>
              {otels.map((otel) => (
                <option key={otel.id} value={otel.otel_id}>
                  {otel.otel_name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* Repeat for other tour fields */}

        <button type="submit" className="btn btn-primary">
          Create Tour
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default CreateTours;
