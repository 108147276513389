import { Card, Collapse, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import CustomCollapse from "./CustomerCollapes";
export default ({ title, open, children, toggleCollapse }) => {
  return (
    <Card className="mb-2" style={{ borderRadius: "0" }}>
      <Card.Header
        onClick={toggleCollapse}
        className="d-flex justify-content-between align-items-center"
        style={{
          cursor: "pointer",
          borderBottom: "none",
          backgroundColor: "white",
        }}
      >
        <h6>{title}</h6>
        <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
      </Card.Header>
      <CustomCollapse isOpen={open} className="pl-3 pr-2">
        <div className="mt-1">{children}</div>
      </CustomCollapse>
    </Card>
  );
};
