import { useState } from "react";
import TableListComponent from "../CRUD/TableListComponent";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button, Form } from "react-bootstrap";
import RenderHTMLContent from "common/helpers/RenderHTMLContent";
export const IndexQA = ({
  data,
  deleteQuestionMutation,
  editQuestionMutation,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const columns = [
    { label: "Soru", field: "question" },
    { label: "Cevap", field: "answer" },
  ];
  const actions = [
    {
      variant: "warning",
      onClick: (item) => {
        setCurrentItem(item);
        setShowModal(true);
      },
      icon: faEdit,
      style: { marginRight: "10px" },
    },
    {
      variant: "danger",
      onClick: (item) => {
        if (window.confirm("Are you sure you want to delete this question?")) {
          deleteQuestionMutation.mutate(item.id);
        }
      },
      icon: faTrash,
      style: { marginRight: "10px" },
    },
  ];
  const handleSaveChanges = () => {
    if (currentItem) {
      editQuestionMutation.mutate({ id: currentItem.id, ...currentItem });
    }
    // After successful update:
    setShowModal(false);
  };
  return (
    <div>
      <TableListComponent columns={columns} actions={actions} data={data} />
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Question</Form.Label>
              <Form.Control
                type="text"
                value={currentItem?.question || ""}
                onChange={(e) =>
                  setCurrentItem({ ...currentItem, question: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Answer</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={currentItem?.answer || ""}
                onChange={(e) =>
                  setCurrentItem({ ...currentItem, answer: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default IndexQA;
