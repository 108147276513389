import { useState, useRef, useEffect } from "react";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Card, Dropdown, Form } from "react-bootstrap";
import { useQuery } from "react-query";
import { fetchFilterTours } from "common/Api/AdvertismentApi";
const selectData = [
  {
    name: "tourName",
    placeholder: "Tur Adı",
    options: [
      { value: "option1A", label: "Option 1A" },
      { value: "option2A", label: "Option 2A" },
    ],
  },
  {
    name: "dealer",
    placeholder: "Şirket",
    options: [
      { value: "option1A", label: "Option 1A" },
      { value: "option2A", label: "Option 2A" },
    ],
  },
  {
    name: "category",
    placeholder: "Kategori",
    options: [
      { value: "Hac", label: "Hac" },
      { value: "option2B", label: "Option 2B" },
    ],
  },
  {
    name: "departureCity",
    placeholder: "Kalkış Şehri",
    options: [
      { value: "option1B", label: "Option 1B" },
      { value: "option2B", label: "Option 2B" },
    ],
  },
  {
    name: "returnCity",
    placeholder: "Dönüş Şehri",
    options: [
      { value: "option1B", label: "Option 1B" },
      { value: "option2B", label: "Option 2B" },
    ],
  },
  {
    name: "otel",
    placeholder: "Otel",
    options: [
      { value: "option1B", label: "Option 1B" },
      { value: "option2B", label: "Option 2B" },
    ],
  },
  {
    name: "TransportCompany",
    placeholder: "Taşıma Şirketi",
    options: [
      { value: "option1B", label: "Option 1B" },
      { value: "option2B", label: "Option 2B" },
    ],
  },
  {
    name: "tripTitle",
    placeholder: "Trip Adı",
    options: [
      { value: "option1B", label: "Option 1B" },
      { value: "option2B", label: "Option 2B" },
    ],
  },
  {
    name: "season",
    placeholder: "Sezon",
    options: [
      { value: "option1B", label: "Option 1B" },
      { value: "option2B", label: "Option 2B" },
    ],
  },
];
const numberInputData = [
  { name: "childAge", placeholder: "Çocuk Yaşı" },
  { name: "capacity", placeholder: "Kapasite" },
  { name: "HowmanyDays", placeholder: "Kaç Gün" },
  { name: "ChildernCount", placeholder: "Çocuk Sayısı" },
  // ... more number input data objects
];

const dateInputData = [
  { name: "startDate", placeholder: "Başlangıç Tarihi" },
  { name: "endDate", placeholder: "Bitiş Tarihi" },
  { name: "publicationStartDate", placeholder: "Yayın Başlangıç Tarihi" },
  { name: "publicationEndDate", placeholder: "Yayın Bitiş Tarihi" },
  // ... more date input data objects
];
const booleanInputData = [
  { name: "intimateControl", label: "Intimate Control" },
  { name: "withAccommodation", label: "With Accommodation" },
  { name: "showOnWebsite", label: "Show On Website" },
  { name: "lock", label: "Lock" },
  { name: "visa", label: "Visa" },
  // ... Add other boolean fields similarly ...
];
export default ({
  handleInputChange,
  applyFilters,
  handleCheckboxChange,
  setFilters,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleClearFilter = () => {
    setFilters("");
  };
  useEffect(() => {
    // Step 2: Set up an event listener for clicks
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false); // Step 3: Determine click inside or outside
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Step 4: Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  return (
    <div ref={dropdownRef}>
      <Dropdown show={isDropdownOpen}>
        <Dropdown.Toggle
          onClick={toggleDropdown}
          style={{
            border: "none",
            backgroundColor: "#1cab1c",
            fontWeight: "700",
            borderRadius: "7px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
          id="dropdown-basic"
        >
          Gelişmiş Arama <FontAwesomeIcon icon={faFilter} className="ml-2" />
        </Dropdown.Toggle>

        <Dropdown.Menu
          className="dropdown-menu-custom"
          style={{
            maxHeight: "350px",
            overflowY: "auto",
            padding: "15px",
          }}
        >
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {selectData.map((data, idx) => (
              <Col
                md={4}
                xs={12}
                style={{ marginBottom: idx % 3 < 2 ? "20px" : "20px" }}
              >
                <Form.Control
                  as="select"
                  name={data.name}
                  onChange={handleInputChange} // Attach the change handler
                >
                  <option value="" hidden>
                    {data.placeholder}
                  </option>
                  {data.options.map((opt) => (
                    <option key={opt.value} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            ))}

            <Col md={4} xs={4} style={{ marginBottom: "20px" }}>
              <Form.Control
                min={0}
                type="number"
                name="minPrice"
                placeholder="Min Price"
                onChange={handleInputChange} // Attach the change handler
              />
            </Col>
            <Col md={4} xs={4} style={{ marginBottom: "20px" }}>
              <Form.Control
                min={0}
                type="number"
                name="maxPrice"
                placeholder="Max Price"
                onChange={handleInputChange} // Attach the change handler
              />
            </Col>

            <Col md={4} xs={4} style={{ marginBottom: "20px" }}>
              <Form.Control
                as="select"
                name="priceType"
                onChange={handleInputChange} // Attach the change handler
              >
                <option value="" hidden>
                  Price Type
                </option>
                <option value="TR">TR</option>
                <option value="USD">USD</option>
                <option value="Euo">Euo</option>
              </Form.Control>
            </Col>

            {numberInputData.map((data) => (
              <Col md={3} sx={3} style={{ marginBottom: "20px" }}>
                <Form.Control
                  min={0}
                  type="number"
                  name={data.name}
                  placeholder={data.placeholder}
                  onChange={handleInputChange} // Attach the change handler
                />
              </Col>
            ))}

            {dateInputData.map((data) => (
              <Col md={6} style={{ marginBottom: "20px" }}>
                <Form.Control
                  type="date"
                  name={data.name}
                  placeholder={data.placeholder}
                  onChange={handleInputChange} // Attach the change handler
                />
              </Col>
            ))}
            {booleanInputData.map((data, idx) => (
              <Col md={4} xs={6} style={{ marginBottom: "20px" }}>
                <Form.Group controlId={`formCheckbox-${data.name}`}>
                  <Form.Check
                    type="checkbox"
                    label={data.label}
                    name={data.name}
                    onChange={handleCheckboxChange}
                  />
                </Form.Group>
              </Col>
            ))}

            <Col md={6}>
              <button
                onClick={applyFilters}
                style={{
                  width: "100%",
                  color: "white",
                  marginTop: "7px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  border: "none",
                  backgroundColor: "rgb(9 149 193)",
                  marginLeft: "10px",
                  fontWeight: "700",
                  borderRadius: "7px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                Uygula
              </button>
            </Col>
            <Col md={6}>
              <button
                onClick={handleClearFilter}
                style={{
                  width: "100%",
                  color: "white",
                  marginTop: "7px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  border: "none",
                  backgroundColor: "rgb(84 99 104)",
                  marginLeft: "10px",
                  fontWeight: "700",
                  borderRadius: "7px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                Temizle
              </button>
            </Col>
          </Row>

          {/* Dropdown items here */}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
