import React, { useState, useEffect } from "react";
import SelectInput from "../Components/SelectInput";
import TextInput from "../Components/TextInput";
import DateInput from "../Components/DateInput";
import { axios } from "common/utils/axios";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { toast } from "react-toastify";
import { Col, Row, Form, Button } from "react-bootstrap";

export const EditCustomerMeetings = ({ lastMeetingId, setEditShowModal }) => {
  const { id, meetingId: routeMeetingId } = useParams();
  const meetingId =
    routeMeetingId && routeMeetingId !== "" ? routeMeetingId : lastMeetingId;
  const [customerMeetings, setCustomerMeetings] = useState({
    data: { MeetingDate: "", Direction: "", Note: "" },
  });
  const [meetingsDirection, setMeetingsDirection] = useState([]);

  useEffect(() => {
    axios
      .get(`/api/customers/${id}/meetings/${meetingId}`)
      .then((response) => setCustomerMeetings({ data: response.data.meeting }))
      .catch((error) => console.error(error));
  }, [id, meetingId]);

  useEffect(() => {
    axios
      .get("/api/diraction")
      .then((response) => setMeetingsDirection(response.data))
      .catch((error) => console.error(error));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerMeetings((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [name]: value,
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(`/api/customers-meetings/${id}`, customerMeetings.data)
      .then((res) => {
        console.log(res);
        toast.success("Meeting updated successfully!");
        setEditShowModal(false);
      })
      .catch((error) => {
        console.error(error);
        let errorMessage = error.response?.data?.message;
        if (!errorMessage) {
          errorMessage = "Something went wrong!";
        }

        toast.error(errorMessage);
      });
  };

  return (
    <div>
      <Form>
        <Row>
          <Col xs={6}>
            <Form.Group className="mb-3">
              <Form.Label>Müşteriler</Form.Label>
              <Form.Control
                type="text"
                value={id}
                readOnly // Assuming this is a read-only field based on your example
              />
            </Form.Group>
          </Col>

          <Col xs={6}>
            <Form.Group className="mb-3">
              <Form.Label>Yön</Form.Label>
              <Form.Select
                name="Direction"
                value={customerMeetings.data.Direction}
                onChange={handleChange}
                aria-label="Yön"
              >
                {meetingsDirection.map((item) => (
                  <option key={item.name} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Tarihi Ve Saat</Form.Label>
              <Form.Control
                type="datetime-local"
                name="MeetingDate"
                value={customerMeetings.data.MeetingDate}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>

          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Not</Form.Label>
              <Form.Control
                as="textarea"
                name="Note"
                value={customerMeetings.data.Note}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>

          <Col xs={12}>
            <Button variant="primary" onClick={handleSubmit}>
              Ekle
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default EditCustomerMeetings;
