import { axios } from "common/utils/axios";

export const getCompanies = () =>
  axios.get(`api/company`).then((response) => response.data);
export const getCompany = (id) => axios.get(`api/company/${id}`);
export const createCompany = (data) => axios.post(`api/company`, data);
export const updateCompany = ({ id, ...data }) =>
  axios.put(`api/company/${id}`, data);

export const deleteCompany = (id) => axios.delete(`api/company/${id}`);
