import { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { motion } from "framer-motion";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "..//misc/Headings.js";
import {
  Container as OriginalContainer,
  ContentWithPaddingXl,
} from "../misc/Layouts.js";
import { useQuery } from "react-query";
import * as api from "common/Api/BlogApi";
import { Link } from "react-router-dom";

const Row = tw.div`flex flex-col lg:flex-row -mb-10`;
const Heading = tw(SectionHeading)`text-left lg:text-4xl xl:text-5xl`;
const PopularPostsContainer = tw.div`w-full lg:w-full xl:w-full`;

const Image = styled(motion.div)((props) => [
  `background-size: cover;`,
  `background-image: url("${props.$imageSrc}");`,
  tw`h-64 bg-cover bg-center rounded`,
  `background-repeat: no-repeat;`, // This prevents the image from repeating
]);

const Title = styled.h5`
  ${tw`mt-6 text-xl font-bold transition duration-300`}
  &:hover {
    color: rgb(0, 109, 119);
  }
`;
const Description = tw.p`mt-2 font-medium text-secondary-100 leading-loose text-sm`;

const ContainerBox = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 3rem;

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
  }

  @media (min-width: 641px) and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const PostBox = styled(motion.a)`
  box-sizing: border-box;
  cursor: pointer;
`;

// Background Pattern
const BackgroundPattern = styled.div`
  ${tw`absolute inset-0 pointer-events-none`}
  background-image: 
    repeating-linear-gradient(
      45deg,
      transparent,
      transparent 10px,
      rgba(255,255,255,0.1) 10px,
      rgba(255,255,255,0.1) 20px
    );
  z-index: -1;
`;

const FloatingSymbol1 = styled.svg`
  ${tw`absolute w-24 h-24 text-primary-500 opacity-10`}
  top: 10%;
  left: 5%;
`;

const FloatingSymbol2 = styled(FloatingSymbol1)`
  top: 60%;
  left: 70%;
  transform: rotate(45deg);
`;

// Enhanced Container
const Container = styled(OriginalContainer)`
  ${tw`relative`}
`;

export default () => {
  const [posts, setPosts] = useState([]);
  const { data, isLoading, isError, refetch } = useQuery(
    "posts",
    api.getLast3Posts,
    {
      enabled: true,
      onSuccess: (data) => {
        setPosts(data);
      },
    }
  );

  const postBackgroundSizeAnimation = {
    rest: {
      backgroundSize: "100%",
    },
    hover: {
      backgroundSize: "110%",
    },
  };

  if (isError) {
    return <div>Error fetching posts...</div>;
  }

  if (isLoading) {
    return <div>loading......</div>;
  }

  return (
    <div style={{ marginTop: "75px" }}>
      <Container>
        <BackgroundPattern />
        <FloatingSymbol1 viewBox="0 0 24 24">
          <path d="..." /> {/* SVG path data */}
        </FloatingSymbol1>
        <FloatingSymbol2 viewBox="0 0 24 24">
          <path d="..." /> {/* SVG path data */}
        </FloatingSymbol2>
        <ContentWithPaddingXl>
          <Row style={{ marginBottom: "35px" }}>
            <PopularPostsContainer>
              <Heading>Popüler Gönderiler</Heading>
              <ContainerBox>
                {posts.map((post, index) => (
                  <Link to={`blog/post/${post.slug}`} key={index}>
                    <PostBox
                      href={post.url}
                      className="group"
                      initial="rest"
                      whileHover="hover"
                      animate="rest"
                    >
                      <Image
                        transition={{ duration: 0.3 }}
                        variants={postBackgroundSizeAnimation}
                        $imageSrc={post.image}
                      />
                      <Title>{post.title}</Title>
                      <Description>{post.description}</Description>
                    </PostBox>
                  </Link>
                ))}
              </ContainerBox>
            </PopularPostsContainer>
          </Row>
        </ContentWithPaddingXl>
      </Container>
    </div>
  );
};
