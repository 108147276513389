import React, { useState, useEffect, Suspense } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button } from "react-bootstrap";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import DialogTitle from "@mui/material/DialogTitle";
import { axios } from "common/utils/axios";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import DialogContentText from "@mui/material/DialogContentText";
import SelectInput from "../../CustomerMangement/ServiceCustomersComponents/Components/SelectInput";

export const PopUpReserversationTours = ({ handleSaveTours }) => {
  const [selectedTour, setSelectedTour] = useState(null);
  const [selectedTourAndPrice, setSelectedTourAndPrice] = useState(null);
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [toursName, setToursName] = useState([]);
  const [toursData, setToursData] = useState([]);
  const [selectedTours, setSelectedTours] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [value, setValue] = useState("1");

  const [tours, setTours] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    axios
      .get("api/get-active-tours")
      .then((response) => setToursData(response.data))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get("/api/categories");
      setCategories(response.data);
      // Optionally, select the first category by default
      if (response.data.length > 0) {
        setSelectedCategory(response.data[0].slug);
        fetchToursBasedOnCategory(response.data[0].slug);
      }
    } catch (error) {
      console.error("Error fetching categories", error);
    }
  };
  const fetchToursBasedOnCategory = async (slug) => {
    try {
      const response = await axios.get(
        `/api/get-active-tours?category=${slug}`
      );
      setTours(response.data);
    } catch (error) {
      console.error("Error fetching tours based on category", error);
    }
  };

  const handleChangeCategory = (event, newValue) => {
    const selectedCat = categories.find(
      (cat) => cat.id.toString() === newValue
    );
    if (selectedCat) {
      setSelectedCategory(selectedCat.slug);
      fetchToursBasedOnCategory(selectedCat.slug);
    }
  };
  const fetchTours = async (slug) => {
    try {
      const response = await axios.get(`/api/categories/${slug}/tours`);
      setToursData(response.data);
    } catch (error) {
      console.error("Error fetching tours", error);
    }
  };

  const handleChangeMenuTab = (event, newValue) => {
    setValue(newValue);
    const categorySlug = categories.find(
      (category) => category.id.toString() === newValue
    ).slug;
    fetchTours(categorySlug);
  };

  const handleTourSelection = (event) => {
    const selectedTourId = Number(event.target.value);
    const selectedTourData = toursData.find(
      (tour) => tour.tourId === selectedTourId
    );

    if (selectedTourData) {
      setSelectedTour(selectedTourData); // Assuming setSelectedTour is a state setter for the whole selected tour object
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const saveInterestedAdvertisements2 = () => {
    handleSaveTours(selectedTour); // Assuming selectedTour is the state holding the full selected tour object
    handleClose();
  };

  useEffect(() => {
    fetchCustomerTours();
  }, []);

  const fetchCustomerTours = async () => {
    try {
      const response = await axios.get(`/api/customer/${id}/tours`);
      const tourAndPriceIds = response.data.tours.map((tour) => {
        return {
          tourName: tour.title,
          tourId: tour.id,
          priceId: tour.price_id,
          tourDate: tour.gidis_tarih,
        };
      });
      setSelectedTours(tourAndPriceIds);
    } catch (error) {
      console.error("Error fetching customer's tours", error);
    }
  };
  console.log(toursData, "toursData");
  console.log(selectedCategory, "toursData");
  return (
    <div>
      <Button
        variant="warning"
        onClick={handleClickOpen}
        style={{ marginTop: "32px", fontSize: "13px" }}
      >
        Tur seç
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Select Tours</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select the tours that the customer is interested in.
          </DialogContentText>
          <TabContext value={selectedCategory}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChangeCategory}
                aria-label="Tour categories"
              >
                {categories.map((category) => (
                  <Tab
                    label={category.category}
                    value={category.id.toString()}
                    key={category.id}
                  />
                ))}
              </TabList>
            </Box>
            {tours.map((tour) => (
              <TabPanel value={selectedCategory} key={tour.tourId}>
                <div>
                  <input
                    className="mr-2"
                    type="radio"
                    name="tourSelection"
                    value={tour.tourId}
                    onChange={handleTourSelection}
                  />
                  {tour.tourName}
                </div>
              </TabPanel>
            ))}
          </TabContext>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={saveInterestedAdvertisements2}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default PopUpReserversationTours;
