import { useState, useEffect } from "react";
import React from "react";
import { Card, Button, Collapse } from "react-bootstrap";

export const TourDetilsButtomSection = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [openItem, setOpenItem] = useState(null);

  const handleItemClick = (itemIndex) => {
    setOpenItem(itemIndex === openItem ? null : itemIndex);
  };

  return (
    <div className={"col-md-6 col-lg-3 hotel-detail-block pl-0 pr-0"}>
      {/* <div id="accordion"> */}
      <Card>
        <Button
          onClick={() => handleItemClick(0)}
          style={{ marginBottom: "1rem" }}
          block
        >
          Detaylar
        </Button>
        <Collapse in={openItem === 0} key={0}>
          <Card>
            <Card.Body>Content for item 1</Card.Body>
          </Card>
        </Collapse>
      </Card>
      <Card>
        <Button
          onClick={() => handleItemClick(1)}
          style={{ marginBottom: "1rem" }}
          block
        >
          Ulaşım ve Konaklama
        </Button>
        <Collapse in={openItem === 1} key={1}>
          <Card>
            <Card.Body>Content for item 2</Card.Body>
          </Card>
        </Collapse>
      </Card>
      <Card>
        <Button
          onClick={() => handleItemClick(2)}
          style={{ marginBottom: "1rem" }}
          block
        >
          Tur Programı
        </Button>
        <Collapse in={openItem === 2} key={2}>
          <Card>
            <Card.Body>Content for item 3</Card.Body>
          </Card>
        </Collapse>
      </Card>
      <Card>
        <Button
          onClick={() => handleItemClick(3)}
          style={{ marginBottom: "1rem" }}
          block
        >
          Tur Yorumları
        </Button>
        <Collapse in={openItem === 3} key={3}>
          <Card>
            <Card.Body>Content for item 4</Card.Body>
          </Card>
        </Collapse>
      </Card>
      <Card>
        <Button
          onClick={() => handleItemClick(4)}
          style={{ marginBottom: "1rem" }}
          block
        >
          Oda Fiyatları
        </Button>
        <Collapse in={openItem === 4} key={4}>
          <Card>
            <Card.Body>Content for item 5</Card.Body>
          </Card>
        </Collapse>
      </Card>
      <Card>
        <Button
          onClick={() => handleItemClick(5)}
          style={{ marginBottom: "1rem" }}
          block
        >
          Tüm Tarihler
        </Button>
        <Collapse in={openItem === 5} key={5}>
          <Card>
            <Card.Body>Content for item 6</Card.Body>
          </Card>
        </Collapse>
      </Card>

      <div className="col-md-12 alert alert-secondary text-center">
        <h4 className="Gun-sayisi">
          Gündüz Sayısı : <b>43 Gün</b>
        </h4>
      </div>
      <div className="col-md-12 alert alert-secondary text-center">
        <h4 className="Gun-sayisi">
          Gece Sayısı : <b>43 Gün</b>
        </h4>
      </div>
    </div>
  );
};
export default TourDetilsButtomSection;
