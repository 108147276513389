import React from "react";

export default function OtelVideoComponent({setVideo, video}) {
	const videoHandle = (e) => {
		setVideo(e.target.value);
	};
	return (
		<div>
			<label>Video </label>
			<br />
			<input
				type="text"
				value={video}
				onChange={videoHandle}
			/>
			<br />
		</div>
	);
}
