import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SideBarDateSection from "./SideBarDateSection";
import SideBareAdultSection from "./SideBareAdultSection";
import SideBarChildSection from "./SideBarChildSection";
import SideBarButtons from "./SideBarButtons";
import UseTourDetails from "../../../../../common/utils/functions/UseTourDetails";
const TourDetailsSideBar = ({
  adultCount,
  onAdultCountChange,
  onRoomDetailsClick,
}) => {
  /********** Adult Counter ********** */
  // const [adultCount, setAdultCount] = useState(2);
  // adultCountP = adultCount;
  // const handleAdultCountChange = (count) => {
  //   setAdultCount(count);
  // };

  /****  Start Reseve Data From Database   **** */
  const { id } = useParams();
  const tour = UseTourDetails(id);
  if (!tour) {
    return <div>Loading...</div>;
  }
  /****  End Reseve Data From Database   **** */

  if (!tour) {
    return <div>Loading...</div>;
  }

  return (
    <div className="col-lg-4 col-xl-3 col-md-6 pl-0 pr-0 hotel-left">
      <form>
        <div className="detail-left-content" id="reZContent">
          <div className="reservation text-center">
            <p className="hotel-name text-center py-4">{tour?.title}</p>
            <SideBarDateSection />
            <SideBareAdultSection
              adultCount={adultCount}
              onAdultCountChange={onAdultCountChange}
            />
            <SideBarChildSection />
            {/*Here must add the Hide Prop */}
            <SideBarButtons onRoomDetailsClick={onRoomDetailsClick} />
          </div>
        </div>
      </form>
    </div>
  );
};
export default TourDetailsSideBar;
