import { useState } from "react";
import { Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const AvatarImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
`;

function TableListComponent({ isRenderHtml, data, columns, actions }) {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [expandedItems, setExpandedItems] = useState({});
  const handleShowModal = (content) => {
    setModalContent(content);
    setShowModal(true);
  };
  const handleExpandText = (index) => {
    setExpandedItems((prev) => ({ ...prev, [index]: !prev[index] })); // Toggle expand/collapse
  };
  return (
    <>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{column.label}</th>
            ))}
            {actions && <th>Eylem</th>}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => (
              <tr key={index}>
                {columns.map((column) => {
                  if (column.field === "image") {
                    return (
                      <td key={column.field}>
                        <img
                          src={item[column.field]}
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                          }}
                          alt="avatar"
                        />
                      </td>
                    );
                  } else if (column.field === "avatar") {
                    return (
                      <td key={column.field}>
                        <img
                          src={item[column.field]}
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                          }}
                          alt="avatar"
                        />
                      </td>
                    );
                  } else if (column.field === "logo") {
                    return (
                      <td key={column.field}>
                        <img
                          src={item[column.field]}
                          style={{
                            width: "80px",
                            height: "50px",
                            objectFit: "",
                          }}
                          alt="avatar"
                        />
                      </td>
                    );
                  } else if (
                    column.field === "text" &&
                    item[column.field].length > 300 &&
                    !expandedItems[index]
                  ) {
                    return (
                      <td key={column.field}>
                        {item[column.field].substring(0, 300)}...
                        <Button
                          size="sm"
                          onClick={() => handleExpandText(index)}
                        >
                          See More
                        </Button>
                      </td>
                    );
                  } else if (column.field === "gender") {
                    // Handling gender field specifically
                    return (
                      <td key={column.field}>
                        {item[column.field] === 0
                          ? "E"
                          : item[column.field] === 1
                          ? "K"
                          : item[column.field]}
                      </td>
                    );
                  } else {
                    // Default rendering for other fields
                    return <td key={column.field}>{item[column.field]}</td>;
                  }
                })}
                {actions && (
                  <td>
                    {actions.map((action, actionIndex) => (
                      <Button
                        className="ml-2"
                        variant={action.variant}
                        key={actionIndex}
                        style={action.style}
                        onClick={() => action.onClick(item)}
                      >
                        {action.icon && <FontAwesomeIcon icon={action.icon} />}
                        {action.label}
                      </Button>
                    ))}
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </Table>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TableListComponent;
