import { useQuery } from "react-query";
import { axios } from "common/utils/axios";
export const useLastMeetingShow = (lastMeetingId) => {
  const fetchLastMeeting = async (id) => {
    const response = await axios.get(`api/meetings/${id}`);
    return response.data;
  };
  const {
    data: lastMeeting,
    error,
    isLoading,
  } = useQuery(
    ["lastMeeting", lastMeetingId],
    () => fetchLastMeeting(lastMeetingId),
    {
      enabled: !!lastMeetingId, // Only run the query if the `lastMeetingId` exists
    }
  );

  return {
    lastMeeting,
    error,
    isLoading,
  };
};
