import { axios } from "common/utils/axios";

export const FetchCustomerTours = async ({ queryKey }) => {
  const customerId = queryKey[1];
  const response = await axios.get(`/api/customer/${customerId}/tours`);
  return response.data.tours;
};

export const getCustomerData = (id) => {
  return axios.get(`api/customer-data/${id}`).then((response) => {
    console.log("Response data:", response);
    return response.data;
  });
};
