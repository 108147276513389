import React, { useState } from "react";
import { Table, Form, Button, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import "assets/styles/TourPrice.css";
export const NewPricing = ({
  formData,
  setFormData,
  updatePriceData,
  updateInstallmentsData,
}) => {
  const [newPeopleCount, setNewPeopleCount] = useState("");
  const [showInstallments, setShowInstallments] = useState(false);
  const addNewPriceData = (newPrice) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        pricing: [newPrice, ...prevFormData.pricing],
      };
    });
  };
  const addPriceRow = () => {
    if (!newPeopleCount) {
      toast.warn("Yeni fiyat için kişi sayısını giriniz.");
      return;
    }
    if (formData?.pricing?.length >= 3) {
      toast.warn("En fazla 3 fiyat satırı ekleyebilirsiniz");
      return;
    }
    const newPrice = {
      id: Date.now(),
      howManyPeople: newPeopleCount,
      mainPrice: "",
      pricingType: "TRY",
      description: "",
      discountPrice: "",
      issuedOptionPrice: "",
      addedOptionPrice: "",
      installments: [{ Months: "", installmentPrice: "" }],
    };
    addNewPriceData(newPrice);
    setNewPeopleCount("");
  };

  const handlePriceChange = (e, index, field) => {
    const updatedPrice = {
      ...formData.pricing[index],
      [field]: e.target.value,
    };
    updatePriceData(index, updatedPrice);
  };

  const handleInstallmentChange = (e, priceIndex, installmentId, field) => {
    // Map through the pricing array
    const newPricing = formData.pricing.map((price, idx) => {
      if (idx === priceIndex) {
        // Map through the installments of the matched price
        const updatedInstallments = price.installments.map((installment) => {
          if (installment.id === installmentId) {
            return { ...installment, [field]: e.target.value };
          }
          return installment;
        });

        // Return the updated price object
        return { ...price, installments: updatedInstallments };
      }
      return price;
    });

    // Update the state
    setFormData({ ...formData, pricing: newPricing });
  };

  const addInstallmentRow = (priceIndex) => {
    setFormData((prevFormData) => {
      const newPricing = prevFormData.pricing.map((price, idx) => {
        if (idx === priceIndex) {
          return {
            ...price,
            // Fixed: Ensuring unique keys for installments by using Date.now()
            installments: [
              ...price.installments,
              { Months: "", installmentPrice: "", id: Date.now() },
            ],
          };
        }
        return price;
      });

      return { ...prevFormData, pricing: newPricing };
    });
  };

  const removeInstallmentRow = (priceIndex, installmentId) => {
    const updatedInstallments = formData.pricing[
      priceIndex
    ].installments.filter((installment) => installment.id !== installmentId);
    updateInstallmentsData(priceIndex, updatedInstallments);
  };
  const removePriceRow = (priceId) => {
    const updatedPrices = formData.pricing.filter(
      (price) => price.id !== priceId
    );
    setFormData({ ...formData, pricing: updatedPrices });
  };

  return (
    <div>
      <h5>Fiyatlandırma</h5>
      <Row className="mb-3">
        <Col xl={6} xs={6}>
          <Form.Label>Kaç Kişi:</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter number of people"
            value={newPeopleCount}
            onChange={(e) => setNewPeopleCount(e.target.value)}
          />
        </Col>
        <Col xl={6} xs={6} className="d-flex align-items-end">
          <Button className="mt-2" variant="success" onClick={addPriceRow}>
            <FontAwesomeIcon icon={faPlus} /> Yeni Fiyat Ekle
          </Button>
        </Col>
      </Row>
      <Table responsive>
        <thead>
          <tr>
            <th className="narrow-column">Kaç Kişi</th>
            <th className="narrow-column">Fiyat</th>
            <th className="wide-column">Para Turu</th>
            <th className="narrow-column">İndirimli</th>
            <th className="narrow-column">Verilen Opsiyon</th>
            <th className="narrow-column">Eklenen Opsiyon</th>
            <th className="wide-column">Taksitler</th>
            <th className="narrow-column">Eylem</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(formData.pricing) &&
            formData.pricing.map((price, index) => (
              <tr key={price.id}>
                <td>
                  <Form.Control
                    type="number"
                    value={price.howManyPeople}
                    onChange={(e) =>
                      handlePriceChange(e, index, "howManyPeople")
                    }
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    value={price.mainPrice}
                    onChange={(e) => handlePriceChange(e, index, "mainPrice")}
                  />
                </td>
                <td>
                  <Form.Select
                    value={price.pricingType}
                    onChange={(e) => handlePriceChange(e, index, "pricingType")}
                  >
                    <option value="TRY">TRY</option>
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                  </Form.Select>
                </td>
                <td>
                  <Form.Control
                    type="number"
                    value={price.discountPrice}
                    onChange={(e) =>
                      handlePriceChange(e, index, "discountPrice")
                    }
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    value={price.issuedOptionPrice}
                    onChange={(e) =>
                      handlePriceChange(e, index, "issuedOptionPrice")
                    }
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    value={price.addedOptionPrice}
                    onChange={(e) =>
                      handlePriceChange(e, index, "addedOptionPrice")
                    }
                  />
                </td>
                <td>
                  <Button
                    className="mb-2"
                    variant="primary"
                    onClick={() => addInstallmentRow(index)}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Add Installment
                  </Button>
                  {price.installments.map((installment) => (
                    <div key={installment.id}>
                      <Row>
                        <Col>
                          <Form.Control
                            min={1}
                            max={48}
                            type="number"
                            placeholder="Ay"
                            value={installment.Months}
                            onChange={(e) =>
                              handleInstallmentChange(
                                e,
                                index,
                                installment.id,
                                "Months"
                              )
                            }
                          />
                        </Col>
                        <Col className="mb-2">
                          <Form.Control
                            min={1}
                            type="number"
                            placeholder="Taksit"
                            value={installment.installmentPrice}
                            onChange={(e) =>
                              handleInstallmentChange(
                                e,
                                index,
                                installment.id,
                                "installmentPrice"
                              )
                            }
                          />
                        </Col>
                      </Row>
                      <Button
                        variant="danger"
                        onClick={() =>
                          removeInstallmentRow(index, installment.id)
                        }
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </div>
                  ))}
                </td>
                <td>
                  <Button
                    variant="danger"
                    onClick={() => removePriceRow(price.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default NewPricing;
