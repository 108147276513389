import React, { useState, useEffect } from "react";
import { axios } from "common/utils/axios";

export const RatingStars = ({ customerData, effectiveCustomerData }) => {
  const [rating, setRating] = useState(
    (effectiveCustomerData && effectiveCustomerData.StarsLevel) || 0
  );

  useEffect(() => {
    setRating((effectiveCustomerData && effectiveCustomerData.StarsLevel) || 0);
  }, [effectiveCustomerData && effectiveCustomerData.StarsLevel]);

  function handleRatingClick(newRating) {
    setRating(newRating);
    updateCustomerRating(newRating);
  }

  const updateCustomerRating = async (newRating) => {
    try {
      await axios.put(`/api/customers/${effectiveCustomerData.id}/rate`, {
        StarsLevel: newRating,
      });
      console.log("Rating updated successfully!");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <p className="mb-0 mt-3">YILDIZLAMA</p>
      {[1, 2, 3, 4, 5].map((value) => (
        <span
          key={value}
          onClick={() => handleRatingClick(value)}
          style={{
            fontSize: "25px",
            cursor: "pointer",
            color: value <= rating ? "gold" : "gray",
          }}
        >
          &#9733;
        </span>
      ))}
    </div>
  );
};
export default RatingStars;
