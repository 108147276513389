import React from "react";
import Form from "react-bootstrap/Form";

export const TextInput = (props) => {
  return (
    <div>
      <Form.Label htmlFor="inputPassword5">{props.label}</Form.Label>
      <Form.Control
        type="text"
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled} // Use the disabled prop here
      />
    </div>
  );
};

// Set the default value for disabled to true
TextInput.defaultProps = {
  disabled: false,
};

export default TextInput;
