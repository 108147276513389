export const mapMonthNameToNumber = (monthName) => {
  const monthMappings = {
    Ocak: 1,
    Şubat: 2,
    Mart: 3,
    Nisan: 4,
    Mayıs: 5,
    Haziran: 6,
    Temmuz: 7,
    Ağustos: 8,
    Eylül: 9,
    Ekim: 10,
    Kasım: 11,
    Aralık: 12,
  };
  return monthMappings[monthName];
};
