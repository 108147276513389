import React from "react";

export const TotalFee = () => {
  return (
    <div>
      <div className="pull-right price-alan p2 mt-4">
        <strong>Toplam Ücret: </strong>{" "}
        <span data-price="4400.00" data-currency="$">
          4.400,00 $
        </span>
      </div>
      <div className="rez-buttonlar">
        {/* <button type="submit" className="btn btn-primary btn-sm float-right">
          <span className="glyphicon glyphicon-chevron-right"></span> İleri
        </button> */}
        <div className="clearfix"></div>
      </div>
    </div>
  );
};
export default TotalFee;
