import { useContext } from "react";
import { Row, Col, Form } from "react-bootstrap";
import LineAdvance from "../../../CRUD/LineAdvance";
import { CategoriesContext } from "redux/ContextApi/Contexts";
import "assets/styles/AdvertismentManger.css";
import ToggleSwitch from "area/admin/CRUD/RadioButtonAdvance";
import { getTodayDateString } from "common/helpers/getTodayDateString";
import { getCompanies } from "common/Api/CompaniesApi";
import { useQuery } from "react-query";
export const TourSection = ({ handleChange, formData }) => {
  const {
    data: companies,
    isLoadingCompanies,
    isError,
  } = useQuery("Companies", getCompanies, {
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
  const localHandleChange = (e) => {
    const { name, value } = e.target;
    let finalValue = value;
    const newFormData = { ...formData, [name]: finalValue };
    handleChange(newFormData);
  };

  const { categories, isLoading } = useContext(CategoriesContext);
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (!categories) {
    return <div>No categories available</div>;
  }
  console.log(categories, "categories");
  return (
    <div>
      <h5>Tur</h5>
      <hr />
      <Row>
        <Col md={3}>
          <Form.Group controlId="turAdi">
            <Form.Label>Tur Adı</Form.Label>
            <Form.Control
              value={formData?.tourName}
              onChange={localHandleChange}
              name="tourName"
              type="text"
              placeholder="Enter Tur Adı"
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group controlId="companyId">
            <Form.Label>Şirket</Form.Label>
            <Form.Control
              as="select"
              name="companyId"
              value={formData?.companyId}
              onChange={localHandleChange}
            >
              {companies &&
                companies.map((company) => (
                  <option key={company.companyId} value={company.companyId}>
                    {company.companyName}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group controlId="category">
            <Form.Label>kategori</Form.Label>

            <Form.Select
              value={formData?.category}
              name="category"
              onChange={localHandleChange}
            >
              {categories &&
                categories.map((category) => (
                  <option key={category.id} value={category.category}>
                    {category.category}
                  </option>
                ))}

              <option value="" hidden>
                Ketegori seç
              </option>
            </Form.Select>
          </Form.Group>
        </Col>

        <LineAdvance />
        <Col md={3}>
          <Form.Group controlId="gun">
            <Form.Label>Gün</Form.Label>
            <Form.Control
              min={1}
              value={formData?.days}
              onChange={localHandleChange}
              name="days"
              type="number"
              placeholder="Enter Gün"
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group controlId="gece">
            <Form.Label>Gece</Form.Label>
            <Form.Control
              min={0}
              value={formData?.nights}
              onChange={localHandleChange}
              name="nights"
              type="number"
              placeholder="Enter Gece"
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group controlId="baslangicTarihi">
            <Form.Label>Başlangıç Tarihi</Form.Label>
            <Form.Control
              value={formData?.startingDate}
              name="startingDate"
              onChange={localHandleChange}
              type="date"
              min={getTodayDateString()}
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group controlId="bitisTarihi">
            <Form.Label>Bitiş Tarihi</Form.Label>
            <Form.Control
              value={formData?.endingDate}
              name="endingDate"
              onChange={localHandleChange}
              type="date"
              min={formData?.startingDate || getTodayDateString()}
            />
          </Form.Group>
        </Col>
        {/*Todo Add kategori  */}
        <LineAdvance />
        <Col md={3}>
          <Form.Group controlId="hareketSehiri">
            <Form.Label>Gidiş Şehiri</Form.Label>
            <Form.Select
              value={formData?.departureCity}
              name="departureCity"
              onChange={localHandleChange}
            >
              <option value="" hidden>
                Gidiş Şehiri seç
              </option>
              <option>Şehir A</option>
              <option>Şehir B</option>
            </Form.Select>
          </Form.Group>
        </Col>
        {/*Todo  Adding ara parkur */}
        <Col md={3}>
          <Form.Group controlId="hareketSehiri">
            <Form.Label>dönüş Şehiri</Form.Label>
            <Form.Select
              value={formData?.returnCity}
              name="returnCity"
              onChange={localHandleChange}
            >
              <option value="" hidden>
                dönüş Şehiri seç
              </option>
              <option>Şehir A</option>
              <option>Şehir B</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group controlId="yayinTarihi">
            <Form.Label>Yayın Tarihi</Form.Label>
            <Form.Control
              value={formData?.publicationDate}
              name="publicationDate"
              onChange={localHandleChange}
              type="date"
              min={getTodayDateString()}
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group controlId="yayinBitis">
            <Form.Label>Yayın Bitiş</Form.Label>
            <Form.Control
              value={formData?.publicationEndDate}
              name="publicationEndDate"
              onChange={localHandleChange}
              type="date"
              min={formData?.publicationDate || getTodayDateString()}
            />
          </Form.Group>
        </Col>
        <LineAdvance />
        <Col md={3}>
          <Form.Group controlId="kapasite">
            <Form.Label>Kapasite</Form.Label>
            <Form.Control
              min={0}
              value={formData?.capacity}
              name="capacity"
              onChange={localHandleChange}
              type="number"
              placeholder="Enter Kapasite"
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group controlId="sezon">
            <Form.Label>Sezon</Form.Label>
            <Form.Select
              value={formData?.season}
              name="season"
              onChange={localHandleChange}
            >
              <option value="" hidden>
                Sezon seç
              </option>
              <option>Yaz</option>
              <option>Kış</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group controlId="tags">
            <Form.Label>Çocuk Yaşı</Form.Label>
            <Form.Control
              min={0}
              value={formData?.childAge}
              name="childAge"
              onChange={localHandleChange}
              type="number"
              placeholder="Enter Çocuk Yaşı"
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group controlId="childs">
            <Form.Label>Çocuk Sayisi</Form.Label>
            <Form.Control
              min={0}
              value={formData?.childs}
              name="childs"
              onChange={localHandleChange}
              type="number"
              placeholder="Çocuk Sayisi"
            />
          </Form.Group>
        </Col>
        <LineAdvance />

        <Col md={3} className="mt-4">
          <ToggleSwitch
            name="intimateControl"
            id="intimateControlToggle"
            label="Intimate Control"
            checked={formData?.intimateControl}
            onChange={(e) =>
              localHandleChange({
                target: {
                  name: e.target.name,
                  value: e.target.checked, // or any other values you use to represent the toggle state
                },
              })
            }
          />
        </Col>
        <Col md={3} className="mt-4">
          <ToggleSwitch
            name="withAccommodation"
            id="withAccommodationToggle"
            label="With Accommodation"
            checked={formData?.withAccommodation}
            onChange={(e) =>
              localHandleChange({
                target: {
                  name: e.target.name,
                  value: e.target.checked, // or any other values you use to represent the toggle state
                },
              })
            }
          />
        </Col>
        <Col md={3} className="mt-4">
          <ToggleSwitch
            name="visa"
            id="visaToggle"
            label="visa"
            checked={formData?.visa}
            onChange={(e) =>
              localHandleChange({
                target: {
                  name: e.target.name,
                  value: e.target.checked, // or any other values you use to represent the toggle state
                },
              })
            }
          />
        </Col>
        <Col md={3} className="mt-4">
          <ToggleSwitch
            name="lock"
            id="lockToggle"
            label="lock"
            checked={formData?.lock}
            onChange={(e) =>
              localHandleChange({
                target: {
                  name: e.target.name,
                  value: e.target.checked, // or any other values you use to represent the toggle state
                },
              })
            }
          />
        </Col>
        <Col md={3} className="mt-4">
          <ToggleSwitch
            name="showOnWebsite"
            id="showOnWebsiteToggle"
            label="Show On Website"
            checked={formData?.showOnWebsite}
            onChange={(e) =>
              localHandleChange({
                target: {
                  name: e.target.name,
                  value: e.target.checked, // or any other values you use to represent the toggle state
                },
              })
            }
          />
        </Col>
        <LineAdvance />
      </Row>
    </div>
  );
};
export default TourSection;
