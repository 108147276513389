import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tab, Tabs, Row, Col, Container, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "assets/icons/logo.png";
// import Header from "area/user/Public/components/UserHeader";
import UserProfile from "area/user/Dashbord/UserProfile";
import UserFamily from "area/user/Dashbord/UserFamily";
import Footer from "common/components/footers/FiveColumnWithInputForm";
import UserAdress from "area/user/Dashbord/UserAdress";
import UserHelp from "area/user/Dashbord/UserHelp";
import UserReservation from "area/user/Dashbord/UserReservation";
import UserDetails from "area/user/Dashbord/UserDetails";
import UserHelpp from "area/user/Dashbord/UserHelpp";
import UserOffers from "area/user/Dashbord/UserOffers";
import Header from "common/components/headers/userHeader";
import { axios } from "common/utils/axios";
import { useQuery } from "react-query";
import { getCookie } from "common/helpers/getCookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faHome,
  faAddressCard,
  faCalendarAlt,
  faGift,
  faHeadset,
  faMobile,
  faMailBulk,
} from "@fortawesome/free-solid-svg-icons";

function UserLayout() {
  const logoImage = <img src={logo} alt="Logo" />;
  let userId, customerId;

  try {
    userId = JSON.parse(getCookie("user"))?.id;
  } catch (error) {
    console.error("Error parsing user cookie:", error);
    // Handle the error or set a default value
    userId = null;
  }

  try {
    customerId = JSON.parse(getCookie("CustomerId"));
  } catch (error) {
    console.error("Error parsing CustomerId cookie:", error);
    // Handle the error or set a default value
    customerId = null;
  }

  const [key, setKey] = useState("profile");

  const fetchCustomerData = async () => {
    const { data } = await axios.get(`/api/customer-data/${customerId}`);
    return data;
  };

  const {
    data: userData,
    isError,
    error,
  } = useQuery("customerData", fetchCustomerData, {
    enabled: !!customerId,
  });
  const renderContent = () => {
    switch (key) {
      case "profile":
        return <UserDetails userData={userData} />;
      case "family":
        return <UserFamily userData={userData} userId={customerId} />;
      case "address":
        return <UserAdress userId={customerId} userData={userData} />;
      case "reservations":
        return <UserReservation userData={userData} userId={customerId} />;
      case "offers":
        return <UserOffers userId={customerId} />;
      case "support":
        return <UserHelpp userId={customerId} />;
      // Add more cases as needed for other tabs
      default:
        return null;
    }
  };

  return (
    <>
      <style>
        {`
      .custom-tab .nav-item .nav-link {
  
        border-radius:0px;
        width: 100%; // Make each tab button fill the container
        text-align: left !important;
        padding-left: 1rem; 
        transition: background-color 0.3s ease;
      }
      .custom-tab .nav-item .nav-link.active {
        background-color: #007bff; // Example active state background color
        color: #fff;
      }
    `}
      </style>

      <Container fluid>
        <Header />
        <Row className="mt-4">
          <Col sm={3} className="vh-100 overflow-auto">
            <Card className="shadow mb-4 pl-3 pt-4 pb-4 pr-2">
              <span style={{ fontWeight: "700" }}>
                {userData?.FirstName} {userData?.LastName}
              </span>
              <hr />{" "}
              <span style={{ fontSize: "12px" }}>
                <FontAwesomeIcon icon={faMailBulk} className="mr-2" />

                {userData?.Eposta}
              </span>
              <hr />
              <span style={{ fontSize: "12px" }}>
                <FontAwesomeIcon icon={faMobile} className="mr-2" />
                {userData?.Telefon
                  ? userData?.Telefon
                  : "PLeas Enter your phone Number"}
              </span>
            </Card>
            <Card className="shadow">
              <Tabs
                className="flex-column custom-tab"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                variant="pills"
              >
                <Tab
                  eventKey="profile"
                  title={
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <FontAwesomeIcon icon={faUser} className="mr-4" />
                      Profilim
                    </span>
                  }
                ></Tab>
                <Tab
                  eventKey="family"
                  title={
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <FontAwesomeIcon icon={faHome} className="mr-4" /> Ailem
                    </span>
                  }
                ></Tab>
                <Tab
                  eventKey="address"
                  title={
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <FontAwesomeIcon icon={faAddressCard} className="mr-4" />{" "}
                      Adresler
                    </span>
                  }
                ></Tab>
                <Tab
                  eventKey="reservations"
                  title={
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <FontAwesomeIcon icon={faCalendarAlt} className="mr-4" />{" "}
                      Rezervasyonlarım
                    </span>
                  }
                ></Tab>
                <Tab
                  eventKey="offers"
                  title={
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <FontAwesomeIcon icon={faGift} className="mr-4" />{" "}
                      Teklifler
                    </span>
                  }
                ></Tab>
                <Tab
                  eventKey="support"
                  title={
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <FontAwesomeIcon icon={faHeadset} className="mr-4" />{" "}
                      Destek Talep
                    </span>
                  }
                ></Tab>

                {/* Add more tabs as needed */}
              </Tabs>
            </Card>
          </Col>
          <Col sm={9}>{renderContent()}</Col>
        </Row>
        <Footer />
      </Container>
    </>
  );
}

export default UserLayout;
