import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { axios } from "common/utils/axios";
import { useParams, Link } from "react-router-dom";
import { useCustomerData } from "common/hooks/useCustomerData";
import { useToursData } from "common/hooks/useToursData";
import Button from "@mui/material/Button";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export const CustomersSales = () => {
  const [customersSalesData, setCustomersSalesData] = useState([]);
  const customersData = useCustomerData();
  const toursData = useToursData();
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const { id } = useParams();
  const columns = [
    { name: "Müşteri ID", selector: "customer_id", sortable: true },
    { name: "Tours", selector: "tour_id", sortable: true },
    {
      name: "Fiyat",
      selector: (row) => (
        <div className="mb-2">
          <div className="mb-2">
            <span
              style={{
                marginRight: "3px",
                backgroundColor: "#e0e0d1",
                padding: "2px",
                borderRadius: "4px",
              }}
            >
              2 Kişi {row.price}
            </span>
            <span
              style={{
                marginRight: "3px",
                backgroundColor: "#e0e0d1",
                padding: "2px",
                borderRadius: "4px",
              }}
            >
              3 Kişi {row.price}
            </span>
          </div>
          <div>
            <span
              style={{
                marginRight: "3px",
                backgroundColor: "#e0e0d1",
                padding: "2px",
                borderRadius: "4px",
              }}
            >
              4 Kişi {row.price}
            </span>
            <span
              style={{
                marginRight: "3px",
                backgroundColor: "#e0e0d1",
                padding: "2px",
                borderRadius: "4px",
              }}
            >
              5 Kişi
              {row.price}
            </span>
          </div>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      minWidth: "250px",
      maxWidth: "250px",
    },
    { name: "created", selector: "created_at", sortable: true },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <Button
            style={{ backgroundColor: "#ff1a1a" }}
            className="mr-2 ml-2"
            variant="contained"
            color="secondary"
            onClick={() => handleDeleteMeeting(row.id)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      minWidth: "250px",
      maxWidth: "250px",
    },
  ];
  const filteredItems = customersSalesData.filter(
    (item) =>
      item.customer_id &&
      item.customer_id
        .toString()
        .toLowerCase()
        .includes(filterText.toLowerCase())
  );
  const mappedItems = filteredItems.map((item) => {
    const customer = customersData.find((c) => c.id == item.customer_id);
    const tours = toursData.find((c) => c.id == item.tour_id);
    return customer
      ? {
          ...item,
          customer_id: `${customer.FirstName} ${customer.LastName}`,
          tour_id: tours ? tours.title : item.tour_id,
        }
      : item;
  });
  /*********************Fething Api Customer meetings******************* */
  const handleDeleteMeeting = async (customerId, saleId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this meeting?"
    );
    if (confirmDelete) {
      await axios.delete(`api/customer/${customerId}/sales/${saleId}`);
    }
  };
  useEffect(() => {
    axios
      .get(`/api/customer-sales`)
      .then((response) => setCustomersSalesData(response.data))
      .catch((error) => console.error(error));
  }, []);
  /******************* Data Table Style ********************** */
  const customStyles = {
    rows: {
      style: {
        fontSize: "17px", // Custom font size
      },
    },
    headCells: {
      style: {
        fontSize: "17px", // Custom font size
      },
    },
  };
  const handleSearch = (e) => {
    setFilterText(e.target.value);
  };
  return (
    <div>
      <div
        className="row mb-4"
        style={{
          backgroundColor: "#e6e6e6",
          padding: "10px",
          borderRadius: "8px",
        }}
      >
        <div className="col-6">
          <h2>Staış Yönetimi</h2>
        </div>
        <div className="col-6" style={{ display: "grid", placeItems: "end" }}>
          <Link
            key="one"
            to={`/admin/customerManagement/customers/CreateSales`}
          >
            <Button
              style={{
                backgroundColor: "DodgerBlue",
                color: "#fff",
                fontWeight: "bold",
                marginBottom: "8px",
              }}
            >
              Yeni Staış
            </Button>
          </Link>
        </div>
      </div>
      <div
        style={{
          padding: "20px",
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow:
            " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <h4>Sistemde kayıtlı Staış</h4>
        <input
          type="text"
          placeholder="Search by name"
          value={filterText}
          onChange={handleSearch}
        />
        <DataTable
          columns={columns}
          data={mappedItems}
          pagination
          paginationResetDefaultPage={resetPaginationToggle}
          subHeader
          subHeaderAlign="right"
          persistTableHead
          style={{ fontSize: "100px !important" }}
          customStyles={customStyles}
        />
      </div>
    </div>
  );
};
export default CustomersSales;
