import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UseTourDetails from "../../../../../common/utils/functions/UseTourDetails";
import "../../../../../assets/styles/TourDetails.css";
export const ButtomSection = () => {
  /****  Start Reseve Data From Database   **** */
  const { id } = useParams();
  const tour = UseTourDetails(id);
  /****  End Reseve Data From Database   **** */
  return (
    <div className=".carousel-inner">
      <img
        src={tour?.tour_Img_url}
        style={{
          width: "100%",
          maxHeight: "500px",
        }}
      />
      Her gonna be carousel slide carousel-fade carousel-thumbnails className
    </div>
  );
};
export default ButtomSection;
