import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import * as api from "common/Api/QaApi";
// import "bootstrap/dist/css/bootstrap.min.css";
import { Form } from "react-bootstrap";
import CustomCollapse from "pages/NewTour/Components/CustomerCollapes";
import styled from "styled-components";
const LargeScreenContainer = styled.div`
  /* Default styles */
  width: 100%; // Use 100% width for smaller screens

  @media (min-width: 1400px) {
    width: 93%; // Use 80% width for screens wider than 1400px
    margin: auto; // Center the container
  }
`;
export default ({ submitButtonText = "Gönder" }) => {
  const [questions, setQuestions] = useState([]);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);

  const {
    data: QaData,
    isLoading,
    isError,
    refetch,
  } = useQuery("questions", api.getQuestions, {
    enabled: false,
    onSuccess: (data) => {
      localStorage.setItem("cachedQuestions", JSON.stringify(data));
      setQuestions(data);
    },
  });

  useEffect(() => {
    if (questions.length > 0) {
      setActiveQuestionIndex(0);
    }
    const shouldRefetchQA = localStorage.getItem("shouldRefetchQA");
    const cachedData = JSON.parse(
      localStorage.getItem("cachedQuestions") || "[]"
    );
    if (shouldRefetchQA === "true") {
      localStorage.removeItem("cachedQuestions");
      refetch();
      localStorage.removeItem("shouldRefetchQA");
    } else if (cachedData.length === 0) {
      refetch();
    } else if (cachedData.length > 0) {
      setQuestions(cachedData);
    }
  }, []);

  const toggleQuestion = (index) => {
    if (activeQuestionIndex === index) {
      // If the clicked question is already active, close it
      setActiveQuestionIndex(null);
    } else {
      // Open the clicked question
      setActiveQuestionIndex(index);
    }
  };

  if (isLoading) {
    return <div>Loading ....</div>;
  }

  return (
    <LargeScreenContainer>
      <div className="row d-flex justify-content-center mt-4">
        <div className="col-lg-8 ">
          <div
            id="faqAccordion"
            role="tablist"
            aria-multiselectable="true"
            className="mb-4"
          >
            {questions.map((faq, index) => (
              <div className="card" key={index}>
                <div className="card-header" role="tab">
                  <h5 className="mb-0">
                    <button
                      style={{ color: "rgb(0, 109, 119)", fontWeight: "bold" }}
                      className="btn btn-link"
                      onClick={() => toggleQuestion(index)}
                    >
                      {faq.question}
                    </button>
                  </h5>
                </div>
                <CustomCollapse isOpen={activeQuestionIndex === index}>
                  <div className="card-body">
                    {/* Render HTML Content */}
                    <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                  </div>
                </CustomCollapse>
              </div>
            ))}
          </div>
          <div style={{ marginTop: "75px" }}>
            <h2 class="text-center text-lg-left">
              Herhangi Bir Sorunuz Varsa Bize Sorabilirsiniz
            </h2>
            <Form>
              <Form.Group className="mb-2">
                <Form.Control type="" name="name" placeholder="Adı Soyadı" />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Control
                  type="email"
                  name="Telefon"
                  placeholder="Telefon"
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Control type="email" name="email" placeholder="E-posta" />
              </Form.Group>

              <Form.Group>
                <Form.Control
                  as="textarea"
                  name="message"
                  placeholder="Sorunuzu Yazın"
                  rows={4}
                />
              </Form.Group>
              <div
                className="d-flex justify-content-end"
                style={{ marginBottom: "20px" }}
              >
                <button
                  type="submit"
                  className="mt-4 btn btn-primary"
                  style={{ paddingLeft: "30px", paddingRight: "30px" }}
                >
                  {submitButtonText}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </LargeScreenContainer>
  );
};
