import React, { useEffect, useState } from "react";
import { Link, NavLink, useSearchParams } from "react-router-dom";

import AnimationRevealPage from "common/helpers/AnimationRevealPage.js";
import {
  Container,
  ContentWithPaddingXl,
} from "common/components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import UserHeader from "../components/UserHeader";
import Footer from "common/components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "common/components/misc/Headings";
import { PrimaryButton } from "common/components/misc/Buttons";
import otelsError from "assets/icons/errorPhoto.jpg";
import getOtels from "common/utils/functions/getOtels";
// import { axios } from "common/utils/axios";
// import { axios } from "axios";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${(props) =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
const Description = tw.div``;

const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

/********************* */
/********************** */
export default ({ headingText = "Oteller" }) => {
  const [otels, setOtels] = useState([]);
  const [visible, setVisible] = useState(7);
  const [searchParams, setSearchParams] = useSearchParams();
  const Image = ({ imageSrc, defaultImageSrc }) => {
    const [currentImage, setCurrentImage] = React.useState(imageSrc);

    const handleError = () => {
      setCurrentImage(defaultImageSrc);
    };

    React.useEffect(() => {
      setCurrentImage(imageSrc);
    }, [imageSrc]);

    return <img alt="" src={currentImage} onError={handleError} />;
  };
  const onLoadMoreClick = () => {
    setVisible((v) => v + 6);
  };
  useEffect(() => {
    let otels = getOtels(searchParams.get("type"), searchParams.get("key"));
    otels.then(function (result) {
      console.log(result);
      setOtels(result);
    });
  }, []);
  console.log(otels);
  return (
    <AnimationRevealPage>
      <UserHeader />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Posts>
            {otels.slice(0, visible).map((otel, index) => (
              <PostContainer key={index} featured={otel.featured}>
                <Post className="group">
                  <Link to={`/otel/${otel.id}`}>
                    <Image
                      imageSrc={otel.imageSrc}
                      defaultImageSrc={otelsError}
                    />
                  </Link>
                  <Info>
                    <Category>{otel.category}</Category>
                    <CreationDate>{otel.date}</CreationDate>
                    <Title>{otel.title}</Title>
                    {otel.featured && otel.description && (
                      <Description>{otel.description}</Description>
                    )}
                  </Info>
                </Post>
              </PostContainer>
            ))}
          </Posts>

          {visible < otels?.length && (
            <ButtonContainer>
              <LoadMoreButton onClick={onLoadMoreClick}>
                Load More
              </LoadMoreButton>
            </ButtonContainer>
          )}
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

const getPlaceholderPost = () => ({
  imageSrc:
    "https://images.unsplash.com/photo-1418854982207-12f710b74003?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
  category: "Travel Guide",
  date: "April 19, 2020",
  title: "Visit the beautiful Alps in Switzerland",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  url: "https://reddit.com",
});
