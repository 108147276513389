import React from "react";
import { Link } from "react-router-dom";
import UseTourDetails from "../../../../common/utils/functions/UseTourDetails";
import { useParams } from "react-router-dom";
export const RoomDetails = ({ adultCount }) => {
  /****  Start Reseve Data From Database   **** */
  const { id } = useParams();
  const tour = UseTourDetails(id);
  if (!tour) {
    return <div>Loading...</div>;
  }
  /****  End Reseve Data From Database   **** */
  let roomContent;

  if (adultCount === 2) {
    roomContent = tour.iki_kisi_fiyat;
  } else if (adultCount === 3) {
    roomContent = tour.uç_kisi_fiyat;
  } else if (adultCount === 4) {
    roomContent = tour.dört_kisi_fiyat;
  } else if (adultCount > 4) {
    roomContent = tour.özel_fiyat;
  } else if (
    tour.özel_kisi_saısı != null &&
    tour.özel_kisi_saısı < adultCount
  ) {
    roomContent = "bu kisi sayis yok şuan";
  }
  // console.log(roomContent);
  console.log();
  return (
    <div className="container">
      <div
        className="row"
        style={{
          justifyContent: "center",
        }}
      >
        <div className="col-md-6 pr-0 pl-0 mt-4 col-md-12 col-xl-6">
          <figure className="figure room-figure">
            <div className="room-detail-content">
              <figcaption className="figure-caption">
                <div className="room-detail-wrapper tour-detail-wrapper">
                  <span
                    className="room-name option-name"
                    style={{ fontSize: "20px" }}
                  >
                    <h3> {adultCount}'Lİ ODA</h3>

                    <br />
                    <span className="concept">Yarım Pansiyon</span>
                  </span>
                  <div className="float-right">
                    <span
                      className="hotel-price-detail"
                      data-price="4400.00"
                      data-currency22="$"
                      style={{ fontSize: "20px" }}
                    >
                      {roomContent}
                    </span>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </figcaption>
              <figcaption className="figure-caption tour-option-details"></figcaption>
              <figcaption className="figure-caption room-price-content">
                <div className="container room-price-bottom">
                  <div className="text-center">
                    <Link
                      to="/tour-reservation"
                      className="btn btn-outline-primary"
                      style={{ fontSize: "30px" }}
                    >
                      Satın Al
                    </Link>
                  </div>
                </div>
              </figcaption>
            </div>
          </figure>
        </div>
      </div>
    </div>
  );
};
export default RoomDetails;
