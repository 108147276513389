import UserGlobalLayout from "layouts/UserGlobalLayout";
import UserHomePage from "pages/HomePage/UserHomePage";
import QS from "pages/QA/QS";
import AboutUS from "pages/AboutUs/AboutUs";
import UserOtels from "pages/OtelsPage/UserOtels.js";
import Tours from "pages/Tours/Tours";
import UserToursPage from "pages/Tours/UserToursPage";
import TourDetails from "pages/TourList/TourDetails/TourDetails";
import UserBlogList from "pages/Blog/UserBlogList";
import UserOtelSinglPage from "pages/OtelsPage/UserOtelSinglPage";
import UserNewToursPage from "pages/NewTour/UserNewToursPage";
import UserPostPage from "pages/Blog/UserPostPage";
import UserNewTourDetails from "pages/NewTour/UserNewTourDetails";

import UserLayout from "layouts/UserLayout";
import CreateFamily from "area/user/Dashbord/Create/CreateFamily";
import CreateCustomerAdress from "area/admin/CustomerMangement/ServiceCustomersComponents/Create/CreateCustomerAddress";
import CreateReservation from "area/admin/CustomerMangement/ReservationMangment/CreateReservation";
import EditReservation from "area/admin/CustomerMangement/ReservationMangment/EditReservation";
import UserChat from "area/user/Dashbord/components/Chat";
import TwoColContactUsWithIllustrationFullForm from "../pages/ContactUs/ContactUs";
export const UserRoutes = [
  {
    path: "user",
    auth: "user",
    element: <UserGlobalLayout />, // Your User Layout that wraps around all user pages
    children: [
      {
        path: "", // This acts as the /user default route
        element: <UserHomePage />,
      },
      {
        path: "otels",
        element: <UserOtels />,
      },
      {
        path: "otels/:hotelName",
        element: <UserOtelSinglPage />,
      },
      {
        path: "tours",
        element: <UserToursPage />,
      },
      {
        path: "tour/:id",
        element: <TourDetails />,
      },
      {
        path: "tours/:categoryName",
        element: <UserNewToursPage />,
      },
      {
        path: "categories/:slug/tours",
        element: <Tours />,
      },
      {
        path: "tours/:categoryName/:tourName",
        element: <UserNewTourDetails />,
      },

      {
        path: "about-us",
        element: <AboutUS />,
      },
      {
        path: "contactUs",
        element: <TwoColContactUsWithIllustrationFullForm />,
      },
      {
        path: "QA",
        element: <QS />,
      },

      {
        path: "Blog",
        element: <UserBlogList />,
      },
      {
        path: "blog/post/:slug",
        element: <UserPostPage />,
      },
    ],
  },
  {
    path: "user/profile",
    element: <UserLayout />,
    auth: "user",
    children: [],
  },
  // {
  //   path: "/user",
  //   element: <UserHomePage />,
  //   auth: "user",
  //   children: [],
  // },
  // {
  //   path: "/user/contactUs",
  //   auth: "user",
  //   element: <TwoColContactUsWithIllustrationFullForm />,
  // },

  // {
  //   auth: "user",
  //   path: "user/profilim/createfamily/:id",
  //   element: <CreateFamily />,
  // },
  // {
  //   auth: "user",
  //   path: "user/profilim/CreateCustomerAdress/:id?",
  //   element: <CreateCustomerAdress />,
  // },

  // {
  //   auth: "user",
  //   path: "user/profilim/CreateReservation/:id?",
  //   element: <CreateReservation />,
  // },
  // {
  //   auth: "user",
  //   path: "user/profilim/edit_reservation/:reservation_id",
  //   element: <EditReservation />,
  // },
  // {
  //   auth: "user",
  //   path: "user/profilim/chat/:chatId",
  //   element: <UserChat />,
  // },
];
