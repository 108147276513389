import { axios } from "common/utils/axios";

export const getTours = (filters = {}) => {
  // Construct query parameters from filters
  const queryParams = new URLSearchParams(filters).toString();
  return axios
    .get(`api/newtour?${queryParams}`)
    .then((response) => response.data);
};
export const getFirstFiveTours = (pageNumber) =>
  axios
    .get(`api/get-first-five-tours?page=${pageNumber}`)
    .then((response) => {
      console.log(response.data); // Check the response structure
      return response.data;
    })
    .catch((error) => {
      console.error("API error:", error);
      return null; // Handle error appropriately
    });

export const getTour = (id) => axios.get(`api/newtour/${id}`);
export const getLastTour = () => axios.get(`api/latest-tours`);

export const createTour = (data) => axios.post(`api/creat-newtour`, data);

export const updateTour = (id, data) => axios.put(`/api/newtour/${id}`, data);

export const deleteTour = (id) => axios.delete(`api/newtour/${id}`);

export const getTourImages = (tourId) =>
  axios
    .get(`api/tours/${tourId}/images`)
    .then((response) => response.data)
    .catch((error) => {
      console.error("API error fetching tour images:", error);
      throw error; // Re-throw the error for handling in the component
    });

export const fetchFilterTours = async (filters) => {
  const params = new URLSearchParams(filters);
  const response = await axios.get(
    `https://backend.suarelab.com/api/tours/filter?${params}`
  );
  return response.data;
};

export const fetchTourBySlug = async (slug) => {
  try {
    const response = await axios.get(`api/tours/${slug}`);
    console.log(response); // Check the structure of the response
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Network error");
  }
};
