import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrash,
  faEdit,
  faSave,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

export const HotelRooms = ({ updateFormData, otelData }) => {
  const [roomTypes, setRoomTypes] = useState(otelData.room_types || []);
  const [editIndex, setEditIndex] = useState(-1); // -1 means no edit mode

  // Local state for the new room type
  const [newRoomType, setNewRoomType] = useState({
    room_type_name: "",
    food_type: "",
  });

  // Function to handle adding a new room
  const addRoom = () => {
    if (newRoomType.room_type_name || newRoomType.food_type) {
      const updatedRooms = [...roomTypes, { ...newRoomType, id: Date.now() }];
      setRoomTypes(updatedRooms);
      updateFormData({ room_types: updatedRooms });
      setNewRoomType({ room_type_name: "", food_type: "" });
    } else {
      toast.warning("Eksik data");
    }
  };

  const deleteRoom = (index) => {
    const updatedRoomTypes = roomTypes.filter((_, i) => i !== index);
    setRoomTypes(updatedRoomTypes);
    updateFormData({ room_types: updatedRoomTypes });
  };

  const handleEdit = (index) => {
    setEditIndex(index);
  };

  const handleSave = (index) => {
    setEditIndex(-1);
    updateFormData({ room_types: roomTypes });
  };

  const handleCancel = () => {
    setEditIndex(-1);
  };

  const handleChange = (index, key, value) => {
    const updatedRoomTypes = roomTypes.map((room, i) => {
      if (i === index) {
        return { ...room, [key]: value };
      }
      return room;
    });
    setRoomTypes(updatedRoomTypes);
  };

  return (
    <div>
      <Form>
        {/* Separate row for adding a new room type */}
        <Row className="mb-3">
          <Col xl={5}>
            <Form.Group>
              <Form.Label>Oda Türü Adı</Form.Label>
              <Form.Control
                type="text"
                placeholder="Oda Türü Adı"
                value={newRoomType.room_type_name}
                onChange={(e) =>
                  setNewRoomType({
                    ...newRoomType,
                    room_type_name: e.target.value,
                  })
                }
              />
            </Form.Group>
          </Col>

          <Col xl={3}>
            <Form.Group>
              <Form.Label>Yemek Türü</Form.Label>
              <Form.Select
                value={newRoomType.food_type}
                onChange={(e) =>
                  setNewRoomType({ ...newRoomType, food_type: e.target.value })
                }
              >
                <option>Choose...</option>
                <option value="Vegetarian">Vejetaryen</option>
                <option value="Non-Vegetarian">Et Yiyen</option>
                <option value="Vegan">Vegan</option>
              </Form.Select>
            </Form.Group>
          </Col>

          <Col xl={2} className="d-flex align-items-end">
            <Button variant="primary" onClick={addRoom}>
              <FontAwesomeIcon icon={faPlus} /> Oda Türü Ekle
            </Button>
          </Col>
        </Row>

        {/* Rows for existing room types */}
        {roomTypes.map((roomType, index) => (
          <Row className="mb-3" key={roomType.id || `room-type-${index}`}>
            <Col xl={5}>
              {editIndex === index ? (
                <Form.Control
                  type="text"
                  value={roomType.room_type_name}
                  onChange={(e) =>
                    handleChange(index, "room_type_name", e.target.value)
                  }
                />
              ) : (
                roomType.room_type_name
              )}
            </Col>
            <Col xl={3}>
              {editIndex === index ? (
                <Form.Select
                  value={roomType.food_type}
                  onChange={(e) =>
                    handleChange(index, "food_type", e.target.value)
                  }
                >
                  <option value="Vegetarian">Vegetarian</option>
                  <option value="Non-Vegetarian">Non-Vegetarian</option>
                  <option value="Vegan">Vegan</option>
                </Form.Select>
              ) : (
                roomType.food_type
              )}
            </Col>
            <Col xl={2} className="d-flex align-items-end">
              {editIndex === index ? (
                <>
                  <Button variant="primary" onClick={() => handleSave(index)}>
                    <FontAwesomeIcon icon={faSave} /> Save
                  </Button>
                  <Button variant="secondary" onClick={handleCancel}>
                    <FontAwesomeIcon icon={faTimes} /> Cancel
                  </Button>
                </>
              ) : (
                <>
                  <Button variant="secondary" onClick={() => handleEdit(index)}>
                    <FontAwesomeIcon icon={faEdit} /> Edit
                  </Button>
                  <Button variant="danger" onClick={() => deleteRoom(index)}>
                    <FontAwesomeIcon icon={faTrash} /> Delete
                  </Button>
                </>
              )}
            </Col>
          </Row>
        ))}
      </Form>
    </div>
  );
};

export default HotelRooms;
