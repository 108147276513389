import { axios } from "common/utils/axios";

export const getComments = () =>
  axios.get(`api/comments`).then((response) => response.data);

export const getComment = (id) => axios.get(`api/comments/${id}`);
export const createComments = (data) => axios.post(`api/comments`, data);
export const updateComments = (data) => {
  console.log("Data being sent:", data);
  console.log("id being sent:", data.id);
  return axios.put(`api/comments/${data.id}`, data);
};

export const deleteComments = (id) => axios.delete(`api/comments/${id}`);
export const getAvatar = (avatar) => axios.get(`api/comment/avatar/${avatar}`);
