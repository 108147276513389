import React from "react";
import { Modal, Button, Card, Row, Col, Accordion } from "react-bootstrap";
import CollapsibleCard from "./CollapsibleCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import BooleanIcon from "./BooleanIcon";
import TourInformationImage from "assets/images/Advertisment/TurBilgileri.png";
import priceImage from "assets/images/Advertisment/Fiyatlar.png";
import TripImage from "assets/images/Advertisment/Gezi.png";
import foodImage from "assets/images/Advertisment/Yemek.png";
import trasportImage from "assets/images/Advertisment/Ulaşım.png";
import accomodationImage from "assets/images/Advertisment/Konaklama.png";
import InstallmentPrice from "./Edit/InstallmentPrice";
export default ({ showModal, setShowModal, tourDetails }) => {
  console.log(tourDetails, "tourDetails.data.transportations");
  console.log(TourInformationImage, "TourInformationImage");
  return (
    <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Tour Details</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
        {/* Display detailed tour information here */}
        {tourDetails ? (
          <>
            <Accordion>
              <Row>
                <Col xl={6} lg={6} md={6} sm={12} className="mb-4">
                  <CollapsibleCard
                    title="Tur Bilgileri"
                    bgColor=""
                    textColor="#FBFCFC"
                    backgroundImage={TourInformationImage}
                  >
                    <hr />
                    <Row>
                      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <p className="blur-background">
                          Adı: {tourDetails.data?.tourName}
                        </p>
                      </Col>

                      <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                        <p className="blur-background">
                          dealer: {tourDetails.data?.dealer}
                        </p>
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                        <p className="blur-background">
                          Kategori: {tourDetails.data?.category}
                        </p>
                      </Col>
                      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <p className="blur-background">
                          Kalkış Şehri: {tourDetails.data?.departureCity}
                        </p>
                      </Col>
                      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <p className="blur-background">
                          Dönüş Şehri: {tourDetails.data?.returnCity}
                        </p>
                      </Col>

                      <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                        <p className="blur-background">
                          Günler: {tourDetails.data?.days}
                        </p>
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                        <p className="blur-background">
                          Geceler: {tourDetails.data?.nights}
                        </p>
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                        <p className="blur-background">
                          Kapasite: {tourDetails.data?.capacity}
                        </p>
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                        <p className="blur-background">
                          Çocukler: {tourDetails.data?.childs}
                        </p>
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                        <p className="blur-background">
                          Çocuk Sayısı: {tourDetails.data?.childAge}
                        </p>
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                        <p className="blur-background">
                          Sezon: {tourDetails.data?.season}
                        </p>
                      </Col>
                      <hr />
                      <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                        <p className="blur-background">
                          Visa: <BooleanIcon value={tourDetails.data.visa} />
                        </p>
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                        <p className="blur-background">
                          Lock:{" "}
                          <BooleanIcon value={tourDetails.data.showOnWebsite} />
                        </p>
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                        <p className="blur-background">
                          Mahremiyet Kontrolü:{" "}
                          <BooleanIcon
                            value={tourDetails.data.intimateControl}
                          />
                        </p>
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                        <p className="blur-background">
                          Web Sitesinde Göste:{" "}
                          <BooleanIcon value={tourDetails.data.showOnWebsite} />
                        </p>
                      </Col>

                      <hr />

                      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <p className="blur-background">
                          yayın tarihi : {tourDetails.data?.publicationDate}
                        </p>
                      </Col>

                      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <p className="blur-background">
                          yayın Bitiş Tarihi:{" "}
                          {tourDetails.data?.publicationEndDate}
                        </p>
                      </Col>
                    </Row>
                    <p className="blur-background">
                      Başlangıç Tarihi: {tourDetails.data?.startingDate}
                    </p>
                    <p className="blur-background">
                      Bitiş Tarihi: {tourDetails.data?.endingDate}
                    </p>
                  </CollapsibleCard>
                </Col>

                <Col xl={6} lg={6} md={6} sm={12} className="mb-4">
                  <Card style={{ backgroundColor: "#", color: "#FBFCFC" }}>
                    <CollapsibleCard
                      title="Ulaşım"
                      bgColor=""
                      textColor="#FBFCFC"
                      backgroundImage={accomodationImage}
                    >
                      <hr />
                      <Row>
                        {tourDetails.data.transportations &&
                          tourDetails.data?.transportations?.map(
                            (transportation) => (
                              <>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                  <p className="blur-background">
                                    Şirket: {transportation.company}
                                  </p>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                  <p className="blur-background">
                                    nerden: {transportation.fromLocation}
                                  </p>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                  <p className="blur-background">
                                    nereye: {transportation.toLocation}
                                  </p>
                                </Col>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                  <p className="blur-background">
                                    Kalkış Tarihi ve Saati:{" "}
                                    {transportation.departureDateTime}
                                  </p>
                                </Col>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                  <p className="blur-background">
                                    Varış Tarihi ve Saati:{" "}
                                    {transportation.arrivalDateTime}
                                  </p>
                                </Col>
                                <hr />
                              </>
                            )
                          )}
                      </Row>
                    </CollapsibleCard>
                  </Card>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12} className="mb-4">
                  <CollapsibleCard
                    title="Gezi"
                    bgColor=""
                    textColor="#FBFCFC"
                    backgroundImage={TripImage}
                  >
                    <hr />
                    <Row>
                      {tourDetails.data.trips &&
                        tourDetails.data?.trips?.map((trip) => (
                          <>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                              <p className="blur-background">
                                Tur Başlığı : {trip.tripTitle}
                              </p>
                            </Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                              <p className="blur-background">
                                Açıklama: {trip.tripDescription}
                              </p>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                              <p className="blur-background">
                                Kaç Gün: {trip.howManyDays}
                              </p>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={5} xs={6}>
                              <p className="blur-background">
                                Zamanlama: {trip.timing}
                              </p>
                            </Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                              <p className="blur-background">
                                Paket Fiyatlandırma: {trip.packagePricing}
                              </p>
                            </Col>
                          </>
                        ))}
                    </Row>
                    <hr />
                  </CollapsibleCard>
                </Col>

                <Col xl={6} lg={6} md={6} sm={12} className="mb-4">
                  <CollapsibleCard
                    title="Yemek"
                    bgColor=""
                    textColor="#FBFCFC"
                    backgroundImage={foodImage}
                  >
                    <hr />
                    {tourDetails.data.food_options &&
                      tourDetails.data?.food_options.map((food_option) => (
                        <>
                          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <p className="blur-background">
                              {food_option.foodOptionType}:{" "}
                              {food_option.foodOptionName}
                            </p>
                          </Col>
                        </>
                      ))}
                  </CollapsibleCard>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12} className="mb-4">
                  <CollapsibleCard
                    title="Fiyatlar"
                    bgColor=""
                    textColor="#FBFCFC"
                    backgroundImage={priceImage}
                  >
                    <Row>
                      {tourDetails.data.pricing &&
                        tourDetails.data.pricing.map((price, index) => (
                          <React.Fragment key={index}>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                              <p className="blur-background">
                                Kaç Kişi: {price.howManyPeople}
                              </p>
                            </Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                              <p className="blur-background">
                                Fiyat: {price.mainPrice} {price.pricingType}
                              </p>
                            </Col>

                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                              <p className="blur-background">
                                İndirim: {price.discountPrice}{" "}
                                {price.pricingType}
                              </p>
                            </Col>

                            <hr />
                            <h5>Installments</h5>
                            {price.installments &&
                              price.installments.map((installment, index) => (
                                <>
                                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                    <p className="blur-background">
                                      Ay : {installment.Months}{" "}
                                    </p>
                                  </Col>
                                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                    <p className="blur-background">
                                      Price: {installment.installmentPrice}{" "}
                                    </p>
                                  </Col>
                                </>
                              ))}
                          </React.Fragment>
                        ))}
                    </Row>
                    <hr />
                  </CollapsibleCard>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12} className="mb-4">
                  <CollapsibleCard
                    title="Konaklama"
                    bgColor=""
                    textColor="#FBFCFC"
                    backgroundImage={trasportImage}
                  >
                    <hr />
                    <Row>
                      {tourDetails.data.accomodations &&
                        tourDetails.data?.accomodations.map((accomodation) => (
                          <>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                              <p className="blur-background">
                                Şehir: {accomodation.city}
                              </p>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                              <p className="blur-background">
                                otel: {accomodation.otel}
                              </p>
                            </Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                              <p className="blur-background">
                                Başlangıç Tarihi: {accomodation.startDate}
                              </p>
                            </Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                              <p className="blur-background">
                                Bitiş Tarihi: {accomodation.endDate}
                              </p>
                            </Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                              <p className="blur-background">
                                Gece Sayıs: {accomodation.nights}
                              </p>
                            </Col>
                          </>
                        ))}
                      <hr />
                    </Row>
                  </CollapsibleCard>
                </Col>
              </Row>
            </Accordion>
            {/* Add other detailed tour information */}
          </>
        ) : (
          <p>Loading...</p> // Show a loading message or spinner
        )}
        {/* Add other detailed tour information */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Kapat
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
