import React, { useState, useContext } from "react";
import { axios } from "common/utils/axios";
import "assets/styles/SitSetting.css";
import { Card, Col, Row, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import FormUplaodInput from "area/admin/CRUD/ReactBootsrap/FormUplaodInput";
import { CategoriesContext } from "redux/ContextApi/Contexts";
export default function Categories() {
  const { categories, isLoading } = useContext(CategoriesContext);
  const [categoryData, setCategoryData] = useState({
    category: "",
    photo: null, // Add a state property to hold the selected file
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("category", categoryData.category);
    if (categoryData.photo) {
      formData.append("file", categoryData.photo); // Change "photo" to "file"
    }

    axios
      .post("api/categories", formData) // Send the FormData object
      .then((response) => {
        console.log(response.data);
        // reset category data
        toast.success("Create Category Success");
        setCategoryData({ category: "", photo: null });
      })
      .catch((error) => {
        console.error(error);
        toast.success(error, "error");
      });
  };

  const handleFileChange = (e) => {
    console.log(e.target.files[0]); // add this line
    setCategoryData((prevState) => ({
      ...prevState,
      photo: e.target.files[0],
    }));
  };

  // Delete
  const handleDeleteCategory = (categoryId) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      axios
        .delete(`api/categories/${categoryId}`)
        .then((response) => {
          console.log(response.data);
          // Handle successful deletion, e.g., refresh the list of categories
        })
        .catch((error) => {
          console.error(error);
          toast.error("Failed to delete category");
        });
    }
  };
  return (
    <div>
      <Card>
        <Card.Header
          style={{
            backgroundColor: "#636665",
            color: "white",
            borderRadius: "5px 5px 0 0",
          }}
        >
          <Row>
            <Col>
              <h1 style={{ padding: "10px", fontSize: "24px", margin: 0 }}>
                Tur Kategori
              </h1>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Row>
                <Col>
                  <Button className="mt-2" onClick={handleSubmit}>
                    Yeni Kategori Ekle
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Header>
        <Row>
          <Col xl={7} className="p-4">
            <Card>
              {categories && (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  {categories.map((category) => (
                    <div
                      key={category.id}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "250px",
                      }}
                    >
                      <div>
                        <h3>{category.category}</h3>
                      </div>
                      <div>
                        <img
                          style={{ width: "100%", height: "auto" }}
                          src={`https://backend.suarelab.com/api/${category.photo}`}
                          alt={category.category}
                          className="img-fluid hover-img img-category"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </Card>
          </Col>
          <Col xl={4} className="mt-4">
            <form onSubmit={handleSubmit}>
              <Card className="pl-4 pr-4">
                <Form.Group className="mb-4 mt-4">
                  <Form.Label>
                    <strong> Kategori Adı </strong>
                  </Form.Label>
                  <Form.Control
                    value={categoryData.category}
                    name="category"
                    onChange={(e) => {
                      const { name, value } = e.target;
                      setCategoryData((prevState) => ({
                        ...prevState,
                        [name]: value,
                      }));
                    }}
                    type="text"
                  />
                </Form.Group>{" "}
                <div className="mb-4">
                  <FormUplaodInput
                    name="photo"
                    label="Kategori Görseli"
                    editWithCustomStyle={true}
                    onChange={handleFileChange}
                  />
                </div>
              </Card>
            </form>
          </Col>
        </Row>
      </Card>
    </div>
  );
}
