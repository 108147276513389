// ReservationDetail.js
import { useState, useEffect } from "react";
import { Button, Modal, Card, Col, Row } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faPlane,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";

const ReservationDetail = ({ reservation, show, handleClose }) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        setShowModal(true);
      }, 500); // Adjust the delay as needed, 1000 milliseconds = 1 second

      return () => clearTimeout(timer); // Cleanup the timer
    } else {
      setShowModal(false);
    }
  }, [show]); // Depend on the 'show' prop

  const tour = reservation?.tour;

  console.log(tour, "tourtourtourtour");
  return (
    <Modal show={showModal} onHide={handleClose} size="lg" scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Rezervasyon Detayları</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="mb-3">
          <Card.Header>
            <FontAwesomeIcon icon={faPlane} /> Tur
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <p>
                  <strong>Tur ismi:</strong> {tour?.tourName}
                </p>
                <p>
                  <strong>Kalkış:</strong> {tour?.departureCity} -{" "}
                  {tour?.startingDate}
                </p>

                <p>
                  <strong>Return:</strong> {tour?.returnCity} -{" "}
                  {tour?.endingDate}
                </p>
              </Col>
              <Col>
                {" "}
                <p>
                  <strong>kategori:</strong> {tour?.category}
                </p>
                <p>
                  <strong>fiyat:</strong> {reservation?.price}
                </p>
                <p>
                  <strong>durum:</strong> {reservation?.durum}
                </p>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card className="mb-3">
          <Card.Header>
            <FontAwesomeIcon icon={faUser} /> Müşteri
          </Card.Header>
          <Card.Body>
            <p>
              <strong>adı Soyadı:</strong> {reservation.customer.FirstName}{" "}
              {reservation.customer.LastName}
            </p>
            <p>
              <strong>E-posta:</strong> {reservation.customer.Eposta}
            </p>
            <p>
              <strong>Telefon:</strong> {reservation.customer.Telefon}
            </p>
          </Card.Body>
        </Card>

        <Card>
          <Card.Header>
            <FontAwesomeIcon icon={faCreditCard} /> Ödeme bilgisi
          </Card.Header>
          <Card.Body>{/* Add payment info here... */}</Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button active={true} variant="secondary" onClick={handleClose}>
          Kapat
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReservationDetail;
