import React from "react";

const CustomCollapse = ({ isOpen, children }) => {
  return (
    <div
      style={{
        display: isOpen ? "block" : "none",
        transition: "display 0.5s ease",
        maxHeight: isOpen ? "1000px" : "0",
      }}
    >
      {children}
    </div>
  );
};

export default CustomCollapse;
