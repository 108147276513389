import { useState } from "react";
import TableListComponent from "../CRUD/TableListComponent";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button, Form } from "react-bootstrap";
import Quill from "react-quill";

export const IndexComments = ({
  imageURL,
  data,
  deleteCommentMutation,
  updateCommentMutation,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [imageURL2, setImageURL2] = useState("");
  const [currentItem, setCurrentItem] = useState({
    commenter_name: "",
    comment: "",
    gender: "0", // default gender
    stars: "1", // default star rating
  });

  const columns = [
    { label: "Müşteri", field: "commenter_name" },
    { label: "Yorum", field: "comment" },
    { label: "Cinsiyet", field: "gender" },
    { label: "Yıldız", field: "stars" },
    { label: "Müşteri Profili", field: "avatar" },
  ];
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleAvatarChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const base64 = await convertToBase64(file);
      setAvatar(base64); // Set the avatar state to the base64 encoded string
      setImageURL2(URL.createObjectURL(file)); // For displaying the selected image
    }
  };
  const actions = [
    {
      variant: "warning",
      onClick: (item) => {
        setCurrentItem(item);
        setShowModal(true);
      },
      icon: faEdit,
      style: { marginRight: "10px" },
    },
    {
      variant: "danger",
      onClick: (item) => {
        if (window.confirm("Are you sure you want to delete this Comment?")) {
          deleteCommentMutation.mutate(item.id);
        }
      },
      icon: faTrash,
      style: { marginRight: "10px" },
    },
  ];

  const handleSaveChanges = () => {
    console.log(currentItem, "currentItem");
    if (currentItem) {
      const updatedData = {
        ...currentItem,
        commenter_name: currentItem.commenter_name,
        gender: parseInt(currentItem.gender),
        stars: parseInt(currentItem.stars),
        comment:
          typeof currentItem.comment === "object"
            ? currentItem.comment.toString()
            : currentItem.comment, // Assuming Quill might produce an object
        avatar,
      };
      console.log(updatedData, "updatedData");
      updateCommentMutation.mutate(updatedData); // Modified this line
      setShowModal(false);
    }
  };

  return (
    <div>
      <TableListComponent columns={columns} actions={actions} data={data} />

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Yorumu ayarla</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Yorumcu</Form.Label>
              <Form.Control
                type="text"
                value={currentItem?.commenter_name || ""}
                onChange={(e) =>
                  setCurrentItem({
                    ...currentItem,
                    commenter_name: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Yorum</Form.Label>
              <Quill
                value={currentItem?.comment || ""}
                onChange={(content) =>
                  setCurrentItem({ ...currentItem, comment: content })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Cinsiyet</Form.Label>
              <Form.Select
                name="gender"
                value={currentItem?.gender || ""}
                onChange={(e) =>
                  setCurrentItem({ ...currentItem, gender: e.target.value })
                }
              >
                <option value="" disabled>
                  {currentItem?.gender === 0 ? "Ekek" : "Kadın"}
                </option>
                <option value="0">Erkek</option>
                <option value="1">Kiz</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Yıldız</Form.Label>
              <Form.Select
                name="stars"
                value={currentItem?.stars || ""}
                onChange={(e) =>
                  setCurrentItem({ ...currentItem, stars: e.target.value })
                }
              >
                <option value="" disabled></option>
                <option value="1">Boş Bırak</option>
                <option value="2">1 yıldız</option>
                <option value="3">2 yıldız</option>
                <option value="4">3 yıldız</option>
                <option value="5">4 yıldız</option>
                <option value="6">5 yıldız</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3 row">
              <Form.Label>Avatar</Form.Label>
              <div className="col-md-6 d-flex align-items-center justify-content-center">
                <Form.Control
                  type="file"
                  name="avatar"
                  onChange={handleAvatarChange}
                />
              </div>
              <div className="col-12 d-flex align-items-center justify-content-center">
                {imageURL && (
                  <img
                    src={imageURL2}
                    alt="avatar Image"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                )}
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default IndexComments;
