import React from "react";

export const AccommodationInformation = () => {
  return (
    <div>
      <div class="online-section-title mb-4">
        <span class="glyphicon glyphicon-info-sign p3"></span> Konaklama
        Bilgileri
      </div>
      <div className="row form-horizontal">
        <div className="col-sm-6">
          <div className="row form-group">
            <label className="control-label col-sm-12 col-md-4 fw-600">
              Otel Adı :
            </label>
            <div className="col-sm-12 col-md-8">
              201. Grup – RAMAZAN - 37 Gün – 3 Gece Medine / 33 Gece Mekke
            </div>
          </div>
          <div className="row form-group">
            <label className="control-label col-sm-12 col-md-4 fw-600">
              Giriş Tarihi :
            </label>
            <div class="col-sm-12 col-md-8">18.03.2023</div>
          </div>
          <div className="row form-group">
            <label className="control-label col-sm-12 col-md-4 fw-600">
              Çıkış Tarihi :
            </label>
            <div className="col-sm-12 col-md-8">24.04.2023</div>
          </div>
        </div>
        <div class="col-sm-6">
          <div className="row form-group">
            <label className="control-label col-sm-12 col-md-4 fw-600">
              Konsept :
            </label>
            <div className="col-sm-12 col-md-8">Yarım Pansiyon</div>
          </div>
          <div className="row form-group">
            <label className="control-label col-sm-12 col-md-4 fw-600">
              Oda Tipi :
            </label>
            <div className="col-sm-12 col-md-8">2'Lİ ODA</div>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
      <hr />
    </div>
  );
};
