import React from "react";
import { Card } from "react-bootstrap";

export const CardHeader = ({ Title }) => {
  return (
    <Card.Header
      style={{
        backgroundColor: "#636665",
        color: "white",
        borderRadius: "5px 5px 0 0",
      }}
    >
      {" "}
      <h1 style={{ padding: "10px", fontSize: "24px", margin: 0 }}>{Title}</h1>
    </Card.Header>
  );
};
