import { axios } from "common/utils/axios";

export const createHotel = async (hotelData) => {
  return axios.post(`api/hotels`, hotelData, {
    header: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const fetchHotels = async ({ page = 1, filters = {} }) => {
  const params = new URLSearchParams({ ...filters, page });
  console.log(params, "params");
  const response = await axios.get(`api/hotels?${params}`);
  return response.data;
};
export const fetchHotel = async (hotelName) => {
  const response = await axios.get(`api/hotels/name/${hotelName}`);
  return response.data;
};
export const deleteHotel = async (hotelId) => {
  return axios.delete(`api/hotels/${hotelId}`);
};

export const updateHotel = async (hotelId, hotelData) => {
  const response = await axios.put(`/api/hotels/${hotelId}`, hotelData);
  return response.data;
};

export const getHotelsName = async ({ filters = {} }) => {
  const params = new URLSearchParams({ ...filters });
  const response = await axios.get(`api/hotels-names?${params}`);
  return response.data;
};
