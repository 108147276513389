import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { axios } from "common/utils/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";

const Notlar = () => {
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const [customerNote, setCustomerNote] = useState([]);
  const [customerCreateNote, setCustomerCreateNote] = useState({
    note: "",
  });

  const handleDelete = (notid) => {
    axios
      .delete(`/api/notes/${notid}`)
      .then((response) => {
        console.log("Note deleted:", notid);
        setCustomerNote(customerNote.filter((note) => note.id !== notid)); // Remove deleted note from state array
      })
      .catch((error) => console.error(error));
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleNoteChange = (event) => {
    setCustomerCreateNote({
      ...customerCreateNote,
      note: event.target.value,
    });
  };

  const handleSave = () => {
    const data = {
      note: customerCreateNote.note,
      customer_id: id,
    };

    axios
      .post(`/api/customer/${id}/specialnotes`, data)
      .then((response) => {
        console.log(response);
        setCustomerCreateNote({ note: "" });

        toast.success("Note saved successfully!"); // Display success toast

        handleClose(); // Close the popup
      })
      .catch((error) => {
        console.error(error);
        toast.error("An error occurred."); // Display error toast
      });
  };

  return (
    <div>
      <ToastContainer />
      <Button variant="primary" onClick={handleShow}>
        <FontAwesomeIcon icon={faPlus} /> Yeni Not Ekle
      </Button>

      <Modal show={show} onHide={handleClose} size="md" centered>
        <Modal.Header closeButton>
          <Modal.Title>Notunuzu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Lütfen notunuzu giriniz.</Form.Label>
            <Form.Control
              as="textarea"
              rows={4} // Number of visible rows
              autoFocus
              value={customerCreateNote.note}
              onChange={handleNoteChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Kapat
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Kaydet
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Notlar;
