import { Card, Form, Row, Col } from "react-bootstrap";
import { CardHeader } from "../CRUD/CardHeader";
import PostImage from "area/admin/CRUD/ReactBootsrap/FormUplaodInput";
import TableListComponent from "../CRUD/TableListComponent";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  getCompanies,
  createCompany,
  deleteCompany,
  updateCompany,
} from "common/Api/CompaniesApi";
import { useState } from "react";

const columns = [
  {
    label: "Logo",
    field: "logo",
    render: (logoBase64) =>
      logoBase64 ? (
        <img
          src={logoBase64}
          alt="Company Logo"
          style={{ width: "90px", height: "50px" }}
          onError={(e) => console.log("Error loading image:", e)}
        />
      ) : (
        "No Logo Available"
      ),
  },
  {
    label: "Firma İsmi",
    field: "companyName",
  },
  // Add other columns as needed
];

export const Companies = () => {
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState({ companyName: "", logo: "" });
  const [imageURL, setImageURL] = useState(null);
  const { data, isLoading, isError } = useQuery("Companies", getCompanies, {
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
  const mutationCreate = useMutation(createCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries("Companies");
      toast.success("Şirketler başarıyla eklendi!");
      setFormData({ companyName: "", logo: "" });
    },
    onError: (error) => {
      const errorMsg =
        error?.response?.data?.message ||
        "Şirketlerin eklenmesi sırasında bir hata oluştu.";
      toast.error(errorMsg);
    },
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const deleteCompanyMutation = useMutation(deleteCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries("Companies");
      toast.success("Şirket başarıyla silindi!");
    },
    onError: (error) => {
      const errorMsg =
        error?.response?.data?.message ||
        "Şirketin silinmesi sırasında bir hata oluştu.";
      toast.error(errorMsg);
    },
  });
  const actions = [
    {
      label: "Sil",
      variant: "danger",
      onClick: (item) => {
        console.log("Deleting company with ID:", item.companyId);
        deleteCompanyMutation.mutate(item.companyId);
      },
    },
  ];
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, logo: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };
  console.log(data, "data");
  return (
    <div>
      <Card>
        <CardHeader Title={"Firmalar"} />
        <Card.Body>
          <Row className="d-flex justify-content-center">
            <Col xl={8}>
              <Card>
                {data?.map((company) => (
                  <div key={company.id}>
                    {" "}
                    <div className="text-cener">
                      <h6>{company?.companyName}</h6>
                    </div>
                    {company?.logo ? (
                      <img
                        src={company.logo}
                        alt="Company Logo"
                        style={{ width: "110px", height: "70px" }}
                        onError={(e) => (e.target.style.display = "none")} // Hide image if it fails to load
                      />
                    ) : (
                      <p>No Logo Available</p>
                    )}
                  </div>
                ))}
              </Card>
            </Col>
            <Col xl={4}>
              <Card className="pl-4 pr-4">
                <Form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    console.log(formData.logo);
                    const jsonData = {
                      companyName: formData.companyName,
                      logo: formData.logo,
                    };
                    mutationCreate.mutate(jsonData);
                  }}
                >
                  <Form.Group controlId="turAdi">
                    <Form.Label>
                      <strong>Şirket Adı</strong>
                    </Form.Label>
                    <Form.Control
                      name="companyName"
                      type="text"
                      placeholder="Şirket Adı"
                      onChange={handleChange}
                      value={formData.companyName}
                    />
                  </Form.Group>

                  <PostImage
                    editWithCustomStyle={true}
                    imageSrc={formData.logo}
                    label={"Logo"}
                    name={"logo"}
                    onChange={handleFileChange}
                  />

                  <Form.Group className="mb-3 text-end">
                    <button type="submit" className="btn btn-primary">
                      Ekle
                    </button>
                  </Form.Group>
                </Form>
              </Card>
            </Col>
          </Row>
          <div className="mt-4">
            <TableListComponent
              data={data}
              columns={columns}
              actions={actions}
            />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};
export default Companies;
