import React from "react";
import { Button, Modal } from "react-bootstrap";
import { axios } from "common/utils/axios";

const ReservationCancellation = ({
  code,
  show,
  handleClose,
  setReservations,
  reservations,
}) => {
  const handleConfirmation = () => {
    const url = `/api/reservations/${code}/durum`;

    axios
      .put(url, { durum: "iptal" })
      .then(() => {
        setReservations(
          reservations.map((reservation) =>
            reservation.code === code
              ? { ...reservation, durum: "iptal" }
              : reservation
          )
        );
        handleClose();
      })
      .catch((error) => {
        console.error(`There was an error updating the reservation: ${error}`);
      });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to iptal this?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button active={true} variant="secondary" onClick={handleClose}>
          No
        </Button>
        <Button active={true} variant="primary" onClick={handleConfirmation}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReservationCancellation;
