import React, { useState } from "react";
import { axios } from "common/utils/axios";
import { toast, ToastContainer } from "react-toastify";

import { Button } from "react-bootstrap";
export const FromWhereYouHeard = () => {
  const [heardData, setHeardData] = useState({
    data: {
      from_where_you_heard_about_us: "",
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("api/heard", {
        data: {
          from_where_you_heard_about_us:
            heardData.data.from_where_you_heard_about_us,
        },
      })
      .then((response) => {
        console.log(response.data);
        setHeardData({ data: { from_where_you_heard_about_us: "" } });
        toast.success("Form submitted successfully!"); // show success toast message
      })
      .catch((error) => {
        console.error(error);
        toast.error("Error submitting form"); // show error toast message
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHeardData((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [name]: value,
      },
    }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <label style={{ marginRight: "1rem" }}>Bizi nereden buldu?</label>
        <input
          value={heardData.data.from_where_you_heard_about_us}
          name="from_where_you_heard_about_us"
          onChange={handleChange}
          type="text"
          className="m-4 rounded h3"
          style={{ flex: 1 }}
        />
        <Button active={true} type="submit" className="btn btn-primary">
          {" "}
          Create{" "}
        </Button>
      </div>
      <ToastContainer />
    </form>
  );
};

export default FromWhereYouHeard;
