import React, { useEffect, useState } from "react";
import { axios } from "common/utils/axios";
import MapComponent from "./OtelEditModalComponents/MapComponent";
import OtelDescComponent from "./OtelEditModalComponents/OtelDescComponent";
import OtelFeaturesComponent from "./OtelEditModalComponents/OtelFeaturesComponent";
import OtelFoodsComponent from "./OtelEditModalComponents/OtelFoodsComponent";
import OtelHoneymoonComponent from "./OtelEditModalComponents/OtelHoneymoonComponent";
import OtelImageComponent from "./OtelEditModalComponents/OtelImageComponent";
import OtelOtherInformationComponent from "./OtelEditModalComponents/OtelOtherInformationComponent";
import OtelRoomsComponents from "./OtelEditModalComponents/OtelRoomsComponents";
import OtelSliderComponent from "./OtelEditModalComponents/OtelSliderComponent";
import OtelVideoComponent from "./OtelEditModalComponents/OtelVideoComponent";

export default function OtelsModal({ setOpenModal, otel, getOtels }) {
  const [editOtelImage, setOtelImage] = useState(otel?.cover_picture);
  const [editOtelId, setOtelId] = useState(otel?.otel_id);
  const [editOtelDesc, setOtelDesc] = useState(otel?.otel_desc);
  const [otelFetaures, setOtelFetaures] = useState(otel?.facility_features);
  const [editOtelDetailedDesc, setOtelDetailedDesc] = useState(
    otel?.detailed_desc
  );
  const [editOtelName, setOtelName] = useState(otel?.otel_name);
  const [editPositionX, setOtelPositionX] = useState(otel?.x_axsis);
  const [editPositionY, setOtelPositionY] = useState(otel?.y_axsis);
  const [otelFoodsTitle, setOtelFoodsTitle] = useState(
    otel?.food_pack_name ? otel?.food_pack_name : ""
  );
  const [otelFoods, setOtelFoods] = useState(
    otel?.food_desc ? otel?.food_desc : ""
  );
  const [video, setVideo] = useState(otel?.video_link);
  const [specialForHoneymoon, setSpecialForHoneymoon] = useState(
    otel?.specialForHoneymoon ? otel?.specialForHoneymoon : []
  );
  const [slider, setSlider] = useState(otel?.sliders ? otel?.sliders : []);
  const [otherInformations, setOtherInformations] = useState({
    animal: otel?.animal,
    check_in: otel?.check_in,
    check_out: otel?.check_out,
    payment_info: otel?.payment_info,
  });
  const [rooms, setRooms] = useState(otel?.rooms);

  const handleSubmit = async () => {
    setOpenModal([]);
    console.log(editOtelImage);
    const res = await axios.post("api/otel/update", {
      otel_id: editOtelId,
      otel_name: editOtelName,
      otel_desc: editOtelDesc,
      otel_detailed_desc: editOtelDetailedDesc,
      cover_picture: editOtelImage,
      position: { x_axsis: editPositionX, y_axsis: editPositionY },
      otelFetaures: otelFetaures,
      otelFoodsTitle: otelFoodsTitle,
      otelFoods: otelFoods,
      video: video,
      specialForHoneymoon: specialForHoneymoon,
      slider: slider,
      otherInformations: otherInformations,
      rooms: rooms,
    });
    if (res) {
      console.log(res);
      getOtels();
    }
  };
  return (
    <div>
      <div className="fixed z-10 w-screen h-full overlay-0 top-0 bottom-0 right-0 left-0 bg-black/[0.9]">
        <div className="sticky top-0 h-screen w-2/5 mx-auto grid">
          <div className=" sticky bg-white m-auto overflow-x-auto h-[80vh] p-5 bg-gray-50">
            <div>
              <div>
                <MapComponent
                  editPositionX={editPositionX}
                  editPositionY={editPositionY}
                  setOtelPositionX={setOtelPositionX}
                  setOtelPositionY={setOtelPositionY}
                />

                <OtelImageComponent
                  editOtelImage={editOtelImage}
                  setOtelImage={setOtelImage}
                />

                <OtelDescComponent
                  setOtelName={setOtelName}
                  setOtelDesc={setOtelDesc}
                  setOtelDetailedDesc={setOtelDetailedDesc}
                  editOtelName={editOtelName}
                  editOtelDesc={editOtelDesc}
                  editOtelDetailedDesc={editOtelDetailedDesc}
                />

                <OtelFeaturesComponent
                  setOtelFetaures={setOtelFetaures}
                  otelFetaures={otelFetaures}
                />

                <OtelFoodsComponent
                  otelFoodsTitle={otelFoodsTitle}
                  otelFoods={otelFoods}
                  setOtelFoodsTitle={setOtelFoodsTitle}
                  setOtelFoods={setOtelFoods}
                />

                <OtelHoneymoonComponent
                  setSpecialForHoneymoon={setSpecialForHoneymoon}
                  specialForHoneymoon={specialForHoneymoon}
                />
                <OtelSliderComponent setSlider={setSlider} slider={slider} />

                <OtelVideoComponent setVideo={setVideo} video={video} />

                <OtelOtherInformationComponent
                  setOtherInformations={setOtherInformations}
                  otherInformations={otherInformations}
                />

                <OtelRoomsComponents setRooms={setRooms} rooms={rooms} />

                <div className="flex justify-end gap-2 mt-2">
                  <button
                    className="bg-red-300 text-white p-2"
                    onClick={() => setOpenModal([])}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-blue-300 text-white p-2"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Tamamla
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
