import React from "react";

export default function ImageComponent({editOtelImage, setOtelImage}) {
	const handleOnChangeUrl = (e) => {
		console.log(e.target.value);
		setOtelImage(e.target.value);
	};
	return (
		<div>
			<label htmlFor="ressim">Resmin Kendisi</label>
			<br />
			<img
				className="w-2/5"
				src={editOtelImage}
				alt=""
			/>
			<label htmlFor="imageurl">Resmin Adresi</label>
			<br />
			<textarea
				className="border-2"
				name="imageurl"
				rows="4"
				cols="50"
				value={editOtelImage}
				onChange={handleOnChangeUrl}></textarea>
			<br />
		</div>
	);
}
