import React, { useState } from "react";
import { axios } from "common/utils/axios";
import { toast, ToastContainer } from "react-toastify";

import { Button } from "react-bootstrap";
export const CustomerDocumentType = () => {
  const [heardData, setHeardData] = useState({
    data: {
      document_type: "",
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("api/customer-document-type", {
        data: {
          document_type: heardData.data.document_type,
        },
      })
      .then((response) => {
        console.log(response.data);
        setHeardData({ data: { document_type: "" } });
        toast.success("Form submitted successfully!"); // show success toast message
      })
      .catch((error) => {
        console.error(error);
        toast.error("Error submitting form"); // show error toast message
      });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setHeardData((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [name]: value,
      },
    }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <label style={{ marginRight: "1rem" }}>Document Type</label>
        <input
          value={heardData.data.document_type}
          name="document_type"
          onChange={handleChange}
          type="text"
          className="m-4 rounded h3"
          style={{ flex: 1 }}
        />
        <Button active={true} type="submit" className="btn btn-primary">
          {" "}
          Create{" "}
        </Button>
      </div>
      <ToastContainer />
    </form>
  );
};

export default CustomerDocumentType;
