import { Form, Col } from "react-bootstrap";
import "assets/styles/AdvertismentManger.css";
export default ({ id, label, checked, onChange, name }) => {
  return (
    <Form.Group controlId={id}>
      <Form.Label style={{ inlineSize: "-webkit-fill-available" }}>
        {label}
      </Form.Label>
      <div className="custom-switch">
        <input
          name={name}
          type="checkbox"
          id={id}
          className="custom-switch-input"
          checked={checked}
          onChange={onChange}
        />
        <label className="custom-switch-label" htmlFor={id}></label>
      </div>
    </Form.Group>
  );
};
