import { useState, useEffect } from "react";
import { axios } from "common/utils/axios";

export const useToursData = () => {
  const [toursData, setToursData] = useState([]);

  useEffect(() => {
    axios
      .get(`/api/tours`)
      .then((response) => setToursData(response.data))
      .catch((error) => console.error(error));
  }, []);

  return toursData;
};
