export const HamburgerButton = ({ isOpen, toggleSidebar }) => {
  return (
    <button
      onClick={toggleSidebar}
      style={{
        marginLeft: "20px",
        marginTop: "20px",
        marginBottom: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        width: "24px",
        height: "18px",
        background: "transparent",
        border: "none",
        cursor: "pointer",
        padding: 0,
        zIndex: 10,
      }}
    >
      <div
        style={{
          width: "24px",
          height: "2px",
          background: isOpen ? "transparent" : "white",
          transition: "all 0.3s linear",
        }}
      ></div>
      <div
        style={{
          width: "24px",
          height: "2px",
          background: "white",
          transform: isOpen ? "rotate(-45deg) translate(-5px, 6px)" : "none",
          transition: "all 0.3s linear",
        }}
      ></div>
      <div
        style={{
          width: "24px",
          height: "2px",
          background: "white",
          transform: isOpen ? "rotate(45deg) translate(-5px, -6px)" : "none",
          transition: "all 0.3s linear",
        }}
      ></div>
    </button>
  );
};
