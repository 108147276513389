import React, { useEffect, useState } from "react";
import { axios } from "common/utils/axios";

export default function OtelFeaturesComponent({
  setOtelFetaures,
  otelFetaures,
}) {
  const [otelFetaure, setOtelFetaure] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [currentTitle, setCurrentTitle] = useState();
  const [otelFetaureTitles, setOtelFetaureTitles] = useState([]);

  useEffect(() => {
    getOtelFeatureTitles();
  }, []);

  const getOtelFeatureTitles = async () => {
    const res = await axios.get("api/otel/features/title");
    if (res) {
      console.log(res);
      setOtelFetaureTitles(res.data);
    }
  };
  const handleOtelFeatureInput = (e) => {
    setOtelFetaure(e.target.value);
  };
  const addOtelFeature = () => {
    console.log("currents", currentTitle, otelFetaure);
    let obj = { title_id: currentTitle, feature: otelFetaure };
    console.log("obj", obj);
    setOtelFetaures([...otelFetaures, obj]);
    setOtelFetaure("");
    console.log(otelFetaures);
  };
  const handleselect = (e) => {
    console.log("select changed");
    console.log(e.target.value);
    setShowInput(true);
    setCurrentTitle(e.target.value);
    // let arr = otelFetaure;
    // setOtelFetaure([...arr,{}]);
  };
  const deleteFeature = (e) => {
    console.log(e.target.value);
    let arr = otelFetaures;
    arr.splice(e.target.value, 1);
    setOtelFetaures([...arr]);
  };
  return (
    <div>
      <label>Otel Tesis Özellikleri</label>
      <br />
      <select onChange={handleselect} name="Chose Title" id="">
        <option value="">Seçiniz</option>
        {otelFetaureTitles.map((item, index) => {
          return (
            <option key={index} value={item.title_id}>
              {item.title}
            </option>
          );
        })}
      </select>
      <br />
      <input
        type="text"
        value={otelFetaure}
        onChange={handleOtelFeatureInput}
        className={showInput ? "" : "hidden"}
      />
      <button className={showInput ? "" : "hidden"} onClick={addOtelFeature}>
        add
      </button>
      <br />

      {otelFetaures?.map((item, index) => {
        return (
          <>
            <div key={index} className="inline-block p-3">
              <span>{item.feature} </span>
              <button
                value={index}
                onClick={deleteFeature}
                className="text-red-500"
              >
                X
              </button>
            </div>
          </>
        );
      })}

      <br />
    </div>
  );
}
