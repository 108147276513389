import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { axios } from "common/utils/axios";
import { CategoriesContext } from "./Contexts";
import { useLoading } from "./LoadingContext";

const fetchCategories = async () => {
  const { data } = await axios.get("/api/categories");
  return data;
};

const CategoriesProvider = ({ children }) => {
  const { setLoading } = useLoading();

  const { data: categories, isLoading } = useQuery(
    "categories",
    fetchCategories,
    {
      onSuccess: () => setLoading(false),
      onError: () => setLoading(false),
    },
    {
      staleTime: 10000 * 60 * 5,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  useEffect(() => {
    if (isLoading) {
      setLoading(true);
    }
  }, [isLoading, setLoading]);

  return (
    <CategoriesContext.Provider value={{ categories, isLoading }}>
      {children}
    </CategoriesContext.Provider>
  );
};

export default CategoriesProvider;
