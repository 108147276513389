import { useState, useEffect } from "react";
import { axios } from "../axios";
const UseTourDetails = (id) => {
  const [tour, setTour] = useState(null);
  useEffect(() => {
    const fetchTourDetails = async () => {
      try {
        const response = await axios.get(`api/tour/${id}`);
        setTour(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTourDetails();
  }, [id]);

  return tour;
};

export default UseTourDetails;
