import React from "react";

export default function OtelVideosComponent({setVideo, video}) {
	const videoHandle = (e) => {
		setVideo(e.target.value);
	};
	return (
		<div>
			{" "}
			<label> Video embeded link</label>
			<br />
			<input
				type="text"
				value={video}
				onChange={videoHandle}
			/>
			<br />
		</div>
	);
}
