import { useState, useRef, useEffect } from "react";
import { axios } from "common/utils/axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import "font-awesome/css/font-awesome.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";

const scrollableToastContainerStyle = {
  maxHeight: "200px",
  overflowY: "auto",
  backgroundColor: "#cdcdcd47",
};

const CallToast = ({ show, callerNumber, onClose, callSecnario }) => {
  const [showClearButton, setShowClearButton] = useState(true);
  const toastIdRef = useRef(null);
  const lastScenarioRef = useRef(null);
  const lastCallerRef = useRef(null);

  const handleClearAll = () => {
    toast.dismiss();
    setShowClearButton(false);
  };

  const fetchCallerDetails = async (callerNumber) => {
    const response = await axios.get(`api/callerDetails`, {
      params: {
        number: callerNumber,
      },
    });
    return response.data;
  };

  const { data: callerDetails } = useQuery(
    ["callerDetails", callerNumber],
    () => fetchCallerDetails(callerNumber),
    {
      enabled: !!callerNumber,
    }
  );

  const getScenarioIconAndColor = (scenario) => {
    switch (scenario) {
      case "Inbound_call":
        return { color: "green", icon: "fa-phone", senarioCall: "GELEN ARAMA" };
      case "Outbound_call":
        return { color: "blue", icon: "fa-phone", senarioCall: "GİDEN ARAMA" };
      case "Local_call":
        return { color: "grey", icon: "fa-phone" };
      case "Answer":
        return {
          color: "green",
          icon: "fa-check-circle",
          senarioCall: "Cevap Verildi",
        };
      case "Hangup":
        return {
          color: "red",
          icon: "fa-times-circle",
          senarioCall: "Kappati ",
        };
      case "Queue":
        return {
          color: "yellow",
          icon: "fa-hourglass-half",
          senarioCall: "Cevap Bekliyor",
        };
      case "InboundtoPBX":
        return { color: "purple", icon: "fa-exchange" };
      case "Context":
        return { color: "orange", icon: "fa-map-signs" };
      case "DTMF":
        return { color: "pink", icon: "fa-keyboard-o" };
      case "QueueLeave":
        return { color: "cyan", icon: "fa-outdent" };
      case "cdr":
        return { color: "brown", icon: "fa-clipboard" };
      default:
        return { color: "black", icon: "fa-question-circle" };
    }
  };

  useEffect(() => {
    const isScenarioChanged = callSecnario !== lastScenarioRef.current;
    const isCallerChanged = callerNumber !== lastCallerRef.current;

    if (show && (isScenarioChanged || isCallerChanged)) {
      if (toastIdRef.current) {
        toast.dismiss(toastIdRef.current);
      }

      const newToastId = toast(
        ({ closeToast }) => (
          <div>
            <h4>
              <i
                className={`fa ${icon}`}
                style={{ color: color, marginRight: "10px" }}
              ></i>
              {senarioCall}
            </h4>
            {callerDetails && (
              <div>
                <Link
                  to={`/admin/customerManagement/customers/${callerDetails.customerId}`}
                >
                  <FontAwesomeIcon
                    icon={faUserCircle}
                    style={{ marginRight: "5px", fontSize: "24px" }}
                  />
                  Profile
                </Link>
                <p>
                  Ismi: {callerDetails.name} <br /> rakami: {callerNumber}
                </p>
              </div>
            )}
            {!callerDetails && <p>Caller: {callerNumber}</p>}
          </div>
        ),
        {
          position: "bottom-right",
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          closeButton: true,
          onOpen: () => setShowClearButton(true),
        }
      );

      toastIdRef.current = newToastId;
      lastScenarioRef.current = callSecnario;
      lastCallerRef.current = callerNumber;
    }
  }, [show, callSecnario, callerNumber]);

  const { color, icon, senarioCall } = getScenarioIconAndColor(callSecnario);

  return (
    <div>
      {/**
    {showClearButton && (
      <button
      style={{
        paddingLeft: "4px",
        paddingRight: "4px",
        borderRadius: "4px",
        backgroundColor: "#c6c6c657",
        fontWeight: "600",
        position: "fixed",
        bottom: "220px",
        right: "38px",
        zIndex: 9999,
      }}
      onClick={handleClearAll}
      >
      Clear ALL
      </button>
      )}
    */}
      <ToastContainer style={scrollableToastContainerStyle} />
    </div>
  );
};

export default CallToast;
