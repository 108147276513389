import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import "assets/styles/TourHotelComments.css";
const Comment = ({ avatar, comment, date, rating }) => {
  return (
    <div className="comment">
      <div className="avatar">
        <img src={avatar} alt="User Avatar" />
      </div>
      <div className="comment-content">
        <div className="rating f12">
          {[...Array(5)].map((_, i) => (
            <FontAwesomeIcon
              key={i}
              icon={faStar}
              color={i < rating ? "#ffc107" : "#e4e5e9"}
            />
          ))}
        </div>
        <div className="comment-text f12">{comment}</div>
        <div className="comment-date f12">{date}</div>
      </div>
    </div>
  );
};

export default () => {
  // Sample comments data
  const comments = [
    {
      avatar: "https://dummyimage.com/300x250",
      comment: "Great hotel, very comfortable.",
      date: "2023-01-10",
      rating: 4,
    },
    {
      avatar: "https://dummyimage.com/300x250",
      comment: "Lovely place, enjoyed my stay!",
      date: "2023-01-15",
      rating: 5,
    },
    // ... more comments
  ];

  return (
    <div>
      <Row>
        <Col xl={12}>
          <div className="comments-section">
            {comments.slice(0, 2).map((comment, index) => (
              <Comment key={index} {...comment} />
            ))}
            <Button variant="primary responsive-margin f12">
              Daha Fazla Yorumu Gör
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
