import { useState } from "react";
import { Card, Form, Col, Row } from "react-bootstrap";
import { CardHeader } from "../CRUD/CardHeader";
import Quill from "react-quill";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "common/Api/CommentsApi";
import { ToastContainer, toast } from "react-toastify";
import { useRef } from "react";
import { Link } from "react-router-dom";

import CreatButton from "area/admin/CRUD/CreatButton";
import IndexComments from "area/admin/Comments/IndexComments";
export const CreateComment = () => {
  const queryClient = useQueryClient();
  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState({
    commenter_name: "",
    comment: "",
    avatar: "",
    stars: "",
    gender: "",
  });
  const { data, isLoading, isError } = useQuery("comments", api.getComments, {
    staleTime: 1000 * 60 * 5,
  });

  const [imageURL, setImageURL] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let avatar_base64;
      // Check if the avatar is an instance of Blob (file)
      if (formData.avatar instanceof Blob) {
        avatar_base64 = await toBase64(formData.avatar);
      }

      // Construct the JSON object with all the data
      const jsonData = {
        commenter_name: formData.commenter_name,
        comment: formData.comment,
        avatar: avatar_base64,
        stars: formData.stars,
        gender: formData.gender,
      };

      mutationCreate.mutate(jsonData);
    } catch (error) {
      console.error("Yorum gönderme hatası.", error);
      toast.error("Yorumunuz işlenirken bir hata oluştu..");
    }
  };
  const handleQuillChange = (content) => {
    setFormData((prev) => ({ ...prev, comment: content }));
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const newImageURL = URL.createObjectURL(file);

    // Update the imageURL state
    setImageURL(newImageURL);
    setFormData((prevState) => ({
      ...prevState,
      avatar: file,
    }));
  };
  const deleteCommentMutation = useMutation(api.deleteComments, {
    onSuccess: () => {
      queryClient.invalidateQueries("comments");
      toast.success("Yorumlar başarıyla silindi.!");
      localStorage.setItem("shouldRefetchComments", "true");
    },
    onError: (error) => {
      const errorMsg =
        error?.response?.data?.message || "Yorumları silme hatası..";
      toast.error(errorMsg);
    },
  });

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  // React Query's mutation for creating comments
  const mutationCreate = useMutation(api.createComments, {
    onSuccess: () => {
      queryClient.invalidateQueries("comments");
      toast.success("Yorum başarıyla eklendi.!");
      setFormData({
        commenter_name: "",
        comment: "",
        avatar: "",
        stars: "",
        gender: "",
      });
      setImageURL(null);

      // Clear the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      localStorage.setItem("shouldRefetchComments", "true");
    },
    onError: (error) => {
      // Handling API errors
      const errorMsg = error?.response?.data?.message || "Error adding comment";
      toast.error(errorMsg);
    },
  });
  const updateCommentMutation = useMutation(api.updateComments, {
    onSuccess: () => {
      queryClient.invalidateQueries("comments");
      toast.success("Yorum başarıyla güncellendi.!");
      localStorage.setItem("shouldRefetchComments", "true");
    },
    onError: (error) => {
      const errorMsg =
        error?.response?.data?.message || "Yorum güncelleme hatası..";
      toast.error(errorMsg);
    },
  });
  if (isLoading) {
    return <div>Loading...</div>;
  }
  const handleRemoveAvatar = () => {
    setImageURL(null); // Clear the image URL to remove the preview
    setFormData({ ...formData, avatar: "" }); // Clear the avatar field in the form data
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset the file input
    }
  };
  return (
    <div>
      <Card>
        <Card.Header
          style={{
            backgroundColor: "#636665",
            color: "white",
            borderRadius: "5px 5px 0 0",
          }}
        >
          <Row>
            <Col>
              <h1 style={{ padding: "10px", fontSize: "24px", margin: 0 }}>
                Yorumlar Yönetimi
              </h1>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Row>
                <Col>
                  <Link to="/admin/comments/create">
                    <CreatButton title={"Yeni yorum Ekle "} />
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          {" "}
          <IndexComments
            data={data}
            deleteCommentMutation={deleteCommentMutation}
            updateCommentMutation={updateCommentMutation}
          />
        </Card.Body>
      </Card>

      <ToastContainer />
    </div>
  );
};
export default CreateComment;
