import React, { useState } from "react";
import { axios } from "common/utils/axios";
import { toast } from "react-toastify";
import { fetchContactDetails } from "common/Api/SiteSettingsApi";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";
import CreatButton from "area/admin/CRUD/CreatButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
export const ContactUs = ({ handleClickCreatButton }) => {
  const [googleMapsUrl, setGoogleMapsUrl] = useState("");
  const queryClient = useQueryClient();
  const [contactDetails, setContactDetails] = useState({
    telefon: "",
    email: "",
    facebook: "",
    instagram: "",
    twitter: "",
    linkedin: "",
    whatsapp: "",
    youtube: "",
    address_link: "",
    open_address: "",
    google_maps_intergration: "",
  });
  const [isPhoneOpen, setIsPhoneOpen] = useState(true);
  const [isSocialMediaOpen, setIsSocialMediaOpen] = useState(true);
  const [isKonumOpen, setIsKonumOpen] = useState(true);

  const { data: contactData } = useQuery(
    "contactDetails",
    fetchContactDetails,
    {
      onSuccess: (data) => {
        setContactDetails(data.data);
        setGoogleMapsUrl(data.data.google_maps_intergration);
      },
    }
  );

  const handleGoogleMapsUrlChange = (event) => {
    setGoogleMapsUrl(event.target.value);
  };

  const mutation = useMutation(
    (newContactDetails) => axios.post("/api/contact", newContactDetails),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("contactDetails");
        toast.success("Data Saved Successfuly");
      },
    }
  );

  const handleChange = (e) => {
    setContactDetails({ ...contactDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation.mutate(contactDetails);
  };
  console.log(contactData, "contactData");
  return (
    <Card style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)" }}>
      <Card.Header
        style={{
          backgroundColor: "#636665",
          color: "white",
          borderRadius: "5px 5px 0 0",
        }}
      >
        <Row>
          <Col>
            <h1 style={{ padding: "10px", fontSize: "24px", margin: 0 }}>
              İletişim Bilgileri
            </h1>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Row>
              <Col>
                <CreatButton title={"Kaydet "} onClick={handleSubmit} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Header>
      <Row>
        <Col xl={6} className="mb-2 mt-2">
          <Card className="p-4">
            <div
              className="social-links"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <span style={{ fontWeight: "bold" }}>Telefon</span>
              <span
                style={{
                  marginBottom: "10px",
                }}
              >
                {contactData?.data.telefon}
              </span>

              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  marginBottom: "10px",
                }}
              >
                {contactData?.data.email}
              </a>
              <hr
                style={{
                  width: "100%",
                  borderTop: "1px solid black",
                  margin: "2px 0",
                }}
              />
              <a
                href={`https://${contactData?.data.facebook}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "blue",

                  marginBottom: "10px",
                }}
              >
                <FontAwesomeIcon icon={faLink} /> Facebook
              </a>
              <hr
                style={{
                  width: "100%",
                  borderTop: "1px solid black",
                  margin: "2px 0",
                }}
              />
              <a
                href={`https://${contactData?.data.instagram}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "blue",

                  marginBottom: "10px",
                }}
              >
                <FontAwesomeIcon icon={faLink} /> Instagram
              </a>
              <hr
                style={{
                  width: "100%",
                  borderTop: "1px solid black",
                  margin: "2px 0",
                }}
              />
              <a
                href={`https://${contactData?.data.linkedin}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "blue",

                  marginBottom: "10px",
                }}
              >
                <FontAwesomeIcon icon={faLink} /> LinkedIn
              </a>
              <hr
                style={{
                  width: "100%",
                  borderTop: "1px solid black",
                  margin: "0px 0",
                }}
              />
              <a
                href={`https://${contactData?.data.twitter}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "blue",

                  marginBottom: "10px",
                }}
              >
                <FontAwesomeIcon icon={faLink} /> Twitter
              </a>
              <hr
                style={{
                  width: "100%",
                  borderTop: "1px solid black",
                  margin: "2px 0",
                }}
              />
              <a
                href="https://whatsapp.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "blue",

                  marginBottom: "10px",
                }}
              >
                <FontAwesomeIcon icon={faLink} /> WhatsApp
              </a>
            </div>
          </Card>
          <Card className="mt-3 p-3">{contactDetails.open_address}</Card>
          <Card className="mt-4">
            {googleMapsUrl && (
              <iframe
                src={googleMapsUrl}
                width="100%"
                height="410"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            )}
          </Card>
        </Col>

        <Col className="mt-2 mb-2">
          <Card className="p-3">
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Telefon</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter phone number"
                      name="telefon"
                      value={contactDetails.telefon}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Whatsapp</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="whatsapp "
                      name="whatsapp"
                      value={contactDetails.whatsapp}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      name="email"
                      value={contactDetails.email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Facebook</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="https://Facebook/"
                      name="facebook"
                      value={contactDetails.facebook}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Instagram</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="https://Instagram/"
                      name="instagram"
                      value={contactDetails.instagram}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Twitter</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="https://Twitter/"
                      name="twitter"
                      value={contactDetails.twitter}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Linkedin </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="https://Linkedin/"
                      name="linkedin"
                      value={contactDetails.linkedin}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Youtube</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="https://youtube/ "
                      name="youtube"
                      value={contactDetails.youtube}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Adres Linki</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Adres Linki"
                      name="address_link"
                      value={contactDetails.address_link}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Açık Adres</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Açık Adres"
                      name="open_address"
                      value={contactDetails.open_address}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Google Maps Entegrasyon</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Google Maps Entegrasyon"
                      name="google_maps_intergration"
                      value={contactDetails.google_maps_intergration}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <span className="d-flex justify-content-end">
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </span>
            </Form>
          </Card>
        </Col>
      </Row>
    </Card>
  );
};

export default ContactUs;
