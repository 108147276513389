import React, { useState, useEffect } from "react";
import { Table, Form, Button } from "react-bootstrap";
import { getTodayDateString } from "common/helpers/getTodayDateString";
import { isObjectEmpty } from "common/helpers/isObjectEmpty";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { getHotelsName } from "common/Api/HotelsApi";
import { useQuery } from "react-query";
const buttonStyle = {
  padding: "3px 6px",
  fontSize: "14px",
  lineHeight: "1",
  marginRight: "5px",
};

export const Accomodation = ({ accomodations, updateAccomodationData }) => {
  console.log(accomodations, "accomodationsaccomodationsaccomodations");
  const [filters, setFilters] = useState();
  const [accomodationsRows, setAccomodationRows] = useState(accomodations);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [originalRowData, setOriginalRowData] = useState(null);

  const [newAccomodationData, setNewAccomodationData] = useState({
    city: "",
    otel: "",
    startDate: "",
    endDate: "",
    nights: "",
  });

  const addAccomodationRow = () => {
    if (isObjectEmpty(newAccomodationData)) {
      toast.warn("bazı veriler eksik");
      return;
    }
    const newRows = [...accomodationsRows, newAccomodationData];
    setAccomodationRows(newRows);
    updateAccomodationData(newRows);
    setNewAccomodationData({
      city: "",
      otel: "",
      startDate: "",
      endDate: "",
      nights: "",
    });
  };

  const handleInputChange = (e, field) => {
    let value = e.target.value;
    setNewAccomodationData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleRowChange = (e, field, index) => {
    const updatedRows = [...accomodationsRows];
    updatedRows[index] = { ...updatedRows[index], [field]: e.target.value };
    setAccomodationRows(updatedRows);
  };

  const removeAccomodationsRowsRow = (index) => {
    const updatedRows = [...accomodationsRows];
    updatedRows.splice(index, 1);
    setAccomodationRows(updatedRows);
    updateAccomodationData(updatedRows);
  };

  const editAccomodationRow = (index) => {
    setEditingRowIndex(index);
    setOriginalRowData({ ...accomodationsRows[index] });
  };

  const saveRowData = (index) => {
    setEditingRowIndex(null);
    updateAccomodationData(accomodationsRows);
  };

  const cancelEdit = (index) => {
    const updatedRows = [...accomodationsRows];
    updatedRows[index] = originalRowData;
    setAccomodationRows(updatedRows);
    setEditingRowIndex(null);
    setOriginalRowData(null);
  };

  useEffect(() => {
    if (accomodations) {
      const filteredAccomodations = accomodations.filter(
        (acc) => !isObjectEmpty(acc)
      );
      setAccomodationRows(filteredAccomodations);
    } else {
      setAccomodationRows([]);
    }
  }, [accomodations]);
  const {
    data: hotelNameAndId,
    isLoading,
    isError,
  } = useQuery(["HotelsName", filters], () => getHotelsName({ filters }), {
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
  return (
    <div>
      <h5>Konaklama</h5>
      <Table responsive>
        <thead>
          <tr>
            <th style={{ width: "25%" }}>Şehir</th>
            <th style={{ width: "25%" }}>Otel Adı</th>
            <th style={{ width: "15%" }}>Başlancı Tarıhı</th>
            <th style={{ width: "10%" }}>Gece</th>
            <th style={{ width: "15%" }}>Bitiş Tarıhı</th>
            <th style={{ width: "10%" }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Form.Select
                value={newAccomodationData?.city}
                onChange={(e) => handleInputChange(e, "city")}
              >
                <option value="" hidden>
                  Şehir seç
                </option>
                <option value="Istanbul">Istanbul</option>
                <option value="Ankara">Ankara</option>
                <option value="Izmir">Izmir</option>
              </Form.Select>
            </td>
            <td>
              <Form.Select
                value={newAccomodationData?.otel}
                onChange={(e) => handleInputChange(e, "otel")}
              >
                <option value="" hidden>
                  Otel seç
                </option>
                {hotelNameAndId &&
                  hotelNameAndId.map((hotel, index) => (
                    <option key={index} value={hotel.otel_id}>
                      {hotel.otel_name}
                    </option>
                  ))}
              </Form.Select>
            </td>
            <td>
              <Form.Control
                type="date"
                value={newAccomodationData?.startDate}
                onChange={(e) => handleInputChange(e, "startDate")}
                min={getTodayDateString()}
              />
            </td>
            <td>
              <Form.Control
                type="number"
                min={1}
                max={356}
                value={newAccomodationData?.nights}
                onChange={(e) => handleInputChange(e, "nights")}
              />
            </td>
            <td>
              <Form.Control
                type="date"
                value={newAccomodationData?.endDate}
                onChange={(e) => handleInputChange(e, "endDate")}
                min={newAccomodationData?.startDate || getTodayDateString()}
              />
            </td>
            <td>
              <Button
                active={true}
                variant="success"
                onClick={addAccomodationRow}
              >
                <FontAwesomeIcon icon={faSave} />
              </Button>
            </td>
          </tr>
          {accomodationsRows.map((row, index) => {
            const isEditing = index === editingRowIndex;
            console.log(row, "rowrowrow");
            return (
              <tr key={index}>
                <td>
                  {isEditing ? (
                    <Form.Control
                      type="text"
                      value={row.city}
                      onChange={(e) => handleRowChange(e, "city", index)}
                    />
                  ) : (
                    row.city
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <Form.Select
                      value={newAccomodationData?.otel}
                      onChange={(e) => handleInputChange(e, "otel")}
                    >
                      <option key={index} value={row.otel_id}>
                        {row.otel_name}
                      </option>
                      {hotelNameAndId &&
                        hotelNameAndId.map((hotel, index) => (
                          <option key={index} value={hotel.otel_id}>
                            {hotel.otel_name}
                          </option>
                        ))}
                    </Form.Select>
                  ) : (
                    <Form.Select
                      value={newAccomodationData?.otel}
                      onChange={(e) => handleInputChange(e, "otel")}
                    >
                      <option key={index} value={row.otel_id}>
                        {row.otel_name}
                      </option>
                    </Form.Select>
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <Form.Control
                      type="date"
                      value={row.startDate}
                      onChange={(e) => handleRowChange(e, "startDate", index)}
                    />
                  ) : (
                    row.startDate
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <Form.Control
                      type="number"
                      min={1}
                      max={356}
                      value={row.nights}
                      onChange={(e) => handleRowChange(e, "nights", index)}
                    />
                  ) : (
                    row.nights
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <Form.Control
                      type="date"
                      value={row.endDate}
                      onChange={(e) => handleRowChange(e, "endDate", index)}
                    />
                  ) : (
                    row.endDate
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <>
                      <Button
                        variant="warning"
                        style={buttonStyle}
                        onClick={() => saveRowData(index)}
                      >
                        <FontAwesomeIcon icon={faSave} />
                      </Button>
                      <Button
                        variant="secondary"
                        style={buttonStyle}
                        onClick={() => cancelEdit(index)}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="warning"
                      style={buttonStyle}
                      onClick={() => editAccomodationRow(index)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  )}
                  <Button
                    style={buttonStyle}
                    variant="danger"
                    onClick={() => removeAccomodationsRowsRow(index)}
                  >
                    -
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};
export default Accomodation;
