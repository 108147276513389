import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Card, Button, Table, Modal, Form, Col, Row } from "react-bootstrap";
import Typography from "@mui/material/Typography";
import { axios } from "common/utils/axios";
import { faTrash, faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
const Direction = ["Online", "Offsite"];

function formatDate(dateString) {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
    hour12: false,
  };
  const date = new Date(dateString);
  return date.toLocaleDateString("tr-TR", options) + " ";
}
export const Meetings = ({ effectiveId }) => {
  const { id } = useParams();
  const [customerMeetings, setCustomerMeetings] = useState({
    data: { MeetingDate: "", Direction: "", Note: "" },
  });
  // States
  const [customersMeetingsData, setCustomersMeetingsData] = useState([]);
  // const customersData = useCustomerData();
  const [meetings, setMeetings] = useState([]);
  const [meetingId, setMeetingId] = useState("");
  const [filterText, setFilterText] = useState("");
  const [showEditMeetingModal, setShowEditMeetingModal] = useState(false);

  const fetchPastMeetings = async () => {
    const { data } = await axios.get(`/api/customers/${id}/pastMeetings`);
    return data;
  };
  const { data, isLoading, error } = useQuery(
    ["pastMeetings", { customerId: id }],
    fetchPastMeetings,
    {
      keepPreviousData: true,
    }
  );

  // Functions
  const fetchMeetings = async () => {
    const response = await axios.get(
      `/api/customers/${effectiveId}/pastMeetings`
    );
    setMeetings(response.data.meetings);
  };

  console.log(data, "data");

  // Mappings and Filters
  const filteredItems = customersMeetingsData.filter(
    (item) =>
      item.customer_id &&
      item.customer_id.toString().toLowerCase().includes(id.toLowerCase()) &&
      item.customer_id === parseInt(id)
  );

  // Components
  const card = (
    <React.Fragment>
      <CardContent>
        <Typography sx={{ fontSize: 20 }} color="text.secondary" gutterBottom>
          GÖRÜŞMELER
        </Typography>
      </CardContent>
      <CardActions>
        <Link key="one" to={`CreateMeeting`}>
          <Button
            size="medium"
            style={{ backgroundColor: "DodgerBlue", color: "#fff" }}
          >
            GÖRÜŞME oluştur
          </Button>
        </Link>
      </CardActions>
    </React.Fragment>
  );

  /******************* Data Table Style ********************** */
  const customStyles = {
    rows: {
      style: {
        fontSize: "17px", // Custom font size
      },
    },
    headCells: {
      style: {
        fontSize: "17px", // Custom font size
      },
    },
  };
  const handleSearch = (e) => {
    setFilterText(e.target.value);
  };
  const handleDeleteMeeting = async (meetingId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this meeting?"
    );
    if (confirmDelete) {
      try {
        // Update the URL to match the Laravel route definition
        await axios.delete(`/api/customers/meetings/${meetingId}`);

        toast.success("Meeting deleted successfully!");
      } catch (error) {
        console.error(error);

        let errorMessage =
          error.response?.data?.message || "Something went wrong!";
        toast.error(errorMessage);
      }
    }
  };
  /******************************************** */
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(`/api/customers/${id}/meetings/${meetingId}`, customerMeetings.data)
      .then((res) => {
        console.log(res);
        toast.success("Meeting updated successfully!");
        setShowEditMeetingModal(false);
      })
      .catch((error) => {
        console.error(error);
        let errorMessage = error.response?.data?.message;
        if (!errorMessage) {
          errorMessage = "Something went wrong!";
        }

        toast.error(errorMessage);
      });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerMeetings((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [name]: value,
      },
    }));
  };
  const handleShowEditAndMeetingId = (meetingId) => {
    setShowEditMeetingModal(true);
    setMeetingId(meetingId);
  };
  return (
    <div>
      <Card
        style={{ minWidth: "275px", marginBottom: "20px" }}
        variant="outlined"
      >
        <Card.Body>
          <Card.Title>GÖRÜŞMELER</Card.Title>
        </Card.Body>
        <Card.Footer>
          <Link to={`CreateMeeting`}>
            <Button variant="primary">GÖRÜŞME oluştur</Button>
          </Link>
        </Card.Footer>
      </Card>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Müşteri ID</th>
            <th>Yön</th>
            <th>Not</th>
            <th>Görüşme Tarihi</th>
            <th>Oluşturdu</th>
            <th>Eylem</th>
          </tr>
        </thead>
        <tbody>
          {data?.meetings?.map((row) => (
            <tr key={row.id}>
              <td>{row.customer_name}</td>
              <td>{row.Direction}</td>
              <td>{row.Note}</td>
              <td>{row.MeetingDate}</td>
              <td>{formatDate(row.created_at)}</td>
              <td>
                <Link to={`meeting/${row.id}`}>
                  <Button
                    variant="success"
                    onClick={() =>
                      console.log(`Button clicked for row ${row.id}`)
                    }
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </Button>
                </Link>{" "}
                <Button
                  variant="danger"
                  onClick={() => handleDeleteMeeting(row.id)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>{" "}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal
        centered
        show={showEditMeetingModal}
        onHide={() => setShowEditMeetingModal(false)}
      >
        <Modal.Header closeButton>
          <h3>Toplantıyı Düzenle</h3>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group className="">
                <Form.Label>Yön</Form.Label>
                <Form.Select
                  name="Direction"
                  value={customerMeetings.data.Direction}
                  onChange={handleChange}
                  aria-label="Direction select"
                >
                  {Direction &&
                    Direction?.map((direction, index) => (
                      <option key={index} value={direction}>
                        {direction}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Tarihi Ve Saat</Form.Label>
                <Form.Control
                  type="datetime-local"
                  name="MeetingDate"
                  value={customerMeetings.data.MeetingDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={12} className="mt-4">
              <Form.Group>
                <Form.Label>Not</Form.Label>
                <Form.Control
                  as="textarea"
                  name="Note"
                  value={customerMeetings.data.Note}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={12} className="d-flex justify-content-end">
              <Button className="m-4" onClick={handleSubmit}>
                Ekle
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Meetings;
