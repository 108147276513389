import { useState, useEffect, useContext } from "react";
import { axios } from "common/utils/axios";
import styled from "styled-components";
import RenderHTMLContent from "common/helpers/RenderHTMLContent";
import HeaderContext from "redux/ContextApi/HeaderContext .jsx";

export const AboutUs = () => {
  const { setTitle, setImageUrl } = useContext(HeaderContext);

  {
    /*Handiling the Title and image of te HERO */
  }

  useEffect(() => {
    setTitle("Hakkımızda");
    setImageUrl("https://backend.suarelab.com/api/about-us/images-id/1");
    // Clean up function to reset the title and image URL when the component unmounts
    return () => {
      setTitle("");
      setImageUrl("");
    };
  }, [setTitle, setImageUrl]);

  const [aboutUsData, setAboutUsData] = useState({});

  {
    /*Fetching Abou US Data */
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetching the whole Data
        const response = await axios.get("/api/about-us");
        setAboutUsData(response.data[0]); // accessing the first object in the array
        console.log("Data updated successfully", response.data);
      } catch (error) {
        console.error("Error updating the data", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <StyledContainer>
        <h1>{aboutUsData.title}</h1>
        <RenderHTMLContent
          htmlContent={aboutUsData.content}
          marginB={"150px"}
          marginT={"20px"}
        />
      </StyledContainer>
    </div>
  );
};

export default AboutUs;

// Styled-components for centering
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; // Centers content vertically in the container
  align-items: center; // Centers content horizontally in the container

  width: 80%; // Adjust the width as needed
  @media (min-width: 1400px) {
    width: 60%; // Set max width to 75% for large screens
  }

  margin: 20px auto 50px auto;
  h1 {
    font-size: 2em; // Default font size for larger screens

    // Media query for smaller screens (e.g., less than 768px wide)
    @media (max-width: 768px) {
      font-size: 1.5em; // Smaller font size for small screens
    }
  }
`;
