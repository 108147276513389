import React, { useState, useEffect } from "react";
import { Table, Form, Button } from "react-bootstrap";
import { getTodayDateString } from "common/helpers/getTodayDateString";
import { isObjectEmpty } from "common/helpers/isObjectEmpty";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faSave,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
const buttonStyle = {
  padding: "3px 6px",
  fontSize: "14px",
  lineHeight: "1",
  marginRight: "5px",
};

export const Transport = ({ transportations, updateTransportData }) => {
  const [transportationsRows, setTransportationsRows] =
    useState(transportations);
  const [editingTransportationIndex, setEditingTransportationIndex] =
    useState(null);
  const [originalTransportationData, setOriginalTransportationData] =
    useState(null);

  const [newTransportationsRows, setNewTransportationsRows] = useState({
    company: "",
    fromLocation: "",
    toLocation: "",
    departureDateTime: "",
    arrivalDateTime: "",
  });
  const addUlasimRow = () => {
    if (isObjectEmpty(newTransportationsRows)) {
      return toast.warn("bazı veriler eksik");
    } else {
      console.log("Tüm veriler kayıp.");
    }
    const newRows = [...transportationsRows, newTransportationsRows];
    setTransportationsRows(newRows);
    updateTransportData(newRows);
    setNewTransportationsRows({
      company: "",
      fromLocation: "",
      toLocation: "",
      departureDateTime: "",
      arrivalDateTime: "",
    });
  };

  const removeUlasimRow = (index) => {
    const updatedRows = [...transportationsRows];
    updatedRows.splice(index, 1);
    setTransportationsRows(updatedRows);
    updateTransportData(updatedRows); // Update parent state
  };
  const handleInputChange = (e, field) => {
    let value = e.target.value;
    if (field === "departureDateTime" || field === "arrivalDateTime") {
      value = value.replace("T", " "); // Replace T with a space
    }
    setNewTransportationsRows((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const editTransportationRow = (index) => {
    setEditingTransportationIndex(index);
    setOriginalTransportationData({ ...transportationsRows[index] });
  };

  const saveTransportationRow = (index) => {
    setEditingTransportationIndex(null);
  };

  const cancelTransportationEdit = () => {
    const updatedRows = [...transportationsRows];
    updatedRows[editingTransportationIndex] = originalTransportationData;
    setTransportationsRows(updatedRows);
    setEditingTransportationIndex(null);
  };
  const handleRowChange = (e, field, index) => {
    const updatedRows = [...transportationsRows];
    updatedRows[index] = { ...updatedRows[index], [field]: e.target.value };
    setTransportationsRows(updatedRows);
  };

  useEffect(() => {
    if (transportations) {
      const filteredTransportations = transportations.filter(
        (acc) => !isObjectEmpty(acc)
      );
      setTransportationsRows(filteredTransportations);
    } else {
      setTransportationsRows([]);
    }
  }, [transportations]);
  return (
    <div>
      <h5>Ulasim</h5>
      <Table responsive>
        <thead>
          <tr>
            <th style={{ width: "10% !important" }}>Firmasi</th>
            <th style={{ width: "25% !important" }}>Nerden</th>
            <th style={{ width: "25% !important" }}>Nereye</th>
            <th style={{ width: "20% !important" }}>Kalkis Tarihi</th>
            <th style={{ width: "10% !important" }}>Varis Tarihi</th>

            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Form.Control
                type="text"
                name="company"
                value={newTransportationsRows.company}
                onChange={(e) => handleInputChange(e, "company")}
              />
            </td>
            <td>
              <Form.Control
                type="text"
                name="fromLocation"
                value={newTransportationsRows.fromLocation}
                onChange={(e) => handleInputChange(e, "fromLocation")}
              />
            </td>
            <td>
              <Form.Control
                type="text"
                name="toLocation"
                value={newTransportationsRows.toLocation}
                onChange={(e) => handleInputChange(e, "toLocation")}
              />
            </td>
            <td>
              <Form.Control
                type="datetime-local"
                name="departureDateTime"
                value={newTransportationsRows.departureDateTime}
                onChange={(e) => handleInputChange(e, "departureDateTime")}
                min={getTodayDateString()}
              />
            </td>
            <td>
              <Form.Control
                type="datetime-local"
                name="arrivalDateTime"
                value={newTransportationsRows.arrivalDateTime}
                onChange={(e) => handleInputChange(e, "arrivalDateTime")}
                min={
                  newTransportationsRows.kalkisTarihi || getTodayDateString()
                }
              />
            </td>

            <td>
              <Button active={true} variant="success" onClick={addUlasimRow}>
                <FontAwesomeIcon icon={faSave} />
              </Button>
            </td>
          </tr>
          {transportationsRows?.map((row, index) => {
            const isEditing = index === editingTransportationIndex;
            return (
              <tr key={index}>
                <td>
                  {isEditing ? (
                    <Form.Control
                      type="text"
                      value={row.company}
                      onChange={(e) => handleRowChange(e, "company", index)}
                    />
                  ) : (
                    row.company
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <Form.Control
                      type="text"
                      value={row.fromLocation}
                      onChange={(e) =>
                        handleRowChange(e, "fromLocation", index)
                      }
                    />
                  ) : (
                    row.fromLocation
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <Form.Control
                      type="text"
                      value={row.toLocation}
                      onChange={(e) => handleRowChange(e, "toLocation", index)}
                    />
                  ) : (
                    row.toLocation
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <Form.Control
                      type="datetime-local"
                      value={row.departureDateTime}
                      onChange={(e) =>
                        handleRowChange(e, "departureDateTime", index)
                      }
                      min={getTodayDateString()}
                    />
                  ) : (
                    row.departureDateTime
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <Form.Control
                      type="datetime-local"
                      value={row.arrivalDateTime}
                      onChange={(e) =>
                        handleRowChange(e, "arrivalDateTime", index)
                      }
                      min={getTodayDateString()}
                    />
                  ) : (
                    row.arrivalDateTime
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <>
                      <Button
                        variant="warning"
                        onClick={() => saveTransportationRow(index)}
                        style={buttonStyle}
                      >
                        <FontAwesomeIcon icon={faSave} />
                      </Button>
                      <Button
                        style={buttonStyle}
                        variant="secondary"
                        onClick={cancelTransportationEdit}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="warning"
                      onClick={() => editTransportationRow(index)}
                      style={buttonStyle}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  )}
                  <Button
                    style={buttonStyle}
                    variant="danger"
                    onClick={() => removeUlasimRow(index)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default Transport;
