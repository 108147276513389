import React from "react";

export const ReservationAndBillingInformation = () => {
  return (
    <div>
      <div className="online-section-title p3">
        <span className="glyphicon glyphicon-info-sign"></span> Rezervasyon ve
        Fatura Bilgileri
      </div>
      <div className="row">
        <div className="col-4 col-md-2 p5 hidden">
          <label className="control-label mb0">Cinsiyet</label>
          <div>Bay</div>
        </div>
        <div className="col-4 col-md-6 p5">
          <label className="control-label mb0 fw-600 p2">Ad</label>
          <div>asd</div>
        </div>
        <div className="col-4 col-md-6 p5">
          <label className="control-label mb0 fw-600 p2">Soyad</label>
          <div>asd</div>
        </div>
        <div className="col-4 col-md-4 p5 hidden">
          <label className="control-label mb0 fw-600 p2">TC Kimlik No</label>
          <div></div>
        </div>
        <div className="clearfix"></div>
        <div className="col-4 col-md-6 p5">
          <label class="control-label mb0 fw-600 p2">E-Mail</label>
          <div>nabeelhero12@gmail.com</div>
        </div>
        <div className="clearfix"></div>
        <div className="col-4 col-md-6 p5">
          <label className="control-label mb0 fw-600 p2">Cep Telefonu</label>
          <div>5511663824</div>
        </div>
        <div className="col-4 col-md-4 p5 hidden">
          <label className="control-label mb0 fw-600 p2">Sabit Telefon</label>
          <div>&nbsp;</div>
        </div>
        <div className="col-4 col-md-4 p5 hidden">
          <label className="control-label mb0 fw-600 p2">Faks</label>
          <div>&nbsp;</div>
        </div>
        <div className="clearfix"></div>
        <div className="col-12 col-md-12 p5 hidden">
          <label className="control-label mb0 fw-600 p2">Adres</label>
          <div></div>
        </div>
        <div className="clearfix"></div>
        <div className="col-4 col-md-4 p5 hidden">
          <label className="control-label mb0 fw-600 p2">İl</label>
          <div></div>
        </div>
        <div className="col-4 col-md-4 p5 hidden">
          <label className="control-label mb0 fw-600 p2">İlçe</label>
          <div></div>
        </div>
        <div className="clearfix"></div>
      </div>

      <hr />
    </div>
  );
};
