import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
export default ({ onClick, title }) => {
  return (
    <button
      onClick={onClick}
      style={{
        marginTop: "7px",
        paddingTop: "5px",
        paddingBottom: "5px",
        border: "none",
        backgroundColor: "rgb(9 149 193)",
        marginLeft: "10px",
        fontWeight: "700",
        borderRadius: "7px",
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
    >
      {title} <FontAwesomeIcon icon={faPlusSquare} />
    </button>
  );
};
