import { Form, Button, Table, Card, Alert, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEye, faEdit } from "@fortawesome/free-solid-svg-icons";
import { axios } from "common/utils/axios";
function formatDate(dateString) {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
    hour12: false,
  };
  const date = new Date(dateString);
  return date.toLocaleDateString("tr-TR", options) + " ";
}
const UserOffers = ({ userId, handleDeleteMeeting, handleEditOfferClick }) => {
  const {
    data: customersOffersData,
    isLoading,
    error,
  } = useQuery("customersOffers", () =>
    axios.get(`/api/new-offer/${userId}`).then((res) => res.data)
  );
  if (!customersOffersData || customersOffersData?.length === 0) {
    return (
      <Row className="justify-content-center">
        <div className="d-flex justify-content-between mb-4">
          <h2>Tekliflerim</h2>
        </div>
        <Alert variant="warning">Teklifler üyeleri bilgisi bulunamadı.</Alert>{" "}
        {/* This line displays the alert */}
      </Row>
    );
  }
  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <h2 className="">Kullanıcı Teklifler</h2>
        <Button className="mr-2" variant="contained" disableElevation></Button>
      </div>
      <div className="d-flex justify-content-center">
        {customersOffersData?.map((item) => (
          <Card className="shadow" style={{ width: "80%" }}>
            <Card.Body>
              <div className="d-flex justify-content-between mb-2">
                <span>{item.tour.tourName}</span>
                <span>{item.offer_statu}</span>
              </div>
              <div>
                {" "}
                {item.prices?.map((price, index) => (
                  <div key={index}>
                    <span
                      style={{
                        fontSize: "13px",
                        display: "block",
                        marginRight: "3px",
                        backgroundColor: "#e0e0d1",
                        padding: "2px",
                        borderRadius: "4px",
                        marginBottom: "2px",
                      }}
                    >
                      {price.how_many_people} Kişi: {price.price}{" "}
                      {price.pricing_type}
                    </span>
                  </div>
                ))}
              </div>
            </Card.Body>
            <Card.Footer>
              <div className="d-flex justify-content-between">
                <span>{formatDate(item.created_at)}</span>
                <span>{item.stars_level}</span>
              </div>
            </Card.Footer>
          </Card>
        ))}
      </div>
      {/**
      <div style={{ fontSize: "18px" }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Müşteri ID</th>
              <th>Turlar</th>
              <th>Fiyat</th>
              <th>Teklif durumu</th>
              <th>Yıldızlama</th>
              <th>Oluşturuldu</th>
            </tr>
          </thead>
          <tbody>
            {customersOffersData?.map((item) => (
              <tr key={item.id}>
                <td>{item?.customer.full_name}</td>
                <td>{item?.tour?.tourName}</td>
                <td>
                  {item.prices?.map((price, index) => (
                    <div key={index}>
                      <span
                        style={{
                          fontSize: "13px",
                          display: "block",
                          marginRight: "3px",
                          backgroundColor: "#e0e0d1",
                          padding: "2px",
                          borderRadius: "4px",
                          marginBottom: "2px",
                        }}
                      >
                        {price.how_many_people} Kişi: {price.price}{" "}
                        {price.pricing_type}
                      </span>
                    </div>
                  ))}
                </td>
                <td>{item.offer_statu}</td>
                <td>{item.stars_level}</td>
                <td>{formatDate(item.created_at)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
       */}
    </>
  );
};

export default UserOffers;
