import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import DropzoneComponent from "area/admin/Hotels/components/create/DropzoneComponent";
import CustomeDropZone from "./CustomeDropZone";
export const HotelPhotograhps = ({ setFormData, formData, updateFormData }) => {
  const updateMainPhotograph = (mainImage) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      mainPhotograph: mainImage,
    }));
  };

  const updateInsidePhotographs = (insideImages) => {
    console.log(insideImages, "insideImages");
    setFormData((prevFormData) => ({
      ...prevFormData,
      insidePhotographs: insideImages,
    }));
  };

  const updateOutsidePhotographs = (outsideImages) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      outsidePhotographs: outsideImages,
    }));
  };
  useEffect(() => {
    console.log("FormData updated:", formData); // Log updated formData
  }, [formData]);
  const handleImagesUpdated = (data) => {
    // Process the data received from CustomeDropZone
    // Update the formData state accordingly
    console.log(data); // For debugging
    // Update your formData here based on the data received
    // Example: updateFormData({ ...formData, mainPhotograph: data.mainImage, insidePhotographs: data.insideImages, outsidePhotographs: data.outsideImages });
  };

  return (
    <div>
      <Row className="mt-4">
        <h5>Custome Dropzone</h5>

        <CustomeDropZone
          updateMainPhotograph={updateMainPhotograph}
          updateInsidePhotographs={updateInsidePhotographs}
          updateOutsidePhotographs={updateOutsidePhotographs}
          onImagesUpdated={handleImagesUpdated}
        />
      </Row>
    </div>
  );
};

export default HotelPhotograhps;
