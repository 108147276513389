import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { axios } from "common/utils/axios";
import { useMutation } from "react-query";
import {
  getCountries,
  getStates,
  getCities,
} from "common/Api/UniversalTutorialApi";
import {
  Pagination,
  Card,
  Row,
  Col,
  Dropdown,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
export const CreatCustomerModal = ({
  showCreatCustomer,
  setShowCreatCustomer,
}) => {
  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState({
    // Initialize state for your form fields, e.g.,
    role: "",
    il: "",
    ilce: "",
    mahalle: "",
    adres: "",
    adres_title: "",
    active: true,
    // Assuming 'customer' fields are flattened for simplicity
    FirstName: "",
    LastName: "",
    Telefon: "",
    BirthDate: "",
    Eposta: "",
    jop: "",
    explanation: "",
    CreatedDate: "",
    Gender: "",
    RegistrationTypeId: "",
    FromWhereHeardAboutUsId: "",
    FaceBook: "",
    Instagram: "",
    twitter: "",
    // No need to initialize file here, as it will be handled by ref
  });
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    if (showCreatCustomer) {
      const fetchCountries = async () => {
        const countriesData = await getCountries();
        setCountries(countriesData);
      };
      fetchCountries();
    }
  }, [showCreatCustomer]);

  useEffect(() => {
    if (formData.il) {
      const fetchStates = async () => {
        const statesData = await getStates(formData.il);
        setStates(statesData);
      };
      fetchStates();
    } else {
      setStates([]); // Reset states if country is cleared
    }
  }, [formData.il]);

  useEffect(() => {
    if (formData.ilce) {
      const fetchCities = async () => {
        const citiesData = await getCities(formData.ilce);
        setCities(citiesData);
      };
      fetchCities();
    } else {
      setCities([]); // Reset cities if state is cleared
    }
  }, [formData.ilce]);
  const handleSubmit = async (e) => {
    e.preventDefault();

    mutation.mutate(formData, {
      onSuccess: (response) => {
        toast.success("Yeni kullanıcı eklemede başarı");
        console.log(response.data);

        setShowCreatCustomer(false);
      },
      onError: (error) => {
        toast.error("Yeni kullanıcı eklemede başarısız");
        console.error(error);
      },
    });
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          file: reader.result, // Store the Base64 string
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleCloseCreatCustomer = () => {
    setShowCreatCustomer(false);
  };
  const mutation = useMutation((formData) => {
    const endpoint = "http://backend.suarelab.com/api/customers"; // Specify your endpoint URL
    const formDataToSend = new FormData();

    // Append all form data to formDataToSend
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        formDataToSend.append(key, formData[key]);
      }
    }

    // For the file, make sure it is appended only if it exists
    if (formData.file) {
      formDataToSend.append("file", formData.file);
    }

    return axios.post(endpoint, formDataToSend, {
      headers: {
        // 'Content-Type': 'multipart/form-data', // You don't need to explicitly set this, as Axios and the browser will handle it correctly when you pass a FormData object
      },
    });
  });
  console.log(formData, "formData");
  return (
    <div>
      <Modal
        size="xl"
        centered
        scrollable
        show={showCreatCustomer}
        onHide={handleCloseCreatCustomer}
      >
        <Modal.Header>Yeni Müşteri Ekle</Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xl={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Adı</Form.Label>
                  <Form.Control
                    type="text"
                    name="FirstName"
                    value={formData["customer.FirstName"]}
                    onChange={handleInputChange}
                    placeholder="Adı"
                  />
                </Form.Group>
              </Col>
              <Col xl={4}>
                <Form.Group className="mb-3">
                  <Form.Label>SoyAdı</Form.Label>
                  <Form.Control
                    type="text"
                    name="LastName"
                    value={formData["customer.LastName"]}
                    onChange={handleInputChange}
                    placeholder="Soyadı"
                  />
                </Form.Group>
              </Col>
              <Col xl={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Telefon</Form.Label>
                  <Form.Control
                    type="text"
                    name="Telefon"
                    value={formData["customer.Telefon"]}
                    onChange={handleInputChange}
                    placeholder="Telefon"
                  />
                </Form.Group>
              </Col>
              <Col xl={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Eposta</Form.Label>
                  <Form.Control
                    type="email"
                    name="Eposta"
                    value={formData["customer.Eposta"]}
                    onChange={handleInputChange}
                    placeholder="E-posta"
                  />
                </Form.Group>
              </Col>
              <Col xl={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Cinsiyet</Form.Label>
                  <Form.Control
                    as="select"
                    name="Gender"
                    value={formData["customer.Gender"]}
                    onChange={handleInputChange}
                  >
                    <option value="">Cinsiyet seç</option>
                    <option value="0">Erkek</option>
                    <option value="1">Kadın</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col xl={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Statü</Form.Label>
                  <Form.Control
                    as="select"
                    name="Role"
                    value={formData["customer.Role"]}
                    onChange={handleInputChange}
                  >
                    <option value="">Bir Statü Seçin</option>
                    <option value="user">Customer</option>
                    <option value="manager">Manager</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col xl={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Doğum günü</Form.Label>
                  <Form.Control
                    type="date"
                    name="BirthDate"
                    value={formData["customer.BirthDate"]}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col xl={4}>
                <Form.Group className="mb-3">
                  <Form.Label>İş</Form.Label>
                  <Form.Control
                    type="text"
                    name="jop"
                    value={formData["customer.jop"]}
                    onChange={handleInputChange}
                    placeholder="Jop"
                  />
                </Form.Group>
              </Col>
              <Col xl={4}></Col>
              <Col xl={4}>
                {" "}
                <Form.Group className="mb-3">
                  <Form.Label> Ülke</Form.Label>
                  <Form.Select
                    name="il"
                    value={formData.il}
                    onChange={handleInputChange}
                  >
                    <option>Ülke Seç...</option>
                    {countries.map((country) => (
                      <option
                        key={country.country_name}
                        value={country.country_name}
                      >
                        {country.country_name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col xl={4}>
                <Form.Group className="mb-3">
                  <Form.Label>İl</Form.Label>
                  <Form.Select
                    name="ilce"
                    value={formData.ilce}
                    onChange={handleInputChange}
                  >
                    <option>İl Seç...</option>
                    {states.map((state) => (
                      <option key={state.state_name} value={state.state_name}>
                        {state.state_name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xl={3}>
                <Form.Group className="mb-3">
                  <Form.Label>İlce</Form.Label>
                  <Form.Select
                    name="mahalle"
                    value={formData.mahalle}
                    onChange={handleInputChange}
                  >
                    <option>İlce Seç...</option>
                    {cities.map((city) => (
                      <option key={city.city_name} value={city.city_name}>
                        {city.city_name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>

              <Form.Group className="mb-3">
                <Form.Label>Adres</Form.Label>
                <Form.Control
                  type="text"
                  name="adres"
                  value={formData.adres}
                  onChange={handleInputChange}
                  placeholder="Adres"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Adres Başlığı</Form.Label>
                <Form.Control
                  type="text"
                  name="adres_title"
                  value={formData.adres_title}
                  onChange={handleInputChange}
                  placeholder="Ev / İş "
                />
              </Form.Group>

              {/* Customer Details */}

              <Form.Group className="mb-3">
                <Form.Label>Açıklama (İsteğe bağlı)</Form.Label>
                <Form.Control
                  as="textarea"
                  name="customer.explanation"
                  value={formData["customer.explanation"]}
                  onChange={handleInputChange}
                  placeholder="Açıklama"
                />
              </Form.Group>

              {/* Assume RegistrationTypeId and FromWhereHeardAboutUsId are select inputs */}
              {/* Additional Fields for Social Media (Facebook, Instagram, Twitter) */}
              {/* File Input for Profile Photo */}
              <Form.Group className="mb-3">
                <Form.Label>Müşteri profili</Form.Label>
                <Form.Control
                  type="file"
                  name="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCreatCustomer}>
            Kapat
          </Button>
          <Button onClick={handleSubmit} type="submit" variant="primary">
            Ekle
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default CreatCustomerModal;
