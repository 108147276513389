import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import errorPhoto from "../../../../assets/icons/errorPhoto.jpg";
import tw from "twin.macro";
import { Link } from "react-router-dom";
import { PrimaryButton } from "../../../../common/components/misc/Buttons";
import Modal from "react-bootstrap/Modal";
import styles from "assets/styles/tourstamplate.module.css";

function ToursTamplateCardWithOtels({
  tours = [],
  otels,
  categories,
  filteredTours = [],
  min,
  max,
  prices,
  onLoadMoreClick,
  visibleTours,
  selectedCurrency,
  maxDays,
  minDays,
}) {
  const ButtonContainer = tw.div`flex justify-center mb-4`;
  const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;
  const [show, setShow] = useState(false); // For handling modal visibility
  const [selectedImage, setSelectedImage] = useState(""); // For handling the selected image

  const handleClose = () => setShow(false); // Function to close the modal
  const handleShow = (image) => {
    // Function to open the modal
    setSelectedImage(image);
    setShow(true);
  };
  const iconsMapping = {
    "2 kişi": styles.people2,
    "3 kişi": styles.people3,
    "4 kişi": styles.people4,
    "5 kişi": styles.people4,
  };
  const toursToDisplay = filteredTours.length ? filteredTours : tours;
  const filteredToursonPrice = toursToDisplay.filter((tour) => {
    const startDate = new Date(tour.gidis_tarih);
    const endDate = new Date(tour.donus_tarih);
    const daysBetween = Math.ceil(
      (endDate - startDate) / (1000 * 60 * 60 * 24)
    );
    return (
      tour.iki_kisi_fiyat >= min &&
      tour.iki_kisi_fiyat <= max &&
      (!selectedCurrency || tour.price_id === selectedCurrency) &&
      daysBetween >= minDays &&
      daysBetween <= maxDays
    );
  });
  function findHotelById(id) {
    return otels.find((otel) => otel.otel_id === id);
  }
  function findPriceById(id) {
    return prices.find((price) => price.id === id);
  }
  const findCategoryById = (id) =>
    categories.find((category) => category.id === id);
  function getPeopleStyle(peopleCount) {
    if (peopleCount === 2) return styles.people2;
    if (peopleCount === 3) return styles.people3;
    if (peopleCount >= 4) return styles.people4;
    return null; // Or some default style
  }
  return (
    <div>
      {filteredToursonPrice
        .filter((tour) => new Date(tour.gidis_tarih) >= new Date())
        .slice(0, visibleTours)
        .map((tour) => {
          const category = findCategoryById(tour.category_id);
          const pricesData = [
            {
              people: "2 kişi",
              price: tour.iki_kisi_fiyat,
              key: `2-kişi-${tour.id}`,
            },
            {
              people: "3 kişi",
              price: tour.uç_kisi_fiyat,
              key: `3-kişi-${tour.id}`,
            },
            {
              people: "4 kişi",
              price: tour.dört_kisi_fiyat,
              key: `4-kişi-${tour.id}`,
            },
            {
              people: `${tour.özel_kisi_saısı} kişi`,
              price: tour.özel_fiyat,
              key: `${tour.özel_kisi_saısı}-kişi-${tour.id}`,
            },
          ];
          return (
            <div className={styles.cardWrapper}>
              <span className={styles.title}>
                {category && category.category}
              </span>
              <div className={styles.card}>
                <div className={styles.turSecWrapper}>
                  <span className={styles.turSecText}>Tur Seç</span>
                  <input type="checkbox" className={styles.turSecCheckbox} />
                </div>
                <div className={styles.container}>
                  <div className={styles.leftSection}>
                    {[
                      {
                        otelId: tour.otel_id,
                        gece: tour.birinci_hotel_geceler_sayisi,
                      },
                      {
                        otelId: tour.otel_id2,
                        gece: tour.ikinci_hotel_geceler_sayisi,
                      },
                    ].map((otelData, index) => {
                      const otel = findHotelById(otelData.otelId); // Fetch the hotel info using the ID
                      return (
                        <div
                          key={`${tour.id}-${otelData.otelId}-${otelData.gece}-${index}`}
                        >
                          <div className={styles.titleWithIcon}>
                            <div className={styles.addressIcon}></div>
                            <h6 style={{ fontWeight: "700" }}>
                              {otel
                                ? otel.otel_name
                                : "Hotel name not available"}{" "}
                              {/* Render the hotel name */}
                            </h6>
                          </div>
                          <img
                            className={styles.otelImage}
                            src={otel ? otel.cover_picture : errorPhoto} // Render the hotel image
                            alt={`Image ${otelData.otelId}`}
                            onClick={() =>
                              handleShow(otel ? otel.cover_picture : errorPhoto)
                            } // Add click handler to open the modal with the image
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = errorPhoto;
                            }} // In case of error loading the image, use the static image
                          />
                          <div>Bursa</div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              className={styles.moonIcon}
                              style={{ marginRight: "5px" }}
                            ></div>
                            <strong>Geceler {otelData.gece}</strong>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className={styles.rightSection}>
                    <h6>
                      <strong>{tour.title}</strong>
                    </h6>
                    <div className={styles.line}></div>
                    <div className={styles.cardContainerDate}>
                      <ul className={styles.listGroup}>
                        {[
                          {
                            label: "Gidiş Tarih",
                            date: tour.gidis_tarih,
                            binis: tour.gidis_binis,
                            inis: tour.gidis_inis,
                            img: "https://www.gezintitatil.com/elci-assets/img/airplane.png",
                          },
                          {
                            label: "Ara Geçiş Tarih",
                            date: tour.ara_gecis_tarih,
                            binis: tour.ara_gecis_binis,
                            inis: tour.ara_gecis_inis,
                            img: "https://www.gezintitatil.com/elci-assets/img/bus.png",
                          },
                          {
                            label: "Dönüş Tarih",
                            date: tour.donus_tarih,
                            binis: tour.donus_binis,
                            inis: tour.donus_inis,
                            img: "https://www.gezintitatil.com/elci-assets/img/airplane.png",
                          },
                        ].map((item, index) => (
                          <li
                            className={styles.listItemUnique}
                            key={`${tour.id}-${item.label}-${item.binis}-${index}`}
                          >
                            {" "}
                            {/* Used index as a key here */}
                            <strong className={styles.titleWithIconUnique}>
                              {item.label}:
                            </strong>
                            <span className={styles.dateTextUnique}>
                              {item.date.replace("\n", " ") + " Cumartisi"}{" "}
                              {/* replace newline characters with a space */}
                            </span>
                            <div className={styles.iconRowUnique}>
                              <span className={styles.binis}>{item.binis}</span>
                              <img
                                src={item.img}
                                className={styles.airplaneIcon}
                              />
                              <span className={styles.inis}>{item.inis}</span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className={styles.line}></div>
                    <div className={styles.iconRow}>
                      {pricesData.map(({ people, price }, index) => {
                        const peopleCount = parseInt(people.split(" ")[0], 10);
                        return (
                          price &&
                          price !== 0 && (
                            <div
                              key={`${tour.id}-${price}-${index}`}
                              className={styles.iconWithText}
                            >
                              <div
                                className={`${styles.icon} ${getPeopleStyle(
                                  peopleCount
                                )}`}
                              ></div>
                              <p>{people}</p>
                              <h3
                                className="pric"
                                style={{ color: "#4EAC74", fontWeight: "bold" }}
                              >
                                {price}{" "}
                                {prices.find((p) => p.id === tour.price_id) &&
                                  findPriceById(tour.price_id).abbreviation}
                              </h3>
                            </div>
                          )
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <Link to={`/tour/${tour.id}`}>
                <div className={styles.satinAlButtonWrapper}>
                  <Button className={styles.satinAlButton}>SATIN AL</Button>
                </div>
              </Link>
            </div>
          );
        })}
      {visibleTours < tours.length && (
        <ButtonContainer>
          <Button onClick={onLoadMoreClick} className={styles.loadButton}>
            Daha Fazla Gör
          </Button>
        </ButtonContainer>
      )}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body className="p-0">
          <img
            src={selectedImage}
            alt="Enlarged"
            style={{ width: "100%", height: "auto", objectFit: "cover" }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default ToursTamplateCardWithOtels;
