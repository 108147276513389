import React from "react";
import { useLoading } from "./LoadingContext";
import { BeatLoader } from "react-spinners";

export default function GlobalLoader() {
  const { loading } = useLoading();

  if (!loading) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
      <BeatLoader color="#60DABF" size={15} margin={2} />
    </div>
  );
}
