import { useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import AirPLan from "assets/icons/Plane.png";
import Ship from "assets/icons/Ship.png";
import Bus from "assets/icons/BusTransportaion.png";
const TransportaionDetails = [
  {
    Company: "Akyol",
    WithWhat: AirPLan,
    FromWhere: "Istanbul",
    ToWhere: "Meke",
    StartData: "2024/1/12 9:30 Am",
    EndDate: "2024/1/12 3:30 pm",
  },
  {
    Company: "Akyol",
    WithWhat: Ship,
    FromWhere: "Istanbul",
    ToWhere: "Meke",
    StartData: "2024/1/12 9:30 Am",
    EndDate: "2024/1/12 3:30 pm",
  },
  {
    Company: "Akyol",
    WithWhat: Bus,
    FromWhere: "Istanbul",
    ToWhere: "Meke",
    StartData: "2024/1/12 9:30 Am",
    EndDate: "2024/1/12 3:30 pm",
  },
  {
    Company: "Akyol",
    WithWhat: AirPLan,
    FromWhere: "Istanbul",
    ToWhere: "Meke",
    StartData: "2024/1/12 9:30 Am",
    EndDate: "2024/1/12 3:30 pm",
  },
];
export const Transportaion = ({ tourDetails }) => {
  return (
    <Row>
      {tourDetails?.transportations?.map((detail, index) => (
        <Col xs={12} md={12} xl={6} key={index} className="mb-2">
          <Card>
            <Card.Body className="p-0">
              <Row>
                <Col xl={9}>
                  <div className="d-flex align-items-center p-4">
                    <ul>
                      <li>
                        <span>{detail.fromLocation}</span>
                      </li>
                      <li>{detail.departureDateTime}</li>
                    </ul>
                    <div className="mx-2 text-center">
                      <FontAwesomeIcon
                        style={{
                          fontSize: "30px",
                          marginLeft: "15px",
                          marginRight: "15px",
                        }}
                        icon={faArrowRight}
                      />
                    </div>
                    <ul>
                      <li>
                        <span>{detail.toLocation}</span>
                      </li>
                      <li>{detail.arrivalDateTime}</li>
                    </ul>
                  </div>
                </Col>
                <Col
                  xl={3}
                  style={{
                    backgroundImage: `url(${detail.WithWhat || Bus})`,
                    backgroundSize: "cover",
                  }}
                >
                  {/* Content of the column (if any) */}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};
export default Transportaion;
