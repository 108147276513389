import React, { useState } from "react";

export const SideBarChildSection = () => {
  const [childCount, setChildCount] = useState(0);

  const incrementCount = () => {
    if (childCount < 10) {
      setChildCount(childCount + 1);
    }
  };

  const decrementCount = () => {
    if (childCount > 0) {
      setChildCount(childCount - 1);
    }
  };

  return (
    <div
      className="container stepper py-2 hotel-accom-calc-row"
      data-min="0"
      data-max="3"
    >
      <div className="input-group range" id="accom-people">
        <span className="input-group-addon">
          <i className="far fa-user-circle fa-2x"></i>
        </span>
        <span className="input-group-addon w70 form-control-font">
          <strong>Çocuk</strong>
        </span>
        <span className="input-group-prepend">
          <button
            type="button"
            className="btn btn-xs btn-default step-prev"
            onClick={decrementCount}
          >
            -
          </button>
        </span>
        <input
          type="text"
          className="form-control form-control-font"
          name="calc[children]"
          id="accom-child-count"
          readOnly
          value={childCount}
        />
        <span className="input-group-prepend">
          <button
            type="button"
            className="btn btn-xs btn-default step-next"
            onClick={incrementCount}
          >
            +
          </button>
        </span>
      </div>
    </div>
  );
};

export default SideBarChildSection;
