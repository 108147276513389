import { useState } from "react";
import { motion } from "framer-motion";
import { Card } from "react-bootstrap";
import { CardContent, Typography, Box } from "@mui/material";
import {
  VictoryPie,
  VictoryTheme,
  VictoryLabel,
  VictoryChart,
  VictoryAxis,
  VictoryLine,
} from "victory";
const colors = [
  "#2196F3",
  "#66BB6A",
  "#BA68C8",
  "#FFC107",
  "#8BC34A",
  "#FF5722",
];

export default () => {
  const [chartData, setChartData] = useState([
    { x: "A", y: 20 },
    { x: "B", y: 30 },
    { x: "C", y: 50 },
    { x: "D", y: 45 },
    { x: "E", y: 75 },
    { x: "[", y: 35 },
  ]);
  const chartVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1 } },
  };

  const hoverAnimation = {
    scale: 1.1,
    boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.3)",
  };
  return (
    <li className="mb-4">
      <motion.div whileHover={hoverAnimation} sx={{ m: 1 }}>
        <Card
          sx={{
            width: "100%",
            background: "linear-gradient(45deg, #8BC34A 30%, #CDDC39 90%)",
            boxShadow: "3px 3px 5px 2px rgba(255, 105, 135, .3)",
          }}
        >
          <CardContent>
            <Typography> </Typography>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={chartVariants}
            >
              <VictoryPie
                data={chartData}
                x="x"
                y="y"
                theme={VictoryTheme.material}
                style={{
                  data: {
                    fill: ({ datum, active }) =>
                      active ? "tomato" : colors[datum._x - 1],
                    transition: "fill 0.5s",
                  },
                  labels: {
                    fill: "#333",
                    fontSize: 12,
                  },
                }}
                labels={({ datum }) => `${datum.x}: ${datum.y}`}
                animate={{
                  duration: 2000,
                  onLoad: { duration: 1500 },
                  onExit: {
                    duration: 500,
                    before: () => ({ y: 0, label: "none" }),
                  },
                  onEnter: {
                    duration: 500,
                    before: () => ({ y: 0, label: "none" }),
                    after: (datum) => ({
                      y: datum.y,
                      label: `${datum.x}: ${datum.y}`,
                    }),
                  },
                }}
                innerRadius={50}
                labelRadius={({ innerRadius }) => innerRadius + 25}
                labelComponent={<VictoryLabel angle={0} />}
              />
            </motion.div>
          </CardContent>
        </Card>
      </motion.div>
    </li>
  );
};
