// Create a new file, e.g., HeaderContext.js
import React from "react";

const HeaderContext = React.createContext({
  title: "",
  imageUrl: "",
  setTitle: () => {},
  setImageUrl: () => {},
});

export default HeaderContext;
