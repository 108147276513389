import React from "react";
import { Pagination as BootstrapPagination } from "react-bootstrap";

export const PaginationList = ({ setPage, totalPages, page }) => {
  return (
    <BootstrapPagination className="justify-content-center">
      <BootstrapPagination.First
        onClick={() => setPage(1)}
        disabled={page === 1}
      />
      <BootstrapPagination.Prev
        onClick={() => setPage(page - 1)}
        disabled={page === 1}
      />
      <BootstrapPagination.Item active>{page}</BootstrapPagination.Item>
      <BootstrapPagination.Next
        onClick={() => setPage(page + 1)}
        disabled={page === totalPages}
      />
      <BootstrapPagination.Last
        onClick={() => setPage(totalPages)}
        disabled={page === totalPages}
      />
    </BootstrapPagination>
  );
};

export default PaginationList;
