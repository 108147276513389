import React from "react";
import GlobalStyles from "./assets/styles/GlobalStyles";
import { css } from "styled-components/macro"; //eslint-disable-line
import "bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import "assets/styles/custom-bootstrap.scss";

import { GoogleOAuthProvider } from "@react-oauth/google";

import { QueryClient, QueryClientProvider } from "react-query";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
} from "react-router-dom";
import CategoriesProvider from "redux/ContextApi/CategoriesProvider";
import combinedRoutes from "routes/routes";
import { LoadingProvider } from "redux/ContextApi/LoadingContext";
import GlobalLoader from "redux/ContextApi/GlobalLoader";
export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <LoadingProvider>
        <CategoriesProvider>
          <GlobalLoader />
          {useRoutes(combinedRoutes)}
        </CategoriesProvider>
      </LoadingProvider>
    </QueryClientProvider>
  );
}
