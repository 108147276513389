import React, { useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import "assets/styles/Style.css";

export default function DatePicker({ onChange }) {
  // Accept an onChange prop
  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  const handleValueChange = (newValue) => {
    console.log("newValue:", newValue);
    setValue(newValue);
    onChange(newValue); // Call the onChange prop function with the new value
  };

  return (
    <div className="form-group">
      <Datepicker
        className="form-control datepicker-input"
        primaryColor="purple"
        value={value}
        onChange={handleValueChange}
      />
    </div>
  );
}
