import React, { useState, useEffect } from "react";
import { Table, Form, Button, Dropdown } from "react-bootstrap";
import { isObjectEmpty } from "common/helpers/isObjectEmpty";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
export const FoodOptions = ({ food_options, updateFoodOptionData }) => {
  const [selectedFoodOptions, setSelectedFoodOptions] = useState([]);
  const foodOptionTypes = ["Sabah Menüsü", "Akşam Menüsü", "Öğlen Menüsü"];
  const foodOptions = [
    "Yemek A",
    "Yemek B",
    "Yemek C",
    "Yemek D",
    "Yemek E",
    "Yemek F",
    "Yemek G",
    "Yemek H",
    "Yemek I",
    "Yemek J",
  ];
  const [foodOptionRows, setFoodOptionRows] = useState(food_options);
  const [newFoodOptionData, setNewFoodOptionData] = useState({
    foodOptionType: "",
    foodOptionName: "",
  });
  const addFoodOptionRow = () => {
    const typeExists = foodOptionRows.some(
      (row) => row.foodOptionType === newFoodOptionData.foodOptionType
    );
    if (typeExists) {
      toast.warn("Bu yiyecek türü eklenmiş.."); // You can replace this with a more sophisticated way of showing errors
      return;
    }

    const newRow = {
      ...newFoodOptionData,
      foodOptionName: selectedFoodOptions.join(", "), // Joining the array into a string
    };
    const newRows = [...foodOptionRows, newRow];
    setFoodOptionRows(newRows);
    updateFoodOptionData(newRows);
    setNewFoodOptionData({ foodOptionType: "", foodOptionName: "" });
    setSelectedFoodOptions([]); // Resetting the selected options
  };

  const removeFoodOptionRow = (index) => {
    const updatedRows = [...foodOptionRows];
    updatedRows.splice(index, 1);
    setFoodOptionRows(updatedRows);
    updateFoodOptionData(updatedRows); // Update parent state
  };
  const handleFoodTypeChange = (e) => {
    console.log("Selected option:", e.target.value); // Check the selected value
    setNewFoodOptionData((prevData) => {
      console.log("Previous Data:", prevData); // Log previous data
      console.log("Updated Data:", {
        ...prevData,
        foodOptionType: e.target.value,
      }); // Log what the updated data will be
      return { ...prevData, foodOptionType: e.target.value };
    });
  };

  const handleCheckboxChange = (option) => {
    setSelectedFoodOptions((prevSelected) => {
      if (prevSelected.includes(option)) {
        return prevSelected.filter((item) => item !== option);
      } else {
        return [...prevSelected, option];
      }
    });
  };
  useEffect(() => {
    if (food_options) {
      const filteredFood_options = food_options.filter(
        (acc) => !isObjectEmpty(acc)
      );
      setFoodOptionRows(filteredFood_options);
    } else {
      setFoodOptionRows([]);
    }
  }, [food_options]);

  return (
    <div>
      <h5>Yemekler</h5>
      <Table responsive style={{ marginBottom: "150px" }}>
        <thead>
          <tr>
            <th>Öğün</th>
            <th>Yemek seçeneği</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Form.Group controlId="foodOptionType">
                <Form.Select
                  value={newFoodOptionData.foodOptionType} // Use the state variable here
                  name="foodOptionType"
                  onChange={handleFoodTypeChange} // Corrected event handler
                >
                  {foodOptionTypes.map((foodOptionType, index) => (
                    <option key={index} value={foodOptionType}>
                      {foodOptionType}
                    </option>
                  ))}
                  <option value="" hidden>
                    Yiyecek türü seç
                  </option>
                </Form.Select>
              </Form.Group>
            </td>
            <td>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  Yemek Seç
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{ maxHeight: "130px", overflowY: "scroll" }}
                >
                  {foodOptions.map((option, index) => (
                    <Form.Check
                      style={{
                        marginLeft: "15px",
                        marginButtom: "12px",
                      }}
                      type="checkbox"
                      id={`checkbox-${index}`}
                      label={option}
                      key={index}
                      checked={selectedFoodOptions.includes(option)}
                      onChange={() => handleCheckboxChange(option)}
                    />
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </td>

            <td>
              <Button
                active={true}
                variant="success"
                onClick={addFoodOptionRow}
              >
                <FontAwesomeIcon icon={faSave} />
              </Button>
            </td>
          </tr>
          {foodOptionRows &&
            foodOptionRows?.map((row, index) => (
              <tr key={index}>
                <td>{row.foodOptionType}</td>
                <td>{row.foodOptionName}</td>

                <td>
                  <Button
                    variant="danger"
                    onClick={() => removeFoodOptionRow(index)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};
export default FoodOptions;
