import React, { useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "../misc/Headings.js";
import otelsError from "assets/icons/errorPhoto.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faSnowflake,
  faFirstAid,
  faUtensils,
  faFire,
  faKitchenSet,
  faMicrochip,
} from "@fortawesome/free-solid-svg-icons";
import GoogleMapIframe from "common/helpers/GoogleMapIframe.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(
  Column
)`md:w-6/12 lg:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-8 lg:mr-16 md:order-first`
    : tw`md:ml-8 lg:ml-16 md:order-last`,
]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8`;

const Heading = tw(
  SectionHeading
)`text-left text-3xl sm:text-4xl lg:text-4xl text-center md:text-left leading-tight`;
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-4`;

const Statistics = tw.div`mt-6 lg:mt-8 xl:mt-16 flex flex-wrap`;
const Statistic = tw.div`text-lg sm:text-2xl lg:text-3xl w-1/2 mt-4 lg:mt-10 text-center md:text-left`;
const Value = tw.div`font-bold text-primary-500`;
const Key = tw.div`font-medium text-gray-700`;
const RoomType = tw.div`border p-4 rounded-lg shadow mt-4`;
const RoomTypeName = tw.h5`text-lg font-semibold`;
const FoodType = tw.div`mt-2 text-sm`;
const HotelDetailsIcons = ({ details }) => {
  const amenitiesIcons = {
    cooling: faSnowflake,
    first_aid_kit: faFirstAid,
    food: faUtensils,
    heating: faFire,
    microwave: faMicrochip,
    // Add other details and their corresponding icons here
  };
  const amenitiesTranslations = {
    cooling: "Soğutma",
    first_aid_kit: "İlk Yardım Kiti",
    food: "Yiyecek",
    heating: "Isıtma",
    microwave: "Mikrodalga",
    // Add other translations here
  };
  return (
    <div tw="flex flex-wrap">
      {Object.entries(details).map(([key, value]) => {
        if (value && amenitiesIcons[key]) {
          return (
            <div tw="flex items-center m-2" key={key}>
              <FontAwesomeIcon icon={amenitiesIcons[key]} />
              <span tw="ml-2">
                {amenitiesTranslations[key] || key.replace(/_/g, " ")}
              </span>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};
const RoomTypes = ({ roomTypes }) => (
  <div>
    <h4 tw="text-xl font-semibold mt-6 mb-4">Oda Türleri</h4>
    {roomTypes.map((room, index) => (
      <RoomType key={index}>
        <RoomTypeName>{room.room_type_name}</RoomTypeName>
        <FoodType>Food Type: {room.food_type}</FoodType>
        {/* Render more room details here if necessary */}
      </RoomType>
    ))}
  </div>
);
export default ({ textOnLeft = false, otel }) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects
  const defaultOtel = [
    {
      cover_picture: "https://placekitten.com/500/500",
      otel_name: "Sample Hotel 1",
      otel_desc: "This is a description for Sample Hotel 1.",
    },
    {
      cover_picture: "https://placekitten.com/400/400",
      otel_name: "Sample Hotel 2",
      otel_desc: "This is a description for Sample Hotel 2.",
    },
  ];

  useEffect(() => {}, []);
  const Image = ({ imageSrc, defaultImageSrc }) => {
    const [currentImage, setCurrentImage] = React.useState(imageSrc);

    const handleError = () => {
      setCurrentImage(defaultImageSrc);
    };

    React.useEffect(() => {
      setCurrentImage(imageSrc);
    }, [imageSrc]);

    return <img src={currentImage} onError={handleError} />;
  };
  const Stars = ({ count }) => {
    let stars = [];
    for (let i = 0; i < count; i++) {
      stars.push(<FontAwesomeIcon key={i} icon={faStar} />);
    }
    return <div style={{ fontSize: "20px" }}>{stars}</div>;
  };
  console.log(otel, "otel");
  // console.log("otel", otel.cover_picture);
  return (
    <div>
      <Container>
        <TwoColumn>
          <ImageColumn>
            <ul>
              <li>
                <Image
                  imageSrc={
                    otel.photographs.find(
                      (photo) => photo.photo_type === "main"
                    )?.photo_url || otelsError
                  }
                  defaultImageSrc={otelsError}
                />
              </li>
              <li tw="hidden md:block">
                <h4 tw="text-xl font-semibold mt-6 mb-4">Otel Detayları</h4>
                <div className="border p-4 rounded-lg shadow mt-4">
                  <HotelDetailsIcons details={otel.details} />
                </div>
              </li>
              <li tw="hidden md:block">
                {" "}
                <RoomTypes roomTypes={otel.room_types} />
              </li>
            </ul>
          </ImageColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <TextContent>
              <Heading>
                {otel?.otel_name
                  ? otel?.otel_name
                  : "We have the best service."}
                <Stars style={{ fontSize: "20px" }} count={otel?.otel_stars} />
              </Heading>
              <Description>
                {otel?.address.street_address
                  ? otel?.address.street_address
                  : "	Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod tempor incididunt ut labore et dolore magna aliqua. Utenim ad minim veniam, quis nostrud exercitation ullamco laborisnisi ut aliquip ex ea commodo consequat."}
              </Description>
              <div className="mb-4">{otel.otel_description}</div>
              <div tw="md:hidden" className="mb-4">
                <h4 tw="text-xl font-semibold mt-6 mb-4">Otel Detayları</h4>
                <div className="border p-4 rounded-lg shadow mt-4">
                  <HotelDetailsIcons details={otel.details} />
                </div>
                <RoomTypes roomTypes={otel.room_types} />
              </div>
              <span>
                {otel.address.country} /{otel.address.district} /
                {otel.address.state}
              </span>
              {otel.address && <GoogleMapIframe address={otel.address} />}
            </TextContent>
          </TextColumn>
        </TwoColumn>
      </Container>
    </div>
  );
};
