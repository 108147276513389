import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Modal, Button, Table, Alert } from "react-bootstrap";
import { axios } from "common/utils/axios";
import EditTourTest from "./EditTourTest";

// Function to fetch tours
const fetchTours = async () => {
  try {
    const response = await axios.get("/api/newtour"); // Replace with your actual API endpoint
    return response.data; // Axios wraps the response data inside the data property
  } catch (error) {
    // Handle the error appropriately
    // You can throw an error or return a default value
    throw new Error(
      error.response?.data?.message ||
        "An error occurred while fetching the tours."
    );
  }
};
const deleteTour = async (tourId) => {
  await axios.delete(`/api/newtour/${tourId}`); // Adjust the endpoint as necessary
};

// ToursList component
const ToursList = () => {
  // State to handle the modal visibility and the selected tour
  const [show, setShow] = React.useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedTour, setSelectedTour] = React.useState(null);
  const [errors, setError] = useState("");
  const queryClient = useQueryClient();
  const [editMode, setEditMode] = useState(false);
  const [editTourData, setEditTourData] = useState({});
  // Using react-query to fetch tours
  const { data, error, isLoading } = useQuery("tours", fetchTours);

  const deleteMutation = useMutation(deleteTour, {
    onSuccess: () => {
      queryClient.invalidateQueries("tours");
      setShowConfirm(false); // Close the confirmation modal on success
    },
    onError: (error) => {
      // Show error message
      setError(
        error.response?.data?.message || "An error occurred during deletion."
      );
    },
  });
  // -----------------------------------------------------------
  const editTour = async ({ tourId, tourData }) => {
    await axios.put(`/api/newtour/${tourId}`, tourData); // Adjust with your actual API endpoint
  };
  const editMutation = useMutation(editTour, {
    onSuccess: () => {
      queryClient.invalidateQueries("tours");
      setEditMode(false); // Close the edit modal on success
    },
    // onError can be handled similarly to deleteMutation if needed
  });
  useEffect(() => {
    if (!editMode) setEditTourData({});
  }, [editMode]);
  const handleShowEdit = (tour) => {
    setEditTourData(tour);
    setEditMode(true);
  };
  const formatTimeTo24Hour = (time12h) => {
    const [time, modifier] = time12h.split(" ");
    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  };

  const handleEditChange = (e, index, section) => {
    const { name, value } = e.target;
    let formattedValue = value;

    // Format the timing to 24-hour format if it's in 12-hour format
    if (name === "timing" && value.match(/\d{1,2}:\d{2}\s?(AM|PM)/i)) {
      formattedValue = formatTimeTo24Hour(value);
    }

    if (section) {
      setEditTourData((prev) => {
        const sectionCopy = [...prev[section]];
        sectionCopy[index] = { ...sectionCopy[index], [name]: formattedValue };
        return { ...prev, [section]: sectionCopy };
      });
    } else {
      setEditTourData((prev) => ({ ...prev, [name]: formattedValue }));
    }
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    editMutation.mutate({
      tourId: editTourData.tourId,
      tourData: editTourData,
    });
  };
  // -------------------------------------------------------------
  const handleShowConfirm = (tour) => {
    setError(""); // Clear any previous errors
    setSelectedTour(tour);
    setShowConfirm(true);
  };

  const handleDelete = () => {
    deleteMutation.mutate(selectedTour.tourId);
  };

  // Handlers for showing and hiding the modal
  const handleClose = () => setShow(false);
  const handleShow = (tour) => {
    setSelectedTour(tour);
    setShow(true);
  };

  if (isLoading) return "Loading...";
  if (error) return "An error has occurred: " + error.message;

  console.log(data, "data");
  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Tour Name</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          {data.map((tour) => (
            <tr key={tour.tourId}>
              <td>{tour.tourId}</td>
              <td>{tour.tourName}</td>
              <td>
                <Button
                  className="mr-2"
                  variant="primary"
                  onClick={() => handleShow(tour)}
                >
                  Show
                </Button>

                <Button
                  className="mr-2"
                  variant="danger"
                  onClick={() => handleShowConfirm(tour)} // Open confirmation modal
                >
                  Delete
                </Button>
                <Button variant="warning" onClick={() => handleShowEdit(tour)}>
                  Edit
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Tour Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedTour && (
            <div>
              <p>
                <strong>ID:</strong> {selectedTour.id}
              </p>
              <p>
                <strong>Tour Name:</strong> {selectedTour.tourName}
              </p>
              <p>
                <strong>Dealer:</strong> {selectedTour.dealer}
              </p>
              <p>
                <strong>Days:</strong> {selectedTour.days}
              </p>
              <p>
                <strong>Nights:</strong> {selectedTour.nights}
              </p>
              <p>
                <strong>Starting Date:</strong> {selectedTour.startingDate}
              </p>
              <p>
                <strong>Ending Date:</strong> {selectedTour.endingDate}
              </p>
              <p>
                <strong>Departure City:</strong> {selectedTour.departureCity}
              </p>
              <p>
                <strong>Return City:</strong> {selectedTour.returnCity}
              </p>
              <p>
                <strong>Publication Date:</strong>{" "}
                {selectedTour.publicationDate}
              </p>
              <p>
                <strong>Publication EndDate:</strong>{" "}
                {selectedTour.publicationEndDate}
              </p>
              <p>
                <strong>capacity:</strong> {selectedTour.capacity}
              </p>
              <p>
                <strong>season:</strong> {selectedTour.season}
              </p>
              <p>
                <strong>childAge:</strong> {selectedTour.childAge}
              </p>
              <p>
                <strong>childs:</strong> {selectedTour.childs}
              </p>
              <p>
                <strong>intimateControl:</strong> {selectedTour.intimateControl}
              </p>
              <p>
                <strong>withAccommodation:</strong>{" "}
                {selectedTour.withAccommodation}
              </p>
              <p>
                <strong>showOnWebsite:</strong> {selectedTour.showOnWebsite}
              </p>
              <p>
                <strong>lock:</strong> {selectedTour.lock}
              </p>
              <p>
                <strong>visa:</strong> {selectedTour.visa}
              </p>
              <hr />
              <h2>Companies</h2>
              <p>
                {selectedTour.companies.map((company) => (
                  <div key={company.id}>
                    {" "}
                    {/* Make sure each company has a unique 'id' prop */}
                    <strong>Company Name:</strong> {company.companyName}
                  </div>
                ))}
              </p>
              <hr />
              <h2>Food</h2>
              <p>
                {selectedTour.food_options.map((food_option) => (
                  <div key={food_option.id}>
                    {" "}
                    {/* Make sure each company has a unique 'id' prop */}
                    <strong>Food:</strong> {food_option.foodOptionName}
                  </div>
                ))}
              </p>
              <hr />
              <h2>Trips</h2>
              <p>
                {selectedTour.trips.map((trip) => (
                  <div key={trip.id}>
                    {" "}
                    {/* Make sure each company has a unique 'id' prop */}
                    <p>
                      <strong>Title:</strong> {trip.tripTitle}
                    </p>
                    <p>
                      <strong>Trip Description:</strong> {trip.tripDescription}
                    </p>
                    <p>
                      <strong>HowMany Days:</strong> {trip.howManyDays}
                    </p>
                    <p>
                      <strong>Timing:</strong> {trip.timing}
                    </p>
                    <p>
                      <strong>Package Pricing:</strong> {trip.packagePricing}
                    </p>
                  </div>
                ))}
              </p>
              <hr />
              <h2>Transportaion</h2>
              <p>
                {selectedTour.transportations.map((transportation) => (
                  <div key={transportation.id}>
                    {" "}
                    {/* Make sure each company has a unique 'id' prop */}
                    <p>
                      <strong>Company :</strong> {transportation.company}
                    </p>
                    <p>
                      <strong>From Location:</strong>{" "}
                      {transportation.fromLocation}
                    </p>
                    <p>
                      <strong>To Location:</strong> {transportation.toLocation}
                    </p>
                    <p>
                      <strong>Expedition Number:</strong>{" "}
                      {transportation.expeditionNumber}
                    </p>
                    <p>
                      <strong>Departure DateTime:</strong>{" "}
                      {transportation.departureDateTime}
                    </p>
                  </div>
                ))}
              </p>
              <hr />
              <h2>Pric</h2>
              <p>
                <p>
                  <strong>Main Price :</strong>{" "}
                  {selectedTour?.pricing?.mainPrice}{" "}
                  {selectedTour?.pricing?.pricingType}
                </p>
              </p>
              {selectedTour?.pricing?.discount_prices.map((discount_price) => (
                <div>
                  <p>
                    <strong>Discount Price : </strong>{" "}
                    {discount_price.discountPrice}
                  </p>
                  <p>
                    <strong>Added Option Price : </strong>{" "}
                    {discount_price.addedOptionPrice}
                  </p>
                  <p>
                    <strong>Issued Option Price : </strong>{" "}
                    {discount_price.issuedOptionPrice}
                  </p>
                </div>
              ))}
              {/* You can add more details here */}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          Are you sure you want to delete this tour?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirm(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete Tour
          </Button>
        </Modal.Footer>
      </Modal>

      <EditTourTest
        editMode={editMode}
        setEditMode={setEditMode}
        handleEditChange={handleEditChange}
        editTourData={editTourData}
        handleEditSubmit={handleEditSubmit}
      />
    </>
  );
};

export default ToursList;
