import React, { useState, useEffect, useRef } from "react";
import "assets/styles/CustomeDropZone.css";
import { Row, Col } from "react-bootstrap";
const CustomeDropZone = ({
  onImagesUpdated,
  updateMainPhotograph,
  updateInsidePhotographs,
  updateOutsidePhotographs,
}) => {
  const [images, setImages] = useState([]);
  const [mainImageIndex, setMainImageIndex] = useState(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const fileInputRef = useRef(null);

  const handleDragOver = (e) => {
    e.preventDefault(); // Necessary to allow for dropping
  };
  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      const newImages = Array.from(files).map((file) => ({
        file,
        category: "inside", // Default category
      }));
      setImages([...images, ...newImages]);
    }
  };
  const handleDropzoneClick = () => {
    fileInputRef.current.click();
  };
  const handleImageChange = (event) => {
    const newImages = Array.from(event.target.files).map((file) => ({
      file,
      category: "inside", // Default category
    }));
    setImages([...images, ...newImages]);
  };

  const handleRemoveImage = (index, event) => {
    if (event) event.preventDefault();
    const newImages = images.filter((_, i) => i !== index);
    setImages(newImages);
    if (mainImageIndex === index) {
      setMainImageIndex(null);
    }
  };
  const handleSelectMainImage = (index, event) => {
    event.preventDefault();
    setMainImageIndex(index);
  };

  const handleCategoryChange = (index, category) => {
    const newImages = [...images];
    newImages[index].category = category;
    setImages(newImages);
  };

  // Call this function to update parent component's state
  useEffect(() => {
    const mainImage =
      mainImageIndex !== null ? images[mainImageIndex].file : null;
    const insideImages = images
      .filter(
        (image, index) =>
          image.category === "inside" && index !== mainImageIndex
      )
      .map((image) => image.file); // Extract only the File objects
    const outsideImages = images
      .filter(
        (image, index) =>
          image.category === "outside" && index !== mainImageIndex
      )
      .map((image) => image.file); // Extract only the File objects

    updateMainPhotograph(mainImage);
    updateInsidePhotographs(insideImages);
    updateOutsidePhotographs(outsideImages);
  }, [images, mainImageIndex]);

  return (
    <div>
      <div
        className="custom-dropzone"
        onClick={handleDropzoneClick}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        Resimleri yüklemek için buraya tıklayın veya sürükleyin
      </div>
      <input
        ref={fileInputRef}
        className="file-input"
        type="file"
        multiple
        onChange={handleImageChange}
      />
      <div className="image-gallery">
        {images.map((image, index) => (
          <div key={index} className="image-thumbnail">
            <div className="image-tooltip">
              {`${(image.file.size / 1024).toFixed(2)} KB`}{" "}
            </div>
            <img src={URL.createObjectURL(image.file)} alt={`Image ${index}`} />
            <div className="image-controls">
              <button
                style={{ fontSize: "15px" }}
                onClick={(e) => handleSelectMainImage(index, e)}
              >
                {mainImageIndex === index ? "Ana Resim" : "Ana olarak Ayarla"}
              </button>
              <select
                value={image.category}
                onChange={(e) => handleCategoryChange(index, e.target.value)}
              >
                <option value="inside">İç</option>
                <option value="outside">Dış</option>
              </select>
            </div>
            <button
              className="remove-button"
              onClick={(e) => handleRemoveImage(index, e)}
            >
              Kaldır
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomeDropZone;
