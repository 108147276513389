import { useQuery } from "react-query";
import { axios } from "common/utils/axios";

const fetchCustomers = (page, pageSize, searchFilter) => {
  // Construct a query string from the searchFilter object
  const queryString = new URLSearchParams({
    ...searchFilter,
    page,
    pageSize,
  }).toString();

  return axios
    .get(`/api/customersfilter?${queryString}`)
    .then((res) => res.data);
};

export const useCustomerPagenationList = (page, pageSize, searchFilter) => {
  const { data, error, isLoading } = useQuery(
    ["customers", page, searchFilter], // Include searchFilter in the query key
    () => fetchCustomers(page, pageSize, searchFilter),
    {
      keepPreviousData: true,
    }
  );

  return {
    customerData: data,
    error,
    isLoading,
  };
};
