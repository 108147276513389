import { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { updateHotel } from "common/Api/HotelsApi";
import { Modal, Button, Row, Col, Form, ListGroup } from "react-bootstrap";
import HotelDetails from "area/admin/Hotels/components/edit/HotelDetails";
import HotelRooms from "area/admin/Hotels/components/edit/HotelRooms";
import HotelAddress from "area/admin/Hotels/components/edit/HotelAddress";
import HotelPhotograhps from "area/admin/Hotels/components/edit/HotelPhotograhps";
import classNames from "classnames";
import { toast } from "react-toastify";
const sectionTitles = ["Otel", "Otel Odalari", "Konum"];
export const EditHotelModal = ({
  otelData,
  modalShow,
  setModalShow,
  selectedHotel,
  handleNextSection,
  handlePreviousSection,
  activeSection,
  setActiveSection,
}) => {
  const [formData, setFormData] = useState({
    otel_name: "",
    otel_stars: "",
    otel_description: "",

    otel_details: {
      wifi: false,
      rating: null, // or a default value if you prefer
      food: false,
      microwave: false,
      kitchen: false,
      first_aid_kit: false,
      heating: false,
      cooling: false,
    },

    otel_address: {
      country: "",
      state: "",
      district: "",
      neighborhood: "",
      street_address: "",
      postal_code: "",
    },

    room_types: [],
    // photographs: [],
  });
  const updateFormData = (sectionData) => {
    setFormData((prev) => {
      const updatedFormData = { ...prev, ...sectionData };

      return updatedFormData;
    });
  };
  const mutation = useMutation(
    (newHotelData) => updateHotel(otelData.otel_id, newHotelData),
    {
      onSuccess: () => {
        // Handle successful update
        toast.success("Hotel updated successfully");
      },
      onError: (error) => {
        // Handle error case
        toast.error(`Error updating hotel: ${error.message}`);
      },
    }
  );
  const handleSubmitFinal = () => {
    mutation.mutate(formData);
  };

  useEffect(() => {
    if (otelData) {
      // const mainPhotograph = otelData.photographs.find(
      //   (p) => p.photo_type === "main"
      // );
      // const insidePhotographs = otelData.photographs
      //   .filter((p) => p.photo_type === "inside")
      //   .map((p) => p.photo_url);
      // const outsidePhotographs = otelData.photographs
      //   .filter((p) => p.photo_type === "outside")
      //   .map((p) => p.photo_url);

      setFormData({
        otel_name: otelData.otel_name || "",
        otel_stars: otelData.otel_stars || "",
        otel_description: otelData.otel_description || "",
        otel_details:
          otelData.details ||
          {
            // default details structure
          },
        otel_address:
          otelData.address ||
          {
            // default address structure
          },
        room_types: otelData.room_types || [],
        // photographs: {
        //   mainPhotograph: mainPhotograph ? mainPhotograph.photo_url : null,
        //   insidePhotographs,
        //   outsidePhotographs,
        // },
      });
    }
  }, [otelData]);
  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        centered
      >
        <Modal.Body style={{ height: "495px", overflowY: "auto" }}>
          <Row className="mb-4 text-center ">
            <Col md={12}>
              <ListGroup className="list-group-horizontal-md">
                {sectionTitles &&
                  sectionTitles?.map((title, index) => (
                    <ListGroup.Item
                      key={index}
                      className={classNames({
                        "active-list-group-item": activeSection === index + 1,
                      })}
                      action
                      active={activeSection === index + 1}
                      onClick={() => setActiveSection(index + 1)}
                    >
                      {title}
                    </ListGroup.Item>
                  ))}
              </ListGroup>
            </Col>
          </Row>
          <Form>
            <Row>
              <Col md={12}>
                {activeSection === 1 && (
                  <HotelDetails
                    updateFormData={updateFormData}
                    otelData={otelData}
                    formData={formData}
                  />
                )}
              </Col>
              <Col md={12}>
                {activeSection === 2 && (
                  <HotelRooms
                    updateFormData={updateFormData}
                    otelData={otelData}
                    formData={formData}
                  />
                )}
              </Col>
              <Col md={12}>
                {activeSection === 3 && (
                  <HotelAddress
                    updateFormData={updateFormData}
                    otelData={otelData}
                    formData={formData}
                  />
                )}
              </Col>
              {/**
                <Col md={12}>
                {activeSection === 4 && (
                  <HotelPhotograhps
                  formData={formData}
                  updateFormData={updateFormData}
                  />
                  )}
                  </Col>
                */}
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            Kapat
          </Button>
          <Button variant="success" onClick={handleSubmitFinal}>
            Kaydet
          </Button>
          {activeSection > 1 && (
            <Button variant="warning" onClick={handlePreviousSection}>
              Önceki
            </Button>
          )}
          {activeSection < 3 ? (
            <>
              <Button variant="primary" onClick={() => handleNextSection({})}>
                Sonraki
              </Button>
            </>
          ) : (
            ""
          )}
        </Modal.Footer>{" "}
      </Modal>
    </div>
  );
};
export default EditHotelModal;
