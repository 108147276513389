import { useMutation, useQueryClient } from "react-query";
import { axios } from "common/utils/axios";
import { toast } from "react-toastify";

const setActiveAddressMutation = async ({ addressId }) => {
  const response = await axios.put(`/api/addresses/${addressId}/setActive`);
  return response.data;
};

const useSetActiveAddress = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(setActiveAddressMutation, {
    onSuccess: (data, variables, context) => {
      // Invalidate and refetch queries related to addresses
      queryClient.invalidateQueries("customerAddresses");

      // Optionally, update local state as well
      if (context && context.setSelectedAddress) {
        context.setSelectedAddress(variables.addressId);
      }
      if (context && context.setAddresses) {
        context.setAddresses((prevAddresses) =>
          prevAddresses.map((address) =>
            address.id === variables.addressId
              ? { ...address, active: true }
              : { ...address, active: false }
          )
        );
      }
      toast.success("Adres başarıyla değiştirildi.!");
    },
    onError: (error) => {
      console.error("API call error:", error);
      toast.error("Failed to change address.");
    },
  });

  const setActiveAddress = (addressId, context) => {
    mutation.mutate({ addressId }, { context });
  };

  return { setActiveAddress, ...mutation };
};

export default useSetActiveAddress;
