import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Title = styled.div`
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 10px;
  }
`;

const StyledButton = styled.button`
  background-color: dodgerblue;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover,
  &:focus {
    background-color: #2980b9;
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
  }
`;

const CreateButtonComponent = ({ link, onClick, title, buttonTitle }) => {
  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <ButtonContainer>
      <Title>{title}</Title>
      {onClick ? (
        <StyledButton onClick={handleClick}>
          <FontAwesomeIcon icon={faPlusSquare} className="mr-2" />
          {buttonTitle}
        </StyledButton>
      ) : (
        <Link to={link} style={{ textDecoration: "none" }}>
          <StyledButton as="div">
            <FontAwesomeIcon icon={faPlusSquare} className="mr-2" />
            {buttonTitle}
          </StyledButton>
        </Link>
      )}
    </ButtonContainer>
  );
};

export default CreateButtonComponent;
