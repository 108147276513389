import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, Row, Col, Button } from "react-bootstrap";
import Slider from "react-slick";
import { createSlug } from "common/helpers/createSlug";
import schedule from "assets/icons/schedule.png";
import TCompany from "assets/icons/TCompany.png";
import elci from "assets/images/elci.png";
import transportaion from "assets/icons/transportaion.png";
import transportaion2 from "assets/icons/transportaion2.png";
import money from "assets/icons/money.png";
import bus from "assets/icons/bus.png";
import night from "assets/icons/night.png";
import night2 from "assets/icons/night2.png";
import night3 from "assets/icons/night3.png";
import day from "assets/icons/day.png";
import airplan from "assets/icons/airplan.png";
import twoPeopleIcon from "assets/icons/people2.png";
import threePeopleIcon from "assets/icons/people3.png";
import fourPeopleIcon from "assets/icons/people4.png";
import { ReactComponent as WifiIcon } from "assets/icons/wifi.svg";
import { ReactComponent as BedIcon } from "assets/icons/bed.svg";
import { ReactComponent as BathIcon } from "assets/icons/bath.svg";
import { ReactComponent as WasherIcon } from "assets/icons/washer.svg";
import { ReactComponent as TvretroIcon } from "assets/icons/tvretro.svg";
import { ReactComponent as UtensilsIcon } from "assets/icons/utensils.svg";
import { ReactComponent as SnowflakeIcon } from "assets/icons/snowflake.svg";
import { ReactComponent as OvenIcon } from "assets/icons/oven.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faDownload } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { generatePDF } from "common/helpers/generatePDF";
import { PDFTemplateTours } from "pages/NewTour/Components/PDFTemplateTours";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "25px" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "25px", zIndex: 1 }}
      onClick={onClick}
    />
  );
}

export const TourCards = ({
  tours,
  tourData,
  categoryName,
  selectedTours,
  handleCheckboxChange,
  isSelected,
}) => {
  console.log(tourData, "tourData");
  const [hotelData, setHotelData] = useState([]);
  const [activeSlide, setActiveSlide] = useState(0);
  const [toursWithImages, setToursWithImages] = useState([]);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: (current) => setActiveSlide(current),
  };
  const toggleFavorite = (item, isTour) => {
    if (isTour) {
      if (!item.isFavorited) {
        toast.success("Tur favorilere eklendi.", { autoClose: 2000 });
      }
      // setTourData({ ...item, isFavorited: !item.isFavorited });
    } else {
      setHotelData(
        hotelData.map((hotel) =>
          hotel === item ? { ...hotel, isFavorited: !hotel.isFavorited } : hotel
        )
      );
    }
  };
  const getPeopleIcon = (howManyPeople) => {
    switch (howManyPeople) {
      case 2:
        return twoPeopleIcon;
      case 3:
        return threePeopleIcon;
      default:
        return fourPeopleIcon; // Used for 4 or more people
    }
  };
  const currencySymbols = {
    TRY: "₺", // Turkish Lira
    USD: "$", // US Dollar
    EUR: "€", // Euro
  };
  const getCurrencySymbol = (currencyCode) => {
    return currencySymbols[currencyCode] || currencyCode; // Default to the code itself if no symbol is found
  };
  const { smallestPrice, currencyCode } = tourData.pricing.reduce(
    (acc, row) => {
      const price = Number(row.mainPrice);
      if (price < acc.smallestPrice) {
        return { smallestPrice: price, currencyCode: row.pricingType };
      }
      return acc;
    },
    { smallestPrice: Infinity, currencyCode: "" }
  );

  useEffect(() => {
    if (tourData && tourData.accomodations) {
      const extractedHotelData = tourData.accomodations.map((accomodation) => ({
        ...accomodation.hotel,
        isFavorited: false,
      }));
      setHotelData(extractedHotelData);
    }
  }, [tourData]);
  console.log(selectedTours, "selectedTours");
  return (
    <>
      <Card className="rounded-0 mb-4">
        <Card.Header>
          <Row>
            <Col md={10} sm={6} xs={11}>
              <h6 className="monsterratFont w600 grayC pt-1">
                {tourData.tourName}
              </h6>
            </Col>
            <Col md={2} sm={6} xs={1} className="d-flex justify-content-end">
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  id={`customCheckbox-${tourData.tourId}`}
                  onChange={() => handleCheckboxChange(tourData.tourId)}
                  checked={isSelected}
                />
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="cardBodyStyle">
          <Row className="monsterratFont grayC">
            <Col md={4} xl={4} xs={12}>
              <Slider {...settings}>
                {/* Tour Image */}
                <div style={{ position: "relative", height: "200px" }}>
                  <img
                    style={{ height: "190px", borderRadius: "0" }}
                    className="responsive-slider-img"
                    src={tourData?.photos[0]?.file_path}
                    alt="Tour Image"
                  />

                  <FontAwesomeIcon
                    icon={faHeart}
                    className="heart-icon"
                    style={{
                      color: tourData.isFavorited ? "red" : "white",
                    }}
                    onClick={() => toggleFavorite(tourData, true)}
                  />
                </div>
                {/* Hotel Images */}
                {hotelData.map((hotel, index) => (
                  <div key={index}>
                    {hotel.photographs && (
                      <div style={{ position: "relative", height: "200px" }}>
                        {hotel.photographs
                          .filter((photo) => photo.photo_type === "main")
                          .map((mainPhoto, photoIndex) => (
                            <img
                              key={photoIndex}
                              style={{ height: "190px", borderRadius: "0" }}
                              className="responsive-slider-img"
                              src={mainPhoto.photo_url}
                              alt={`Hotel ${hotel.otel_name} Main Photograph`}
                            />
                          ))}
                      </div>
                    )}
                  </div>
                ))}
              </Slider>
            </Col>
            {activeSlide === 0 ? (
              <>
                <Row className="d-block d-md-none d-flex justify-content-center">
                  <Col
                    className="d-block d-md-none pt-1 blurred-box-shadow"
                    xs={7}
                    style={{
                      padding: "0px",
                      marginLeft: "22px",
                      backgroundColor: "white",
                      marginRight: "5px",
                      borderRadius: "2px",
                    }}
                  >
                    <ul className="pl-2" style={{ marginBottom: "0" }}>
                      <li className="d-flex align-items-center">
                        <img className="tour-card-icon" src={schedule} />
                        <span className="tour-card">Tur Tarihleri</span>
                      </li>
                      <li className="mb-1 tour-card">
                        Gidiş :{" "}
                        <span className="fb tour-card">
                          {" "}
                          {tourData.startingDate}
                        </span>{" "}
                      </li>

                      <li className="tour-card">
                        Dönüş :
                        <span className="fb tour-card">
                          {" "}
                          {tourData.endingDate}
                        </span>
                      </li>
                    </ul>
                    <ul
                      style={{
                        width: "75%",
                        marginLeft: "5px",
                        display: "flex",
                        alignItems: "center",
                        listStyle: "none",
                        padding: 0,
                        border: "1px solid rgb(0 0 0 / 36%)",
                        marginBottom: "2px",
                      }}
                    >
                      <li>
                        <img
                          className="tour-card-icon"
                          src={TCompany}
                          alt="TCompany Logo"
                        />
                      </li>
                      <li className="tour-card" style={{ marginRight: "4px" }}>
                        Firma :
                      </li>
                      <li>
                        {tourData.company_logo &&
                        tourData.company_logo.base64 ? (
                          <img
                            className="sponsor-image"
                            src={tourData.company_logo.base64}
                            alt={`${tourData.company_name} Logo`}
                          />
                        ) : (
                          <span>{tourData.company_name}</span> // or any default content if no logo
                        )}
                      </li>
                    </ul>
                  </Col>
                  <Col
                    className="d-block d-md-none pl-2 pt-1 blurred-box-shadow"
                    xs={4}
                    style={{
                      padding: "0px",
                      backgroundColor: "white",
                      borderRadius: "2px",
                    }}
                  >
                    {" "}
                    <ul
                      style={{
                        display: "flex",
                        alignItems: "center",
                        listStyle: "none",
                        padding: 0,
                        marginBottom: "2px",
                      }}
                    >
                      <li className="d-flex align-items-center justify-content-center">
                        {" "}
                        <img className="tour-card-icon" src={transportaion2} />
                        <span className="tour-card">Güzergah</span>
                      </li>
                    </ul>
                    <ul style={{ paddingLeft: "0 !important" }}>
                      <li className="grayC fb tour-card">
                        Istanbul{" "}
                        <img
                          src={bus}
                          alt="Bus"
                          style={{
                            display: "inline",
                            verticalAlign: "middle",
                            height: "1em",
                            marginLeft: "0.1rem",
                            marginRight: "0.1rem",
                          }}
                        />{" "}
                        Meke
                      </li>
                      <li className="grayC fb tour-card">
                        Meke
                        <img
                          src={airplan}
                          alt="Bus"
                          style={{
                            display: "inline",
                            verticalAlign: "middle",
                            height: "1em",
                            marginLeft: "0.2rem",
                            marginRight: "0.2rem",
                          }}
                        />
                        Medine
                      </li>
                      <li className="grayC fb tour-card">
                        Medine
                        <img
                          src={bus}
                          alt="Bus"
                          style={{
                            display: "inline",
                            verticalAlign: "middle",
                            height: "1em",
                            marginLeft: "0.2rem",
                            marginRight: "0.2rem",
                          }}
                        />
                        Istanbul
                      </li>
                    </ul>{" "}
                  </Col>
                </Row>
                <Row className="d-block d-md-none ml-3 pl-2">
                  <Col
                    className="d-block d-md-none blurred-box-shadow"
                    xs={11}
                    style={{ backgroundColor: "white", marginTop: "5px" }}
                  >
                    <ul className="pt-1">
                      <Row className="monsterratFont">
                        {tourData.pricing.map((row) => (
                          <Col xs={4}>
                            <li className="d-flex justify-content-center soponsor-image">
                              <img
                                src={getPeopleIcon(row.howManyPeople)}
                                alt="People Icon"
                              />
                            </li>
                            <li className="d-flex justify-content-center tour-card">
                              {row.howManyPeople} kişi
                            </li>
                            <li className="greenC fb barlow d-flex justify-content-center tour-prices ">
                              {Number(row.mainPrice)}{" "}
                              {getCurrencySymbol(row.pricingType)}
                            </li>
                          </Col>
                        ))}
                      </Row>
                    </ul>
                  </Col>
                </Row>
                {/*End Mobil Verion */}
                <Col md={3} xl={3} xs={6} className="d-none d-md-block">
                  <ul
                    style={{
                      display: "flex",
                      alignItems: "center",
                      listStyle: "none",
                      padding: 0,
                    }}
                  >
                    <li className="d-flex align-items-center">
                      <img
                        style={{ height: "35px", marginRight: "10px" }}
                        src={schedule}
                        alt="Schedule Icon"
                      />
                      <span>Tur Tarihleri</span>
                    </li>
                  </ul>
                  <ul
                    style={{
                      fontSize: "15px",
                      marginBottom: "12px",
                      marginTop: "25px ! important",
                      paddingLeft: "0 !important",
                    }}
                  >
                    <li className="mb-2">
                      Gidiş :{" "}
                      <span style={{ fontSize: "15px" }} className="fb">
                        {" "}
                        {tourData.startingDate}
                      </span>{" "}
                    </li>

                    <li className="">
                      Dönüş :
                      <span className="fb " style={{ fontSize: "15px" }}>
                        {tourData.endingDate}
                      </span>
                    </li>
                  </ul>
                  <ul
                    style={{
                      display: "flex",
                      alignItems: "center",
                      listStyle: "none",
                      padding: 0,
                      border: "1px solid rgb(0 0 0 / 36%)",
                    }}
                  >
                    <li style={{ marginRight: "10px" }}>
                      <img
                        className="soponsor-icon"
                        style={{
                          height: "35px",
                          marginLeft: "5px",
                          marginTop: "5px",
                          marginBottom: "5px",
                        }}
                        src={TCompany}
                        alt="TCompany Logo"
                      />
                    </li>
                    <li className="soponsor" style={{ marginRight: "10px" }}>
                      Firma :
                    </li>
                    <li>
                      {tourData.company_logo && tourData.company_logo.base64 ? (
                        <img
                          className="sponsor-image"
                          src={tourData.company_logo.base64}
                          alt={`${tourData.company_name} Logo`}
                        />
                      ) : (
                        <span>{tourData.company_name || "No Sponsor"}</span>
                      )}
                    </li>
                  </ul>
                </Col>
                <Col md={2} xl={2} xs={6} className="d-none d-md-block">
                  <ul
                    style={{
                      display: "flex",
                      alignItems: "center",
                      listStyle: "none",
                      padding: 0,
                    }}
                  >
                    <li className="d-flex align-items-center justify-content-center">
                      {" "}
                      <img
                        style={{ height: "35px", marginRight: "10px" }}
                        src={transportaion2}
                      />
                      <span>Güzergah</span>
                    </li>
                  </ul>
                  <ul style={{ paddingLeft: "0 !important" }}>
                    <li
                      className="grayC fb "
                      style={{
                        fontSize: "15px",
                        marginBottom: "10px",
                        paddingTop: "5px",
                      }}
                    >
                      Istanbul{" "}
                      <img
                        src={bus}
                        alt="Bus"
                        style={{
                          display: "inline",
                          verticalAlign: "middle",
                          height: "1em",
                          marginLeft: "0.1rem",
                          marginRight: "0.1rem",
                        }}
                      />{" "}
                      Meke
                    </li>
                    <li
                      className="grayC fb"
                      style={{ fontSize: "15px", marginBottom: "10px" }}
                    >
                      Meke
                      <img
                        src={airplan}
                        alt="Bus"
                        style={{
                          display: "inline",
                          verticalAlign: "middle",
                          height: "1em",
                          marginLeft: "0.2rem",
                          marginRight: "0.2rem",
                        }}
                      />
                      Medine
                    </li>
                    <li style={{ fontSize: "15px" }} className="grayC fb">
                      Medine
                      <img
                        src={bus}
                        alt="Bus"
                        style={{
                          display: "inline",
                          verticalAlign: "middle",
                          height: "1em",
                          marginLeft: "0.2rem",
                          marginRight: "0.2rem",
                        }}
                      />
                      Istanbul
                    </li>
                  </ul>
                </Col>
                <Col md={3} xl={3} className="d-none d-md-block">
                  <ul>
                    <li className="d-flex justify-content-center">
                      <img style={{ height: "40px" }} src={money} />
                    </li>
                  </ul>
                  <ul className="pt-2">
                    <Row className="monsterratFont">
                      {tourData.pricing.map((row) => (
                        <Col md={4} xs={4} xxl={4}>
                          <li className="d-flex justify-content-center">
                            <img
                              src={getPeopleIcon(row.howManyPeople)}
                              alt="People Icon"
                            />
                          </li>
                          <li className="d-flex justify-content-center">
                            {row.howManyPeople} kişi
                          </li>
                          <li
                            style={{ fontSize: "1.15rem" }}
                            className="greenC fb barlow d-flex justify-content-center"
                          >
                            {Number(row.mainPrice)}{" "}
                            {getCurrencySymbol(row.pricingType)}
                          </li>
                        </Col>
                      ))}
                    </Row>
                  </ul>
                </Col>
              </>
            ) : (
              <>
                <Col
                  xs={6}
                  className="d-block d-md-none blurred-box-shadow-card mr-2 ml-4"
                >
                  <ul>
                    <li>{hotelData[activeSlide - 1].otel_name}</li>
                    <li
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <span
                        className="barlow"
                        style={{
                          fontSize: "0.7rem",
                          backgroundColor: "#288728",
                          borderRadius: "0.5px",
                          color: "white",
                          padding: "2px 7px",
                          marginRight: "10px",
                        }}
                      >
                        {hotelData[activeSlide - 1].otel_stars || 4.3}
                      </span>
                      <div>
                        <div style={{ fontSize: "0.6rem" }} className="fb">
                          Harika
                        </div>
                        <div style={{ fontSize: "0.6rem" }} className="barlow">
                          200 yorum
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>

                <Col
                  xs={4}
                  className="d-block d-md-none blurred-box-shadow-card"
                >
                  <ul>
                    <li
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "0 !important",
                        margin: "0 !important",
                        marginBottom: "2px",
                      }}
                    >
                      <img
                        src={day}
                        alt="Day Icon"
                        style={{ marginRight: "10px", height: "20px" }}
                      />
                      <span className="tour-card">
                        {hotelData[activeSlide - 1].HowManyDays}
                      </span>
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={night3}
                        alt="Night Icon"
                        style={{ marginRight: "10px", height: "20px" }}
                      />
                      <span className="tour-card">
                        {hotelData[activeSlide - 1].HowManyNights}
                      </span>
                    </li>
                  </ul>
                </Col>
                <Col
                  xs={12}
                  className="d-block d-md-none blurred-box-shadow-card mt-2"
                >
                  {" "}
                  <ul>
                    <li style={{ fontSize: "12px" }}>
                      {hotelData[activeSlide - 1].otelLocation}
                    </li>
                    <li>
                      <div style={{ width: "100%", height: "150px" }}>
                        <iframe
                          title="Hotel Location"
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3010.8313553919584!2d28.951869684585983!3d41.00706427930081!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac9ce9db2146d%3A0xc5d066ff4c0de45b!2sHOTEL%20MEKKE!5e0!3m2!1sar!2str!4v1701851980711!5m2!1sar!2str"
                          width="100%"
                          height="100%"
                          frameBorder="0"
                          style={{ border: 0 }}
                          allowFullScreen
                        ></iframe>
                      </div>
                    </li>
                  </ul>
                </Col>
                {/* End Mobile version*/}
                <Col md={3} xl={3} xs={6} className="d-none d-md-block">
                  <ul>
                    <li style={{ fontSize: "1.2rem" }} className="fb">
                      {hotelData[activeSlide - 1].otel_name}
                    </li>
                    <li>
                      {" "}
                      {hotelData[activeSlide - 1].address?.street_address}
                    </li>
                    <li
                      className="mt-3"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "3px",
                      }}
                    >
                      <img
                        src={day}
                        alt="Day Icon"
                        style={{ marginRight: "10px", height: "20px" }}
                      />
                      <span>{hotelData[activeSlide - 1].numberOfDays}</span>
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={night3}
                        alt="Night Icon"
                        style={{ marginRight: "10px", height: "20px" }}
                      />
                      <span>{hotelData[activeSlide - 1].numberOfNights}</span>
                    </li>

                    <li
                      className="mt-3"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <span
                        className="barlow"
                        style={{
                          backgroundColor: "#288728",
                          borderRadius: "0.5px",
                          color: "white",
                          padding: "2px 7px",
                          marginRight: "10px", // Added margin for spacing
                        }}
                      >
                        {hotelData[activeSlide - 1].otel_stars || 4.3}
                      </span>
                      <div>
                        <div style={{ fontSize: "0.8rem" }} className="fb">
                          Harika
                        </div>
                        <div style={{ fontSize: "0.8rem" }} className="barlow">
                          200 yorum
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
                <Col md={2} xl={3} xs={6} className="d-none d-md-block">
                  <ul>
                    <li>{hotelData[activeSlide - 1].otelLocation}</li>
                    <li>
                      <div style={{ width: "100%", height: "150px" }}>
                        <iframe
                          title="Hotel Location"
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3010.8313553919584!2d28.951869684585983!3d41.00706427930081!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac9ce9db2146d%3A0xc5d066ff4c0de45b!2sHOTEL%20MEKKE!5e0!3m2!1sar!2str!4v1701851980711!5m2!1sar!2str"
                          width="100%"
                          height="100%"
                          frameBorder="0"
                          style={{ border: 0 }}
                          allowFullScreen
                        ></iframe>
                      </div>
                    </li>
                  </ul>
                </Col>
                <Col md={3} xl={2} xs={6} className="d-none d-md-block">
                  <ul>
                    <Row>
                      <Col
                        className="grayC d-none d-md-block"
                        md={12}
                        xl={12}
                        xxl={12}
                      >
                        {" "}
                        Özelikleri
                      </Col>
                      {hotelData[activeSlide] &&
                        hotelData[activeSlide].details && (
                          <>
                            {hotelData[activeSlide].details.wifi === 1 && (
                              <Col
                                md={6}
                                xl={6}
                                xxl={6}
                                className="mb-2 d-flex align-items-center mb-2"
                              >
                                <WifiIcon
                                  style={{
                                    width: "17px",
                                  }}
                                />
                                <span style={{ fontSize: "12px" }}> Wifi </span>
                              </Col>
                            )}
                            {hotelData[activeSlide].details.bed === 1 && (
                              <Col
                                md={6}
                                xl={6}
                                xxl={6}
                                className="mb-2 d-flex align-items-center mb-2"
                              >
                                <BedIcon
                                  style={{
                                    width: "17px",
                                  }}
                                />
                                <span style={{ fontSize: "12px" }}> Bed </span>
                              </Col>
                            )}
                            {hotelData[activeSlide].details.bath === 1 && (
                              <Col
                                md={6}
                                xl={6}
                                xxl={6}
                                className="mb-2 d-flex align-items-center mb-2 mt-2"
                              >
                                <BathIcon
                                  style={{
                                    width: "17px",
                                    fill: "red !important",
                                  }}
                                />

                                <span style={{ fontSize: "12px" }}> Bath </span>
                              </Col>
                            )}
                            {hotelData[activeSlide].details.washer === 1 && (
                              <Col
                                md={6}
                                xl={6}
                                xxl={6}
                                className="mb-2 d-flex align-items-center mb-2 mt-2"
                              >
                                <WasherIcon />

                                <span style={{ fontSize: "12px" }}>
                                  {" "}
                                  Washer{" "}
                                </span>
                              </Col>
                            )}
                            {hotelData[activeSlide].details.tv === 1 && (
                              <Col
                                md={6}
                                xl={6}
                                xxl={6}
                                className="mb-2 d-flex align-items-center mb-2 mt-2"
                              >
                                <TvretroIcon />

                                <span style={{ fontSize: "12px" }}> TV </span>
                              </Col>
                            )}
                            {hotelData[activeSlide].details.cooling === 1 && (
                              <Col
                                md={6}
                                xl={6}
                                xxl={6}
                                className="mb-2 d-flex align-items-center mb-2 mt-2"
                              >
                                <SnowflakeIcon />

                                <span style={{ fontSize: "12px" }}> AC </span>
                              </Col>
                            )}
                            {hotelData[activeSlide].details.oven === 1 && (
                              <Col
                                md={6}
                                xl={6}
                                xxl={6}
                                className="mb-2 d-flex align-items-center mb-2 mt-2"
                              >
                                <OvenIcon />

                                <span style={{ fontSize: "12px" }}> Oven </span>
                              </Col>
                            )}
                            {hotelData[activeSlide].details.food === 1 && (
                              <Col
                                md={6}
                                xl={6}
                                xxl={6}
                                className="mb-2 d-flex align-items-center mt-2"
                              >
                                <UtensilsIcon />

                                <span style={{ fontSize: "12px" }}>
                                  {" "}
                                  Utensils{" "}
                                </span>
                              </Col>
                            )}

                            {/*  Need To ADD THE RIGHT ICONS HERE */}

                            {hotelData[activeSlide].details.first_aid_kit ===
                              1 && (
                              <Col
                                md={6}
                                xl={6}
                                xxl={6}
                                className="mb-2 d-flex align-items-center mt-2"
                              >
                                <UtensilsIcon />

                                <span style={{ fontSize: "12px" }}>
                                  {" "}
                                  Utensils{" "}
                                </span>
                              </Col>
                            )}
                            {hotelData[activeSlide].details.heating === 1 && (
                              <Col
                                md={6}
                                xl={6}
                                xxl={6}
                                className="mb-2 d-flex align-items-center mt-2"
                              >
                                <UtensilsIcon />

                                <span style={{ fontSize: "12px" }}>
                                  {" "}
                                  Utensils{" "}
                                </span>
                              </Col>
                            )}
                            {hotelData[activeSlide].details.kitchen === 1 && (
                              <Col
                                md={6}
                                xl={6}
                                xxl={6}
                                className="mb-2 d-flex align-items-center mt-2"
                              >
                                <UtensilsIcon />

                                <span style={{ fontSize: "12px" }}>
                                  {" "}
                                  Utensils{" "}
                                </span>
                              </Col>
                            )}

                            <Col>
                              {hotelData[activeSlide - 1].wifi}
                              {hotelData[activeSlide - 1].food}
                              {hotelData[activeSlide - 1].bathroom}
                            </Col>
                          </>
                        )}
                    </Row>
                  </ul>
                </Col>
              </>
            )}
          </Row>
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col xs={7} md={6} className="monsterratFont fb pt-2 pt-md-1 ">
              <span className="greenC barlow footer-price">
                {smallestPrice} {currencyCode}
              </span>{" "}
              <span className="grayC footer-price">
                ‘den başlayan fiyatlarla
              </span>
            </Col>
            <Col xs={5} md={6} className="d-flex justify-content-end pt-md-0">
              <Link to={`${tourData.slug}`}>
                <button className="satinAlButton">Satın Al</button>
              </Link>
            </Col>
          </Row>
        </Card.Footer>
      </Card>

      <PDFTemplateTours
        getCurrencySymbol={getCurrencySymbol}
        selectedTours={selectedTours}
        tours={tours}
        getPeopleIcon={getPeopleIcon}
      />
      {selectedTours?.length > 0 && (
        <div
          style={{
            zIndex: "10",
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            width: "100%",
            backgroundColor: "#f8f9fa",
            paddingBottom: "5px",
            paddingTop: "10px",
            borderTop: "1px solid #ddd",
          }}
        >
          <Row>
            <Col className="pl-4">
              <p>{selectedTours.length} seçilen turlar</p>
            </Col>
            <Col className="text-center">
              <Button
                style={{ fontSize: "13px" }}
                onClick={() => generatePDF(selectedTours, tours)}
              >
                Seçilen Turları PDF Olarak İndirin{"  "}
                <FontAwesomeIcon icon={faDownload} />
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};
export default TourCards;
