import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPlusCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

import { axios } from "common/utils/axios";
import OtelsEditModal from "./ServiceOtelsComponents/OtelsEditModal";
import OtelsCreateModal from "./ServiceOtelsComponents/OtelsCreateModal";
import OtelsDeleteModal from "./ServiceOtelsComponents/OtelsDeleteModal";

export default function Otels() {
  const [openModal, setOpenModal] = useState([]);
  const [otels, setOtels] = useState();
  const [otel, setotel] = useState(null);

  const getOtels = async () => {
    const res = await axios.get("api/otel");
    if (res) {
      console.log("otels", res);
      setOtels(res.data.otels);
    }
  };

  const editOnClick = (otel) => {
    setotel(otel);
    setOpenModal(["edit"]);
  };

  const deleteOnClick = (otel) => {
    setotel(otel);
    setOpenModal(["delete"]);
  };

  useEffect(() => {
    getOtels();
  }, []);
  const Content = () => {
    return (
      <div>
        {otels?.map((otel, index) => {
          console.log(otel);
          return (
            <div key={index} className="grid grid-cols-4  mt-2 bg-white">
              <div className="h-40 grid bg-blue-200">
                <img
                  className="m-auto h-40 w-full object-fill"
                  src={otel.cover_picture}
                  alt=""
                />
              </div>
              <div className=" p-3 m-auto">{otel.otel_name}</div>
              <div className="p-3 m-auto">{otel.otel_desc}</div>
              <div className="flex gap-3 m-auto">
                <FontAwesomeIcon
                  icon={faEdit}
                  className="text-yellow-600"
                  onClick={() => editOnClick(otel)}
                />
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  className="text-red-600"
                  onClick={() => deleteOnClick(otel)}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      {openModal.includes("edit") && (
        <OtelsEditModal
          setOpenModal={setOpenModal}
          otel={otel}
          getOtels={getOtels}
        />
      )}
      {openModal.includes("create") && (
        <OtelsCreateModal
          setOpenModal={setOpenModal}
          otel={otel}
          getOtels={getOtels}
        />
      )}
      {openModal.includes("delete") && (
        <OtelsDeleteModal
          setOpenModal={setOpenModal}
          otel={otel}
          getOtels={getOtels}
        />
      )}
      <div className="w-full bg-black text-white p-2 text-xl">
        Site Değişkenleri / Otel Değişkenleri
      </div>

      <div>
        <div className="w-full bg-black  p-3  mt-2 ">
          <div className="flex justify-between">
            <div>
              <span className="text-white text-xl">Otel Değişkenleri </span>
            </div>
            <div>
              <button
                className="bg-blue-200 p-1 rounded"
                onClick={() => setOpenModal([...openModal, "create"])}
              >
                <FontAwesomeIcon icon={faPlusCircle} className="inline-block" />{" "}
                add new one
              </button>
            </div>
          </div>
          {otels ? (
            <Content />
          ) : (
            <div className="mt-2">
              <div className="loader mx-auto text-black"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
