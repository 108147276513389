import React, { useState } from "react";

export const SideBarAdultSection = ({ adultCount, onAdultCountChange }) => {
  // const [adultCount, onAdultCountChange] = useState(2);

  const handleDecrement = () => {
    if (adultCount > 1) {
      onAdultCountChange(adultCount - 1);
    }
  };

  const handleIncrement = () => {
    if (adultCount < 10) {
      onAdultCountChange(adultCount + 1);
    }
  };

  return (
    <div
      className="container stepper py-2 hotel-accom-calc-row"
      data-min="1"
      data-max="10"
    >
      <div className="input-group range" id="accom-people">
        <span className="input-group-addon">
          <i className="far fa-user-circle fa-2x"></i>
        </span>
        <span className="input-group-addon w70 form-control-font">
          <strong>Yetişkin</strong>
        </span>
        <span className="input-group-prepend">
          <button
            type="button"
            className="btn btn-xs btn-default step-prev"
            onClick={handleDecrement}
          >
            -
          </button>
        </span>
        <input
          type="text"
          className="form-control form-control-font"
          name="calc[adults]"
          id="accom-adult-count"
          readOnly
          value={adultCount}
        />
        <span className="input-group-prepend">
          <button
            type="button"
            className="btn btn-xs btn-default step-next"
            onClick={handleIncrement}
          >
            +
          </button>
        </span>
      </div>
    </div>
  );
};
export default SideBarAdultSection;
