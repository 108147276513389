import React, { useState, useEffect, useRef } from "react";
import { axios } from "common/utils/axios";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Pagination,
  InputGroup,
  FormControl,
  Modal,
  Dropdown,
} from "react-bootstrap";
import { useQuery } from "react-query";
import { fetchReservations } from "common/Api/ReservsationApi";
import CreateReservationModal from "area/admin/ReservationMangment/components/CreateReservationModal";
import EditReservsationModal from "area/admin/ReservationMangment/components/EditReservsationModal";
import CreateReservation from "area/admin/CRUD/CreateButtonComponent";
import ReservationDetailViewer from "./components/ReservationDetailViewer";
import ReservationResev from "./components/ReservationResev";
import ReservationCancellation from "./components/ReservationCancellation";
import ReservationTable from "./components/ReservationTable ";
import { sortFunction } from "common/helpers/SortFunction";
import { toast, ToastContainer } from "react-toastify";
import LineAdvance from "area/admin/CRUD/LineAdvance";
import FilterReservsationComponent from "./components/FilterReservsationComponent";
import { faSearch, faFilter, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useReservationPagenationList from "common/hooks/useReservationPagenationList";
import CreatButton from "area/admin/CRUD/CreatButton";
import DropDownInputBootsrap from "area/admin/CRUD/ReactBootsrap/DropDownInputBootsrap";
import DateInputBootsrap from "area/admin/CRUD/ReactBootsrap/DateInputBootsrap";
import PopUpReserversationTours from "area/admin/ReservationMangment/components/PopUpReserversationTours";

import SearchButtonComponent from "area/admin/CRUD/SearchButtonComponent";
function ReservationManagementList() {
  const dropdownRef = useRef(null);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [showEditModal, setShowEditModal] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [reservations, setReservations] = useState([]);
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [modalShowEdit, setModalShowEdit] = useState(false);
  const [modalShowCreate, setModalShowCreate] = useState(false);
  const [customerNameOrCode, setCustomerNameOrCode] = useState("");
  const [currentReservation, setCurrentReservation] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfirmResevModal, setShowConfirmResevModal] = useState(false);
  const [currentCode, setCurrentCode] = useState(null);

  const [selectedTour, setSelectedTour] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const initialFilter = {
    durum: "",
    payment_deadline: "",
    reservation_date: "",
    tour_id: "",
  };
  const [filter, setFilter] = useState(initialFilter);

  // handle opening and closing detail modal

  const handleSearchFilter = (e) => {
    e.preventDefault();

    // Collect all filter values, including the new ones
    const filters = {
      ...filter,
      tour_id: selectedTour?.id,
      // Include other filter values as needed
    };

    // Update the fetch function or API call to include these filters
    fetchReservations(currentPage + 1, pageSize, customerNameOrCode, filters)
      .then((data) => {
        setReservations(data.reservations);
        setTotalPages(data.totalPages);
      })
      .catch((error) => {
        console.error(
          `There was an error retrieving the reservation data: ${error}`
        );
      });
  };
  useEffect(() => {
    // Step 2: Set up an event listener for clicks
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false); // Step 3: Determine click inside or outside
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Step 4: Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  // fetch reservations
  const { isLoading, error } = useQuery(
    ["reservations", currentPage, pageSize, customerNameOrCode], // Include customerNameOrCode in the query key
    () => fetchReservations(currentPage + 1, pageSize, customerNameOrCode), // Pass customerNameOrCode to the fetch function
    {
      onSuccess: (data) => {
        setReservations(data.reservations);
        setTotalPages(data.totalPages);
      },
      keepPreviousData: true,
    }
  );

  const sortReservations = (field) => {
    const direction =
      sortField === field && sortDirection === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortDirection(direction);
    setReservations(
      [...reservations].sort((a, b) => sortFunction(a, b, direction, field))
    );
  };

  // handle status change
  const handleStatusChangeIptal = (code) => {
    setCurrentCode(code);
    setShowConfirmModal(true);
  };
  const handleStatusChangeReseve = (code) => {
    setCurrentCode(code);
    setShowConfirmResevModal(true);
  };
  // handle cancel
  const handleCancel = () => {
    setCurrentCode(null);
    setShowConfirmModal(false);
  };

  const handleShowDetails = (reservation) => {
    setCurrentReservation(reservation);
    setShowDetails(true);
  };
  const handleShowEdit = (reservation, code) => {
    setCurrentCode(code);
    setCurrentReservation(reservation);
    setModalShowEdit(true);
  };
  const handleHideDetails = () => {
    setShowDetails(false);
  };
  const updateReservations = (newReservation) => {
    setReservations([...reservations, newReservation]);
  };
  // Pagination
  const pages = [];
  for (let i = 0; i < totalPages; i++) {
    pages.push(
      <Pagination.Item
        key={i}
        active={i === currentPage}
        onClick={() => setCurrentPage(i)}
      >
        {i + 1}
      </Pagination.Item>
    );
  }

  const paginatedData = reservations.slice(
    currentPage * pageSize,
    (currentPage + 1) * pageSize
  );
  const durumOptions = [
    { label: "Paid", value: "Paid" },
    { label: "kismi odemi", value: "kismi odemi" },
    { label: "Another", value: "another" },
    { label: "iptal", value: "iptal" },
  ];
  const handleInputChange = (field, value) => {
    console.log(`Selected ${field}: ${value}`);
    setFilter({ ...filter, [field]: value });
  };
  const handleFilter = (e) => {
    e.preventDefault();
    const url = `/api/reservation_management/filter`;

    const dataToSend = {
      ...filter,
      tour_id: selectedTour?.tourId,
      name: customerNameOrCode,
    };

    axios
      .post(url, dataToSend)
      .then((response) => {
        setReservations(response.data);
      })
      .catch((error) => {
        console.error(
          `There was an error retrieving the reservation data: ${error}`
        );
      });
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleClearFilter = () => {
    setFilter({
      durum: "",
      payment_deadline: "",
      reservation_date: "",
    });
    setSelectedTour(null);
  };
  return (
    <div>
      <Card style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)" }}>
        <Card.Header
          style={{
            backgroundColor: "#636665",
            color: "white",
            borderRadius: "5px 5px 0 0",
          }}
        >
          <Row>
            <Col>
              <h1 style={{ padding: "10px", fontSize: "24px", margin: 0 }}>
                Rezervasyon Yönetimi
              </h1>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Row>
                <Col>
                  <CreatButton
                    title={"Reservazyon ekle"}
                    onClick={() => setModalShowCreate(true)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSearchFilter}>
            <Row>
              <Col md={6} sm={12}>
                <h4>Sistemde kayıtlı Rezervasyon listeleniyor</h4>
              </Col>
              <Col md={4} xl={4}>
                <InputGroup className="mb-3">
                  {" "}
                  <FormControl
                    type="text"
                    value={customerNameOrCode}
                    onChange={(e) => setCustomerNameOrCode(e.target.value)}
                    placeholder="Kod veya Müşteri ismı..."
                  />
                  <Button
                    style={{ backgroundColor: "rgb(0, 123, 255)" }}
                    onClick={handleSearchFilter}
                  >
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{ fontSize: "18px", marginRight: "8px" }}
                    />
                  </Button>
                </InputGroup>
              </Col>
              <Col md={2}>
                <div ref={dropdownRef}>
                  <Dropdown show={isDropdownOpen}>
                    <Dropdown.Toggle
                      onClick={toggleDropdown}
                      style={{
                        border: "none",
                        backgroundColor: "#1cab1c",
                        fontWeight: "700",
                        borderRadius: "7px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                      }}
                      id="dropdown-basic"
                    >
                      Gelişmiş Arama{" "}
                      <FontAwesomeIcon icon={faFilter} className="ml-2" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      className="dropdown-menu-custom"
                      style={{
                        maxHeight: "400px",
                        paddingLeft: "10px",
                        paddingBottom: "20px",
                      }}
                    >
                      <Row>
                        <Col md={3} xl={3}>
                          <DropDownInputBootsrap
                            label="durum"
                            id="durum"
                            value={filter?.durum}
                            options={durumOptions}
                            onChange={handleInputChange}
                          />
                        </Col>

                        <Col md={3} xl={3}>
                          <DateInputBootsrap
                            label="Reservation Date"
                            id="reservation_date"
                            type="date"
                            value={filter?.reservation_date}
                            onChange={(e) =>
                              handleInputChange(
                                "reservation_date",
                                e.target.value
                              )
                            }
                          />
                        </Col>

                        <Col md={3} xl={3}>
                          <DateInputBootsrap
                            label="Payment Deadline"
                            id="payment_deadline"
                            type="date"
                            value={filter?.payment_deadline}
                            onChange={(e) =>
                              handleInputChange(
                                "payment_deadline",
                                e.target.value
                              )
                            }
                          />
                        </Col>

                        <Col md={2} xl={2}>
                          <PopUpReserversationTours
                            handleSaveTours={setSelectedTour}
                          />
                        </Col>
                        <Col md={1} xl={1}>
                          <Row>
                            <Col className="mb-2 mt-2">
                              {" "}
                              <Button
                                style={{
                                  backgroundColor: "#007bff",
                                  color: "white",
                                  padding: "8px 10px",
                                  borderRadius: "5px",
                                  border: "none",
                                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                                  fontWeight: "bold",
                                  cursor: "pointer",
                                  transition: "background-color 0.3s ease",
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: "10px",
                                }}
                                type="submit"
                                onMouseEnter={(e) =>
                                  (e.target.style.backgroundColor = "#004080")
                                }
                                onMouseLeave={(e) =>
                                  (e.target.style.backgroundColor = "#007bff")
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faSearch}
                                  style={{
                                    fontSize: "15px",
                                    marginRight: "2px",
                                  }}
                                />{" "}
                              </Button>
                            </Col>
                            <Col>
                              <Button
                                style={{
                                  backgroundColor: "#7c6b6b",
                                  color: "white",
                                  padding: "8px 10px",
                                  borderRadius: "5px",
                                  border: "none",
                                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                                  fontWeight: "bold",
                                  cursor: "pointer",
                                  transition: "background-color 0.3s ease",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={handleClearFilter}
                                onMouseEnter={(e) =>
                                  (e.target.style.backgroundColor = "#584242")
                                }
                                onMouseLeave={(e) =>
                                  (e.target.style.backgroundColor = "#7c6b6b")
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faSync}
                                  style={{ fontSize: "16px" }}
                                />
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      {/**
                    <SearchButtonComponent
                    handleClearFilter={handleClearFilter}
                    />
                  */}

                      {/* Dropdown items here */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </Row>
          </Form>
          <LineAdvance />

          <ReservationTable
            reservations={reservations}
            handleStatusChangeReseve={handleStatusChangeReseve}
            handleStatusChangeIptal={handleStatusChangeIptal}
            sortReservations={sortReservations}
            handleShowDetails={handleShowDetails}
            handleShowEdit={handleShowEdit}
          />
          {currentReservation && (
            <EditReservsationModal
              show={showEditModal}
              onHide={() => setShowEditModal(false)}
              reservation={currentReservation}
              // include other necessary props
            />
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* Pagination in the center */}
            <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
              <Pagination>
                {/* Button to go to the first page */}
                <Pagination.First
                  disabled={currentPage === 0}
                  onClick={() => setCurrentPage(0)}
                >
                  İlk
                </Pagination.First>

                <Pagination.Prev
                  disabled={currentPage === 0}
                  onClick={() => setCurrentPage(currentPage - 1)}
                />
                {pages}
                <Pagination.Next
                  disabled={currentPage === pages.length - 1}
                  onClick={() => setCurrentPage(currentPage + 1)}
                />
                {/* Button to go to the last page */}
                <Pagination.Last
                  disabled={currentPage === pages.length - 1}
                  onClick={() => setCurrentPage(pages.length - 1)}
                >
                  Son
                </Pagination.Last>
              </Pagination>
            </div>

            {/* Items per page selector on the right */}
            <div>
              <Form.Label className="mr-2">Sayfada :</Form.Label>
              <Form.Control
                as="select"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                  setCurrentPage(0); // Reset to the first page
                }}
                style={{ display: "inline-block", width: "auto" }}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </Form.Control>
            </div>
          </div>
        </Card.Body>
      </Card>
      {currentReservation && (
        <ReservationDetailViewer
          reservation={currentReservation}
          show={showDetails}
          handleClose={handleHideDetails}
        />
      )}
      {currentCode && (
        <ReservationCancellation
          code={currentCode}
          show={showConfirmModal}
          handleClose={handleCancel}
          setReservations={setReservations}
          reservations={reservations}
        />
      )}
      {currentCode && (
        <ReservationResev
          reservations={reservations}
          code={currentCode}
          show={showConfirmResevModal}
          handleClose={handleCancel}
          setReservations={setReservations}
        />
      )}
      <CreateReservationModal
        updateReservations={updateReservations}
        setModalShowCreate={setModalShowCreate}
        modalShowCreate={modalShowCreate}
      />
      <EditReservsationModal
        currentReservation={currentReservation}
        currentCode={currentCode}
        setModalShowEdit={setModalShowEdit}
        modalShowEdit={modalShowEdit}
        updateReservations={updateReservations}
      />
      <ToastContainer />
    </div>
  );
}
export default ReservationManagementList;
