import React from "react";
import { axios } from "common/utils/axios";

export default function OtelDeleteModal({ setOpenModal, otel, getOtels }) {
  const handleSubmit = async () => {
    const res = axios.post("api/otel/destroy", { otel_id: otel.otel_id });
    if (res) {
      getOtels();
      setOpenModal([]);
    }
  };

  return (
    <div>
      <div>
        <div className="fixed z-10 w-screen h-full overlay-0 top-0 bottom-0 right-0 left-0 bg-black/[0.9]">
          <div className="sticky top-0 h-screen w-2/5 mx-auto grid">
            <div className=" sticky bg-white m-auto  p-5 bg-gray-50">
              <div>
                <div className="grid grid-cols-3">
                  <div>
                    <img src={otel?.cover_picture} alt="" />
                  </div>
                  <div className="col-span-2 flex flex-col p-3 ">
                    <div className="grow align-top">
                      Oteli silmek istediğinizden emin misiniz?
                    </div>
                    <div className="mt-2 flex gap-3 ml-auto">
                      <button
                        className="bg-red-300 text-white px-2"
                        onClick={() => setOpenModal([])}
                      >
                        Cancel
                      </button>
                      <button
                        className="bg-blue-300 text-white px-2"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Onayla
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
