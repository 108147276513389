import React, { useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { setCookie } from "common/helpers/setCookie";
function GoogleLoginButton() {
  const navigate = useNavigate();

  useEffect(() => {
    // Define the function to handle the Google sign-in response
    window.handleCredentialResponse = (response) => {
      try {
        const obj = jwt_decode(response.credential);

        // Send a request to your backend
        fetch("https://backend.suarelab.com/api/google-login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: obj.email,
            firstName: obj.given_name,
            lastName: obj.family_name,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            setCookie("user", JSON.stringify(data.user), 7);
            setCookie("role", data.role.name, 7);
            setCookie("token", data.role.name, 7);
            setCookie("CustomerId", data.CustomerId, 7);
            navigate("/user");
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    };
    // Load the Google sign-in button
    window.google.accounts.id.initialize({
      client_id:
        "3246594183-cnrr1p3rkr3vlj859k5b04gslog825oe.apps.googleusercontent.com",
      callback: window.handleCredentialResponse,
    });
    window.google.accounts.id.renderButton(
      document.getElementById("googleSignInButton"),
      { theme: "outline", size: "large" } // Customise the appearance
    );
  }, [navigate]);

  return (
    <div>
      <div id="googleSignInButton"></div>{" "}
      {/* This div will contain the Google sign-in button */}
    </div>
  );
}

export default GoogleLoginButton;
