import { useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
const TripsDetails = [
  {
    Title: "This is the first Trip",
    Details:
      "em Ipsum is simpuse it?  It is a re, conteesktop publishing packages and web page editors now use Lore willny web sites still in t and the like). ",
    Hours: "2:30 Pm",
    Days: "2",
  },
  {
    Title: "This is the first Trip",
    Details:
      "em Ipsum is simply den book. It has survo elec uncharth the release of Letraset sheets containin and the like). ",
    Hours: "2:30 Pm",
    Days: "2",
  },
  {
    Title: "This is the first Trip",
    Details:
      "em Ipsum is simply den book. It has survo elec uncharth the release of Letraset sheets containin and the like). ",
    Hours: "2:30 Pm",
    Days: "2",
  },
  {
    Title: "This is the first Trip",
    Details:
      "em Ipsum is simply den book. It has survo elec uncharth the release of Letraset sheets containin and the like). ",
    Hours: "2:30 Pm",
    Days: "2",
  },
  {
    Title: "This is the first Trip",
    Details:
      "em Ipsum is simply den book. It has survo elec uncharth the release of Letraset sheets containin and the like). ",
    Hours: "2:30 Pm",
    Days: "2",
  },
];
console.log(TripsDetails, "TripsDetails");
export const Trips = ({ tourDetails }) => {
  return (
    <Row>
      {tourDetails?.trips.map((TripsDetail) => (
        <Col xs={12} md={6} xl={3} xxl={3} className="mb-3">
          <Card className="monsterratFont">
            <Card.Header className="d-flex justify-content-between">
              <span>{TripsDetail.tripTitle}</span>
              <span>{TripsDetail.howManyDays} days</span>
            </Card.Header>
            <Card.Body>{TripsDetail.tripDescription}</Card.Body>
            <Card.Footer>{TripsDetail.timing}</Card.Footer>
          </Card>
        </Col>
      ))}
    </Row>
  );
};
export default Trips;
