import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Comment from "pages/NewTour/Components/HotelComments";
import HotelAdress from "pages/NewTour/Components/HotelAdress";
import HotelImages from "pages/NewTour/Components/HotelImages";
import {
  faStar,
  faLocationDot,
  faBed,
  faUtensils,
  faWifi,
  faThermometerHalf,
  faMicrochip,
  faFirstAid,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";

import "assets/styles/TourHotelDetails.css";
export default ({ tourDetails, accomodation }) => {
  console.log(accomodation, "accomodation");
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };
  const firstAccommodation = accomodation?.[0];
  const startDate = accomodation?.startDate
    ? formatDate(accomodation.startDate)
    : "";
  const endDate = accomodation?.endDate ? formatDate(accomodation.endDate) : "";

  const calculateDays = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const timeDiff = endDate.getTime() - startDate.getTime();
    return Math.ceil(timeDiff / (1000 * 3600 * 24));
  };

  const numberOfDays =
    startDate && endDate ? calculateDays(startDate, endDate) : 0;

  const HotelDetails = accomodation?.hotel?.details;
  const HotelAddess = accomodation?.hotel?.address;
  const HotelPhotographs = accomodation?.hotel?.photographs;
  return (
    <div className="monsterratFont">
      <Row>
        <Col xl={6}>
          <HotelImages HotelPhotographs={HotelPhotographs} />
        </Col>
        <Col xl={6}>
          <div className="hotel-details">
            <div className="d-flex align-items-center">
              <h3 className="mb-0 me-custom">Otel Detayları</h3>
              <span className="me-2">{startDate}</span>
              <FontAwesomeIcon icon={faArrowRight} className="me-2" />
              <span className="me-4">{endDate}</span>
              <span className="me-2">{numberOfDays} Günler </span>
            </div>
            <Row>
              <Col xl={4} xs={5} className="detail-item f12">
                <FontAwesomeIcon icon={faStar} /> <span>Puan: 4.5/5</span>
              </Col>

              <Col xl={4} xs={7} className="detail-item f12">
                <FontAwesomeIcon icon={faLocationDot} />{" "}
                <span>Konum: {HotelAddess?.district}</span>
              </Col>

              <Col xl={4} xs={6} className="detail-item f12">
                <FontAwesomeIcon icon={faBed} /> <span>Deluxe, Suite</span>
              </Col>

              {HotelDetails?.food === 1 && (
                <Col xl={4} xs={6} className="detail-item f12">
                  <FontAwesomeIcon icon={faUtensils} />{" "}
                  <span>Yemek: Dahil</span>
                </Col>
              )}

              {HotelDetails?.wifi === 1 && (
                <Col xl={4} xs={4} className="detail-item f12">
                  <FontAwesomeIcon icon={faWifi} /> <span>Ücretsiz WiFi</span>
                </Col>
              )}

              {HotelDetails?.heating === 1 && (
                <Col xl={4} xs={8} className="detail-item f12">
                  <FontAwesomeIcon icon={faThermometerHalf} />{" "}
                  <span>Klima</span>
                </Col>
              )}

              {HotelDetails?.microwave === 1 && (
                <Col xl={4} xs={6} className="detail-item f12">
                  <FontAwesomeIcon icon={faMicrochip} /> <span>Microwave</span>
                </Col>
              )}

              {HotelDetails?.kitchen === 1 && (
                <Col xl={4} xs={6} className="detail-item f12">
                  <FontAwesomeIcon icon={faUtensils} /> <span>Mutfak</span>
                </Col>
              )}

              {HotelDetails?.first_aid_kit === 1 && (
                <Col xl={4} xs={6} className="detail-item f12">
                  <FontAwesomeIcon icon={faFirstAid} />{" "}
                  <span>İlk Yardım Kiti</span>
                </Col>
              )}
            </Row>
            <Row className="mt-2">
              <Col xl={6}>
                <Comment />
              </Col>
              <Col>
                <HotelAdress HotelAddress={accomodation?.hotel?.address} />
              </Col>
            </Row>
            {/* Add more details as needed */}
          </div>
        </Col>
      </Row>
    </div>
  );
};
