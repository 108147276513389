import { Card, Col, Row, Modal, Button, Form } from "react-bootstrap";
import ReactSelect from "react-select";
export const ToursFilterMobile = ({
  showModal,
  handleCloseModal,
  selectedButtons,
  handleButtonClick,
  getSelectedCurrency,
  setMinPrice,
  setMaxPrice,
  setNerden,
  setNereye,
  setSelectedTime,
  setWithAccommodation,
  setWithVisa,
  withVisa,
  withAccommodation,
  ToggleButton,
  minPrice,
  maxPrice,
  nerden,
  nereye,
  timeOptions,
  selectedTime,
}) => {
  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Filter Options</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Col md={2}>
          <Card className="mb-2 pl-3 pt-2">
            <h6>Fiyat</h6>
            <Row className="d-flex justify-content-start align-items-center mb-2 pl-3 ">
              {Object.keys(selectedButtons).map((name) => (
                <ToggleButton
                  key={name}
                  name={name}
                  isSelected={selectedButtons[name]}
                  onClick={handleButtonClick}
                />
              ))}
            </Row>
            <div className="form-group-container mb-2">
              <Form.Group>
                <Form.Control
                  min={0}
                  type="number"
                  className="small-input"
                  placeholder={`Min ${getSelectedCurrency()}`}
                  value={minPrice}
                  onChange={(e) => setMinPrice(e.target.value)}
                />
              </Form.Group>
              <span className="dash-between">-</span>
              <Form.Group>
                <Form.Control
                  min={minPrice}
                  type="number"
                  className="small-input"
                  placeholder={`Max ${getSelectedCurrency()}`}
                  value={maxPrice}
                  onChange={(e) => setMaxPrice(e.target.value)}
                />
              </Form.Group>
            </div>
          </Card>
          <Card className="mb-2 pl-3 pt-2">
            <h6>Address</h6>
            <div className="select-container mb-2">
              <div className="select-small">
                <ReactSelect
                  value={nerden}
                  onChange={(selectedOption) => setNerden(selectedOption)}
                  options={[
                    { value: "option1", label: "Option 1" },
                    { value: "option2", label: "Option 2" },
                  ]}
                  isSearchable
                  placeholder="Nerden"
                />
              </div>
              <div className="select-small">
                <ReactSelect
                  value={nereye}
                  onChange={(selectedOption) => setNereye(selectedOption)}
                  options={[
                    { value: "option1", label: "Option 1" },
                    { value: "option2", label: "Option 2" },
                  ]}
                  isSearchable
                  placeholder="Nereye"
                />
              </div>
            </div>
          </Card>
          <Card className="mb-2 pl-3 pt-2">
            <Row>
              <Col>
                <h6 style={{ paddingTop: "5px" }}>ilan Tarihi</h6>
              </Col>
              <Col>
                {" "}
                <button
                  className="clear-button"
                  onClick={() => setSelectedTime(null)}
                >
                  Clear
                </button>
              </Col>
            </Row>
            <div className="time-options">
              {timeOptions.map((option) => (
                <label key={option.value} className="time-option">
                  <input
                    type="radio"
                    name="time"
                    value={option.value}
                    checked={selectedTime === option.value}
                    onChange={() => setSelectedTime(option.value)}
                  />
                  {option.label}
                </label>
              ))}
            </div>
          </Card>

          <Card className="mb-2 pl-3 pt-2">
            <h6>Otel Odalar</h6>
          </Card>
          <Card className="mb-2 pl-3 pt-2">
            <h6>With Accommodation</h6>
            <div className="accommodation-options">
              <label className="accommodation-option">
                <input
                  type="radio"
                  name="accommodation"
                  value="false"
                  checked={withAccommodation === "false"}
                  onChange={() => setWithAccommodation("false")}
                />
                Hayır
              </label>
              <label className="accommodation-option">
                <input
                  type="radio"
                  name="accommodation"
                  value="true"
                  checked={withAccommodation === "true"}
                  onChange={() => setWithAccommodation("true")}
                />
                Evet
              </label>
            </div>
          </Card>
          <Card className="mb-2 pl-3 pt-2">
            <h6>with visa</h6>
            <div className="accommodation-options">
              <label className="accommodation-option">
                <input
                  type="radio"
                  name="visa"
                  value="false"
                  checked={withVisa === "false"}
                  onChange={() => setWithVisa("false")}
                />
                Hayır
              </label>
              <label className="accommodation-option">
                <input
                  type="radio"
                  name="visa"
                  value="true"
                  checked={withVisa === "true"}
                  onChange={() => setWithVisa("true")}
                />
                Evet
              </label>
            </div>
          </Card>

          <Card className="mb-2 pl-3 pt-2">
            <h6>Kapasite</h6>
          </Card>
        </Col>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
        <Button variant="primary" onClick={handleCloseModal}>
          Apply Filters
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ToursFilterMobile;
