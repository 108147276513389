import React from "react";
import { Modal, Button } from "react-bootstrap";
export const ConformFinishModal = ({
  showConfirmationModal,
  setShowConfirmationModal,
  handleConfirmFinish,
}) => {
  return (
    <Modal
      show={showConfirmationModal}
      onHide={() => setShowConfirmationModal(false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm Finish</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to finish this conversation?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          active={true}
          variant="secondary"
          onClick={() => setShowConfirmationModal(false)}
        >
          Cancel
        </Button>
        <Button active={true} variant="danger" onClick={handleConfirmFinish}>
          Finish
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ConformFinishModal;
