import React, { useState, useEffect, useRef } from "react";
import "assets/styles/CustomeDropZone.css";
import { Button } from "react-bootstrap";

const CustomeDropZone = ({
  updateMainPhotograph,
  updateInsidePhotographs,
  updateOutsidePhotographs,
  initialImages = [],
}) => {
  const [images, setImages] = useState([]);
  const fileInputRef = useRef(null);

  // Convert file to Base64
  const convertToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });

  const handleFiles = async (files, category = "inside") => {
    const newImages = await Promise.all(
      Array.from(files).map(async (file, index) => ({
        base64: await convertToBase64(file),
        category,
        isMain: images.length === 0 && index === 0, // Mark first image as main if no images are currently set
        size: file.size,
      }))
    );

    setImages((prevImages) => [...prevImages, ...newImages]);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleFiles(e.dataTransfer.files);
  };

  const handleImageChange = (event) => {
    handleFiles(event.target.files);
  };

  const handleSelectMainImage = (index) => {
    setImages(images.map((img, idx) => ({ ...img, isMain: idx === index })));
  };

  const handleRemoveImage = (index) => {
    setImages(images.filter((_, idx) => idx !== index));
  };

  const handleCategoryChange = (index, category) => {
    setImages(
      images.map((img, idx) => (idx === index ? { ...img, category } : img))
    );
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  // Sync state with parent component
  useEffect(() => {
    const mainImage = images.find((img) => img.isMain)?.base64 || null;
    const insideImages = images
      .filter((img) => img.category === "inside" && !img.isMain)
      .map((img) => img.base64);
    const outsideImages = images
      .filter((img) => img.category === "outside")
      .map((img) => img.base64);

    updateMainPhotograph(mainImage);
    updateInsidePhotographs(insideImages);
    updateOutsidePhotographs(outsideImages);
  }, [
    images,
    updateMainPhotograph,
    updateInsidePhotographs,
    updateOutsidePhotographs,
  ]);

  return (
    <div>
      <div
        className="custom-dropzone"
        onClick={() => fileInputRef.current.click()}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        Resimleri yüklemek için buraya tıklayın veya sürükleyin
      </div>
      <input
        ref={fileInputRef}
        className="file-input"
        type="file"
        multiple
        onChange={handleImageChange}
      />
      <div className="image-gallery">
        {images.map((image, index) => (
          <div key={index} className="image-thumbnail">
            <div className="image-tooltip">
              {`${(image.size / 1024).toFixed(2)} KB`}{" "}
            </div>
            <img src={image.base64} alt={`Image ${index}`} />
            <div className="image-controls">
              <button
                style={{ fontSize: "15px" }}
                onClick={(e) => handleSelectMainImage(index, e)}
              >
                {image.isMain ? "Ana Resim" : "Ana olarak Ayarla"}
              </button>
              <select
                value={image.category}
                onChange={(e) => handleCategoryChange(index, e.target.value)}
              >
                <option value="inside">İç</option>
                <option value="outside">Dış</option>
              </select>
            </div>
            <button
              className="remove-button"
              onClick={(e) => handleRemoveImage(index, e)}
            >
              Kaldır
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomeDropZone;
