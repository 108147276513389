import React, { Suspense, useEffect, useState } from "react";
import AnimationRevealPage from "../../common/helpers/AnimationRevealPage.js";
import { Row, Col } from "react-bootstrap";
import Travel from "assets/icons/Travel.png";
import { useQuery } from "react-query";
import { fetchContactDetails } from "common/Api/SiteSettingsApi";
import { useContext } from "react";
import HeaderContext from "redux/ContextApi/HeaderContext .jsx";
import { HeaderHeightContext } from "layouts/GlobalLayout";

import BubbleContactUs from "common/components/BubbleContactUs/BubbleContactUs.jsx";
import BootstrapV1Footer from "common/components/footers/BootstrapV1Footer";
import HomePageFooterBootstrapV1 from "common/components/footers/HomePageFooterBootstrapV1";

const Hero2 = React.lazy(() =>
  import("../../common/components/hero/SliderImageHero")
);
const Faq2 = React.lazy(() =>
  import("common/components/faqs/TwoColumnPrimaryBackground.js")
);
const Features = React.lazy(() =>
  import("../../common/components/features/ThreeColSimple.js")
);
const SliderCard = React.lazy(() =>
  import("../../common/components/cards/ThreeColSlider.js")
);
const Comments = React.lazy(() =>
  import("pages/HomePage/Components/Comments.jsx")
);
const Footer = React.lazy(() =>
  import("../../common/components/footers/HomePageFooter.js")
);
const FAQ = React.lazy(() =>
  import("../../common/components/faqs/SimpleWithSideImage.js")
);
const Blog = React.lazy(() =>
  import("../../common/components/blogs/PopularAndRecentBlogPosts.js")
);
const HotelTravelLandingPage = () => {
  const [allImages, setAllImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { setTitle, setImageUrl } = useContext(HeaderContext);
  const headerHeightContext = useContext(HeaderHeightContext);
  const {
    setTabTry,
    setHeaderHeight,
    setHeaderTitlePaddingTop,
    setHeaderTitlePaddingBottom,
  } = headerHeightContext;
  useEffect(() => {
    setTabTry(true);

    // Clean up function to reset the title and image URL when the component unmounts
    return () => {
      setTabTry(false);
      setImageUrl("");
    };
  }, [setTitle]);

  useEffect(() => {
    if (allImages.length > 0 && currentImageIndex < allImages.length) {
      const newImageUrl = allImages[currentImageIndex]?.image_url;
      setImageUrl(newImageUrl); // Dynamically update the context with the new image URL
    }
  }, [currentImageIndex, allImages, setImageUrl]);

  useEffect(() => {
    setHeaderHeight("560px"); // Set the header height to 200px
    setHeaderTitlePaddingTop("0px");
    setHeaderTitlePaddingBottom("0px");
    return () => {
      setHeaderHeight(null);
      setHeaderTitlePaddingBottom(null);
      setHeaderTitlePaddingTop(null); // Reset the header height when component unmounts
    };
  }, [setHeaderHeight]);
  // Correctly placing useQuery hook inside the functional component
  const {
    data: contactData,
    isLoading,
    error,
  } = useQuery("contactDetails", fetchContactDetails, {
    staleTime: 3 * 24 * 60 * 60 * 1000, // 3 days in milliseconds
    refetchOnWindowFocus: true, // Refetch when window gains focus
    onSuccess: (data) => {
      console.log("Data fetched successfully:", data);
    },
  });

  // You can use isLoading and error to handle loading states and errors
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {/* Render your components here 7
      <Hero2 />
    */}
      <SliderCard />
      <Comments />
      <Blog />
      <Faq2 />
      <BubbleContactUs contactData={contactData} />
      {/**
      <HomePageFooterBootstrapV1 />
    */}
    </Suspense>
  );
};

export default HotelTravelLandingPage;
