import React, { useState, useEffect, useCallback } from "react";
import { useQuery } from "react-query";
import { useMutation, useQueryClient } from "react-query";
import { ToastContainer, toast } from "react-toastify";
import { Row, Col, Card, Form } from "react-bootstrap";
import { createTour, fetchFilterTours } from "common/Api/AdvertismentApi";
import AdvertismentList from "area/admin/AdvertisementManegment/AdvertismentList";
import HacUmraCreateModal from "./Components/Create/CreateTourModal";
import AdvanceSearchAdvertisment from "./Components/AdvanceSearchAdvertisment";
import SearchButtonComponent from "../CRUD/SearchButtonComponent";
import CreatButton from "area/admin/CRUD/CreatButton";
import { fileToBase64 } from "common/helpers/ConvertFileToBase64";
import "assets/styles/AdvertismentManger.css";

export const Advertisment = () => {
  const [filters, setFilters] = useState({ page: 1, perPage: 5 });
  const [currentPage, setCurrentPage] = useState(1);
  const queryClient = useQueryClient();
  const [modalShowCreate2, setModalShowCreate2] = useState(false);
  const [activeSection, setActiveSection] = useState(1);

  const [formData, setFormData] = useState({
    tourName: "",
    days: "",
    nights: "",
    startingDate: "",
    endingDate: "",
    departureCity: "",
    returnCity: "",
    publicationDate: "",
    publicationEndDate: "",
    capacity: "",
    season: "",
    childAge: "",
    childs: "",
    category: "",
    companyId: "",
    intimateControl: false,
    withAccommodation: false,
    showOnWebsite: false,
    lock: false,
    visa: false,

    food_options: [{ foodOptionName: "", foodOpationtype: "" }],

    pricing: [],

    trips: [
      {
        tripTitle: "",
        tripDescription: "",
        howManyDays: "",
        timing: "",
        packagePricing: "",
      },
    ],

    transportations: [
      {
        company: "",
        vehicle_transport: "",
        fromLocation: "",
        toLocation: "",
        departureDateTime: "",
        arrivalDateTime: "",
        expeditionNumber: "",
      },
    ],
    accomodations: [
      {
        city: "",
        otel_id: "",
        startDate: "",
        endDate: "",
        nights: "",
      },
    ],
    mainPhotograph: null,
    insidePhotographs: [],
    outsidePhotographs: [],
  });

  const sectionTitles = [
    "Tur",
    "Konaklama",
    "Yemekler",
    "Ulasim",
    "Gezi",
    "Fiyatlar",
    "Galeri",
  ];

  const updateAccomodationData = (accomodationData) => {
    setFormData({ ...formData, accomodations: accomodationData });
  };
  const updateFoodOptionData = (food_optionData) => {
    setFormData({ ...formData, food_options: food_optionData });
  };
  const updateTransportData = (transportData) => {
    setFormData({ ...formData, transportations: transportData });
  };
  const updateTripData = (tripData) => {
    setFormData({ ...formData, trips: tripData });
  };
  const updateTourSectionData = (updatedData) => {
    setFormData({ ...formData, ...updatedData });
  };
  const updateTourPhotosData = (newFile) => {
    setFormData((prevFormData) => ({
      ...formData,
      images: [...formData.images, newFile],
    }));
  };
  const updateMainPhotograph = useCallback(
    (mainImage) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        mainPhotograph: mainImage,
      }));
    },
    [setFormData]
  );

  const updateInsidePhotographs = useCallback(
    (insideImages) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        insidePhotographs: insideImages,
      }));
    },
    [setFormData]
  );
  const updateOutsidePhotographs = useCallback(
    (outsideImages) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        outsidePhotographs: outsideImages,
      }));
    },
    [setFormData]
  );
  const handleImagesUpdated = (data) => {
    // Process the data received from CustomeDropZone
    // Update the formData state accordingly
    console.log(data); // For debugging
    // Update your formData here based on the data received
    // Example: updateFormData({ ...formData, mainPhotograph: data.mainImage, insidePhotographs: data.insideImages, outsidePhotographs: data.outsideImages });
  };
  console.log(formData, "formData CReat");
  const updatePriceData = (index, updatedPrice) => {
    const newPricing = formData.pricing.map((price, idx) => {
      if (idx === index) {
        return updatedPrice;
      }
      return price;
    });
    setFormData({ ...formData, pricing: newPricing });
  };

  const updateDiscountPriceData = (discountPrices) => {
    setFormData({
      ...formData,
      pricing: {
        ...formData.pricing,
        discount_prices: discountPrices,
      },
    });
  };
  const updateInstallmentsData = (priceIndex, updatedInstallments) => {
    const newPricing = formData.pricing.map((price, idx) => {
      if (idx === priceIndex) {
        return { ...price, installments: updatedInstallments };
      }
      return price;
    });
    setFormData({ ...formData, pricing: newPricing });
  };

  // Creat Tour
  // to open Creat Modal
  const handleClickCreatButton = () => {
    setModalShowCreate2(true);
  };
  const mutation = useMutation((newTourData) => createTour(newTourData), {
    onSuccess: () => {
      queryClient.invalidateQueries(["firstFiveTours", currentPage]);
      toast.success("Tur başarıyla eklendi.!");
    },
  });

  const handleSubmitFinal = async () => {
    // Prepare the form data with images already in base64 format
    const updatedFormData = {
      ...formData,
      mainPhotograph: formData.mainPhotograph,
      insidePhotographs: formData.insidePhotographs,
      outsidePhotographs: formData.outsidePhotographs,
    };

    try {
      // Submit the form data
      mutation.mutate(updatedFormData, {
        onSuccess: () => {
          toast.success("Tur başarıyla eklendi!");
          // Reset the form data or perform other success actions
          setModalShowCreate2(false);
          setActiveSection(1);
          setFormData({
            // Reset to initial form state if necessary
            tourName: "",
            days: "",
            nights: "",
            startingDate: "",
            endingDate: "",
            departureCity: "",
            returnCity: "",
            publicationDate: "",
            publicationEndDate: "",
            capacity: "",
            season: "",
            childAge: "",
            childs: "",
            category: "",
            companyId: "",
            intimateControl: false,
            withAccommodation: false,
            showOnWebsite: false,
            lock: false,
            visa: false,
            food_options: [{ foodOptionName: "", foodOpationtype: "" }],
            pricing: [],
            trips: [],
            transportations: [],
            accomodations: [],
            mainPhotograph: null,
            insidePhotographs: [],
            outsidePhotographs: [],
          });
        },
        onError: (error) => {
          // Handle submission error
          const errorMsg =
            error?.response?.data?.message || "Tur oluşturma hatası..";
          toast.error(errorMsg);
        },
      });
    } catch (error) {
      console.error("Turun gönderimi sırasında bir hata oluştu.", error);
      // Handle or display the error
      toast.error("Gönderim işlemi sırasında bir hata oluştu.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Filter and show data with pagenation
  const {
    data: tours,
    isLoading,
    refetch,
  } = useQuery(
    ["toursFilter", JSON.stringify(filters)],
    () => fetchFilterTours(filters),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: checked,
    }));
  };
  const applyFilters = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page: 1,
      perPage: prevFilters.perPage,
    }));
    refetch();
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setFilters({ ...filters, page: pageNumber });
  };
  const handleNextSection = (sectionData) => {
    setFormData((prevFormData) => ({ ...prevFormData, ...sectionData }));
    setActiveSection((prev) => Math.min(prev + 1, 7));
  };

  const handlePreviousSection = () => {
    setActiveSection((prev) => Math.max(prev - 1, 1));
  };
  console.log(formData, "formData");
  return (
    <div>
      <Card style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)" }}>
        <Card.Header
          style={{
            backgroundColor: "#636665",
            color: "white",
            borderRadius: "5px 5px 0 0",
          }}
        >
          <Row>
            <Col>
              <h1 style={{ padding: "10px", fontSize: "24px", margin: 0 }}>
                Tur Yönetimi
              </h1>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Row>
                <Col>
                  <CreatButton
                    title={"Tur Ekle "}
                    onClick={handleClickCreatButton}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Header>

        <Card.Body>
          <Row className="mb-4 mt-2">
            <Col md={5} sm={12} style={{ paddingTop: "10px" }}>
              <h4>Sistemde Kayıtlı Turlar Listeleniyor</h4>
            </Col>
            <Col md={3} sm={6} xs={6} style={{ paddingTop: "10px" }}>
              <Form.Control
                type="text"
                placeholder="Tur Ara"
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    tourName: e.target.value,
                  })
                }
              />
            </Col>
            <Col md={2} sm={6} xs={6} style={{ paddingTop: "10px" }}>
              <SearchButtonComponent />
            </Col>
            <Col md={2} style={{ paddingTop: "10px" }}>
              <AdvanceSearchAdvertisment
                setFilters={setFilters}
                handleInputChange={handleInputChange}
                applyFilters={applyFilters}
                handleCheckboxChange={handleCheckboxChange}
              />
            </Col>
          </Row>

          <AdvertismentList
            updateMainPhotograph={updateMainPhotograph}
            updateInsidePhotographs={updateInsidePhotographs}
            updateOutsidePhotographs={updateOutsidePhotographs}
            data={tours}
            isLoading={isLoading}
            setFormData={setFormData}
            setModalShowCreate2={setModalShowCreate2}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setActiveSection={setActiveSection}
            activeSection={activeSection}
            handleNextSection={handleNextSection}
            handlePreviousSection={handlePreviousSection}
            sectionTitles={sectionTitles}
            handlePageChange={handlePageChange}
          />
        </Card.Body>
      </Card>

      <HacUmraCreateModal
        updateMainPhotograph={updateMainPhotograph}
        updateInsidePhotographs={updateInsidePhotographs}
        updateOutsidePhotographs={updateOutsidePhotographs}
        formData={formData}
        setFormData={setFormData}
        setActiveSection={setActiveSection}
        activeSection={activeSection}
        setModalShowCreate2={setModalShowCreate2}
        modalShowCreate2={modalShowCreate2}
        sectionTitles={sectionTitles}
        handleSubmitFinal={handleSubmitFinal}
        handleNextSection={handleNextSection}
        handlePreviousSection={handlePreviousSection}
        handleChange={handleChange}
        currentPage={currentPage}
        updateAccomodationData={updateAccomodationData}
        updateFoodOptionData={updateFoodOptionData}
        updateTransportData={updateTransportData}
        updateTripData={updateTripData}
        updateTourSectionData={updateTourSectionData}
        updatePriceData={updatePriceData}
        updateDiscountPriceData={updateDiscountPriceData}
        updateInstallmentsData={updateInstallmentsData}
        updateTourPhotosData={updateTourPhotosData}
      />

      <ToastContainer />
    </div>
  );
};
export default Advertisment;
