import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { axios } from "common/utils/axios";

export const PriceCurrent = ({ setPrices, prices, onChange }) => {
  return (
    <div>
      <Box sx={{ minWidth: 120, backgroundColor: "white", width: "80px" }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Currency</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Currency"
            onChange={onChange}
          >
            {prices.map((price) => (
              <MenuItem key={price.id} value={price.id}>
                {" "}
                {price.abbreviation}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
};

export default PriceCurrent;
