import { Modal, Button } from "react-bootstrap";
export default ({
  editMode,
  setEditMode,
  handleEditChange,
  editTourData,
  handleEditSubmit,
}) => {
  return (
    <div>
      <Modal show={editMode} onHide={() => setEditMode(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Tour</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleEditSubmit}>
            {/* Construct form fields using editTourData to set initial values. For example: */}
            <h2>General Information</h2>
            <div className="form-group">
              <label>Tour Name</label>
              <input
                className="form-control"
                type="text"
                name="tourName"
                value={editTourData.tourName || ""}
                onChange={handleEditChange}
              />
            </div>

            {/* Dealer */}
            <div className="form-group">
              <label>Dealer</label>
              <input
                type="text"
                className="form-control"
                name="dealer"
                value={editTourData.dealer || ""}
                onChange={handleEditChange}
              />
            </div>

            {/* Days */}
            <div className="form-group">
              <label>Days</label>
              <input
                type="number"
                className="form-control"
                name="days"
                value={editTourData.days || ""}
                onChange={handleEditChange}
              />
            </div>

            {/* Nights */}
            <div className="form-group">
              <label>Nights</label>
              <input
                type="number"
                className="form-control"
                name="nights"
                value={editTourData.nights || ""}
                onChange={handleEditChange}
              />
            </div>

            {/* Starting Date */}
            <div className="form-group">
              <label>Starting Date</label>
              <input
                type="date"
                className="form-control"
                name="startingDate"
                value={editTourData.startingDate || ""}
                onChange={handleEditChange}
              />
            </div>

            {/* Ending Date */}
            <div className="form-group">
              <label>Ending Date</label>
              <input
                type="date"
                className="form-control"
                name="endingDate"
                value={editTourData.endingDate || ""}
                onChange={handleEditChange}
              />
            </div>

            {/* Departure City */}
            <div className="form-group">
              <label>Departure City</label>
              <input
                type="text"
                className="form-control"
                name="departureCity"
                value={editTourData.departureCity || ""}
                onChange={handleEditChange}
              />
            </div>

            {/* Return City */}
            <div className="form-group">
              <label>Return City</label>
              <input
                type="text"
                className="form-control"
                name="returnCity"
                value={editTourData.returnCity || ""}
                onChange={handleEditChange}
              />
            </div>

            {/* Publication Date */}
            <div className="form-group">
              <label>Publication Date</label>
              <input
                type="date"
                className="form-control"
                name="publicationDate"
                value={editTourData.publicationDate || ""}
                onChange={handleEditChange}
              />
            </div>

            {/* Publication EndDate */}
            <div className="form-group">
              <label>Publication EndDate</label>
              <input
                type="date"
                className="form-control"
                name="publicationEndDate"
                value={editTourData.publicationEndDate || ""}
                onChange={handleEditChange}
              />
            </div>

            {/* Capacity */}
            <div className="form-group">
              <label>Capacity</label>
              <input
                type="number"
                className="form-control"
                name="capacity"
                value={editTourData.capacity || ""}
                onChange={handleEditChange}
              />
            </div>

            {/* Season */}
            <div className="form-group">
              <label>Season</label>
              <input
                type="text"
                className="form-control"
                name="season"
                value={editTourData.season || ""}
                onChange={handleEditChange}
              />
            </div>

            {/* Child Age */}
            <div className="form-group">
              <label>Child Age</label>
              <input
                type="number"
                className="form-control"
                name="childAge"
                value={editTourData.childAge || ""}
                onChange={handleEditChange}
              />
            </div>

            {/* Childs */}
            <div className="form-group">
              <label>Childs</label>
              <input
                type="number"
                className="form-control"
                name="childs"
                value={editTourData.childs || ""}
                onChange={handleEditChange}
              />
            </div>
            <hr />
            <h2>Companies</h2>
            {editTourData?.companies?.map((company, index) => (
              <div className="form-group">
                <label>Company Name</label>
                <input
                  className="form-control"
                  type="text"
                  name="companyName"
                  value={company.companyName || ""}
                  onChange={(e) => handleEditChange(e, index, "companies")}
                />
              </div>
            ))}
            <hr />
            <h2>Food</h2>
            {editTourData?.food_options?.map((food_option, index) => (
              <div className="form-group">
                <label>Food</label>
                <input
                  className="form-control"
                  type="text"
                  name="foodOptionName"
                  value={food_option.foodOptionName || ""}
                  onChange={(e) => handleEditChange(e, index, "food_options")}
                />
              </div>
            ))}
            <hr />

            <h2>Transportaion</h2>
            {editTourData?.transportations?.map((transportation, index) => (
              <div>
                <div className="form-group">
                  <label>Company</label>
                  <input
                    className="form-control"
                    type="text"
                    name="company"
                    value={transportation.company || ""}
                    onChange={(e) =>
                      handleEditChange(e, index, "transportations")
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Departure DateTime</label>
                  <input
                    className="form-control"
                    type="datetime-local"
                    name="departureDateTime"
                    value={transportation.departureDateTime || ""}
                    onChange={(e) =>
                      handleEditChange(e, index, "transportations")
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Arrival DateTime</label>
                  <input
                    className="form-control"
                    type="datetime-local"
                    name="arrivalDateTime"
                    value={transportation.arrivalDateTime || ""}
                    onChange={(e) =>
                      handleEditChange(e, index, "transportations")
                    }
                  />
                </div>
                <div className="form-group">
                  <label>From Location</label>
                  <input
                    className="form-control"
                    type="text"
                    name="fromLocation"
                    value={transportation.fromLocation || ""}
                    onChange={(e) =>
                      handleEditChange(e, index, "transportations")
                    }
                  />
                </div>
                <div className="form-group">
                  <label>To Location</label>
                  <input
                    className="form-control"
                    type="text"
                    name="toLocation"
                    value={transportation.toLocation || ""}
                    onChange={(e) =>
                      handleEditChange(e, index, "transportations")
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Expedition Number</label>
                  <input
                    className="form-control"
                    type="text"
                    name="expeditionNumber"
                    value={transportation.expeditionNumber || ""}
                    onChange={(e) =>
                      handleEditChange(e, index, "transportations")
                    }
                  />
                </div>
              </div>
            ))}
            <hr />
            <h2>Trip</h2>
            {editTourData?.trips?.map((trip, index) => (
              <div>
                <div className="form-group">
                  <label>Trip</label>
                  <input
                    className="form-control"
                    type="text"
                    name="tripTitle"
                    value={trip.tripTitle || ""}
                    onChange={(e) => handleEditChange(e, index, "trips")}
                  />
                </div>
                <div className="form-group">
                  <label>Trip Description</label>
                  <input
                    className="form-control"
                    type="text"
                    name="tripDescription"
                    value={trip.tripDescription || ""}
                    onChange={(e) => handleEditChange(e, index, "trips")}
                  />

                  <div className="form-group">
                    <label>How Many Days</label>
                    <input
                      type="number"
                      className="form-control"
                      name="howManyDays"
                      value={trip.howManyDays || ""}
                      onChange={(e) => handleEditChange(e, index, "trips")}
                    />
                  </div>
                  <div className="form-group">
                    <label>Package Pricing</label>
                    <input
                      type="number"
                      className="form-control"
                      name="packagePricing"
                      value={trip.packagePricing || ""}
                      onChange={(e) => handleEditChange(e, index, "trips")}
                    />
                  </div>
                  <div className="form-group">
                    <label>Timing</label>
                    <input
                      type="time"
                      className="form-control"
                      name="timing"
                      value={trip.timing || ""}
                      onChange={(e) => handleEditChange(e, index, "trips")}
                    />
                  </div>
                </div>
              </div>
            ))}
            {/* Add other fields for all tour properties that you want to be editable */}
            {/* ... */}
            <Button type="submit" variant="primary">
              Save Changes
            </Button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setEditMode(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
