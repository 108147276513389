import React, { useState } from "react";
import { Card } from "react-bootstrap";
import "assets/styles/CollapsibleCard.css";

export default ({ title, children, bgColor, textColor, backgroundImage }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  const cardStyle = {
    backgroundColor: bgColor,
    color: textColor,
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover", // or 'contain' based on your needs
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    border: "none",
  };

  return (
    <Card style={cardStyle} onClick={toggleOpen}>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <div className={`collapsible-content ${isOpen ? "open" : ""}`}>
          {children}
        </div>
      </Card.Body>
    </Card>
  );
};
