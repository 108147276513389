import { Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BooleanIcon from "area/admin/AdvertisementManegment/Components/BooleanIcon";
import {
  faEdit,
  faTrashAlt,
  faEye,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
const ShowMobileTourData = ({ row, handleShowClick, handleDeleteClick }) => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>{row.tourName}</Card.Title>
        <Card.Text>
          Dealer: {row.dealer}
          <br />
          Category: {row.category}
          <br />
          Active: <BooleanIcon value={row.showOnWebsite} />
        </Card.Text>
        <div className="d-flex justify-content-end">
          {/* Add your buttons here */}

          <FontAwesomeIcon
            icon={faImage}
            size="lg"
            color="gray"
            className="mr-3"
            onClick={() => handleShowClick(row.tourId, "image")}
          />

          <FontAwesomeIcon
            icon={faEye}
            size="lg"
            color="#198754"
            className="mr-3"
            onClick={() => handleShowClick(row.tourId, "show")}
          />

          <FontAwesomeIcon
            icon={faEdit}
            size="lg"
            color="#0d6efd"
            className="mr-3"
            onClick={() => handleShowClick(row.tourId)}
          />

          <FontAwesomeIcon
            icon={faTrashAlt}
            size="lg"
            color="#dc3545"
            className="mr-3"
            onClick={() => handleDeleteClick(row.tourId, "edit")}
          />

          {/* ... other buttons ... */}
        </div>
      </Card.Body>
    </Card>
  );
};
export default ShowMobileTourData;
