import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { axios } from "common/utils/axios";
import SelectInput from "../Components/SelectInput";
import { useCustomerByIdData } from "common/hooks/useCustomerByIdData ";
import { useCustomerData } from "common/hooks/useCustomerData";
import { ToastContainer, toast } from "react-toastify";

import { Form, Button } from "react-bootstrap";

export const CreatePastCustomerMeetings = () => {
  const { id } = useParams();
  const [diraction, serDirection] = useState([]);
  const customerData = useCustomerByIdData(id);
  const customersData = useCustomerData();
  const [formData, setFormData] = useState({
    Direction: "",
    MeetingDate: "",
    Note: "",
  });

  useEffect(() => {
    axios
      .get(`/api/diraction`)
      .then((response) => serDirection(response.data))
      .catch((error) => console.error(error));
  }, []);

  const handleChange = (event) => {
    if (
      event.target.name === "MeetingDate" &&
      new Date(event.target.value) > new Date()
    ) {
      toast.error("Toplantı tarihi gelecekte olamaz.");
      return;
    }

    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const url = id ? `/api/customers/${id}/meetings` : "/api/customers/meetings";

  const handleSubmit = (event) => {
    event.preventDefault();

    if (new Date(formData.MeetingDate) > new Date()) {
      toast.error("The meeting date cannot be in the future.");
      return;
    }

    axios
      .post(url, formData)
      .then((response) => {
        toast.success("Meeting created successfully!");
        setTimeout(() => {
          window.location.href = `/admin/customerManagement/customers/${id}`;
        }, 2000);
      })
      .catch((error) => {
        toast.error("Error occurred while creating the meeting.");
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div
          style={{
            backgroundColor: "#cdcdcb",
            padding: "5px",
            borderRadius: "4px",
            marginBottom: "15px",
          }}
        >
          <h1>CreateMeeting</h1>
        </div>
        <div className="mb-4">
          <h3>Musterileri</h3>
          {customerData.LastName &&
          customerData.FirstName &&
          customerData.Telefon ? (
            <>
              <h4>
                {customerData.FirstName} {customerData.LastName}{" "}
                {customerData.Telefon}
              </h4>
            </>
          ) : (
            <SelectInput
              value={formData.Direction}
              name="Direction"
              onChange={handleChange}
              label={"yön"}
              options={customersData.map((item) => item.FirstName)}
              keyName="Direction"
            />
          )}
        </div>
        <div className="row mb-4">
          <div className="col-6">
            <h3>yön</h3>
            <SelectInput
              value={formData.Direction}
              name="Direction"
              onChange={handleChange}
              label={"yön"}
              options={diraction.map((item) => item.name)}
              keyName="Direction"
            />
          </div>
          <div className="col-6">
            <h3>tarih ve saat</h3>
            <Form.Group controlId="exampleForm.ControlDateTime">
              <Form.Control
                type="datetime-local"
                name="MeetingDate"
                value={formData.MeetingDate}
                onChange={handleChange}
                min={getLastYearDateTime()}
              />
            </Form.Group>
          </div>
        </div>
        <h3>Note</h3>
        <Form.Group controlId="exampleForm.ControlTextarea">
          <Form.Control
            as="textarea"
            name="Note"
            value={formData.Note}
            onChange={handleChange}
            rows={5}
            placeholder="Enter your note"
          />
        </Form.Group>
        <Button className="mt-4" type="submit">
          Create Meeting
        </Button>
      </form>
      <ToastContainer />
    </div>
  );
};

const getLastYearDateTime = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 1);
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export default CreatePastCustomerMeetings;
