import React, { useState, useEffect } from "react";
import { axios } from "common/utils/axios";
import { useParams } from "react-router-dom";
import SelectInput from "../Components/SelectInput";
import TextInput from "../Components/TextInput";
import { Button } from "@mui/material";
import { Form, Container } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";

import { Link } from "react-router-dom";
function CreateCustomerAddress() {
  const { id } = useParams();
  const [il, setIl] = useState([]);
  const [ilce, setIlce] = useState([]);
  const [mahalle, setMahalle] = useState([]);
  const [filteredIlce, setFilteredIlce] = useState([]);
  const [filteredMahalle, setFilteredMahalle] = useState([]);
  const [address, setAddress] = useState({
    il: "",
    ilce: "",
    mahalle: "",
    adres_title: "",
    adres: "",
  });

  useEffect(() => {
    axios.get("/api/districts").then((response) => setIlce(response.data));
    axios.get("/api/provinces").then((response) => setIl(response.data));
  }, []);

  useEffect(() => {
    if (address.il) {
      const selectedIl = il.find((item) => item.province_name === address.il);
      const filteredIlce = ilce.filter(
        (item) => item.district_province_key === selectedIl.province_key
      );
      console.log("filteredIlce:", filteredIlce); // Debug statement
      setFilteredIlce(filteredIlce);
      setAddress((prevAddress) => ({
        ...prevAddress,
        ilce: filteredIlce.length > 0 ? filteredIlce[0].district_name : "",
      }));
    }
  }, [address.il]);

  const handleChange = (e) => {
    setAddress((prevAddress) => ({
      ...prevAddress,
      [e.target.name]: e.target.value,
    }));

    if (e.target.name === "ilce") {
      const selectedIlce = filteredIlce.find(
        (item) => item.district_name === e.target.value
      );
      if (selectedIlce) {
        axios
          .get(`/api/neighbor?district_key=${selectedIlce.district_key}`)
          .then((response) => {
            setMahalle(response.data);
            setFilteredMahalle(response.data);
            setAddress((prevAddress) => ({
              ...prevAddress,
              mahalle:
                response.data.length > 0
                  ? response.data[0].neighborhood_name
                  : "",
            }));
          })
          .catch((error) => {
            console.error("API call error:", error);
          });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const addressData = {
      customer_id: id,
      ...address,
    };

    axios
      .post("api/addresses", addressData)
      .then((response) => {
        // Handle the response as needed
        console.log(response.data);
        toast.success("Address added successfully");
        setTimeout(() => {
          window.location.href = `/admin/customerManagement/customers/${id}`; // Redirect after 2 seconds
        }, 2000);
      })
      .catch((error) => {
        // Handle any errors that

        console.error(error);
        toast.error("Failed to add address"); // Show error toast
      });
  };
  return (
    <Container>
      <div
        style={{
          padding: "5px",
          borderRadius: "4px",
          marginBottom: "15px",
        }}
      >
        <h2
          className=""
          style={{
            backgroundColor: "#636665",
            padding: "10px",
            color: "white",
            fontSize: "24px",

            borderRadius: "5px",
            marginBottom: "15px",
          }}
        >
          Adres Ekle
        </h2>
      </div>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Address Title</Form.Label>
          <Form.Control
            type="text"
            name="adres_title"
            value={address.adres_title}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>il</Form.Label>
          <Form.Control
            as="select"
            name="il"
            value={address.il}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Select il
            </option>{" "}
            {il.map((item, index) => (
              <option key={index} value={item.province_name}>
                {item.province_name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.Label>Ilce</Form.Label>
          <Form.Control
            as="select"
            name="ilce"
            value={address.ilce}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Select Ilce
            </option>{" "}
            {filteredIlce.map((item, index) => (
              <option key={index} value={item.district_name}>
                {item.district_name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.Label>Mahalle</Form.Label>
          <Form.Control
            as="select"
            name="mahalle"
            value={address.mahalle}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Select Mahalle
            </option>{" "}
            {filteredMahalle.map((item, index) => (
              <option key={index} value={item.neighborhood_name}>
                {item.neighborhood_name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group className="mb-4">
          <Form.Label>Adres</Form.Label>
          <Form.Control
            type="text"
            name="adres"
            value={address.adres}
            onChange={handleChange}
          />
        </Form.Group>

        <Button
          type="submit"
          style={{
            backgroundColor: "#3199c5",
            color: "white",
            paddingLeft: "30px",
            paddingRight: "30px",
            marginRight: "10px",
          }}
        >
          Ekle
        </Button>
        <Link to={`/admin/customerManagement/customers/${id}`}>
          <Button
            type="submit"
            style={{
              backgroundColor: "rgb(137 142 144)",
              color: "white",
              paddingLeft: "30px",
              paddingRight: "30px",
            }}
          >
            Iptal
          </Button>
        </Link>
      </Form>

      <ToastContainer />
    </Container>
  );
}

export default CreateCustomerAddress;
