import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Row, Col, Form, Card, Button } from "react-bootstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "common/Api/BlogApi";
import { toast } from "react-toastify";
import Quill from "react-quill";
import Title from "area/admin/CRUD/ReactBootsrap/FormTextInput";
import PostImage from "area/admin/CRUD/ReactBootsrap/FormUplaodInput";
import TagInputComponent from "area/admin/CRUD/TagInputComponent";
import { Link } from "react-router-dom";
import "assets/styles/Bloger.css";
const Tags = [{ name: "Umra" }, { name: "Hac" }];
const Categories = [{ name: "Kandil" }, { name: "Hac" }];
export const CreatePostModal = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const queryClient = useQueryClient();
  const [imageURL, setImageURL] = useState(null);
  const [isScheduling, setIsScheduling] = useState(false);
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    text: "",
    image: "",
    published_at: "",
    status: "",
  });
  const transformedTags = Tags.map((tag) => ({
    value: tag.name,
    label: tag.name,
  }));
  const transformedCategories = Categories.map((category) => ({
    value: category.name,
    label: category.name,
  }));
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(`Handling change for ${name}: ${value}`);
    // Adjust scheduling visibility based on status selection
    if (name === "status") {
      setIsScheduling(value === "scheduled");
    }
    // Update formData state directly with the new value
    setFormData({ ...formData, [name]: value });
  };

  const handleQuillChange = (content) => {
    setFormData((prev) => ({ ...prev, text: content }));
  };
  const handleTagsChange = (selectedOptions) => {
    setTags(selectedOptions);
  };

  const handleCategoriesChange = (selectedOptions) => {
    setCategories(selectedOptions);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const newImageURL = URL.createObjectURL(file);

    // Update the imageURL state
    setImageURL(newImageURL);
    setFormData((prevState) => ({
      ...prevState,
      image: file,
    }));
  };
  useEffect(() => {
    // Directly tie the visibility of the scheduling to the 'scheduled' status
    setIsScheduling(formData.status === "scheduled");
  }, [formData.status]);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const mutationCreate = useMutation(api.createPost, {
    onSuccess: () => {
      queryClient.invalidateQueries("posts");
      toast.success("Gönderi başarıyla eklendi.!");
      setFormData({
        title: "",
        description: "",
        text: "",
        image: "",
        published_at: "",
        status: "",
      });
      // Clear the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }

      // Navigate to the desired path
      window.location.href = "/admin/post/index";
    },
    onError: (error) => {
      const errorMsg = error?.response?.data?.message || "Error adding post";
      toast.error(errorMsg);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let avatar_base64 = formData.image;

    // Convert image to base64 if it's a Blob (file was uploaded)
    if (formData.image instanceof Blob) {
      avatar_base64 = await toBase64(formData.image);
    }

    // Prepare jsonData with converted image and correct status
    let jsonData = {
      ...formData,
      image: avatar_base64,
      tag_ids: tags.map((tag) => tag.value), // Assuming tags have a value property
      category_ids: categories.map((category) => category.value), // Assuming categories have a value property
      // Include logic for handling new tags/categories if applicable
    };

    // Additional handling based on status
    switch (formData.status) {
      case "published":
        // For "Yayınla" - Set published_at to now if not set
        jsonData.published_at =
          formData.published_at || new Date().toISOString();
        break;
      case "scheduled":
        // For "Planla" - Ensure published_at is set; frontend validation should catch missing dates
        if (!formData.published_at) {
          toast.error(
            "Lütfen planlanmış gönderiler için bir yayın tarihi belirleyin.."
          );
          return; // Prevent submission
        }
        break;
      case "draft":
      case "passive":
        // For "Taslak" and "Pasif Al" - No additional action needed
        break;
      default:
        // Log unexpected status values
        console.error("Unexpected status value:", formData.status);
        return; // Prevent submission on unexpected status
    }

    // Submit the post data
    mutationCreate.mutate(jsonData);
  };

  return (
    <Card style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)" }}>
      <Card.Header
        style={{
          backgroundColor: "#636665",
          color: "white",
          borderRadius: "5px 5px 0 0",
        }}
      >
        <Row>
          <Col>
            <h1 style={{ padding: "10px", fontSize: "24px", margin: 0 }}>
              Yeni Blog Ekle
            </h1>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Row>
              <Col>
                <Link to="/admin/post/index">
                  <Button
                    variant="outline-warning"
                    style={{ color: "white" }}
                    className="mt-2"
                  >
                    Geri
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Header>
      <Row className="mb-3 ">
        <Col xl={9}>
          <Card>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Title
                  value={formData.title}
                  label={"Başlık"}
                  type="text"
                  name={"title"}
                  placeholder={"Başlık"}
                  onChange={handleChange}
                />
                <Title
                  value={formData.description}
                  label={"Açıklama"}
                  type="text"
                  name={"description"}
                  placeholder={"Açıklama"}
                  onChange={handleChange}
                />
                <Form.Group className="mb-3" style={{ height: "200px" }}>
                  <Form.Label>Metin Ekleyin</Form.Label>
                  <Quill
                    className="custom-quill"
                    value={formData.text}
                    onChange={handleQuillChange}
                  />
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={3}>
          <Card className="p-4">
            <Form.Group>
              <Form.Label>
                <strong>Durum</strong>
              </Form.Label>
              <Form.Control
                as="select"
                name="status"
                onChange={handleChange}
                defaultValue=""
              >
                <option value="" disabled hidden>
                  Seçiniz
                </option>
                <option value="published">Yayınla</option>
                <option value="scheduled">Planla</option>
                <option value="draft">Taslak</option>
                <option value="passive">Pasif Al</option>
              </Form.Control>
            </Form.Group>
            {isScheduling ? (
              <Form.Group className="d-flex justify-content-between">
                <div className="mt-3">
                  <label>Yayınlama Tarihi Belirle</label>
                  <Form.Control
                    type="datetime-local"
                    name="published_at"
                    value={formData.published_at}
                    onChange={handleChange}
                    required={true}
                  />
                </div>
              </Form.Group>
            ) : (
              ""
            )}
            <div className="mt-3">
              <strong>Etiketler</strong>
              <TagInputComponent
                options={transformedTags}
                value={tags}
                onChange={handleTagsChange}
                placeholder="Select or create tags..."
              />
            </div>
            <div className="mt-3 mb-3">
              <strong>Kategoriler</strong>
              <TagInputComponent
                options={transformedCategories}
                value={categories}
                onChange={handleCategoriesChange}
                placeholder="Select or create categories..."
              />
            </div>
            <PostImage
              editWithCustomStyle={true}
              label={"Blog Görseli"}
              name={"image"}
              imageSrc={imageURL}
              onChange={handleFileChange}
            />
          </Card>
          <div className=" text-end ">
            <Button onClick={handleSubmit} className="mt-2 mr-2">
              Blog Ekle
            </Button>
            <Link to="/admin/post/index">
              <Button className="mt-2 mr-2" variant="warning">
                Geri
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Card>
  );
};
export default CreatePostModal;
