import { Modal, Button, Row, Col, Form, ListGroup } from "react-bootstrap";
import { useMutation } from "react-query";
import { createHotel } from "common/Api/HotelsApi";
import { toast } from "react-toastify";
import HotelDetails from "area/admin/Hotels/components/create/HotelDetails";
import HotelRooms from "area/admin/Hotels/components/create/HotelRooms";
import HotelAddress from "area/admin/Hotels/components/create/HotelAddress";
import HotelPhotograhps from "area/admin/Hotels/components/create/HotelPhotograhps";
import classNames from "classnames";
export const CreateHotelModal = ({
  setFormData,
  setModalShowCreateHotel,
  modalShowCreateHotel,
  handleNextSection,
  handlePreviousSection,
  sectionTitles,
  activeSection,
  setActiveSection,
  formData,
  updateFormData,
}) => {
  const createHotelMutation = useMutation(createHotel, {
    onSuccess: (data) => {
      toast.success("Hotel created successfully!");
      setModalShowCreateHotel(false);
    },
    onError: (error) => {
      const errorMessage =
        error.response?.data?.message || "An unexpected error occurred";
      toast.error(errorMessage);
    },
  });
  const handleSubmitFinal = () => {
    const submissionData = new FormData();

    // Append text fields
    submissionData.append("otel_name", formData.otel_name);
    submissionData.append("otel_stars", formData.otel_stars);
    submissionData.append("otel_description", formData.otel_description);

    // Append nested objects like otel_details and otel_address
    Object.keys(formData.otel_details).forEach((key) => {
      let value = formData.otel_details[key];
      if (typeof value === "boolean") {
        value = value ? "1" : "0"; // Convert boolean to '1' or '0'
      } else if (value === undefined) {
        value = "0"; // Set default value as '0' for undefined boolean fields
      }
      submissionData.append(`otel_details[${key}]`, value);
    });

    Object.keys(formData.otel_address).forEach((key) => {
      submissionData.append(`otel_address[${key}]`, formData.otel_address[key]);
    });
    // Append photographs
    if (formData.room_types) {
      formData.room_types.forEach((roomType, index) => {
        Object.keys(roomType).forEach((key) => {
          submissionData.append(`room_types[${index}][${key}]`, roomType[key]);
        });
      });
    }

    if (formData.mainPhotograph) {
      submissionData.append(
        "mainPhotograph",
        formData.mainPhotograph,
        formData.mainPhotograph.name
      );
    }
    formData.insidePhotographs.forEach((file, index) => {
      submissionData.append(`insidePhotographs[${index}]`, file, file.name);
    });

    formData.outsidePhotographs.forEach((file, index) => {
      submissionData.append(`outsidePhotographs[${index}]`, file, file.name);
    });

    console.log("Submitting formData:", formData);
    console.log("Submitting submissionData:", submissionData);
    for (let [key, value] of submissionData.entries()) {
      console.log(`${key}:`, value);
    }
    // Now, call the API
    createHotelMutation.mutate(submissionData);
  };

  console.log(formData, "formData");
  return (
    <div>
      <Modal
        show={modalShowCreateHotel}
        onHide={() => setModalShowCreateHotel(false)}
        size="xl"
        centered
      >
        <Modal.Body style={{ height: "495px", overflowY: "auto" }}>
          <Row className="mb-4 text-center ">
            <Col md={12}>
              <ListGroup className="list-group-horizontal-md">
                {sectionTitles &&
                  sectionTitles?.map((title, index) => (
                    <ListGroup.Item
                      key={index}
                      className={classNames({
                        "active-list-group-item": activeSection === index + 1,
                      })}
                      action
                      active={activeSection === index + 1}
                      onClick={() => setActiveSection(index + 1)}
                    >
                      {title}
                    </ListGroup.Item>
                  ))}
              </ListGroup>
            </Col>
          </Row>
          <Form>
            <Row>
              <Col md={12}>
                {activeSection === 1 && (
                  <HotelDetails
                    formData={formData}
                    updateFormData={updateFormData}
                  />
                )}
              </Col>
              <Col md={12}>
                {activeSection === 2 && (
                  <HotelRooms
                    formData={formData}
                    updateFormData={updateFormData}
                  />
                )}
              </Col>
              <Col md={12}>
                {activeSection === 3 && (
                  <HotelAddress
                    formData={formData}
                    updateFormData={updateFormData}
                  />
                )}
              </Col>
              <Col md={12}>
                {activeSection === 4 && (
                  <HotelPhotograhps
                    setFormData={setFormData}
                    updateFormData={updateFormData}
                    formData={formData}
                  />
                )}
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setModalShowCreateHotel(false)}
          >
            Kapat
          </Button>
          {activeSection > 1 && (
            <Button variant="warning" onClick={handlePreviousSection}>
              Önceki
            </Button>
          )}
          {activeSection < 4 ? (
            <Button variant="primary" onClick={() => handleNextSection({})}>
              Sonraki
            </Button>
          ) : (
            <Button variant="success" onClick={handleSubmitFinal}>
              Kaydet
            </Button>
          )}
        </Modal.Footer>{" "}
      </Modal>
    </div>
  );
};
export default CreateHotelModal;
