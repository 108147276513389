const API_URL = "https://www.universal-tutorial.com/api";
const API_TOKEN =
  "BXMDBULapGts7B33Qgq4lvymUwJPzbksyovs_YrH5gf_mvbghc6xCE4f2l98zy87MVU";
const USER_EMAIL = "nabeelhero12@gmail.com";

// Function to get access token using fetch
const getAccessToken = async () => {
  const currentTime = Date.now();
  const tokenLifetime = 60 * 60 * 1000; // 1 hour in milliseconds
  const storedToken = localStorage.getItem("authToken");
  const storedExpiry = localStorage.getItem("authTokenExpiry");

  // Check if the stored token is still valid
  if (storedToken && storedExpiry && currentTime < parseInt(storedExpiry)) {
    return storedToken;
  }

  // Fetch a new token if necessary
  try {
    const response = await fetch(`${API_URL}/getaccesstoken`, {
      headers: {
        Accept: "application/json",
        "api-token": API_TOKEN,
        "user-email": USER_EMAIL,
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    const newExpiry = currentTime + tokenLifetime;
    localStorage.setItem("authToken", data.auth_token);
    localStorage.setItem("authTokenExpiry", newExpiry.toString());
    return data.auth_token;
  } catch (error) {
    console.error("Error fetching access token:", error);
    return null;
  }
};

// Function to get countries using fetch and getting access token from the function getAccessToken();
const getCountries = async () => {
  try {
    const authToken = await getAccessToken();
    console.log("Auth Token:", authToken);
    if (!authToken) {
      throw new Error("Failed to get access token");
    }
    const response = await fetch(
      "https://www.universal-tutorial.com/api/countries",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching countries:", error);
    return [];
  }
};

// Function to get states based on countryName the using fetch
const getStates = async (countryName) => {
  try {
    const authToken = await getAccessToken();
    if (!authToken) {
      throw new Error("Failed to get access token");
    }
    const response = await fetch(
      `https://www.universal-tutorial.com/api/states/${countryName}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching states:", error);
    return [];
  }
};

// Function to get cities based on stateName using fetch
const getCities = async (stateName) => {
  try {
    const authToken = await getAccessToken();
    if (!authToken) {
      throw new Error("Failed to get access token");
    }
    const response = await fetch(
      `https://www.universal-tutorial.com/api/cities/${stateName}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching cities:", error);
    return [];
  }
};

export { getCountries, getStates, getCities };
