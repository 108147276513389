import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import tw from "twin.macro";

import DatePicker from "../date-picker/DatePicker";
import { axios } from "../../utils/axios";

const Input = tw.input`relative w-full px-8 py-5 rounded-lg font-medium bg-white border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 min-w-[150px] rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  padding: 0.4rem; /* Set custom padding */
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const StyledCol = styled(Col)`
  @media (max-width: 575.98px) {
    margin-top: 0.5rem; /* Equivalent to Bootstrap's mt-2 */
  }
`;
export default function OtelSearchingList() {
  const [search_params, setSearch_params] = useState([]);
  const [search_params_filtered, setSearch_params_filtered] = useState([]);
  const [showSelect, setShowSelect] = useState(false);

  const whereOtel = useRef();

  let navigate = useNavigate();

  const handleChange = (event) => {
    let newArr = [];
    search_params?.map((item) =>
      item?.value?.toUpperCase().includes(event.target.value.toUpperCase())
        ? (newArr = [...newArr, item])
        : " "
    );
    setSearch_params_filtered(newArr);
  };
  const handleFocus = () => {
    setShowSelect(true);
  };
  const handleBlur = () => {
    setTimeout(run, 200);
    function run() {
      setShowSelect(false);
    }
  };
  const hanleOtelClick = (e) => {
    whereOtel.current.value = e.target.dataset.value;
    whereOtel.current.key = e.target.dataset.key;
    whereOtel.current.datatype = e.target.dataset.type;
  };
  function handleSearch() {
    return navigate(
      `/otels?key=${whereOtel.current.key}&type=${whereOtel.current.datatype}`
    );
  }
  // function getSearchParams() {
  //   axios.get("api/search_params_otel").then((item) => {
  //     setSearch_params(item.data);
  //     setSearch_params_filtered(item.data);
  //   });
  // }
  // useEffect(() => {
  //   getSearchParams();
  // }, []);

  return (
    <Row>
      <Col xl={4}>
        <Input
          ref={whereOtel}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="Otel Adı... Konum..."
          className="form-control"
        />
        <div
          className={`position-absolute z-index-10 overflow-auto h-48 border w-full bg-white ${
            showSelect ? "" : "d-none"
          }`}
        >
          <div className="p-5 shadow-xl">
            <ul>
              {search_params_filtered?.map((item, index) => (
                <li
                  className="even:bg-gray-100 odd:bg-primary-500 odd:text-white p-2 my-2 rounded border"
                  key={index}
                  data-type={item.type}
                  data-value={item.value}
                  data-key={item.key}
                  onClick={hanleOtelClick}
                >
                  {item.value}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Col>
      <StyledCol xl={5}>
        <DatePicker />
      </StyledCol>
      <Col xl={2} className="d-felx align-items-center">
        <button
          type="submit"
          style={{ backgroundColor: "rgb(0, 109, 119)", border: "none" }}
          onClick={handleSearch}
          className="btn btn-primary w-100"
        >
          <span className="text">Ara</span>
        </button>
      </Col>
    </Row>
  );
}
