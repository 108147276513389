import React from "react";
import Header from "../../../common/components/headers/light.js";
import AnimationRevealPage from "../../../common/helpers/AnimationRevealPage.js";
import Footer from "../../../common/components/footers/FiveColumnWithInputForm.js";
import ToursTamplateCardWithOtels from "./components/ToursTamplateCardWithOtels.js";
import ToursTamplateCard from "./components/ToursTamplateCard.js";
import { PraintBttons } from "./components/PraintBttons.js";
import ToursTamplateCardWithPrices from "./components/ToursTamplateCardWithPrices";
export const ToursTamplate = () => {
  return (
    <div>
      <AnimationRevealPage>
        <Header />
        <ToursTamplateCardWithOtels />
        <ToursTamplateCardWithPrices />
        <ToursTamplateCard />
        <PraintBttons />
        <Footer />
      </AnimationRevealPage>{" "}
    </div>
  );
};
export default ToursTamplate;
