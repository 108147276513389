import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Table, Button, Modal, Row, Col } from "react-bootstrap";
import { deleteHotel } from "common/Api/HotelsApi";
import EditHotelModal from "area/admin/Hotels/components/edit/EditHotelModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import ShowHotelModal from "area/admin/Hotels/components/show/ShowHotelModal";
import {
  faEdit,
  faTrashAlt,
  faEye,
  faImage,
  faStar,
  faLocationDot,
  faBed,
  faUtensils,
  faThermometerHalf,
  faArrowRight,
  faWifi,
  faSnowflake,
  faFirstAid,
  faFire,
  faSink,
  faMicrochip,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

export const HotelsList = ({
  otelData,
  updateFormData,
  handleNextSection,
  handlePreviousSection,
  handleSubmitFinal,
  sectionTitles,
  activeSection,
  setActiveSection,
  formData,
}) => {
  const queryClient = useQueryClient();
  const [modalShow, setModalShow] = useState(false);
  const [modalShowHotel, setModalShowHotel] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const handleEditClick = (hotel) => {
    setSelectedHotel(hotel);
    updateFormData({
      otel_name: otelData.otel_name || "",
      otel_stars: otelData.otel_stars || "",
      otel_description: otelData.otel_description || "",
      otel_details:
        otelData.details ||
        {
          // default details structure
        },
      ddress:
        otelData.address ||
        {
          // default address structure
        },
      room_types: otelData.room_types || [],
      photographs: otelData.photographs || [],
    });
    setModalShow(true);
  };
  const handleShowClick = (hotel) => {
    setSelectedHotel(hotel);
    setModalShowHotel(true);
  };
  const deleteHotelMutation = useMutation((hotelId) => deleteHotel(hotelId), {
    onSuccess: () => {
      queryClient.invalidateQueries("hotels");
      toast.success("Hotel Deleted successfuly");
    },
    onError: (error) => {
      console.error("Error deleting hotel:", error);
      toast.error("Error deleting hotel");
    },
  });
  console.log(selectedHotel, "selectedHotel");
  return (
    <div>
      <Table bordered hover responsive>
        <thead className="text-center">
          <tr>
            <th>Otel Adı</th>
            <th>Ulke</th>
            <th>Otel Yıdızlama</th>
            <th>Eylem</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {otelData &&
            otelData.map((row) => (
              <tr key={row.otel_id}>
                <Link to={`/otel/${row.slug}`}>
                  <td>{row?.otel_name}</td>
                </Link>
                <td>{row.address?.country}</td>
                <td>{row?.otel_stars}</td>
                <td>
                  <>
                    <Button
                      variant="success"
                      className="me-2"
                      onClick={() => handleShowClick(row)}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faEye} />
                    </Button>

                    <Button
                      variant="primary"
                      className="me-2"
                      onClick={() => handleEditClick(row)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                    <Button
                      variant="danger"
                      className="me-2"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to delete this hotel?"
                          )
                        ) {
                          deleteHotelMutation.mutate(row.otel_id);
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  </>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <EditHotelModal
        updateFormData={updateFormData}
        otelData={selectedHotel}
        modalShow={modalShow}
        setModalShow={setModalShow}
        selectedHotel={selectedHotel}
        handleNextSection={handleNextSection}
        handlePreviousSection={handlePreviousSection}
        handleSubmitFinal={handlePreviousSection}
        sectionTitles={sectionTitles}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
        formData={setActiveSection}
      />
      <ShowHotelModal
        setModalShowHotel={setModalShowHotel}
        modalShowHotel={modalShowHotel}
        selectedHotel={selectedHotel}
      />
    </div>
  );
};
export default HotelsList;
