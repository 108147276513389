import React, { useState, useEffect } from "react";
import SelectInput from "../Components/SelectInput";
import TextInput from "../Components/TextInput";
import RadioInput from "../Components/RadioInput";
import DateInput from "../Components/DateInput";
import UploadFileInput from "../Components/uploadFileInput";
import { axios } from "common/utils/axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Form, Button, Row, Col } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import InputLabel from "@mui/material/InputLabel";
import { Link } from "react-router-dom";

export const EditCustomerFamily = ({ handleCloseEditFamilyDialog }) => {
  const { id, memberId } = useParams();
  const [familyRelationships, setFamilyRelationships] = useState([]);
  const [customerFamily, setCustomerFamily] = useState({
    data: {
      FirstName: "",
      LastName: "",
      relative_relation: "",
      job: "",
      gender: "",
      birthday: "",
      telephone: "",
      email: "",
      explanation: "",
      photo: "",
    },
  });

  // Fetch the current family member data
  useEffect(() => {
    axios
      .get(`/api/customer-family/${memberId}`)
      .then((response) => {
        setCustomerFamily({ data: response.data });
      })
      .catch((error) => console.error(error));
  }, [memberId]);

  // Fetch family relationships
  useEffect(() => {
    axios
      .get("/api/family-relative-relationships")
      .then((response) => setFamilyRelationships(response.data))
      .catch((error) => console.error(error));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    let data = {};
    for (const key in customerFamily.data) {
      data[key] = customerFamily.data[key];
    }
    let jsonData = JSON.stringify({ data }); // Convert the JavaScript object to a JSON string

    axios
      .put(`api/customer-family/${id}`, jsonData, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        toast.success("Family member updated successfully!");
        window.location.href = `/admin/customerManagement/customers/${id}`;
      })
      .catch((error) => {
        toast.error("An error occurred while updating the family member!");
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerFamily({
      ...customerFamily,
      data: {
        ...customerFamily.data,
        [name]: value,
      },
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setCustomerFamily({
      ...customerFamily,
      data: {
        ...customerFamily.data,
        [name]: files[0],
      },
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} md={12} xl={4} xxl={4}>
            <TextInput
              label="Adı"
              name="FirstName"
              value={customerFamily.data.FirstName}
              onChange={handleChange}
            />
          </Col>
          <Col xs={12} md={12} xl={4} xxl={4}>
            <TextInput
              label="SoyAdı"
              name="LastName"
              value={customerFamily.data.LastName}
              onChange={handleChange}
            />
          </Col>
          <Col xs={12} md={12} xl={4} xxl={4}>
            {/* Assuming SelectInput is a custom component */}
            <SelectInput
              name="relative_relation"
              value={customerFamily.data.relative_relation}
              onChange={handleChange}
              label="İlişki"
              options={familyRelationships.map((item) => item.name)}
              keyName="name"
            />
          </Col>
          <Col xs={12}>
            <TextInput
              label="Meslek"
              name="job"
              value={customerFamily.data.job}
              onChange={handleChange}
            />
          </Col>
          <Col xs={12}>
            {/* Assuming RadioInput is a custom component */}
            <RadioInput
              name="gender"
              value={customerFamily.data.gender}
              onChange={handleChange}
            />
          </Col>
          <Col xs={12} md={12} xl={4} xxl={4}>
            {/* Assuming DateInput is a custom component */}
            <DateInput
              name="birthday"
              value={customerFamily.data.birthday}
              onChange={handleChange}
            />
          </Col>
          <Col xs={12} md={12} xl={4} xxl={4}>
            <TextInput
              label="E-posta"
              name="email"
              value={customerFamily.data.email}
              onChange={handleChange}
            />
          </Col>
          <Col xs={12} md={12} xl={4} xxl={4}>
            <TextInput
              label="Telefon"
              name="telephone"
              value={customerFamily.data.telephone}
              onChange={handleChange}
            />
          </Col>
          <Col xs={12} md={12} xl={12} xxl={12} className="mb-4">
            <TextInput
              label="Açıklama"
              name="explanation"
              value={customerFamily.data.explanation}
              onChange={handleChange}
            />
          </Col>
          <Col xs={12}>
            {/* Assuming UploadFileInput is a custom component */}
            <UploadFileInput name="photo" onChange={handleFileChange} />
          </Col>
          <Col md={10} className="text-end">
            <Button
              type="submit"
              style={{
                backgroundColor: "#3199c5",
                color: "white",
                paddingLeft: "30px",
                paddingRight: "30px",
              }}
            >
              Ekle
            </Button>
          </Col>
          <Col md={2} className="text-end">
            <Button
              onClick={handleCloseEditFamilyDialog}
              style={{
                backgroundColor: "rgb(137 142 144)",
                color: "white",
                paddingLeft: "30px",
                paddingRight: "30px",
              }}
            >
              Iptal
            </Button>
          </Col>
        </Row>
      </form>
      <ToastContainer />
    </div>
  );
};
export default EditCustomerFamily;
