import React, { useState, useEffect } from "react";
import { axios } from "common/utils/axios";
import { Form, Button, Alert, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "assets/styles/ResetPassword.css";

function ResetPassword() {
  const [step, setStep] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [resetCode, setResetCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [timeLeft, setTimeLeft] = useState(120);

  useEffect(() => {
    if (step === 2 && timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else if (timeLeft === 0) {
      setStep(1);
      setPhoneNumber("");
      setResetCode("");
      setNewPassword("");
      setConfirmPassword("");
      setError("");
      setSuccess("");
    }
  }, [step, timeLeft]);

  const handleSendCode = async () => {
    try {
      const response = await axios.post("/api/sendPasswordReset", {
        gsmno: phoneNumber,
      });
      setStep(2);
      setTimeLeft(120); // reset the timer
      setError("");
    } catch (err) {
      setError(err.response.data.message);
    }
  };

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      setError("Password and confirm password do not match.");
      return;
    }

    try {
      const response = await axios.post("/api/resetPassword", {
        token: resetCode,
        new_password: newPassword,
      });

      if (response.status === 200) {
        // If the password reset was successful, attempt to login
        try {
          const loginResponse = await axios.post("/api/login", {
            email_or_phone: phoneNumber, // assuming that phoneNumber is the email or phone number
            password: newPassword,
          });

          if (loginResponse.status === 200) {
            // Login was successful, do something here with loginResponse.data
            console.log(loginResponse.data);
            window.location.href = "/login"; // Redirect to /login
          } else {
            // Login was unsuccessful
            setError("Login after password reset was unsuccessful");
          }
        } catch (loginErr) {
          // handle login error here
          console.error(loginErr);
        }
      } else {
        // The password reset was unsuccessful
        setError("Password reset was unsuccessful");
      }

      setSuccess("Password reset successfully!");
      setError("");
    } catch (err) {
      setError(err.response.data.message);
    }
  };

  return (
    <div style={{}}>
      <div className="title1">
        <h1>Şifremi Unuttum</h1>
      </div>
      <Card className="reset-card">
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}

        {step === 2 && (
          <div className="timer">Time remaining: {timeLeft} seconds</div>
        )}

        {step === 1 ? (
          <div>
            <Form className="reset-form ">
              <Form.Group controlId="formBasicPhoneNumber">
                <Form.Label style={{ textAlign: "center" }}>
                  Şifrenizi sıfırlama Cep Telefon almak için sisteme kayıtlı Cep
                  Telefon giriniz.
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter phone number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Form.Group>

              <Button
                variant="primary"
                onClick={handleSendCode}
                className="mt-4"
              >
                Send Code
              </Button>
              <h6 className="mt-2">yada</h6>
              <Link to="/login">Giriş Yap</Link>
            </Form>
          </div>
        ) : (
          <Form className="reset-form ">
            <Form.Group controlId="formBasicResetCode">
              <Form.Label>Reset Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter reset code"
                value={resetCode}
                onChange={(e) => setResetCode(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formBasicNewPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formBasicConfirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>

            <Button
              variant="primary"
              onClick={handleResetPassword}
              className="mt-4"
            >
              Reset Password
            </Button>
          </Form>
        )}
      </Card>
    </div>
  );
}

export default ResetPassword;
