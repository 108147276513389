// import Axios from "axios";
// // import React from "react";

// const token = localStorage.getItem("token");
// console.log(token, "token");
// export const axios = Axios.create({
//   //RemotServer
//   baseURL: "https://backend.suarelab.com",
//   //LocalServer
//   // baseURL: "http://127.0.0.1:8000/",

//   headers: {
//     "X-Requested-With": "XMLHttpRequest",
//     Accept: " application/json",
//     Authorization: `Bearer ${token}`,
//   },
//   withCredentials: true,
//   // timeout: 3000,
// });
import Axios from "axios";
import { getCookie } from "common/helpers/getCookie";
export const axios = Axios.create({
  baseURL: "https://backend.suarelab.com",
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    Accept: " application/json",
  },
  withCredentials: true,
});

axios.interceptors.request.use(
  (config) => {
    const token = getCookie("token");
    console.log(token, "token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
