import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
export const RadioInput = (props) => {
  return (
    <div>
      <FormControl>
        <FormLabel component="legend">{props.label}</FormLabel>
        <RadioGroup
          aria-label={props.name}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
        >
          <FormControlLabel value={false} control={<Radio />} label="Erkek" />
          <FormControlLabel value={true} control={<Radio />} label="Kadın" />
        </RadioGroup>
      </FormControl>
    </div>
  );
};
export default RadioInput;
