import React, { useState, useEffect, useRef } from "react";
import { axios } from "common/utils/axios";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Modal,
  Alert,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { getCookie } from "common/helpers/getCookie";
import { useParams } from "react-router-dom";

const UserChat = () => {
  const userCookie = getCookie("user");
  const USER_ID = userCookie ? JSON.parse(userCookie).id : null;
  const { conversationId } = useParams();
  const [messagess, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState("active");
  const adminId = 1; // replace with actual admin id
  const statusRef = useRef(status); // create a ref for the status

  // update the statusRef every time status changes
  useEffect(() => {
    statusRef.current = status;
  }, [status]);

  const handleClose = () => {
    setShowModal(false);
    updateConversationStatus(conversationId);
  };

  const handleShow = () => setShowModal(true);

  const updateConversationStatus = (id) => {
    axios
      .put(`api/conversations/${id}`, { status: "inactive" })
      .then((response) => {
        console.log("Successfully updated conversation status to inactive");
        setStatus("inactive");
      })
      .catch((error) => {
        console.error("Error updating conversation status:", error);
      });
  };

  const fetchMessages = async () => {
    const response = await axios.get(
      `api/conversations/${conversationId}/messages`
    );
    setMessages(response.data.messages);
    setStatus(response.data.status); // assuming the status is returned with the messages
  };

  useEffect(() => {
    fetchMessages();
    const intervalId = setInterval(() => {
      if (statusRef.current === "inactive") {
        // if status is 'inactive', clear the interval
        clearInterval(intervalId);
      } else {
        fetchMessages();
      }
    }, 2000); // fetch new messages every 1 second

    return () => clearInterval(intervalId); // cleanup interval on unmount
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const messageData = {
        conversation_id: conversationId, // replace with actual conversation id
        user_id: USER_ID, // replace with actual user id
        message: newMessage,
      };
      await axios.post("api/messages", messageData);
      setNewMessage("");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Button
        onClick={handleShow}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "#ff1a1a",
        }}
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </Button>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Görüşmeyi Kapat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Yapmanız gereken her şeyi yaptığınızdan emin misiniz?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Evet
          </Button>
          <Button variant="primary" onClick={() => setShowModal(false)}>
            Haiyr
          </Button>
        </Modal.Footer>
      </Modal>

      <Container className="chat">
        <Row
          className="messages"
          style={{ overflowY: "auto", maxHeight: "80vh", marginBottom: "1rem" }}
        >
          {messagess.map((message) => (
            <Col
              xs={12}
              key={message.id}
              style={{
                display: "flex",
                justifyContent:
                  message.user_id === adminId ? "flex-end" : "flex-start",
              }}
            >
              <Card
                style={{ width: "auto", marginBottom: "0.5rem" }}
                bg={message.user_id === adminId ? "primary" : "light"}
                text={message.user_id === adminId ? "white" : "dark"}
              >
                <Card.Body>{message.message}</Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <Row>
          <Col xs={12}>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="messageInput" className="d-flex">
                <Form.Control
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  type="text"
                  placeholder="Write a message..."
                  disabled={status === "inactive"}
                />
                <Button
                  style={{ backgroundColor: "dodgerblue" }}
                  type="submit"
                  disabled={status === "inactive"}
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </Button>
              </Form.Group>
              {status === "inactive" && (
                <Alert variant="danger">
                  Bu sohbet kapalı ve artık mesaj gönderemezsiniz.
                </Alert>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserChat;
