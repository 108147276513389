import React, { useState } from "react";
import { axios } from "common/utils/axios";
import { Table, Button, ButtonGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  faEye,
  faUserEdit,
  faUserMinus,
  faPlusSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ActiveCustomerPopUp from "../ServiceCustomersComponents/Components/ActiveCustomerPopUp";
import EditeCustomerModal from "../ServiceCustomersComponents/Update/EditeCustomerModal";
export const CustomerListTable = ({
  handleDelete,
  handleCloseEdit,
  handleOpenModal,
  setOpenEdit,
  openEdit,
  paginatedData,
  customerId,
  customerToUpdate,
  customersData,
  setCustomerToUpdate,
  setCustomersData,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  console.log(customersData, "customersData");
  const handleEditeIsActive = (selectedOption) => {
    axios
      .put(`api/customers/update-isactive/${customerToUpdate.id}`, {
        IsActive: selectedOption,
      })
      .then((response) => {
        const updatedCustomersData = customersData.map((customer) => {
          if (customer.id === customerToUpdate.id) {
            return { ...customer, IsActive: selectedOption };
          }
          return customer;
        });
        setCustomersData(updatedCustomersData);
        handleClose();
      })
      .catch((error) => {
        // handle error response
      });
  };
  const handleClickOpen = (customerId, isActive) => {
    setCustomerToUpdate({ id: customerId, isActive });
    setSelectedOption(isActive);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setSelectedOption("");
  };
  const customerIdforEdit = customerId;
  return (
    <div style={{ overflowX: "auto" }}>
      <Table striped bordered hover responsive style={{ fontSize: "14px" }}>
        <thead>
          <tr>
            <th>Durum</th>
            <th>Ad Soyad</th>
            <th>Telefon</th>
            <th>Eposta</th>
            <th>Meslek</th>
            <th>Oluşturma Tarih</th>
            <th>Ekleyen Kullanıcı</th>
            <th>Nereden Buldunuz?</th>
            <th>Eylem</th>
          </tr>
        </thead>
        <tbody>
          {customersData.map((customer) => {
            const isActiveColor =
              customer.IsActive === "active" ? "success" : "danger";
            const isActiveText =
              customer.IsActive === "active" ? "Aktif" : "Pasif"; // Adjust text based on the IsActive value

            return (
              <tr key={customer.id}>
                <td>
                  <Button
                    active={true}
                    variant={isActiveColor}
                    className="w-100"
                    onClick={() =>
                      handleClickOpen(customer.id, customer.IsActive)
                    }
                  >
                    {isActiveText}
                  </Button>

                  <ActiveCustomerPopUp
                    open={open}
                    setOpen={setOpen}
                    handleClose={handleCloseEdit}
                    selectedOption={selectedOption}
                    handleOptionChange={handleOptionChange}
                    handleEditeIsActive={handleEditeIsActive}
                  />
                </td>
                <td>{`${customer.FirstName} ${customer.LastName}`}</td>
                <td>{customer.Telefon}</td>
                <td>{customer.Eposta}</td>
                <td>{customer.jop}</td>
                <td>{customer.CreatedDate}</td>
                <td>Test</td>
                <td>{customer.FromWhereHeardAboutUsId}</td>
                <td>
                  <ButtonGroup>
                    <Link
                      to={`${customer.id}/${customer.FirstName}-${customer.LastName}`}
                    >
                      <Button active={true} variant="info">
                        <FontAwesomeIcon icon={faEye} />
                      </Button>
                    </Link>
                    <Button
                      className="mr-2 ml-2"
                      active={true}
                      variant="warning"
                      onClick={() => handleOpenModal(customer.id)}
                    >
                      <FontAwesomeIcon icon={faUserEdit} />
                    </Button>
                    <EditeCustomerModal
                      customerIdFromTable={customer.id}
                      setOpenEdit={setOpenEdit}
                      openEdit={openEdit && customerId === customer.id}
                      handleClose={handleClose}
                      selectedOption={selectedOption}
                      handleOptionChange={handleOptionChange}
                      handleEditeIsActive={handleEditeIsActive}
                    />
                    <Button
                      active={true}
                      variant="danger"
                      onClick={() => handleDelete(customer.id)}
                    >
                      <FontAwesomeIcon icon={faUserMinus} />
                    </Button>
                  </ButtonGroup>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};
export default CustomerListTable;
