import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TourDetilsButtomSection from "./components/TourDetilsButtomSection";
import Header from "../../../common/components/headers/light.js";
import Footer from "../../../common/components/footers/FiveColumnWithInputForm.js";
import AnimationRevealPage from "../../../common/helpers/AnimationRevealPage.js";
import TourDetailsSideBar from "./components/sideBar/TourDetailsSideBar";
import TourDetailsSection from "./components/detials/TourDetailsSection.js";
import UseTourDetails from "../../../common/utils/functions/UseTourDetails";
import RoomDetails from "./components/RoomDetails.js";
import "../../../assets/styles/TourDetails.css";
const TourDetails = () => {
  /************** Show Up Satin Al *********** */
  const [showRoomDetails, setShowRoomDetails] = useState(false);
  const [adultCount, setAdultCount] = useState(2);

  const handleRoomDetailsClick = (event) => {
    event.preventDefault();
    setShowRoomDetails(true);
  };
  /********** Filter Price On Adult Count *********** */
  const handleAdultCountChange = (adultCount) => {
    setAdultCount(adultCount);
  };
  /************************************* */
  /****  Start Reseve Data From Database   **** */
  const { id } = useParams();
  const tour = UseTourDetails(id);
  if (!tour) {
    return <div>Loading...</div>;
  }
  /****  End Reseve Data From Database   **** */
  if (!tour) {
    return <div>Loading...</div>;
  }
  return (
    <AnimationRevealPage>
      <Header />
      <div className="mt-4">
        <div className="row hotel-top">
          {/********************************** Details Setion ********************************** */}
          <TourDetailsSection />
          {/************************ Side Bar Section ******************************** */}
          <TourDetilsButtomSection />
          <TourDetailsSideBar
            onRoomDetailsClick={handleRoomDetailsClick}
            adultCount={adultCount}
            onAdultCountChange={handleAdultCountChange}
          />
        </div>
        {/* <BuyButton /> */}
        {showRoomDetails && <RoomDetails adultCount={adultCount} />}
      </div>
      <Footer />
    </AnimationRevealPage>
  );
};

export default TourDetails;
