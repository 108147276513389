import jsPDF from "jspdf";
import { useQuery } from "react-query";
import html2canvas from "html2canvas";
import { fetchContactDetails } from "common/Api/SiteSettingsApi";

const toDataURL = (url) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );

export const generatePDF = async (selectedTours, tours) => {
  const doc = new jsPDF();

  // Filter only the selected tours' data based on selectedTours state
  const selectedToursData = tours.filter((tour) =>
    selectedTours.includes(tour.tourId)
  );

  // Assuming you have a way to render selected tours to a specific div or component
  // Here you would render that component or update your component to only show selected tours
  // For simplicity, let's assume you have a div with an id 'selected-tours' that shows the selected tours
  const parentDiv = document.querySelector(".hidden");
  parentDiv.classList.remove("hidden");
  const input = document.getElementById("selected-tours");
  if (!input || !parentDiv) {
    console.error("Element not found");
    return;
  }

  const options = {
    useCORS: true,
    onclone: (document) => {
      const images = document.getElementsByTagName("img");
      for (let i = 0; i < images.length; i++) {
        images[i].setAttribute("crossOrigin", "anonymous");
      }
    },
  };

  html2canvas(input, options).then(async (canvas) => {
    const imgData = canvas.toDataURL("image/png");

    // Add image to PDF
    const imgProps = doc.getImageProperties(imgData);
    const pdfWidth = doc.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    doc.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

    for (const tour of selectedToursData) {
      if (tour.photos && tour.photos.length > 0) {
        const firstPhotoUrl = tour.photos[0].file_path;
        try {
          const firstPhotoBase64 = await toDataURL(firstPhotoUrl);
          const yPos = 10; // Update this based on your layout needs
          doc.addPage();
          doc.addImage(firstPhotoBase64, "JPEG", 15, yPos, 180, 160); // Adjust size as needed
        } catch (error) {
          console.error("Error fetching tour photo:", error);
        }
      }
    }

    // Function to add footer with icons
    const addFooter = async () => {
      const pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      doc.setFontSize(10); // Adjust the font size as needed

      doc.text(` 5511663828`, 14, pageHeight - 10); // Adjust positioning as needed
      doc.text(` Suarelab.com`, 105, pageHeight - 10, null, null, "center"); // Center align
      doc.text(
        ` admin@suarelab.com`,
        200,
        pageHeight - 10,
        null,
        null,
        "right"
      ); // Right align

      const icon1Base64 =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEoSURBVEiJrdU7SgNRFIfxX3w1wcIEQStRSx9glT3oDrQVV2FAK7HWwlI3YSG4AAWzAVsVQSJWwQcxFuPgEHJnxpn5w6nm8n1zueeeO250ltHHR+B7qRziG09YqBp+gEGirlGrCr43BI9rtwr4GnoBwSsaZeA1dALwuI7KCDYy4PEuJorAx/Ag6pq0fP6uLZxb4b9/QasMHNopgq2ycJjHe0BwVoUATgOCL6xUIWiiG5DcoV6FZCcgGOAyIJnC5H8kFymSDlYTa1t4xhuOMZNHUJfetl+ig98UtXDyW1c00zIH5CzuUyRZdSXHqF8sKXnEdJ6d3BQU9LGUJSA6k/MCgnYeeDLbwvekNDxOAyfCY6WnohdwDvui8+n7u+3rVcCH0xQ9WiN7/wdg0b8RRvi3ygAAAABJRU5ErkJggg==";
      const icon2Base64 =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAHTSURBVEiJxdW9ahVREAfw311iLE0gBm0i2Fjo9QEEW9HSjxgjgoq9Yl5A4gNYWcU8gqSxMWgkSoziI9hoZxHD9Rr8gBCvxZk1m83u/SAB/3BYzsyc/39n9uwM/xENXMA8JnAQt3ELw2F7gvMROxCOYwkdXMEBPIt9B4sheDX2q2j2S34dP+LgctgeFsjzNRu+N7H/iele5DewVSC5iDF8rxDYwDguF2xb3URO43ch+CuGMFNBnq97UvnWC7ZfOFUmb+B96fDT8L3qIvAyYhZK9tWceCieR6R6LxdEF0P4LT7UZL0ZMY/xseQ7ii815/YXDbTsTv+E7WvYbU1GbNn+DVkm3fmRCuE2Rvt4wdEgK+MQjmXSVatCa48CMJ7hT42zIaXaCx1kdb4MazXOESmLXmhJ5ajCWoZPqlMcRKCqlG187uP83pC32THcKflWpJ9sRmoHVdjEI5zB2ZJvXmoh//DOznu8EPYl9f/Ai4ipbRVFNKVGlQetx5vf7yJwV3WzO1mTsWk72/UlqXztCvINHLa7XU/Vkee4Jg2PDl6HbbZC4EH4igOnJ3mOpvSB815THpnPpbk8FfsVXcpShwbOYU4a8MPSwL9pe+jPRczAQ3/f8BffGrdCcdNJugAAAABJRU5ErkJggg=="; // Replace with actual base64 string for Icon 2
      const icon3Base64 =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA3QAAAN0BcFOiBwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAF6SURBVFiF7Zc7bsJAEIY/W+noeN4hl0gBd6EHIZAtRJMbJEeiN+9TJFKqdMmkmUUTB5t1jHEBK4208szO98OuZ8eBiFDnCGul3wUADwBBEITAI9C+EvcNOIjIN3oIe8ABkCvZAei5F2BwZREWPgD4BEZGxL5C+N7AR8o+Osfq6AK7CuA7oKuMsXn+K2hSkQgLn6R8f4KnGtgBtheAb4GO5pye8J9cNDMiNiXgGwOfZcRkLo50YRtY/wO+BtqaI8qJy00SGxGrAvCVgcdnYs8mm2uilqeIFdDSNXOPeK9ftDAikpy4xMAXPrkLXUYi8g70FZQeCdDXmELDdwuawNDMX4EPtRegqb6hmZfegtgA3V//DIT6vAE0dB6qz21Fs+whjHIO3xJ4cgJ0vkzF2MNY+DWcedaAL7Usv60F3oVoauBlqqCzjRFxthRPLnwPOLP3QeZlVNt1bBuSquCnRBwbEtuSVdkNObNd0aD2pjQQkVrb8uD+bXjzAn4AQzjJFh8jv44AAAAASUVORK5CYII="; // Replace with actual base64 string for Icon 3
      const iconWidth = 5; // Smaller width
      const iconHeight = 5;
      const iconY = pageHeight - 14;
      // Adjust positioning (iconX, iconY) and size (iconWidth, iconHeight) as needed
      doc.addImage(icon1Base64, "PNG", 9, iconY, iconWidth, iconHeight); // Icon 1
      doc.addImage(icon2Base64, "PNG", 89, iconY, iconWidth, iconHeight); // Icon 2
      doc.addImage(icon3Base64, "PNG", 159, iconY, iconWidth, iconHeight); // Icon 3
    };

    addFooter();
    // Save the PDF
    doc.save("selected-tours.pdf");
    parentDiv.classList.add("hidden");
  });
};
