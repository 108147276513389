import { Form } from "react-bootstrap";
export default ({
  editWithCustomStyle,
  label,
  name,
  onChange,
  imageSrc,
  edit,
}) => {
  console.log("edit:", edit, "editWithCustomStyle:", editWithCustomStyle);

  if (edit) {
    return (
      <Form.Group className="mb-3 row">
        <div className="col-md-12 ">
          <Form.Label className="me-3">{label}</Form.Label>
          <Form.Control type="file" name={name} onChange={onChange} />
        </div>
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          {imageSrc && (
            <img
              src={imageSrc}
              alt="Background Image"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          )}
        </div>
      </Form.Group>
    );
  } else if (editWithCustomStyle) {
    return (
      <Form.Group>
        <div className="mb-4">
          <Form.Label className="">
            <strong>{label}</strong>
          </Form.Label>
          <Form.Control type="file" name={name} onChange={onChange} />
        </div>
        <div className="col-md-12 d-flex align-items-center justify-content-center mt-3">
          {imageSrc && (
            <img
              src={imageSrc}
              alt="Background Image"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          )}
        </div>
      </Form.Group>
    );
  }
  return (
    <Form.Group className="mb-3 row">
      <div className="col-md-6 d-flex align-items-center justify-content-center">
        <Form.Label className="me-3">{label}</Form.Label>
        <Form.Control type="file" name={name} onChange={onChange} />
      </div>
      <div className="col-md-6 d-flex align-items-center justify-content-center">
        {imageSrc && (
          <img
            src={imageSrc}
            alt="Background Image"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        )}
      </div>
    </Form.Group>
  );
};
