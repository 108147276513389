import React from "react";
import { Button, Form, Modal, Row, Col } from "react-bootstrap";

const FamilyModalEdit = ({
  show,
  handleClose,
  handleEdit,
  selectedCustomer,
}) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Düzenleme Detayları</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formBasicEmail">
            <Row>
              <Col xl={6} className="mb-2">
                <Form.Label>Adı</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={selectedCustomer.FirstName}
                />
              </Col>
              <Col xl={6} className="mb-2">
                <Form.Label>Soyadı</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={selectedCustomer.LastName}
                />
              </Col>
              <Col xl={6} className="mb-2">
                <Form.Label>Telefon Numarası</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={selectedCustomer.Telefon}
                />
              </Col>
              <Col xl={6} className="mb-2">
                <Form.Label>Cinsiyet</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={selectedCustomer.Gender}
                />
              </Col>
              <Col xl={6} className="mb-2">
                <Form.Label>Doğum Günü</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={selectedCustomer.BirthDay}
                />
              </Col>
              <Col xl={6}>
                <Form.Label>İş</Form.Label>
                <Form.Control type="text" defaultValue={selectedCustomer.Jop} />
              </Col>
              <Col xl={6}>
                <Form.Label>Akraba İlişkisi</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={selectedCustomer.RelativeRelation}
                />
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button active={true} variant="secondary" onClick={handleClose}>
          Kapat
        </Button>
        <Button active={true} variant="primary" onClick={handleEdit}>
          Değişiklikleri Kaydet
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FamilyModalEdit;
