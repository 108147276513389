import React, { useState, useEffect, Suspense, useContext } from "react";
import { axios } from "common/utils/axios";
import { useParams } from "react-router-dom";
import { CategoriesContext } from "redux/ContextApi/Contexts";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import DialogTitle from "@mui/material/DialogTitle";
import ButtonGroup from "@mui/material/ButtonGroup";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

export const PopUpToursInterested = () => {
  const { categories, isLoading } = useContext(CategoriesContext);
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [toursName, setToursName] = useState([]);
  const [selectedTours, setSelectedTours] = useState([]);
  const [value, setValue] = useState("1");
  const [tours, setTours] = useState([]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeMenuTab = (event, newValue) => {
    setValue(newValue);
    // Get the category name
    const categoryName = categories.find(
      (category) => category.id === newValue
    )?.category;
    if (categoryName) fetchTours(categoryName);
  };

  const handleTourSelection = (event) => {
    const tourId = Number(event.target.value);
    if (event.target.checked) {
      setSelectedTours((prevSelectedTours) => [...prevSelectedTours, tourId]);
    } else {
      setSelectedTours((prevSelectedTours) =>
        prevSelectedTours.filter((id) => id !== tourId)
      );
    }
  };

  useEffect(() => {
    axios
      .get("/api/get-active-tours")
      .then((response) => {
        const options = response.data.map((tour) => ({
          value: tour.tourId,
          label: tour.tourName,
        }));
        setToursName(options);
      })
      .catch((error) => console.log(error));
  }, []);

  // Tap Menu Functions

  const fetchTours = async (category) => {
    try {
      const response = await axios.get(`/api/get-active-tours`, {
        params: { category: category },
      });
      const data = response.data;
      setTours(data); // Update the tours state with the fetched data
    } catch (error) {
      console.error("Error fetching tours for category", error);
    }
  };

  const saveInterestedAdvertisements2 = async () => {
    axios
      .post(`/api/customer/${id}/tours`, {
        tourIds: selectedTours,
      })
      .then((response) => {
        console.log(response);
        alert("Interested advertisements saved successfully.");
        handleClose();
      })
      .catch((error) => {
        console.error(error);
        alert("Error saving interested advertisements.");
      });
  };

  const fetchCustomerTours = async () => {
    try {
      const response = await axios.get(`/api/customer/${id}/tours`);
      const tourIds = response.data.tours.map((tour) => tour.id);
      setSelectedTours(tourIds);
    } catch (error) {
      console.error("Error fetching customer's tours", error);
    }
  };
  useEffect(() => {
    fetchCustomerTours();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (!categories) {
    return <div>No categories available</div>;
  }
  return (
    <div>
      <ButtonGroup>
        <Button
          onClick={handleClickOpen}
          variant="info"
          style={{
            background: "#d39e00",
            fontSize: "20x",
            height: "28px",
            width: "24px",
            padding: "2px",
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      </ButtonGroup>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>İLGİLENDİĞİ Aktiv Turlar </DialogTitle>
        <DialogContent sx={{ width: "100%", maxWidth: "none" }}>
          <div className="row mb-12 d-flex">
            <Autocomplete
              multiple
              id="tours-autocomplete"
              value={toursName.filter((option) =>
                selectedTours.includes(option.value)
              )}
              onChange={(event, newValue) => {
                setSelectedTours(newValue.map((item) => item.value));
              }}
              options={toursName}
              getOptionLabel={(option) => option.label}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip label={option.label} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} label="Tour Names" />
              )}
            />
          </div>
          {/** */}
          <div className="mb-4">
            <Box
              className="parent"
              sx={{
                width: "100%",
                typography: "body1",
                backgroundColor: "white",
                borderRadius: "8px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                marginBottom: "20px",
              }}
            >
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChangeMenuTab}
                    aria-label="lab API tabs example"
                  >
                    {categories.map((category) => (
                      <Tab
                        label={category.category}
                        value={category.id}
                        key={category.id}
                      />
                    ))}
                  </TabList>
                </Box>
                <Suspense fallback={<div>Loading...</div>}>
                  {categories.map((category) => (
                    <TabPanel value={category.id} key={category.id}>
                      {tours.map((tour) => (
                        <div
                          key={tour.tourId}
                          className="d-flex justify-algin-center mb-2"
                        >
                          <input
                            className="mr-2"
                            type="checkbox"
                            value={tour.tourId}
                            checked={selectedTours.includes(tour.tourId)}
                            onChange={handleTourSelection}
                          />
                          {tour.tourName}
                        </div>
                      ))}
                    </TabPanel>
                  ))}
                </Suspense>
              </TabContext>
            </Box>
            <Suspense fallback={<div>Loading...</div>}></Suspense>
          </div>
          {/** */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Kapat</Button>
          <Button onClick={saveInterestedAdvertisements2}>Kaydet</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default PopUpToursInterested;
