import React from "react";

const getCurrentDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};
export const DateInput = (props) => {
  return (
    <div>
      <input
        min={getCurrentDate()}
        name={props.name}
        onChange={props.onChange}
        value={props.value}
        required
        type="date"
        style={{
          borderRadius: "8px",
          fontSize: "20px",
          padding: "10px",
        }}
      />
    </div>
  );
};
export default DateInput;
