import { generatePDF } from "common/helpers/generatePDF";
import schedule from "assets/icons/schedule.png";
import TCompany from "assets/icons/TCompany.png";
import bus from "assets/icons/bus.png";
import airplan from "assets/icons/airplan.png";
import money from "assets/icons/money.png";
import { fetchContactDetails } from "common/Api/SiteSettingsApi";
import transportaion2 from "assets/icons/transportaion2.png";
import { useQuery } from "react-query";
import { Card, Col, Row } from "react-bootstrap";
import { axios } from "common/utils/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "assets/styles/PDFTemplate.css";
import {
  FetchTourImagebase64,
  ComponentUsingLogo,
} from "./FetchTourImagebase64";
import {
  faSquarePhoneFlip,
  faEnvelope,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

export const PDFTemplateTours = ({
  isActive = false,
  getCurrencySymbol,
  selectedTours,
  tours,
  tourData,
  getPeopleIcon,
}) => {
  const { data: contactData } = useQuery(
    "contactDetails",
    fetchContactDetails,
    {
      staleTime: 3 * 24 * 60 * 60 * 1000, // 3 days in milliseconds
      refetchOnWindowFocus: true, // Refetch when window gains focus
      onSuccess: (data) => {
        console.log("Data fetched successfully:", data);
      },
    }
  );
  console.log(contactData, "contactDatacontactData");
  return (
    <div className="hidden">
      <div
        className="p-4"
        id="selected-tours"
        style={{
          display: selectedTours?.length ? "block" : "none",
        }}
      >
        <div className="d-flex justify-content-center">
          <ComponentUsingLogo />
        </div>
        {selectedTours?.map((tourId) => {
          const tour = tours.find((t) => t.tourId === tourId); // Assuming 'tours' is your array of all tours
          const imageId = tour?.photos[0]?.id;
          return (
            <Card className="rounded-0 mb-4">
              <Card.Header>
                <Row>
                  <Col md={10} sm={6} xs={11}>
                    <h6 className="monsterratFont w600 grayC pt-1">
                      {tour.tourName}
                    </h6>
                  </Col>
                  <Col
                    md={2}
                    sm={6}
                    xs={1}
                    className="d-flex justify-content-end"
                  >
                    <div className="checkbox-container"></div>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="cardBodyStyle">
                <Row key={tourId} className="monsterratFont grayC">
                  <Col md={4} xl={4} xs={12}>
                    <FetchTourImagebase64 imageId={imageId} />
                  </Col>

                  <Col md={3} xl={3} xs={6} className="d-none d-md-block">
                    <ul
                      style={{
                        display: "flex",
                        alignItems: "center",
                        listStyle: "none",
                        padding: 0,
                      }}
                    >
                      <li className="d-flex align-items-center">
                        <img
                          style={{ height: "35px", marginRight: "10px" }}
                          src={schedule}
                          alt="Schedule Icon"
                        />
                        <span>Tur Tarihleri</span>
                      </li>
                    </ul>
                    <ul
                      style={{
                        fontSize: "15px",
                        marginBottom: "12px",
                        marginTop: "25px ! important",
                        paddingLeft: "0 !important",
                      }}
                    >
                      <li className="mb-2">
                        Gidiş :{" "}
                        <span style={{ fontSize: "15px" }} className="fb">
                          {" "}
                          {tour?.startingDate}
                        </span>{" "}
                      </li>

                      <li className="">
                        Dönüş :
                        <span className="fb " style={{ fontSize: "15px" }}>
                          {tour?.endingDate}
                        </span>
                      </li>
                    </ul>
                    <ul
                      style={{
                        display: "flex",
                        alignItems: "center",
                        listStyle: "none",
                        padding: 0,
                        border: "1px solid rgb(0 0 0 / 36%)",
                      }}
                    >
                      <li style={{ marginRight: "10px" }}>
                        <img
                          className="soponsor-icon"
                          style={{ height: "35px" }}
                          src={TCompany}
                          alt="TCompany Logo"
                        />
                      </li>
                      <li className="soponsor" style={{ marginRight: "10px" }}>
                        Firma :
                      </li>
                      <li>
                        {tour?.company_logo && tour?.company_logo.base64 ? (
                          <img
                            className="sponsor-image"
                            src={tour?.company_logo.base64}
                            alt={`${tour?.company_name} Logo`}
                          />
                        ) : (
                          <span>{tour?.company_name || "No Sponsor"}</span>
                        )}
                      </li>
                    </ul>
                  </Col>
                  <Col md={2} xl={2} xs={6} className="d-none d-md-block">
                    <ul
                      style={{
                        display: "flex",
                        alignItems: "center",
                        listStyle: "none",
                        padding: 0,
                      }}
                    >
                      <li className="d-flex align-items-center justify-content-center">
                        {" "}
                        <img
                          style={{ height: "35px", marginRight: "10px" }}
                          src={transportaion2}
                        />
                        <span>Güzergah</span>
                      </li>
                    </ul>
                    <ul style={{ paddingLeft: "0 !important" }}>
                      <li
                        className="grayC fb "
                        style={{
                          fontSize: "15px",
                          marginBottom: "10px",
                          paddingTop: "5px",
                        }}
                      >
                        Istanbul{" "}
                        <img
                          src={bus}
                          alt="Bus"
                          style={{
                            display: "inline",
                            verticalAlign: "middle",
                            height: "1em",
                            marginLeft: "0.1rem",
                            marginRight: "0.1rem",
                          }}
                        />{" "}
                        Meke
                      </li>
                      <li
                        className="grayC fb"
                        style={{ fontSize: "15px", marginBottom: "10px" }}
                      >
                        Meke
                        <img
                          src={airplan}
                          alt="Bus"
                          style={{
                            display: "inline",
                            verticalAlign: "middle",
                            height: "1em",
                            marginLeft: "0.2rem",
                            marginRight: "0.2rem",
                          }}
                        />
                        Medine
                      </li>
                      <li style={{ fontSize: "15px" }} className="grayC fb">
                        Medine
                        <img
                          src={bus}
                          alt="Bus"
                          style={{
                            display: "inline",
                            verticalAlign: "middle",
                            height: "1em",
                            marginLeft: "0.2rem",
                            marginRight: "0.2rem",
                          }}
                        />
                        Istanbul
                      </li>
                    </ul>
                  </Col>
                  <Col md={3} xl={3} className="d-none d-md-block">
                    <ul>
                      <li className="d-flex justify-content-center">
                        <img style={{ height: "40px" }} src={money} />
                      </li>
                    </ul>
                    <ul className="pt-2">
                      <Row className="monsterratFont">
                        {tour?.pricing.map((row) => (
                          <Col md={4} xs={4} xxl={4}>
                            <li className="d-flex justify-content-center">
                              <img
                                src={getPeopleIcon(row.howManyPeople)}
                                alt="People Icon"
                              />
                            </li>
                            <li className="d-flex justify-content-center">
                              {row.howManyPeople} kişi
                            </li>
                            <li
                              style={{ fontSize: "1.15rem" }}
                              className="greenC fb barlow d-flex justify-content-center"
                            >
                              {Number(row.mainPrice)}{" "}
                              {getCurrencySymbol(row.pricingType)}
                            </li>
                          </Col>
                        ))}
                      </Row>
                    </ul>
                  </Col>
                  {/* Add more details as needed */}
                </Row>
              </Card.Body>
            </Card>
          );
        })}
        <div>
          {/*
        <div className="d-flex justify-content-between ">
        <span>
        <FontAwesomeIcon icon={faSquarePhoneFlip} />{" "}
        {contactData?.data?.telefon}
        </span>
        <span>
        <FontAwesomeIcon icon={faGlobe} /> Suarelab.com
            </span>
            <span>
            <FontAwesomeIcon icon={faEnvelope} /> {contactData?.data?.email}
            </span>
            </div>
          */}
        </div>
      </div>
    </div>
  );
};
