import React, {useState} from "react";

export default function OtelsRoomsComponent({setRooms, rooms}) {
	const [room, setRoom] = useState();
	const handleRoom = (e, type) => {
		switch (type) {
			case "room_name":
				setRoom({
					room_name: e.target.value,
					room_picture: room?.room_picture,
				});
				break;
			case "room_picture":
				setRoom({
					room_name: room?.room_name,
					room_picture: e.target.value,
				});
				break;
			default:
				break;
		}
	};

	const handleRoomAdd = () => {
		setRooms([...rooms, room]);
		setRoom({
			room_name: "",
			room_picture: "",
		});
	};

	const deleteRoom = (e) => {
		console.log(e.target.value);
		let arr = rooms;
		arr.splice(e.target.value, 1);
		setRooms([...arr]);
	};
	return (
		<div>
			<label>Oda ismi</label>
			<input
				type="text"
				value={room?.room_name}
				onChange={(e) => handleRoom(e, "room_name")}
			/>
			<br />
			<label>Oda ressim linki</label>
			<input
				type="text"
				value={room?.room_picture}
				onChange={(e) => handleRoom(e, "room_picture")}
			/>
			<br />
			<button onClick={handleRoomAdd}>add</button>
			<br />
			{rooms?.map((item, index) => {
				return (
					<div key={index}>
						<span>{item.room_name}</span>/<span>{item.room_picture}</span>{" "}
						<br />
						<button
							value={index}
							onClick={deleteRoom}
							className="text-red-500">
							X
						</button>
					</div>
				);
			})}
		</div>
	);
}
