import { useState, useEffect } from "react";
import { axios } from "common/utils/axios";
import { useParams } from "react-router-dom";
import SelectInput from "../Components/SelectInput";
import DateInput from "../Components/DateInput";
import RadioInput from "../Components/RadioInput";
import UploadFileInput from "../Components/uploadFileInput";
import { Button, Card, Form, Row, Col } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";

function EditCustomer({ customerId }) {
  const { id: paramId } = useParams();
  const idToUse = paramId || customerId;
  const [registaryTypeData, setRegistaryTypeData] = useState([]);
  const [heardData, setHeards] = useState([]);
  const [districtsData, setDistricts] = useState([]);

  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    telefon: "",
    jop: "",
    faceBook: "",
    instagram: "",
    tweeter: "",
    eposta: "",
    adres: "",

    registrationTypeId: "",
    birthDate: "",
    gender: "",
  });
  useEffect(() => {
    axios
      .get(`api/customers/${idToUse}`)
      .then(({ data }) => {
        const {
          FirstName,
          LastName,
          Telefon,
          jop,
          faceBook,
          Instagram,
          tweeter,
          Eposta,
          Adres,
          RegistrationTypeId,
          FromWhereHeardAboutUsId,
          TownId,
          CityId,
          birthDate,
          Gender,
        } = data;
        setFormState({
          firstName: FirstName,
          lastName: LastName,
          telefon: Telefon,
          jop: jop,
          faceBook: faceBook,
          instagram: Instagram,
          tweeter: tweeter,
          eposta: Eposta,
          adres: Adres,
          registrationTypeId: RegistrationTypeId,
          fromWhereHeardAboutUsId: FromWhereHeardAboutUsId, // Set the initial value here
          townId: TownId,
          cityId: CityId,
          birthDate: birthDate,
          gender: Gender,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [idToUse]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    axios
      .put(`customers/update/${idToUse}`, formState)
      .then(({ data }) => {
        console.log(data);
        toast.success("Update successful"); // Display success toast
      })
      .catch((error) => {
        console.log(error);
        toast.error("Update failed"); // Display error toast
      });
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);

      axios
        .post("/api/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setFormState({
            ...formState,
            [name]: response.data.fileUrl, // save the file URL returned by the server in your customer data
          });
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        });
    }
  };

  const fields = [
    { label: "First Name", name: "firstName" },
    { label: "Last Name", name: "lastName" },
    { label: "Telefon", name: "telefon" },
    { label: "Jop", name: "jop" },

    { label: "Eposta", name: "eposta" },
    { label: "Adres", name: "adres" },
  ];
  useEffect(() => {
    axios
      .get("/api/registarationtype")
      .then((response) => setRegistaryTypeData(response.data))
      .catch((error) => console.error(error));
  }, []);
  //                        API Area                     //

  //                      API   Customers               //
  useEffect(() => {
    axios
      .get("/api/heard")
      .then((response) => setHeards(response.data))
      .catch((error) => console.error(error));
  }, []);
  return (
    <Card
      style={{
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Card.Body>
        <Form onSubmit={handleFormSubmit}>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>{fields[0].label}:</Form.Label>
                <Form.Control
                  type="text"
                  name={fields[0].name}
                  value={formState[fields[0].name] || ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>{fields[1].label}:</Form.Label>
                <Form.Control
                  type="text"
                  name={fields[1].name}
                  value={formState[fields[1].name] || ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>{fields[4].label}:</Form.Label>
                <Form.Control
                  type="text"
                  name={fields[4].name}
                  value={formState[fields[4].name] || ""}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{fields[5].label}:</Form.Label>
                <Form.Control
                  type="text"
                  name={fields[5].name}
                  value={formState[fields[5].name] || ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>{fields[2].label}:</Form.Label>
                <Form.Control
                  type="text"
                  name={fields[2].name}
                  value={formState[fields[2].name] || ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          {/* Render other form inputs here */}
          <Button active={true} type="submit">
            Update
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
}

export default EditCustomer;
