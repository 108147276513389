import React, { useContext } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import EmailIllustrationSrc from "../../../assets/images/email-illustration.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactDataContext from "redux/ContextApi/ContactDataContext";
import "assets/styles/ContactSection.css";
import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

export default ({
  subheading = "İletişim Bilgileri",
  heading = (
    <>
      "Bizimle{" "}
      <span style={{ color: "rgb(0, 109, 119)" }}>iletişime geçmekten</span>{" "}
      çekinmeyin."
    </>
  ),
  description = "",
  submitButtonText = "Gönder",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  const contactData = useContext(ContactDataContext);
  const googleMapUrl = contactData?.data?.google_maps_intergration;
  return (
    <>
      <Row className="contact-info-section mt-4 mb-4 d-felx justify-content-center">
        <Col md={4} className="contact-info-col text-center mb-3">
          <FontAwesomeIcon
            icon={faMapMarkerAlt}
            style={{ color: "rgb(0, 109, 119)" }}
            className="contact-info-icon"
          />
          <h6>Adres</h6>
          <a href={contactData?.data.address_link} target="blank">
            <p>{contactData?.data.open_address}</p>
          </a>
        </Col>

        <Col md={4} className="contact-info-col text-center mb-3">
          <FontAwesomeIcon
            style={{ color: "rgb(0, 109, 119)" }}
            icon={faEnvelope}
            className="contact-info-icon"
          />
          <h6>E-posta </h6>
          <p>{contactData?.data.email}</p>
        </Col>
        <Col md={3} className="contact-info-col text-center mb-3">
          <FontAwesomeIcon
            style={{ color: "rgb(0, 109, 119)" }}
            icon={faPhone}
            className="contact-info-icon"
          />
          <h6>Telefon</h6>
          <p>{contactData?.data.telefon}</p>
        </Col>
      </Row>
      <Row className="mb-4 mt-4">
        <Col
          md={5}
          className="d-flex align-items-center justify-content-center"
        >
          {googleMapUrl ? (
            <iframe
              src={googleMapUrl}
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          ) : (
            // Display a placeholder or alternative content if no URL is provided
            <div>No Map Available</div>
          )}
        </Col>
        <Col md={7} className={`mt-4 ${textOnLeft ? "" : "order-md-first"}`}>
          <div className="text-center text-md-left">
            {subheading && <h5>{subheading}</h5>}
            <h3>{heading}</h3>
            {description && <p>{description}</p>}
            <div className="d-flex justify-content-center">
              <Form
                action={formAction}
                method={formMethod}
                style={{ maxWidth: "85%" }}
                className="w-100" // Ensures the form takes the full width of the container
              >
                <Form.Group className="mb-2">
                  <Form.Control type="" name="name" placeholder="Adı Soyadı" />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Control
                    type=""
                    name="telefon"
                    placeholder="Telefon No"
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="E-posta"
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Control type="" name="subject" placeholder="Konu" />
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    as="textarea"
                    name="message"
                    placeholder="Mesajınızı Buraya Girin"
                    rows={4}
                  />
                </Form.Group>
                <div className="d-flex justify-content-center">
                  <Button
                    variant="primary"
                    type="submit"
                    className="mt-4"
                    style={{
                      BackgroundColor: "rgb(0, 109, 119)",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                    }}
                  >
                    {submitButtonText}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
