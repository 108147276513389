import React, { useState } from "react";
import { Input, Button } from "@mui/material";

function UploadFileInput(props) {
  const [fileName, setFileName] = useState("");

  const handleFileChange = (e) => {
    props.onChange(e); // call the parent's onChange method
    setFileName(e.target.files[0].name); // set the file name
  };

  return (
    <div>
      <Input
        type="file"
        name={props.name}
        onChange={handleFileChange}
        inputProps={{ accept: "image/*" }}
      />
      {fileName && <p>{fileName}</p>}
    </div>
  );
}

export default UploadFileInput;
