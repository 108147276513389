import React from "react";

import ToursCreateModal2 from "../ServiceManagement/ServiceToursComponents/ToursCreateModal2";
import ToursGridModal2 from "../ServiceManagement/ServiceToursComponents/ToursGridModal2";
/* ******************************************* */
/*Sending data to dataBase */
const Tours2 = () => {
  return (
    <div>
      <ToursCreateModal2 />
      <hr></hr>
      <ToursGridModal2 />
    </div>
  );
};
export default Tours2;
