import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { CategoriesContext } from "redux/ContextApi/Contexts";
import { HeaderHeightContext } from "layouts/GlobalLayout";
import HeaderContext from "redux/ContextApi/HeaderContext .jsx";
import "assets/styles/ToursPage.css";

export const ToursPage = () => {
  const { setTitle, setImageUrl } = useContext(HeaderContext);
  const { categories, isLoading } = useContext(CategoriesContext);
  const headerHeightContext = useContext(HeaderHeightContext);
  const {
    setHeaderHeight,
    setHeaderTitlePaddingTop,
    setHeaderTitlePaddingBottom,
  } = headerHeightContext;
  useEffect(() => {
    setImageUrl("https://backend.suarelab.com/api/about-us/images-id/1");
    // Clean up function to reset the title and image URL when the component unmounts
    return () => {
      setTitle("");
      setImageUrl("");
    };
  }, [setTitle, setImageUrl]);

  useEffect(() => {
    setHeaderHeight("0px"); // Set the header height to 200px
    setHeaderTitlePaddingTop("0px");
    setHeaderTitlePaddingBottom("0px");
    return () => {
      setHeaderHeight(null);
      setHeaderTitlePaddingBottom(null);
      setHeaderTitlePaddingTop(null); // Reset the header height when component unmounts
    };
  }, [setHeaderHeight]);

  return (
    <div style={{ marginTop: "130px" }}>
      <div></div>
      <h2
        className="mt-4 mb-4 pl-4 d-flex justify-content-center"
        style={{ fontSize: "40px", fontWeight: "bold" }}
      >
        Turlar
      </h2>
      <div className="container my-4">
        <div className="row justify-content-center">
          {categories &&
            categories?.map((category) => (
              <div
                key={category.id}
                className="col-md-4 col-sm-6 col-xs-12 my-4"
              >
                <Link
                  to={`/tours/${category.category}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                  className="d-block text-center category-link"
                >
                  {category.photo && (
                    <div
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        borderRadius: "8px",
                      }}
                      className="category-image-container"
                    >
                      <img
                        src={`https://backend.suarelab.com/api/${category.photo}`}
                        alt={category.category}
                        className="img-fluid hover-img img-category"
                      />
                      <div
                        style={{}}
                        className="hover-overlay title-categpry"
                      ></div>
                      <div className="category-label">
                        <span className="category-text">
                          {category.category}
                        </span>
                      </div>
                    </div>
                  )}
                </Link>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ToursPage;
