import React from "react";
import AccommodationInformation from "./AccommodationInformation";
import InformationofAccommodationPersons from "./InformationofAccommodationPersons";
import ReservationandBillingInformation from "./ReservationandBillingInformation";
import ReservationNote from "./ReservationNote";
import TotalFee from "./TotalFee";
import { SideBar } from "../SideBar";
import { Wizard } from "../Wizard";
export const InformationsMainContent = () => {
  return (
    <div>
      <div className="main-content-bottom">
        <div className="row">
          <div className="col-12 col-md-8 content-body">
            {/******************************************** */}
            <Wizard currentStep={0} />
            <AccommodationInformation />
            <InformationofAccommodationPersons />
            <ReservationandBillingInformation />
            <ReservationNote />
            <TotalFee />
          </div>
          {/*********************************************************** */}{" "}
          <div class="col-12 col-md-3 mb-4">
            <SideBar />
          </div>
        </div>
      </div>
    </div>
  );
};
export default InformationsMainContent;
