import React, {useCallback} from "react";
import {GoogleMap, LoadScript, Marker} from "@react-google-maps/api";

export default function MapComponent({
	editPositionX,
	editPositionY,
	setOtelPositionX,
	setOtelPositionY,
}) {
	const containerStyle = {
		width: "auto",
		height: "200px",
	};
	const center = {
		lat: Number(editPositionX),
		lng: Number(editPositionY),
	};

	const handleDrag = (e) => {
		console.log("dragged", e);
		setOtelPositionX(e.latLng.lat());
		setOtelPositionY(e.latLng.lng());
	};
	const Gmap = useCallback(() => {
		return (
			<LoadScript googleMapsApiKey="AIzaSyB7XX2tMXlrB0G-4larvlaxYgceY8zGeA4">
				<GoogleMap
					mapContainerStyle={containerStyle}
					center={center}
					zoom={4}>
					<Marker
						draggable={true}
						onDragEnd={handleDrag}
						position={center}
					/>
				</GoogleMap>
			</LoadScript>
		);
	}, []);
	return (
		<div>
			<label>Konumu Sürükle</label>
			<Gmap />
		</div>
	);
}
