import GlobalLayout from "layouts/GlobalLayout";
import PostPage from "pages/Blog/PostPage";
import BlogList from "pages/Blog/BlogList";
import QS from "pages/QA/QS";
import ResetPassword from "pages/ResetPassword";
import UserOtels from "area/user/Public/UserOtels/Otels";
import UserOtel from "area/user/Public/UserOtels/OtelSingle";
import UserTours from "area/user/Public/UserTours/Tours";
import UserToursPage from "area/user/Public/UserTours/ToursPage";
import HotelTravelLandingPage from "../pages/HomePage/HotelTravelLandingPage";
import Otels from "pages/OtelsPage/Otels.js";
import TwoColContactUsWithIllustrationFullForm from "../pages/ContactUs/ContactUs";
import AboutUS from "pages/AboutUs/AboutUs";
import LoginPage from "pages/Login.js";
import SignupPage from "pages/Signup.js";
import TourDetails from "pages/TourList/TourDetails/TourDetails";
import OtelSingle from "pages/OtelsPage/OtelSingle";
import Tours from "pages/Tours/Tours";
import ToursPage from "pages/Tours/ToursPage";
import OnlineRezervasyon from "../pages/OnlineRezervasyon/OnlineRezervasyon";
import ToursTamplate from "pages/Tours/ToursTemplate/ToursTamplate";
import Login from "pages/AuthPages/Login";
//New 2023 / 11 / 6

import NewToursPage from "pages/NewTour/NewToursPage";
import NewTourDetails from "pages/NewTour/NewTourDetails";
import ToursList from "pages/TourTest";
import UserHomePage from "pages/HomePage/UserHomePage";
export const GlobalRoutes = [
  // {
  //   path: "/",
  //   element: <HotelTravelLandingPage />,
  // },
  {
    path: "/",
    element: <GlobalLayout />,
    children: [
      {
        path: "/",
        element: <HotelTravelLandingPage />,
      },
      {
        path: "contactUs",
        element: <TwoColContactUsWithIllustrationFullForm />,
      },
      {
        path: "QA",
        element: <QS />,
      },
      {
        path: "about-us",
        element: <AboutUS />,
      },
      {
        path: "Blog",
        element: <BlogList />,
      },
      {
        path: "blog/post/:slug",
        element: <PostPage />,
      },
      {
        path: "tours",
        element: <ToursPage />,
      },
      {
        path: "tour/:id",
        element: <TourDetails />,
      },
      {
        path: "categories/:slug/tours",
        element: <Tours />,
      },
      {
        path: "otels",
        element: <Otels />,
      },
      {
        path: "otel/:hotelName",
        element: <OtelSingle />,
      },
      {
        path: "tours/:categoryName/:tourName",
        element: <NewTourDetails />,
      },
      {
        path: "tours/:categoryName",
        element: <NewToursPage />,
      },
    ],
  },

  /**************************************** */

  /********************************************* */
  {
    path: "/tour-test",
    element: <ToursList />,
  },
  {
    path: "/resetPassword",
    element: <ResetPassword />,
  },

  // {
  //   path: "/categories/:slug/tours",
  //   element: <Tours />,
  // },

  {
    path: "/tour-reservation",
    element: <OnlineRezervasyon />,
  },

  {
    path: "/Login",
    element: <LoginPage />,
  },
  {
    path: "/auth/login",
    element: <LoginPage />,
  },
  {
    path: "/Signup",
    element: <SignupPage />,
  },
  {
    path: "/Signup",
    element: <SignupPage />,
  },
  {
    path: "/tourstamplate",
    element: <ToursTamplate />,
  },
  {
    path: "/login2",
    element: <Login />,
  },
  {
    path: "/resetPassword",
    element: <ResetPassword />,
  },

  // {
  //   auth: "user",
  //   path: "/user/otels/:id",
  //   element: <UserOtel />,
  // },

  // {
  //   path: "/categories/:slug/tours",
  //   element: <Tours />,
  // },

  {
    path: "/",
    element: <HotelTravelLandingPage />,
  },

  {
    path: "/tour-reservation",
    element: <OnlineRezervasyon />,
  },
  {
    path: "/Login",
    element: <LoginPage />,
  },
  {
    path: "/Signup",
    element: <SignupPage />,
  },
  {
    path: "/Signup",
    element: <SignupPage />,
  },
  {
    path: "/tourstamplate",
    element: <ToursTamplate />,
  },
  {
    path: "/login2",
    element: <Login />,
  },
  {
    path: "/newtours",
    element: <NewToursPage />,
  },
];
