import { useState } from "react";
import { Card, Table } from "react-bootstrap";
import { useQuery } from "react-query";
import * as api from "common/Api/NetGsmCalles";
import ActionButtonsCallerTable from "area/admin/Dashboard/components/ActionButtonsCallerTable";
import { Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
export default () => {
  const [currentPage, setCurrentPage] = useState(1);
  const MAX_PAGES_TO_SHOW = 15;
  const { data, isLoading, isError } = useQuery(
    ["calles", currentPage],
    () => api.getCalle(currentPage),
    {
      staleTime: 1000 * 60 * 5,
    }
  );
  if (isLoading) return "Loading...";
  if (isError) return "Error fetching data.";
  console.log(data.data, " calles data");
  const getBackgroundColor = (scenario) => {
    switch (scenario) {
      case "Hangup":
        return "#ff6c6c";
      case "InboundtoPBX":
        return "#dd98dd";
      case "Answer":
        return "#9ad99a";
      case "Outbound_call":
        return "#2bb8f6fc";
      case "Queue":
        return "#b4aeae";
      case "QueueLeave":
        return "#ffd866";
      default:
        return ""; // default color or any fallback color
    }
  };
  const scenarioNameConverstion = (scenario) => {
    switch (scenario) {
      case "Answer":
        return "Cevap Verdi";
      case "Hangup":
        return "Kapatı";
      case "QueueLeave":
        return "Cevapsız çağrı";
      case "InboundtoPBX":
        return "Cevap makinesi";
      case "InboundtoPBX":
        return "Cevap makinesi ";
      case "Inbound_call":
        return "Kapat düğmesi";
      case "Queue":
        return "Queue";
      case "Outbound_call":
        return "Giden arama ";
    }
  };
  return (
    <li className="mb-4">
      <Card>
        <Card.Header>Sistemdeki Son Çağrılar</Card.Header>
        <Card.Body>
          {" "}
          <Table bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr style={{ fontSize: "12px" }}>
                <th>Operatör No</th>
                <th>Müşteri No</th>
                <th>Müşteri Adı Soyadı</th>
                <th>E-posta</th>
                <th>Senaryo</th>
                <th>Tarih - Saat</th>
                <th style={{ width: "150px" }}>Eylem</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.data.map((callData) => (
                  <tr
                    key={callData.id}
                    style={{
                      fontSize: "13px",

                      textDecoration: "none",
                    }}
                  >
                    <td>{callData.pbx_num}</td>
                    <td>{callData.customer_num}</td>
                    <td>
                      {callData.userId ? (
                        <Link
                          to={`/admin/customerManagement/customers/${callData.userId}`}
                        >
                          {callData.userName}
                        </Link>
                      ) : (
                        callData.userName
                      )}
                    </td>

                    <td>{callData.userEposta}</td>
                    <td
                      style={{
                        backgroundColor: getBackgroundColor(callData.scenario),
                      }}
                    >
                      {scenarioNameConverstion(callData.scenario)}
                    </td>
                    <td>{callData.formatted_date}</td>
                    <td>
                      <ActionButtonsCallerTable />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <Pagination>
          <Pagination.Prev
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          />

          {Array.from({
            length: Math.min(data.last_page, MAX_PAGES_TO_SHOW),
          }).map((_, idx) => (
            <Pagination.Item
              key={idx + 1}
              active={idx + 1 === currentPage}
              onClick={() => setCurrentPage(idx + 1)}
            >
              {idx + 1}
            </Pagination.Item>
          ))}

          {data.last_page > MAX_PAGES_TO_SHOW && <Pagination.Ellipsis />}

          <Pagination.Next
            disabled={currentPage === data.last_page}
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, data.last_page))
            }
          />
        </Pagination>
      </div>
    </li>
  );
};
