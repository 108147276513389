import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { axios } from "common/utils/axios";
import { Table, Container, Button, Icon } from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { faTrash, faEye, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreateButtonComponent from "area/admin/CRUD/CreateButtonComponent";
function ReservationCustomerMangmentList() {
  const { id } = useParams();
  const [reservations, setReservations] = useState([]);
  const [tourNames, setTourNames] = useState({});

  useEffect(() => {
    const url = `/api/customers/${id}/reservations`;

    axios
      .get(url)
      .then((response) => {
        setReservations(response.data);
      })
      .catch((error) => {
        console.error(
          `There was an error retrieving the reservation data: ${error}`
        );
      });
  }, [id]);

  const handleDelete = (reservationId) => {
    const url = `api/reservation_management/${reservationId}`;

    axios
      .delete(url)
      .then(() => {
        setReservations(
          reservations.filter((reservation) => reservation.id !== reservationId)
        );
      })
      .catch((error) => {
        console.error(`There was an error deleting the reservation: ${error}`);
      });
  };
  useEffect(() => {
    const fetchTourNames = async () => {
      try {
        const response = await axios.get("/api/tours");
        const tours = response.data;
        const tourNamesMap = {};

        // Map tour IDs to their respective names
        tours.forEach((tour) => {
          tourNamesMap[tour.id] = tour.title;
        });

        setTourNames(tourNamesMap);
      } catch (error) {
        console.error(`There was an error retrieving the tour names: ${error}`);
      }
    };

    fetchTourNames();
  }, []);
  const card = (
    <React.Fragment>
      <CardContent>
        <Typography sx={{ fontSize: 20 }} color="text.secondary" gutterBottom>
          Reservazyon
        </Typography>
      </CardContent>
      <CardActions>
        {/**
      <Link key="one" to={`CreateReservation`}>
    */}
        <Button
          size="medium"
          style={{ backgroundColor: "DodgerBlue", color: "#fff" }}
        >
          Yeni Reservazyon oluştur
        </Button>
        {/**
    </Link>
  */}
      </CardActions>
    </React.Fragment>
  );
  console.log(reservations, "reservations");
  return (
    <div>
      <Card variant="outlined">{card}</Card>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#Rezervasyon Kodu</th>
            <th>Tur</th>
            <th>Durum</th>
            <th>Fiyatı</th>
            <th>Kalan</th>
            <th>Rezervasyon Tarihi</th>
            <th>Son Ödeme Tarihi</th>
            <th>Note</th>
            <th>Eylem</th>
          </tr>
        </thead>
        <tbody>
          {reservations.map((reservation, index) => (
            <tr key={index}>
              <td>{reservation.code}</td>
              <td>{reservation.tour.tourName}</td>
              <td>{reservation.durum}</td>
              <td>{reservation.price}</td>
              <td>
                {reservation.restPrice === "0" || reservation.restPrice === ""
                  ? "Kalan Yok"
                  : reservation.restPrice}
              </td>
              <td>
                {new Date(reservation.reservation_date).toLocaleDateString()}
              </td>
              <td>
                {reservation.payment_deadline === "0000-00-00" ||
                reservation.payment_deadline == null
                  ? "Yok"
                  : new Date(reservation.payment_deadline).toLocaleDateString()}
              </td>

              <td>{reservation.note}</td>
              <td>
                <Link to={`edit_reservation/${reservation.id}`}>
                  <Button variant="warning" className="mr-2">
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                </Link>
                <Button
                  variant="danger"
                  onClick={() => handleDelete(reservation.id)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default ReservationCustomerMangmentList;
