import { useState, useEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";
import {
  getCountries,
  getStates,
  getCities,
} from "common/Api/UniversalTutorialApi";
export const HotelAddress = ({ formData, updateFormData, otelData }) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [localAddress, setLocalAddress] = useState({});

  useEffect(() => {
    // Fetch countries when component mounts
    const fetchCountries = async () => {
      const countriesData = await getCountries();
      setCountries(countriesData);
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    // Fetch states when country changes
    const fetchStates = async () => {
      if (formData.otel_address.country) {
        const statesData = await getStates(formData.otel_address.country);
        setStates(statesData);
      }
    };
    fetchStates();
  }, [formData.otel_address.country]);

  useEffect(() => {
    // Fetch cities when state changes
    const fetchCities = async () => {
      if (formData.otel_address.state) {
        const citiesData = await getCities(formData.otel_address.state);
        setCities(citiesData);
      }
    };
    fetchCities();
  }, [formData.otel_address.state]);

  useEffect(() => {
    setLocalAddress(otelData?.address || {});
  }, [otelData]);

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    const updatedAddress = {
      ...localAddress,
      [name]: value,
    };

    setLocalAddress(updatedAddress);
    updateFormData({ ...formData, otel_address: updatedAddress });
  };
  console.log(formData, "formData");
  console.log(localAddress, "localAddresslocalAddress");
  return (
    <div>
      <h5>Address</h5>
      <Row>
        <Col xl={3}>
          <Form.Group className="mb-3">
            <Form.Label>Country (Ulke)</Form.Label>
            <Form.Select
              name="country"
              value={localAddress?.country}
              onChange={handleAddressChange}
            >
              <option>{localAddress?.country}</option>
              {countries.map((country) => (
                <option key={country.country_name} value={country.country_name}>
                  {country.country_name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>

        <Col xl={3}>
          <Form.Group className="mb-3">
            <Form.Label>State/Province (Il)</Form.Label>
            <Form.Select
              name="state"
              value={localAddress?.state}
              onChange={handleAddressChange}
            >
              <option>{localAddress?.state}</option>
              {states.map((state) => (
                <option key={state.state_name} value={state.state_name}>
                  {state.state_name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>

        <Col xl={3}>
          <Form.Group className="mb-3">
            <Form.Label>District (Ilce)</Form.Label>
            <Form.Select
              name="district"
              value={localAddress?.district}
              onChange={handleAddressChange}
            >
              <option>{localAddress?.district}</option>
              {cities.map((city) => (
                <option key={city.city_name} value={city.city_name}>
                  {city.city_name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>

        <Form.Group className="mb-3">
          <Form.Label>Full Street Address (Acik Adres)</Form.Label>
          <Form.Control
            type="text"
            name="street_address"
            placeholder="Full Street Address"
            value={localAddress?.street_address}
            onChange={handleAddressChange}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Postal Code (Postal Kodu)</Form.Label>
          <Form.Control
            type="text"
            name="postal_code"
            placeholder="Postal Code"
            value={localAddress?.postal_code}
            onChange={handleAddressChange}
          />
        </Form.Group>
      </Row>
    </div>
  );
};
export default HotelAddress;
