import { useState } from "react";
import CreatePostModal from "area/admin/Blog/CreatePostModal";
import IndexPosts from "./IndexPosts";
import { CardHeader } from "area/admin/CRUD/CardHeader";
import CreatButton from "area/admin/CRUD/CreatButton";
import { Card, Row, Col } from "react-bootstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "common/Api/BlogApi";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import PaginationControls from "./PaginationControls";
export const CreatePost = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchFilter, setSearchFilter] = useState("");
  const queryClient = useQueryClient();
  const { data, isLoading, isError } = useQuery(
    ["posts", { page: currentPage, search: searchFilter }],
    api.getPagenationPost,
    {
      staleTime: 1000 * 60 * 5,
      keepPreviousData: true, // This is useful for pagination to avoid flickers when fetching new pages
    }
  );
  const totalPageCount = data?.last_page;
  const test = data ?? isLoading;

  const deletePostMutation = useMutation(api.deletePost, {
    onSuccess: () => {
      queryClient.invalidateQueries("posts");
      toast.success("Gönderi başarıyla silindi.!");
    },
    onError: (error) => {
      const errorMsg = error?.response?.data?.message || "Error deleting post.";
      toast.error(errorMsg);
    },
  });

  const editPostMutation = useMutation(api.updatePost, {
    onSuccess: () => {
      queryClient.invalidateQueries("posts");
      toast.success("Gönderiler başarıyla güncellendi.!");
    },
    onError: (error) => {
      const errorMsg = error?.response?.data?.message || "Güncelleme hatası..";
      toast.error(errorMsg);
    },
  });
  if (isError) {
    return <div>Error loading data</div>;
  }
  if (isLoading) {
    return <div>loading...</div>;
  }
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  return (
    <div>
      <Card style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)" }}>
        <Card.Header
          style={{
            backgroundColor: "#636665",
            color: "white",
            borderRadius: "5px 5px 0 0",
          }}
        >
          <Row>
            <Col>
              <h1 style={{ padding: "10px", fontSize: "24px", margin: 0 }}>
                Blog Yönetimi
              </h1>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Row>
                <Col>
                  <Link to="/admin/post/create">
                    <CreatButton title={"Yeni Blog Ekle "} />
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Header>

        <IndexPosts
          data={data}
          setSearchFilter={setSearchFilter}
          searchFilter={searchFilter}
          deletePostMutation={deletePostMutation}
          editPostMutation={editPostMutation}
        />
        <div className="d-flex justify-content-center">
          <PaginationControls
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            totalPageCount={totalPageCount}
          />
        </div>
      </Card>
      <ToastContainer />
    </div>
  );
};
export default CreatePost;
