import { useState } from "react";
import { motion } from "framer-motion";
import { Card } from "react-bootstrap";
import { CardContent, Typography, Box } from "@mui/material";
import {
  VictoryPie,
  VictoryTheme,
  VictoryLabel,
  VictoryChart,
  VictoryAxis,
  VictoryLine,
} from "victory";
export default () => {
  const [chartData, setChartData] = useState([
    { x: "A", y: 20 },
    { x: "B", y: 30 },
    { x: "C", y: 50 },
    { x: "D", y: 45 },
    { x: "E", y: 75 },
    { x: "[", y: 35 },
  ]);
  const chartVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1 } },
  };

  const hoverAnimation = {
    scale: 1.1,
    boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.3)",
  };
  return (
    <li>
      <motion.div whileHover={hoverAnimation} sx={{ m: 1 }}>
        <Card
          className="mb-4"
          sx={{
            width: "100%",
            background: "linear-gradient(45deg, #8BC34A 30%, #CDDC39 90%)",
            boxShadow: "3px 3px 5px 2px rgba(255, 105, 135, .3)",
          }}
        >
          <CardContent>
            <Typography></Typography>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={chartVariants}
            >
              <VictoryChart
                domainPadding={20}
                theme={VictoryTheme.material}
                height={200}
              >
                <VictoryAxis />
                <VictoryAxis dependentAxis />
                <VictoryLine
                  data={chartData}
                  style={{
                    data: {
                      stroke: "rgba(139, 195, 74, 1)",
                    },
                  }}
                  animate={{
                    duration: 2000,
                    onLoad: { duration: 1500 },
                  }}
                />
              </VictoryChart>
            </motion.div>
          </CardContent>
        </Card>
      </motion.div>
    </li>
  );
};
