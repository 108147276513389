import React from "react";
import "../../../../assets/styles/TourList.css";
export const DaysRange = ({ minDays, handleFilterDateChange, maxDays }) => {
  return (
    <div>
      <h4 className="mb-2">Günler</h4>
      <div className="container">
        <div className="slider-track slider-track__color">
          <input
            className="slider"
            id="slider-a"
            type="range"
            min="0"
            max="60"
            name="minDays"
            value={minDays}
            onChange={handleFilterDateChange}
            data-popup-enabled="true"
            data-show-value="true"
          />
          <input
            className="slider"
            id="slider-b"
            type="range"
            min="0"
            max="60"
            name="maxDays"
            value={maxDays}
            onChange={handleFilterDateChange}
            data-popup-enabled="true"
            data-show-value="true"
          />
        </div>
      </div>
    </div>
  );
};
export default DaysRange;
