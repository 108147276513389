import React, { useState, useEffect } from "react";
import { Button, Form, Modal, Row, Col } from "react-bootstrap";

const AddressModalEdit = ({
  show,
  handleClose,
  currentAddress,
  handleUpdate,
}) => {
  const [addressTitle, setAddressTitle] = useState(currentAddress.adres_title);
  const [address, setAddress] = useState(currentAddress.adres);
  const [city, setCity] = useState(currentAddress.il);
  const [district, setDistrict] = useState(currentAddress.ilce);
  const [neighborhood, setNeighborhood] = useState(currentAddress.mahalle);

  const handleReset = () => {
    setAddressTitle(currentAddress.adres_title);
    setAddress(currentAddress.adres);
    setCity(currentAddress.il);
    setDistrict(currentAddress.ilce);
    setNeighborhood(currentAddress.mahalle);
    handleClose();
  };

  const handleSave = () => {
    handleUpdate(currentAddress.id, {
      adres_title: addressTitle,
      adres: address,
      il: city,
      ilce: district,
      mahalle: neighborhood,
    });
    handleReset();
  };

  useEffect(() => {
    setAddressTitle(currentAddress.adres_title);
    setAddress(currentAddress.adres);
    setCity(currentAddress.il);
    setDistrict(currentAddress.ilce);
    setNeighborhood(currentAddress.mahalle);
  }, [currentAddress]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Address</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col xl={12}>
              <Form.Group controlId="formAddressTitle">
                <Form.Label>Address Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter address title"
                  value={addressTitle}
                  onChange={(e) => setAddressTitle(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col xl={4}>
              <Form.Group controlId="formCity">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col xl={4}>
              <Form.Group controlId="formDistrict">
                <Form.Label>District</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter district"
                  value={district}
                  onChange={(e) => setDistrict(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col xl={4}>
              <Form.Group controlId="formNeighborhood">
                <Form.Label>Neighborhood</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter neighborhood"
                  value={neighborhood}
                  onChange={(e) => setNeighborhood(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col xl={12}>
              <Form.Group controlId="formAddress">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button active={true} variant="secondary" onClick={handleReset}>
          Close
        </Button>
        <Button active={true} variant="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddressModalEdit;
