import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import {css} from "twin.macro";

const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 1rem;

	@media (max-width: 768px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (max-width: 480px) {
		grid-template-columns: 1fr;
	}
	${tw`mt-4`}
`;

const Rooms = tw.div`
  p-4
  border-2
  border-purple-500
  bg-purple-500
  bg-opacity-10
`;
const Image = styled.div((props) => [
	`background-image: url("${props.imageSrc}");`,
	tw`w-full h-64  shadow-lg bg-cover bg-center object-cover`,
]);
const Name = tw.h2`text-2xl font-bold text-purple-500 mt-4`;
const Price = tw.p`text-purple-500 font-bold mt-2`;
const Button = tw.button`px-4 py-2 font-bold text-white  bg-purple-500 hover:bg-purple-700`;

const RoomCards = ({rooms}) => {
	return (
		<Container>
			{rooms?.map((room) => (
				<Rooms key={room.id}>
					{console.log("room", room)}
					<Image imageSrc={room.room_picture} /> <Name> {room.room_name} </Name>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
						}}>
						<Price>
							{"111"}
							TL
						</Price>
						<Button> Select </Button>
					</div>
				</Rooms>
			))}{" "}
		</Container>
	);
};

export default RoomCards;
