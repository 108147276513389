import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import styles from "assets/styles/tourstamplate.module.css";
import errorPhoto from "../../../../assets/icons/errorPhoto.jpg";
import people2 from "../../../../assets/icons/people2.png";
import people3 from "../../../../assets/icons/people3.png";
import people4 from "../../../../assets/icons/people4.png";
import people5 from "../../../../assets/icons/people5.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlane,
  faBed,
  faUtensils,
  faBus,
} from "@fortawesome/free-solid-svg-icons";

function ToursTamplateCardWithPrices() {
  const icons = [
    { icon: styles.people2, text: "2500", title: "2 kişi oda" },
    { icon: styles.people3, text: "3500", title: "3 kişi oda" },
    { icon: styles.people4, text: "4500", title: "4 kişi oda" },
    { icon: styles.people5, text: "6500", title: "5 kişi oda" },
  ];
  return (
    <div className={styles.cardWrapper}>
      <div className={styles.card}>
        <div className={styles.turSecWrapper}>
          <span className={styles.turSecText}>tur sec</span>
          <input type="checkbox" className={styles.turSecCheckbox} />
        </div>
        <div className={styles.leftSection}>
          {[1, 2].map((item) => (
            <div key={item}>
              <div className={styles.titleWithIcon}>
                <div className={styles.addressIcon}></div>
                <h6 style={{ fontWeight: "700" }}>MURJAM KARAM OTEL {item}</h6>
              </div>
              <img
                style={{ marginTop: "20px", marginBottom: "10px" }}
                src={errorPhoto}
                alt={`Image ${item}`}
                width="201"
                height="224"
              />
              <p style={{ display: "flex", alignItems: "center" }}>
                <div
                  className={styles.moonIcon}
                  style={{ marginRight: "5px" }}
                ></div>{" "}
                <strong>22 Gece</strong>
              </p>
            </div>
          ))}
        </div>
        <div className={styles.rightSection}>
          <h6>
            <strong>
              21 GECE MEKKE 4 GECE MEDİNE / RAMAZAN'DA SON 10 GÜN MEKKE 25 Gece
              26 Gün
            </strong>
          </h6>
          <div className={styles.line}></div>
          <ul>
            {["2023-06-01", "2023-06-02", "2023-06-03"].map((date, index) => (
              <li
                key={date}
                className={`${styles.listItem} ${
                  index % 2 === 0 ? styles.withBackground : ""
                }`}
              >
                <span style={{ marginRight: "10px" }}>Gidiş Tarih: </span>
                <span style={{ marginLeft: "20px", fontWeight: "900" }}>
                  {date} Cumartisi
                </span>
                <span style={{ marginRight: "10px", marginLeft: "20px" }}>
                  İstanbul
                </span>
                <div
                  className={styles.airplaneIcon}
                  style={{ marginRight: "10px" }}
                ></div>
                <span style={{ marginRight: "10px" }}>Medine</span>
              </li>
            ))}
          </ul>
          <div className={styles.line}></div>
          <div className={styles.iconRow}>
            {icons.map(({ icon, text, title }, index) => (
              <div key={index} className={styles.iconWithText}>
                <div className={`${styles.icon} ${icon}`}></div>
                <p>{title}</p>
                <h3 style={{ color: "#4EAC74", fontWeight: "bold" }}>
                  {text} $
                </h3>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.satinAlButtonWrapper}>
        <Button className={styles.satinAlButton}>SATIL AL</Button>
      </div>
    </div>
  );
}
export default ToursTamplateCardWithPrices;
