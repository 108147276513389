import React, { useState } from "react";
import axios from "axios";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
// import LocationPin from "assets/icons/location-pin.png";
import LocationPin from "assets/icons/location.png";

const DraggableMarker = ({ onDrag }) => {
  const [position, setPosition] = useState({
    lat: 40.2291093,
    lng: 29.0569051,
  });

  // Custom icon
  const icon = new L.Icon({
    iconUrl: LocationPin, // Replace with your icon image URL
    iconSize: [30, 41], // Size of the icon
    iconAnchor: [12, 41], // Point of the icon which will correspond to marker's location
  });

  const reverseGeocode = async (latLng) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse`,
        {
          params: {
            lat: latLng.lat,
            lon: latLng.lng,
            format: "json",
          },
        }
      );
      console.log(response, "response");
      console.log(response.data, "response.data");
      if (response.data) {
        onDrag(response.data);
      }
    } catch (error) {
      console.error("Error during reverse geocoding:", error);
    }
  };
  return (
    <Marker
      position={position}
      draggable={true}
      icon={icon}
      eventHandlers={{
        dragend: (e) => {
          const newPosition = e.target.getLatLng();
          setPosition(newPosition);
          reverseGeocode(newPosition);
        },
      }}
    />
  );
};

const Leaflet = ({ onLocationSelect, Height, Width }) => {
  return (
    <MapContainer
      center={{ lat: 40.2291093, lng: 29.0569051 }}
      zoom={13}
      style={{ height: Height, width: Width }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <DraggableMarker onDrag={onLocationSelect} />
    </MapContainer>
  );
};

export default Leaflet;
