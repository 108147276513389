import React, { useEffect, useRef } from "react";
import Dropzone from "dropzone";
import "dropzone/dist/dropzone.css";

// Disable auto discovery
Dropzone.autoDiscover = false;

const DropzoneComponent = ({ onFilesRemoved, updateTourPhotosData }) => {
  const dropzoneRef = useRef(null);

  useEffect(() => {
    // Initialize Dropzone
    const dropzone = new Dropzone(dropzoneRef.current, {
      url: "#", // Dummy URL for testing
      autoProcessQueue: false,
      uploadMultiple: true,
      maxFiles: 10,
      addRemoveLinks: true,
      maxFilesize: 1.1,
      init: function () {
        this.hiddenFileInput.setAttribute("multiple", "multiple");
      },
    });

    // Event listeners
    dropzone.on("addedfile", (file) => {
      updateTourPhotosData(file);
    });

    dropzone.on("removedfile", (file) => {
      onFilesRemoved(file);
    });

    // Cleanup function
    return () => {
      dropzone.destroy();
    };
  }, []);

  return <div ref={dropzoneRef} className="dropzone" />;
};

export default DropzoneComponent;
