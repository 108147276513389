import { useState, useRef, useEffect } from "react";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Card, Dropdown, Form } from "react-bootstrap";
const selectData = [
  {
    name: "otel_stars",
    placeholder: "Tur Adı",
    options: [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
      { value: "6", label: "6" },
    ],
  },
  {
    name: "country",
    placeholder: "Ülke",
    options: [
      { value: "option1A", label: "Option 1A" },
      { value: "option2A", label: "Option 2A" },
    ],
  },
  {
    name: "state",
    placeholder: "Eyalet",
    options: [
      { value: "option2B", label: "Option 2B" },
      { value: "option2B", label: "Option 2B" },
    ],
  },
  {
    name: "district",
    placeholder: "İlçe",
    options: [
      { value: "option1B", label: "Option 1B" },
      { value: "option2B", label: "Option 2B" },
    ],
  },
  {
    name: "neighborhood",
    placeholder: "Mahalle",
    options: [
      { value: "option1B", label: "Option 1B" },
      { value: "option2B", label: "Option 2B" },
    ],
  },
  {
    name: "otel odasi",
    placeholder: "otel odasi",
    options: [
      { value: "option1B", label: "Option 1B" },
      { value: "option2B", label: "Option 2B" },
    ],
  },
];
export const AdvanceSearchHotel = ({
  setFilters,
  handleInputChange,
  applyFilters,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleClearFilter = () => {
    setFilters("");
  };
  useEffect(() => {
    // Step 2: Set up an event listener for clicks
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false); // Step 3: Determine click inside or outside
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Step 4: Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  return (
    <div ref={dropdownRef}>
      <Dropdown show={isDropdownOpen}>
        <Dropdown.Toggle
          onClick={toggleDropdown}
          style={{
            border: "none",
            backgroundColor: "#1cab1c",
            fontWeight: "700",
            borderRadius: "7px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
          id="dropdown-basic"
        >
          Gelişmiş Arama <FontAwesomeIcon icon={faFilter} className="ml-2" />
        </Dropdown.Toggle>

        <Dropdown.Menu
          className="dropdown-menu-custom"
          style={{
            maxHeight: "350px",
            overflowY: "auto",
            padding: "15px",
          }}
        >
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {selectData.map((data, idx) => (
              <Col
                key={data.name}
                md={4}
                xs={12}
                style={{ marginBottom: idx % 3 < 2 ? "20px" : "20px" }}
              >
                <Form.Control
                  as="select"
                  name={data.name}
                  onChange={handleInputChange} // Attach the change handler
                >
                  <option value="" hidden>
                    {data.placeholder}
                  </option>
                  {data.options.map((opt) => (
                    <option key={opt.value} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            ))}

            <Col md={6}>
              <button
                onClick={applyFilters}
                style={{
                  width: "100%",
                  color: "white",
                  marginTop: "7px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  border: "none",
                  backgroundColor: "rgb(9 149 193)",
                  marginLeft: "10px",
                  fontWeight: "700",
                  borderRadius: "7px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                Uygula
              </button>
            </Col>
            <Col md={6}>
              <button
                onClick={handleClearFilter}
                style={{
                  width: "100%",
                  color: "white",
                  marginTop: "7px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  border: "none",
                  backgroundColor: "rgb(84 99 104)",
                  marginLeft: "10px",
                  fontWeight: "700",
                  borderRadius: "7px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                Temizle
              </button>
            </Col>
          </Row>

          {/* Dropdown items here */}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
export default AdvanceSearchHotel;
