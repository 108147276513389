import React, { useEffect, useState } from "react";
import SelectInput from "../Components/SelectInput";
import TextInput from "../Components/TextInput";
import UploadFileInput from "../Components/uploadFileInput";
import { useParams } from "react-router-dom";
import { axios } from "common/utils/axios";
import FormData from "form-data";
import { ToastContainer, toast } from "react-toastify";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
const customerDocumentType = ["PDF", "EXL", "PNG"];
export const CreateCustomerDocuments = ({
  toggleCreateModal,
  showCreateModal,
}) => {
  const { id } = useParams();
  // const [customerDocumentType, setCustomerDocumentType] = useState([]);
  const [customerDocument, setCustomerDocument] = useState({
    data: {
      document_title: "",
      file_path: "",
      document_typeId: "",
    },
  });

  // useEffect(() => {
  //   axios
  //     .get("/api/customer-document-type")
  //     .then((response) => setCustomerDocumentType(response.data))
  //     .catch((error) => console.error(error));
  // }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerDocument({
      ...customerDocument,
      data: {
        ...customerDocument.data,
        [name]: value,
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("handleSubmit called");

    console.log("document_typeId:", customerDocument.data.document_typeId);
    console.log("file:", customerDocument.data.file_path);

    const formData = new FormData();
    formData.append("document_typeId", customerDocument.data.document_typeId);
    formData.append("document_title", customerDocument.data.document_title);
    formData.append("file_path", customerDocument.data.file_path);

    const csrfTokenElement = document.querySelector('meta[name="csrf-token"]');
    const csrfToken = csrfTokenElement
      ? csrfTokenElement.getAttribute("content")
      : null;

    if (!csrfToken) {
      console.error("CSRF token not found");
      return;
    }

    axios
      .post(`api/customers/${id}/documents`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-TOKEN": csrfToken,
        },
      })
      .then((res) => {
        console.log(res);
        toast.success("Document added successfully!");
        toggleCreateModal();
      })
      .catch((error) => {
        console.error(error);

        const errorMessage =
          error.response && error.response.data.message
            ? error.response.data.message
            : "An error occurred while adding the document!";
        toast.error(errorMessage);
      });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === "file_path") {
      setCustomerDocument({
        ...customerDocument,
        data: {
          ...customerDocument.data,
          file_path: files[0],
        },
      });
    }
  };

  return (
    <div>
      <Modal centered show={showCreateModal} onHide={toggleCreateModal}>
        <Modal.Header closeButton>
          <Modal.Title>Yeni Belge Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={6}>
                <Form.Group controlId="documentTitle">
                  <Form.Label>Başlık</Form.Label>
                  <Form.Control
                    type="text"
                    name="document_title"
                    value={customerDocument.data.document_title}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group controlId="documentType">
                  <Form.Label>Tür</Form.Label>
                  <Form.Select
                    name="document_typeId"
                    value={customerDocument.data.document_typeId}
                    onChange={handleChange}
                  >
                    <option hidden value="">
                      Select type
                    </option>
                    {customerDocumentType.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group controlId="documentFile">
                  <Form.Label>Dosya</Form.Label>
                  <Form.Control
                    type="file"
                    name="file_path"
                    onChange={handleFileChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} className="text-center"></Col>
            </Row>
          </Form>
          <ToastContainer />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="m-2"
            onClick={toggleCreateModal}
          >
            Kapat
          </Button>
          <Button
            variant="primary"
            className="m-2"
            onClick={(e) => handleSubmit(e)}
          >
            Ekle
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default CreateCustomerDocuments;
