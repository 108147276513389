import React from "react";

export const LineAdvance = () => {
  return (
    <div>
      <div
        style={{
          width: "100%",
          height: "2px",
          background:
            "linear-gradient(to right, rgb(142 142 142), transparent)",
          marginBottom: "10px",
          marginTop: "10px",
        }}
      ></div>
    </div>
  );
};
export default LineAdvance;
