import { useState, useEffect } from "react";
import TableListComponent from "area/admin/CRUD/TableListComponent";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import Quill from "react-quill";
import Title from "area/admin/CRUD/ReactBootsrap/FormTextInput";
import PostImage from "area/admin/CRUD/ReactBootsrap/FormUplaodInput";
import SearchFilterComponent from "area/admin/CRUD/ReactBootsrap/SearchFilterComponent";
import { faFilter, faSearch, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Todo CHANGE THE deleteCommentMutation
export const IndexPosts = ({
  handleChange,
  onChange,
  imageURL,
  data,
  deletePostMutation,
  editPostMutation,
  handleSearchFilter,
  setSearchFilter,
  searchFilter,
  handleClearFilter,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  // ToDo Change the columns
  const columns = [
    { label: "Durum", field: "status" },
    { label: "Başlık", field: "title" },
    { label: "Açıklama", field: "description" },
    { label: "Metin Ekleyin", field: "text" },
    { label: "Tarihi", field: "published_at" },
    { label: "Blog Görseli", field: "image" },
  ];
  const actions = [
    {
      variant: "warning",
      onClick: (item) => {
        setCurrentItem(item);
        setShowModal(true);
      },
      icon: faEdit,
      style: { marginRight: "10px" },
    },
    {
      variant: "danger",
      onClick: (item) => {
        if (window.confirm("Are you sure you want to delete this Post?")) {
          deletePostMutation.mutate(item.id);
        }
      },
      icon: faTrash,
      style: { marginRight: "10px" },
    },
  ];
  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        // Set the converted Base64 string to the currentItem state
        // Assuming you have an 'imageBase64' field or you can directly assign to 'image'
        setCurrentItem({
          ...currentItem,
          image: e.target.result, // Stores the Base64 string
        });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleSaveChanges = () => {
    debugger;
    if (currentItem) {
      // console.log("Current Item:", currentItem); // Add this line for debug

      // const formData = new FormData();
      // // Append the file to formData only if currentItem.image is a File object
      // if (currentItem.image instanceof File) {
      //   formData.append("image", currentItem.image);
      // }

      // // Append other form data as necessary
      // Object.keys(currentItem).forEach((key) => {
      //   if (key !== "image") {
      //     formData.append(key, currentItem[key]);
      //   }
      // });
      // console.log(formData, "formDataformDataformDataformDataformData");
      // for (const [key, value] of formData.entries()) {
      //   console.log(
      //     `${key}: ${value}`,
      //     "formDataformDataformDataformDataformData"
      //   );
      // }
      // Assuming editPostMutation.mutate is set up to handle the formData
      // Ensure the API endpoint expects a multipart/form-data request
      editPostMutation.mutate({
        id: currentItem.id,
        data: currentItem,
      });
    }

    // After successful update
    setShowModal(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  console.log(data, "datadatadatadata");
  return (
    <div>
      <Row className="pl-4 pr-4 pt-4">
        <Col md={6} sm={12}>
          <h4>Sistemde kayıtlı tüm bloglar listeleniyor</h4>
        </Col>
        <Col>
          <Row>
            <Col>
              {" "}
              <InputGroup className="mb-3">
                {" "}
                <FormControl
                  type="text"
                  value={searchFilter}
                  onChange={(e) => setSearchFilter(e.target.value)}
                  placeholder="Başlık , Açıklama , Metin ..."
                />
                <Button
                  style={{ backgroundColor: "rgb(0, 123, 255)" }}
                  onClick={handleSearchFilter}
                >
                  <FontAwesomeIcon
                    icon={faSearch}
                    style={{ fontSize: "18px", marginRight: "8px" }}
                    onClick={handleSearchFilter}
                  />
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <TableListComponent
        isRenderHtml={true}
        columns={columns}
        actions={actions}
        data={data.data}
      />
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Post ayarla</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentItem && (
            <Form>
              <Form.Group>
                <Form.Label>
                  <strong>Durum</strong>
                </Form.Label>
                <Form.Control
                  as="select"
                  name="status"
                  onChange={(e) =>
                    setCurrentItem({ ...currentItem, status: e.target.value })
                  }
                  defaultValue=""
                >
                  <option value={currentItem.status} hidden>
                    {currentItem.status === "published"
                      ? "Yayınla"
                      : "scheduled"
                      ? "Planla"
                      : "draft"
                      ? "Taslak"
                      : "passive"
                      ? "Pasif Al"
                      : ""}
                  </option>
                  <option value="published">Yayınla</option>
                  <option value="scheduled">Planla</option>
                  <option value="draft">Taslak</option>
                  <option value="passive">Pasif Al</option>
                </Form.Control>
              </Form.Group>
              <Title
                value={currentItem.title}
                label={"Başlık"}
                type="text"
                name={"title"}
                placeholder={"Başlık"}
                onChange={(e) =>
                  setCurrentItem({ ...currentItem, title: e.target.value })
                }
              />
              <Title
                value={currentItem.description}
                label={"Açıklama"}
                type="text"
                name={"description"}
                placeholder={"Başlık"}
                onChange={(e) =>
                  setCurrentItem({
                    ...currentItem,
                    description: e.target.value,
                  })
                }
              />
              <Form.Group className="mb-3">
                <Form.Label>Metin Ekleyin</Form.Label>
                <Quill
                  value={currentItem.text || ""}
                  onChange={(value) =>
                    setCurrentItem({
                      ...currentItem,
                      text: value,
                    })
                  }
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Takvimlemek</Form.Label>
                <Form.Control
                  value={currentItem.published_at}
                  name="published_at"
                  onChange={(e) =>
                    setCurrentItem({
                      ...currentItem,
                      published_at: e.target.value,
                    })
                  }
                  type="datetime-local"
                  className="mt-2"
                />
              </Form.Group>
              <PostImage
                edit={true}
                label={"Post Resimi"}
                name={"image"}
                onChange={handleImageChange}
                imageSrc={
                  currentItem && currentItem.image instanceof File
                    ? URL.createObjectURL(currentItem.image)
                    : null
                }
              />
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default IndexPosts;
