import { useContext, useEffect } from "react";
import HeaderContext from "redux/ContextApi/HeaderContext .jsx";
import ContactUsForm from "../../common/components/forms/TwoColContactUsWithIllustrationFullForm.js";

export default () => {
  const { setTitle, setImageUrl } = useContext(HeaderContext);
  useEffect(() => {
    setTitle("İletişim");
    setImageUrl("https://backend.suarelab.com/api/about-us/images/SSS.jpg");
    // Clean up function to reset the title and image URL when the component unmounts
    return () => {
      setTitle("");
      setImageUrl("");
    };
  }, [setTitle, setImageUrl]);

  return (
    <>
      <ContactUsForm />
    </>
  );
};
