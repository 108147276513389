import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useQuery } from "react-query";
import * as api from "common/Api/BlogApi";
import BlogWall from "assets/images/Blog.jpg";
import HeaderContext from "redux/ContextApi/HeaderContext ";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import "assets/styles/BlogCards.css";
const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

export const UserBlogList = () => {
  const { setTitle, setImageUrl } = useContext(HeaderContext);
  const [displayCount, setDisplayCount] = useState(4);
  const [hasMore, setHasMore] = useState(true);

  const {
    data: blogPosts,
    isLoading,
    isError,
  } = useQuery("posts", api.getPosts, {
    onSuccess: (data) => {
      // Optional: Process data or handle side effects on success
    },
  });

  // Set title and image URL on mount and clean up on unmount
  useEffect(() => {
    setTitle("Blog");
    setImageUrl(BlogWall);
    return () => {
      setTitle("");
      setImageUrl("");
    };
  }, [setTitle, setImageUrl]);

  // Infinite scroll effect
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
          document.body.offsetHeight - 500 &&
        hasMore
      ) {
        setDisplayCount((prevCount) => prevCount + 4);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasMore]);

  // Update hasMore state based on display count and blogPosts length
  useEffect(() => {
    if (blogPosts && displayCount >= blogPosts.length) {
      setHasMore(false);
    }
  }, [blogPosts, displayCount]);

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error fetching posts.</div>;
  const getTruncatedDescription = (description) => {
    const screenWidth = window.innerWidth;
    const maxLength = 38; // Maximum characters for large screens
    if (screenWidth > 768) {
      // Assuming 768px as a breakpoint for larger screens
      return truncateText(description, maxLength);
    }
    return description;
  };
  console.log(blogPosts, "blogPosts");
  return (
    <Container className="mt-4">
      <Row>
        <TransitionGroup component={null}>
          {blogPosts &&
            blogPosts.slice(0, displayCount).map((post) => (
              <CSSTransition key={post.id} timeout={500} classNames="fade">
                <Col xs={12} md={6} lg={4} className="mb-4">
                  <Link to={`post/${post.slug}`}>
                    <Card className="card-style">
                      <Card.Img variant="top" src={post.image} />
                      <Card.Body>
                        <Card.Title>{post.title}</Card.Title>
                        <Card.Text>
                          {" "}
                          {getTruncatedDescription(post.description)}
                        </Card.Text>
                        <Card.Footer>
                          <small className="text-muted">
                            {post.published_at}
                          </small>
                        </Card.Footer>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              </CSSTransition>
            ))}
        </TransitionGroup>
      </Row>
    </Container>
  );
};

export default UserBlogList;
