import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Table, Form, Button } from "react-bootstrap";
import { getTodayDateString } from "common/helpers/getTodayDateString";
import { isObjectEmpty } from "common/helpers/isObjectEmpty";
import { toast } from "react-toastify";
import { getCompanies } from "common/Api/CompaniesApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
const TourCitys = ["Istanbul", "Ankara", "Make", "Medine"];
export const Transport = ({ transportations, updateTransportData }) => {
  const [transportationsRows, setTransportationsRows] =
    useState(transportations);

  const [newTransportationsRows, setNewTransportationsRows] = useState({
    company: "",
    vehicle_transport: "",
    fromLocation: "",
    toLocation: "",
    departureDateTime: "",
    arrivalDateTime: "",
  });
  const addUlasimRow = () => {
    if (isObjectEmpty(newTransportationsRows)) {
      return toast.warn("bazı veriler eksik");
    } else {
      console.log("Tüm veriler kayıp.");
    }
    const newRows = [...transportationsRows, newTransportationsRows];
    setTransportationsRows(newRows);
    updateTransportData(newRows);
    setNewTransportationsRows({
      company: "",
      vehicle_transport: "",
      fromLocation: "",
      toLocation: "",
      departureDateTime: "",
      arrivalDateTime: "",
    });
  };

  const removeUlasimRow = (index) => {
    const updatedRows = [...transportationsRows];
    updatedRows.splice(index, 1);
    setTransportationsRows(updatedRows);
    updateTransportData(updatedRows); // Update parent state
  };
  const handleInputChange = (e, field) => {
    let value = e.target.value;
    if (field === "departureDateTime" || field === "arrivalDateTime") {
      value = value.replace("T", " "); // Replace T with a space
    }
    setNewTransportationsRows((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  useEffect(() => {
    if (transportations) {
      const filteredTransportations = transportations.filter(
        (acc) => !isObjectEmpty(acc)
      );
      setTransportationsRows(filteredTransportations);
    } else {
      setTransportationsRows([]);
    }
  }, [transportations]);
  const {
    data: companies,
    isLoading,
    isError,
  } = useQuery("Companies", getCompanies, {
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error loading companies</div>;
  console.log(companies, "companies");
  console.log(newTransportationsRows, "newTransportationsRows");
  return (
    <div>
      <h5>Ulasim</h5>
      <Table responsive>
        <thead>
          <tr>
            <th style={{ width: "10% !important" }}>Firmasi</th>
            <th style={{ width: "10% !important" }}>Ne ile</th>
            <th style={{ width: "25% !important" }}>Nerden</th>
            <th style={{ width: "25% !important" }}>Nereye</th>
            <th style={{ width: "20% !important" }}>
              Kalkis Tarihi ve Saatleri
            </th>
            <th style={{ width: "20% !important" }}>
              Varis Tarihi ve Saatleri
            </th>

            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Form.Control
                as="select"
                name="company" // Change this to match the field name in the state
                value={newTransportationsRows.company} // Add value attribute to bind the state
                onChange={(e) => handleInputChange(e, "company")}
              >
                <option value="" hidden>
                  Select Company
                </option>
                {companies &&
                  companies.map((company) => (
                    <option key={company.companyId} value={company.companyName}>
                      {company.companyName}
                    </option>
                  ))}
              </Form.Control>
            </td>
            <td>
              <Form.Select
                value={newTransportationsRows.vehicle_transport}
                onChange={(e) => handleInputChange(e, "vehicle_transport")}
              >
                <option value="" hidden>
                  Ne ile seç
                </option>
                <option value="Bus">Bus</option>
                <option value="Airplan">Airplan</option>
                <option value="Ship">Ship</option>
              </Form.Select>
            </td>
            <td>
              <Form.Select
                value={newTransportationsRows.fromLocation}
                onChange={(e) => handleInputChange(e, "fromLocation")}
              >
                <option value="" hidden>
                  Nerder seç
                </option>
                {TourCitys.map((DepartureCity) => {
                  return (
                    <option key={DepartureCity} value={DepartureCity}>
                      {DepartureCity}
                    </option>
                  );
                })}
              </Form.Select>
            </td>
            <td>
              <Form.Select
                value={newTransportationsRows.toLocation}
                onChange={(e) => handleInputChange(e, "toLocation")}
              >
                <option value="" hidden>
                  Nerder seç
                </option>
                {TourCitys.filter(
                  (ReturnCity) =>
                    ReturnCity !== newTransportationsRows.fromLocation
                ).map((ReturnCity) => {
                  return (
                    <option key={ReturnCity} value={ReturnCity}>
                      {ReturnCity}
                    </option>
                  );
                })}
              </Form.Select>
            </td>
            <td>
              <Form.Control
                type="datetime-local"
                name="departureDateTime"
                value={newTransportationsRows.departureDateTime}
                onChange={(e) => handleInputChange(e, "departureDateTime")}
                min={getTodayDateString()}
              />
            </td>
            <td>
              <Form.Control
                type="datetime-local"
                name="arrivalDateTime"
                value={newTransportationsRows.arrivalDateTime}
                onChange={(e) => handleInputChange(e, "arrivalDateTime")}
                min={
                  newTransportationsRows.kalkisTarihi || getTodayDateString()
                }
              />
            </td>

            <td>
              <Button active={true} variant="success" onClick={addUlasimRow}>
                <FontAwesomeIcon icon={faSave} />
              </Button>
            </td>
          </tr>
          {transportationsRows &&
            transportationsRows?.map((row, index) => (
              <tr key={index}>
                <td>{row.company}</td>
                <td>{row.vehicle_transport}</td>
                <td>{row.fromLocation}</td>
                <td>{row.toLocation}</td>
                <td>{row.departureDateTime}</td>
                <td>{row.arrivalDateTime}</td>

                <td>
                  <Button
                    variant="danger"
                    onClick={() => removeUlasimRow(index)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Transport;
