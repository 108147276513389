import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { axios } from "common/utils/axios";

function EditReservation() {
  const { id, reservation_id } = useParams(); // get reservation id from the route
  const [reservationDate, setReservationDate] = useState("");
  const [paymentDeadline, setPaymentDeadline] = useState("");
  const [tour, setTour] = useState("");
  const [tours, setTours] = useState([]);

  useEffect(() => {
    // Replace with your actual API URLs
    const urlReservation = `api/reservation_management/${reservation_id}`;
    const urlTours = `api/tours`;

    axios
      .get(urlReservation)
      .then((response) => {
        setReservationDate(response.data.reservation_date);
        setPaymentDeadline(response.data.payment_deadline);
        setTour(response.data.tour_id);
      })
      .catch((err) => {
        console.error(err);
      });

    axios
      .get(urlTours)
      .then((response) => {
        setTours(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Replace with your actual API URL
    const url = `api/reservation_management/${id}`;

    try {
      await axios.put(url, {
        reservation_date: reservationDate,
        payment_deadline: paymentDeadline,
        tour_id: tour,
      });
      alert("Reservation updated successfully");
    } catch (err) {
      console.error(err);
      alert("An error occurred while updating the reservation");
    }
  };

  return (
    <Form onSubmit={handleSubmit} style={{ width: "50%", margin: "auto" }}>
      <Form.Group controlId="formReservationDate">
        <Form.Label>Reservation Date</Form.Label>
        <Form.Control
          type="date"
          value={reservationDate}
          onChange={(e) => setReservationDate(e.target.value)}
          required
        />
      </Form.Group>
      <Form.Group controlId="formPaymentDeadline">
        <Form.Label>Payment Deadline</Form.Label>
        <Form.Control
          type="date"
          value={paymentDeadline}
          onChange={(e) => setPaymentDeadline(e.target.value)}
          required
        />
      </Form.Group>
      <Form.Group controlId="formTour">
        <Form.Label>Tour</Form.Label>
        <Form.Control
          as="select"
          value={tour}
          onChange={(e) => setTour(e.target.value)}
          required
        >
          <option value="">-- Select a Tour --</option>
          {tours.map((tour, index) => (
            <option value={tour.id} key={index}>
              {tour.title}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Button style={{ backgroundColor: "dodgerblue" }} type="submit">
        Submit
      </Button>
      <Link
        to={`/admin/customerManagement/customers/${id}/ReservationManagementList/`}
        className="btn btn-secondary ml-2"
      >
        Cancel
      </Link>
    </Form>
  );
}

export default EditReservation;
