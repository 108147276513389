import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import googleIcon from "assets/icons/google.png";
import facebookIcon from "assets/icons/facebook.png";

export const Login = () => {
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <h2 className="text-center mb-4">Login</h2>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email or Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter email or phone number"
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" />
            </Form.Group>

            <Button variant="primary" type="submit" className="mb-3 w-100">
              Login
            </Button>
          </Form>

          <div className="text-center my-3">
            <p>or sign in with</p>
          </div>

          <Button variant="outline-dark" className="mb-3 w-100">
            <img
              src={googleIcon}
              alt="Google"
              style={{ marginRight: "10px", height: "20px" }}
            />{" "}
            Sign in with Google Sign in with Google
          </Button>

          <Button variant="outline-dark" className="mb-3 w-100">
            <img
              src={facebookIcon}
              alt="Google"
              style={{ marginRight: "10px", height: "20px" }}
            />{" "}
            Sign in with Facebook
          </Button>

          <div className="d-flex justify-content-between mt-3">
            <Form.Text className="text-muted">
              <a href="/forgot-password">Did you forget your password?</a>
            </Form.Text>

            <Form.Text className="text-muted">
              <a href="/signup">Sign Up</a>
            </Form.Text>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
