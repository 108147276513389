import React, { useState } from "react";
import { Container as ContainerBase } from "common/components/misc/Layouts";
import { ToastContainer, toast } from "react-toastify";
import { axios } from "common/utils/axios";
import { setCookie } from "common/helpers/setCookie";
import { getMainLogo } from "common/Api/SiteSettingsApi";
import { useQuery } from "react-query";
import { useNavigate, Link } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "../assets/images/login-illustration.svg";
import googleIconImageSrc from "../assets/images/google-icon.png";
import twitterIconImageSrc from "../assets/images/facebook.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import AnimationRevealPage from "../common/helpers/AnimationRevealPage.js";
import GoogleLoginButton from "common/components/GoogleLoginButton";
import FacebookLoginButton from "common/components/FacebookLoginButton";
import useFacebook from "common/hooks/useFacebook";

const Container = tw(
  ContainerBase
)`min-h-screen bg-custom-green text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-custom-green text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  cursor: pointer;
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;
const FacebookButton = styled.button`
  margin-top: 20px;
  color: #fff;
  background-color: #4267b2;
  border-radius: 4px;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #314e86;
  }

  &:active {
    background-color: #233b63;
  }
`;
export default ({
  logoLinkUrl = "#",
  illustrationImageSrc = illustration,
  headingText = "Giriş Yap Akyol'a",
  socialButtons = [
    {
      iconImageSrc: googleIconImageSrc,
      text: "Google ile Giriş Yap",
      url: "https://google.com",
    },
    {
      iconImageSrc: twitterIconImageSrc,
      text: "Facebook ile Giriş Yap",
      url: "https://Facebook.com",
    },
  ],
  submitButtonText = "Giriş Yap",
  SubmitButtonIcon = LoginIcon,
  forgotPasswordUrl = "/resetPassword",
  signupUrl = "#",
}) => {
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const status = useFacebook("1845526085821908");
  const logoType = "log_in";
  const {
    data: logoData,
    isLoading,
    isError,
  } = useQuery(["log_inLogo", logoType], () => getMainLogo(logoType));
  const handleLogin = () => {
    if (status !== "connected") {
      window.FB.login((response) => {
        if (response.status === "connected") {
          // Logged into your app and Facebook.
          console.log("User has successfully logged in");
        } else {
          // The person is not logged into your app or we are unable to tell.
          console.log("User could not log in");
        }
      });
    }
  };
  const handleIllustrationClick = () => {
    navigate("/"); // Replace '/your-internal-path' with the path you want to navigate to
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      email_or_phone: emailOrPhone,
      password: password,
    };

    axios
      .post("/api/login", data)
      .then((response) => {
        console.log(response, "responseresponseresponse");
        // save the user object in localStorage
        setCookie("user", JSON.stringify(response.data.user), 7);
        setCookie("role", response.data.role.name, 7);
        setCookie("token", response.data.token, 7);
        setCookie("CustomerId", JSON.stringify(response.data.CustomerId), 7);

        toast.success("Login successful!");

        // Check if the user is an admin and redirect
        if (response.data.role.name === "admin") {
          navigate("/admin/dashboard");
        } else if (response.data.role.name === "user") {
          navigate("/user");
        } else if (response.data.role.name === "staff") {
          navigate("/staff");
        } else if (response.data.role.name === "manager") {
          navigate("/manager");
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          const errors = error.response.data.errors;
          Object.keys(errors).forEach((key) => {
            toast.error(errors[key][0]); // Display the first error message for each field
          });
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          toast.error(error.response.data.error);
        } else {
          toast.error("An error occurred. Please try again.");
        }
      });
  };
  console.log(logoData, "logoData Login");
  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <MainContent>
              <Heading>{headingText}</Heading>
              <FormContainer>
                <SocialButtonsContainer>
                  <GoogleLoginButton />
                </SocialButtonsContainer>
                <SocialButtonsContainer>
                  <FacebookButton onClick={handleLogin}>
                    Facebook ile Giriş Yap
                  </FacebookButton>
                </SocialButtonsContainer>

                <DividerTextContainer>
                  <DividerText>Veya E-postanızla Giriş Yapın</DividerText>
                </DividerTextContainer>
                <Form onSubmit={handleSubmit}>
                  <Input
                    placeholder="Email Yada Telefon"
                    value={emailOrPhone}
                    onChange={(e) => setEmailOrPhone(e.target.value)}
                  />
                  <Input
                    type="password"
                    placeholder="Şifre"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <SubmitButton type="submit">
                    <SubmitButtonIcon className="icon" />
                    <span className="text">{submitButtonText}</span>
                  </SubmitButton>
                </Form>
                <p tw="mt-8 text-sm text-gray-600 text-center">
                  {" "}
                  <Link to="/resetPassword">Parolanızı mı unuttunuz?</Link>
                </p>

                <p tw="mt-8 text-sm text-gray-600 text-center">
                  Hesabınız yok mu? <Link to="/Signup">Kaydol</Link>
                </p>
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer onClick={handleIllustrationClick}>
            <IllustrationImage imageSrc={logoData?.log_in_logo_url} />
          </IllustrationContainer>
        </Content>
      </Container>
      <ToastContainer />
    </AnimationRevealPage>
  );
};
