import React from "react";

export default function OtelDescComponents({
	editOtelName,
	editOtelDesc,
	editOtelDetailedDesc,
	setOtelName,
	setOtelDesc,
	setOtelDetailedDesc,
}) {
	const handleOnChangeName = (e) => {
		console.log(e.target.value);
		setOtelName(e.target.value);
	};
	const handleOnChangeDesc = (e) => {
		console.log(e.target.value);
		setOtelDesc(e.target.value);
	};
	const handleOnChangeDetailedDesc = (e) => {
		console.log(e.target.value);
		setOtelDetailedDesc(e.target.value);
	};
	return (
		<div>
			{" "}
			<label htmlFor="imageorder">Otelin adı</label>
			<br />
			<input
				type="text"
				className="border-2"
				value={editOtelName}
				onChange={handleOnChangeName}
			/>
			<br />
			<label htmlFor="imageorder">Otelin Açıklaması</label>
			<br />
			<input
				type="text"
				className="border-2"
				value={editOtelDesc}
				onChange={handleOnChangeDesc}
			/>
			<br />
			<label htmlFor="imageorder">Otelin Detaylı Açıklaması</label>
			<br />
			<textarea
				type="text"
				className="border-2 w-full"
				value={editOtelDetailedDesc}
				onChange={handleOnChangeDetailedDesc}
			/>
			<br />
		</div>
	);
}
