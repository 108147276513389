import React, { useState, useEffect } from "react";
import { axios } from "common/utils/axios";
import { useParams } from "react-router-dom";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import {
  faEye,
  faTrash,
  faArrowRight,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import Notlar from "./Notlar";
import {
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Box,
} from "@mui/material";

const NoteSlider = () => {
  const [notes, setNotes] = useState([]);
  const { id } = useParams();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupContent, setPopupContent] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedNote, setEditedNote] = useState("");

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => prevIndex - 1);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const handleDelete = (noteId) => {
    if (window.confirm("Are you sure you want to delete this note?")) {
      axios
        .delete(`/api/notes/${noteId}`)
        .then((response) => {
          console.log("Note deleted:", noteId);
          setNotes(notes.filter((note) => note.id !== noteId)); // Remove deleted note from state array
        })
        .catch((error) => console.error(error));
    }
  };

  const fetchNotes = async () => {
    try {
      const response = await axios.get(`/api/customer/${id}/specialnotes`);
      setNotes([...response.data].reverse()); // This line has been modified
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (noteId) => {
    const noteToEdit = notes.find((note) => note.id === noteId);
    setEditedNote(noteToEdit.note);
    setEditMode(true);
    setPopupOpen(true);
    setPopupContent(noteToEdit);
  };

  const handleSave = () => {
    axios
      .put(`/api/customer/${id}/notes/${popupContent.id}`, { note: editedNote })
      .then((response) => {
        console.log("Note updated:", response.data);
        const updatedNotes = notes.map((note) =>
          note.id === popupContent.id ? response.data : note
        );
        setNotes(updatedNotes);
        setEditMode(false);
        setPopupOpen(false);
      })
      .catch((error) => console.error(error));
  };

  const handleCreate = () => {
    // Implement your create functionality here
  };

  const renderCards = () => {
    return notes.slice(currentIndex, currentIndex + 4).map((note) => (
      <Card
        key={note.id}
        className="m-2 shadow"
        style={{ maxWidth: "250px", maxHeight: "200px" }}
      >
        <Card.Body>
          <Row>
            <Row>
              <Col>
                <h6>{format(new Date(note.created_at), "yyyy/MM/dd HH:mm")}</h6>
                <p style={{ fontSize: "15px" }}>
                  {note && note.note
                    ? note.note.length > 60
                      ? note.note.substring(0, 60) + "..."
                      : note.note.length > 25
                      ? note.note.substring(0, 17) + "..."
                      : note.note
                    : "There is no note."}
                </p>
                {note.note && note.note.length > 25 && (
                  <Button
                    size="sm"
                    variant="primary"
                    onClick={() => {
                      setPopupContent({
                        note: note.note,
                        id: note.id,
                        created_at: note.created_at,
                      });
                      setPopupOpen(true);
                    }}
                    className="text-nowrap"
                    style={{ fontSize: "12px", padding: "4px 6px" }}
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "14px" }}
                    />{" "}
                    Daha Fazla
                  </Button>
                )}
              </Col>
            </Row>
            <Row className="mt-3 ">
              <Col xs={12} className="d-flex justify-content-end">
                <Button
                  variant="danger"
                  size="sm"
                  className="mr-2 ml-2"
                  onClick={() => handleDelete(note.id)}
                  style={{ fontSize: "10px" }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => handleEdit(note.id)}
                  style={{ fontSize: "10px" }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              </Col>
            </Row>
          </Row>
        </Card.Body>
      </Card>
    ));
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  return (
    <div>
      <Notlar />
      {popupOpen && (
        <Dialog
          open={popupOpen}
          onClose={() => {
            setPopupOpen(false);
            setEditMode(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
        >
          <DialogContent sx={{ width: "500px", height: "500px" }}>
            <h4 style={{ textAlign: "center" }}>
              {format(new Date(popupContent.created_at), "yyyy/MM/dd HH:mm")}
            </h4>
            {editMode ? (
              <textarea
                style={{
                  width: "100%",
                  height: "80%",
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                }}
                value={editedNote}
                onChange={(e) => setEditedNote(e.target.value)}
              />
            ) : (
              <DialogContentText
                style={{
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                }}
              >
                {JSON.stringify(popupContent.note, null, 2)}
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              style={{ backgroundColor: "#ff1a1a" }}
              className="mr-2 ml-2"
              variant="contained"
              color="secondary"
              onClick={() => handleDelete(popupContent.id)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
            {editMode ? (
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: "#007bff" }}
                onClick={handleSave}
              >
                Save
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: "#007bff" }}
                onClick={() => handleEdit(popupContent.id)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
      <Box
        sx={{
          display: "flex",

          marginTop: 2,
        }}
      >
        {renderCards()}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 2,
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <IconButton onClick={handlePrevious} disabled={currentIndex === 0}>
          <FontAwesomeIcon icon={faArrowLeft} />
          Previous
        </IconButton>
        <IconButton
          onClick={handleNext}
          disabled={currentIndex + 5 >= notes.length}
        >
          <FontAwesomeIcon icon={faArrowRight} />
          Next
        </IconButton>
      </Box>
    </div>
  );
};

export default NoteSlider;
