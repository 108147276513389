import React from "react";

export const ReservationandBillingInformation = () => {
  return (
    <div className="row mb-4">
      <div className="col-4 col-md-2 p5 hidden">
        <label className="control-label mb0 p2">Cinsiyet</label>
        <select name="data[rez][cinsiyet]" className="form-control">
          <option value="bay">Bay</option>
          <option value="bayan">Bayan</option>
        </select>
      </div>
      <div className="col-4 col-md-6 p5">
        <label className="control-label mb0 p2">Ad</label>
        <input
          type="text"
          name="data[rez][ad]"
          className="form-control"
          required=""
          value=""
        />
      </div>
      <div className="col-4 col-md-6 p5">
        <label className="control-label mb0 p2">Soyad</label>
        <input
          type="text"
          name="data[rez][soyad]"
          className="form-control"
          required=""
          value=""
        />
      </div>
      <div className="col-12 col-md-6 p5">
        <label className="control-label mb0 p2">E-Mail</label>
        <input
          type="text"
          name="data[rez][email]"
          className="form-control"
          required=""
          value=""
        />
      </div>
      <div class="col-4 col-md-6 p5">
        <label class="control-label mb0 p2">Cep Telefonu</label>
        <input
          type="text"
          name="data[rez][mobile]"
          className="form-control"
          required=""
          value=""
        />
      </div>
      <div className="gribg mt-4">
        <div className="online-section-subtitle bbdashed p2">
          Fatura Bilgileri
        </div>
        <div className="p4">
          <label class="normalw mb-4">
            <input
              style={{
                fontSize: "10px",
                transform: "scale(1.5)",
                marginRight: "15px",
              }}
              type="checkbox"
              name="data[rez][faturabilgiayni]"
              value="1"
              checked=""
              id="farklifatura"
            />{" "}
            Rezervasyon bilgileri ile aynı
          </label>
        </div>
        {/* <div class="row" id="faturabilgileri" style="display: none;">
          <div class="col-12 col-md-12 p5">
            <label class="control-label mb0">Ad Soyad yada Ünvan</label>
            <input
              type="text"
              name="data[fatura][unvan]"
              className="form-control zorunlu"
              value=""
            />
          </div>
          <div className="clearfix"></div>
          <div className="col-6 col-md-6 p5">
            <label className="control-label mb0">Vergi Dairesi</label>
            <input
              type="text"
              name="data[fatura][vd]"
              className="form-control zorunlu"
              value=""
            />
          </div>
          <div className="col-6 col-md-6 p5">
            <label className="control-label mb0">Vergi Numarası</label>
            <input
              type="text"
              name="data[fatura][vno]"
              className="form-control zorunlu"
              value=""
            />
          </div>
          <div className="clearfix"></div>
          <div className="col-12 col-md-12 p5">
            <label className="control-label mb0">Adres</label>
            <input
              type="text"
              name="data[fatura][adres]"
              className="form-control zorunlu"
              value=""
            />
          </div>
          <div className="clearfix"></div>
          <div className="col-6 col-md-6 p5">
            <label className="control-label mb0">İl</label>
            <input
              type="text"
              name="data[fatura][il]"
              className="form-control zorunlu"
              value=""
            />
          </div>
          <div className="col-6 col-md-6 p5">
            <label className="control-label mb0">İlçe</label>
            <input
              type="text"
              name="data[fatura][ilce]"
              className="form-control zorunlu"
              value=""
            />
          </div>
          <div className="clearfix"></div>
        </div> */}
      </div>
      <hr />
    </div>
  );
};
export default ReservationandBillingInformation;
