import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { axios } from "common/utils/axios";
import { Link } from "react-router-dom";
import { useCustomerData } from "common/hooks/useCustomerData";
import { useToursData } from "common/hooks/useToursData";
import { Button, Modal, Table, Form, Col, Row } from "react-bootstrap";
import { faTrash, faEye, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
function formatDate(dateString) {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
    hour12: false,
  };
  const date = new Date(dateString);
  return date.toLocaleDateString("tr-TR", options) + " ";
}
const fetchNewOffers = async ({ queryKey }) => {
  // Extract the query key parameters
  const [_key, { filterText }] = queryKey;
  const response = await axios.get(
    `/api/new-offer?search=${encodeURIComponent(filterText)}`
  );
  return response.data;
};

export const CustomersOffers = () => {
  const [selectedTourPricing, setSelectedTourPricing] = useState([]);
  const [showEditOfferModal, setShowEditOfferModal] = useState(false);

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [customerNames, setCustomerNames] = useState({});
  const [customer, setCustomer] = useState("");
  const [toursData, setToursData] = useState([]);
  const [customerNewOfferData, setCustomerNewOfferData] = useState({
    customer_id: "",
    tour_id: "",
    offer_statu: "",
    stars_level: "",
    prices: [],
  });
  const mutation = useMutation((updatedOfferData) => {
    return axios.put(
      `api/tour-offer/update/${updatedOfferData.id}`,
      updatedOfferData
    );
  });
  const {
    data: customersOffersData,
    isLoading,
    error,
  } = useQuery(["newOffers", { filterText }], fetchNewOffers, {
    keepPreviousData: true, // Optional: Keep previous data while fetching new data
  });
  console.log(customersOffersData, "customersOffersData ");

  /*********************Fething Api Customer meetings******************* */
  const handleDeleteMeeting = async (offerId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Offer?"
    );
    if (confirmDelete) {
      try {
        await axios.delete(`/api/tour-offer/delete/${offerId}`);
        toast.success("Teklif başarıyla silindi.!"); // Show success toast

        // Optionally, refresh the data or remove the deleted item from the local state to update UI
        const updatedOffers = customersOffersData.filter(
          (item) => item.id !== offerId
        );
      } catch (error) {
        console.error("Teklifin silinmesi sırasında bir hata oluştu.:", error);
        toast.error("An error occurred while deleting the offer."); // Show error toast
      }
    }
  };
  useEffect(() => {
    axios
      .get(`/customers/name/all`)
      .then((response) => setCustomerNames(response.data))
      .catch((error) => console.error(error));
  }, []);
  /******************* Data Table Style ********************** */
  const customStyles = {
    rows: {
      style: {
        fontSize: "17px", // Custom font size
      },
    },
    headCells: {
      style: {
        fontSize: "17px", // Custom font size
      },
    },
  };
  const handleSearch = (e) => {
    setFilterText(e.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    const updatedOfferData = {
      ...customerNewOfferData,
      // Make sure you include the offer ID in your customerNewOfferData state or have another way to reference it here
    };

    mutation.mutate(updatedOfferData, {
      onSuccess: () => {
        toast.success("Offer updated successfully!");
        setShowEditOfferModal(false);
        // Optionally, refetch offer data or update local state to reflect the changes
      },
      onError: (error) => {
        toast.error(`An error occurred: ${error.message}`);
      },
    });
  };

  const handleEditOfferClick = (offer) => {
    setCustomerNewOfferData({
      ...customerNewOfferData,
      id: offer.id,
      customer_id: offer.customer_id,
      tour_id: offer.tour_id.toString(),
      offer_statu: offer.offer_statu,
      stars_level: offer.stars_level,
      prices: offer.prices, // Assuming this is an array of { how_many_people, price, pricing_type }
    });
    console.log(customerNewOfferData, "dataafterthesetcustomerData");
    setSelectedTourPricing(offer.prices); // Make sure this aligns with how you're using it in the modal
    setShowEditOfferModal(true);
  };
  const handleTourChange = async (e) => {
    const tourId = e.target.value;

    // Fetch the selected tour's details including its prices (or find it in already loaded toursData if available)
    const selectedTour = toursData.find(
      (tour) => tour.tourId.toString() === tourId
    );

    // If a new tour is selected, update prices to reflect the new tour's pricing
    if (selectedTour && selectedTour.pricing) {
      setSelectedTourPricing(selectedTour.pricing);
      setCustomerNewOfferData((prevState) => ({
        ...prevState,
        tour_id: tourId,
        // Reset prices to the new tour's prices, or keep the original logic if you only want to view them
        prices: selectedTour.pricing.map((price) => ({
          how_many_people: price.howManyPeople,
          price: price.mainPrice,
          pricing_type: price.pricingType,
        })),
      }));
    } else {
      // If no tour is selected or it has no pricing, reset the selectedTourPricing
      setSelectedTourPricing([]);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerNewOfferData({
      ...customerNewOfferData,
      [name]: value,
    });
  };
  const handlePriceChange = (howManyPeople, value, pricingType) => {
    const newPrices = [...customerNewOfferData.prices];
    const priceIndex = newPrices.findIndex(
      (price) => price.how_many_people === howManyPeople
    );

    if (priceIndex !== -1) {
      newPrices[priceIndex] = {
        ...newPrices[priceIndex],
        price: value,
        pricing_type: pricingType,
      };
    } else {
      newPrices.push({
        how_many_people: howManyPeople,
        price: value,
        pricing_type: pricingType,
      });
    }

    setCustomerNewOfferData((prevState) => ({
      ...prevState,
      prices: newPrices,
    }));
  };
  useEffect(() => {
    axios
      .get("api/get-active-tours")
      .then((response) => setToursData(response.data))
      .catch((error) => console.error(error));
  }, []);
  return (
    <div>
      <div
        style={{
          display: "flex", // Use flexbox
          justifyContent: "space-between", // Space between the title and the button
          alignItems: "center", // Vertically center align items
          backgroundColor: "rgb(99, 102, 101)",
          padding: "5px",
          borderRadius: "4px",
          marginBottom: "15px",
          fontWeight: "bold",
          padding: "10px",
          color: "white",
        }}
      >
        <h2>Teklif Yönetimi</h2>
        <Link to={`/admin/customerManagement/CustomersOffers/CreateOffers`}>
          <Button variant="primary" style={{ marginBottom: "7px" }}>
            Yeni Teklif
          </Button>
        </Link>
      </div>
      <div
        style={{
          padding: "20px",
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <h4>Sistemde Kayıtlı Teklif</h4>
        <input
          className="form-control mb-3"
          type="text"
          placeholder="Tur adına göre ara ..."
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Müşteri ID</th>
              <th>Tur</th>
              <th>Fiyat</th>
              <th>Teklif Durumu</th>
              <th>ilgi Düzeyi</th>
              <th>Eklenme Tarihi</th>
              <th>Eylem</th>
            </tr>
          </thead>
          <tbody>
            {customersOffersData?.map((item) => (
              <tr key={item.id}>
                <td>{item?.customer.full_name}</td>
                <td>{item?.tour?.tourName}</td>
                <td>
                  {item.prices?.map((price, index) => (
                    <div key={index}>
                      <span
                        style={{
                          fontSize: "13px",
                          display: "block",
                          marginRight: "3px",
                          backgroundColor: "#e0e0d1",
                          padding: "2px",
                          borderRadius: "4px",
                          marginBottom: "2px",
                        }}
                      >
                        {price.how_many_people} Kişi: {price.price}{" "}
                        {price.pricing_type}
                      </span>
                    </div>
                  ))}
                </td>
                <td>{item.offer_statu}</td>
                <td>{item.stars_level}</td>
                <td>{formatDate(item.created_at)}</td>
                <td>
                  <Link
                    to={`/admin/customerManagement/customers/${item?.customer_id}/${item?.customer.FirstName}-${item?.customer.LastName}`}
                  >
                    <Button
                      variant="success"
                      className="mr-2"
                      onClick={() =>
                        console.log(`Button clicked for row ${item.id}`)
                      }
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </Button>
                  </Link>
                  <Button
                    variant="danger"
                    className="mr-2"
                    onClick={() => handleDeleteMeeting(item.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => handleEditOfferClick(item)}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Modal
        size="lg"
        centered
        show={showEditOfferModal}
        onHide={() => setShowEditOfferModal(false)}
      >
        <Modal.Header closeButton>
          <h3>Teklifi Düzenle</h3>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xl={4}>
                <Form.Group controlId="tours">
                  <h4>Tours</h4>
                  <Form.Select
                    name="tour_id"
                    value={customerNewOfferData.tour_id}
                    onChange={handleTourChange}
                  >
                    <option value="" disabled selected>
                      {" "}
                      -- Tur seç --{" "}
                    </option>
                    {toursData.map((tour) => (
                      <option key={tour.tourId} value={tour.tourId}>
                        {tour.tourName}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xl={4}>
                <Form.Group controlId="stars">
                  <h4>İlgi Düzeyi</h4>
                  <Form.Select
                    name="stars_level"
                    value={customerNewOfferData.stars_level}
                    onChange={handleChange}
                  >
                    <option value="Çok Düşük">Çok Düşük</option>
                    <option value="Düşük">Düşük</option>
                    <option value="Orta">Orta</option>
                    <option value="Yüksek">Yüksek</option>
                    <option value="Çok Yüksek">Çok Yüksek</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xl={4}>
                <Form.Group controlId="offers">
                  <h4>Teklif Durumu</h4>
                  <Form.Select
                    name="offer_statu"
                    value={customerNewOfferData.offer_statu}
                    onChange={handleChange}
                  >
                    <option value="Teklif Verildi">Teklif Verildi</option>
                    <option value="Teklif Kabul Edildi">
                      Teklif Kabul Edildi
                    </option>
                    <option value="Teklif Reddedildi">Teklif Reddedildi</option>
                    <option value="Teklif Revize Edildi">
                      Teklif Revize Edildi
                    </option>
                    <option value="Teklif Iptal Edildi">
                      Teklif Iptal Edildi
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>{" "}
              {selectedTourPricing.length > 0 &&
                selectedTourPricing.map((price, index) => (
                  <Col className="mt-4" xl={3} key={index}>
                    <Form.Group controlId={`pricing-${price.howManyPeople}`}>
                      <Form.Label>
                        {price.howManyPeople} Kişi - Fiyat ({price.pricingType})
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name={`price-${price.howManyPeople}`}
                        // Use customerNewOfferData.prices to find the value if editing, else use selectedTourPricing for a new selection
                        value={
                          customerNewOfferData.prices.find(
                            (p) => p.how_many_people === price.howManyPeople
                          )?.price || price.mainPrice
                        }
                        onChange={(e) =>
                          handlePriceChange(
                            price.howManyPeople,
                            e.target.value,
                            price.pricingType
                          )
                        }
                        min="0"
                        step="0.01"
                      />
                    </Form.Group>
                  </Col>
                ))}
            </Row>
            <div className="d-flex justify-content-end">
              <Button className="mt-4" type="submit">
                Teklif Düzenle
              </Button>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};
export default CustomersOffers;
