import React from "react";
import { FormGroup, FormLabel, FormControl } from "react-bootstrap";

const DropDownInputBootsrap = ({ label, id, value, options, onChange }) => {
  return (
    <FormGroup>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <FormControl
        as="select"
        id={id}
        value={value}
        onChange={(e) => onChange(id, e.target.value)}
      >
        <option value="" disabled>
          Select {label}
        </option>
        {options &&
          options.length > 0 &&
          options.map((option) =>
            option.value ? (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ) : null
          )}
      </FormControl>
    </FormGroup>
  );
};

export default DropDownInputBootsrap;
