import React, { useState } from "react";
import { axios } from "common/utils/axios";
import { ToastContainer, toast } from "react-toastify";

import { Button } from "react-bootstrap";
export default function Price() {
  const [priceData, setPriceData] = useState({
    name: "",
    abbreviation: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("api/prices", {
        name: priceData.name,
        abbreviation: priceData.abbreviation,
      })
      .then((response) => {
        console.log(response.data);
        // show a toast notification
        toast.success("Price created successfully!");
        // reset tour data
        setPriceData({ name: "", abbreviation: "" });
      })
      .catch((error) => {
        console.error(error);
        toast.error("Somthing wrong");
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPriceData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="">
          <label>Price Name</label>
          <input
            value={priceData.name}
            name="name"
            onChange={handleChange}
            type="text"
            className="m-4 rounded h3"
          />
          <label>Abbreviation</label>
          <input
            value={priceData.abbreviation}
            name="abbreviation"
            onChange={handleChange}
            type="text"
            className="m-4 rounded h3"
          />
          <Button active={true} type="submit" className="btn btn-primary">
            {" "}
            Create{" "}
          </Button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}
