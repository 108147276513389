import React from "react";
import { Modal } from "react-bootstrap";
import CreateReservationMangment from "area/admin/ReservationMangment/components/CreateReservationMangment";
export const CreateReservationModal = ({
  setModalShowCreate,
  modalShowCreate,
  updateReservations,
}) => {
  return (
    <div>
      {" "}
      <Modal
        show={modalShowCreate}
        onHide={() => setModalShowCreate(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Rezervasyon Oluştur
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
          <CreateReservationMangment
            setModalShowCreate={setModalShowCreate}
            updateReservations={updateReservations}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default CreateReservationModal;
