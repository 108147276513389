import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { ToastContainer, toast } from "react-toastify";
import { fetchHotels } from "common/Api/HotelsApi";
import SearchButtonComponent from "../CRUD/SearchButtonComponent";
import CreatButton from "area/admin/CRUD/CreatButton";
import CreateHotelModal from "area/admin/Hotels/components/create/CreateHotelModal";
import HotelsList from "./HotelsList";
import { fileToBase64 } from "common/helpers/ConvertFileToBase64";
import { Row, Col, Card, Form, Dropdown, Pagination } from "react-bootstrap";
import AdvanceSearchHotel from "area/admin/Hotels/components/AdvanceSearchHotel";

const sectionTitles = ["Otel", "Otel Odalari", "Konum", "Fotograflari"];

export const Hotels = () => {
  const queryClient = useQueryClient();
  const itemsPerPageOptions = [5, 10, 15, 20];
  const [formData, setFormData] = useState({
    otel_name: "",
    otel_stars: "",
    otel_description: "",

    otel_details: {
      wifi: false,
      rating: null, // or a default value if you prefer
      food: false,
      microwave: false,
      kitchen: false,
      first_aid_kit: false,
      heating: false,
      cooling: false,
    },

    otel_address: {
      country: "",
      state: "",
      district: "",
      neighborhood: "",
      street_address: "",
      postal_code: "",
    },
    mainPhotograph: null,
    insidePhotographs: [],
    outsidePhotographs: [],

    room_types: [], // Array of room type IDs
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [activeSection, setActiveSection] = useState(1);
  const [filters, setFilters] = useState({ page: 1, perPage: 5 });
  const [modalShowCreateHotel, setModalShowCreateHotel] = useState(false);
  const { data, isLoading, isError } = useQuery(
    ["hotels", currentPage, filters],
    () => fetchHotels({ page: currentPage, filters })
  );
  const updateFormData = (sectionData) => {
    setFormData((prev) => ({ ...prev, ...sectionData }));
  };
  const handleClickCreatButton = () => {
    setModalShowCreateHotel(true);
  };
  const handlePreviousSection = () => {
    setActiveSection((prev) => Math.max(prev - 1, 1));
  };
  const handleNextSection = (sectionData) => {
    setFormData((prevFormData) => ({ ...prevFormData, ...sectionData }));
    setActiveSection((prev) => Math.min(prev + 1, 7));
  };
  const handleSubmitFinal = async () => {};

  const handleItemsPerPageChange = (number) => {
    setFilters((prevFilters) => ({ ...prevFilters, page: 1, perPage: number }));
    setCurrentPage(1); // Reset to first page when items per page change
    // Optionally, you can immediately fetch the updated list here or use `applyFilters` to do so
    applyFilters(); // Reset to first page when items per page change
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setFilters({ ...filters, page: pageNumber });
  };
  const handleInputChange = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };
  const applyFilters = () => {
    queryClient.invalidateQueries("hotels");
  };
  const handleClearFilter = () => {
    setFilters({});
    queryClient.invalidateQueries("hotels");
  };
  const totalPages = data ? data.last_page : 0;
  const paginationItems =
    totalPages > 0 ? (
      <>
        <Pagination.Prev
          onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
          disabled={currentPage === 1}
        />
        {[...Array(totalPages).keys()].map((pageNumber) => (
          <Pagination.Item
            key={pageNumber + 1}
            active={pageNumber + 1 === currentPage}
            onClick={() => handlePageChange(pageNumber + 1)}
          >
            {pageNumber + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() =>
            handlePageChange(Math.min(totalPages, currentPage + 1))
          }
          disabled={currentPage === totalPages}
        />
      </>
    ) : null;
  const otelData = data?.data;
  return (
    <div>
      <Card style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)" }}>
        <Card.Header
          style={{
            backgroundColor: "#636665",
            color: "white",
            borderRadius: "5px 5px 0 0",
          }}
        >
          <Row>
            <Col>
              <h1 style={{ padding: "10px", fontSize: "24px", margin: 0 }}>
                Otel Yönetimi
              </h1>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Row>
                <Col>
                  <CreatButton
                    title={"Otel Ekle "}
                    onClick={handleClickCreatButton}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row className="mb-4 mt-2">
            <Col md={5} sm={12} style={{ paddingTop: "10px" }}>
              <h4>Sistemde kayıtlı Otelleri listeleniyor</h4>
            </Col>
            <Col md={3} sm={6} xs={6} style={{ paddingTop: "10px" }}>
              <Form.Control
                onChange={handleInputChange}
                name="otel_name"
                type="text"
                placeholder="Otel Adı"
              />
            </Col>
            <Col md={2} sm={6} xs={6} style={{ paddingTop: "10px" }}>
              <SearchButtonComponent />
            </Col>
            <Col md={2} style={{ paddingTop: "10px" }}>
              <AdvanceSearchHotel
                setFilters={setFilters}
                handleInputChange={handleInputChange}
                applyFilters={applyFilters}
              />
            </Col>
          </Row>

          <HotelsList
            otelData={otelData}
            updateFormData={updateFormData}
            handleNextSection={handleNextSection}
            handlePreviousSection={handlePreviousSection}
            handleSubmitFinal={handlePreviousSection}
            sectionTitles={sectionTitles}
            activeSection={activeSection}
            setActiveSection={setActiveSection}
            formData={setActiveSection}
          />

          <div className="d-flex justify-content-between align-items-center mt-3">
            <div></div> {/* Empty div for spacing */}
            <div style={{ paddingTop: "15px" }}>
              {paginationItems && <Pagination>{paginationItems}</Pagination>}
            </div>
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Sayifada
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {itemsPerPageOptions.map((option) => (
                  <Dropdown.Item
                    key={option}
                    onClick={() => handleItemsPerPageChange(option)}
                  >
                    {option}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Card.Body>
      </Card>

      <CreateHotelModal
        setFormData={setFormData}
        updateFormData={updateFormData}
        setModalShowCreateHotel={setModalShowCreateHotel}
        modalShowCreateHotel={modalShowCreateHotel}
        handleNextSection={handleNextSection}
        handlePreviousSection={handlePreviousSection}
        handleSubmitFinal={handleSubmitFinal}
        sectionTitles={sectionTitles}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
        formData={formData}
      />
    </div>
  );
};
export default Hotels;
