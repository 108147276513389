import { useState, useRef } from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import Quill from "react-quill";
import { Link } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "common/Api/CommentsApi";
import PostImage from "area/admin/CRUD/ReactBootsrap/FormUplaodInput";
const CreateComments = () => {
  const queryClient = useQueryClient();
  const fileInputRef = useRef(null);
  const [imageURL, setImageURL] = useState(null);
  const [formData, setFormData] = useState({
    commenter_name: "",
    comment: "",
    avatar: "",
    stars: "",
    gender: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let avatar_base64;
      // Check if the avatar is an instance of Blob (file)
      if (formData.avatar instanceof Blob) {
        avatar_base64 = await toBase64(formData.avatar);
      }

      // Construct the JSON object with all the data
      const jsonData = {
        commenter_name: formData.commenter_name,
        comment: formData.comment,
        avatar: avatar_base64,
        stars: formData.stars,
        gender: formData.gender,
      };

      mutationCreate.mutate(jsonData);
    } catch (error) {
      console.error("Yorum gönderme hatası.", error);
      toast.error("Yorumunuz işlenirken bir hata oluştu.");
    }
  };
  const handleQuillChange = (content) => {
    setFormData((prev) => ({ ...prev, comment: content }));
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const newImageURL = URL.createObjectURL(file);

    // Update the imageURL state
    setImageURL(newImageURL);
    setFormData((prevState) => ({
      ...prevState,
      avatar: file,
    }));
  };
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const mutationCreate = useMutation(api.createComments, {
    onSuccess: () => {
      queryClient.invalidateQueries("comments");
      toast.success("Yorum başarıyla eklendi!");
      setFormData({
        commenter_name: "",
        comment: "",
        avatar: "",
        stars: "",
        gender: "",
      });
      setImageURL(null);

      // Clear the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      localStorage.setItem("shouldRefetchComments", "true");
      window.location.href = "/admin/comments/index";
    },
    onError: (error) => {
      // Handling API errors
      const errorMsg =
        error?.response?.data?.message || "Yorum eklenirken hata oluştu";
      toast.error(errorMsg);
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleRemoveAvatar = () => {
    setImageURL(null); // Clear the image URL to remove the preview
    setFormData({ ...formData, avatar: "" }); // Clear the avatar field in the form data
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset the file input
    }
  };
  return (
    <Card>
      <Card.Header
        style={{
          backgroundColor: "#636665",
          color: "white",
          borderRadius: "5px 5px 0 0",
        }}
      >
        <Row>
          <Col>
            <h1 style={{ padding: "10px", fontSize: "24px", margin: 0 }}>
              Yorumlar Yönetimi
            </h1>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Row>
              <Col>
                <Link to="/admin/comments/index">
                  <Button variant="outline-warning">Geri</Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xl={9} sm={12}>
              {" "}
              <Form.Group className="mb-3">
                <Form.Label>Müşteri</Form.Label>
                <Form.Control
                  type="text"
                  name="commenter_name"
                  value={formData.commenter_name}
                  onChange={handleChange}
                  placeholder="Adı Soyadı"
                />
              </Form.Group>
              <Form.Group className="mb-3" style={{ height: "200px" }}>
                <Form.Label>Yorum</Form.Label>
                <Quill
                  className="custom-quill"
                  value={formData.comment}
                  onChange={handleQuillChange}
                />
              </Form.Group>
            </Col>
            <Col xl={3} sm={12}>
              {" "}
              <Form.Group className="mb-3">
                <Form.Label>Cinsiyet</Form.Label>
                <Form.Select
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Bir cinsiyet derecelendirmesi seçin
                  </option>
                  <option value="0">Erkek</option>
                  <option value="1">Kız</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Yıldız</Form.Label>
                <Form.Select
                  name="stars"
                  value={formData.stars}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Bir yıldız derecelendirmesi seçin
                  </option>
                  <option value="0">Boş Bırak</option>
                  <option value="1">1 yıldız</option>
                  <option value="2">2 yıldız</option>
                  <option value="3">3 yıldız</option>
                  <option value="4">4 yıldız</option>
                  <option value="5">5 yıldız</option>
                </Form.Select>
              </Form.Group>
              <PostImage
                editWithCustomStyle={true}
                label={"Müşteri Profili"}
                name={"image"}
                imageSrc={imageURL}
                onChange={handleFileChange}
              />
            </Col>

            <Form.Group className="mb-3 text-end">
              <button type="submit" className="mt-2 btn btn-primary">
                Yorum Ekle
              </button>
            </Form.Group>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};
export default CreateComments;
