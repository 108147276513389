import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import logo from "assets/icons/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { deleteCookie } from "common/helpers/deleteCookie";
import { useNavigate } from "react-router-dom";
import Header, {
  NavLink,
  NavLinks,
  PrimaryLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../headers/userHeader.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
import { useQuery } from "react-query";
import { getListHomePageImagess } from "common/Api/SiteSettingsApi";
import TabTry from "../cards/LandingPageSearchTab";
import { axios } from "../../utils/axios";

const StyledHeader = styled(Header)`
  ${tw`max-w-none z-50 rounded-lg`}

  ${(props) =>
    !props.isScrolled &&
    (props.paddingTop ? `padding-top: ${props.paddingTop}rem;` : tw`pt-8`)}
  ${(props) =>
    props.isScrolled &&
    css`
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.2);
      transform: translateY(0);
      opacity: 1;
      transition: all 0.3s ease;
      height: ${(props) => props.height || "default height"};
    `}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300 `}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500 z-40`}
    ${(props) => props.isScrolled && `margin-right: 2rem;`}
  }
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-25`;

const HeroContainer = styled.div(({ maxWidth }) => [
  tw`z-20 relative px-4 sm:px-8 mx-auto`,
  css`
    max-width: ${maxWidth || "max-w-screen-xl"};
  `,
]);

const HeroContainerHomePage = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;

const TwoColumn = styled.div`
  ${({ paddingTop, paddingBottom, display }) => css`
    ${tw`px-4 flex justify-between items-center flex-col lg:flex-row`}
    padding-top: ${paddingTop || "4"}rem;
    padding-bottom: ${paddingBottom || "4"}rem;
    ${display === "none" && "display: none;"}
  `}
`;
const LeftColumn = tw.div`flex flex-1 flex-col items-center justify-center`;

const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;
const CenteredTextContainer = tw.div`text-center`;
const DateText = styled.span`
  ${tw`text-xl bg-white bg-opacity-50 backdrop-blur-md px-2 py-1 rounded`}
  color: #242222;
`;
const SingleColumn = tw.div`flex flex-col justify-center items-center pt-24 pb-32 px-4 lg:px-8 w-full lg:max-w-screen-2xl`;
const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

const Container = styled.div`
  ${tw`relative  bg-center bg-cover`}
  background-image: url(${(props) => props.image});
  background-position: center 1%;
  transition: background-image 0.5s ease-in-out;
  height: ${(props) => props.height || "400px"};
`;

export default ({
  tabTry,
  title,
  imageUrl,
  date,
  height,
  headerTitlePaddingTop,
  headerTitlePaddingBottom,
  headerPaddingTop,
  maxWidthContainer = "1570px",
  display,
}) => {
  const [allImages, setAllImages] = useState([]);
  const [sliders, setSlider] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);

  const imageUrls = [
    "https://backend.suarelab.com/api/uploads/home.jpg",
    "https://d.newsweek.com/en/full/51857/hajj-british-museum-exhibit-photos-tease.jpg",
    "https://i.ytimg.com/vi/4iUbezXg0Pk/maxresdefault.jpg",
    "https://gretopia.com/media/middle-east/israel/jerusalem/jerusalem-israel-gretopia_5.jpg",
  ];
  const navigate = useNavigate();
  const handleLogout = () => {
    deleteCookie("user");
    deleteCookie("role");
    deleteCookie("token");
    navigate("/login", { replace: true });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
  };
  const navLinks = [
    <NavLinks key={1}>
      <NavLink>
        <NavLinkRRD to="/user">Ana Sayfa</NavLinkRRD>
      </NavLink>
      <NavLink>
        <NavLinkRRD to="/user/otels">Oteller</NavLinkRRD>
      </NavLink>
      <NavLink>
        <NavLinkRRD to="/user/tours">Turlar</NavLinkRRD>
      </NavLink>
      <NavLink>
        <NavLinkRRD to="/user/about-us">Hakkımızda</NavLinkRRD>
      </NavLink>
      <NavLink>
        <NavLinkRRD to="/user/QA">SSS</NavLinkRRD>
      </NavLink>
      <NavLink>
        <NavLinkRRD to="/user/blog">Blog</NavLinkRRD>
      </NavLink>
      <NavLink>
        <NavLinkRRD to="/user/contactUs">İletişim</NavLinkRRD>
      </NavLink>

      <NavLink>
        <NavLinkRRD to="/user/profile">
          <FontAwesomeIcon icon={faUser} /> {/* User Icon */}
        </NavLinkRRD>
      </NavLink>
      <NavLink>
        <NavLinkRRD to="/" onClick={handleLogout}>
          <FontAwesomeIcon icon={faSignOutAlt} /> {/* Logout Icon */}
        </NavLinkRRD>
      </NavLink>
    </NavLinks>,
  ];

  const { data, error, isLoading } = useQuery(
    "HomPageImages",
    getListHomePageImagess,
    {
      onSuccess: (data) => {
        localStorage.setItem("cachedPosts", JSON.stringify(data));
        setAllImages(data);
      },
    }
  );

  // useEffect(() => {
  //   const imageChangeInterval = setInterval(() => {
  //     setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
  //   }, 2500);

  //   return () => clearInterval(imageChangeInterval);
  // }, [sliders]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  console.log(height, "height");
  return (
    <>
      {tabTry ? (
        <Container
          height={height}
          image={allImages[currentImageIndex]?.image_url}
        >
          <OpacityOverlay />
          <HeroContainerHomePage>
            <StyledHeader links={navLinks} isScrolled={isScrolled}>
              <Link to="/">
                <img src={logo} alt="Logo" style={{ width: "100px" }} />
              </Link>
            </StyledHeader>
            <SingleColumn>
              <TabTry />
            </SingleColumn>
          </HeroContainerHomePage>
        </Container>
      ) : (
        <Container image={imageUrl} height={height}>
          <OpacityOverlay />
          <HeroContainer maxWidth={maxWidthContainer}>
            <StyledHeader
              links={navLinks}
              isScrolled={isScrolled}
              paddingTop={headerPaddingTop}
            />
            <TwoColumn
              display={display}
              paddingTop={headerTitlePaddingTop}
              paddingBottom={headerTitlePaddingBottom}
            >
              <LeftColumn>
                <Heading>
                  <CenteredTextContainer>
                    <span>{title}</span>
                    <br />
                    <DateText>{date}</DateText>
                  </CenteredTextContainer>
                </Heading>
                {tabTry && <TabTry />}
              </LeftColumn>
            </TwoColumn>
          </HeroContainer>
        </Container>
      )}
    </>
  );
};
