import React from "react";

export const SideBar = () => {
  return (
    <div>
      <div class="side-box home-destination">
        <div class="card mb15">
          <div class="card-header">Online Alışveriş</div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <a class="collapsed" href="#" target="_blank">
                Online Alışveriş Hakkında
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="side-box home-destination hidden-xs">
        <div class="card mb15"></div>
      </div>{" "}
    </div>
  );
};
