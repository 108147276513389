import React from "react";

const ButtonFilter = ({ onFilter }) => {
  return (
    <div>
      <div className="d-flex justify-content-center">
        <button
          onClick={onFilter}
          type="submit"
          className="btn btn-outline-primary filter-btn filter-btn-color mx-auto px-5"
        >
          Filtrele
        </button>
      </div>
    </div>
  );
};
export default ButtonFilter;
