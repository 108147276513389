// Components/ReservationTable.jsx
import React from "react";
import { Table, Button, ButtonGroup } from "react-bootstrap";
import NoteViewer from "./NoteViewer";
import { toast, ToastContainer } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faEye,
  faSync,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
const ReservationTable = ({
  reservations,
  handleStatusChangeIptal,
  sortReservations,
  handleStatusChangeReseve,
  handleShowDetails,
  handleShowEdit,
}) => {
  return (
    <Table striped bordered hover responsive style={{ fontSize: "14px" }}>
      <thead>
        <tr>
          {[
            "Kod",
            "Tur",
            "Müşteri",
            "tarafından öluştür",
            "Durum",
            "Fiyatı",
            "Kalan",
            "Rezervasyon tarihi",
            "Ödeme son tarihi",
            "Note",
            "Eylem",
          ].map((header, index) => (
            <th key={index} onClick={() => sortReservations(header)}>
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {reservations.map((reservation, index) => (
          <tr key={reservation.code}>
            <td
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <span
                style={{
                  backgroundColor:
                    reservation.durum === "iptal"
                      ? "rgba(225, 54, 52, 0.54)"
                      : reservation.durum === "another"
                      ? "rgba(224, 225, 52, 0.54)"
                      : "#3ae1348a",
                  padding: "3px 6px",
                  borderRadius: "8px",
                }}
              >
                {reservation.code}
              </span>
            </td>
            <td>
              {reservation.tour_id && <p>{reservation.tour?.tourName}</p>}
            </td>
            <td>{`${reservation.customer?.FirstName} ${reservation.customer?.LastName}`}</td>
            <td>
              {reservation.created_by?.name ||
                reservation.created_by?.email ||
                "admin@suarelab.com"}
            </td>
            <td>
              {reservation.durum === "paid"
                ? "Ödedi"
                : reservation.durum === "iptal"
                ? "iptal"
                : "Değişir"}
            </td>
            <td>{reservation.price}</td>
            <td>
              {reservation.restPrice == 0 ? "Kalan Yok" : reservation.restPrice}
            </td>
            <td>
              {new Date(reservation.reservation_date).toLocaleDateString()}
            </td>
            <td>
              {reservation?.payment_deadline
                ? new Date(reservation.payment_deadline).toLocaleDateString()
                : "Yok"}
            </td>
            <td>
              <NoteViewer note={reservation.note} />
            </td>
            <td style={{ display: "flex" }}>
              {reservation.durum !== "iptal" && (
                <Button
                  active={true}
                  variant="danger"
                  onClick={() => handleStatusChangeIptal(reservation.code)}
                  size="md" // Increased size
                  style={{ marginRight: "10px" }} // Optional: To give some space between the buttons
                >
                  <FontAwesomeIcon icon={faTimes} size="sm" color="white" />
                </Button>
              )}
              {reservation.durum == "iptal" && (
                <Button
                  active={true}
                  onClick={() => handleStatusChangeReseve(reservation.code)}
                  size="md" // Increased size
                  style={{
                    marginRight: "10px",
                    backgroundColor: "rgb(5 133 0 / 54%)",
                    border: "none",
                  }} // Optional: To give some space between the buttons
                >
                  <FontAwesomeIcon icon={faSync} size="sm" color="white" />
                </Button>
              )}

              <ButtonGroup>
                <span title="Show">
                  <Button
                    active={true}
                    variant="info"
                    onClick={() => handleShowDetails(reservation)}
                    size="md" // Increased size
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = "#056575")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = "#117a8b")
                    }
                  >
                    <FontAwesomeIcon icon={faEye} size="sm" color="white" />
                  </Button>
                </span>
              </ButtonGroup>
              <ButtonGroup>
                <span title="Edit">
                  <Button
                    style={{ marginLeft: "10px", backgroundColor: "#d39e00" }}
                    active={true}
                    variant="info"
                    onClick={() => handleShowEdit(reservation)} // Update the click handler
                    size="md" // Increased size
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = "#ad8203")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = "#d39e00")
                    }
                  >
                    <FontAwesomeIcon icon={faEdit} size="sm" color="white" />{" "}
                    {/* Update the icon */}
                  </Button>
                </span>
              </ButtonGroup>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ReservationTable;
