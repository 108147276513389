import { Modal, Button } from "react-bootstrap";

export const FamilyModalDelete = ({
  setShowDeleteConfirm,
  handleDelete,
  showDeleteConfirm,
}) => {
  return (
    <div>
      <Modal
        show={showDeleteConfirm}
        onHide={() => setShowDeleteConfirm(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Silme işlemini Onayla</Modal.Title>
        </Modal.Header>
        <Modal.Body>silmek istediğinizden emin misiniz?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteConfirm(false)}
          >
            Haiyr
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Evet
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FamilyModalDelete;
