import React, { useState, useEffect } from "react";
import { axios } from "common/utils/axios";
import SelectInput from "../Components/SelectInput";
import TextInput from "../Components/TextInput";
import RadioInput from "../Components/RadioInput";
import DateInput from "../Components/DateInput";
import UploadFileInput from "../Components/uploadFileInput";
import { borderRadius } from "@mui/system";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

export const CreateCustomre = () => {
  const [heardData, setHeards] = useState([]);
  const [roleData, setRole] = useState([]);
  const [districtsData, setDistricts] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [registaryTypeData, setRegistaryTypeData] = useState([]);
  const [filteredDistricts, setFilteredDistricts] = useState([]);
  const [filteredNeighborhoods, setFilteredNeighborhoods] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedDistrictNam, setSelectedDistrictNam] = useState("");
  const [selectedProvincename, setSelectedProvincename] = useState("");
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [file, setFile] = useState(null);
  const [selectedNeighborhoodName, setSelectedNeighborhoodName] = useState("");
  const [customer, setCustomer] = useState({
    data: {
      FirstName: "",
      LastName: "",
      Telefon: "",
      BirthDate: "",
      jop: "",
      ProfilePhoto: "",
      RegistrationTypeId: "",
      FromWhereHeardAboutUsId: "",
      FaceBook: "",
      Instagram: "",
      twitter: "",
      StarsLevel: "",
      explanation: "",
      CreatedDate: "",
      IsActive: true,
      Eposta: "",
      Gender: "",
      Adres: "",
      CityId: "",
      TownId: "",
      NeighbourhoodId: "",
      Role: "",
      il: "", // Add il field
      ilce: "", // Add ilce field
      mahalle: "", // Add mahalle field
    },
  });

  //                        API Area                     //
  useEffect(() => {
    axios
      .get("/api/districts")
      .then((response) => setDistricts(response.data))
      .catch((error) => console.error(error));
  }, []);
  useEffect(() => {
    axios
      .get("/api/provinces")
      .then((response) => setProvinces(response.data))
      .catch((error) => console.error(error));
  }, []);
  useEffect(() => {
    axios
      .get(`/api/neighbor?district_key=${selectedDistrict}`)
      .then((response) => setFilteredNeighborhoods(response.data))
      .catch((error) => console.error(error));
  }, [selectedDistrict]);
  console.log(selectedDistrict, "selectedDistrictselectedDistrict");
  //                      API   Customers               //
  useEffect(() => {
    axios
      .get("/api/heard")
      .then((response) => setHeards(response.data))
      .catch((error) => console.error(error));

    axios
      .get("/api/roles")
      .then((response) => setRole(response.data))
      .catch((error) => console.error(error));
  }, []);
  useEffect(() => {
    axios
      .get("/api/registarationtype")
      .then((response) => setRegistaryTypeData(response.data))
      .catch((error) => console.error(error));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    // create a new FormData object
    const formData = new FormData();

    // add each field in the customer object to the form data separately
    formData.append("customer[FirstName]", customer.data.FirstName);
    formData.append("customer[LastName]", customer.data.LastName);
    formData.append("customer[Telefon]", customer.data.Telefon);
    formData.append("customer[BirthDate]", customer.data.BirthDate);
    formData.append("customer[Role]", customer.data.Role);
    formData.append("customer[jop]", customer.data.jop);
    formData.append(
      "customer[RegistrationTypeId]",
      customer.data.RegistrationTypeId
    );
    formData.append(
      "customer[FromWhereHeardAboutUsId]",
      customer.data.FromWhereHeardAboutUsId
    );
    formData.append("customer[FaceBook]", customer.data.FaceBook);
    formData.append("customer[Instagram]", customer.data.Instagram);
    formData.append("customer[twitter]", customer.data.twitter);
    formData.append("customer[StarsLevel]", customer.data.StarsLevel);
    formData.append("customer[explanation]", customer.data.explanation);
    formData.append("customer[CreatedDate]", customer.data.CreatedDate);
    formData.append("customer[IsActive]", customer.data.IsActive);
    formData.append("customer[Eposta]", customer.data.Eposta);
    formData.append("customer[Gender]", customer.data.Gender);
    formData.append("customer[Adres]", customer.data.Adres);
    formData.append("customer[CityId]", customer.data.CityId);
    formData.append("customer[TownId]", customer.data.TownId);
    formData.append("customer[NeighbourhoodId]", customer.data.NeighbourhoodId);

    formData.append("il", customer.data.CityId);
    formData.append("ilce", customer.data.TownId);
    formData.append("mahalle", customer.data.NeighbourhoodId);
    formData.append("adres", customer.data.Adres);

    // add the file to the form data
    if (file) {
      formData.append("file", file);
    }

    // send the form data to the server using axios
    axios
      .post("api/customers", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res);
        toast.success("Customer created successfully!");
      })
      .catch((error) => {
        if (error.response) {
          // Check for 409 status code
          if (error.response.status === 409) {
            toast.error(
              error.response.data.message ||
                "The phone number is already in use."
            );
          } else {
            console.error(error);
            toast.error(
              error.response.data.message ||
                "An error occurred while creating the customer."
            );
          }
        } else {
          console.error(error);
          toast.error("An unexpected error occurred.");
        }
      });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  console.log(filteredNeighborhoods, "filteredNeighborhoods");
  /****************************** Start HandlChange *********************************************/
  const handleChange = (e) => {
    console.log("handleChange triggered", e.target.name, e.target.value);
    const { name, value, type } = e.target;

    if (type === "file") {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);

      axios
        .post("/api/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setCustomer({
            ...customer,
            data: {
              ...customer.data,
              [name]: response.data.fileUrl, // save the file URL returned by the server in your customer data
            },
          });
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        });
    } else {
      setCustomer({
        ...customer,
        data: {
          ...customer.data,
          [name]: value,
        },
      });

      // Update CityId and TownId in customer object
      if (name === "Province") {
        const selectedProvinceData = provinces.find(
          (province) => province.province_name === value
        );
        setSelectedProvince(selectedProvinceData.province_key);
        setSelectedProvincename(selectedProvinceData.province_name);
        const filteredDistricts = districtsData.filter(
          (district) =>
            district.district_province_key === selectedProvinceData.province_key
        );
        setFilteredDistricts(filteredDistricts);
        const filteredNeighborhoods = neighborhoods.filter(
          (neighborhood) =>
            neighborhood.neighborhood_district_key ===
            filteredDistricts[0].district_key
        );
        setFilteredNeighborhoods(filteredNeighborhoods);
        setCustomer({
          ...customer,
          data: {
            ...customer.data,
            CityId: selectedProvinceData.province_name,
            TownId: filteredDistricts[0].district_town_key,
          },
        });
      } else if (name === "District") {
        const selectedDistrictData = filteredDistricts.find(
          (district) => district.district_name === value
        );
        setSelectedDistrict(selectedDistrictData.district_key);
        setSelectedDistrictNam(selectedDistrictData.district_name);
        const filteredNeighborhoods = neighborhoods.filter(
          (neighborhood) =>
            neighborhood.neighborhood_district_key ===
            selectedDistrictData.district_key
        );
        setFilteredNeighborhoods(filteredNeighborhoods);
        console.log("filteredNeighborhoods", filteredNeighborhoods); // Add this console log
        setCustomer({
          ...customer,
          data: {
            ...customer.data,
            TownId: selectedDistrictData.district_name,
          },
        });
      } else if (name === "Neighbourhood") {
        const selectedNeighborhoodData = filteredNeighborhoods.find(
          (neighborhood) => neighborhood.neighborhood_name === value
        );
        console.log("selectedNeighborhoodData");
        console.log("selectedNeighborhoodData", selectedNeighborhoodData);
        console.log(
          "selectedNeighborhoodName before setting",
          selectedNeighborhoodName
        );
        console.log("value", value);
        console.log(
          "selectedNeighborhoodName after setting",
          selectedNeighborhoodName
        );
        setSelectedNeighborhoodName(selectedNeighborhoodData.neighborhood_name);
        setCustomer({
          ...customer,
          data: {
            ...customer.data,
            NeighbourhoodId: selectedNeighborhoodData.neighborhood_key, // Use the neighborhood_key instead of neighborhood_name
          },
        });
      }
    }
  };

  const blurShadowStyle = {
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    transition: "0.3s",
    borderRadius: "5px" /* 5px rounded corners */,
  };
  return (
    <div>
      <Link
        to="/admin/customerManagement/customers"
        className="btn btn-secondary"
      >
        <button active={true} type="secondary">
          Geri
        </button>
      </Link>
      <form onSubmit={handleSubmit}>
        <div
          className="row"
          style={{
            backgroundColor: "white",
            borderRadius: "8px",
            padding: "20px",
          }}
        >
          <div
            className="col-8"
            style={{ marginRight: "2px", ...blurShadowStyle }}
          >
            <h3 style={{ padding: "12px" }}>Genel Bilgileri</h3>
            <div className="row">
              <div className="col-8">
                <SelectInput
                  name="Role"
                  value={customer.data.Role}
                  onChange={handleChange}
                  label={"Role"}
                  options={roleData.map((item) => item.name)}
                  keyName="Role"
                />
              </div>
              <div className="col-6">
                Bizi nereden buldu?
                <SelectInput
                  name="FromWhereHeardAboutUsId"
                  value={customer.data.FromWhereHeardAboutUsId}
                  onChange={handleChange}
                  label={"Bizi nereden buldu?"}
                  options={heardData.map(
                    (item) => item.from_where_you_heard_about_us
                  )}
                  keyName="from_where_you_heard_about_us"
                />
              </div>
              <div className="col-6">
                Kayit tipi
                <SelectInput
                  name="RegistrationTypeId"
                  value={customer.data.RegistrationTypeId}
                  onChange={handleChange}
                  label={"Kayit tipi"}
                  options={registaryTypeData.map(
                    (item) => item.customer_registration_type
                  )}
                  keyName="from_where_you_heard_about_us"
                />
              </div>
              <div className="col-5 mt-4 m-2">
                <TextInput
                  label={"Adı"}
                  name="FirstName"
                  value={customer.data.FirstName}
                  onChange={handleChange}
                />
              </div>
              <div className="col-5 mt-4 m-2">
                <TextInput
                  label={"Soyad"}
                  name="LastName"
                  value={customer.data.LastName}
                  onChange={handleChange}
                />
              </div>
              <hr style={{ maxWidth: "80%" }}></hr>
              <div className="col-6">
                <TextInput
                  label={"Telefon"}
                  name="Telefon"
                  value={customer.data.Telefon}
                  onChange={handleChange}
                />
              </div>
              <div className="col-6">
                Cinsiyet
                <RadioInput
                  label1={"Erkek"}
                  name="Gender"
                  value={customer.data.Gender}
                  onChange={handleChange}
                />
              </div>
              <hr style={{ maxWidth: "80%" }}></hr>
              <div className="row mb-4">
                <div className="col-4">
                  Doğum Tarihi
                  <DateInput
                    name="BirthDate"
                    onChange={handleChange}
                    value={customer.data.BirthDate}
                  />
                </div>
                <div className="col-4">
                  E-posta
                  <TextInput
                    name="Eposta"
                    onChange={handleChange}
                    value={customer.data.Eposta}
                  />
                </div>
                <div className="col-4">
                  Meslek
                  <TextInput
                    name="jop"
                    onChange={handleChange}
                    value={customer.data.jop}
                  />
                </div>
              </div>
              <hr style={{ maxWidth: "80%" }}></hr>
              <div className="row mb-4">
                <div className="col-4">
                  <SelectInput
                    label={"il"}
                    name="Province"
                    value={selectedProvincename}
                    onChange={handleChange}
                    options={provinces.map((item) => item.province_name)}
                    keyName="province_name"
                    required
                  />
                </div>
                <div className="col-4">
                  <SelectInput
                    required
                    label={"ilce"}
                    name="District"
                    value={selectedDistrictNam}
                    onChange={handleChange}
                    options={filteredDistricts.map(
                      (item) => item.district_name
                    )}
                    keyName="district_name"
                  />
                </div>
                <div className="col-4">
                  <SelectInput
                    required
                    label={"mahalle"}
                    name="Neighbourhood"
                    value={selectedNeighborhoodName}
                    onChange={handleChange}
                    options={
                      Array.isArray(filteredNeighborhoods)
                        ? filteredNeighborhoods.map(
                            (item) => item.neighborhood_name
                          )
                        : []
                    }
                    keyName="neighborhood_name"
                  />
                </div>
              </div>
              <div className="col-12">
                Adres
                <TextInput
                  name="Adres"
                  value={customer.data.Adres}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div
            className="col-3"
            style={{ marginLeft: "70px", ...blurShadowStyle }}
          >
            <h3 style={{ padding: "12px" }}>Sosyal Medya Bilgileri</h3>
            <div>
              <TextInput
                label={"Facebook Profil Linki"}
                name="FaceBook"
                value={customer.data.FaceBook}
                onChange={handleChange}
                className="mb-4 border "
                style={{ width: "100%", padding: "12px", borderRadius: "8px" }}
              />
              <TextInput
                label={"Instagram Profil Linki"}
                name="Instagram"
                value={customer.data.Instagram}
                onChange={handleChange}
                className="mb-4 border "
                style={{ width: "100%", padding: "12px", borderRadius: "8px" }}
              />
              <TextInput
                label={"Twitter Profil Linki"}
                name="twitter"
                value={customer.data.twitter}
                onChange={handleChange}
                className="mb-4 border "
                style={{ width: "100%", padding: "12px", borderRadius: "8px" }}
              />
            </div>

            <TextInput
              label={"Kısa Açıklama"}
              name="explanation"
              value={customer.data.explanation}
              onChange={handleChange}
              className="mb-4 border "
              style={{ width: "100%", padding: "20px", borderRadius: "8px" }}
            />
            <label>Profile Resmi</label>
            <UploadFileInput
              className="mb-4"
              name="ProfilePhoto"
              value={customer.data.ProfilePhoto}
              onChange={handleFileChange}
            />
            <Button
              active={true}
              type="submit"
              style={{ width: "100%" }}
              className="mt-4"
            >
              Ekle
            </Button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default CreateCustomre;
