import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";

// Example options that might come from your database
const initialOptions = [
  { value: "react", label: "React" },
  { value: "javascript", label: "JavaScript" },
  { value: "css", label: "CSS" },
  // Add more tags as needed
];

export function TagSelectionComponent({
  onChange,
  options,
  value,
  placeholder,
}) {
  return (
    <CreatableSelect
      isMulti
      onChange={onChange}
      options={options}
      value={value}
      placeholder=""
    />
  );
}

export default TagSelectionComponent;
